import React from 'react';
import { RouteProps } from 'react-router-dom';
import './style.scss';

interface LayoutProps extends RouteProps {
  children: any;
}

const LayoutBodyComponent: React.FunctionComponent<LayoutProps> = ({
  children,
}: any) => <div className="layout-body">{children}</div>;

export default LayoutBodyComponent;
