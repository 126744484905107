import React, { FunctionComponent } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../style.scss';
import * as netWorking from '../../../redux/netWorking';
import { useTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useSelector } from 'react-redux';
import { RootStore } from '../../../redux/Store';

interface ModalDuplicateAlertProps {
  modalShow: any;
  setModalShow: any;
  listRejected: any;
  replacedFun: any;
  reviewFun: any;
  upDataId: string;
}

export const ModalDuplicateAlert: FunctionComponent<ModalDuplicateAlertProps> = ({
  modalShow,
  setModalShow,
  listRejected,
  replacedFun,
  reviewFun,
  upDataId,
}: any) => {
  const { t } = useTranslation();
  const userData = useSelector((globalState: RootStore) => {
    return globalState.core.userData;
  });

  const replaceAll = () => {
    netWorking.post(
      netWorking.ReplaceVoters,
      {
        listRejected,
        upDataId,
        reviewedId: userData?._id,
        reviewedByName:
          (userData?.firstName || '') + ' ' + (userData?.lastName || ''),
      },
      () => {
        replacedFun();
        setModalShow(false);
      },
      () => {},
    );
  };

  const reviewList = () => {
    setModalShow(false);
    reviewFun(true);
  };

  const getPluralSingularText = () => {
    if (listRejected.length === 1) {
      return (
        t('settings.votersList.modal.text.duplicate.body.1').substring(
          0,
          t('settings.votersList.modal.text.duplicate.body.1').length - 2,
        ) + ','
      );
    } else {
      return t('settings.votersList.modal.text.duplicate.body.1');
    }
  };

  return (
    <Modal
      show={modalShow}
      onHide={setModalShow}
      backdrop="static"
      keyboard={false}
      size={'lg'}
      className={'md'}
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="static-modal"
      centered
    >
      <Modal.Header>
        <Modal.Title className="title-border-bottom">
          {t('settings.votersList.modal.text.duplicate.title')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
          <Row noGutters className="modal-text-row">
            {/* <Dropzone type={type} setRouteFile={setRouteFile}></Dropzone> */}
            <Col xs={12} className="description-col">
              <strong>
                {listRejected.length + ' ' + getPluralSingularText()}
              </strong>
              {' ' + t('settings.votersList.modal.text.duplicate.body.2')}
            </Col>
            <Col xs={12} className="question-col">
              {t('settings.votersList.modal.text.duplicate.question')}
            </Col>
          </Row>
        </>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={replaceAll}>
          Remplazar Todas
        </Button>
        <Button variant="primary" onClick={reviewList}>
          Revisar Lista
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalDuplicateAlert;
