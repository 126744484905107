import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Map } from 'react-leaflet';
import L, { LatLngExpression } from 'leaflet';
import Button from 'react-bootstrap/Button';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import '@geoman-io/leaflet-geoman-free';
import LayerControlComponent from '../../../../components/sub-component/layerControl';
import { useDispatch } from 'react-redux';
import { updateLicense } from '../../../../redux/campaigns/campaignsActions';
import {
  Campaign,
  City,
  SET_SELECTED_LICENSE_CITY,
} from '../../../../redux/campaigns/campaignsTypes';

interface MapLimitsProps {
  licenseSelected?: Campaign;
  cityLicense?: City;
}

// tslint:disable-next-line: prefer-const
let defaultData: any;

const MapLimits: React.FunctionComponent<MapLimitsProps> = ({
  licenseSelected,
  cityLicense,
}: MapLimitsProps) => {
  const { t, i18n } = useTranslation();
  const leafletMapRef = React.useRef<Map>(null);
  const defaultPosition = {
    lat: 32.7157431,
    lng: -117.1888535,
    zoom: 11,
  };
  const dispatch = useDispatch();
  const [editMode, setEditMode] = React.useState(false);
  const [polygonData, setPolygonData] = React.useState(defaultData);
  const [pm, setPm] = React.useState(defaultData);
  const [zoom, setZoom] = React.useState(defaultPosition.zoom);
  const [center, setCenter] = React.useState([
    defaultPosition.lat,
    defaultPosition.lng,
  ] as LatLngExpression);

  useEffect(() => {
    const mapElement = leafletMapRef.current?.leafletElement;
    if (
      mapElement &&
      cityLicense &&
      cityLicense.geoLimits &&
      cityLicense.geoLimits !== null
    ) {
      mapElement.eachLayer((layer: any) => {
        if (
          layer._url === undefined &&
          // layer._container === undefined
          layer._events !== undefined &&
          layer instanceof L.Layer
        )
          layer.remove();
      });
      AddLayer(cityLicense.geoLimits.geometry);
    }
  }, [cityLicense]);

  useEffect(() => {
    if (cityLicense) {
      if (cityLicense.defaultCenter !== undefined)
        setCenter(cityLicense.defaultCenter as LatLngExpression);
      setZoom(cityLicense.defaultZoom);
    }
  }, [cityLicense]);

  const AddLayer = (polygon: any) => {
    const mapElement = leafletMapRef.current?.leafletElement;
    if (mapElement && polygon !== null) {
      L.geoJSON(polygon, {
        onEachFeature: (feature, layer) => {
          layer.on('pm:edit', (e) => {
            if (e.target && e.target.pm) {
              const lay = e.layer.toGeoJSON();
              setPolygonData(lay);
            }
          });
          layer.on('pm:cut', function (e) {
            if (e.target && e.target.pm) {
              layer.off('pm:edit');
            }
          });
          layer.on('click', function (e) {
            setPm(e.target.pm);
            e.target.pm.enable();
            setEditMode(true);
          });
          layer.addTo(mapElement);
        },
      });
    }
  };

  const onSave = () => {
    if (licenseSelected && cityLicense) {
      const license = licenseSelected;
      const city = cityLicense;
      const cities: any[] = [];
      license.cities.forEach((e) => {
        if (e._id === city._id) {
          cities.push({
            ...e,
            geoLimits: polygonData,
          });
        } else {
          cities.push(e);
        }
      });
      license.cities = [...cities];
      dispatch(
        updateLicense(
          license,
          (data: any) => {
            let city2: any = null;
            data.cities.forEach((e: City) => {
              if (e._id === city._id) {
                city2 = e;
              }
            });
            dispatch({
              type: SET_SELECTED_LICENSE_CITY,
              licenseSelected: data,
              cityLicense: city2,
            });
            pm.disable();
            setEditMode(false);
          },
          (err: any) => {
            console.log(err);
          },
        ),
      );
    }
  };

  const onCancel = () => {
    setEditMode(false);
    setPolygonData(defaultData);
  };

  useEffect(() => {
    if (leafletMapRef.current) {
      const mapElement = leafletMapRef.current.leafletElement;

      (mapElement as any).pm.setGlobalOptions({ pmIgnore: false });
      (mapElement as any).pm.setLang(i18n.language);

      (mapElement as any).pm.addControls({
        drawMarker: false,
        drawCircle: false,
        drawCircleMarker: false,
        drawPolyline: false,
        drawRectangle: false,
        drawPolygon: false,
        editMode: false,
        dragMode: false,
        cutPolygon: false,
        removalMode: false,
        zoomMode: false,
      });
      mapElement.off('pm:create');
      mapElement.on('pm:create', (e: any) => {
        if (e.target && e.target.pm) {
          // addNewLayer(e.layer.toGeoJSON());
        }
      });
      mapElement.off('pm:remove');
      mapElement.on('pm:remove', (e: any) => {
        // (mapElement as any).pm.addControls({
        //   drawPolygon: true,
        //   removalMode: false,
        // });
        // onPolygonRemoved();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leafletMapRef.current, i18n.language]);

  const [style, updateStyle] = useState(`
    .leaflet-pm-toolbar .leaflet-pm-icon-polygon::after {
      content: '${t('territorial.goals.lbl.draw')}';
    } .button-container.active .leaflet-pm-actions-container .action-removeLastVertex::after{
      content: '${t('territorial.goals.lbl.undo')}';
    }
  `);

  useEffect(() => {
    updateStyle(`
      .leaflet-pm-toolbar .leaflet-pm-icon-polygon::after {
        content: '${t('territorial.goals.lbl.draw')}';
      }
      .button-container.active .leaflet-pm-actions-container .action-removeLastVertex::after{
        content: '${t('territorial.goals.lbl.undo')}';
      }
    `);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  return (
    <>
      <div className="map-box">
        <Map
          ref={leafletMapRef}
          center={center}
          zoom={zoom}
          bounds={undefined}
          boundsOptions={{ padding: [0, 0] }}
        >
          {editMode && (
            <div className="buttons-box">
              <Button
                variant="outline-dark"
                onClick={() => {
                  onCancel();
                }}
              >
                Cancel
              </Button>

              <Button
                // disabled={validBtn}
                variant="primary"
                onClick={() => {
                  onSave();
                }}
              >
                Save
              </Button>
            </div>
          )}
          <style>{style}</style>
          <LayerControlComponent checked={'Street'}></LayerControlComponent>
        </Map>
      </div>
    </>
  );
};

export default MapLimits;
