import React from 'react';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import '@geoman-io/leaflet-geoman-free';
import {
  AddActionIcon,
  Trash,
  EditIcon,
  InactiveStateIcon,
} from '../../../../assets/svg';
import InputComponent from '../../../../components/sub-component/input';
import Button from 'react-bootstrap/Button';
import Switch from 'react-switch';
import { BsUpload } from 'react-icons/bs';
import {
  Campaign,
  City,
  SET_SELECTED_LICENSE_CITY,
  TimeZone,
} from '../../../../redux/campaigns/campaignsTypes';
import { useDispatch } from 'react-redux';
import { updateLicense } from '../../../../redux/campaigns/campaignsActions';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { Problem } from '../../shared/general/components/problemsView';
import DeleteModalComponent from '../../shared/general/components/deleteDialog';
import * as minimalTimezoneSet from 'compact-timezone-list';
import SelectorFormsComponent from '../../../../components/sub-component/selectorForms';
import TimeField from 'react-simple-timefield';

interface SettingsProps {
  licenseSelected?: Campaign;
  cityLicense?: City;
}
const arrayEmpty: any[] = [
  { es: 'Capturistas', en: 'Volunteers', role: 'CAPTURIST', level: 1 },
  { es: 'Seccionales', en: 'Sectionals', role: 'CAPTURIST_BOSS', level: 2 },
];

// tslint:disable-next-line: prefer-const
let emptyEdit: Problem;
let emptyToAdd: TimeZone;

const transFormTimeZones = (
  timesZones: {
    offset: string;
    label: string;
    tzCode: string;
  }[],
) => {
  return timesZones.map((d) => {
    return {
      _id: d.tzCode,
      offset: d.offset,
      name: d.label + ' ' + d.tzCode,
      label: d.label,
    };
  });
};

const SettingsTab: React.FunctionComponent<SettingsProps> = ({
  licenseSelected,
  cityLicense,
}: SettingsProps) => {
  const [editModules, setEditModules] = React.useState(false);
  const [menuOption, setMenuOption] = React.useState(-1);
  const [list, setList] = React.useState(
    cityLicense?.capturistUserLevels || arrayEmpty,
  );
  const [counter, setCounter] = React.useState(2);
  const [toggle, setToggle] = React.useState(false);
  const [autoSyncTerritorial, setAutoSyncTerritorial] = React.useState(
    cityLicense?.autoSyncTerritorial || false,
  );
  const [editMode, setEditMode] = React.useState<Problem>(emptyEdit);
  const [toAdd, setToAdd] = React.useState<TimeZone>(
    transFormTimeZones(minimalTimezoneSet.default)[0],
  );
  const [editModeToDelete, setEditModeToDelete] = React.useState<string>('');

  const [editTimes, setEditTimes] = React.useState<{
    endDate: string;
    startDate: string;
  }>({
    endDate: '00:00',
    startDate: '00:00',
  });

  const [deleteItem, setDeleteItem] =
    React.useState<{
      _id: string;
      name: string;
    } | null>(null);
  const [showDelete, setShowDelete] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (counter > 0) setTimeout(() => setCounter(counter - 1), 1000);
    if (counter === 0) {
      if (toggle) {
        setToggle(false);
        onSave(list);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter, toggle]);

  React.useEffect(() => {
    if (cityLicense)
      setEditTimes({
        startDate: cityLicense.startDatePollingStation,
        endDate: cityLicense.endDatePollingStation,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cityLicense]);

  const dispatch = useDispatch();

  const onSave = (capturistUserLevels: string[]) => {
    if (licenseSelected && cityLicense) {
      const license = licenseSelected;
      const city = cityLicense;
      const cities: any = [];
      license.cities.forEach((e) => {
        if (e._id === city._id) {
          cities.push({
            ...e,
            capturistUserLevels,
            autoSyncTerritorial,
          });
        } else {
          cities.push(e);
        }
      });
      license.cities = [...cities];
      dispatch(
        updateLicense(
          license,
          (data: any) => {
            let city2: any = null;
            data.cities.forEach((e: City) => {
              if (e._id === city._id) {
                city2 = e;
              }
            });
            dispatch({
              type: SET_SELECTED_LICENSE_CITY,
              licenseSelected: data,
              cityLicense: city2,
            });

            // setList(city2.capturistUserLevels);
          },
          (err: any) => {
            console.log(err);
          },
        ),
      );
    }
  };

  const onSaveProblems = () => {
    if (licenseSelected && cityLicense) {
      const license = licenseSelected;
      const city = cityLicense;
      const cities: any = [];
      const problems: Problem[] = [...city.problemList];
      if (editMode) {
        const foundIndex = problems.findIndex((e) => {
          return e._id === editMode._id;
        });
        if (foundIndex !== -1) {
          problems.splice(foundIndex, 1);
          problems.push(editMode);
        } else {
          problems.push(editMode);
        }
        setEditMode(emptyEdit);
      } else if (editModeToDelete !== '') {
        const foundIndex = problems.findIndex((e) => {
          return e._id === editModeToDelete;
        });
        if (foundIndex !== -1) problems.splice(foundIndex, 1);
        setEditModeToDelete('');
      }
      license.cities.forEach((e) => {
        if (e._id === city._id) {
          cities.push({
            ...e,
            autoSyncTerritorial,
            problemList: problems,
          });
        } else {
          cities.push(e);
        }
      });
      license.cities = [...cities];
      dispatch(
        updateLicense(
          license,
          (data: any) => {
            let city2: any = null;
            data.cities.forEach((e: City) => {
              if (e._id === city._id) {
                city2 = e;
              }
            });
            dispatch({
              type: SET_SELECTED_LICENSE_CITY,
              licenseSelected: data,
              cityLicense: city2,
            });
            // setList(city2.capturistUserLevels);
          },
          (err: any) => {
            console.log(err);
          },
        ),
      );
    }
  };

  const onSaveTimeZone = (value: TimeZone, toDelete: boolean) => {
    if (licenseSelected && cityLicense) {
      const license = licenseSelected;
      const city = cityLicense;
      const cities: any = [];
      let timeZones: TimeZone[] = [];
      if (city.timeZones && city.timeZones.length > 0)
        timeZones = [...city.timeZones];
      if (!toDelete) {
        const foundIndex = timeZones.findIndex((e) => {
          return e._id === value._id;
        });
        if (foundIndex === -1) {
          timeZones.push(value);
        }
      } else if (toDelete) {
        const foundIndex = timeZones.findIndex((e) => {
          return e._id === value._id;
        });
        if (foundIndex !== -1) timeZones.splice(foundIndex, 1);
      }
      license.cities.forEach((e) => {
        if (e._id === city._id) {
          cities.push({
            ...e,
            autoSyncTerritorial,
            timeZones,
          });
        } else {
          cities.push(e);
        }
      });
      license.cities = [...cities];
      dispatch(
        updateLicense(
          license,
          (data: any) => {
            let city2: any = null;
            data.cities.forEach((e: City) => {
              if (e._id === city._id) {
                city2 = e;
              }
            });
            dispatch({
              type: SET_SELECTED_LICENSE_CITY,
              licenseSelected: data,
              cityLicense: city2,
            });
            // setList(city2.capturistUserLevels);
          },
          (err: any) => {
            console.log(err);
          },
        ),
      );
    }
  };

  const onSaveTime = () => {
    if (licenseSelected && cityLicense) {
      const license = licenseSelected;
      const city = cityLicense;
      const cities: any = [];
      license.cities.forEach((e) => {
        if (e._id === city._id) {
          cities.push({
            ...e,
            startDatePollingStation: editTimes.startDate,
            endDatePollingStation: editTimes.endDate,
          });
        } else {
          cities.push(e);
        }
      });
      license.cities = [...cities];
      dispatch(
        updateLicense(
          license,
          (data: any) => {
            let city2: any = null;
            data.cities.forEach((e: City) => {
              if (e._id === city._id) {
                city2 = e;
              }
            });
            dispatch({
              type: SET_SELECTED_LICENSE_CITY,
              licenseSelected: data,
              cityLicense: city2,
            });
            setEditTimes({
              startDate: city2.startDatePollingStation,
              endDate: city2.endDatePollingStation,
            });
            // setList(city2.capturistUserLevels);
          },
          (err: any) => {
            console.log(err);
          },
        ),
      );
    }
  };

  const validSubmit = () => {
    if (
      editMode &&
      editMode.en &&
      editMode.en !== '' &&
      editMode.es &&
      editMode.es !== '' &&
      editMode.pt &&
      editMode.pt !== ''
    ) {
      return false;
    } else return true;
  };

  const validSubmitTimes = () => {
    if (editTimes.startDate !== '' && editTimes.endDate !== '') {
      return false;
    } else return true;
  };

  const getViewOption = (option: number) => {
    switch (option) {
      case -1:
        return (
          <div className="list-users-box">
            <div className="box-1">
              <div className="title">
                <h2>Levels In Use</h2> <span>(Write as plural)</span>
              </div>
              <div className="box-titles">
                <h2>Spanish</h2>
                <h2>English</h2>
              </div>
              <div className="list">
                {list.map((e, i) => {
                  return (
                    <div className="item" key={i}>
                      <div className="icons">
                        {' '}
                        {i === 0 &&
                          e.role !== 'CAPTURIST' &&
                          e.role !== 'CAPTURIST_BOSS' && (
                            <Trash
                              onClick={() => {
                                const array = [...list];
                                let index = 0;
                                array.forEach((d, i) => {
                                  if (d.level === e.level) index = i;
                                });
                                if (index !== -1) {
                                  array.splice(index, 1);
                                }
                                setList(array);
                                setCounter(2);
                                setToggle(true);
                              }}
                            ></Trash>
                          )}
                      </div>
                      <div className="inputs-boxes">
                        <InputComponent
                          placeholder={'es'}
                          length={100}
                          onChange={(value: string) => {
                            const array = list.map((d) => {
                              if (d.level === e.level) {
                                return { ...e, es: value };
                              } else return d;
                            });
                            setList(array);
                            setCounter(2);
                            setToggle(true);
                          }}
                          title={''}
                          value={e.es}
                          horizontal={false}
                        ></InputComponent>
                        <InputComponent
                          placeholder={'en'}
                          length={100}
                          onChange={(value: string) => {
                            const array = list.map((d) => {
                              if (d.level === e.level) {
                                return { ...e, en: value };
                              } else return d;
                            });
                            setList(array);
                            setCounter(2);
                            setToggle(true);
                          }}
                          title={''}
                          value={e.en}
                          horizontal={false}
                        ></InputComponent>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="box-2">
              <div className="title">
                <h2>Levels Available</h2>
              </div>
              <div className="list">
                <div
                  className="item"
                  onClick={() => {
                    let index = 0;
                    list.forEach((e) => {
                      if (e.level > index) index = e.level;
                    });
                    const array = [
                      {
                        es: 'Custom',
                        en: 'Custom',
                        role: 'CUSTOM',
                        level: index + 1,
                      },
                      ...list,
                    ];
                    setList(array);
                    setCounter(2);
                    setToggle(true);
                  }}
                >
                  <p>{'Add'}</p>
                  <div className="icons">
                    {' '}
                    <AddActionIcon></AddActionIcon>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      case 0:
        return (
          <div className="modules-name-box">
            <div className="title">
              <h2>Naming</h2>
              {!editModules && (
                <EditIcon
                  onClick={() => {
                    setEditModules(true);
                  }}
                ></EditIcon>
              )}
            </div>
            <div className="list-m">
              <div className="item">
                <div className="left">
                  <p>Dashboard:</p>
                </div>
                <div className="right">
                  {!editModules ? (
                    <p>Dashboard</p>
                  ) : (
                    <InputComponent
                      placeholder={'Dashboard'}
                      length={50}
                      onChange={(value: string) => {}}
                      title={''}
                      value={'Dashboard'}
                      horizontal={false}
                    ></InputComponent>
                  )}
                </div>
              </div>
              <div className="item">
                <div className="left">
                  <p>Electoral Analysis:</p>
                </div>
                <div className="right">
                  {' '}
                  {!editModules ? (
                    <p>Electoral Analysis</p>
                  ) : (
                    <InputComponent
                      placeholder={'Electoral Analysis'}
                      length={50}
                      onChange={(value: string) => {}}
                      title={''}
                      value={'Electoral Analysis'}
                      horizontal={false}
                    ></InputComponent>
                  )}
                </div>
              </div>
              <div className="item">
                <div className="left">
                  <p>Territorial:</p>
                </div>
                <div className="right">
                  {!editModules ? (
                    <p>Territorial:</p>
                  ) : (
                    <InputComponent
                      placeholder={'Territorial'}
                      length={50}
                      onChange={(value: string) => {}}
                      title={''}
                      value={'Territorial'}
                      horizontal={false}
                    ></InputComponent>
                  )}
                </div>
              </div>
              <div className="item">
                <div className="left">
                  {' '}
                  <p>Electoral</p>
                </div>
                <div className="right">
                  {!editModules ? (
                    <p>Electoral</p>
                  ) : (
                    <InputComponent
                      placeholder={'Electoral'}
                      length={50}
                      onChange={(value: string) => {}}
                      title={''}
                      value={'Electoral'}
                      horizontal={false}
                    ></InputComponent>
                  )}
                </div>
              </div>
              {editModules && (
                <div className="buttons-row">
                  <Button
                    variant="outline-dark"
                    onClick={() => {
                      setEditModules(false);
                    }}
                  >
                    Cancel
                  </Button>

                  <Button
                    // disabled={validBtn}
                    variant="primary"
                    onClick={() => {
                      // if (false) {
                      setEditModules(false);
                      // } else null;
                    }}
                  >
                    Save
                  </Button>
                </div>
              )}
            </div>
          </div>
        );
      case 1:
        return (
          <div className="list-problems-box">
            <div className="title">
              <h2>List of Problems</h2>
              <AddActionIcon
                onClick={() => {
                  setEditModules(true);
                  setEditMode({
                    _id: 'TEMP-ID' + Math.floor(Math.random() * 16),
                    en: '',
                    es: '',
                    pt: '',
                  });
                }}
              ></AddActionIcon>
            </div>
            <div className="list">
              {editMode && (
                <div className="item-edit">
                  <div className="inputs">
                    {' '}
                    <InputComponent
                      placeholder={'Robo, Agua...'}
                      length={250}
                      onChange={(value: string) => {
                        setEditMode((prev) => {
                          return { ...prev, es: value };
                        });
                      }}
                      title={'Spanish'}
                      value={editMode.es}
                      horizontal={false}
                    ></InputComponent>{' '}
                    <InputComponent
                      placeholder={'Theft, Water...'}
                      length={250}
                      onChange={(value: string) => {
                        setEditMode((prev) => {
                          return { ...prev, en: value };
                        });
                      }}
                      title={'English'}
                      value={editMode.en}
                      horizontal={false}
                    ></InputComponent>{' '}
                    <InputComponent
                      placeholder={'Roubou, Água...'}
                      length={250}
                      onChange={(value: string) => {
                        setEditMode((prev) => {
                          return { ...prev, pt: value };
                        });
                      }}
                      title={'Portuguese'}
                      value={editMode.pt}
                      horizontal={false}
                    ></InputComponent>
                  </div>
                  <div className="buttons">
                    <div className="buttons-row">
                      <Button
                        variant="outline-dark"
                        onClick={() => {
                          // setEditModules(false);
                          setEditMode(emptyEdit);
                        }}
                      >
                        Cancel
                      </Button>

                      <Button
                        disabled={validSubmit()}
                        variant="primary"
                        onClick={() => {
                          // if (false) {
                          // setEditModules(false);
                          // } else null;
                          if (!validSubmit()) {
                            onSaveProblems();
                          }
                        }}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </div>
              )}
              {cityLicense?.problemList.map((e: Problem, i) => {
                return (
                  <div className="item" key={i}>
                    <p>{e.en}</p>
                    <div className="icons">
                      {' '}
                      <Trash
                        onClick={() => {
                          setShowDelete(true);
                          setDeleteItem({
                            _id: e._id,
                            name: e.en,
                          });
                          setEditModeToDelete(e._id);
                        }}
                      ></Trash>
                      <EditIcon
                        onClick={() => {
                          setEditMode(e);
                        }}
                      ></EditIcon>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      case 2:
        return (
          <div className="list-timezone-box">
            <div className="title">
              <h2>Hours to open and close of D Day</h2>
            </div>
            <div className="times-box">
              <div className="inputs">
                {' '}
                <div className="time-input-box">
                  <h2>Start Date (24 hour)</h2>
                  <TimeField
                    value={editTimes.startDate}
                    onChange={(event: any, value: any) => {
                      setEditTimes((prev) => {
                        return { ...prev, startDate: value };
                      });
                    }}
                  />{' '}
                </div>
                <div className="time-input-box">
                  <h2>End Date (24 hour)</h2>
                  <TimeField
                    value={editTimes.endDate}
                    onChange={(event: any, value: any) => {
                      setEditTimes((prev) => {
                        return { ...prev, endDate: value };
                      });
                    }}
                  />
                </div>
              </div>
              <div className="buttons-row">
                <Button
                  disabled={validSubmitTimes()}
                  variant="primary"
                  onClick={() => {
                    // if (false) {
                    // setEditModules(false);
                    // } else null;
                    if (!validSubmitTimes()) {
                      onSaveTime();
                    }
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
            <div className="selector-of-timezone">
              {/* { offset: '-11:00', label: '(GMT-11:00) Niue', tzCode: 'Pacific/Niue' }, */}
              <SelectorFormsComponent
                options={transFormTimeZones(minimalTimezoneSet.default)}
                title={'TimeZones'}
                defaultSelector={toAdd?._id}
                horizontal={false}
                dataValue={'name'}
                fun={(data: any) => {
                  // setToSave((prevState: any) => ({
                  //   ...prevState,
                  //   partyId: data,
                  // }));
                  setToAdd(
                    transFormTimeZones(minimalTimezoneSet.default).find(
                      (d) => d._id === data,
                    ) || emptyToAdd,
                  );
                }}
              ></SelectorFormsComponent>
              <AddActionIcon
                className="svg-add"
                onClick={() => {
                  onSaveTimeZone(toAdd, false);
                  // setEditModules(true);
                  // setEditMode({
                  //   _id: 'TEMP-ID' + Math.floor(Math.random() * 16),
                  //   en: '',
                  //   es: '',
                  //   pt: '',
                  // });
                }}
              ></AddActionIcon>
            </div>
            <div className="list">
              {cityLicense?.timeZones?.map((e: TimeZone, i) => {
                return (
                  <div className="item" key={i}>
                    <p>{e.name}</p>
                    <div className="icons">
                      {' '}
                      <Trash
                        onClick={() => {
                          onSaveTimeZone(e, true);
                        }}
                      ></Trash>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      case 3:
        return (
          <div className="my-network-box">
            <div className="title">
              <h2>My Network Identifications</h2>
              <AddActionIcon
                onClick={() => {
                  setEditModules(true);
                }}
              ></AddActionIcon>
            </div>
            <div className="list-items">
              <p className="sub-title">
                Identifications requested for search of people
              </p>
              <div className="item">
                <div className="row-1">
                  <div className="ident-box">
                    <h2 className="title-box">Identification 1</h2>
                    <div className="input-box-1">
                      <InputComponent
                        placeholder={'Name of the Identification'}
                        length={50}
                        onChange={(value: string) => {}}
                        title={''}
                        value={''}
                        horizontal={false}
                      ></InputComponent>
                    </div>
                  </div>
                  <div className="num">
                    <h2 className="title-box">Number of digits or letters</h2>
                    <div className="input-box-1">
                      <p>{'('}</p>
                      <InputComponent
                        placeholder={'ej, 4 last digits'}
                        length={50}
                        onChange={(value: string) => {}}
                        title={''}
                        value={''}
                        horizontal={false}
                      ></InputComponent>
                      <p>{')'}</p>
                    </div>
                  </div>
                </div>
                <div className="row-2">
                  <h2>Image</h2>
                  <div className="upload-box">
                    <BsUpload></BsUpload> <p>Upload</p>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="row-1">
                  <div className="ident-box">
                    <h2 className="title-box">Identification 2</h2>
                    <div className="input-box-1">
                      <InputComponent
                        placeholder={'Name of the Identification'}
                        length={50}
                        onChange={(value: string) => {}}
                        title={''}
                        value={''}
                        horizontal={false}
                      ></InputComponent>
                    </div>
                  </div>
                  <div className="num">
                    <h2 className="title-box">Number of digits or letters</h2>
                    <div className="input-box-1">
                      <p>{'('}</p>
                      <InputComponent
                        placeholder={'ej, 4 last digits'}
                        length={50}
                        onChange={(value: string) => {}}
                        title={''}
                        value={''}
                        horizontal={false}
                      ></InputComponent>
                      <p>{')'}</p>
                    </div>
                  </div>
                </div>
                <div className="row-2">
                  <h2>Image</h2>
                  <div className="upload-box">
                    <BsUpload></BsUpload> <p>Upload</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      case 4:
        return (
          <div className="list-problems-box">
            <div className="title">
              <h2>Settings</h2>
            </div>
            <div className="list">
              <div className="item-settings">
                <h2>AutoSync Territorial</h2>
                <Switch
                  onChange={() => {
                    setAutoSyncTerritorial((prev) => {
                      return !prev;
                    });
                    setCounter(2);
                    setToggle(true);
                  }}
                  checked={autoSyncTerritorial}
                  height={20}
                  width={40}
                  onColor="#2fad74"
                  // onHandleColor="#2693e6"
                  handleDiameter={20}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 5px rgba(0, 0, 0, 0.2)"
                  className="react-switch"
                  id="material-switch"
                ></Switch>
              </div>
            </div>
          </div>
        );
      default:
        break;
    }
  };

  return (
    <>
      <div className="settings-tab">
        <div className="menu-box">
          <div className="title">
            <h2>Settings</h2>
          </div>
          <div className="list-items-menu">
            <div
              className={menuOption === -1 ? 'item active' : 'item'}
              onClick={() => {
                if (menuOption !== -1) {
                  setMenuOption(-1);
                }
              }}
            >
              <p>Territorial User Levels</p>
            </div>
            <div
              className={menuOption === 0 ? 'item active' : 'item'}
              onClick={() => {
                if (menuOption !== 0) {
                  setMenuOption(0);
                }
              }}
            >
              <p>Naming</p>
            </div>
            <div
              className={menuOption === 1 ? 'item active' : 'item'}
              onClick={() => {
                if (menuOption !== 1) {
                  setMenuOption(1);
                }
              }}
            >
              <p>List of Problems</p>
            </div>
            <div
              className={menuOption === 2 ? 'item active' : 'item'}
              onClick={() => {
                if (menuOption !== 2) {
                  setMenuOption(2);
                }
              }}
            >
              <p>Time of D Day</p>
            </div>
            <div
              className={menuOption === 3 ? 'item active' : 'item'}
              onClick={() => {
                if (menuOption !== 3) {
                  setMenuOption(3);
                }
              }}
            >
              <p>My Network Identifications</p>
            </div>
            <div
              className={menuOption === 4 ? 'item active' : 'item'}
              onClick={() => {
                if (menuOption !== 4) {
                  setMenuOption(4);
                }
              }}
            >
              <p>Settings</p>
            </div>
          </div>
        </div>
        <div className="view-box">{getViewOption(menuOption)}</div>
      </div>
      <DeleteModalComponent
        setModalShow={setShowDelete}
        modalShow={showDelete}
        successFun={() => {
          onSaveProblems();
        }}
        toDelete={deleteItem}
        type={'PROBLEM_CITY'}
      ></DeleteModalComponent>
    </>
  );
};

export default SettingsTab;
