import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../redux/Store';

import {
  postGetAreasByUsers,
  putListRoutes,
  postUpdateRouteState,
  getAreasByCoordinator,
} from '../../../redux/routes/RoutesActions';
import {
  postListCapturistTeamsByCity,
  getHeatMapData,
} from '../../../redux/areas/areasActions';
import {
  ROUTES_SELECT_AREA,
  UPDATE_OF_ENABLE,
} from '../../../redux/routes/RoutesTypes';
import { Routes } from '../../../redux/routes/RoutesTypes';
import { TEAM_TYPE } from '../../../redux/RgRc/RgRcTypes';
import { array_move, ROLE } from '../../../utils/utils';

import LayoutBodyComponent from '../../../components/LayoutBody';
import AreaDataBox from './AreaDataBox';
import RoutesMap from './RoutesMap';

import { Map } from 'react-leaflet';
import * as turf from '@turf/turf';
import L from 'leaflet';

interface RoutesCreationProps {
  t: any;
}
// tslint:disable-next-line: prefer-const
let layer: any;
// tslint:disable-next-line: prefer-const
let defaultLayersGeo: any[] = [];
// tslint:disable-next-line: prefer-const
let generalLayersEmpty: Routes[] = [];
// tslint:disable-next-line: prefer-const
let newLayersEmpty: Routes;
// tslint:disable-next-line: prefer-const
let boundEmpty: any;

const RoutesCreation: React.FunctionComponent<RoutesCreationProps> = ({
  t,
}: any) => {
  const citySelected: any = useSelector((globalState: RootStore) => {
    return globalState.core.globalMunicipalitySelected;
  });

  const selectedArea = useSelector(
    (globalState: RootStore) => globalState.routes.selectedArea,
  );
  const createdAreas: Routes[] = useSelector(
    (globalState: RootStore) => globalState.routes.createdAreas,
  );
  const teams = useSelector(
    (globalState: RootStore) => globalState.areas.teams,
  );
  const selectedCoordinator = useSelector(
    (globalState: RootStore) => globalState.areas.selectedCoordinator,
  );

  const [municipalityId, setMunicipalityId] = useState(String);
  const teamLevel = localStorage?.level
    ? parseInt(localStorage?.level) - 1
    : citySelected.capturistUserLevels[0].level;

  const leafletMapRef = React.useRef<Map>(null);
  const [coordinates, setCoordinates] = useState(null);
  const [selected, setSelected] = useState(selectedArea ? selectedArea : layer);
  const [layersGeoJson, setLayersGeoJson] = useState(defaultLayersGeo);
  const [generalLayers, setGeneralLayers] = useState(generalLayersEmpty);
  const [loadData, setLoadData] = React.useState(false);

  const defaultPosition = {
    lat: 32.7157431,
    lng: -117.1888535,
    zoom: 11,
  };
  const position: [number, number] =
    citySelected?.defaultCenter !== undefined
      ? citySelected?.defaultCenter
      : [defaultPosition.lat, defaultPosition.lng];

  const [center, setCenter] = React.useState(position);
  const [zoom, setZoom] = React.useState(
    citySelected?.defaultZoom !== undefined
      ? citySelected?.defaultZoom
      : defaultPosition.zoom,
  );
  const [listBounds, setListBounds] = useState(defaultLayersGeo);
  const [bounds, setBounds] = useState(boundEmpty);

  const dispatch = useDispatch();
  // useEffect(() => {
  //   if (!loadData2 && userData?.role !== ROLE.CAPTURIST_BOSS) {
  //     if (team) getTeamsData(team.members);
  //     setLoadData2(true);
  //   }
  // }, [loadData2]);

  const getBounds = (list: string[]) => {
    var bounds = L.latLngBounds([]);
    listBounds.forEach((e) => {
      if (e.members.some((v: any) => list.indexOf(v) !== -1)) {
        bounds.extend(e.bound);
      }
    });
    if (bounds.isValid()) setBounds(bounds);
    else {
      // const center2 = L.latLng(center);
      // const bounds2 = center2.toBounds(50000);
      // if (bounds2.isValid()) setBounds(bounds2);
      // setUpdateCenterAndZoom(Math.random());
    }
  };

  useEffect(() => {
    if (!loadData) {
      if (localStorage.role !== ROLE.ADMIN_BOSS)
        dispatch(getAreasByCoordinator(localStorage.id));
      if (listBounds.length > 0) {
        const members: any[] = teams.reduce(
          // eslint-disable-next-line
          (a: any, o: any) => (o.bossId && a.push(o.bossId), a),
          [],
        );

        if (localStorage.role === ROLE.CAPTURIST_BOSS) {
          if (selectedCoordinator?._id) getBounds([selectedCoordinator._id]);
          else getBounds([...members]);
          setLoadData(true);
        } else {
          if (localStorage.id) getBounds([localStorage.id]);
          else getBounds([...members]);
          setLoadData(true);
        }
      }
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listBounds]);

  const changeRouteState = (routeValue: any) => {
    dispatch(
      postUpdateRouteState(
        routeValue,
        (data: any) => {
          if (data.success) {
            console.log('si actualizo');
            setSelected((prevState: any) => {
              prevState?.pm.disable();
              return null;
            });

            dispatch({
              type: UPDATE_OF_ENABLE,
              payload: routeValue,
            });
          }

          // if (teams.length > 0)
          //   dispatch(
          //     postGetAreasByUsers(
          //       teams.reduce(
          //         (a: any, o: any) => (o.bossId && a.push(o.bossId), a),
          //         [],
          //       ),
          //     ),
          //   );
        },
        (error: any) => {},
      ),
    );
  };

  const goUpList = (_id: string, howMuch: number) => {
    let index = -1;
    const value = layersGeoJson.find((e: Routes, i: number) => {
      if (e._id === _id) index = i;
      return e._id === _id;
    });
    if (value) {
      if (layersGeoJson.length > index + howMuch) {
        const result = polygonProcess(
          array_move(layersGeoJson, index, index + howMuch),
          value,
          index + howMuch,
          true,
        );
        const array: any[] = [];
        layersGeoJson.forEach((e, i) => {
          if (i === index + howMuch) {
            if (e._id !== result._id) array.push(createLayer(e, array, i));
            array.push({ ...result, indexValue: i });
          } else {
            if (e._id !== result._id) array.push(createLayer(e, array, i));
          }
        });
        setLayersGeoJson(array);
        updateArray(array);
      }
    }
  };

  const goDownList = (_id: string, howMuch: number) => {
    let index = -1;
    console.log('');
    const value = layersGeoJson.find((e: Routes, i: number) => {
      if (e._id === _id) index = i;
      return e._id === _id;
    });
    if (value) {
      if (layersGeoJson.length > index - howMuch && index - howMuch >= 0) {
        const result = polygonProcess(
          layersGeoJson,
          value,
          index - howMuch,
          true,
        );
        const array: any[] = [];
        layersGeoJson.forEach((e, i) => {
          if (i === index - howMuch) {
            array.push({ ...result, indexValue: i });
            if (e._id !== result._id) array.push(createLayer(e, array, i));
          } else {
            if (e._id !== result._id) array.push(createLayer(e, array, i));
          }
        });
        setLayersGeoJson(array);
        updateArray(array);
      }
    }
  };

  const polygonProcess = (
    layersGeoJson: any,
    newLayer: any,
    index: number,
    down: boolean,
  ) => {
    let value = {
      ...newLayer,
    };

    const array: any[] = [];
    try {
      layersGeoJson.forEach((e: any, i: number) => {
        if (down) {
          if (i < index && i !== index)
            array.push({
              type: e.type,
              geometry: e.originalCoordinates,
            } as any);
        } else if (i < index && i !== index) array.push(e);
      });
      if (array.length > 0) {
        const union = turf.union(...array);
        if (union) {
          let intersect;

          intersect = turf.difference(
            {
              type: value.type,
              geometry: value.originalCoordinates,
            } as any,
            union,
          );
          if (
            intersect &&
            intersect.geometry &&
            intersect.geometry.type === 'Polygon'
          )
            value = {
              ...value,
              geometry: {
                ...value.geometry,
                coordinates:
                  intersect && intersect.geometry
                    ? intersect.geometry.coordinates
                    : value.originalCoordinates.coordinates,
              },
            };
        }
      } else {
        value = {
          ...value,
          geometry: {
            ...value.geometry,
            coordinates: value.originalCoordinates.coordinates,
          },
        };
      }
    } catch (error) {
      console.log(error);
      value = { ...newLayer, originalCoordinates: newLayer.geometry };
    }
    return value;
  };

  const updateArray = (array: any) => {
    const list: any[] = [];
    array.forEach((e: any, index: number) => {
      if (e && e._id.length > 6)
        if (e.geometry.coordinates !== undefined) {
          list.push({
            _id: e._id,
            coordinates: e.geometry.coordinates,
            originalCoordinates: e.originalCoordinates,
            indexValue: index,
          });
        } else {
          list.push({
            _id: e._id,
            coordinates: e.originalCoordinates,
            originalCoordinates: e.originalCoordinates,
            indexValue: index,
          });
        }
    });
    dispatch(
      putListRoutes(
        list,
        (data: any) => {
          setSelected((prevState: any) => {
            prevState?.pm.disable();
            return null;
          });
          if (teams.length > 0)
            dispatch(
              postGetAreasByUsers(
                teams.reduce(
                  // eslint-disable-next-line
                  (a: any, o: any) => (o.bossId && a.push(o.bossId), a),
                  [],
                ),
              ),
            );
        },
        (error: any) => {},
      ),
    );
  };

  const createLayer = (newLayer: any, layersGeoJson: any, i: number) => {
    if (newLayer) {
      let arrayGeneral: any[] = [];
      // if (localStorage.role === ROLE.ADMIN_BOSS) {
      //   arrayGeneral.push({
      //     type: 'Feature',
      //     ...citySelected?.geoLimits,
      //   });
      // } else
      //   arrayGeneral = generalLayers.map((e: Routes) => {
      //     return {
      //       type: 'Feature',
      //       geometry: {
      //         type: 'Polygon',
      //         coordinates: e.coordinates,
      //       },
      //     };
      //   });
      let value = {
        ...newLayer,
        indexValue: i,
      };
      try {
        if (layersGeoJson.length > 0) {
          const union = turf.union(...layersGeoJson);
          if (union) {
            let intersect;

            intersect = turf.difference(
              {
                type: value.type,
                geometry: value.originalCoordinates,
              } as any,
              union,
            );
            if (
              intersect &&
              intersect.geometry &&
              intersect.geometry.type === 'Polygon'
            )
              value = {
                ...value,
                geometry: {
                  ...value.geometry,
                  coordinates:
                    intersect && intersect.geometry
                      ? intersect.geometry.coordinates
                      : value.geometry.coordinates,
                },
              };
          }
        } else {
          value = {
            ...value,
            geometry: {
              ...value.geometry,
              coordinates: value.originalCoordinates.coordinates,
            },
          };
        }
        if (arrayGeneral.length > 0) {
          const union2 = turf.union(...arrayGeneral);
          if (union2) {
            let intersect2 = null;
            if (union2.geometry?.type === 'MultiPolygon') {
              union2.geometry?.coordinates.forEach((e: any) => {
                const valueInter = turf.intersect(
                  {
                    type: 'Feature',
                    geometry: {
                      type: 'Polygon',
                      coordinates: e,
                    },
                  } as any,
                  {
                    type: value.type,
                    geometry: value.geometry,
                  } as any,
                );
                if (valueInter !== null) {
                  if (
                    valueInter &&
                    valueInter.geometry &&
                    valueInter.geometry.type === 'Polygon'
                  )
                    value = {
                      ...value,
                      geometry: {
                        ...value.geometry,
                        coordinates:
                          valueInter && valueInter.geometry
                            ? valueInter.geometry.coordinates
                            : value.geometry.coordinates,
                      },
                    };
                }
              });
            } else {
              intersect2 = turf.intersect(union2 as any, value);
            }
            if (
              intersect2 &&
              intersect2.geometry &&
              intersect2.geometry.type === 'Polygon'
            )
              value = {
                ...value,
                geometry: {
                  ...value.geometry,
                  coordinates:
                    intersect2 && intersect2.geometry
                      ? intersect2.geometry.coordinates
                      : value.geometry.coordinates,
                },
              };
          }
          if (union2.geometry?.type === 'MultiPolygon') {
            let result = false;
            union2.geometry?.coordinates.forEach((e: any) => {
              const valueInter = turf.intersect(
                {
                  type: 'Feature',
                  geometry: {
                    type: 'Polygon',
                    coordinates: e,
                  },
                } as any,
                {
                  type: value.type,
                  geometry: value.geometry,
                } as any,
              );
              if (valueInter !== null && !result) {
                result = true;
              } else {
                if (!result) result = false;
              }
            });
            if (!result) value = newLayersEmpty;
          } else {
            if (
              turf.intersect(union2, {
                type: value.type,
                geometry: value.geometry,
              } as any) === null
            ) {
              value = newLayersEmpty;
            }
          }
        }
      } catch (error) {
        console.log(error);
        value = { ...newLayer, originalCoordinates: newLayer.geometry as any };
      }
      return value;
    }
  };

  const buildParamsCapturistTeamsByCity = (
    municipalityId: string,
    isFirst: boolean,
  ) => {
    let requestObject;
    requestObject = {
      municipalityId: municipalityId,
      isFirst: isFirst,
      teamLevel: teamLevel,
    };
    return requestObject;
  };

  useEffect(() => {
    if (createdAreas.length > 0) {
      const layersGeoJsonL: any[] = [];
      if (layersGeoJson.length > 0) {
        for (const layer of layersGeoJson) {
          const value = createdAreas.find((e) => {
            return e._id === layer._id;
          });
          if (value) {
            layersGeoJsonL.push({
              ...value,
              color: layer.color,
              colorBase: layer.colorBase,
              _id: value._id,
              geometry: { type: 'Polygon', coordinates: value.coordinates },
              properties: {},
              type: 'Feature',
            });
          } else {
            layersGeoJsonL.push({ ...layer });
          }
        }
      } else {
        for (const value of createdAreas) {
          // const r1 = (Math.random() * 255).toFixed(0);
          // const r2 = (Math.random() * 255).toFixed(0);
          // const r3 = (Math.random() * 255).toFixed(0);
          // const color = `rgba(${r1},${r2},${r3},0.5)`;
          // const colorBase = `rgba(${r1},${r2},${r3},1)`;
          const colorActive = `rgba(78, 205, 196, .5)`;
          const colorBaseActive = `rgba(78, 205, 196, 1)`;
          const colorInActive = `rgba(253, 227, 167, .5)`;
          const colorBaseInActive = `rgba(253, 227, 167, 1)`;
          if (value.members.indexOf(selectedCoordinator._id) !== -1) {
            layersGeoJsonL.push({
              ...value,
              color: colorActive,
              colorBase: colorBaseActive,
              _id: value._id,
              geometry: { type: 'Polygon', coordinates: value.coordinates },
              properties: {},
              type: 'Feature',
            });
          } else {
            layersGeoJsonL.push({
              ...value,
              color: colorInActive,
              colorBase: colorBaseInActive,
              _id: value._id,
              geometry: { type: 'Polygon', coordinates: value.coordinates },
              properties: {},
              type: 'Feature',
            });
          }
        }
      }

      setLayersGeoJson(layersGeoJsonL);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createdAreas]);

  useEffect(() => {
    if (teams.length > 0) {
      dispatch(
        postGetAreasByUsers(
          teams.reduce(
            // eslint-disable-next-line
            (a: any, o: any) => (o.bossId && a.push(o.bossId), a),
            [],
          ),
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teams]);

  useEffect(() => {
    if (municipalityId) {
      const params = buildParamsCapturistTeamsByCity(municipalityId, true);
      dispatch(postListCapturistTeamsByCity(params));
      dispatch(getHeatMapData(municipalityId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [municipalityId]);

  useEffect(() => {
    dispatch({ type: ROUTES_SELECT_AREA, selectedArea: selected });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  useEffect(() => {
    if (citySelected) {
      setMunicipalityId(citySelected._id);
    }
  }, [citySelected]);

  useEffect(() => {
    if (citySelected) {
      setCenter(
        citySelected?.defaultCenter !== undefined
          ? citySelected?.defaultCenter
          : [defaultPosition.lat, defaultPosition.lng],
      );
      setZoom(
        citySelected?.defaultZoom !== undefined
          ? citySelected?.defaultZoom
          : defaultPosition.zoom,
      );
      // dispatch(getHeatMapData(municipalityId));
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [citySelected]);

  return (
    <Fragment>
      <div className="routes-creation-territorial-container">
        <LayoutBodyComponent>
          <div className="map-list-container">
            <RoutesMap
              coordinates={coordinates}
              setCoordinates={setCoordinates}
              selected={selected}
              setSelected={setSelected}
              layersGeoJson={layersGeoJson}
              setLayersGeoJson={setLayersGeoJson}
              setGeneralLayers={setGeneralLayers}
              generalLayers={generalLayers}
              loadData={() => {
                if (
                  localStorage.role === ROLE.ADMIN_BOSS ||
                  localStorage.role === ROLE.SUPER_ADMIN
                ) {
                  const members: any = [];
                  teams.forEach((e) => {
                    members.push(e.bossId);
                  });
                  //console.log('members', members)
                  dispatch(postGetAreasByUsers(members));
                } else {
                  if (teams.length > 0)
                    dispatch(
                      postGetAreasByUsers(
                        teams.reduce(
                          // eslint-disable-next-line
                          (a: any, o: any) => (o.bossId && a.push(o.bossId), a),
                          [],
                        ),
                      ),
                    );
                }
              }}
              center={center}
              zoom={zoom}
              setBounds={setBounds}
              bounds={bounds}
              setListBounds={setListBounds}
              listBounds={listBounds}
              getBounds={getBounds}
              updateArray={updateArray}
              leafletMapRef={leafletMapRef}
              t={t}
            ></RoutesMap>
          </div>
          <AreaDataBox
            selected={selected}
            setSelected={setSelected}
            layersGeoJson={layersGeoJson}
            setLayersGeoJson={setLayersGeoJson}
            goUpList={goUpList}
            goDownList={goDownList}
            changeRouteState={changeRouteState}
            getBounds={getBounds}
            onHide={() => {
              // console.log(leafletMapRef.current);
              setTimeout(function () {
                window.dispatchEvent(new Event('resize'));
              }, 301);
              leafletMapRef.current?.leafletElement.invalidateSize();
            }}
            t={t}
          ></AreaDataBox>
        </LayoutBodyComponent>
      </div>
    </Fragment>
  );
};

export default RoutesCreation;
