import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Map } from 'react-leaflet';
//import * as turf from '@turf/turf';
import { LatLngExpression } from 'leaflet';

import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import '@geoman-io/leaflet-geoman-free';
import LayerControlComponent from '../../../../components/sub-component/layerControl';
import {
  Campaign,
  City,
  SET_SELECTED_LICENSE_CITY,
} from '../../../../redux/campaigns/campaignsTypes';
import { useDispatch } from 'react-redux';
import { updateLicense } from '../../../../redux/campaigns/campaignsActions';
import Button from 'react-bootstrap/Button';

interface MapZoomProps {
  licenseSelected?: Campaign;
  cityLicense?: City;
}

const MapZoom: React.FunctionComponent<MapZoomProps> = ({
  licenseSelected,
  cityLicense,
}: MapZoomProps) => {
  const { t } = useTranslation();
  const leafletMapRef = React.useRef<Map>(null);
  const defaultPosition = {
    lat: 32.7157431,
    lng: -117.1888535,
    zoom: 11,
  };
  //const position: [number, number] = [defaultPosition.lat, defaultPosition.lng];
  const [editMode, setEditMode] = React.useState(false);
  const [zoom, setZoom] = React.useState(defaultPosition.zoom);
  const [center, setCenter] = React.useState([
    defaultPosition.lat,
    defaultPosition.lng,
  ] as LatLngExpression);

  const dispatch = useDispatch();

  const onMoveEnd = (event: any) => {
    setEditMode(true);
    setCenter(event.target.getCenter() as LatLngExpression);
  };

  const onZoomEnd = (event: any) => {
    setEditMode(true);
    setZoom(event.target.getZoom());
  };

  useEffect(() => {
    if (cityLicense) {
      if (cityLicense.defaultCenter !== undefined)
        setCenter(cityLicense.defaultCenter as LatLngExpression);
      setZoom(cityLicense.defaultZoom);
    }
  }, [cityLicense]);

  const onSave = () => {
    if (licenseSelected && cityLicense) {
      const license = licenseSelected;
      const city = cityLicense;
      const cities: any = [];
      license.cities.forEach((e) => {
        if (e._id === city._id) {
          cities.push({
            ...e,
            defaultZoom: zoom,
            defaultCenter: center,
          });
        } else {
          cities.push(e);
        }
      });
      license.cities = [...cities];
      dispatch(
        updateLicense(
          license,
          (data: any) => {
            console.log(data);
            let city2: any = null;
            data.cities.forEach((e: City) => {
              if (e._id === city._id) {
                city2 = e;
              }
            });
            dispatch({
              type: SET_SELECTED_LICENSE_CITY,
              licenseSelected: data,
              cityLicense: city2,
            });
            setEditMode(false);
          },
          (err: any) => {
            console.log(err);
          },
        ),
      );
    }
  };

  const onCancel = () => {
    setEditMode(false);
    // setPolygonData(defaultData);
  };

  return (
    <>
      <div className="map-box">
        <Map
          ref={leafletMapRef}
          center={center}
          zoom={zoom}
          bounds={undefined}
          boundsOptions={{ padding: [0, 0] }}
          onmoveend={onMoveEnd}
          onzoomend={onZoomEnd}
        >
          {' '}
          {editMode && (
            <div className="buttons-box">
              <Button
                variant="outline-dark"
                onClick={() => {
                  onCancel();
                }}
              >
                {t('submit.cancel')}
              </Button>

              <Button
                // disabled={validBtn}
                variant="primary"
                onClick={() => {
                  onSave();
                }}
              >
                {t('submit.save')}
              </Button>
            </div>
          )}
          <LayerControlComponent checked={'Street'}></LayerControlComponent>
        </Map>
      </div>
    </>
  );
};

export default MapZoom;
