import React from 'react';
import { Polygon } from 'react-leaflet';

import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';

import MapWithGeoman from './MapWithGeoman';

interface RoutesMapProps {
  coordinates: any;
  setCoordinates: any;
  selected: any;
  setSelected: any;
  layersGeoJson: any;
  setLayersGeoJson: any;
  setGeneralLayers: any;
  generalLayers: any;
  loadData: any;
  center: any;
  zoom: any;
  setBounds: any;
  bounds: any;
  setListBounds: any;
  listBounds: any;
  getBounds: any;
  updateArray: any;
  leafletMapRef: any;
  t: any;
}

const RoutesMap: React.FunctionComponent<RoutesMapProps> = ({
  coordinates,
  setCoordinates,
  selected,
  setSelected,
  layersGeoJson,
  setLayersGeoJson,
  setGeneralLayers,
  generalLayers,
  loadData,
  center,
  zoom,
  setBounds,
  bounds,
  setListBounds,
  listBounds,
  getBounds,
  updateArray,
  leafletMapRef,
  t,
}: any) => {
  const onSelectionPolygonAdded = (data: any) => {
    setCoordinates(data);
  };
  const onSelectionPolygonMoved = (data: any) => {
    setCoordinates(data);
  };
  const onSelectionPolygonRemoved = () => setCoordinates(undefined);

  return (
    <div className="map-box-route">
      <MapWithGeoman
        key={coordinates ? 'map-populated' : 'map-empty'}
        className="map"
        center={center}
        zoom={zoom}
        onSelectionPolygonAdded={onSelectionPolygonAdded}
        onSelectionPolygonMoved={onSelectionPolygonMoved}
        onSelectionPolygonRemoved={onSelectionPolygonRemoved}
        selected={selected}
        setSelected={setSelected}
        layersGeoJson={layersGeoJson}
        setLayersGeoJson={setLayersGeoJson}
        setGeneralLayers={setGeneralLayers}
        generalLayers={generalLayers}
        loadData={loadData}
        setBounds={setBounds}
        bounds={bounds}
        setListBounds={setListBounds}
        listBounds={listBounds}
        getBounds={getBounds}
        leafletMapRef={leafletMapRef}
        updateArray={updateArray}
      >
        {coordinates && (
          <Polygon
            color="purple"
            positions={coordinates?.coordinates}
          ></Polygon>
        )}
      </MapWithGeoman>
    </div>
  );
};
export default RoutesMap;
