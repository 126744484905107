import React, { FunctionComponent } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../style.scss';
import StaticModal from '../../../dialogs/StaticModal';
import * as netWorking from '../../../redux/netWorking';
import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Group from 'react-bootstrap/FormGroup';
import Checkbox from 'react-bootstrap/FormCheck';
import Input from 'react-bootstrap/FormControl';
import { WarningIcon } from '../../../assets/svg';

interface ModalIncidenceProps {
  modalShow: any;
  setModalShow: any;
  successFun: any;
  data: any;
  tracking: any;
}

export const ModalIncidence: FunctionComponent<ModalIncidenceProps> = ({
  modalShow,
  setModalShow,
  successFun,
  data,
  tracking,
}: ModalIncidenceProps) => {
  const { t } = useTranslation();
  const [reasonState, setReasonState] = React.useState(0);
  const [reasonInput, setReasonInput] = React.useState('');

  const submitFun = () => {
    netWorking.post(
      netWorking.postMarkPointIncidence,
      {
        _id: tracking._id,
        pointId: data._id,
        status: 2,
        issue: getIssue(),
      },
      () => {
        successFun();
        setModalShow(false);
      },
      () => {},
    );

    // successFun();
    // setModalShow(false);
  };

  const getIssue = () => {
    switch (reasonState) {
      case 0:
        return '0';
      case 1:
        return '1';
      case 2:
        return reasonInput;
      default:
        return '';
    }
  };

  const getValidSubmit = () => {
    if (reasonState <= 1) return false;
    else {
      if (reasonInput !== '' && reasonInput.length > 15) return false;
      else return true;
    }
  };

  return (
    <StaticModal
      show={modalShow}
      title={''}
      closeText={t('submit.cancel')}
      confirmText={t('submit.yes')}
      onHide={() => setModalShow(false)}
      submitFun={submitFun}
      validBtn={getValidSubmit()}
      size="lg"
      sizeClass="md modal-rc"
      customTitle={
        <div className="title-incidence">
          <WarningIcon></WarningIcon>
          <h2>{t('territorial.tracker.modal.lbl.markProblem')}</h2>
        </div>
      }
    >
      <>
        <Container className="body-modal">
          <Row className="information-content">
            <Col xl={12} lg={12} md={12} sm={12} className="mt-2">
              <p className="content-voter-confirm">
                {t('territorial.tracker.modal.lbl.selectProblem')}
              </p>
            </Col>
            <Col xl={12} lg={12} md={12} sm={12} className="">
              <Group className="mt-4">
                <Checkbox
                  custom
                  type="radio"
                  label={t('territorial.tracker.modal.lbl.problem.0')}
                  className="mr-3 deny-voter-radio-button"
                  name="deny-voter-doesntMatch"
                  id="deny-voter-doesntMatch"
                  checked={reasonState === 0}
                  onChange={() => {
                    setReasonState(0);
                  }}
                />
                <Checkbox
                  custom
                  type="radio"
                  label={t('territorial.tracker.modal.lbl.problem.1')}
                  className="mr-3 deny-voter-radio-button"
                  name="deny-voter-incompleteInformation"
                  id="deny-voter-incompleteInformation"
                  checked={reasonState === 1}
                  onChange={() => {
                    setReasonState(1);
                  }}
                />
                <Checkbox
                  custom
                  type="radio"
                  label={t('territorial.tracker.modal.lbl.problem.3')}
                  className="mr-3 deny-voter-radio-button"
                  name="deny-voter-doesntExist"
                  id="deny-voter-doesntExist"
                  checked={reasonState === 2}
                  onChange={() => {
                    setReasonState(2);
                  }}
                />
              </Group>
            </Col>
            <Col
              xl={12}
              lg={12}
              md={12}
              sm={12}
              className={reasonState === 2 ? 'mt-2' : 'mt-2 d-none'}
            >
              <Input
                as="textarea"
                rows={3}
                placeholder={t('territorial.auditory.modal.input.placeholder')}
                onChange={(event) => setReasonInput(event.target.value)}
              />
            </Col>
          </Row>
        </Container>
      </>
    </StaticModal>
  );
};

export default ModalIncidence;
