import React, { useEffect, useState } from 'react';
import * as netWorking from '../../../redux/netWorking';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../redux/Store';
import { useTranslation } from 'react-i18next';

import { Team } from '../../../redux/RgRc/RgRcTypes';
import {
  postRCsTeamDataByRG,
  postGetRCsTeamDataTotal,
  postListTeamMembersLeftSide,
} from '../../../redux/RgRc/RgRcActions';

import { 
  DeleteIcon,
  AddActionIcon,
} from '../../../assets/svg/index';
import SelectorComponent from '../../../components/sub-component/selector';
import StaticModal from '../../../dialogs/StaticModal';
import Container from 'react-bootstrap/Container';
import { Spinner } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';

interface ModalAdministrerProps {
  modalShow: any;
  setModalShow: any;
  selectedTeam: any;
  successFun: any;
  selectedMunicipality: any;
}

let defaultArray: [] = [];

export const ModalAdministrer: React.FunctionComponent<ModalAdministrerProps> = ({
  modalShow,
  setModalShow,
  selectedTeam,
  successFun,
  selectedMunicipality,
}: any) => {
  const citySelected: any = useSelector((globalState: RootStore) => {
    return globalState.core.globalMunicipalitySelected;
  });

  const selectedRG: any = useSelector((globalState: RootStore) => globalState.rgRc.selectedRG);
  const teams: Array<Team> = useSelector((globalState: RootStore) => globalState.rgRc.teams);
  const teamData: Array<Team> = useSelector((globalState: RootStore) => globalState.rgRc.teamData);
  const teamLeftSide: Array<Team> = useSelector((globalState: RootStore) => globalState.rgRc.teamLeftSide);

  const search = useSelector((globalState: RootStore) => globalState.rgRc.search);
  const sort: any = useSelector((globalState: RootStore) => globalState.rgRc.sort);
  const records: any = useSelector((globalState: RootStore) => globalState.rgRc.records);
  const page: any = useSelector((globalState: RootStore) => globalState.rgRc.page);

  const [municipalityId, setMunicipalityId] = useState(String);
  const [RGs, setRGs] = useState(Object);
  const [leftData, setLeftData] = React.useState(Array);
  const [rightData, setRightData] = useState(defaultArray);
  const [loadingRightData, setLoadingRightData] = useState(false);
  const [teamRightId, setTeamRightId] = useState('-1');

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const extractSelectedTeamFromTeamsArray = (
    teams: Array<any>,
    selectedTeam: any,
  ): Array<any> => {
    return teams.filter((team) => {
      if (selectedTeam) {
        return team.teamId !== selectedTeam;
      } else {
        return true;
      }
    });
  };

  const buildParamSearchTeamDataByRG = (
    search: string,
    recordsNum: number,
    order: string,
    pageNum: number,
  ) => {
    let requestObject;
    requestObject = {
      selectedId: selectedRG?._id,
      selectedRole: selectedRG?.role,
      municipalityId: municipalityId,
      search: search.length >= 3 ? search : '',
      records: recordsNum,
      order: order,
      page: pageNum,
    };
    return requestObject;
  };

  const buildParamSearchTeamLeftData = () => {
    let requestObject;
    requestObject = {
      coordinatorId: selectedRG?._id,
    };
    return requestObject;
  };

  const getTeamsData = () => {
    const params = buildParamSearchTeamDataByRG(search, records, sort[1], page);
    dispatch(postRCsTeamDataByRG(params));
    dispatch(postGetRCsTeamDataTotal(params));
  };

  const getLeftData = () => {
    const params = buildParamSearchTeamLeftData();
    dispatch(postListTeamMembersLeftSide(params));
  }

  const getMembersWithoutAssign = (municipality = '') => {
    setLoadingRightData(true);
    netWorking.get(
      `${netWorking.getRcsWithoutAssign}/${municipality}`,
      (res: any) => {
        setRightData(res.data.response);
        setLoadingRightData(false);
      },
      () => {
        setLoadingRightData(false);
      },
    );
  };

  const getTeamMembers = (rgId: string, members = null) => {
    setLoadingRightData(true);
    if (!rgId) return;
    const rgTeam = RGs.find(
      (rg: any) => rg._id === rgId,
    );
    netWorking.post(
      netWorking.getCpCbRgRcTeamMembers,
      { users: members || rgTeam!.members },
      (res: any) => {
        if (res && res.data && res.data.response) {
          setRightData(res.data.response);
          setLoadingRightData(false);
        }
      },
      (err: any) => {
        console.error(err);
        setLoadingRightData(false);
      },
    );
  };

  const addFromTeamRequest = (user: any) => {
    if (user && user._id && selectedTeam) {
      netWorking.post(
        netWorking.addCpCbRgRcFromTeam,
        {
          userId: user._id,
          teamId: selectedTeam,
          fromTeamId: teamRightId === '-1' ? '' : teamRightId,
        },
        (res: any) => {
          getTeamsData()
          if (res.data.response && res.data.response.error !== 1) {
            removeFormListNoTeam(res.data.response);
          };
        },
        (err: any) => {
          console.log('Some error ocurred while making transaction', err);
        },
      );
    }
  };

  const removeFromTeamRequest = (user: any) => {
    if (teamRightId === '-1') {
      // remove to no team
      netWorking.post(
        netWorking.removeCpCbRgRcFromTeam,
        { 
          userId: user._id, 
          teamId: selectedTeam,
        },
        (res: any) => {
          getTeamsData()
          if (res.data.response && res.data.response.error !== 1) {
            removeFromListWithTeam(res.data.response);
          };
        },
        () => {},
      );
    } else if (user && user._id && selectedTeam) {
      netWorking.post(
        netWorking.addCpCbRgRcFromTeam,
        { userId: user._id, teamId: teamRightId, fromTeamId: selectedTeam },
        (res: any) => {
          getTeamsData()
          if (res.data.response && res.data.response.error !== 1) {
            removeFromListWithTeam(res.data.response);
          };
        },
        (err: any) => {
          console.log('Some error ocurred while making transaction', err);
        },
      );
    }
  };

  const removeFromListWithTeam = (e: any) => {
    const listNoTeam: any = [];
    const listWithTeam: any = [];

    listNoTeam.push(...rightData);
    if (!listNoTeam.includes(e)) {
      listNoTeam.push(e);
    }
    leftData.forEach((item: any) => {
      if (item._id !== e._id) {
        listWithTeam.push(item);
      }
    });

    setLeftData(() => listWithTeam);
    setRightData(listNoTeam);
  };

  const removeFormListNoTeam = (e: any) => {
    const listNoTeam: any = []; //der
    const listWithTeam: any = []; //izq
    listWithTeam.push(...leftData);

    listWithTeam.push(e);

    rightData.forEach((item: any) => {
      if (item._id !== e._id) {
        listNoTeam.push(item);
      }
    });

    setLeftData(() => listWithTeam);
    setRightData(listNoTeam);
  };

  const searchTeamId = (rgId: string, rgs: Array<any>) => {
    if (rgs == null || rgId === '-1') return '-1';
    const rgTeam = rgs.find(
      (rg) => rg._id === rgId,
    );
    return rgTeam != null ? rgTeam.teamId : '-1';
  };

  useEffect(() => {
    setRGs(
      extractSelectedTeamFromTeamsArray(teams, selectedTeam).map((team) => ({
        _id: team.bossId,
        name: `${team.name || ''}`,
        members: team.members,
        teamId: team.teamId,
      }))
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teams]);

  useEffect(() => {
    setLeftData(teamData);
  }, [teamData]);

  useEffect(() => {
    if (citySelected) {
      setMunicipalityId(citySelected._id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [citySelected]);

  useEffect(() => {
    if(teamLeftSide){
      setLeftData(teamLeftSide);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamLeftSide]);

  useEffect(() => {
    if(municipalityId){
      getLeftData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [municipalityId]);

  return (
    <StaticModal
      show={modalShow}
      title={t('territorial.volunteer.modal.transfer.title')}
      closeText={t('submit.cancel')}
      onHide={() => setModalShow(false)}
      submitFun={() => {}}
      validBtn={false}
      size="xl"
      sizeClass="md modal-rc"
    >
      <>
        <Container className="dialog-administrer-teams">
          <div className="dialog-superior">
            <div className="dialog-organizer-name">
              <div className="dialog-subtitle">
                {t('electoral.polling.table.rg')}
                <span>
                {selectedRG? (' ' + selectedRG.firstName + ' ' + selectedRG.lastName) : ''}
                </span>
              </div>
            </div>
            <div className="dialog-organizer-select">
              <h4>
                {/* <strong> */}
                {t('electoral.polling.modal.transfer.RGs')}
                {/* </strong> */}
              </h4>
              <SelectorComponent
                noOptionTitle={t('select.lbl.none')}
                title={``}
                defaultSelector={
                  RGs.length > 0 ? RGs[0]._id : '-1'
                }
                funOnInit={(defaultRgId: string) => {
                  setLoadingRightData(true);
                  setTeamRightId(
                    searchTeamId(defaultRgId, RGs),
                  );
                  if (defaultRgId === '-1') {
                    // no option value => -1
                    getMembersWithoutAssign(selectedMunicipality);
                  } else {
                    getTeamMembers(defaultRgId);
                  }
                }}
                options={RGs}
                horizontal={true}
                fun={(rgId: string) => {
                  setLoadingRightData(true);
                  setTeamRightId(searchTeamId(rgId, RGs));
                  if (rgId === '-1') {
                    // no option value => -1
                    getMembersWithoutAssign(selectedMunicipality);
                  } else {
                    getTeamMembers(rgId);
                  }
                }}
              ></SelectorComponent>
            </div>
          </div>
          <Row className="list-box-main-row">
            <div className="list-box-main">
                <div className="noGutters">
                  <div className="list-box-team-title">
                    <h3>
                        {`${t(
                          'territorial.volunteer.modal.transfer.subtitle1',
                        )} (${leftData.length}):`}
                    </h3>
                  </div>
                  <div className="list-box-team">
                    {leftData.map((e: any, index: number) => {
                      return toDeleteItem(e, removeFromTeamRequest);
                    }).filter((e: any) => e != null)}
                  </div>
                </div>
            </div>
            <div className="list-box-main">
                <div className="noGutters">
                  <div className="list-box-team-title">
                    <h3>
                        {`${t(
                          'territorial.volunteer.modal.transfer.subtitle2',
                        )} (${rightData.length})`}
                    </h3>
                  </div>
                  <div className="list-box-team">
                    {!loadingRightData ? (
                      rightData
                        .map((e: any) => {
                          return toAddItem(e, addFromTeamRequest, t);
                        })
                        .filter((e: any) => e != null)
                    ) : (
                      <div className="spinner">
                        <span>
                          <Spinner animation="grow" />
                        </span>
                      </div>
                    )}
                  </div>
                </div>{' '}
            </div>
          </Row>
        </Container>
      </>
    </StaticModal>
  );
};

const toDeleteItem = (e: any, fun: any) => {
  return (
    <div key={e._id} className="to-delete-item" onClick={() => fun(e)}>
      <p>{e.firstName + ' ' + e.lastName}</p>
      <DeleteIcon></DeleteIcon>
    </div>
  );
};

const toAddItem = (e: any, fun: any, t: any) => {
  return (
    <div key={e._id} className="to-add-item" onClick={() => fun(e)}>
      <div className="add-icon">
        <AddActionIcon></AddActionIcon>
      </div>
      <p className="name">{e.firstName + ' ' + e.lastName}</p>
    </div>
  );
};

export default ModalAdministrer;
