import React, { useState, createRef, useEffect, Fragment } from 'react';
import { RouteProps } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import { IoMdArrowDropdown } from 'react-icons/io';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { CheckGreenIcon, CloseIcon, See } from '../../assets/svg/index';
import './style.scss';
import InputSearchComponent from './inputSearch';
import { useTranslation } from 'react-i18next';

interface SelectProps extends RouteProps {
  options: any;
  optionsSelected: any;
  listColors: any;
  title: string;
  horizontal: boolean;
  fun: any;
  sendToHide: any;
  dataName?: string;
}

const SearchSelectorComponent: React.FunctionComponent<SelectProps> = ({
  title,
  options,
  optionsSelected,
  listColors,
  horizontal,
  fun,
  sendToHide,
  dataName,
}: any) => {
  const ref = createRef<HTMLDivElement>();
  const { t } = useTranslation();
  const [style, updateStyle] = useState(`
  .dropwwwwwww {
    background-color: #fff;
    border-bottom: 1px solid #eee;
  }
`);

  useEffect(() => {
    updateStyle(`
    .dropwwwwwww {
      background-color: #fff;
      border-bottom: 1px solid #eee;
    }
  `);
  }, [ref]);

  const getUsersHided = (options: any[]) => {
    const array = [];
    for (const item of options) {
      for (const u of item.users) {
        if (
          u.coordinates !== undefined &&
          u.coordinates[0] === -117.18885351 &&
          u.coordinates[1] === 32.71574311
        )
          array.push(u);
      }
    }
    return array.sort((a, b) => a.name.localeCompare(b.name));
  };

  return (
    <div className={horizontal ? 'tags-box-horizontal' : 'tags-box'}>
      <h2>{title}</h2>
      <div className="dropdown-tags">
        <div ref={ref} className="tag-box">
          <Dropdown as={ButtonGroup}>
            <style>{style}</style>
            <div className="tags-items-box">
              {optionsSelected && (
                <div
                  className="tag-user"
                  key={optionsSelected._id}
                  onClick={() => {
                    // fun(e
                  }}
                >
                  <CloseIcon color="white"></CloseIcon>
                  {dataName ? optionsSelected[dataName] : optionsSelected.name}
                </div>
              )}{' '}
              {!optionsSelected && (
                <div
                  className="tag-user"
                  onClick={() => {
                    // fun(e
                  }}
                >
                  <CloseIcon color="white"></CloseIcon>
                  {t('select.lbl.none')}
                </div>
              )}
            </div>

            <Dropdown.Toggle split variant="success" id="dropdown-split-basic">
              <IoMdArrowDropdown></IoMdArrowDropdown>
            </Dropdown.Toggle>

            <Dropdown.Menu
              className="dropwwwwwww"
              style={{
                transform: `translate(${0}px, ${0}px) !important`,
              }}
            >
              {/* <div className="search-item">
                <InputSearchComponent
                  onChange={() => {}}
                  value={''}
                ></InputSearchComponent>
              </div> */}
              {options.map((e: any, index: any) => {
                if (
                  e.users.find((p: any) => {
                    if (p.coordinates === undefined) return p;
                  }) !== undefined
                )
                  return (
                    <Fragment key={index}>
                      <div className={'header-item'}>
                        <h2>
                          {listColors?.find((l: any) => {
                            if (e._id.level === l.level) return l;
                          })
                            ? listColors?.find((l: any) => {
                                if (e._id.level === l.level) return l;
                              })[t('language')]
                            : ''}
                        </h2>
                      </div>
                      {e.users.map((u: any, index2: any) => {
                        if (
                          u.coordinates === undefined ||
                          u.coordinates.length === 0
                        )
                          return (
                            <div className="user-item" key={u._id}>
                              <p
                                onClick={() => {
                                  fun(u);
                                }}
                              >
                                {u.name}
                              </p>
                              {u.coordinates === undefined && (
                                <See
                                  onClick={() => {
                                    sendToHide(u);
                                  }}
                                ></See>
                              )}
                            </div>
                          );
                      })}
                    </Fragment>
                  );
              })}
              <>
                {getUsersHided(options).length > 0 && (
                  <div className={'header-item'}>
                    <h2>{[t('territorial.teams.coverage.lbl.hided')]}</h2>
                  </div>
                )}
                {getUsersHided(options).map((u: any, index: any) => {
                  return (
                    <div className="user-item" key={u._id}>
                      <p
                        onClick={() => {
                          fun(u);
                        }}
                      >
                        {u.name}
                      </p>
                    </div>
                  );
                })}
              </>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default SearchSelectorComponent;
