import React, { useEffect, useState } from 'react';
import './style.scss';
import { useDispatch, useSelector } from 'react-redux';
import LayoutBodyComponent from '../../../../components/LayoutBody';
import Top from './components/top';
import ProblemsView from './components/problemsView';

// tslint:disable-next-line: prefer-const
let emptyEdit: any;

const GeneralSettings: React.FunctionComponent = () => {
  const [loadData, setLoadData] = useState(false);
  const [menuOption, setMenuOption] = React.useState(0);
  const dispatch = useDispatch();
  const getViewOption = (option: number) => {
    switch (option) {
      case 0:
        return <ProblemsView></ProblemsView>;
      default:
        return <></>;
    }
  };
  return (
    <>
      <LayoutBodyComponent>
        <div className="body-general-settings">
          <Top title={'General Settings'} btnText={''} submit={() => {}}></Top>
          <LayoutBodyComponent>
            <div className="menu-box">
              <div className="list-items-menu">
                <div
                  className={menuOption === 0 ? 'item active' : 'item'}
                  onClick={() => {
                    if (menuOption !== 0) {
                      setMenuOption(0);
                    }
                  }}
                >
                  <p>Naming</p>
                </div>
                <div
                  className={menuOption === 1 ? 'item active' : 'item'}
                  onClick={() => {
                    if (menuOption !== 1) {
                      setMenuOption(1);
                    }
                  }}
                >
                  <p>List of Problems</p>
                </div>
                <div
                  className={menuOption === 2 ? 'item active' : 'item'}
                  onClick={() => {
                    if (menuOption !== 2) {
                      setMenuOption(2);
                    }
                  }}
                >
                  <p>My Network Identifications</p>
                </div>
                <div
                  className={menuOption === 3 ? 'item active' : 'item'}
                  onClick={() => {
                    if (menuOption !== 3) {
                      setMenuOption(3);
                    }
                  }}
                >
                  <p>Settings</p>
                </div>
              </div>
            </div>
            <div className="view-box">{getViewOption(menuOption)}</div>
          </LayoutBodyComponent>
        </div>
      </LayoutBodyComponent>
    </>
  );
};

export default GeneralSettings;
