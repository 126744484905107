import { combineReducers } from 'redux';
// import pokemonReducer from "./PokemonReducer";
import { userReducer } from './User/userReducer';
import { uiReducer } from './reducers/uiReducer';
import votersBoxReducer from './votersBox/votersBoxReducer';
import coreReducer from './core/CoreReducer';
import { RgRcReducer } from './RgRc/RgRcReducer';
import trainingsReducer from './trainings/trainingsReducer';
import votersReducer from './voters/votersReducer';
import auditoryReducer from './auditory/AuditoryReducer';
import capturistsReducer from './capturists/capturistsReducer';
import upDataReducer from './updata/UpDataReducer';
import logsReducer from './logs/LogsReducer';
import RoutesReducer from './routes/routesReducer';
import TrackingReducer from './tracking/trackingReducer';
import usersReducer from './users/UsersReducer';
import teamsReducer from './teams/teamsReducer';
import areasReducer from './areas/areasReducer';
import lawyersReducer from './lawyers/lawyersReducer';
import incidenceReducer from './incidence/incidenceReducer';
import dashboardReducer from './dashboard/dashboardReducer';
import { campaignReducer } from './campaigns/campaignsReducer';
import { partyReducer } from './parties/partiesReducer';
import { coreLicenseReducer } from './coreLicense/coreLicenseReducer';
import { wsReducer } from './socket/wsReducer';
import { bingoReducer } from './bingo/bingoReducer';

const appReducer = combineReducers({
  user: userReducer,
  ui: uiReducer,
  votersBox: votersBoxReducer,
  core: coreReducer,
  rgRc: RgRcReducer,
  trainings: trainingsReducer,
  voters: votersReducer,
  auditory: auditoryReducer,
  users: usersReducer,
  capturists: capturistsReducer,
  teams: teamsReducer,
  areas: areasReducer,
  lawyers: lawyersReducer,
  dashboard: dashboardReducer,
  incidence: incidenceReducer,
  upData: upDataReducer,
  logs: logsReducer,
  routes: RoutesReducer,
  tracking: TrackingReducer,
  campaignsManager: campaignReducer,
  party: partyReducer,
  coreLicense: coreLicenseReducer,
  bingo: bingoReducer,
});

const RootReducer = (state: any, action: any) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === 'USER_LOGGED_OUT') {
    state = undefined;
  }

  return appReducer(state, action);
};

export default RootReducer;
