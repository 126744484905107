import React, { Fragment } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./style.scss";
interface ModalProps {
  onHide: any;
  title: string;
  show: any;
  children: any;
  validBtn: boolean;
  submitFun: any;
  closeText: string;
  confirmText: string;
  size?: "sm" | "lg" | "xl";
  sizeClass?: string;
  showConfirmBtn: boolean;
  showCancelBtn: boolean;
}

const StaticModalCustom: React.SFC<ModalProps> = ({
  onHide,
  show,
  title,
  closeText,
  confirmText,
  children,
  validBtn,
  submitFun,
  size,
  sizeClass,
  showConfirmBtn,
  showCancelBtn
}: any) => {
  return (
    <Fragment>
      <Modal
        show={show}
        onHide={onHide}
        backdrop="static"
        keyboard={false}
        size={size || "lg"}
        className={sizeClass || "lg"}
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="static-modal-custom"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
        <Modal.Footer>
          {showCancelBtn &&
            <Button variant="outline-dark" onClick={onHide}>
            {closeText}
            </Button>
          }
          {showConfirmBtn &&
            <Button
              disabled={validBtn}
              variant="primary"
              onClick={!validBtn ? submitFun : null}
            >
              {confirmText}
            </Button>
          }
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default StaticModalCustom;
