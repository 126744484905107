import React from 'react';
import { useTranslation } from 'react-i18next';
import Image from 'react-bootstrap/Image';
import { findFlagUrlByCountryName } from 'country-flags-svg';
import { BsPlus } from 'react-icons/bs';
import { SELECT_COUNTRY } from '../../../../redux/campaigns/campaignsTypes';
import { useDispatch } from 'react-redux';
import AddCountryModalComponent from '../../shared/countries/components/addCountry';
import { getCountries } from '../../../../redux/campaigns/campaignsActions';

interface ListCountriesProps {
  countries?: any[];
  countrySelected?: any;
}

const ListCountries: React.FunctionComponent<ListCountriesProps> = ({
  countries,
  countrySelected,
}: ListCountriesProps) => {
  const { t } = useTranslation();
  const [showAddModal, setShowAddModal] = React.useState(false);
  const dispatch = useDispatch();

  return (
    <>
      <div className="list-countries">
        {countries?.map((e, i) => {
          return (
            <div
              className={
                (countrySelected?.country?._id || '') === e.country._id
                  ? 'item-country active'
                  : 'item-country'
              }
              key={i}
              onClick={() => {
                dispatch({
                  type: SELECT_COUNTRY,
                  _id: e?.country._id,
                });
              }}
            >
              <div className="country-line">
                <Image src={findFlagUrlByCountryName(e?.country.name)} />
                <h3>{e?.country.name}</h3>
              </div>
              <div className="campaigns-line">{e?.count}{' '}{t('admin.modal.lbl.campaigns')}</div>
            </div>
          );
        })}
        <div
          className="item-add-country"
          onClick={() => {
            setShowAddModal(true);
          }}
        >
          <div className="campaigns-line">
            <BsPlus></BsPlus>
            {t('admin.modal.lbl.addCountry')}
          </div>
        </div>
      </div>
      <AddCountryModalComponent
        setModalShow={setShowAddModal}
        modalShow={showAddModal}
        successFun={() => {
          dispatch(getCountries());
        }}
      ></AddCountryModalComponent>
    </>
  );
};

export default ListCountries;
