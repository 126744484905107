import React from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { UploadIcon, UploadLoading, UploadSuccess } from '../../../assets/svg';
import StaticModal from '../../../dialogs/StaticModal';
import * as netWorking from '../../../redux/netWorking';
import { RootStore } from '../../../redux/Store';

interface ModalXLSProps {
  modalShow: any;
  setModalShow: any;
  successFun: any;
}

export const UploadDialog: React.FunctionComponent<ModalXLSProps> = ({
  modalShow,
  setModalShow,
  successFun,
}: any) => {
  const [routeFile, setRouteFile] = React.useState(null);
  const dispatch = useDispatch();

  const [uploadProgress, setUploadProgress] = React.useState(0);
  const [uploadStatus, setUploadStatus] = React.useState('NONE');
  const citySelected = useSelector((globalState: RootStore) => {
    return globalState.core.globalMunicipalitySelected;
  });
  const stateSelected = useSelector((globalState: RootStore) => {
    return globalState.core.globalStateSelected;
  });
  const userData = useSelector((globalState: RootStore) => {
    return globalState.core.userData;
  });
  const { t } = useTranslation();

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    accept:
      '.xls, .xlsx, application/excel, application/vnd.ms-excel, application/x-excel, application/x-msexcel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    onDrop: (acceptedFile: any) => {
      if (acceptedFile.length > 0) {
        let formData = new FormData();
        formData.append('file', acceptedFile[0]);
        setUploadStatus('LOADING');
        netWorking.sendRequest(
          netWorking.UploadVotersXLSAuditory +
            citySelected?._id +
            '/' +
            stateSelected?.name +
            '/' +
            userData?.campaignId,
          formData,
          setUploadProgress,
          setUploadStatus,
          (resp: any) => {
            const url = window.URL.createObjectURL(new Blob([resp.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'auditory_response.xlsx'); //or any other extension
            document.body.appendChild(link);
            link.click();
            link.remove();
          },
          true,
        );
      }
    },
  });

  const hideFun = () => {
    setModalShow(false);
    successFun();
  };

  const submitFun = () => {
    setModalShow(false);
    successFun();
  };

  return (
    <StaticModal
      show={modalShow}
      title={t('XLS.modal.lbl.uploadFile')}
      closeText={
        uploadStatus === 'DONE'
          ? t('submit.close')
          : t('XLS.modal.button.cancel')
      }
      confirmText={t('XLS.modal.button.save')}
      onHide={hideFun}
      submitFun={submitFun}
      showConfirmBtn={false}
      showCancelBtn={uploadStatus === 'LOADING' ? false : true}
      validBtn={routeFile === null}
      size="xl"
      sizeClass="md modal-rc"
    >
      <Container>
        <div className="container dropzone-container">
          {uploadStatus === 'NONE' && (
            <div {...getRootProps({ className: 'dropzone' })}>
              <input {...getInputProps()} />
              <UploadIcon></UploadIcon>
              <h2>{t('XLS.modal.lbl.dropFilesHere')}</h2>
              <p>{t('XLS.modal.lbl.or')}</p>
              <Button type="button" onClick={open} variant="primary">
                {t('XLS.modal.lbl.searchFile')}
              </Button>
            </div>
          )}
          {uploadStatus === 'LOADING' && (
            <div className="loading-file-box">
              <UploadLoading></UploadLoading>
              <h2>{t('XLS.modal.lbl.uploading')}</h2>
              <div className="loading-bar">
                <div className="bar" style={{ width: uploadProgress }}></div>
              </div>
              {/* <p>Loading {uploadProgress}</p> */}
            </div>
          )}
          {uploadStatus === 'DONE' && (
            <div {...getRootProps({ className: 'dropzone done-file-box' })}>
              <input {...getInputProps()} />
              <UploadSuccess></UploadSuccess>
              <h2>{t('XLS.modal.lbl.fileUploaded')}</h2>
              <p>{acceptedFiles[0]?.name}</p>
              {/* <Button type="button" onClick={open} variant="primary">
                {t('XLS.modal.lbl.replace')}
              </Button> */}
            </div>
          )}
          {/* <aside>
        <h4>Files</h4>
        <ul>{files}</ul>
      </aside> */}
        </div>
      </Container>
    </StaticModal>
  );
};

export default UploadDialog;
