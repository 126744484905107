import React from 'react';
import './style.scss';
import { useTranslation } from 'react-i18next';
import { RiCloseLine } from 'react-icons/ri';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

interface ModalProps {
  onHide: any;
  onUnassign?: any;
  onContinue?: any;
  title: string;
  show: any;
  children: any;
  validBtn: boolean;
  submitFun: any;
  closeText: string;
  confirmText?: string;
  unassignText?: string;
  toAssign?: number;
  size?: 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
  sizeClass?: string;
  customTitle?: any;
  showConfirmBtn?: boolean;
  showCancelBtn?: boolean;
  showUnassignBtn?: boolean;
  showToAssign?: boolean;
  showCloseIcon?: boolean;
  showModalFooter?: boolean;
  showModalFooterContinue?: boolean;
}

const StaticModal: React.FunctionComponent<ModalProps> = ({
  onHide,
  onUnassign,
  onContinue,
  show,
  title,
  closeText,
  confirmText,
  unassignText,
  toAssign,
  children,
  validBtn,
  submitFun,
  size,
  sizeClass,
  customTitle,
  showConfirmBtn = true,
  showCancelBtn = true,
  showUnassignBtn = false,
  showToAssign = false,
  showCloseIcon = false,
  showModalFooter = true,
  showModalFooterContinue = false,
}: any) => {
  const { t } = useTranslation();
  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        backdrop="static"
        keyboard={false}
        size={size || 'lg'}
        className={sizeClass || 'lg'}
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="static-modal"
        centered
      >
        <Modal.Header>
          {customTitle ? (
            customTitle
          ) : (
            <Modal.Title className="title-border-bottom">
              <strong>{title}</strong>
            </Modal.Title>
          )}
          {(showUnassignBtn || showCloseIcon) && (
            <div className="close-button" onClick={onHide}>
              <RiCloseLine />
            </div>
          )}
          {showToAssign && (
            <div className="title-assign-main">
              <h2>{t('territorial.volunteer.slider.lbl.remain')}&nbsp;</h2>
              <div className="title-assign-left">{toAssign}</div>
            </div>
          )}
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
        {showModalFooter && (
          <Modal.Footer>
            <div className="unsassign-actions">
              {showUnassignBtn && (
                <Button variant="outline-dark" onClick={onUnassign}>
                  {unassignText}
                </Button>
              )}
            </div>
            <div className="common-actions">
              {showCancelBtn && (
                <Button variant="outline-dark" onClick={onHide}>
                  {closeText}
                </Button>
              )}
              {confirmText != null && showConfirmBtn && (
                <Button
                  disabled={validBtn}
                  variant="primary"
                  onClick={!validBtn ? submitFun : null}
                >
                  {confirmText}
                </Button>
              )}
              {showModalFooterContinue && (
                <Button variant="primary" onClick={onContinue}>
                  {t('submit.continue')}
                </Button>
              )}
            </div>
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
};

export default StaticModal;
