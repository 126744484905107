import {
    LAWYERS_LOADING,
    LAWYERS_FAIL,
    LAWYERS_SUCCESS,
    LAWYERS_COUNT_SUCCESS,
    DeleteLawyers,
    SearchLawyersData,
} from './lawyersTypes';
import * as netWorking from '../netWorking';

export const postCreateUserTeam = (data: any, successFun: any) => {
    return async (dispatch: any) => {
        dispatch({
            type: LAWYERS_LOADING,
        });
        return await netWorking.post(
            netWorking.createUsers,
            data,
            (res: any) => {
            successFun(res);
            },
            (error: any) => {
            return dispatch({
                type: LAWYERS_FAIL,
            });
            },
        );
    };
};
  
export const putUpdateUserTeam = (data: any, successFun: any) => {
    return async (dispatch: any) => {
        dispatch({
            type: LAWYERS_LOADING,
        });
        return await netWorking.put(
            netWorking.updateCreateUsers,
            data,
            (res: any) => {
            successFun();
            },
            (error: any) => {
            return dispatch({
                type: LAWYERS_FAIL,
            });
            },
        );
    };
};

export const postDeleteLawyers = (
    data: DeleteLawyers,
    successFun: any,
) => {
    return async (dispatch: any) => {
        dispatch({
            type: LAWYERS_LOADING,
        });
        return await netWorking.post(
            netWorking.deleteCapturistTeam,
            data,
            (res: any) => {
                successFun();
            },
            (error: any) => {
                return dispatch({
                    type: LAWYERS_FAIL,
                });
            },
        );
    };
};

export const postListLawyersByCity = (data: SearchLawyersData) => {
    return async (dispatch: any) => {
        dispatch({
            type: LAWYERS_LOADING,
        });
        return await netWorking.post(
            netWorking.postByCityLawyers,
            data,
            (res: any) => {
            console.log(res)
            return dispatch({
                type: LAWYERS_SUCCESS,
                payload: res.data.response,
            });
            },
            (error: any) => {
            return dispatch({
                type: LAWYERS_FAIL,
            });
            },
        );
    };
}

export const postGetLayersTotalByCity = (data: SearchLawyersData) => {
    return async (dispatch: any) => {
        return await netWorking.post(
            netWorking.getByCityTotalLawyers,
            data,
            (res: any) => {
            console.log(res)
            return dispatch({
                type: LAWYERS_COUNT_SUCCESS,
                payload: res.data.response,
            });
            },
            (error: any) => {
            return dispatch({
                type: LAWYERS_FAIL,
            });
            },
        );
    };
}