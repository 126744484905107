import React, { Fragment } from 'react';
import './style.scss';

import TopSeparator from '../../components/TopSeparator';
import List from './components/List';

const ProblemsDDay: React.FunctionComponent = () => {
  return (
    <Fragment>
      <TopSeparator></TopSeparator>
      <List></List>
    </Fragment>
  );
};

export default ProblemsDDay;