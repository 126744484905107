import React, { useState, createRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteProps } from 'react-router-dom';
import moment from 'moment';
import './style.scss';

import photoIdPlaceHolderFrontNoINE from '../../assets/img/photo-id-placeholder-front-none.png';
import photoIdPlaceHolderFront from '../../assets/img/photo-id-placeholder-front.png';
import { CloseIcon } from '../../assets/svg/index';
import { IoMdArrowDropdown } from 'react-icons/io';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import Form from 'react-bootstrap/Form';

interface SelectProps extends RouteProps {
  length?: number;
  as?: string;
  rows?: number;
  className: string;
  classNameInput?: string;
  defaultValue: string;
  options?: any;
  optionSelected?: any;
  horizontal: boolean;
  fun: any;
  type?: string;
  regExp?: any;
  placeholder?: string;
  refElement?: any;
  focusAction?: any;
}

export enum SEX {
  MAN,
  WOMAN,
  APACHE_HELICOPTER,
}

const TagsOptionsTextFieldComponent: React.FunctionComponent<SelectProps> = ({
  length = 120,
  as = 'input',
  rows = 2,
  className = '',
  classNameInput = '',
  defaultValue = '',
  options = [],
  optionSelected = '',
  horizontal,
  fun,
  type = 'input',
  regExp,
  placeholder = '',
  refElement,
  focusAction,
}: any) => {
  const { t } = useTranslation();

  let refBody = createRef<HTMLDivElement>();
  const [style, updateStyle] = React.useState(`
    .dropwwwwwww {
      background-color: #fff;
      border-bottom: 1px solid #eee;
      transform: translate(${0}px, ${
    refBody.current?.clientHeight
  }px) !important
    }
  `);

  const [optionsList, setOptionsList] = useState(options);
  const [selectedValueOption, setSelectedValue] = useState(Number);
  const [defaultValueOption, setDefaultValue] = useState();

  const [ineExpirationValueOption, setIneExpirationValue] = useState(String);
  const [ineFrontImageValueOption, setIneFrontImageValue] = useState(String);

  const onChangeOption = (e: any) => {
    if (type === 'sex') {
      setSelectedValue(e);
      setDefaultValue(e);
    }

    if (type === 'default') {
      setSelectedValue(e);
      setDefaultValue(e);
    }

    if (type === 'input') {
      setSelectedValue(e);
      setDefaultValue(e);
    }

    if (type === 'photo') {
      let defaultIneValue = e + '';

      const split = defaultIneValue.split('&');
      let ineExpiration = split[0]?.trim() || '';
      let ineFrontImage = ineExpiration
        ? photoIdPlaceHolderFront
        : photoIdPlaceHolderFrontNoINE;

      setIneExpirationValue(ineExpiration);
      setIneFrontImageValue(ineFrontImage);
    }
  };

  useEffect(() => {
    updateStyle(`
    .dropwwwwwww {
      background-color: #fff;
      border-bottom: 1px solid #eee;
      transform: translate(${0}px, ${
      refBody.current?.clientHeight
    }px) !important
    }
  `);
  }, [refBody]);

  useEffect(() => {
    setSelectedValue(optionSelected);
  }, [optionSelected]);

  useEffect(() => {
    setDefaultValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    let defaultIneValue = defaultValue + '';

    const split = defaultIneValue.split('&');
    let ineExpiration = split[0]?.trim() || '';
    let ineFrontImage = ineExpiration
      ? photoIdPlaceHolderFront
      : photoIdPlaceHolderFrontNoINE;
    setIneExpirationValue(ineExpiration);
    setIneFrontImageValue(ineFrontImage);
  }, [defaultValue]);

  useEffect(() => {
    if (type === 'default') {
      setOptionsList(
        options?.filter((e: any) => {
          return e.value !== defaultValueOption;
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValueOption]);

  return (
    <div
      className={
        horizontal
          ? className + ' tags-box-horizontal'
          : className + ' tags-box'
      }
    >
      {type === 'input' && (
        <div className="dropdown-tags-input">
          <div className="tag-box-input">
            <div className="tags-items-box-input">
              <Form.Control
                className="input-box"
                value={defaultValueOption || ''}
                onChange={(data: any) => {
                  if (
                    regExp === undefined ||
                    regExp.test(data.target.value.trim()) ||
                    data.target.value === ''
                  ) {
                    onChangeOption(data.target.value);
                    fun(data.target.value);
                  }
                }}
                type="text"
                as={as}
                rows={rows}
                maxLength={length}
                placeholder={placeholder}
                ref={refElement}
                onKeyPress={(data: any) => {
                  if (data.key === 'Enter') {
                    if (focusAction) {
                      focusAction();
                    }
                  }
                }}
              ></Form.Control>
            </div>
          </div>
        </div>
      )}
      {type === 'default' && options.length > 0 && (
        <div className="dropdown-tags-input">
          <div className="tag-box-input">
            <div className="tags-items-box-input">
              <Dropdown as={ButtonGroup}>
                <style>{style}</style>
                <div className="tags-items-box">
                  <Form.Control
                    className={'input-box ' + classNameInput}
                    value={defaultValueOption || ''}
                    onChange={(data: any) => {
                      if (
                        regExp === undefined ||
                        regExp.test(data.target.value.trim()) ||
                        data.target.value === ''
                      ) {
                        onChangeOption(data.target.value);
                        fun(data.target.value);
                      }
                    }}
                    type="text"
                    as={as}
                    rows={rows}
                    maxLength={length}
                    placeholder={placeholder}
                    ref={refElement}
                    onKeyPress={(data: any) => {
                      if (data.key === 'Enter') {
                        if (focusAction) {
                          focusAction();
                        }
                      }
                    }}
                  ></Form.Control>
                </div>

                <Dropdown.Toggle
                  split
                  variant="success"
                  id="dropdown-split-basic"
                >
                  <IoMdArrowDropdown></IoMdArrowDropdown>
                </Dropdown.Toggle>

                <Dropdown.Menu
                  className={
                    as === 'textarea'
                      ? 'dropwwwwwww dropdown-textarea-menu'
                      : 'dropwwwwwww dropdown-text-menu'
                  }
                  style={{
                    transform: `translate(${0}px, ${
                      refBody.current?.clientHeight
                    }px) !important`,
                  }}
                >
                  {optionsList.map((e: any, index: any) => {
                    return (
                      <Dropdown.Item
                        key={e._id + '-' + index}
                        onClick={() => {
                          fun(e.value);
                          onChangeOption(e.value);
                        }}
                        className=""
                      >
                        <p> {e.value} </p>
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      )}
      {type === 'default' && options.length < 1 && (
        <div className="dropdown-tags-input">
          <div className="tag-box-input">
            <div className="tags-items-box-input">
              <Form.Control
                className={'input-box ' + classNameInput}
                value={defaultValueOption || ''}
                onChange={(data: any) => {
                  fun(data.target.value);
                  onChangeOption(data.target.value);
                }}
                type="text"
                as={as}
                rows={rows}
                maxLength={length}
                placeholder={placeholder}
                ref={refElement}
                onKeyPress={(data: any) => {
                  if (data.key === 'Enter') {
                    if (focusAction) {
                      focusAction();
                    }
                  }
                }}
              ></Form.Control>
            </div>
          </div>
        </div>
      )}
      {type === 'photo' && (
        <div className="dropdown-tags-input">
          <div className="tag-box-input">
            <div className="tags-items-box-input">
              <Dropdown as={ButtonGroup}>
                <style>{style}</style>
                <div className="tags-items-box">
                  <div className="tag tag-ine">
                    <CloseIcon color="white"></CloseIcon>
                    <Image
                      className="photo-id-fluid"
                      src={ineFrontImageValueOption}
                      fluid
                      onClick={() => {}}
                    />
                    <p>
                      {' '}
                      {ineExpirationValueOption
                        ? moment(ineExpirationValueOption).format('MM/DD/YYYY')
                        : ''}{' '}
                    </p>
                  </div>
                </div>

                <Dropdown.Toggle
                  split
                  variant="success"
                  id="dropdown-split-basic"
                >
                  <IoMdArrowDropdown></IoMdArrowDropdown>
                </Dropdown.Toggle>

                <Dropdown.Menu
                  className="dropwwwwwww dropdown-image-menu"
                  style={{
                    transform: `translate(${0}px, ${
                      refBody.current?.clientHeight
                    }px) !important`,
                  }}
                >
                  <Dropdown.Item
                    key={'no-INE-photo'}
                    onClick={() => {
                      fun('&&');
                      onChangeOption('&&');
                    }}
                    className=""
                  >
                    <Image
                      className="photo-id-fluid"
                      src={photoIdPlaceHolderFrontNoINE}
                      fluid
                      onClick={() => {}}
                    />
                  </Dropdown.Item>
                  {options.map((e: any, index: any) => {
                    return (
                      <Dropdown.Item
                        key={e._id + '-' + index}
                        onClick={() => {
                          fun(e.value);
                          onChangeOption(e.value);
                        }}
                        className=""
                      >
                        <Image
                          className="photo-id-fluid"
                          src={photoIdPlaceHolderFront}
                          fluid
                          onClick={() => {}}
                        />
                        <p>
                          {' '}
                          {e.ineExpirationDate
                            ? moment(e.ineExpirationDate).format('MM/DD/YYYY')
                            : ''}{' '}
                        </p>
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      )}
      {type === 'sex' && (
        <ButtonGroup
          aria-label="ine-status-to-label"
          className="ine-status-group"
        >
          <Button
            variant="primary"
            className={selectedValueOption === 0 ? 'active' : ''}
            onClick={() => {
              fun(0);
              onChangeOption(0);
            }}
          >
            {t('territorial.voters.lbl.sexMale')}
          </Button>
          <Button
            variant="primary"
            className={selectedValueOption === 1 ? 'active' : ''}
            onClick={() => {
              fun(1);
              onChangeOption(1);
            }}
          >
            {t('territorial.voters.lbl.sexFemale')}
          </Button>
          <Button
            variant="primary"
            className={selectedValueOption === 2 ? 'active' : ''}
            onClick={() => {
              fun(2);
              onChangeOption(2);
            }}
          >
            {t('territorial.voters.lbl.sexTrans')}
          </Button>
        </ButtonGroup>
      )}
    </div>
  );
};

export default TagsOptionsTextFieldComponent;
