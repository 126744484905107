import React, { useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../style.scss';
import StaticModal from '../../../dialogs/StaticModal';
import {
  postTrainingCreate,
  postTrainingUpdateFiles,
} from '../../../redux/trainings/TrainingsActions';
import { useDispatch } from 'react-redux';

interface ModalAddProps {
  modalShow: any;
  setModalShow: any;
  training: any;
  filesArray: any;
  setFilesArray: any;
  successFun: any;
  setIsPerformingRequest: any;
  t: any;
}

export const ModalAdd: React.SFC<ModalAddProps> = ({
  modalShow,
  setModalShow,
  training,
  filesArray,
  setFilesArray,
  successFun,
  setIsPerformingRequest,
  t,
}: any) => {
  const [formData, setFormData] = React.useState(Object);
  const dispatch = useDispatch();

  const checkFiles = (stepFiles: any) => {
    let newFormData = { ...formData };
    let imageFile;
    let stepFile;

    if (stepFiles.length === newFormData.stepData.length + 1) {
      imageFile = stepFiles.filter((e: any) => {
        return e.fieldName === 'image';
      });

      stepFile = stepFiles.filter((e: any) => {
        return e.fieldName !== 'image';
      });

      newFormData.uploadData.name = imageFile[0].location;

      stepFile.forEach((e: any) => {
        newFormData.stepData[e.fieldName].file.dataFull = e.location;
      });

      postTrainingCreate(newFormData)
        .then((data) => {
          successFun({ created: true });
          setIsPerformingRequest(false);
        })
        .catch((err) => {
          successFun({ created: false });
          setIsPerformingRequest(false);
        });
    }
  };

  const submitFun = () => {
    if (Object.keys(training).length > 0) {
      setIsPerformingRequest(true);
      setModalShow(false);
      dispatch(
        postTrainingUpdateFiles(
          filesArray,
          (res: any) => {
            console.log('res.files', res.files);
            checkFiles(res.files);
          },
          () => {},
        ),
      );
    }
  };

  useEffect(() => {
    setFormData(training);
  }, [training]);

  return (
    <StaticModal
      show={modalShow}
      title={t('settings.trainings.modal.add.title')}
      closeText={t('settings.trainings.modal.button.cancel')}
      confirmText={t('settings.trainings.modal.button.yesAdd')}
      onHide={() => setModalShow(false)}
      submitFun={submitFun}
      validBtn={false}
      size="sm"
    >
      <Row noGutters className="modal-text-row">
        <Col xs={12} className="description-col">
          {t('settings.trainings.modal.add.body')}
        </Col>
      </Row>
    </StaticModal>
  );
};

export default ModalAdd;
