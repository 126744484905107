import React, { useEffect, useState } from 'react';
//import { useTranslation } from 'react-i18next';
import './style.scss';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../../redux/Store';
//import L from 'leaflet';
import LayoutBodyComponent from '../../../../components/LayoutBody';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import { BsSearch } from 'react-icons/bs';
import { Party } from '../../../../redux/parties/partiesTypes';
import Top from './components/top';
import ListCountries from '../../campaings/components/listCountries';
import { getCountries } from '../../../../redux/campaigns/campaignsActions';
//import { useDropzone } from 'react-dropzone';
import { Map } from 'react-leaflet';
import TopBarS from '../../../components/TopBarSComponent';
import { getMunicipality, getStates } from '../../../../redux/core/CoreActions';
import StateItem from './components/stateItem';
import {
  SET_CITIES_DATA,
  SET_STATES_DATA,
} from '../../../../redux/coreLicense/coreLicenseTypes';
import { useHistory } from 'react-router-dom';
import { SUPER_SHARED_COUNTRIES_CREATION } from '../../../../utils/paths';
import Button from 'react-bootstrap/Button';
//import ListFilterCustomComponent from '../../../../components/ListFilterCustom';
//import ListCities from './components/listCities';
import CityItem from './components/cityItem';
import ListFilterClosedCustomComponent from '../../../../components/ListFilterClosedCustom';

// tslint:disable-next-line: prefer-const
let emptyEdit: any;

const Countries: React.FunctionComponent = () => {
  // const { t } = useTranslation();
  const leafletMapRef = React.useRef<Map>(null);
  const [, setShowAddModal] = useState(false);
  const [loadData, setLoadData] = useState(false);
  const [, setToEdit] = useState(emptyEdit);
  const [search, setSearch] = useState('');
  /*const defaultPosition = {
    lat: 32.7157431,
    lng: -117.1888535,
    zoom: 11,
  };*/
  const dispatch = useDispatch();
  const redirect = useHistory();

  const countrySelected = useSelector((globalState: RootStore) => {
    return globalState.campaignsManager.selectedCountry;
  });

  const countries = useSelector((globalState: RootStore) => {
    return globalState.campaignsManager.countries;
  });

  const states = useSelector((globalState: RootStore) => {
    return globalState.core.stateD;
  });

  const cities = useSelector((globalState: RootStore) => {
    return globalState.core.municipality;
  });

  /*const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    accept: '.json',
    onDrop: (acceptedFile: any) => {
      if (acceptedFile.length > 0) {
        let xhr = new XMLHttpRequest();
        xhr.open('GET', URL.createObjectURL(acceptedFile[0]));
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.responseType = 'json';
        xhr.onload = function () {
          if (xhr.status !== 200) return;
          if (
            xhr.response.type &&
            xhr.response.type === 'FeatureCollection' &&
            xhr.response.features.length > 0
          ) {
            console.log(xhr.response);
            dispatch({
              type: SET_STATES_DATA,
              featureCollection: xhr.response,
            });
            redirect.push(SUPER_SHARED_COUNTRIES_CREATION);
          }

          // AddLayer(xhr.response);
        };
        xhr.send();
      }
    },
  });*/

  const onFileChange = (e: any, type: string) => {
    let files = e.target.files || e.dataTransfer.files;
    if (files.length > 0) {
      const weight = files[0].size / 1024;
      const limit = 10 * 1024;
      if (weight < limit) {
        let isValidExt = false;

        if (files[0].name.match(/\.(json)$/)) {
          isValidExt = true;
        }
        if (!isValidExt) {
          return false;
        } else {
          openFile(files[0], type);
        }
      } else {
        console.log('Máximo 10MB por archivo');
      }
    }
  };

  const openFile = (file: any, type: string) => {
    let xhr = new XMLHttpRequest();
    xhr.open('GET', URL.createObjectURL(file));
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.responseType = 'json';
    xhr.onload = function () {
      if (xhr.status !== 200) return;
      if (
        xhr.response.type &&
        xhr.response.type === 'FeatureCollection' &&
        xhr.response.features.length > 0
      ) {
        console.log(xhr.response);
        dispatch({
          type,
          featureCollection: xhr.response,
        });
        redirect.push(SUPER_SHARED_COUNTRIES_CREATION);
      }
    };
    xhr.send();
  };

  useEffect(() => {
    if (!loadData) {
      if (countrySelected === undefined) dispatch(getCountries(getState));
      setLoadData(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadData]);

  useEffect(() => {
    if (countrySelected !== undefined) {
      getState(countrySelected.country._id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countrySelected]);

  const getState = (id: string) => {
    dispatch(getStates(id, () => {}));
  };

  const getCities = (id: string) => {
    dispatch(getMunicipality(id, () => {}));
  };
  const onEdit = (e?: Party) => {
    setToEdit(e);
    setShowAddModal(true);
  };

  /*const AddLayer = (polygon: any) => {
    const mapElement = leafletMapRef.current?.leafletElement;
    if (mapElement && polygon !== null) {
      var bounds = L.geoJSON(polygon, {
        onEachFeature: (feature, layer) => {
          layer.addTo(mapElement);
          layer.on('pm:edit', (e) => {
            if (e.target && e.target.pm) {
              //const lay = e.layer.toGeoJSON();
              // setPolygonData(lay);
            }
          });
          layer.on('pm:cut', function (e) {
            if (e.target && e.target.pm) {
              layer.off('pm:edit');
            }
          });
          layer.on('click', function (e) {
            // setPm(e.target.pm);
            e.target.pm.enable();
            // setEditMode(true);
          });
        },
      }).getBounds();
      return bounds;
    } else return null;
  };*/

  useEffect(() => {
    if (leafletMapRef.current) {
      const mapElement = leafletMapRef.current.leafletElement;

      (mapElement as any).pm.setGlobalOptions({ pmIgnore: false });
      (mapElement as any).pm.setLang('es');

      (mapElement as any).pm.addControls({
        drawMarker: false,
        drawCircle: false,
        drawCircleMarker: false,
        drawPolyline: false,
        drawRectangle: false,
        drawPolygon: false,
        editMode: false,
        dragMode: false,
        cutPolygon: false,
        removalMode: false,
        zoomMode: false,
      });
      mapElement.off('pm:create');
      mapElement.on('pm:create', (e: any) => {
        if (e.target && e.target.pm) {
          // addNewLayer(e.layer.toGeoJSON());
        }
      });
      mapElement.off('pm:remove');
      mapElement.on('pm:remove', (e: any) => {
        // (mapElement as any).pm.addControls({
        //   drawPolygon: true,
        //   removalMode: false,
        // });
        // onPolygonRemoved();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leafletMapRef.current]);

  return (
    <>
      <LayoutBodyComponent>
        <div className="body-country body-country-list body-states-list">
          <Top
            title={'Countries'}
            btnText={'Add Country'}
            submit={() => {
              onEdit(undefined);
            }}
          ></Top>
          <LayoutBodyComponent>
            <ListCountries
              countries={countries}
              countrySelected={countrySelected}
            ></ListCountries>
            <div className="body-list-campaings countries-body">
              <TopBarS countrySelected={countrySelected}></TopBarS>
              <div className="body-inside">
                <div className="top">
                  <div className="filters-campaigns">
                    <div className={'item-filter-c active'}>
                      <p>{states?.length} States</p>
                    </div>
                  </div>
                  <div className="filters-search-box">
                    <Form className="upload-input">
                      <Form.File
                        id="custom-file"
                        label={''}
                        custom
                        onChange={(value: any) => {
                          onFileChange(value, SET_STATES_DATA);
                        }}
                      />
                      <Button variant="primary">Upload States</Button>
                    </Form>
                    <Form className="upload-input">
                      <Form.File
                        id="custom-file"
                        label={''}
                        custom
                        onChange={(value: any) => {
                          onFileChange(value, SET_CITIES_DATA);
                        }}
                      />
                      <Button variant="primary">Upload Cities</Button>
                    </Form>
                    {/* <Button
                  className="c-button"
                  variant="primary"
                  onClick={() => {}}
                >
                  Add State
                </Button> */}
                    <Form inline className={'tab-search-field-normal'}>
                      <FormControl
                        defaultValue={''}
                        type="text"
                        placeholder={'Search'}
                        className="mr-sm-2"
                        onChange={(data: any) => {
                          setSearch(data.target.value);
                        }}
                      />
                      <div className="icon">
                        <BsSearch />
                      </div>
                    </Form>
                  </div>
                </div>
                <div className="list">
                  {states
                    ?.filter((e) =>
                      e.name.toLowerCase().includes(search.toLowerCase()),
                    )
                    .map((e: any, i: number) => {
                      return (
                        <StateItem
                          key={e._id}
                          state={e}
                          getCities={() => {
                            getCities(e._id);
                          }}
                          getState={() => {
                            if (countrySelected !== undefined) {
                              getState(countrySelected.country._id);
                            }
                          }}
                        ></StateItem>
                      );
                    })}
                </div>
              </div>
            </div>
            <ListFilterClosedCustomComponent
              direction={'right'}
              className={'list-cities-custom-filter'}
              size={'lg-filter'}
            >
              <h2>Cities</h2>
              <div className={'list-cities-custom'}>
                {cities
                  ?.filter((e: any) =>
                    e.name.toLowerCase().includes(search.toLowerCase()),
                  )
                  .map((e: any, i: number) => {
                    return (
                      <CityItem
                        key={e._id}
                        city={e}
                        getState={() => {
                          getCities(e.stateId);
                        }}
                      ></CityItem>
                    );
                  })}
              </div>
            </ListFilterClosedCustomComponent>
          </LayoutBodyComponent>
        </div>
      </LayoutBodyComponent>
    </>
  );
};

export default Countries;
