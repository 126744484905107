import React, { useState, useEffect, Fragment, FunctionComponent } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootStore } from '../redux/Store';
import { useTranslation } from 'react-i18next';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { NavLink } from 'react-router-dom';
import { LeftArrowIcon } from '../assets/svg';

import { TEAMS_COORDINATOR_SUCCESS_ONE } from '../redux/teams/teamsTypes';
import {
  getCoordinatorViewByIdTeam,
  postBreadcumByCoordinator,
} from '../redux/teams/teamsActions';

import { RGRC_RG_SUCCESS_ONE } from '../redux/RgRc/RgRcTypes';
import { getCoordinatorViewByIdRGRC } from '../redux/RgRc/RgRcActions';
import { ROLE } from '../utils/utils';

interface TopTitleProps {
  location: any;
  getTitle: any;
}

const TopTitle: FunctionComponent<TopTitleProps> = ({
  location,
  getTitle,
}: any) => {
  const citySelected: any = useSelector((globalState: RootStore) => {
    return globalState.core.globalMunicipalitySelected;
  });

  const navigationPathTeam: any = useSelector(
    (globalState: RootStore) => globalState.teams.navigationPath,
  );
  const coordinator: any = useSelector(
    (globalState: RootStore) => globalState.teams.coordinator,
  );
  const tabChange: any = useSelector(
    (globalState: RootStore) => globalState.teams.tabChange,
  );
  const totalCampaign: any = useSelector(
    (globalState: RootStore) => globalState.teams.totalCampaign,
  );
  const breadcumCall: any = useSelector(
    (globalState: RootStore) => globalState.teams.breadcumCall,
  );
  const navigationPathRGRC: any = useSelector(
    (globalState: RootStore) => globalState.rgRc.navigationPath,
  );
  const selectedRG: any = useSelector(
    (globalState: RootStore) => globalState.rgRc.selectedRG,
  );

  const userData: any = useSelector((globalState: RootStore) => {
    return globalState.core.userData;
  });

  const [isCoordinatorViewTeam, setIsCoordinatorViewTeam] = useState(false);
  const [isCoordinatorViewRGRC, setIsCoordinatorViewRGRC] = useState(false);

  const [isSubRoute, setIsSubRoute] = useState(false);
  const [fatherRoute, setFatherRoute] = useState('');
  const [fatherTitle, setFatherTitle] = useState('');
  const [childRoute, setChildRoute] = useState('');
  const [childTitle, setChildTitle] = useState('');

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const childNames = [
    {
      name: 'trainings',
      childsRoutes: [{ name: 'edit', title: '' }],
    },
    {
      name: 'voters',
      childsRoutes: [],
    },
  ];
  const getChildRouteName = () => {
    let labelName = '';
    childNames.forEach((route) => {
      if (route.name === fatherRoute) {
        route.childsRoutes.forEach((child) => {
          if (child.name === childRoute) {
            labelName = child.title;
          }
        });
      }
      return labelName;
    });
    setChildTitle(labelName);
  };

  const isSubRouteChecker = (splicedRoute: any) => {
    setFatherRoute(splicedRoute[1]);
    setFatherTitle(getTitle(`/${fatherRoute}`));
    if (splicedRoute.length > 2) {
      setIsSubRoute(true); //true
      setChildRoute(splicedRoute[2]);
      getChildRouteName();
    } else {
      setIsSubRoute(false);
      setChildRoute('');
    }
  };

  const goToView = (_id: string, followValue: any, subLevel?: any) => {
    if (location.pathname === '/teams') {
      dispatch(getCoordinatorViewByIdTeam(_id, followValue + 1, subLevel));
    } else if (location.pathname === '/pollwatchers') {
      dispatch(getCoordinatorViewByIdRGRC(_id, followValue + 1));
    }
  };

  const getBreadcum = () => {
    return (
      <Fragment>
        {navigationPathTeam?.map((element: any, index: any) => {
          let linkClass = 'coord-link sub-level';

          if (!element.subLevel) {
            linkClass = 'coord-link';
          }

          if (index === navigationPathTeam.length - 1) {
            if (element.id === localStorage.id) {
              linkClass = '';
            }
            if (element.id !== localStorage.id) {
              if (element.subLevel) {
                linkClass = 'coord-no-link';
              } else {
                linkClass = 'coord-no-link-gray';
              }
            }
          }

          if (
            element.id === localStorage.id &&
            index !== navigationPathTeam.length - 1
          ) {
            linkClass = 'coord-link-black';
          }

          return (
            <Fragment key={'main-fragment-' + index}>
              {index === 0 && (
                <Fragment key={'top-fragment-' + index}>
                  <h2
                    className={
                      localStorage?.role === ROLE.ADMIN_BOSS ||
                      localStorage?.role === ROLE.SUPER_ADMIN
                        ? 'coord-link sub-level'
                        : 'coord-link'
                    }
                    onClick={() => {
                      goHome();
                    }}
                  >
                    {t('general.profile.home')}
                  </h2>
                  <p className="show-link-separator">&nbsp;/&nbsp;</p>
                </Fragment>
              )}
              <Fragment key={'bot-fragment-' + index}>
                <h2
                  className={linkClass}
                  onClick={() => {
                    if (
                      navigationPathTeam.length > 1 &&
                      index !== navigationPathTeam.length - 1
                    ) {
                      goToView(element.id, index, element.subLevel);
                    }
                  }}
                >
                  {element.teamName ? element.teamName : element.name}&nbsp;
                  {coordinator.focus && index === navigationPathTeam.length - 1
                    ? ' ' + coordinator.focus + ' '
                    : ''}
                  {element.teamName && index === navigationPathTeam.length - 1
                    ? '[' + coordinator.firstName + ']'
                    : ''}
                </h2>
                <p
                  className={
                    index < navigationPathTeam.length - 1
                      ? 'show-link-separator'
                      : ''
                  }
                >
                  /&nbsp;
                </p>
              </Fragment>
            </Fragment>
          );
        })}
      </Fragment>
    );
  };

  const goHome = () => {
    if (location.pathname === '/teams') {
      dispatch({
        type: TEAMS_COORDINATOR_SUCCESS_ONE,
        payload: {},
        follow: '0',
      });
    } else if (location.pathname === '/pollwatchers') {
      dispatch({ type: RGRC_RG_SUCCESS_ONE, payload: {}, follow: '0' });
    }
  };

  useEffect(() => {
    const pathNames = location.pathname.split('/');
    isSubRouteChecker(pathNames); // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  useEffect(() => {
    if (navigationPathTeam.length > 0) {
      setIsCoordinatorViewTeam(true);
    } else {
      setIsCoordinatorViewTeam(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigationPathTeam]);

  useEffect(() => {
    if (navigationPathRGRC.length > 0) {
      setIsCoordinatorViewRGRC(true);
    } else {
      setIsCoordinatorViewRGRC(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigationPathRGRC]);

  useEffect(() => {
    if (
      localStorage?.role !== ROLE.ADMIN_BOSS &&
      localStorage?.role !== ROLE.SUPER_ADMIN &&
      !breadcumCall &&
      citySelected
    ) {
      let lvlQty = citySelected.capturistUserLevels
        ? citySelected.capturistUserLevels.length -
          parseInt(localStorage?.level)
        : 0;
      dispatch(postBreadcumByCoordinator(lvlQty, () => {}));
    }
  }, [citySelected]);

  return (
    <Fragment>
      {isSubRoute ? (
        <Row>
          <Col md={12} lg={12} xs={12}>
            <span className="title-header father">
              <NavLink to={`/${fatherRoute}`}>
                <LeftArrowIcon></LeftArrowIcon>{' '}
              </NavLink>
              {isSubRoute && fatherRoute === 'voters'
                ? ' ' + t('territorial.voters.top.title')
                : fatherTitle}
            </span>
          </Col>
          <Col md={12} lg={12} xs={12}>
            <span className="title-header child">{childTitle}</span>
          </Col>
        </Row>
      ) : fatherTitle === t('menu.teams') &&
        tabChange === 'territorial.teams.tab.1' ? (
        <>
          <h1
            className="title-header"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              if (
                localStorage?.role !== ROLE.ADMIN_BOSS &&
                localStorage?.role !== ROLE.SUPER_ADMIN &&
                citySelected
              ) {
                let lvlQty = citySelected.capturistUserLevels
                  ? citySelected.capturistUserLevels.length -
                    parseInt(localStorage?.level)
                  : 0;
                dispatch(postBreadcumByCoordinator(lvlQty, () => {}));
              }
            }}
          >
            {fatherTitle + ' (' + totalCampaign + ') | '}
            {localStorage.teamName !== 'undefined' && localStorage.teamName
              ? localStorage.teamName + ' '
              : ''}
            {localStorage.focus !== 'undefined' && localStorage.focus
              ? localStorage.focus + ' '
              : ''}
            {'[' + userData?.firstName + ']'}
          </h1>
          {isCoordinatorViewTeam && (
            <div className="title-breadcum">{getBreadcum()}</div>
          )}
        </>
      ) : fatherTitle === t('menu.pollwatchers') &&
        isCoordinatorViewRGRC &&
        location.pathname === '/pollwatchers' ? (
        navigationPathRGRC.map((element: any, index: any) => {
          return (
            <div className="title-breadcum">
              {index === 0 && (
                <>
                  <h2
                    className="coord-link"
                    onClick={() => {
                      goHome();
                    }}
                  >
                    {t('general.profile.home')}
                  </h2>
                  <p className="show-link-separator">&nbsp;/&nbsp;</p>
                </>
              )}
              <h2
                className={
                  navigationPathRGRC.length > 1 &&
                  index !== navigationPathRGRC.length - 1
                    ? 'coord-link'
                    : ''
                }
                onClick={() => {
                  if (
                    navigationPathRGRC.length > 1 &&
                    index !== navigationPathRGRC.length - 1
                  ) {
                    goToView(element.id, index);
                  }
                }}
              >
                {element.teamName ? element.teamName : element.name}&nbsp;
                {element.teamName && index === navigationPathRGRC.length - 1
                  ? '[' + selectedRG.firstName + ']'
                  : ''}
                {selectedRG.focus && index === navigationPathRGRC.length - 1
                  ? '[' + selectedRG.focus + ']'
                  : ''}
              </h2>
              <p
                className={
                  index < navigationPathRGRC.length - 1
                    ? 'show-link-separator'
                    : ''
                }
              >
                &nbsp;/&nbsp;
              </p>
            </div>
          );
        })
      ) : (
        <h1 className="title-header">{fatherTitle}</h1>
      )}
    </Fragment>
  );
};

export default TopTitle;
