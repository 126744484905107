import React, { useEffect, useState } from 'react';
import * as netWorking from '../../../redux/netWorking';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../redux/Store';
import { useTranslation } from 'react-i18next';

import { Team } from '../../../redux/RgRc/RgRcTypes';
import {
  postCapturistTeamDataByCoordinator,
  postGetCapturistTeamDataTotal,
  postListTeamMembersLeftSide,
} from '../../../redux/teams/teamsActions';

import { DeleteIcon, AddActionIcon } from '../../../assets/svg/index';
import SelectorComponent from '../../../components/sub-component/selector';
import StaticModal from '../../../dialogs/StaticModal';
import Container from 'react-bootstrap/Container';
import { Spinner } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';

interface ModalAdministrerProps {
  modalShow: any;
  setModalShow: any;
  selectedTeam: any;
  successFun: any;
  selectedMunicipality: any;
  teamType: any;
  userRole: any;
  topTitle: any;
}

let defaultArray: [] = [];

export const ModalAdministrer: React.FunctionComponent<ModalAdministrerProps> =
  ({
    modalShow,
    setModalShow,
    selectedTeam,
    successFun,
    selectedMunicipality,
    teamType,
    userRole,
    topTitle,
  }: any) => {
    const citySelected = useSelector((globalState: RootStore) => {
      return globalState.core.globalMunicipalitySelected;
    });

    const coordinator: any = useSelector(
      (globalState: RootStore) => globalState.teams.coordinator,
    );
    const teams: Array<Team> = useSelector(
      (globalState: RootStore) => globalState.teams.teams,
    );
    const teamLeftSide: Array<Team> = useSelector(
      (globalState: RootStore) => globalState.teams.teamLeftSide,
    );

    const search = useSelector(
      (globalState: RootStore) => globalState.teams.search,
    );
    const sort: any = useSelector(
      (globalState: RootStore) => globalState.teams.sort,
    );
    const records: any = useSelector(
      (globalState: RootStore) => globalState.teams.records,
    );
    const page: any = useSelector(
      (globalState: RootStore) => globalState.teams.page,
    );

    const [municipalityId, setMunicipalityId] = useState(String);
    const [coordinators, setCoordinators] = useState(Object);
    const [leftData, setLeftData] = React.useState(Array);
    const [rightData, setRightData] = useState(defaultArray);
    const [loadingRightData, setLoadingRightData] = useState(false);
    const [teamRightId, setTeamRightId] = useState('-1');

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const extractSelectedTeamFromTeamsArray = (
      teams: Array<any>,
      selectedTeam: any,
    ): Array<any> => {
      return teams.filter((team) => {
        if (selectedTeam) {
          return team.teamId !== selectedTeam;
        } else {
          return true;
        }
      });
    };

    const buildParamSearchTeamDataByCoordinator = (
      search: string,
      recordsNum: number,
      order: string,
      pageNum: number,
    ) => {
      let requestObject;
      requestObject = {
        coordinatorId: coordinator?._id,
        coordinatorRole: coordinator?.role,
        teamLevel: 1,
        municipalityId: municipalityId,
        search: search.length >= 3 ? search : '',
        records: recordsNum,
        order: order,
        page: pageNum,
      };
      return requestObject;
    };

    const buildParamSearchTeamLeftData = () => {
      let requestObject;
      requestObject = {
        coordinatorId: coordinator?._id,
      };
      return requestObject;
    };

    const getTeamsData = () => {
      const params = buildParamSearchTeamDataByCoordinator(
        search,
        records,
        sort[1],
        page,
      );
      dispatch(postCapturistTeamDataByCoordinator(params));
      dispatch(postGetCapturistTeamDataTotal(params));
    };

    const getLeftData = () => {
      const params = buildParamSearchTeamLeftData();
      dispatch(postListTeamMembersLeftSide(params));
    };

    const getMembersWithoutAssign = (
      municipality = '',
      teamLevel = '',
      usersLevel = '',
    ) => {
      setLoadingRightData(true);
      netWorking.get(
        `${netWorking.getCapturistsWithoutAssign}/${municipality}/${teamLevel}/${usersLevel}`,
        (res: any) => {
          setRightData(res.data.response);
          setLoadingRightData(false);
        },
        () => {
          setLoadingRightData(false);
        },
      );
    };

    const getTeamMembers = (coordinatorId: string, members = null) => {
      setLoadingRightData(true);
      if (!coordinatorId) return;
      const coordinator = coordinators.find(
        (coordinator: any) => coordinator._id === coordinatorId,
      );
      netWorking.post(
        netWorking.getCpCbRgRcTeamMembers,
        { users: members || coordinator!.members },
        (res: any) => {
          console.log('res get team:', res);
          if (res && res.data && res.data.response) {
            setRightData(res.data.response);
            setLoadingRightData(false);
          }
        },
        (err: any) => {
          console.error(err);
          setLoadingRightData(false);
        },
      );
    };

    const addFromTeamRequest = (user: any) => {
      if (user && user._id && selectedTeam) {
        netWorking.post(
          netWorking.addCpCbRgRcFromTeam,
          {
            userId: user._id,
            teamId: selectedTeam,
            fromTeamId: teamRightId === '-1' ? '' : teamRightId,
          },
          (res: any) => {
            getTeamsData();
            if (res.data.response && res.data.response.error !== 1) {
              removeFormListNoTeam(res.data.response);
            }
          },
          (err: any) => {
            console.log('Some error ocurred while making transaction', err);
          },
        );
      }
    };

    const removeFromTeamRequest = (user: any) => {
      if (teamRightId === '-1') {
        // remove to no team
        netWorking.post(
          netWorking.removeCpCbRgRcFromTeam,
          {
            userId: user._id,
            teamId: selectedTeam,
          },
          (res: any) => {
            getTeamsData();
            if (res.data.response && res.data.response.error !== 1) {
              removeFromListWithTeam(res.data.response);
            }
          },
          () => {},
        );
      } else if (user && user._id && selectedTeam) {
        netWorking.post(
          netWorking.addCpCbRgRcFromTeam,
          { userId: user._id, teamId: teamRightId, fromTeamId: selectedTeam },
          (res: any) => {
            getTeamsData();
            if (res.data.response && res.data.response.error !== 1) {
              removeFromListWithTeam(res.data.response);
            }
          },
          (err: any) => {
            console.log('Some error ocurred while making transaction', err);
          },
        );
      }
    };

    const removeFromListWithTeam = (e: any) => {
      const listNoTeam: any = [];
      const listWithTeam: any = [];

      listNoTeam.push(...rightData);
      if (!listNoTeam.includes(e)) {
        listNoTeam.push(e);
      }
      leftData.forEach((item: any) => {
        if (item._id !== e._id) {
          listWithTeam.push(item);
        }
      });

      setLeftData(() => listWithTeam);
      setRightData(listNoTeam);
    };

    const removeFormListNoTeam = (e: any) => {
      const listNoTeam: any = []; //der
      const listWithTeam: any = []; //izq
      listWithTeam.push(...leftData);

      listWithTeam.push(e);

      rightData.forEach((item: any) => {
        if (item._id !== e._id) {
          listNoTeam.push(item);
        }
      });

      setLeftData(() => listWithTeam);
      setRightData(listNoTeam);
    };

    const searchTeamId = (coordinatorId: string, coordinators: Array<any>) => {
      if (coordinators == null || coordinatorId === '-1') return '-1';
      const coord = coordinators.find(
        (coordinator) => coordinator._id === coordinatorId,
      );
      return coord != null ? coord.teamId : '-1';
    };

    useEffect(() => {
      setCoordinators(
        extractSelectedTeamFromTeamsArray(teams, selectedTeam).map((team) => ({
          _id: team.bossId,
          name: `${team.name || ''}`,
          members: team.members,
          teamId: team.teamId,
        })),
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [teams]);

    useEffect(() => {
      if (citySelected) {
        setMunicipalityId(citySelected._id);
      }
      /* netWorking.get(
        `${netWorking.generateBingoDemo}/${citySelected?._id}/${localStorage.campaignId}`,
        (res: any) => {
          console.log('res', res);
        },
        () => {},
      ); */
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [citySelected]);

    useEffect(() => {
      if (teamLeftSide) {
        setLeftData(teamLeftSide);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [teamLeftSide]);

    useEffect(() => {
      if (municipalityId) {
        getLeftData();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [municipalityId]);

    return (
      <StaticModal
        show={modalShow}
        title={t('territorial.volunteer.modal.transfer.title')}
        closeText={t('submit.close')}
        onHide={() => setModalShow(false)}
        submitFun={() => {}}
        validBtn={false}
        size="xl"
        sizeClass="md modal-rc"
      >
        <>
          <Container className="dialog-administrer-teams">
            <div className="dialog-superior">
              <div className="dialog-organizer-name">
                <div className="dialog-subtitle">
                  {topTitle}
                  <span>
                    {coordinator
                      ? ' ' + coordinator.firstName + ' ' + coordinator.lastName
                      : ''}
                  </span>
                </div>
              </div>
              <div className="dialog-organizer-select">
                <h4>
                  {/* <strong> */}
                  {t('territorial.volunteer.modal.transfer.select2') +
                    ' ' +
                    topTitle}
                  {/* </strong> */}
                </h4>
                <SelectorComponent
                  noOptionTitle={t('select.lbl.none')}
                  title={``}
                  defaultSelector={
                    coordinators.length > 0 ? coordinators[0]._id : '-1'
                  }
                  funOnInit={(defaultCoordinatorId: string) => {
                    setLoadingRightData(true);
                    setTeamRightId(
                      searchTeamId(defaultCoordinatorId, coordinators),
                    );
                    if (defaultCoordinatorId === '-1') {
                      // no option value => -1
                      getMembersWithoutAssign(
                        selectedMunicipality,
                        teamType,
                        userRole,
                      );
                    } else {
                      getTeamMembers(defaultCoordinatorId);
                    }
                  }}
                  options={coordinators}
                  horizontal={true}
                  fun={(coordinatorId: string) => {
                    setLoadingRightData(true);
                    setTeamRightId(searchTeamId(coordinatorId, coordinators));
                    if (coordinatorId === '-1') {
                      // no option value => -1
                      getMembersWithoutAssign(
                        selectedMunicipality,
                        teamType,
                        userRole,
                      );
                    } else {
                      getTeamMembers(coordinatorId);
                    }
                  }}
                ></SelectorComponent>
              </div>
            </div>
            <Row className="list-box-main-row">
              <div className="list-box-main">
                <div className="noGutters">
                  <div className="list-box-team-title">
                    <h3>
                      {`${t(
                        'territorial.volunteer.modal.transfer.subtitle1',
                      )} (${leftData.length}):`}
                    </h3>
                  </div>
                  <div className="list-box-team">
                    {leftData
                      .map((e: any, index: number) => {
                        return toDeleteItem(e, removeFromTeamRequest);
                      })
                      .filter((e: any) => e != null)}
                  </div>
                </div>
              </div>
              <div className="list-box-main">
                <div className="noGutters">
                  <div className="list-box-team-title">
                    <h3>
                      {`${t(
                        'territorial.volunteer.modal.transfer.subtitle2',
                      )} (${rightData.length})`}
                    </h3>
                  </div>
                  <div className="list-box-team">
                    {!loadingRightData ? (
                      rightData
                        .map((e: any) => {
                          return toAddItem(e, addFromTeamRequest, t);
                        })
                        .filter((e: any) => e != null)
                    ) : (
                      <div className="spinner">
                        <span>
                          <Spinner animation="grow" />
                        </span>
                      </div>
                    )}
                  </div>
                </div>{' '}
              </div>
            </Row>
          </Container>
        </>
      </StaticModal>
    );
  };

const toDeleteItem = (e: any, fun: any) => {
  return (
    <div key={e._id} className="to-delete-item" onClick={() => fun(e)}>
      <p>{e.firstName + ' ' + e.lastName}</p>
      <DeleteIcon></DeleteIcon>
    </div>
  );
};

const toAddItem = (e: any, fun: any, t: any) => {
  return (
    <div key={e._id} className="to-add-item" onClick={() => fun(e)}>
      <div className="add-icon">
        <AddActionIcon></AddActionIcon>
      </div>
      <p className="name">{e.firstName + ' ' + e.lastName}</p>
    </div>
  );
};

export default ModalAdministrer;
