export const VOTERS_LOADING = 'VOTERS_LOADING';
export const VOTERS_FAIL = 'VOTERS_FAIL';
export const VOTERS_SUCCESS = 'VOTERS_SUCCESS';
export const VOTERS_COUNT_SUCCESS = 'VOTERS_COUNT_SUCCESS';
export const VOTERS_SUCCESS_ONE = 'VOTERS_SUCCESS_ONE';
export const VOTERS_PAGE_SUCCESS = 'VOTERS_PAGE_SUCCESS';
export const VOTERS_SEARCH_SUCCESS = 'VOTERS_SEARCH_SUCCESS';

export const VOTERS_OPEN = 'VOTERS_OPEN';

export type Voters = {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  cellphone: string;
  countryCode: string;
  supportInNetworks: string;
  mostUsedNetwork: string;
  address: string;
};

export type VoterDuplicate = {
  _id: string;
  firstName: string;
  lastName: string;
  sex: number;
  homeAddress: object;
  votingAddress: object;
  cellphone: string;
};

export type ConcentratedVoters = {
  own: string;
  nextElectionsYear: number;
  party: Array<any>;
};

export type SearchVoters = {
  cityLicenseId: string;
  page: number;
  records?: number;
  field: string;
  order: string;
  search: string;
  filters: object;
  nextElectionsYear: number;
};

export type SearchVotersAuditory = {
  cityLicenseId: string;
  page: number;
  records?: number;
  field: string;
  order: string;
  search: string;
  filters: object;
  nextElectionsYear: number;
};

export type ConfirmUnauditedVoter = {
  voterArray: Array<any>;
}

export type DenyUnauditedVoter = {
  voterArray: Array<any>;
}

export interface VotersLoading {
  type: typeof VOTERS_LOADING;
}

export interface VotersFail {
  type: typeof VOTERS_FAIL;
}

export interface VotersSuccess {
  type: typeof VOTERS_SUCCESS;
  payload: Voters[];
}

export interface VotersCountSuccess {
  type: typeof VOTERS_COUNT_SUCCESS;
  payload: object;
}

export interface VotersSuccessOne {
  type: typeof VOTERS_SUCCESS_ONE;
  payload: object;
}

export interface VotersPageSuccess {
  type: typeof VOTERS_PAGE_SUCCESS;
  page: string;
}

export interface VotersSearchSuccess {
  type: typeof VOTERS_SEARCH_SUCCESS;
  searchValue: string;
}

export interface VotersOpen {
  type: typeof VOTERS_OPEN;
  open: boolean;
}

export type VotersDispatchTypes =
  | VotersLoading
  | VotersFail
  | VotersSuccess
  | VotersCountSuccess
  | VotersSuccessOne
  | VotersPageSuccess
  | VotersSearchSuccess
  | VotersOpen;
