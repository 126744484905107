import React from 'react';
import { RouteProps } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import './style.scss';
interface InputProps extends RouteProps {
  title: any;
  onChange: any;
  placeholder: string;
  value: any;
  regExp?: any;
  length?: any;
  horizontal?: boolean;
  readonly?: boolean;
  subTitle?: string;
  as?: string;
  rows?: number;
  className?: string;
  refElement?: any;
  focusAction?: any;
}

const InputComponent: React.FunctionComponent<InputProps> = ({
  title,
  onChange,
  placeholder,
  value,
  regExp,
  length,
  horizontal,
  readonly,
  subTitle,
  as,
  rows,
  className,
  refElement,
  focusAction,
}: any) => {
  return (
    <div
      className={horizontal ? 'input-box-horizontal' : 'input-box ' + className}
    >
      <h2>
        {title}
        <span>{subTitle}</span>
      </h2>

      <Form.Control
        placeholder={placeholder}
        value={value}
        readOnly={readonly}
        maxLength={length}
        as={as}
        rows={rows}
        onChange={(data: any) => {
          if (
            regExp === undefined ||
            regExp.test(data.target.value.trim()) ||
            data.target.value === ''
          ) {
            onChange(data.target.value);
          }
        }}
        ref={refElement}
        onKeyPress={(data: any) => {
          if (data.key === 'Enter') {
            if(focusAction){
              focusAction()
            }
          }
        }}
      />
    </div>
  );
};

export default InputComponent;
