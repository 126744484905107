import React from 'react';
import Modal from 'react-bootstrap/Modal';
import './style.scss';
import Spinner from 'react-bootstrap/Spinner';
interface ModalProps {
  onHide: any;
  show: any;
}

const LoadingModal: React.FunctionComponent<ModalProps> = ({
  onHide,
  show,
}: any) => {
  return (
    <>
      <Modal
        show={show}
        onHide={()=>{}}
        //backdrop="static"
        keyboard={false}
        size={'lg'}
        className={'loading-modal'}
        aria-labelledby="contained-modal-title-vcenter"
        //dialogClassName="static-modal"
        centered
      >
        <Spinner animation="border" variant="primary" />
      </Modal>
    </>
  );
};

export default LoadingModal;
