import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import Card from 'react-bootstrap/Card';
import { CheckedXLS, UploadedXLS } from '../../../assets/svg';
import { UpData } from '../../../redux/updata/UpDataTypes';
import moment from 'moment';
import 'moment/locale/es';
import {
  capitalizeWords,
  format,
  numberWithCommas,
} from '../../../utils/utils';
import Dropdown from 'react-bootstrap/Dropdown';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Row from 'react-bootstrap/Row';
import { BsThreeDots } from 'react-icons/bs';
import * as netWorking from '../../../redux/netWorking';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

interface CardDbProps {
  upData: UpData;
  setSelected?: any;
  setModalShowApprove?: any;
  setModalDeleteUpData?: any;
  index: number;
  openDelete: any;
}

export const CardDb: FunctionComponent<CardDbProps> = ({
  upData,
  setSelected,
  setModalShowApprove,
  setModalDeleteUpData,
  index,
  openDelete,
}) => {
  // const dispatch = useDispatch();
  const { t } = useTranslation();
  // const [modalShowXls, setModalShowXls] = React.useState(false);

  const dropdown = () => {
    return (
      <Dropdown
        as={ButtonGroup}
        id={`dropdown-button-drop-down`}
        className="dropdown-options"
      >
        <Dropdown.Toggle id="dropdown-custom-marker">
          <Row className="h100 w100" noGutters>
            <BsThreeDots />
          </Row>
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-body">
          <Dropdown.Item
            eventKey="1"
            onClick={() => {
              // closePopusOnClick(ref);
              openDelete(upData?._id);
            }}
          >
            {t('settings.polling.dropdown.delete')}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const checkUpData = (status: number, upDataId?: string) => {
    netWorking.post(
      netWorking.CheckUpData,
      { upDataId, status },
      (resp: any) => {
        console.log('success');
        console.log(resp);
      },
      (err: any) => {},
    );
  };

  const getOverlay = (type: string) => {
    return (
      <Popover id={type} placement={'bottom'} className="pop-over-normal">
        <Popover.Title as="h3" bsPrefix="title">
          {t(type)}
        </Popover.Title>
      </Popover>
    );
  };

  const iconUploaded = (type: number) => {
    return (
      <OverlayTrigger
        key={'icon-item'}
        placement={'bottom'}
        overlay={getOverlay(
          type === 0
            ? 'settings.votersList.card.popup.uploaded'
            : 'settings.votersList.card.popup.approved',
        )}
      >
        {type === 0 ? <UploadedXLS></UploadedXLS> : <CheckedXLS></CheckedXLS>}
      </OverlayTrigger>
    );
  };

  const getCard = (status: number, upData?: UpData) => {
    switch (status) {
      case 0:
        return (
          <div className="status-xls-db-card-body">
            {dropdown()}
            <div className="top-party">
              <div className="initials-circle floating-initials-circle">P2</div>
              <h2>{upData?.name}</h2>
            </div>
            <div className="total-box-item">
              <p>
                <strong>
                  #{t('settings.votersList.item.lbl.nVoters')}:{' '}
                  {numberWithCommas(upData?.totalData || 0)}{' '}
                </strong>
              </p>
            </div>
            <div className="subtotal-box-item">
              <p>
                <strong>
                  #{t('settings.votersList.item.lbl.nApproved')}:{' '}
                </strong>
                <span> {numberWithCommas(upData?.totalData || 0)}</span>
              </p>
            </div>
            <div className="footer-box-item">
              <div className="data-box">
                <div className="left-box">
                  {iconUploaded(0)}
                  <p>
                    {capitalizeWords(
                      moment(upData?.createdDate)
                        .locale(t('language').toString())
                        .format(format),
                    )}
                  </p>
                </div>
                <div className="right-box">
                  <p>{upData?.byName}</p>
                </div>
              </div>
              <div className="buttons-box">
                <Button disabled={true} variant="primary" onClick={() => {}}>
                  {t('settings.votersList.item.lbl.uploading')}
                </Button>
              </div>
            </div>
          </div>
        );

      case 1:
        return (
          <div className="status-xls-db-card-body">
            {dropdown()}
            <div className="top-party">
              <div className="initials-circle floating-initials-circle">P2</div>
              <h2>{upData?.name}</h2>
            </div>
            <div className="total-box-item">
              <p>
                <strong>
                  #{t('settings.votersList.item.lbl.nVoters')}:{' '}
                  {numberWithCommas(upData?.totalData || 0)}{' '}
                </strong>
              </p>
            </div>
            <div className="subtotal-box-item">
              <p>
                <strong>
                  #{t('settings.votersList.item.lbl.nApproved')}:{' '}
                </strong>
                <span> {numberWithCommas(upData?.totalData || 0)}</span>
              </p>
            </div>
            <div className="footer-box-item">
              <div className="data-box">
                <div className="left-box">
                  {iconUploaded(0)}
                  <p>
                    {capitalizeWords(
                      moment(upData?.createdDate)
                        .locale(t('language').toString())
                        .format(format),
                    )}
                  </p>
                </div>
                <div className="right-box">
                  <p>{upData?.byName}</p>
                </div>
              </div>
              <div className="buttons-box">
                <Button
                  disabled={false}
                  variant="primary"
                  onClick={() => {
                    setSelected(upData);
                    checkUpData(2, upData?._id);
                    // setModalShowApprove(true);
                  }}
                >
                  {t('settings.votersList.item.btn.review')}
                </Button>
              </div>
            </div>
          </div>
        );

      case 2:
        let nVoter2 =
          upData?.totalData && upData.errorsCount
            ? upData?.totalData - upData?.errorsCount
            : 0;
        return (
          <div className="temp-box-xls-card actionable-card">
            <div className="status-box orange-bar">
              {t('settings.votersList.item.lbl.statusReview')}
            </div>{' '}
            {dropdown()}
            <div
              className="status-xls-db-card-body"
              onClick={() => {
                setSelected(upData);
              }}
            >
              <div className="top-party">
                <div className="initials-circle floating-initials-circle">
                  P2
                </div>
                <h2>{upData?.name}</h2>
              </div>
              <div className="total-box-item">
                <p>
                  <strong>
                    #{t('settings.votersList.item.lbl.nVoters')}:{' '}
                    {numberWithCommas(nVoter2 || 0)}{' '}
                  </strong>
                </p>
              </div>
              <div className="subtotal-box-item">
                <p>
                  <strong>
                    #{t('settings.votersList.item.lbl.nApproved')}:{' '}
                  </strong>
                  <span> {numberWithCommas(upData?.totalData || 0)}</span>
                </p>
              </div>
              <div className="footer-box-item">
                <div className="data-box">
                  <div className="left-box">
                    {iconUploaded(0)}
                    <p>
                      {capitalizeWords(
                        moment(upData?.createdDate)
                          .locale(t('language').toString())
                          .format(format),
                      )}
                    </p>
                  </div>
                  <div className="right-box">
                    <p>{upData?.byName}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      case 3:
        let nVoter3 =
          upData?.winsCount === 0 ? upData?.totalData : upData?.winsCount;
        return (
          <div className="temp-box-xls-card">
            <div className="status-box green-bar">
              {t('settings.votersList.item.lbl.statusReviewed')}
            </div>{' '}
            <div className="status-xls-db-card-body">
              {dropdown()}
              <div className="top-party">
                <div className="initials-circle floating-initials-circle">
                  P2
                </div>
                <h2>{upData?.name}</h2>
              </div>
              <div className="total-box-item">
                <p>
                  <strong>
                    #{t('settings.votersList.item.lbl.nVoters')}:{' '}
                    {numberWithCommas(nVoter3 || 0)}{' '}
                  </strong>
                </p>
              </div>
              <div className="subtotal-box-item">
                <p>
                  <strong>
                    #{t('settings.votersList.item.lbl.nApproved')}:{' '}
                  </strong>
                  <span> {numberWithCommas(upData?.totalData || 0)}</span>
                </p>
              </div>
              <div className="footer-box-item">
                <div className="data-box">
                  <div className="left-box">
                    {iconUploaded(0)}
                    <p>
                      {capitalizeWords(
                        moment(upData?.createdDate)
                          .locale(t('language').toString())
                          .format(format),
                      )}
                    </p>
                  </div>
                  <div className="right-box">
                    <p>{upData?.byName}</p>
                  </div>
                </div>
                <div className="data-box">
                  <div className="left-box">
                    {iconUploaded(1)}
                    <p>
                      {capitalizeWords(
                        moment(upData?.approvalDate)
                          .locale(t('language').toString())
                          .format(format),
                      )}
                    </p>
                  </div>
                  <div className="right-box">
                    <p>{upData?.reviewedByName}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };
  return (
    <Card className="text-center card-xls-db">
      <Card.Body>{getCard(upData?.status || 0, upData)}</Card.Body>
    </Card>
  );
};
export default CardDb;
