import React, { FunctionComponent } from 'react';
import { RouteProps } from 'react-router-dom';
import { BsClock } from 'react-icons/bs';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { getTime } from '../../../utils/utils';
import TagStatusComponent from './tagStatus';

interface ItemProps extends RouteProps {
  data: any;
  length: number;
  index: number;
  onSelected: any;
  dataSelected: any;
}

const TimeMarkerItemComponent: FunctionComponent<ItemProps> = ({
  data,
  length,
  index,
  onSelected,
  dataSelected,
}: any) => {
  const { t } = useTranslation();
  const getLineClass = () => {
    if (index === 0) return 'line-marker-box-top';
    else if (index < length) return 'line-marker-box';
    else if (index === length) return 'line-marker-box-bottom';
  };

  // const getAddress = (address: any) => {
  //   getGeocode({ address })
  //     .then((results: any) => {
  //       return getLatLng(results[0]);
  //     })
  //     .then(({ lat, lng }) => {
  //       console.log('📍 Coordinates: ', { lat, lng });
  //     })
  //     .catch((error) => {
  //       console.log('😱 Error: ', error);
  //     });
  // };

  return (
    <div
      className={
        dataSelected === index ? 'time-item-box selected' : 'time-item-box'
      }
      onClick={() => {
        onSelected(index);
      }}
    >
      <div className={getLineClass()}>
        <div
          className={data.status === 2 ? 'step-circle step-red' : 'step-circle'}
        >
          {index + 1}
        </div>
        <div className="step-line"></div>
      </div>
      <div className="data-box">
        <div>
          <p>
            {/* <strong>{t('territorial.tracker.lbl.lastLocation')}:</strong> */}
            {!data.address || data.address === ''
              ? t('territorial.tracker.popup.lbl.NoAddress')
              : data.address}
          </p>
        </div>
        {<TagStatusComponent status={data.status}></TagStatusComponent>}
      </div>
      <div className="time-box">
        <div>
          <p>{moment(data.startDate).format('hh:mmA')}</p>
        </div>
        <div>
          <BsClock></BsClock>
          <p>{getTime(t, data.startDate, data.endDate)}</p>
        </div>
        <div>
          <p></p>
        </div>
      </div>
    </div>
  );
};

export default TimeMarkerItemComponent;
