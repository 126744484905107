import {
  AUDITORY_LOADING,
  AUDITORY_FAIL,
  AUDITORY_SUCCESS,
  AUDITORY_COUNT_SUCCESS,
  AUDITORY_SUCCESS_ONE,
  AUDITORY_PAGE_SUCCESS,
  AUDITORY_OPEN,
  AUDITORY_PAGE_RESET,
  AUDITORY_SEARCH_SUCCESS,
  AuditoryDispatchTypes,
} from './AuditoryTypes';

interface AuditoryState {
  loading: boolean;
  voters?: Array<any>;
  votersGlobal?: Array<any>;
  page?: number;
  records?: number;
  sort?: Array<any>;
  filters?: Object;
  totalPage: number;
  totalVoters: number;
  voter: Object;
  voterAudition: Object;
  open: boolean;
  search: string;
}

const defaultState: AuditoryState = {
  loading: false,
  page: 1,
  records: 10,
  sort: ['newFirstName', 'asc'],
  filters: { unique: true, duplicated: true },
  totalPage: 1,
  voters: [],
  totalVoters: 0,
  voter: {},
  voterAudition: {},
  open: false,
  search: '',
};

const votersReducer = (
  state: AuditoryState = defaultState,
  action: AuditoryDispatchTypes,
): AuditoryState => {
  switch (action.type) {
    case AUDITORY_FAIL:
      return {
        ...state,
        loading: false,
      };
    case AUDITORY_LOADING:
      return {
        ...state,
        loading: true,
      };
    case AUDITORY_SUCCESS:
      let list_auditory = [];
      const data_auditory: any = action.payload;
      list_auditory.push(...(data_auditory.voters || []));
      return {
        ...state,
        voters: list_auditory,
        page: data_auditory.page,
        records: data_auditory.records,
        sort: data_auditory.sort,
        filters: data_auditory.filters,
        loading: false,
        //search: data_auditory.search
      };
    case AUDITORY_COUNT_SUCCESS:
      const data_auditory2: any = action.payload;
      return {
        ...state,
        totalPage: data_auditory2.pages,
        totalVoters: data_auditory2.totalVoters,
      };
    case AUDITORY_SUCCESS_ONE:
      return {
        ...state,
        voterAudition: action.payload,
        loading: false,
      };
    case AUDITORY_PAGE_SUCCESS:
      const page = Number.parseInt(action.page, 0) - 1;
      const skip = page * 8;
      const list1 = [];
      list1.push(...(state.votersGlobal || []));
      const vBP = list1?.splice(skip, 8);
      return {
        ...state,
        loading: false,
        voters: vBP,
      };
    case AUDITORY_OPEN:
      return {
        ...state,
        open: action.open,
        loading: false,
      };
    case AUDITORY_PAGE_RESET:
      return {
        ...state,
        page: action.page,
        loading: false,
      };
    case AUDITORY_SEARCH_SUCCESS:
      return {
        ...state,
        search: action.searchValue,
        loading: false,
      };
    default:
      return state;
  }
};

export default votersReducer;
