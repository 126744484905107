import React, { FunctionComponent } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../style.scss';
import * as netWorking from '../../../redux/netWorking';
import { useTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import DuplicatesTableComponent, {
  FIELD_TYPE,
  HEADER,
  HEADER_TYPE,
} from './duplicatesTable';
import ModalConfirmFinalize from './confirmFinalizar';
import { useSelector } from 'react-redux';
import { RootStore } from '../../../redux/Store';

interface ModalDuplicateListProps {
  modalShow: any;
  setModalShow: any;
  listRejected: any;
  replacedFun: any;
  reviewFun: any;
  setRejectedList: any;
  upDataId: string;
}

const arrayEmpty: [] = [];

export const ModalDuplicateList: FunctionComponent<ModalDuplicateListProps> = ({
  modalShow,
  setModalShow,
  listRejected,
  replacedFun,
  reviewFun,
  setRejectedList,
  upDataId,
}: any) => {
  const [toUpdate, setToUpdate] = React.useState(arrayEmpty);
  const [toUpdate2, setToUpdate2] = React.useState(arrayEmpty);
  const [modalShowConfirmIgnoreAll, setModalShowConfirmIgnoreAll] =
    React.useState(false);
  const [modalShowConfirmReplaceAll, setModalShowConfirmReplaceAll] =
    React.useState(false);

  const [search, setSearch] = React.useState('');
  const { t } = useTranslation();

  const userData = useSelector((globalState: RootStore) => {
    return globalState.core.userData;
  });

  console.log('listRejected', listRejected);

  const replace = (id: any, id2: any, type: any) => {
    const listForUpdate: object[] = [];
    const listToDelete: string[] = [];
    if (type === 0) {
      listForUpdate.push({
        data: id,
        actualData: id2,
      });
    } else {
      listToDelete.push(id);
    }

    netWorking.post(
      netWorking.ForceReplaceVoters,
      {
        listForUpdate,
        listToDelete,
        upDataId,
        reviewedId: userData?._id,
        reviewedByName:
          (userData?.firstName || '') + ' ' + (userData?.lastName || ''),
      },
      (data: any) => {
        setToUpdate([]);
        setToUpdate2([]);
        console.log('data1', data);
        const toDelete = data.data.response.listToDelete;
        const forUpdate = data.data.response.listForUpdate;
        let passed = false;
        setRejectedList((prev: any) => {
          const array = [...prev];
          const arrayNew: any[] = [];
          array.forEach((e) => {
            if (
              toDelete.indexOf(e.data._id) === -1 &&
              toDelete.indexOf(e.actualData._id) === -1 &&
              forUpdate.find((t: any) => {
                if (
                  t.data === e.data._id ||
                  t.actualData === e.actualData._id
                ) {
                  return t;
                }
              }) === undefined
            ) {
              arrayNew.push(e);
            }
          });
          if (arrayNew.length === 0) {
            passed = true;
          }
          return arrayNew;
        });
        if (passed) getDataUpData(upDataId);
      },
      () => {},
    );
  };

  const replaceAll = () => {
    const listForUpdate: object[] = [];
    const listToDelete: string[] = [];
    listRejected.forEach((j: any) => {
      if (j.actualData.editable) {
        listForUpdate.push({
          data: j.data._id,
          actualData: j.actualData._id,
        });
      } else {
        listToDelete.push(j.data._id);
      }
    });

    netWorking.post(
      netWorking.ForceReplaceVoters,
      {
        listForUpdate,
        listToDelete,
        upDataId,
        reviewedId: userData?._id,
        reviewedByName:
          (userData?.firstName || '') + ' ' + (userData?.lastName || ''),
      },
      (data: any) => {
        setToUpdate([]);
        setToUpdate2([]);
        console.log('data2', data);
        const toDelete = data.data.response.listToDelete;
        const forUpdate = data.data.response.listForUpdate;
        let passed = false;
        setRejectedList((prev: any) => {
          const array = [...prev];
          const arrayNew: any[] = [];
          array.forEach((e) => {
            if (
              toDelete.indexOf(e.data._id) === -1 &&
              toDelete.indexOf(e.actualData._id) === -1 &&
              forUpdate.find((t: any) => {
                if (
                  t.data === e.data._id ||
                  t.actualData === e.actualData._id
                ) {
                  return t;
                }
              }) === undefined
            ) {
              arrayNew.push(e);
            }
          });
          if (arrayNew.length === 0) {
            passed = true;
          }
          return arrayNew;
        });
        if (passed) getDataUpData(upDataId);
      },
      () => {},
    );
  };

  const ignoreAll = () => {
    const listForUpdate: object[] = [];
    const listToDelete: string[] = [];
    listRejected.forEach((j: any) => {
      listToDelete.push(j.data._id);
    });

    netWorking.post(
      netWorking.ForceReplaceVoters,
      {
        listForUpdate,
        listToDelete,
        upDataId,
        reviewedId: userData?._id,
        reviewedByName:
          (userData?.firstName || '') + ' ' + (userData?.lastName || ''),
      },
      (data: any) => {
        setToUpdate([]);
        setToUpdate2([]);
        console.log('data3', data);
        const toDelete = data.data.response.listToDelete;
        const forUpdate = data.data.response.listForUpdate;
        let passed = false;
        setRejectedList((prev: any) => {
          const array = [...prev];
          const arrayNew: any[] = [];
          array.forEach((e) => {
            if (
              toDelete.indexOf(e.data._id) === -1 &&
              toDelete.indexOf(e.actualData._id) === -1 &&
              forUpdate.find((t: any) => {
                if (
                  t.data === e.data._id ||
                  t.actualData === e.actualData._id
                ) {
                  return t;
                }
              }) === undefined
            ) {
              arrayNew.push(e);
            }
          });
          if (arrayNew.length === 0) {
            passed = true;
          }
          return arrayNew;
        });
        if (passed) getDataUpData(upDataId);
      },
      () => {},
    );
  };

  const getDataUpData = (upDataId: string) => {
    netWorking.post(
      netWorking.ApproveUpData,
      {
        upDataId,
        reviewedId: userData?._id,
        reviewedByName:
          (userData?.firstName || '') + ' ' + (userData?.lastName || ''),
      },
      (resp: any) => {
        if (resp.data.response.rejectedVoter.length === 0) {
          replacedFun();
          setModalShow(false);
        } else {
          setRejectedList(resp.data.response.rejectedVoter);
        }
        // setSelected(defaultUpData);
      },
      (err: any) => {},
    );
  };

  const getPluralSingularText = () => {
    if (listRejected.length === 1) {
      return (
        t('settings.votersList.modal.text.duplicate.body.1').substring(
          0,
          t('settings.votersList.modal.text.duplicate.body.1').length - 2,
        ) + ','
      );
    } else {
      return t('settings.votersList.modal.text.duplicate.body.1');
    }
  };

  // const validBtn = () => {
  //   const sum = toUpdate.length + toUpdate2.length;
  //   if (sum >= listRejected.length) return false;
  //   else return true;
  // };

  return (
    <>
      <Modal
        show={modalShow}
        onHide={setModalShow}
        backdrop="static"
        keyboard={false}
        size={'sm'}
        className={'lg all-screen-modal'}
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="static-modal"
        centered
      >
        <Modal.Header>
          <Modal.Title className="title-border-bottom">
            {t('settings.votersList.modal.title.issues')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <Row noGutters className="modal-text-row">
              {/* <Dropzone type={type} setRouteFile={setRouteFile}></Dropzone> */}
              <Col xs={9} className="description-col">
                <strong>
                  {listRejected.length + ' ' + getPluralSingularText()}
                </strong>{' '}
                {t('settings.votersList.modal.lbl.seemsToBeRepeated')}
              </Col>
              <Col xs={3} className="buttons-header-modal">
                <Button
                  variant="outline-dark"
                  onClick={() => {
                    setModalShowConfirmIgnoreAll(true);
                  }}
                >
                  {t('settings.votersList.modal.btn.ignoreAll')}
                </Button>
                <Button
                  variant="primary"
                  onClick={() => {
                    setModalShowConfirmReplaceAll(true);
                  }}
                >
                  {t('settings.votersList.modal.btn.replaceAll')}
                </Button>
              </Col>
              <Col xs={12} className="question-col">
                {t('settings.votersList.modal.lbl.selectOption')}
              </Col>
            </Row>
            <div className="box-duplicates-table">
              <DuplicatesTableComponent
                showActions={false}
                actionsStartRow={false}
                showOnlyDelete={false}
                selectedRows={toUpdate}
                selectRows={setToUpdate}
                selectedRows2={toUpdate2}
                selectRows2={setToUpdate2}
                doneSelects={(id: any, id2: any, type: any) => {
                  replace(id, id2, type);
                }}
                header={{
                  title: t('territorial.voters.lbl.voters'),
                }}
                headerType={HEADER_TYPE.NORMAL}
                headers={[
                  {
                    type: HEADER.TEXT,
                    name: t('territorial.voters.lbl.ineVoterKey'),
                  },
                  {
                    type: HEADER.TEXT,
                    name: t('territorial.voters.lbl.votingSection'),
                  },
                  {
                    type: HEADER.TEXT,
                    name: t('territorial.voters.lbl.name'),
                    fun: () => {
                      // const newOrder = filters.order === 'asc' ? 'desc' : 'asc';
                    },
                  },
                  {
                    type: HEADER.TEXT,
                    name: t('territorial.voters.lbl.phone'),
                  },
                  {
                    type: HEADER.TEXT,
                    name: t('territorial.voters.lbl.sex'),
                  },
                  {
                    type: HEADER.TEXT,
                    name: t('territorial.voters.lbl.votingState'),
                  },
                  {
                    type: HEADER.TEXT,
                    name: t('territorial.voters.lbl.votingCity'),
                  },
                  {
                    type: HEADER.TEXT,
                    name: t('territorial.voters.lbl.address'),
                  },
                  {
                    type: HEADER.TEXT,
                    name: t('territorial.voters.lbl.city'),
                  },
                ]}
                columns={[
                  {
                    type: FIELD_TYPE.TEXT,
                    dataIndex: 'ineVoterKey',
                    classBody: 'text-align-left curp-cell',
                  },
                  {
                    type: FIELD_TYPE.TEXT,
                    dataIndex: 'sectionVote',
                    classBody: 'text-align-left section-box-cell',
                  },
                  {
                    type: FIELD_TYPE.TEXT,
                    dataIndex: 'name',
                    classBody: 'text-align-left name-cell',
                  },
                  {
                    type: FIELD_TYPE.TEXT,
                    dataIndex: 'cellphone',
                    classBody: 'text-align-left cellphone-cell',
                  },
                  {
                    type: FIELD_TYPE.SEX,
                    dataIndex: 'sex',
                    classBody: 'text-align-left sex-cell',
                  },
                  {
                    type: FIELD_TYPE.TEXT,
                    dataIndex: 'stateVote',
                    classBody: 'text-align-left state-box-cell',
                  },
                  {
                    type: FIELD_TYPE.TEXT,
                    dataIndex: 'municipalityVote',
                    classBody: 'text-align-left municipality-box-cell',
                  },
                  {
                    type: FIELD_TYPE.TEXT,
                    dataIndex: 'address',
                    classBody: 'text-align-left address-cell',
                  },
                  {
                    type: FIELD_TYPE.TEXT,
                    dataIndex: 'municipality',
                    classBody: 'text-align-left municipality-cell',
                  },
                ]}
                toEdit={() => {}}
                toDelete={(data: any) => {}}
                search={(value: string) => {
                  setSearch(value.toLowerCase());
                }}
                regExp={undefined}
                data={listRejected.filter((e: any) => {
                  if (
                    e.data.ineVoterKey.toLowerCase().includes(search) ||
                    e.data.sectionVote.toLowerCase().includes(search) ||
                    e.data.name.toLowerCase().includes(search) ||
                    e.data.cellphone.toLowerCase().includes(search) ||
                    e.data.stateVote.toLowerCase().includes(search) ||
                    e.data.municipalityVote.toLowerCase().includes(search) ||
                    e.data.address.toLowerCase().includes(search) ||
                    e.data.municipality.toLowerCase().includes(search)
                  )
                    return true;
                  else if (
                    e.actualData.ineVoterKey.toLowerCase().includes(search) ||
                    e.actualData.sectionVote.toLowerCase().includes(search) ||
                    e.actualData.name.toLowerCase().includes(search) ||
                    e.actualData.cellphone.toLowerCase().includes(search) ||
                    e.actualData.stateVote.toLowerCase().includes(search) ||
                    e.actualData.municipalityVote
                      .toLowerCase()
                      .includes(search) ||
                    e.actualData.address.toLowerCase().includes(search) ||
                    e.actualData.municipality.toLowerCase().includes(search)
                  )
                    return true;
                  else return false;
                })}
              ></DuplicatesTableComponent>
            </div>
          </>
        </Modal.Body>
      </Modal>
      {modalShowConfirmReplaceAll && (
        <ModalConfirmFinalize
          modalShow={modalShowConfirmReplaceAll}
          setModalShow={setModalShowConfirmReplaceAll}
          successFun={() => {
            replaceAll();
          }}
          type={0}
        ></ModalConfirmFinalize>
      )}
      {modalShowConfirmIgnoreAll && (
        <ModalConfirmFinalize
          modalShow={modalShowConfirmIgnoreAll}
          setModalShow={setModalShowConfirmIgnoreAll}
          successFun={() => {
            ignoreAll();
          }}
          type={1}
        ></ModalConfirmFinalize>
      )}
    </>
  );
};

export default ModalDuplicateList;
