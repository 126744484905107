import React, { useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import TopSeparator from '../../components/TopSeparator';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ItemList from './components/itemList';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  getTrainingsList,
  createTraining,
} from '../../redux/trainings/TrainingsActions';
import { RootStore } from '../../redux/Store';
import SearchFormComponent from '../../../src/components/sub-component/searchForm';
import { PRIVATE_TRAINING_CREATE } from '../../utils/paths';
import './styleList.scss';

const TrainingListSettings: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  // data redux
  const trainingsItemsList = useSelector(
    (globalState: RootStore) => globalState.trainings.trainings,
  );
  const search = useSelector(
    (globalState: RootStore) => globalState.trainings.search,
  );

  const data = new Array();
  const [trainingsList, setTrainingsList] = React.useState(data);
  const [searchValue, setsearchValue] = React.useState(search ? search : '');
  const [loadData, setLoadData] = React.useState(false);

  const trainingsLength = (trainings: any) => {
    let count = 0;
    count = trainings !== undefined ? trainings.length : 0;
    return count;
  };

  const redirectAdd = () => {
    dispatch(
      createTraining(
        () => {
          history.push(PRIVATE_TRAINING_CREATE);
        },
        () => {},
      ),
    );
  };

  useEffect(() => {
    if (!loadData) {
      setLoadData(true);
    }
  }, [loadData]);

  useEffect(() => {
    if (searchValue.length >= 3) {
      dispatch(getTrainingsList({ search: searchValue }));
    } else {
      dispatch(getTrainingsList({ search: '' }));
    }
  }, [searchValue]);

  useEffect(() => {
    if (trainingsItemsList) {
      setTrainingsList(trainingsItemsList);
    }
  }, [trainingsItemsList]);

  return (
    <Fragment>
      <TopSeparator></TopSeparator>
      <div className="training-list-list">
        <SearchFormComponent
          search={search}
          funOnInit={(data: any) => {
            setsearchValue(data);
          }}
          additionalClass={'tab-search-noLogs search-form-index'}
        ></SearchFormComponent>
        <div className="training-filters-container">
          <div className="count-container">
            <h1 className="count">
              {`${trainingsLength(trainingsList)} ${t('menu.training')}`}
            </h1>
          </div>
          <div className="filters-container">
            <div className="status">
              <Button
                disabled={false}
                variant="primary"
                onClick={() => {
                  redirectAdd();
                }}
                className="search-add-button"
              >
                {t('settings.trainings.form.add')}{' '}
                {t('settings.trainings.lbl.training')}
              </Button>
            </div>
          </div>
        </div>
        <div className="training-container">
          <Row noGutters>
            <Col
              xl={12}
              lg={12}
              md={12}
              sm={12}
              className="item-list-container"
            >
              <ItemList trainingList={trainingsList} t={t}></ItemList>
            </Col>
          </Row>
        </div>
      </div>
    </Fragment>
  );
};

export default TrainingListSettings;
