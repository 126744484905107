import React, { useEffect, Fragment, FunctionComponent } from 'react';
import Table from 'react-bootstrap/Table';
import './style.scss';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Col from 'react-bootstrap/Col';
import Figure from 'react-bootstrap/Figure';
import { BsSearch, BsThreeDots } from 'react-icons/bs';
import Row from 'react-bootstrap/Row';
// import { MdDelete, MdEdit, MdCheck } from "react-icons/md";
import { GoCheck } from 'react-icons/go';
import {
  EditIcon,
  Trash,
  CorrectIcon,
  OrderIcon,
  FacebookIcon,
  InstagramIcon,
  TiktokIcon,
  WhatsappIcon,
  MaleIcon,
  FemaleIcon,
} from '../assets/svg/index';
import { FaTwitter } from 'react-icons/fa';
import { DiOpera } from 'react-icons/di';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { Team, RCRG } from '../redux/RgRc/RgRcTypes';
import { useTranslation } from 'react-i18next';
import { isArray } from 'lodash';
import { RiAlertLine } from 'react-icons/ri';
import { SEX } from '../pages/auditoryTerritorial/components/DenyVoter';
import { IoIosTransgender } from 'react-icons/io';

export enum FIELD_TYPE {
  TEXT,
  TEXT_ARRAY,
  IMAGE_TEXT,
  IMAGE_TEXT_TWO_INITIALS,
  BUTTON_ARRAY,
  BUTTON_ARRAY_VOTERS_BOX,
  BUTTON_TEXT,
  COUNTER_BOX,
  PORCENTAJE,
  COUNT_LINK,
  OBJECT_ARRAY,
  SOCIAL_BAR,
  SOCIAL_NAME_ICON,
  WHATSAPP,
  OBJECT_TEXT,

  TEXT_LINK,
  PERCENTAJE_PROGRESS,
  TEXT_BOLD,
  SEX,
}

export enum SOCIAL_NETWORK {
  UNDEFINED,
  FACEBOOK,
  INSTAGRAM,
  TIKTOK,
  TWITTER,
  ONLYFANS,
}
// header of all table
export enum HEADER_TYPE {
  NORMAL,
  TEAM,
  TERRITORIAL_TEAM,
}
// header of each column table
export enum HEADER {
  TEXT,
  FILTER,
}

export interface HeaderNormal {
  title: string;
}

export interface HeaderTeamRg {
  _id: string;
  title: string;
  rg?: RCRG;
  funText: string;
  fun: any;
  team: Team;
  editFun: any;
  deleteFun: any;
}

export type header = HeaderNormal | HeaderTeamRg;

export const getSexWithIcon = (network: number): any => {
  switch (Number(network)) {
    case SEX.MAN:
      return <MaleIcon className="icon"></MaleIcon>;
    case SEX.WOMAN:
      return <FemaleIcon className="icon"></FemaleIcon>;
    case SEX.APACHE_HELICOPTER:
      return <IoIosTransgender className="icon"></IoIosTransgender>;
    default:
      break;
  }
};

interface TableProps {
  header: header;
  headerType: HEADER_TYPE;
  headers: any;
  data: any;
  toEdit: any;
  toDelete: any;
  search: any;
  columns: any;
  regExp?: any;
  selectedRows?: any;
  selectRows?: any;
  showActions?: Boolean;
  onTrAction?: boolean;
  onTrFun?: any;
  duplicates?: string[];
  showOnlyDelete?: Boolean;
  actionsStartRow?: Boolean;
}

const NewTableComponent: FunctionComponent<TableProps> = ({
  header,
  headerType,
  headers,
  data,
  toEdit,
  toDelete,
  search,
  regExp,
  columns,
  selectedRows,
  selectRows,
  showActions,
  onTrAction,
  onTrFun,
  duplicates = [],
  showOnlyDelete = false,
  actionsStartRow = false,
}: any) => {
  const { t } = useTranslation();
  //   const [key, setKey] = useState(options[0]);
  const selectRow = (id: any) => {
    const listSelectedRows = [...selectedRows];
    const index = listSelectedRows.indexOf(id);
    if (index !== -1) {
      listSelectedRows.splice(index, 1);
    } else {
      listSelectedRows.push(id);
    }
    Object.assign(selectedRows, listSelectedRows);
    selectRows(listSelectedRows);
  };

  useEffect(() => {}, []);

  const openDelete = (id?: any) => {
    if (id !== undefined) toDelete([id]);
    else {
      toDelete([...selectedRows]);
    }
  };

  const getHeader = () => {
    switch (headerType) {
      case HEADER_TYPE.NORMAL:
        return (
          <div className="header-table">
            <div>
              <h2>{header.title}</h2>
            </div>
            <div className="search-action-box">
              {selectedRows.length > 0 ? (
                <div className="delete-btn" onClick={() => openDelete()}>
                  <Button
                    variant="outline-dark"
                    className="delete-item-territorial-team"
                  >
                    <Trash></Trash>
                    <span>{t('settings.trainings.form.delete')}</span>
                  </Button>
                </div>
              ) : (
                <div className="delete-btn"></div>
              )}
            </div>
          </div>
        );
      case HEADER_TYPE.TEAM:
        return (
          <Container fluid className="header-table-team">
            <Row noGutters>
              <Col className="header-title-box">
                <div>
                  <h2>
                    RG:{' '}
                    {header.rg
                      ? ' ' + header.rg?.firstName + ' ' + header.rg?.lastName
                      : ''}
                  </h2>
                  {dropdown(header)}
                </div>
                {/* <div>
                  {header.rg
                    ? " " + header.rg?.firstName + " " + header.rg?.lastName
                    : ""}
                  {dropdown(header)}
                </div> */}
              </Col>
              <Col lg={6} md={7} xs={8} className="search-action-box">
                <Button variant="primary" onClick={header.fun}>
                  {header.funText}
                </Button>
                {selectedRows.length > 0 ? (
                  <div className="delete-btn" onClick={() => openDelete()}>
                    <Trash></Trash>
                  </div>
                ) : (
                  <div className="delete-btn"></div>
                )}
                <Form inline className="search-field">
                  <FormControl
                    type="text"
                    placeholder="Search"
                    className="mr-sm-2"
                    onChange={(data) => {
                      if (data.target.value === '') search(data.target.value);
                      if (
                        regExp === undefined ||
                        regExp.test(data.target.value.trim())
                      )
                        search(data.target.value);
                    }}
                  />
                  <div className="icon">
                    <BsSearch />
                  </div>
                </Form>
              </Col>
            </Row>
          </Container>
        );
      case HEADER_TYPE.TERRITORIAL_TEAM:
        return (
          <Container fluid className="header-table-team">
            <Row noGutters>
              <div>
                <span>
                  <strong>Lider de equipo: </strong>
                </span>
                <span style={{ marginLeft: '0.5rem' }}>
                  {header.coordinatorName}
                </span>
                {dropdown(header)}
              </div>
              <Col lg={6} md={7} xs={8} className="search-action-box">
                <Button variant="primary" onClick={header.funAdmin}>
                  {header.funTextAdmin}
                </Button>
                <Button variant="primary" onClick={header.fun}>
                  {header.funText}
                </Button>
                {selectedRows.length > 0 ? (
                  <div className="delete-btn" onClick={() => openDelete()}>
                    <Trash></Trash>
                  </div>
                ) : (
                  <div className="delete-btn"></div>
                )}
                <Form inline className="search-field">
                  <FormControl
                    type="text"
                    placeholder="Search"
                    className="mr-sm-2"
                    onChange={(data) => {
                      if (data.target.value === '') search(data.target.value);
                      if (
                        regExp === undefined ||
                        regExp.test(data.target.value.trim())
                      )
                        search(data.target.value);
                    }}
                  />
                  <div className="icon">
                    <BsSearch />
                  </div>
                </Form>
              </Col>
            </Row>
          </Container>
        );
      default:
        return null;
    }
  };

  return (
    <div className="table-box">
      {getHeader()}
      <div className="table-container">
        <Table responsive>
          <thead>
            <tr>{ListHeaders(headers, columns, actionsStartRow)}</tr>
          </thead>
          <tbody>
            {ListBodyData(
              data,
              columns,
              selectedRows,
              selectRow,
              toEdit,
              openDelete,
              showActions,
              onTrAction,
              onTrFun,
              duplicates,
              showOnlyDelete,
              actionsStartRow,
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

const ListHeaders = (headers: any, columns: any, actionsStartRow: boolean) => {
  // eslint-disable-next-line
  return headers.map((header: any, index: number) => {
    switch (header.type) {
      case HEADER.TEXT:
        return (
          <th
            className={
              columns[index].classBody +
              (index === 0 && actionsStartRow ? ' header-actions-row' : '')
            }
            key={header.name}
          >
            {header.name}
          </th>
        );
      case HEADER.FILTER:
        return (
          <th className={columns[index].classBody} key={header.name}>
            {header.name}{' '}
            <OrderIcon
              className="filter-action-icon"
              onClick={() => {
                header.fun();
              }}
            ></OrderIcon>
          </th>
        );
      default:
        break;
    }
  });
};

const ListBodyData = (
  bodyData: any,
  columns: any,
  selectedRows: any,
  selectRow: any,
  toEdit: any,
  openDelete: any,
  showActions: boolean,
  onTrAction: boolean,
  onTrFun: any,
  duplicates: string[],
  showOnlyDelete: boolean,
  actionsStartRow: boolean,
) => {
  return bodyData.map((e: any) => {
    return (
      <tr
        key={e._id}
        className={
          (selectedRows.indexOf(e._id) !== -1 ? 'selected' : '') +
          (duplicates.indexOf(e._id) !== -1 ? ' duplicate-row' : '')
        }
        onClick={() => {
          if (onTrAction) {
            onTrFun(e._id);
          }
        }}
      >
        {cellData(
          e,
          columns,
          selectedRows,
          selectRow,
          showActions,
          actionsStartRow,
          openDelete,
        )}
        {showActions === undefined ||
          (showActions &&
            !actionsStartRow &&
            cellActions(e, columns, toEdit, openDelete, showOnlyDelete))}
        {duplicates.indexOf(e._id) !== -1 ? (
          <th>
            <RiAlertLine className="warning-icon"></RiAlertLine>
          </th>
        ) : (
          <th className="empty-warning-box"></th>
        )}
      </tr>
    );
  });
};

const cellData = (
  data: any,
  columns: any,
  selectedRows: any,
  selectRow: any,
  showActions: boolean,
  actionsStartRow: boolean,
  openDelete: any,
) => {
  return columns.map((e: any, index: number) => {
    return (
      <th
        key={e.dataIndex}
        className={columns[index].classBody}
        onClick={() => {
          if (
            e.type !== FIELD_TYPE.BUTTON_TEXT &&
            e.type !== FIELD_TYPE.BUTTON_ARRAY &&
            e.type !== FIELD_TYPE.COUNT_LINK &&
            e.type !== FIELD_TYPE.BUTTON_ARRAY_VOTERS_BOX &&
            (showActions === undefined || showActions)
          )
            if (actionsStartRow) {
              if (index !== 0) selectRow(data._id);
            } else {
              selectRow(data._id);
            }
        }}
      >
        <div>
          {index === 0 && (
            <div
              onClick={() => {
                if (actionsStartRow) {
                  selectRow(data._id);
                }
              }}
              className={
                selectedRows.indexOf(data._id) !== -1
                  ? 'checkbox active'
                  : 'checkbox'
              }
            >
              <GoCheck></GoCheck>
            </div>
          )}
          {index === 0 && actionsStartRow && (
            <div className="action-buttons actions-start">
              <Trash
                onClick={() => {
                  console.log(data);
                  console.log(data._id);
                  openDelete(data._id);
                }}
              ></Trash>
            </div>
          )}
          {field(e.dataIndex, data, columns[index], () => {})}
        </div>
      </th>
    );
  });
};

const cellActions = (
  data: any,
  columns: any,
  toEdit: any,
  openDelete: any,
  showOnlyDelete: boolean,
) => {
  return (
    <th
      className={
        (showOnlyDelete
          ? 'only-delete-icon  text-align-right action-buttons'
          : ' ') + 'text-align-right action-buttons'
      }
    >
      <div>
        {!showOnlyDelete && (
          <EditIcon
            onClick={() => {
              toEdit(data._id);
            }}
          ></EditIcon>
        )}

        <Trash
          onClick={() => {
            console.log(data);
            console.log(data._id);
            openDelete(data._id);
          }}
        ></Trash>
      </div>
    </th>
  );
};

const field = (e: any, data: any, column: any, actionFun?: any) => {
  switch (column.type) {
    case FIELD_TYPE.OBJECT_TEXT:
      if (data[e] !== null)
        return (
          <div
            className="text-bold"
            onClick={() => {
              column.fun(data);
            }}
          >
            {data[e].name}
          </div>
        );
      else
        return (
          <div>
            <Button
              variant="primary"
              onClick={() => {
                column.fun(data);
              }}
            >
              Asignar
            </Button>
          </div>
        );
    case FIELD_TYPE.SEX:
      return <div>{getSexWithIcon(data[e])}</div>;
    case FIELD_TYPE.TEXT:
      return <div>{data[e]}</div>;
    case FIELD_TYPE.TEXT_LINK:
      if (isArray(data[e])) {
        return (
          <div className="text-link" onClick={column.fun}>
            Ir a rutas
          </div>
        );
      }
      return <div>No hay rutas</div>;
    case FIELD_TYPE.TEXT_BOLD:
      const { text = '', houseDone = 0 } = data[e];
      return (
        <div>
          <strong>{text}</strong> <span>({houseDone} Casas)</span>
        </div>
      );
    case FIELD_TYPE.PERCENTAJE_PROGRESS:
      return (
        <div className="box-percentaje">
          <div className="box-percentaje-value">{data[e] * 100}%</div>
          <ProgressBar
            className="box-progress"
            variant="success"
            now={data[e] * 100}
          />
        </div>
      );
    case FIELD_TYPE.TEXT_ARRAY:
      return <div>{getArrayText(data[e])}</div>;
    case FIELD_TYPE.OBJECT_ARRAY:
      return <div>{getArrayObject(data[e], 'name')}</div>;
    case FIELD_TYPE.COUNT_LINK:
      return (
        <Button
          variant="primary"
          className="box-counter"
          onClick={() => column.fun(data[e])}
          // onClick={actionFun[e]}
        >
          {data[e].members.length}
        </Button>
      );
    case FIELD_TYPE.IMAGE_TEXT:
      return (
        <div>
          <span className="initials">{getInitials(data[e])}</span>
          {data[e]}
        </div>
      );
    case FIELD_TYPE.IMAGE_TEXT_TWO_INITIALS:
      return (
        <div>
          <span className="initials">{getFirstTwoInitials(data[e])}</span>
          {data[e]}
        </div>
      );
    case FIELD_TYPE.BUTTON_ARRAY_VOTERS_BOX:
      if (data[e].length > 0)
        return (
          <div
            className="text-bold"
            onClick={() => {
              column.fun(data);
            }}
          >
            {getArrayObject(data[e], 'number')}
          </div>
        );
      else
        return (
          <div>
            <Button
              variant="primary"
              onClick={() => {
                column.fun(data);
              }}
            >
              Asignar
            </Button>
          </div>
        );
    case FIELD_TYPE.BUTTON_ARRAY:
      if (data[e].length > 0)
        return <div className="text-bold">{getArrayText(data[e])}</div>;
      else
        return (
          <div>
            <Button
              variant="primary"
              onClick={() => {
                column.fun(data);
              }}
            >
              Asignar
            </Button>
          </div>
        );
    case FIELD_TYPE.BUTTON_TEXT:
      if (data[e] !== '') return <div className="text-bold">{data[e]}</div>;
      else
        return (
          <div>
            <Button variant="primary" onClick={actionFun[e]}>
              Asignar
            </Button>
          </div>
        );
    case FIELD_TYPE.COUNTER_BOX:
      return (
        <Button
          variant="primary"
          className={data[e].length > 0 ? 'box-counter' : ''}
          // onClick={actionFun[e]}
        >
          {data[e].length}
        </Button>
      );
    case FIELD_TYPE.PORCENTAJE:
      return (
        <div>
          {data[e] === 100 && <CorrectIcon></CorrectIcon>}
          {data[e]}%
        </div>
      );
    case FIELD_TYPE.SOCIAL_BAR:
      return (
        <div>
          <ProgressBar>
            {data[e] >= 1 && <ProgressBar variant="success" now={25} key={1} />}
            {data[e] >= 2 && <ProgressBar variant="info" now={25} key={2} />}
            {data[e] >= 3 && <ProgressBar variant="warning" now={25} key={3} />}
            {data[e] >= 4 && <ProgressBar variant="danger" now={25} key={4} />}
          </ProgressBar>
        </div>
      );
    case FIELD_TYPE.SOCIAL_NAME_ICON:
      return getSocialNetwork(data[e]);
    case FIELD_TYPE.WHATSAPP:
      return (
        <Fragment>
          <Figure
            className="social-network-container"
            onClick={() => {
              console.log('pending whatsapp functionality');
              console.log(data[e]);
            }}
          >
            <WhatsappIcon className="social-network-icon"></WhatsappIcon>
          </Figure>
        </Fragment>
      );
    default:
      return null;
  }
};

const getInitials = (value: string) => {
  let result = '';
  const array = value.split(' ');
  array.forEach((e) => {
    result = result + e.slice(0, 1);
  });
  return result;
};

const getFirstTwoInitials = (value: string) => {
  let result = '';
  const array = value.split(' ');
  array.forEach((e, idx) => {
    if (idx < 2) {
      result = result + e.slice(0, 1);
    }
  });
  return result;
};

const getArrayObject = (value: any[], valueName: string) => {
  let result = '';

  value.forEach((e) => {
    if (result === '') {
      result = e[valueName];
    } else {
      result = result + ', ' + e[valueName];
    }
  });
  return result;
};

const getArrayText = (value: string[]) => {
  let result = '';

  value.forEach((e) => {
    if (result === '') {
      result = e;
    } else {
      result = result + ', ' + e;
    }
  });
  return result;
};

const dropdown = (header: HeaderTeamRg) => {
  return (
    <Dropdown
      as={ButtonGroup}
      id={`dropdown-button-drop-down`}
      className="dropdown-options"
    >
      <Dropdown.Toggle id="dropdown-custom-marker">
        <Row className="h100 w100" noGutters>
          <BsThreeDots />
        </Row>
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-body">
        <Dropdown.Item
          eventKey="1"
          onClick={() => {
            header.deleteFun(header.rg);
            // closePopusOnClick(ref);
            // openDelete([id]);
          }}
        >
          Eliminar
        </Dropdown.Item>
        <Dropdown.Item
          eventKey="1"
          onClick={() => {
            header.editFun(header.rg);
            // closePopusOnClick(ref);
            // openEdit(id);
          }}
        >
          Editar
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

const getSocialNetwork = (network: number): any => {
  let networkName = '';
  let networkImg;
  switch (Number(network)) {
    case SOCIAL_NETWORK.FACEBOOK:
      networkName = 'Facebook';
      networkImg = (
        <FacebookIcon className="social-network-icon"></FacebookIcon>
      );
      break;
    case SOCIAL_NETWORK.INSTAGRAM:
      networkName = 'Instagram';
      networkImg = (
        <InstagramIcon className="social-network-icon"></InstagramIcon>
      );
      break;
    case SOCIAL_NETWORK.TIKTOK:
      networkName = 'TikTok';
      networkImg = <TiktokIcon className="social-network-icon"></TiktokIcon>;
      break;
    case SOCIAL_NETWORK.TWITTER:
      networkName = 'Twitter';
      networkImg = <FaTwitter className="social-network-icon" />;
      break;
    case SOCIAL_NETWORK.ONLYFANS:
      networkName = 'Only Fans';
      networkImg = <DiOpera className="social-network-icon" />;
      break;
    default:
      break;
  }

  return (
    <Fragment>
      <Figure className="social-network-container">{networkImg}</Figure>
      <span className="social-network-name"> {networkName} </span>
    </Fragment>
  );
};

export default NewTableComponent;
