import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../style.scss";
import StaticModal from "../../../dialogs/StaticModal";

interface ModalDeleteQuestionProps {
  modalShow: any;
  setModalShow: any;
  questionIndex: any;
  successFun: any;
  t: any;
}

export const ModalDeleteQuestion: React.FunctionComponent<ModalDeleteQuestionProps> = ({
  modalShow,
  setModalShow,
  questionIndex,
  successFun,
  t,
}: any) => {
  const submitFun = () => {
    successFun(questionIndex);
  };
  return (
    <StaticModal
      show={modalShow}
      title={t("settings.trainings.modal.deleteQuestion.title")}
      closeText={t("settings.trainings.modal.button.cancel")}
      confirmText={t("settings.trainings.modal.button.yesDelete")}
      onHide={() => setModalShow(false)}
      submitFun={submitFun}
      validBtn={false}
      size="sm"
    >
      <Row noGutters className="modal-text-row">
        <Col xs={12} className="description-col">
          {t("settings.trainings.modal.deleteQuestion.body")}
        </Col>
      </Row>
    </StaticModal>
  );
};

export default ModalDeleteQuestion;
