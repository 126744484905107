import React, { Fragment, useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../redux/Store';
import { useTranslation } from 'react-i18next';

import { INCIDENCE_SELECTED } from '../../../redux/incidence/incidenceTypes';

import ReactPlayer from 'react-player';
import moment from 'moment';

import ModalAssignLawyer from './assignIncidence';
import { DownloadIcon, BackIcon } from '../../../assets/svg/index';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';

interface IncidenceDetailProps {
  setDetailView: any;
}

const IncidenceDetail: React.FunctionComponent<IncidenceDetailProps> = ({
  setDetailView,
}: any) => {
  const selectedIncidence: any = useSelector(
    (globalState: RootStore) => globalState.incidence.selected,
  );

  const { t } = useTranslation();
  const dispatch = useDispatch();

  let defaultValue: any;

  const [modalAssignLawyer, setModalAssignLawyer] = useState(false);

  const getAddress = (address: any) => {
    let result = '';
    const newAddress = address;
    if (newAddress) {
      if (newAddress.street && newAddress.street !== '')
        result = newAddress.street;
      if (newAddress.exteriorNumber && newAddress.exteriorNumber !== '')
        result = result + `, ${newAddress.exteriorNumber}`;
      if (newAddress.section && newAddress.section !== '')
        result = result + `, ${newAddress.section}`;
      if (newAddress.district && newAddress.district !== '')
        result = result + `, ${newAddress.district}`;
    }
    return result;
  };

  return (
    <Fragment>
      <div className="detail-list">
        <div className="polling-station-detail-container">
          <div className="box-row box-row-main">
            <h1 className="main-title">
              {t('dday.problems.lbl.pollingStationInformation')}
            </h1>
            <div
              className="return-arrow"
              onClick={() => {
                dispatch({
                  type: INCIDENCE_SELECTED,
                  payload: {},
                });
                setDetailView(defaultValue);
              }}
            >
              <BackIcon></BackIcon>
            </div>
          </div>
          <div className="box-row">
            <div className="box-title">{t('dday.problems.lbl.number')}</div>
            <p>{selectedIncidence.voterBox.number}</p>
          </div>
          <div className="box-row">
            <div className="box-title">{t('dday.problems.lbl.ubication')}</div>
            <p>{getAddress(selectedIncidence.voterBox.address)}</p>
          </div>
          <div className="box-row box-row-full">
            <div className="box-title">{t('dday.problems.lbl.rgrc')}</div>
            <div className="box-subcontent">
              <div className="subcontent-left">
                <p>
                  <strong>{t('electoral.polling.table.rg')}:</strong>
                </p>
              </div>
              <p>
                {selectedIncidence.voterBox.rg._id
                  ? selectedIncidence.voterBox.rg.firstName +
                    ' ' +
                    selectedIncidence.voterBox.rg.lastName
                  : '-'}
              </p>
            </div>
            <div className="box-subcontent">
              <div className="subcontent-left">
                <p>
                  <strong>{t('electoral.polling.table.rc')}:</strong>
                </p>
              </div>
              <p>
                {selectedIncidence.voterBox.rc._id
                  ? selectedIncidence.voterBox.rc.firstName +
                    ' ' +
                    selectedIncidence.voterBox.rc.lastName
                  : '-'}
              </p>
            </div>
          </div>
          <div className="box-row">
            <div className="box-title">
              {t('dday.problems.lbl.openingTime')}
            </div>
            <p>
              {selectedIncidence.voterBox.openDate
                ? moment(selectedIncidence.voterBox.openDate).format('LT')
                : '-'}
            </p>
          </div>
        </div>

        <div className="incidence-detail-container">
          <div className="box-row box-row-main">
            <h1 className="main-title">
              {t('dday.problems.lbl.incidenceInformation')}
            </h1>
            <div className="download-row">
              <Button
                className="template-button"
                variant="outline-dark"
                onClick={() => {}}
              >
                <DownloadIcon></DownloadIcon>
                {t('dday.problems.lbl.PDF')}
              </Button>
            </div>
          </div>
          <div className="box-row">
            <div className="box-sub-row">
              <div className="box-left-side">
                <div className="box-title">
                  {t('dday.problems.lbl.reportedBy')}
                </div>
                <div className="box-subcontent">
                  <div className="subcontent-left">
                    <p>
                      <strong>{t('electoral.polling.table.rg')}:</strong>
                    </p>
                  </div>
                  <p>{'Paul Fajardo'}</p>
                </div>
              </div>
              <div className="box-rigth-side">
                <div className="subcontent-left">
                  <p>
                    <strong>{t('dday.problems.lbl.time')}:</strong>
                  </p>
                </div>
                <p>{'10:00 am'}</p>
              </div>
            </div>
          </div>
          <div className="box-row">
            <div className="box-sub-row">
              <div className="box-left-side">
                <div className="box-title">
                  {t('dday.problems.table.status')}
                </div>
                <p>{'Sin Asignar'}</p>
              </div>
              <div className="box-middle-side">
                <Button
                  variant="primary"
                  onClick={() => {
                    setModalAssignLawyer(true);
                  }}
                >
                  {t('general.table.btn.assign')}
                </Button>
              </div>
            </div>
          </div>
          <div className="box-row box-row-media">
            <div className="box-title">{t('dday.problems.lbl.evidences')}</div>
            {selectedIncidence.pictures.length > 0 && (
              <div className="box-sub-row box-sub-row-media">
                <div className="box-subcontent">
                  <div className="subcontent-left">
                    <p>
                      <strong>{t('dday.problems.lbl.photos')}:</strong>
                    </p>
                  </div>
                </div>
                <div className="box-sub-row box-sub-row-media-content">
                  {selectedIncidence.pictures?.map((e: any) => {
                    return (
                      <div className="img-box" key={'image-box-' + e}>
                        <Image
                          key={'image-' + e}
                          className="incidence-img"
                          src={e}
                          fluid
                          onClick={() => {}}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {selectedIncidence.videos.length > 0 && (
              <div className="box-sub-row box-sub-row-media">
                <div className="box-subcontent">
                  <div className="subcontent-left">
                    <p>
                      <strong>{t('dday.problems.lbl.videos')}:</strong>
                    </p>
                  </div>
                </div>
                <div className="box-sub-row box-sub-row-media-content">
                  {selectedIncidence.videos?.map((e: any) => {
                    return (
                      <div className="video-box" key={'video-box-' + e}>
                        <ReactPlayer
                          key={'image-' + e}
                          className="incidence-img"
                          url={e}
                          width="100%"
                          height="100%"
                          controls={true}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {selectedIncidence.audios.length > 0 && (
              <div className="box-sub-row box-sub-row-media">
                <div className="box-subcontent">
                  <div className="subcontent-left">
                    <p>
                      <strong>{t('dday.problems.lbl.audios')}:</strong>
                    </p>
                  </div>
                </div>
                <div className="box-sub-row box-sub-row-media-content">
                  {selectedIncidence.audios?.map((e: any) => {
                    return (
                      <div className="audio-box" key={'audio-box-' + e}>
                        <ReactPlayer
                          key={'image-' + e}
                          url={e}
                          width="100%"
                          height="100%"
                          controls={true}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {modalAssignLawyer && (
        <ModalAssignLawyer
          modalShow={modalAssignLawyer}
          setModalShow={setModalAssignLawyer}
          edit={selectedIncidence}
          successFun={() => {}}
        ></ModalAssignLawyer>
      )}
    </Fragment>
  );
};

export default IncidenceDetail;
