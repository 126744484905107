import {
  VOTERSBOX_FAIL,
  VOTERSBOX_LOADING,
  VOTERSBOX_SUCCESS,
  VOTERSBOX_COUNT_SUCCESS,
  VOTERSBOX_SELECT_SUCCESS,
  //=====================
  SearchVoterBoxData,
  SearchNumberInUse,
  //=====================
  VOTERSBOX_MAP_FAIL,
  VOTERSBOX_MAP_LOADING,
  VOTERSBOX_MAP_SUCCESS,
  VOTERSBOX_MAP_COUNT_SUCCESS,
  //=====================
  SearchVoterBoxMapData,
  //=====================
  VOTERSBOX_ELECTORAL_FAIL,
  VOTERSBOX_ELECTORAL_LOADING,
  VOTERSBOX_ELECTORAL_SUCCESS,
  VOTERSBOX_ELECTORAL_COUNT_SUCCESS,
  VOTERSBOX_ELECTORAL_SELECT_SUCCESS,
  //=====================
  VOTERSBOX_ELECTORAL_MAP_FAIL,
  VOTERSBOX_ELECTORAL_MAP_LOADING,
  VOTERSBOX_ELECTORAL_MAP_SUCCESS,
  VOTERSBOX_ELECTORAL_MAP_COUNT_SUCCESS,
  VOTERSBOX_ELECTORAL_MAP_SELECT_SUCCESS,
  //=====================
} from "./VotersBoxTypes";
import * as netWorking from "../netWorking";

export function getVotersBoxByCoordinates(data: SearchVoterBoxMapData) {
  return (dispatch: any) => {
      return netWorking.post(
        netWorking.getVotersBoxByCoordinates,
        data,
        (res: any) => {
          return dispatch({
              type: VOTERSBOX_MAP_SUCCESS,
              payload: res.data.response
          });
        },
        (error: any) => {
          return dispatch({
            type: VOTERSBOX_MAP_FAIL,
          });
        }
      );
  };
}

export function getVotersBoxTotalByCoordinates(data: SearchVoterBoxMapData) {
  return (dispatch: any) => {
      return netWorking.post(
        netWorking.getTotalVotersBoxByCoordinates,
        data,
        (res: any) => {
          return dispatch({
              type: VOTERSBOX_MAP_COUNT_SUCCESS,
              payload: res.data.response
          });
        },
        (error: any) => {
          return dispatch({
            type: VOTERSBOX_MAP_FAIL,
          });
        }
      );
  };
}

export function getVotersBox(data: SearchVoterBoxData) {
  return (dispatch: any) => {
    dispatch({
      type: VOTERSBOX_LOADING,
    });
    return netWorking.post(
      netWorking.getVotersBoxBySection,
      data,
      (res: any) => {
        return dispatch({
            type: VOTERSBOX_SUCCESS,
            payload: res.data.response
        });
      },
      (error: any) => {
        return dispatch({
          type: VOTERSBOX_FAIL,
        });
      }
    );
  };
}

export function getVotersBoxTotal(data: SearchVoterBoxData) {
  return (dispatch: any) => {
      return netWorking.post(
        netWorking.getTotalVotersBoxBySection,
        data,
        (res: any) => {
          return dispatch({
              type: VOTERSBOX_COUNT_SUCCESS,
              payload: res.data.response
          });
        },
        (error: any) => {
          return dispatch({
            type: VOTERSBOX_FAIL,
          });
        }
      );
  };
}

export function setSelectedValues(selected: any){
  return async (dispatch: any) => {
  dispatch({
      type: VOTERSBOX_SELECT_SUCCESS,
      selected: selected,
  });
  }
}

export function checkPollingInUse(data: SearchNumberInUse, successFun: any){
  return async (dispatch: any) => {
    return netWorking.post(
      netWorking.getNumberInUse,
      data,
      (res: any) => {
        successFun(res.data.response);
      },
      (error: any) => {
        console.log(error);
      },
    );
  }
};
//==========================================================================
export function getVotersBoxElectoralByCoordinates(data: SearchVoterBoxMapData) {
  return (dispatch: any) => {
      return netWorking.post(
        netWorking.getVotersBoxElectoralByCoordinates,
        data,
        (res: any) => {
          return dispatch({
              type: VOTERSBOX_ELECTORAL_MAP_SUCCESS,
              payload: res.data.response
          });
        },
        (error: any) => {
          return dispatch({
            type: VOTERSBOX_ELECTORAL_MAP_FAIL,
          });
        }
      );
  };
}

export function getVotersBoxElectoralTotalByCoordinates(data: SearchVoterBoxMapData) {
  return (dispatch: any) => {
      return netWorking.post(
        netWorking.getTotalVotersBoxElectoralByCoordinates,
        data,
        (res: any) => {
          return dispatch({
              type: VOTERSBOX_ELECTORAL_MAP_COUNT_SUCCESS,
              payload: res.data.response
          });
        },
        (error: any) => {
          return dispatch({
            type: VOTERSBOX_ELECTORAL_MAP_FAIL,
          });
        }
      );
  };
}

export function getVotersBoxElectoral(data: SearchVoterBoxData) {
  return (dispatch: any) => {
      dispatch({
        type: VOTERSBOX_ELECTORAL_LOADING,
      });
      return netWorking.post(
        netWorking.getVotersBoxBySection,
        data,
        (res: any) => {
          return dispatch({
              type: VOTERSBOX_ELECTORAL_SUCCESS,
              payload: res.data.response
          });
        },
        (error: any) => {
          return dispatch({
            type: VOTERSBOX_ELECTORAL_FAIL,
          });
        }
      );
  };
}

export function getVotersBoxElectoralTotal(data: SearchVoterBoxData) {
  return (dispatch: any) => {
      return netWorking.post(
        netWorking.getTotalVotersBoxBySection,
        data,
        (res: any) => {
          return dispatch({
              type: VOTERSBOX_ELECTORAL_COUNT_SUCCESS,
              payload: res.data.response
          });
        },
        (error: any) => {
          return dispatch({
            type: VOTERSBOX_ELECTORAL_FAIL,
          });
        }
      );
  };
}

export function setSelectedValuesElectoral(selected: any){
  return async (dispatch: any) => {
  dispatch({
      type: VOTERSBOX_ELECTORAL_SELECT_SUCCESS,
      selected: selected,
  });
  }
}

export function setSelectedValuesElectoralMap(selected: any){
  return async (dispatch: any) => {
  dispatch({
      type: VOTERSBOX_ELECTORAL_MAP_SELECT_SUCCESS,
      selected: selected,
  });
  }
}

