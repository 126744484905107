import React, { FunctionComponent } from 'react';
import '../style.scss';
import Button from 'react-bootstrap/Button';

interface TopSelectProps {
  children?: any;
  submit: any;
  title: string;
  btnText: string;
}

const Top: FunctionComponent<TopSelectProps> = ({
  children,
  submit,
  title,
  btnText,
}: any) => {
  return (
    <div className="top-bar-s">
      <div className="title">
        <h2>{title}</h2>
      </div>
      <div className="selects">
        {children}
        {btnText !== '' && (
          <Button className="c-button" variant="primary" onClick={submit}>
            {btnText}
          </Button>
        )}
      </div>
    </div>
  );
};

export default Top;
