import React from 'react';
// import RecoverEmail from './RecoverEmail';
// import ResetPassword from './ResetPassword';
// import VerifyEmail from './VerifyEmail';
import PasswordConfirmationView from './PasswordConfirmation';
import queryString from 'query-string';
import { Redirect } from 'react-router-dom';
import { PRIVATE_DASHBOARD, PRIVATE_VOTERS } from '../../utils/paths';
import store from '../../redux/Store';
import { SET_SUPER_ADMIN, SET_UNAUTHENTICATED } from '../../redux/types';
// http://localhost:3000/action?mode=resetPassword&oobCode=ABC123&apiKey=AIzaSy

// mode - The user management action to be completed
// oobCode - A one-time code, used to identify and verify a request
// apiKey - Your Firebase project's API key, provided for convenience

const Action = (props: any) => {
  // Get the action to complete.
  //   const mode = props.location.query.mode;
  let params = queryString.parse(props.location.search);
  //   // Get the one-time code from the query parameter.
  //   const actionCode = props.location.query.oobCode;

  //   // (Optional) Get the API key from the query parameter.
  //   // const apiKey = props.location.query.apiKey;

  //   // Handle the user management action.

  // sentToLogin = () =>{
  //   this.props.history.push("/");
  // }
  switch (params.mode) {
    case 'resetPassword':
      // Display email recovery handler and UI.
      localStorage.removeItem('token');
      // window.location.href = '/login';
      store.dispatch({
        type: SET_UNAUTHENTICATED,
      });
      store.dispatch({
        type: SET_SUPER_ADMIN,
        payload: false,
      });
      localStorage.setItem('logoutEvent', 'logout' + Math.random());
      return (
        <PasswordConfirmationView
          t={props.t}
          actionCode={params.oobCode}
          apiKey={params.apiKey}
          history={props.history}
        />
      );
    default:
      return <Redirect to={PRIVATE_VOTERS} />;
  }
};

export default Action;
