import {
  VOTERSBOX_FAIL,
  VOTERSBOX_LOADING,
  VOTERSBOX_SUCCESS,
  VOTERSBOX_COUNT_SUCCESS,
  VOTERSBOX_SELECT_SUCCESS,
  VOTERSBOX_SEARCH_SUCCESS,
  //===========================
  VOTERSBOX_MAP_FAIL,
  VOTERSBOX_MAP_LOADING,
  VOTERSBOX_MAP_SUCCESS,
  VOTERSBOX_MAP_COUNT_SUCCESS,
  VOTERSBOX_MAP_SEARCH_SUCCESS,
  //===========================
  VOTERSBOX_ELECTORAL_FAIL,
  VOTERSBOX_ELECTORAL_LOADING,
  VOTERSBOX_ELECTORAL_SUCCESS,
  VOTERSBOX_ELECTORAL_COUNT_SUCCESS,
  VOTERSBOX_ELECTORAL_SELECT_SUCCESS,
  VOTERSBOX_ELECTORAL_SEARCH_SUCCESS,
  //===========================
  VOTERSBOX_ELECTORAL_MAP_FAIL,
  VOTERSBOX_ELECTORAL_MAP_LOADING,
  VOTERSBOX_ELECTORAL_MAP_SUCCESS,
  VOTERSBOX_ELECTORAL_MAP_COUNT_SUCCESS,
  VOTERSBOX_ELECTORAL_MAP_SELECT_SUCCESS,
  VOTERSBOX_ELECTORAL_MAP_SEARCH_SUCCESS,
  //===========================
  TAB_CHANGE,
  TAB_ELECTORAL_CHANGE,
  //===========================
  VOTERSBOX_DDAY_LOADING,
  VOTERSBOX_DDAY_FAIL,
  VOTERSBOX_DDAY_ALL,
  VOTERSBOX_DDAY_CREATE,
  VOTERSBOX_DDAY_UPDATE,
  VOTERSBOX_DDAY_SEARCH_SUCCESS,
  //===========================
  VotersBoxDispatchTypes,
} from './VotersBoxTypes';

interface VotersBoxState {
  loading: boolean;
  page?: number;
  records?: number;
  sort?: any[];
  totalPage: number;
  totalVoterBoxs: number;
  voterBoxs: any[];
  sectionSelected: object;
  search: string;
  //===========================
  loadingMap: boolean;
  totalVoterBoxsMap: number;
  voterBoxsMap: any[];
  searchMap: string;
  //===========================
  loadingElectoral: boolean;
  pageElectoral?: number;
  recordsElectoral?: number;
  sortElectoral?: any[];
  filtersElectoral?: object;
  totalPageElectoral: number;
  totalVoterBoxsElectoral: number;
  voterBoxsElectoral: any[];
  sectionSelectedElectoral: object;
  searchElectoral: string;
  //===========================
  loadingElectoralMap: boolean;
  totalVoterBoxsElectoralMap: number;
  voterBoxsElectoralMap: any[];
  sectionSelectedElectoralMap: object;
  searchElectoralMap: string;
  //===========================
  tabChange: string;
  tabElectoralChange: string;
  //===========================
  loadingDiaD: boolean;
  filtersDiaD?: object;
  voterBoxsDiaD: any[];
  searchDiaD: string;
}

const defaultState: VotersBoxState = {
  loading: false,
  page: 1,
  records: 10,
  sort: ['voterBox', 'asc'],
  totalPage: 1,
  totalVoterBoxs: 0,
  voterBoxs: [],
  sectionSelected: { section: '', district: '', municipality: '' },
  search: '',
  //===========================
  loadingMap: false,
  totalVoterBoxsMap: 0,
  voterBoxsMap: [],
  searchMap: '',
  //===========================
  loadingElectoral: false,
  pageElectoral: 1,
  recordsElectoral: 10,
  sortElectoral: ['voterBox', 'asc'],
  filtersElectoral: { polling: true, rcs: true },
  totalPageElectoral: 1,
  totalVoterBoxsElectoral: 0,
  voterBoxsElectoral: [],
  sectionSelectedElectoral: { section: '', district: '', municipality: '' },
  searchElectoral: '',
  //===========================
  loadingElectoralMap: false,
  totalVoterBoxsElectoralMap: 0,
  voterBoxsElectoralMap: [],
  sectionSelectedElectoralMap: { section: '', district: '', municipality: '' },
  searchElectoralMap: '',
  //===========================
  tabChange: 'settings.polling.tab.1',
  tabElectoralChange: 'electoral.polling.tab.1',
  //===========================
  loadingDiaD: false,
  filtersDiaD: { open: true, close: true, problem: true },
  voterBoxsDiaD: [],
  searchDiaD: '',
};

const votersBoxReducer = (
  state: VotersBoxState = defaultState,
  action: VotersBoxDispatchTypes,
): VotersBoxState => {
  switch (action.type) {
    case VOTERSBOX_FAIL:
      return {
        ...state,
        loading: false,
      };
    case VOTERSBOX_LOADING:
      return {
        ...state,
        loading: true,
      };
    case VOTERSBOX_SUCCESS:
      const list = [];
      const dataSuccess: any = action.payload;
      list.push(...(dataSuccess.voterBoxs || []));
      return {
        ...state,
        voterBoxs: list,
        records: dataSuccess.records,
        sort: ['voterBox', dataSuccess.order],
        page: dataSuccess.page,
        loading: false,
      };
    case VOTERSBOX_COUNT_SUCCESS:
      const countSuccess: any = action.payload;
      return {
        ...state,
        totalPage: countSuccess.pages,
        totalVoterBoxs: countSuccess.totalVB,
      };
    case VOTERSBOX_SELECT_SUCCESS:
      const selectSuccess: any = action.selected;
      return {
        ...state,
        sectionSelected: selectSuccess,
      };
    case VOTERSBOX_SEARCH_SUCCESS:
      return {
        ...state,
        search: action.searchValue,
      };
    //================================================
    case VOTERSBOX_MAP_FAIL:
      return {
        ...state,
        loadingMap: false,
      };
    case VOTERSBOX_MAP_LOADING:
      return {
        ...state,
        loadingMap: true,
      };
    case VOTERSBOX_MAP_SUCCESS:
      const listMap = [];
      const dataSuccessMap: any = action.payload;
      listMap.push(...(dataSuccessMap.voterBoxs || []));
      return {
        ...state,
        voterBoxsMap: listMap,
      };
    case VOTERSBOX_MAP_COUNT_SUCCESS:
      const countSuccessMap: any = action.payload;
      return {
        ...state,
        totalVoterBoxsMap: countSuccessMap.totalVB,
      };
    case VOTERSBOX_MAP_SEARCH_SUCCESS:
      return {
        ...state,
        searchMap: action.searchValue,
      };
    //================================================
    case VOTERSBOX_ELECTORAL_FAIL:
      return {
        ...state,
        loadingElectoral: false,
      };
    case VOTERSBOX_ELECTORAL_LOADING:
      return {
        ...state,
        loadingElectoral: true,
      };
    case VOTERSBOX_ELECTORAL_SUCCESS:
      const listElectoral = [];
      const dataSuccessElectoral: any = action.payload;
      listElectoral.push(...(dataSuccessElectoral.voterBoxs || []));
      return {
        ...state,
        voterBoxsElectoral: listElectoral,
        recordsElectoral: dataSuccessElectoral.records,
        sortElectoral: ['voterBox', dataSuccessElectoral.order],
        pageElectoral: dataSuccessElectoral.page,
        filtersElectoral: dataSuccessElectoral.filters,
        loadingElectoral: false,
      };
    case VOTERSBOX_ELECTORAL_COUNT_SUCCESS:
      const countSuccessElectoral: any = action.payload;
      return {
        ...state,
        totalPageElectoral: countSuccessElectoral.pages,
        totalVoterBoxsElectoral: countSuccessElectoral.totalVB,
      };
    case VOTERSBOX_ELECTORAL_SELECT_SUCCESS:
      const selectSuccessElectoral: any = action.selected;
      return {
        ...state,
        sectionSelectedElectoral: selectSuccessElectoral,
      };
    case VOTERSBOX_ELECTORAL_SEARCH_SUCCESS:
      return {
        ...state,
        searchElectoral: action.searchValue,
      };
    //================================================
    case VOTERSBOX_ELECTORAL_MAP_FAIL:
      return {
        ...state,
        loadingElectoralMap: false,
      };
    case VOTERSBOX_ELECTORAL_MAP_LOADING:
      return {
        ...state,
        loadingElectoralMap: true,
      };
    case VOTERSBOX_ELECTORAL_MAP_SUCCESS:
      let listElectoralMap = [];
      const dataSuccessElectoralMap: any = action.payload;
      listElectoralMap.push(...(dataSuccessElectoralMap.voterBoxs || []));
      return {
        ...state,
        voterBoxsElectoralMap: listElectoralMap,
      };
    case VOTERSBOX_ELECTORAL_MAP_COUNT_SUCCESS:
      const countSuccessElectoralMap: any = action.payload;
      return {
        ...state,
        totalVoterBoxsElectoralMap: countSuccessElectoralMap.totalVB,
      };
    case VOTERSBOX_ELECTORAL_MAP_SELECT_SUCCESS:
      const selectSuccessElectoralMap: any = action.selected;
      return {
        ...state,
        sectionSelectedElectoralMap: selectSuccessElectoralMap,
      };
    case VOTERSBOX_ELECTORAL_MAP_SEARCH_SUCCESS:
      return {
        ...state,
        searchElectoralMap: action.searchValue,
      };
    //================================================
    case TAB_CHANGE:
      return {
        ...state,
        tabChange: action.tabChange,
      };
    case TAB_ELECTORAL_CHANGE:
      return {
        ...state,
        tabElectoralChange: action.tabChange,
      };

    default:
      return state;
  }
};

export default votersBoxReducer;
