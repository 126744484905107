import React, { FunctionComponent } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../style.scss';
import StaticModal from '../../../dialogs/StaticModal';
import * as netWorking from '../../../redux/netWorking';
import { useTranslation } from 'react-i18next';
import { Container } from 'react-bootstrap';

interface ModalDeleteProps {
  modalShow: any;
  setModalShow: any;
  toDelete: any;
  tempVoters: any;
  successFun: any;
  upDataId: string;
}

export const ModalDeleteUpData: FunctionComponent<ModalDeleteProps> = ({
  modalShow,
  setModalShow,
  toDelete,
  tempVoters,
  successFun,
  upDataId,
}: any) => {
  const { t } = useTranslation();
  const submitFun = () => {
    if (toDelete.length > 0) {
      netWorking.post(
        netWorking.deleteTempVoters,
        {
          toDelete,
          upDataId,
        },
        () => {
          successFun();
          setModalShow(false);
        },
        () => {},
      );
    }
    successFun();
    setModalShow(false);
  };

  const getNames = () => {
    let text = '';
    if (tempVoters !== undefined)
      for (const tv of tempVoters) {
        if (toDelete.indexOf(tv._id) !== -1) {
          if (text === '') text = text + ' ' + tv.firstName + ' ' + tv.lastName;
          else text = text + ', ' + tv.firstName + ' ' + tv.lastName;
        }
      }
    return text;
  };

  return (
    <StaticModal
      show={modalShow}
      title={t('settings.votersList.modal.lbl.delete.title')}
      closeText={t('submit.cancel')}
      confirmText={t('submit.yes.delete')}
      onHide={() => setModalShow(false)}
      submitFun={submitFun}
      validBtn={false}
      size="lg"
      sizeClass="md modal-rc"
    >
      <>
        <Container className="body-modal">
          <Row noGutters className="modal-text-row">
            {/* <Dropzone type={type} setRouteFile={setRouteFile}></Dropzone> */}
            <Col xs={12} className="description-col">
              {t('settings.votersList.modal.text.delete.body')}
            </Col>
            <Col xs={12} className="question-col">
              {t('settings.votersList.modal.lbl.delete.question') + ' '}
              <strong>{getNames()}</strong>?
            </Col>
          </Row>
        </Container>
      </>
    </StaticModal>
  );
};

export default ModalDeleteUpData;
