import {
  TRACKING_FAIL,
  TRACKING_LOADING,
  TRACKING_SUCCESS,
  TRACKING_TEAM_SUCCESS,
  Tracking,
  TrackingDispatchTypes,
  TRACKING_DATA_SUCCESS,
  TRACKING_COORDINATOR_CHANGE,
} from './trackingTypes';

interface Coordinator {
  _id: string;
  name: string;
  members: Array<string>;
}

interface TrackingState {
  loading: boolean;
  tracking: Tracking[];
  teamData: Array<any>;
  result?: any;
  selectedCoordinator: Coordinator;
}

const defaultState: TrackingState = {
  loading: false,
  tracking: [],
  teamData: [],
  selectedCoordinator: {
    _id: '',
    name: '',
    members: [],
  },
};

const TrackingReducer = (
  state: TrackingState = defaultState,
  action: TrackingDispatchTypes,
): TrackingState => {
  switch (action.type) {
    case TRACKING_FAIL:
      return {
        ...state,
        loading: false,
      };
    case TRACKING_LOADING:
      return {
        ...state,
        loading: true,
      };
    case TRACKING_SUCCESS:
      const list1 = [];
      list1.push(...(action.payload || []));
      return {
        ...state,
        loading: false,
        tracking: list1,
        result: action.payload2,
      };
    case TRACKING_DATA_SUCCESS:
      let coordinatorRole = state.selectedCoordinator._id;

      const dataTeamsSuccess: any = action.payload;
      const actualCoordinator = dataTeamsSuccess.teams.find(
        (team: any) => team.bossId === coordinatorRole,
      );

      const teams = [];
      teams.push(...(dataTeamsSuccess.teams || []));
      let coordinator =
        teams.length > 0
          ? !actualCoordinator && dataTeamsSuccess.isFirst
            ? {
                _id: teams[0].bossId,
                name: teams[0].name,
                members: teams[0].members,
              }
            : {
                _id: actualCoordinator.bossId,
                name: actualCoordinator.name,
                members: actualCoordinator.members,
              }
          : {
              _id: '',
              name: '',
              members: [],
            };
      return {
        ...state,
        selectedCoordinator: {
          _id: coordinator._id,
          name: coordinator.name,
          members: coordinator.members,
        },
        teamData: teams,
        loading: false,
      };
    case TRACKING_TEAM_SUCCESS:
      return {
        ...state,
      }
    case TRACKING_COORDINATOR_CHANGE:
      const selectedCoordinator = { ...action.payload };

      return {
        ...state,
        selectedCoordinator,
        loading: false,
      };
    default:
      return state;
  }
};

export default TrackingReducer;
