import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../redux/Store';
import { useTranslation } from 'react-i18next';

import { MODULE } from '../../../redux/logs/LogsTypes';
import { getLogs } from '../../../redux/logs/LogsActions';
import { DownloadIcon, LogsIcon } from '../../../assets/svg';
import {
  postVotersListByAddressElectionsUnauditedSearch,
  postGetUnauditedVotersTotal,
  getVoterAuditionById,
} from '../../../redux/auditory/AuditoryActions';

import TableComponent, {
  HEADER,
  HEADER_TYPE,
  FIELD_TYPE,
} from '../../../components/Table';
import PaginationRecords from '../../../components/PaginationRecords';
import SelectorStatusComponent from './selectorStatus';
import VoterListComponent from './voterList';
import LogsListComponent from '../../../components/logsList';
import photoIdPlaceHolderFrontNoINE from '../../../../src/assets/img/photo-id-placeholder-front-none.png';
import photoIdPlaceHolderFront from '../../../assets/img/photo-id-placeholder-front.png';
import { FemaleIcon, MaleIcon } from '../../../assets/svg/index';
import { IoIosTransgender } from 'react-icons/io';
import LoadingModal from '../../../dialogs/loadingModal';
import UploadDialog from './UploadDialog';
import Button from 'react-bootstrap/Button';
import * as netWorking from '../../../redux/netWorking';
import { getAddress, XLS_TYPE } from '../../../utils/utils';

const nextVotingElections = 2021;

const List: React.FunctionComponent = () => {
  const citySelected = useSelector((globalState: RootStore) => {
    return globalState.core.globalMunicipalitySelected;
  });

  const loading = useSelector(
    (globalState: RootStore) => globalState.auditory.loading,
  );
  const voters = useSelector(
    (globalState: RootStore) => globalState.auditory.voters,
  );
  const voterAudition: any = useSelector(
    (globalState: RootStore) => globalState.auditory.voterAudition,
  );
  const totalVoters = useSelector(
    (globalState: RootStore) => globalState.auditory.totalVoters,
  );
  const page: any = useSelector(
    (globalState: RootStore) => globalState.auditory.page,
  );
  const filter: any = useSelector(
    (globalState: RootStore) => globalState.auditory.filters,
  );
  const totalPage = useSelector(
    (globalState: RootStore) => globalState.auditory.totalPage,
  );
  const records: any = useSelector(
    (globalState: RootStore) => globalState.auditory.records,
  );
  const search = useSelector(
    (globalState: RootStore) => globalState.auditory.search,
  );
  const sort: any = useSelector(
    (globalState: RootStore) => globalState.auditory.sort,
  );

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [tableData, setTableData] = useState(Array);
  const [fieldOrder, setFieldOrder] = useState(sort[1] ? sort[1] : 'asc');
  const [fieldName, setFieldName] = useState(sort[0] ? sort[0] : 'firstName');
  const [filters, setFilters] = useState(
    filter
      ? filter
      : {
          unique: true,
          duplicated: true,
        },
  );

  const [auditorySliderShow, setAuditorySliderShow] = useState(Boolean);
  const [municipalityId, setMunicipalityId] = useState(String);
  const [toShowOnSlider, setToShowOnSlider] = useState(Array);
  const [active, setActive] = React.useState(false);
  const [showXLSDialog, setShowXLSDialog] = React.useState(false);

  const headerVotersBox: any = {};

  const searchByValue = (reset = false) => {
    const params = buildParam(
      fieldOrder,
      fieldName,
      !reset ? page : 1,
      records ? records : 10,
      search,
    );
    dispatch(postVotersListByAddressElectionsUnauditedSearch(params));
    dispatch(postGetUnauditedVotersTotal(params));
  };

  const buildParam = (
    order: string,
    field: string,
    pageNum: number,
    recordsNum: number,
    search: string,
  ) => {
    let requestObject;
    requestObject = {
      cityLicenseId: citySelected?._id || '',
      page: pageNum,
      records: recordsNum,
      field: field,
      order: order,
      search: search.length >= 3 ? search : '',
      filters: filters,
      nextElectionsYear: nextVotingElections,
    };
    return requestObject;
  };

  const goToView = (_id: string) => {
    dispatch(
      getVoterAuditionById(
        _id,
        () => {
          setAuditorySliderShow(true);
        },
        (error: any) => {
          console.log(error);
        },
      ),
    );
  };

  const transformData = () => {
    let newVoters: any = [];

    voters?.forEach((voter) => {
      voter.nextVotingElections = nextVotingElections;
      let newVoter = {
        _id: voter._id,
        name: voter.name,
        address: voter.address ? getAddress(voter.address) : '',
        districtVote: voter.districtVote ? voter.districtVote : '',
        sectionVote: voter.sectionVote ? voter.sectionVote : '',
        cellphone: voter.cellphone,
        sex:
          voter.sex === 0 ? (
            <MaleIcon></MaleIcon>
          ) : voter.sex === 1 ? (
            <FemaleIcon></FemaleIcon>
          ) : (
            <IoIosTransgender></IoIosTransgender>
          ),
        id_photo:
          voter.ineExpirationDate === '' ||
          voter.ineExpirationDate === undefined
            ? photoIdPlaceHolderFrontNoINE
            : photoIdPlaceHolderFront,
        status: voter,
        ineStatus: voter,
        visits: 0,
        visitsHistory: [],
        globalStatus: {
          registered: voter.registered,
          captured: voter.captured,
          audited: voter.audited,
        },
        duplicates: voter.duplicates,
        demo_volunteer: voter.capturer ? voter.capturer : '-',
      };
      newVoters.push(newVoter);
    });

    setTableData(newVoters);
  };

  useEffect(() => {
    if (municipalityId) {
      searchByValue(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    if (municipalityId) {
      searchByValue();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldOrder]);

  useEffect(() => {
    if (municipalityId) {
      searchByValue();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldName]);

  useEffect(() => {
    if (citySelected) {
      setMunicipalityId(citySelected._id);
    }
  }, [citySelected]);

  useEffect(() => {
    if (municipalityId) {
      searchByValue(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [municipalityId]);

  useEffect(() => {
    if (voters) {
      transformData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [voters]);

  useEffect(() => {
    if (municipalityId && (search.length >= 3 || search.length <= 0)) {
      searchByValue(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    if (!auditorySliderShow) {
      setToShowOnSlider([]);
    }
    if (!auditorySliderShow && voterAudition) {
      searchByValue();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auditorySliderShow]);

  return (
    <Fragment>
      <div className="auditory-territorial-list">
        <div className="auditory-filters-container">
          <div className="count-container">
            <h1 className="count">
              {t('territorial.auditory.lbl.pendings') +
                ' [' +
                totalVoters +
                ']'}
            </h1>
          </div>
          <div className="filters-container">
            <Button
              className="template-button"
              variant="outline-dark"
              onClick={() => {
                netWorking.downloadXls(
                  XLS_TYPE.VOTERS_TYPE,
                  citySelected?.name || '',
                );
              }}
            >
              <DownloadIcon></DownloadIcon>
              {t('general.btn.template')}
            </Button>
            <Button
              variant="primary"
              className="upload-btn"
              onClick={() => {
                setShowXLSDialog(true);
              }}
            >
              {t('territorial.auditory.btn.upload')}
            </Button>
            <div className="status">
              <SelectorStatusComponent
                title={t('territorial.auditory.lbl.show')}
                horizontal={true}
                filters={filters}
                fun={(newfilters: any) => {
                  setFilters(newfilters);
                }}
              ></SelectorStatusComponent>
            </div>
            {/*<div
              className="history-box-trigger"
              onClick={() => {
                dispatch(getLogs(t('language'), 1, 30, null, MODULE.VOTERS));
                setActive(true);
              }}
            >
              <LogsIcon></LogsIcon>
            </div>*/}
          </div>
        </div>
        <div className="auditory-container">
          <TableComponent
            onTrAction={true}
            onTrFun={(id: string) => {
              let newSelectedArray = [];
              if (id.length) {
                newSelectedArray.push(id);
              }
              setToShowOnSlider(newSelectedArray);
              goToView(id);
            }}
            showActions={undefined}
            selectedRows={toShowOnSlider}
            selectRows={() => {}}
            header={headerVotersBox}
            headerType={HEADER_TYPE.HIDDEN}
            headers={[
              {
                type: HEADER.FILTER,
                name: t('territorial.auditory.table.nameFull'),
                fun: () => {
                  let newOrder = fieldOrder === 'asc' ? 'desc' : 'asc';
                  let newField = 'newFirstName';
                  if (newField !== fieldName) newOrder = 'asc';
                  setFieldOrder(newOrder);
                  setFieldName(newField);
                },
              },
              // {
              //   type: HEADER.FILTER,
              //   name: t('territorial.auditory.table.lastname'),
              //   fun: () => {
              //     let newOrder = fieldOrder === 'asc' ? 'desc' : 'asc';
              //     let newField = 'newLastName';
              //     if (newField !== fieldName) newOrder = 'asc';
              //     setFieldOrder(newOrder);
              //     setFieldName(newField);
              //   },
              // },
              {
                type: HEADER.TEXT,
                name: t('territorial.auditory.table.address'),
                /*fun: () => {
                  let newOrder = fieldOrder === 'asc' ? 'desc' : 'asc';
                  let newField = 'newAddress';
                  if (newField != fieldName) newOrder = 'asc';
                  setFieldOrder(newOrder);
                  setFieldName(newField);
                },*/
              },
              {
                type: HEADER.FILTER,
                name: t('territorial.auditory.table.district'),
                fun: () => {
                  let newOrder = fieldOrder === 'asc' ? 'desc' : 'asc';
                  let newField = 'newDistrict';
                  if (newField !== fieldName) newOrder = 'asc';
                  setFieldOrder(newOrder);
                  setFieldName(newField);
                },
              },
              {
                type: HEADER.FILTER,
                name: t('territorial.auditory.table.section'),
                fun: () => {
                  let newOrder = fieldOrder === 'asc' ? 'desc' : 'asc';
                  let newField = 'newSection';
                  if (newField !== fieldName) newOrder = 'asc';
                  setFieldOrder(newOrder);
                  setFieldName(newField);
                },
              },
              {
                type: HEADER.FILTER,
                name: t('territorial.auditory.table.cellphone'),
                fun: () => {
                  let newOrder = fieldOrder === 'asc' ? 'desc' : 'asc';
                  let newField = 'newCellphone';
                  if (newField !== fieldName) newOrder = 'asc';
                  setFieldOrder(newOrder);
                  setFieldName(newField);
                },
              },
              {
                type: HEADER.TEXT,
                name: t('territorial.auditory.table.sex'),
              },
              {
                type: HEADER.TEXT,
                name: t('territorial.auditory.table.volunteer'),
              },
              {
                type: HEADER.TEXT,
                name: t('territorial.auditory.table.IDphoto'),
              },
            ]}
            fieldTypes={[
              { type: FIELD_TYPE.TEXT_DUPLICATES_VOTERS },
              { type: FIELD_TYPE.TEXT },
              { type: FIELD_TYPE.TEXT },
              { type: FIELD_TYPE.TEXT },
              { type: FIELD_TYPE.TEXT },
              { type: FIELD_TYPE.TEXT },
              { type: FIELD_TYPE.TEXT },
              { type: FIELD_TYPE.IMAGE_FIELD },
            ]}
            toEdit={() => {}}
            toDelete={() => {}}
            search={() => {}}
            regExp={undefined}
            data={tableData}
            nameOfData={[
              'name',
              'address',
              'districtVote',
              'sectionVote',
              'cellphone',
              'sex',
              'demo_volunteer',
              'id_photo',
            ]}
            classBody={[
              'text-align-left selector-cell-none full-name-container',
              'text-align-left address-container',
              'text-align-left district-container',
              'text-align-left section-container',
              'text-align-center cellphone-container',
              'text-align-center sex-icon-container',
              'text-align-center volunteer-container',
              'text-align-center id-photo-container',
            ]}
          ></TableComponent>
          <PaginationRecords
            totalPages={totalPage}
            value={page}
            records={records}
            onChange={(pagina: string, record: string) => {
              let rec = record === '' ? '10' : record;
              let pag;
              if (records !== record) {
                pag = '1';
              } else {
                pag = pagina === '' ? '1' : pagina;
              }
              const params = buildParam(
                fieldOrder,
                fieldName,
                +pag,
                +rec,
                search,
              );
              dispatch(postVotersListByAddressElectionsUnauditedSearch(params));
              dispatch(postGetUnauditedVotersTotal(params));
            }}
          ></PaginationRecords>
        </div>
      </div>
      <VoterListComponent
        openVoters={setAuditorySliderShow}
        active={auditorySliderShow}
      ></VoterListComponent>
      <LogsListComponent
        openLogs={() => {
          setActive(false);
        }}
        active={active}
      ></LogsListComponent>
      {loading && (
        <LoadingModal show={loading} onHide={() => {}}></LoadingModal>
      )}
      {showXLSDialog && (
        <UploadDialog
          setModalShow={setShowXLSDialog}
          modalShow={showXLSDialog}
          successFun={() => {
            searchByValue(false);
          }}
        ></UploadDialog>
      )}
    </Fragment>
  );
};

export default List;
