import React, { useState, createRef, useEffect } from 'react';
import { RouteProps } from 'react-router-dom';
import { CheckGreenIcon } from '../../assets/svg/index';
import { IoMdArrowDropdown } from 'react-icons/io';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';

interface SelectProps extends RouteProps {
  title: string;
  horizontal: boolean;
  filters: any;
  filtersSelected: any;
  fun: any;
  t: any;
}

const SelectorMultipleComponent: React.FunctionComponent<SelectProps> = ({
  title,
  horizontal,
  filters,
  filtersSelected,
  fun,
  t,
}: any) => {
  const ref = createRef<HTMLDivElement>();

  const [style, updateStyle] = useState(`
  .dropwwwwwww {
    background-color: #fff;
    border-bottom: 1px solid #eee;
    transform: translate(${0}px, ${ref.current?.clientHeight}px) !important
  }
`);

  useEffect(() => {
    updateStyle(`
    .dropwwwwwww {
      background-color: #fff;
      border-bottom: 1px solid #eee;
      transform: translate(${0}px, ${ref.current?.clientHeight}px) !important
    }
  `);
  }, [ref]);

  const getFiltersSelected = () => {
    let result = t('select.lbl.none');
    filtersSelected.forEach((element: any) => {
      if (result === t('select.lbl.none')) {
        result = element.name;
      } else {
        result = result + ', ' + element.name;
      }
    });
    return result;
  };

  return (
    <div className={horizontal ? 'tags-box-horizontal' : 'tags-box'}>
      <h2>{title}</h2>
      <div className="dropdown-tags dropdown-filters">
        <div ref={ref} className="tag-box">
          <Dropdown as={ButtonGroup}>
            <style>{style}</style>
            <p className="tags-items-box-2">{getFiltersSelected()}</p>

            <Dropdown.Toggle split variant="success" id="dropdown-split-basic">
              <IoMdArrowDropdown></IoMdArrowDropdown>
            </Dropdown.Toggle>

            <Dropdown.Menu
              className="dropwwwwwww"
              style={{
                transform: `translate(${0}px, ${
                  ref.current?.clientHeight
                }px) !important`,
              }}
            >
              {filters.map((e: any, index: number) => {
                return (
                  <div
                    key={index}
                    onClick={() => {
                      fun(e);
                    }}
                    className={
                      filtersSelected.find((j: any) => j._id === e._id) !==
                      undefined
                        ? 'item-active dropdown-item'
                        : 'dropdown-item'
                    }
                  >
                    <p> {e.name}</p>
                    <div className="icon">
                      <CheckGreenIcon></CheckGreenIcon>
                    </div>
                  </div>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default SelectorMultipleComponent;
