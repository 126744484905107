import React from 'react';
import { useTranslation } from 'react-i18next';
import './style.scss';

import { onlyDigits } from '../../src/utils/utils';

import { FaCaretRight, FaCaretLeft } from 'react-icons/fa';
import Form from 'react-bootstrap/Form';

interface PaginationProps {
  totalPages: any;
  value: any;
  records: any;
  onChange: any;
}

const PaginationRecordsComponent: React.FunctionComponent<PaginationProps> = ({
  totalPages,
  value,
  records,
  onChange,
}: any) => {
  const { t } = useTranslation();

  const ref = React.createRef<HTMLDivElement>();
  const handleFocus = (event: any) => {
    event.target.select();
  };
  const [counter, setCounter] = React.useState(1);
  const [toggle, setToggle] = React.useState(false);
  const [data, setData] = React.useState('1');

  const [enterOn, setEnterOn] = React.useState(false);

  React.useEffect(() => {
    if (toggle) {
      if (counter > 0) setTimeout(() => setCounter(counter - 1), 1000);
      if (counter <= 0) {
        if (toggle) {
          if (+data > +totalPages) {
            setToggle(false);
            setData(totalPages.toString());
            onChange(totalPages.toString(), records);
          } else {
            setToggle(false);
            onChange(data, records);
          }
        }
      }
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter, toggle]);

  React.useEffect(() => {
    setData(value); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  React.useEffect(() => {
    if (enterOn) {
      if (+data > +totalPages) {
        setToggle(false);
        setEnterOn(false);
        setData(totalPages.toString());
        onChange(totalPages.toString(), records);
      } else {
        setToggle(false);
        setEnterOn(false);
        onChange(data, records);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enterOn]);

  return (
    <div ref={ref} className="pagination-row">
      <div className="pagination-control">
        <div
          className={
            data === '1'
              ? 'arrow-box arrow-left '
              : 'arrow-box arrow-left active'
          }
          onClick={() => {
            if (+data > 1) {
              onChange((+data - 1).toString(), records);
              setData((+data - 1).toString());
            }

            //  onChange((+data - 1).toString(), records);
          }}
        >
          <FaCaretLeft></FaCaretLeft>
        </div>
        <Form.Control
          as={'input'}
          value={data}
          onFocus={handleFocus}
          onChange={(data: any) => {
            if (data.target.value === '') setData('1');
            if (onlyDigits.test(data.target.value.trim()))
              setData(data.target.value.trim());
            setCounter(2);
            setToggle(true);
          }}
          onKeyPress={(e: any) => {
            if (e.key === 'Enter') {
              setEnterOn(true);
            }
          }}
        />
        <div className="pages-box">
          &nbsp;{t('general.lbl.of')}
          <div
            className="total-pages-text"
            onClick={() => {
              if (totalPages !== '1') {
                onChange(totalPages.toString(), records);
                setData(totalPages.toString());
              }
            }}
          >
            {totalPages ? totalPages : 0}&nbsp;
          </div>
        </div>
        <div
          className={
            data >= totalPages
              ? 'arrow-box arrow-right '
              : totalPages
              ? 'arrow-box arrow-right active'
              : 'arrow-box arrow-right '
          }
          onClick={() => {
            if (totalPages && +data < parseInt(totalPages)) {
              onChange((+data + 1).toString(), records);
              setData((+data + 1).toString());
            }
          }}
        >
          <FaCaretRight></FaCaretRight>
        </div>
      </div>
      <div className="records-control">
        <div className="records-box">{t('general.lbl.records')}:</div>
        <Form.Control
          as="select"
          value={records}
          onChange={(res: any) => {
            onChange(data.toString(), res.target.value);
          }}
        >
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="50">50</option>
        </Form.Control>
      </div>
    </div>
  );
};
export default PaginationRecordsComponent;
