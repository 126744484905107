import {
  TRAININGS_FAIL,
  TRAININGS_SUCCESS,
  TRAININGS_ROLE_SUCCESS,
  TrainingsCreate,
  TRAININGS_SINGLE_SUCCESS,
  TrainingsEdit,
  SearchTrainings,
  SearchTrainingsByRole,
} from './TrainingsTypes';
import * as netWorking from '../netWorking';

export const createTraining = (successFun: any, errorFunc: any) => {
  return async (dispatch: any) => {
    dispatch({
      type: TRAININGS_SINGLE_SUCCESS,
      payload: [],
    });
    if(successFun){
        successFun()
    }
    };
};

export const getTrainingsList = (data: SearchTrainings) => {
  return async (dispatch: any) => {
    netWorking.post(
      netWorking.getTrainings,
      data,
      (res: any) => {
        console.log(res);
        return dispatch({
          type: TRAININGS_SUCCESS,
          payload: res.data.response,
        });
      },
      (error: any) => {
        return dispatch({
          type: TRAININGS_FAIL,
        });
      },
    );
  };
};

export const getTrainingsListByRole = (data: SearchTrainingsByRole) => {
  return async (dispatch: any) => {
    netWorking.post(
      netWorking.getTrainingsByRole,
      data,
      (res: any) => {
        console.log(res);
        return dispatch({
          type: TRAININGS_ROLE_SUCCESS,
          payload: res.data.response,
        });
      },
      (error: any) => {
        return dispatch({
          type: TRAININGS_FAIL,
        });
      },
    );
  };
};

export const getTraining = (_id: String, successFun: any, errorFunc: any) => {
  return async (dispatch: any) => {
    if (_id !== undefined) {
      netWorking.get(
        netWorking.getTraining + _id,
        (res: any) => {
          dispatch({
            type: TRAININGS_SINGLE_SUCCESS,
            payload: res.data.response.data,
          });
          successFun(res.data.response.data);
        },
        (error: any) => {
          errorFunc(error);
        },
      );
    } else {
      errorFunc();
    }
  };
};

export const postTrainingCreate = (training: TrainingsCreate) => {
  return new Promise((resolve, reject) => {
    if (training !== undefined) {
      netWorking.post(
        netWorking.postTraining,
        training,
        (res: any) => {
          console.log(res);
          resolve(res.data);
        },
        (error: any) => {
          console.log(error);
          reject(error);
        },
      );
    } else {
      reject();
    }
  });
};

export const postTrainingDelete = (
  search: string,
  _id: String,
  successFun: any,
  errorFunc: any,
) => {
  return async (dispatch: any) => {
    if (_id !== undefined) {
      netWorking.post(
        netWorking.deleteTraining,
        { _id: _id },
        (res: any) => {
          dispatch(getTrainingsList({ search: search }));
          successFun(res.data);
        },
        (error: any) => {
          errorFunc(error);
        },
      );
    } else {
      errorFunc();
    }
  };
};

export const postTrainingUpdateFiles = (files: any, successFun: any, errorFunc: any,) => {
  return async (dispatch: any) => {
    netWorking.sendRequest(
        netWorking.postTrainingUploadFile,
        files,
        (res: any) => {
          //successFun(res.data);
        },
        (error: any) => {
          //errorFunc(error);
        },
        (res: any) => {
          //console.log('res', res)
          successFun(res);
        },
    );
  }
};

export const putTraining = (training: TrainingsEdit) => {
  return new Promise((resolve, reject) => {
    if (training !== undefined) {
      netWorking.put(
        netWorking.putTraining,
        training,
        (res: any) => {
          resolve(res.data);
        },
        (error: any) => {
          reject(error);
        },
      );
    } else {
      reject();
    }
  });
};