import React, { useState } from 'react';
import { RouteProps } from 'react-router-dom';
import './style.scss';
import Menu from './Menu';
import Top from './Top';

interface LayoutProps extends RouteProps {
  role: any;
  children: any;
  history: any;
}

const LayoutComponent: React.SFC<LayoutProps> = ({
  children,
  role,
  history,
}: any) => {
  const [showMenu, setMenu] = useState(false);

  return (
    <div className="layout">
      <Menu
        history={history}
        role={role}
        showMenu={showMenu}
        setMenu={setMenu}
      ></Menu>
      <div className="body-layout">
        <Top role={role} showMenu={showMenu} setMenu={setMenu}></Top>
        <div className="body">{children}</div>

     
      </div>
    </div>
  );
};

export default LayoutComponent;
