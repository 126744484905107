import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import '../style.scss';

import StaticModal from '../../../dialogs/StaticModal';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

interface ModalDeleteProps {
  modalShow: any;
  setModalShow: any;
  successFun: any;
}

export const ModalDeleteArea: FunctionComponent<ModalDeleteProps> = ({
  modalShow,
  setModalShow,
  successFun,
}: any) => {
  const { t } = useTranslation();

  return (
    <StaticModal
      show={modalShow}
      title={t('territorial.routes.lbl.deleteArea')}
      closeText={t('submit.cancel')}
      confirmText={t('submit.yes.delete')}
      onHide={() => setModalShow(false)}
      submitFun={successFun}
      validBtn={false}
      size="lg"
      sizeClass="md modal-rc"
    >
      <>
        <Container className="body-modal">
          <Row noGutters className="modal-text-row">
            <Col xs={12} className="question-col">
              {t('territorial.routes.lbl.deleteAreaQuestion')}
            </Col>
          </Row>
        </Container>
      </>
    </StaticModal>
  );
};

export default ModalDeleteArea;
