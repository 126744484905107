import React, { Fragment } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Figure from "react-bootstrap/Figure";
import { Trash } from "../../../assets/svg";
import ModalDelete from "./modalDelete";
import { useHistory } from "react-router-dom";
import ModalNotification from "./modalNotification";
import { useDispatch } from "react-redux";
import { getTraining } from "../../../redux/trainings/TrainingsActions";
import SimpleModal from "../../../utils/dialogs/simple-dialog.js";
import LoadingDialog from "../../../dialogs/loading-dialog";

interface ItemListProps {
  trainingList: Array<any>;
  t: any;
}

export const ItemList: React.SFC<ItemListProps> = ({
  trainingList,
  t,
}: any) => {
  const dispatch = useDispatch();
  const [toDelete, setToDelete] = React.useState("");
  const [modalDeleteShow, setModalDeleteShow] = React.useState(false);
  const redirect = useHistory();
  const [modalNotificationShow, setModalNotificationShow] = React.useState(
    false
  );
  const [isRedirect, setIsRedirect] = React.useState(false);
  const [customMsg, setCustomMsg] = React.useState(String);
  const [isPerformingRequest, setIsPerformingRequest] = React.useState(false);

  const deleteCourse = (_id: string) => {
    setToDelete(_id);
    setModalDeleteShow(true);
  };

  const goToUpdate = (_id: string) => {
    dispatch(
      getTraining(
        _id,
        () => {
          redirect.push(`/trainings/edit/${_id}`);
        },
        () => {
          setIsRedirect(false);
          setCustomMsg(t("settings.trainings.getting.failure"));
          setModalNotificationShow(true);
        }
      )
    );
  };

  const showToLabel = (option: number) => {
    let label = "";
    switch (option) {
      case 1:
        label = "RGs";
        break;
      case 2:
        label = "RCs";
        break;
      case 3:
        label = t("settings.trainings.form.all");
        break;
      default:
        label = t("settings.trainings.lbl.undefined");
    }
    return label;
  };

  const questionsLength = (steps: any) => {
    let total = 0;
    steps.map((step: any) => {
      total = total + step.questions.length;
    });
    return total;
  };

  return (
    <Row className="training-list">
      {modalDeleteShow && (
        <ModalDelete
          modalShow={modalDeleteShow}
          successFun={(data: any) => {
            if (data.deleted) {
              setIsRedirect(false);
              setCustomMsg(t("settings.trainings.deleted.success"));
              setModalNotificationShow(true);
            }
          }}
          toDelete={toDelete}
          setModalShow={setModalDeleteShow}
          setIsPerformingRequest={setIsPerformingRequest}
          t={t}
        ></ModalDelete>
      )}
      {modalNotificationShow && (
        <ModalNotification
          modalShow={modalNotificationShow}
          setModalShow={setModalNotificationShow}
          customMsg={customMsg}
          successFun={() => {
            setIsRedirect(false);
            redirect.push("/trainings");
          }}
          isRedirect={isRedirect}
          t={t}
        ></ModalNotification>
      )}
      {isPerformingRequest && (
        <SimpleModal>
          <LoadingDialog></LoadingDialog>
        </SimpleModal>
      )}
      {trainingList.length > 0 ? (
        trainingList.map((training: any, index: any) => {
          const masterIndex = index;
          return (
            <Col
              xl={4}
              lg={4}
              md={4}
              sm={4}
              className="training-list-item"
              key={masterIndex}
            >
              <div className="training-list-item-container">
                <div className="picture-container">
                  <Figure>
                    <Figure.Image
                      className="picture"
                      width={75}
                      height={75}
                      alt={training.name}
                      src={training.urlPicture}
                    />
                  </Figure>
                </div>
                <div 
                className="details-container"
                onClick={() => {
                  goToUpdate(training._id);
                }}>
                  <div className="detail-info">
                    <h5 className="title">
                      {" "}
                      {training.name}{" "}
                    </h5>
                    <ul className="list-unstyled">
                      <li className="list-item">
                        <span className="pre">
                          {t("settings.trainings.lbl.step")}s:
                        </span>{" "}
                        &nbsp;{" "}
                        <span className="post">
                          {" "}
                          {training.steps.length}{" "}
                        </span>{" "}
                      </li>
                      <li className="list-item">
                        <span className="pre">
                          {t("settings.trainings.lbl.showTo")}:
                        </span>{" "}
                        &nbsp;{" "}
                        <span className="post">
                          {" "}
                          {showToLabel(training.showTo)}{" "}
                        </span>{" "}
                      </li>
                      <li className="list-item">
                        <span className="pre">
                          {t("settings.trainings.lbl.question")}s:
                        </span>{" "}
                        &nbsp;{" "}
                        <span className="post">
                          {" "}
                          {questionsLength(training.steps)}{" "}
                        </span>{" "}
                      </li>
                    </ul>  
                  </div>
                </div>
                <div className="trash-container">
                  <Trash
                    className="btn-delete"
                    onClick={() => {
                      deleteCourse(training._id);
                    }}
                  ></Trash>
                </div>
              </div>
            </Col>
          );
        })
      ) : (
        <Fragment>
          <h5>{t("settings.trainings.noAvailable")}</h5>
        </Fragment>
      )}
    </Row>
  );
};

export default ItemList;
