import { connect } from 'react-redux';
import jwtDecode from 'jwt-decode'; // you must install jwt-decode using npm
import {
  logoutUser,
  getUserData,
  getUserDataByCampaign,
} from '../redux/User/userActions';
import store from '../redux/Store';
import axios from 'axios';
import {
  SET_AUTHENTICATED,
  SET_SUPER_ADMIN,
  SET_UNAUTHENTICATED,
} from '../redux/types';
import {
  citiesLogin,
  DecodedToken,
  SET_GLOBAL,
  SET_GLOBAL_SELECTED,
  statesLogin,
} from '../redux/core/CoreTypes';
import { ROLE } from './utils';
import { WS_CONNECT, WS_CONNECTED } from '../redux/socket/wsTypes';
import * as netWorking from '../redux/netWorking';

export const CheckAuthentication = () => {
  const authToken = localStorage.token;
  if (authToken) {
    const decodedToken: DecodedToken = jwtDecode(authToken);
    if (decodedToken.exp * 1000 < Date.now()) {
      logoutUser();
      store.dispatch({
        type: SET_UNAUTHENTICATED,
      });
      store.dispatch({
        type: SET_SUPER_ADMIN,
        payload: false,
      });
      localStorage.setItem('logoutEvent', 'logout' + Math.random());
    } else {
      if (localStorage.role === ROLE.SUPER_ADMIN) {
        console.log('SUPER_CAMPAIGNS');
        // window.location.href = SUPER_CAMPAIGNS;
      } else {
        console.log('DASHBOARD');
        // window.location.href = PRIVATE_DASHBOARD;
      }
      axios.defaults.headers.common['Authorization'] = authToken;
      axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
      let fun = getUserData;
      if (localStorage.accessMode && localStorage.accessMode === 'ACTIVE') {
        fun = getUserDataByCampaign;
      }
      fun(
        decodedToken._id,
        (value: any) => {
          const deco: DecodedToken = value.data.response;
          console.log(value.data.response);
          if (deco.role !== ROLE.CAPTURIST) {
            localStorage.setItem('id', deco._id);
            localStorage.setItem('role', deco.role);
            localStorage.setItem(
              'level',
              deco.level ? deco.level.toString() : '',
            );
            localStorage.setItem('teamName', deco.teamName);
            localStorage.setItem('focus', deco.focus);
            localStorage.setItem('campaignId', deco.campaignId);
            localStorage.setItem('team', JSON.stringify(deco.team));
            if (
              localStorage.accessMode &&
              localStorage.accessMode === 'ACTIVE'
            ) {
              store.dispatch({
                type: SET_AUTHENTICATED,
                superAdmin: false,
              });
            } else {
              store.dispatch({
                type: SET_AUTHENTICATED,
                superAdmin:
                  localStorage.role === ROLE.SUPER_ADMIN ? true : false,
              });
            }
            let stateSelected: statesLogin[] = [];
            let citySelected: citiesLogin[] = [];
            try {
              stateSelected = deco.states;
              citySelected = deco.cities;
            } catch (error) {
              stateSelected = [];
              citySelected = [];
              // logoutUser();
              // store.dispatch({
              //   type: SET_UNAUTHENTICATED,
              // });
            }
            store.dispatch({
              type: SET_GLOBAL,
              state: stateSelected,
              municipality: citySelected,
              userData: deco,
            });
            store.dispatch({
              type: SET_GLOBAL_SELECTED,
              state: stateSelected[0],
              municipality: citySelected[0],
            });
          } else {
            logoutUser();
            store.dispatch({
              type: SET_UNAUTHENTICATED,
            });
            store.dispatch({
              type: SET_SUPER_ADMIN,
              payload: false,
            });
            localStorage.setItem('logoutEvent', 'logout' + Math.random());
          }
        },
        () => {
          logoutUser();
          store.dispatch({
            type: SET_UNAUTHENTICATED,
          });
          store.dispatch({
            type: SET_SUPER_ADMIN,
            payload: false,
          });
          localStorage.setItem('logoutEvent', 'logout' + Math.random());
        },
      );
    }
  }
};
