import React, { FunctionComponent } from 'react';
import { RouteProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface ItemProps extends RouteProps {
  status: number;
}

const TagStatusComponent: FunctionComponent<ItemProps> = ({ status }: any) => {
  const { t } = useTranslation();

  const getClass = () => {
    switch (status) {
      case 0:
        return 'none-box';
      case 1:
        return 'issue-box';
      case 2:
        return 'problem-box';
      case 3:
        return 'audit-box';
      default:
        return 'none-box';
    }
  };

  const TagName = () => {
    switch (status) {
      case 0:
        return '';
      case 1:
        return t('territorial.tracker.lbl.statusReview');
      case 2:
        return t('territorial.tracker.lbl.statusProblem');
      case 3:
        return t('territorial.tracker.lbl.statusAudit');
      default:
        return '';
    }
  };

  return (
    <div className={getClass()}>
      <p>{TagName()}</p>
    </div>
  );
};

export default TagStatusComponent;
