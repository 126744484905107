import axios from 'axios';
import { Dispatch } from 'redux';
import * as netWorking from '../netWorking';
import {
  LogsDispatchTypes,
  LOGS_FILE_TYPE,
  LOGS_LOADING,
  LOGS_SUCCESS,
  MODULE,
} from './LogsTypes';

export const getLogs = (
  lang: string,
  page: number,
  limit: number,
  dataId: any,
  module: MODULE,
) => async (dispatch: Dispatch<LogsDispatchTypes>) => {
  dispatch({
    type: LOGS_LOADING,
  });
  netWorking.post(
    netWorking.getLogs,
    { lang, page, limit, dataId, module },
    (res: any) => {
      console.log(res);
      dispatch({
        type: LOGS_SUCCESS,
        logs: res.data.response.logs,
        dataId,
        module,
      });
    },
    (error: any) => {
      dispatch({
        type: LOGS_LOADING,
      });
    },
  );
};

export const getLogsFile = (
  lang: string,
  type: LOGS_FILE_TYPE,
  dataId: any,
  module: MODULE | null,
) => async (dispatch: Dispatch<LogsDispatchTypes>) => {
  dispatch({
    type: LOGS_LOADING,
  });
  axios
    .get(
      netWorking.URL +
        netWorking.getLogsFiles +
        '/' +
        lang +
        '/' +
        type +
        '/' +
        dataId +
        '/' +
        module,
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          crossdomain: true,
        },
        responseType: 'blob',
      },
    )
    .then((resp: any) => {
      // our mocked response will always return true
      // in practice, you would want to use the actual response object

      const url = window.URL.createObjectURL(new Blob([resp.data]));
      const link = document.createElement('a');
      link.href = url;
      if (type === LOGS_FILE_TYPE.PDF)
        link.setAttribute('download', 'logs.pdf'); //or any other extension
      if (type === LOGS_FILE_TYPE.EXCEL)
        link.setAttribute('download', 'logs.xlsx'); //or any other extension
      document.body.appendChild(link);
      link.click();
      link.remove();
    })
    .catch((err: any) => {
      console.error(err);
    });
};
