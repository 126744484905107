import React, { useEffect, Fragment, FunctionComponent } from 'react';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Col from 'react-bootstrap/Col';
import Figure from 'react-bootstrap/Figure';
import { BsSearch } from 'react-icons/bs';
import Row from 'react-bootstrap/Row';
import {
  EditIcon,
  Trash,
  CorrectIcon,
  OrderIcon,
  FacebookIcon,
  InstagramIcon,
  TiktokIcon,
  WhatsappIcon,
} from '../../../assets/svg/index';
import { FaTwitter } from 'react-icons/fa';
import { DiOpera } from 'react-icons/di';
import Button from 'react-bootstrap/Button';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { useTranslation } from 'react-i18next';
import { isArray, random } from 'lodash';
import { getIncome, getSex } from '../../../utils/utils';
import { getSexWithIcon } from '../../../components/NewTable';

export enum FIELD_TYPE {
  TEXT,
  TEXT_ARRAY,
  IMAGE_TEXT,
  IMAGE_TEXT_TWO_INITIALS,
  BUTTON_ARRAY,
  BUTTON_ARRAY_VOTERS_BOX,
  BUTTON_TEXT,
  COUNTER_BOX,
  PORCENTAJE,
  COUNT_LINK,
  OBJECT_ARRAY,
  SOCIAL_BAR,
  SOCIAL_NAME_ICON,
  WHATSAPP,
  OBJECT_TEXT,
  TEXT_LINK,
  PERCENTAJE_PROGRESS,
  TEXT_BOLD,
  SEX,
  INCOME,
}

export enum SOCIAL_NETWORK {
  UNDEFINED,
  FACEBOOK,
  INSTAGRAM,
  TIKTOK,
  TWITTER,
  ONLYFANS,
}
// header of all table
export enum HEADER_TYPE {
  NORMAL,
  TEAM,
  TERRITORIAL_TEAM,
}
// header of each column table
export enum HEADER {
  TEXT,
  FILTER,
}

export interface HeaderNormal {
  title: string;
}

export type header = HeaderNormal;

interface TableProps {
  header: header;
  headerType: HEADER_TYPE;
  headers: any;
  data: any;
  toEdit: any;
  toDelete: any;
  search: any;
  columns: any;
  regExp?: any;
  selectedRows?: any;
  selectRows?: any;
  selectedRows2?: any;
  selectRows2?: any;
  showActions?: boolean;
  onTrAction?: boolean;
  onTrFun?: any;
  duplicates?: string[];
  showOnlyDelete?: boolean;
  actionsStartRow?: boolean;
  doneSelects?: any;
}

const DuplicatesTableComponent: FunctionComponent<TableProps> = ({
  header,
  headerType,
  headers,
  data,
  toEdit,
  toDelete,
  search,
  regExp,
  columns,
  selectedRows,
  selectRows,
  selectedRows2,
  selectRows2,
  showActions,
  onTrAction,
  onTrFun,
  duplicates = [],
  showOnlyDelete = false,
  actionsStartRow = false,
  doneSelects,
}: any) => {
  const { t } = useTranslation();
  const selectRow = (id: any, id2: any, type: number) => {
    doneSelects(id, id2, type);
  };

  useEffect(() => {}, []);

  const openDelete = (id?: any) => {
    if (id !== undefined) toDelete([id]);
    else {
      toDelete([...selectedRows]);
    }
  };

  const getHeader = () => {
    switch (headerType) {
      case HEADER_TYPE.NORMAL:
        return (
          <Container fluid className="header-table">
            <Row noGutters>
              <Col lg={2} md={2} xs={2}>
                <h2>{header.title}</h2>
              </Col>
              <Col lg={2} md={3} xs={4} className="search-action-box">
                <div className="delete-btn"></div>
                <Form inline className="search-field">
                  <FormControl
                    type="text"
                    placeholder={t('submit.search')}
                    className="mr-sm-2"
                    onChange={(data: any) => {
                      if (data.target.value === '') search(data.target.value);
                      if (
                        regExp === undefined ||
                        regExp.test(data.target.value.trim())
                      )
                        search(data.target.value);
                    }}
                  />
                  <div className="icon">
                    <BsSearch />
                  </div>
                </Form>
              </Col>
            </Row>
          </Container>
        );
      default:
        return null;
    }
  };

  return (
    <div className="table-box">
      {getHeader()}
      <div className="table-container">
        <div className="header-box-table">
          <div className="empty-cell"></div>
          {ListHeaders(headers, columns, actionsStartRow)}
        </div>
        <div className="body-box-table">
          {ListBodyData(
            data,
            columns,
            selectedRows,
            selectedRows2,
            selectRow,
            toEdit,
            openDelete,
            showActions,
            onTrAction,
            onTrFun,
            duplicates,
            showOnlyDelete,
            actionsStartRow,
          )}
        </div>
      </div>
    </div>
  );
};

const ListHeaders = (headers: any, columns: any, actionsStartRow: boolean) => {
  // eslint-disable-next-line
  return headers.map((header: any, index: number) => {
    switch (header.type) {
      case HEADER.TEXT:
        return (
          <div
            className={
              columns[index].classBody +
              (index === 0 && actionsStartRow ? ' header-actions-row' : '')
            }
            key={header.name}
          >
            {header.name}
          </div>
        );
      case HEADER.FILTER:
        return (
          <div className={columns[index].classBody} key={header.name}>
            {header.name}{' '}
            <OrderIcon
              className="filter-action-icon"
              onClick={() => {
                header.fun();
              }}
            ></OrderIcon>
          </div>
        );
      default:
        break;
    }
  });
};

const ListBodyData = (
  bodyData: any,
  columns: any,
  selectedRows: any,
  selectedRows2: any,
  selectRow: any,
  toEdit: any,
  openDelete: any,
  showActions: boolean,
  onTrAction: boolean,
  onTrFun: any,
  duplicates: string[],
  showOnlyDelete: boolean,
  actionsStartRow: boolean,
) => {
  return bodyData.map((e: any, index: number) => {
    return (
      <div className="duplicate-box" key={e.actualData._id}>
        <div
          key={e.actualData._id}
          className={
            (selectedRows2.indexOf(e.actualData._id) !== -1 ? 'selected' : '') +
            ' row-data'
          }
          onClick={() => {
            if (onTrAction) {
              onTrFun(e.actualData._id);
            }
          }}
        >
          {checkTh(
            1,
            e.data._id,
            selectRow,
            selectedRows,
            selectedRows2,
            index,
            e.actualData._id,
          )}
          {cellData(e.actualData, columns, e.data)}
          {showActions === undefined ||
            (showActions &&
              !actionsStartRow &&
              cellActions(
                e.actualData,
                columns,
                toEdit,
                openDelete,
                showOnlyDelete,
              ))}
        </div>
        <div
          key={e.data._id}
          className={
            (selectedRows.indexOf(e.data._id) !== -1 ? 'selected' : '') +
            ' row-data'
          }
          onClick={() => {
            if (onTrAction) {
              onTrFun(e.data._id);
            }
          }}
        >
          {checkTh(
            0,
            e.data._id,
            selectRow,
            selectedRows,
            selectedRows2,
            index,
            e.actualData._id,
            e.actualData.editable,
          )}
          {cellData(e.data, columns, e.actualData)}
          {showActions === undefined ||
            (showActions &&
              !actionsStartRow &&
              cellActions(e.data, columns, toEdit, openDelete, showOnlyDelete))}
        </div>
      </div>
    );
  });
};

const checkTh = (
  typeCheck: number,
  id: any,
  selectRow: any,
  selectedRows: any,
  selectedRows2: any,
  index: number,
  id2: any,
  editable?: any,
) => {
  return (
    <div
      key={typeCheck + id}
      className={'checker-th text-align-right selector-cell item-row'}
      onClick={() => {}}
    >
      {typeCheck === 1 && (
        <div className="circle-counter">
          <p>{index + 1}</p>
        </div>
      )}
      <div className="internal-box-checker">
        {typeCheck === 0 && editable && (
          <Button
            variant={'outline-dark'}
            onClick={() => {
              selectRow(id, id2, typeCheck);
            }}
          >
            {'Nuevo'}
          </Button>
        )}
        {typeCheck === 1 && (
          <Button
            variant={'primary'}
            onClick={() => {
              selectRow(id, id2, typeCheck);
            }}
          >
            {'Actual'}
          </Button>
        )}
      </div>
    </div>
  );
};

const cellData = (data: any, columns: any, secondData: any) => {
  return columns.map((e: any, index: number) => {
    return (
      <div
        key={e.dataIndex}
        className={
          columns[index].classBody +
          ' item-row' +
          (data[e.dataIndex] !== secondData[e.dataIndex]
            ? ' duplicate-col'
            : '')
        }
        onClick={() => {}}
      >
        {field(e.dataIndex, data, columns[index], () => {})}
      </div>
    );
  });
};

const cellActions = (
  data: any,
  columns: any,
  toEdit: any,
  openDelete: any,
  showOnlyDelete: boolean,
) => {
  return (
    <div
      key={random()}
      className={
        (showOnlyDelete ? 'only-delete-icon ' : ' ') +
        'text-align-right action-buttons item-row'
      }
    >
      <div>
        {!showOnlyDelete && (
          <EditIcon
            onClick={() => {
              toEdit(data._id);
            }}
          ></EditIcon>
        )}

        <Trash
          onClick={() => {
            // openDelete(data._id);
          }}
        ></Trash>
      </div>
    </div>
  );
};

const field = (e: any, data: any, column: any, actionFun?: any) => {
  switch (column.type) {
    case FIELD_TYPE.OBJECT_TEXT:
      if (data[e] !== null)
        return (
          <div
            className="text-bold"
            onClick={() => {
              column.fun(data);
            }}
          >
            {data[e].name}
          </div>
        );
      else
        return (
          <div>
            <Button
              variant="primary"
              onClick={() => {
                column.fun(data);
              }}
            >
              Asignar
            </Button>
          </div>
        );
    case FIELD_TYPE.TEXT:
      return <div>{data[e]}</div>;
    case FIELD_TYPE.SEX:
      return <div>{getSexWithIcon(data[e])}</div>;
    case FIELD_TYPE.INCOME:
      return <div>{getIncome(data[e])}</div>;

    case FIELD_TYPE.TEXT_LINK:
      if (isArray(data[e])) {
        return (
          <div className="text-link" onClick={column.fun}>
            Ir a rutas
          </div>
        );
      }
      return <div>No hay rutas</div>;
    case FIELD_TYPE.TEXT_BOLD:
      const { text = '', houseDone = 0 } = data[e];
      return (
        <div>
          <strong>{text}</strong> <span>({houseDone} Casas)</span>
        </div>
      );
    case FIELD_TYPE.PERCENTAJE_PROGRESS:
      return (
        <div className="box-percentaje">
          <div className="box-percentaje-value">{data[e] * 100}%</div>
          <ProgressBar
            className="box-progress"
            variant="success"
            now={data[e] * 100}
          />
        </div>
      );
    case FIELD_TYPE.TEXT_ARRAY:
      return <div>{getArrayText(data[e])}</div>;
    case FIELD_TYPE.OBJECT_ARRAY:
      return <div>{getArrayObject(data[e], 'name')}</div>;
    case FIELD_TYPE.COUNT_LINK:
      return (
        <Button
          variant="primary"
          className="box-counter"
          onClick={() => column.fun(data[e])}
          // onClick={actionFun[e]}
        >
          {data[e].members.length}
        </Button>
      );
    case FIELD_TYPE.IMAGE_TEXT:
      return (
        <div>
          <span className="initials">{getInitials(data[e])}</span>
          {data[e]}
        </div>
      );
    case FIELD_TYPE.IMAGE_TEXT_TWO_INITIALS:
      return (
        <div>
          <span className="initials">{getFirstTwoInitials(data[e])}</span>
          {data[e]}
        </div>
      );
    case FIELD_TYPE.BUTTON_ARRAY_VOTERS_BOX:
      if (data[e].length > 0)
        return (
          <div
            className="text-bold"
            onClick={() => {
              column.fun(data);
            }}
          >
            {getArrayObject(data[e], 'number')}
          </div>
        );
      else
        return (
          <div>
            <Button
              variant="primary"
              onClick={() => {
                column.fun(data);
              }}
            >
              Asignar
            </Button>
          </div>
        );
    case FIELD_TYPE.BUTTON_ARRAY:
      if (data[e].length > 0)
        return <div className="text-bold">{getArrayText(data[e])}</div>;
      else
        return (
          <div>
            <Button
              variant="primary"
              onClick={() => {
                column.fun(data);
              }}
            >
              Asignar
            </Button>
          </div>
        );
    case FIELD_TYPE.BUTTON_TEXT:
      if (data[e] !== '') return <div className="text-bold">{data[e]}</div>;
      else
        return (
          <div>
            <Button variant="primary" onClick={actionFun[e]}>
              Asignar
            </Button>
          </div>
        );
    case FIELD_TYPE.COUNTER_BOX:
      return (
        <Button
          variant="primary"
          className={data[e].length > 0 ? 'box-counter' : ''}
          // onClick={actionFun[e]}
        >
          {data[e].length}
        </Button>
      );
    case FIELD_TYPE.PORCENTAJE:
      return (
        <div>
          {data[e] === 100 && <CorrectIcon></CorrectIcon>}
          {data[e]}%
        </div>
      );
    case FIELD_TYPE.SOCIAL_BAR:
      return (
        <div>
          <ProgressBar>
            {data[e] >= 1 && <ProgressBar variant="success" now={25} key={1} />}
            {data[e] >= 2 && <ProgressBar variant="info" now={25} key={2} />}
            {data[e] >= 3 && <ProgressBar variant="warning" now={25} key={3} />}
            {data[e] >= 4 && <ProgressBar variant="danger" now={25} key={4} />}
          </ProgressBar>
        </div>
      );
    case FIELD_TYPE.SOCIAL_NAME_ICON:
      return getSocialNetwork(data[e]);
    case FIELD_TYPE.WHATSAPP:
      return (
        <Fragment>
          <Figure
            className="social-network-container"
            onClick={() => {
              console.log('pending WhatsApp functionality');
            }}
          >
            <WhatsappIcon className="social-network-icon"></WhatsappIcon>
          </Figure>
        </Fragment>
      );
    default:
      return null;
  }
};

const getInitials = (value: string) => {
  let result = '';
  const array = value.split(' ');
  array.forEach((e) => {
    result = result + e.slice(0, 1);
  });
  return result;
};
const getFirstTwoInitials = (value: string) => {
  let result = '';
  const array = value.split(' ');
  array.forEach((e, idx) => {
    if (idx < 2) {
      result = result + e.slice(0, 1);
    }
  });
  return result;
};

const getArrayObject = (value: any[], valueName: string) => {
  let result = '';

  value.forEach((e) => {
    if (result === '') {
      result = e[valueName];
    } else {
      result = result + ', ' + e[valueName];
    }
  });
  return result;
};

const getArrayText = (value: string[]) => {
  let result = '';

  value.forEach((e) => {
    if (result === '') {
      result = e;
    } else {
      result = result + ', ' + e;
    }
  });
  return result;
};

const getSocialNetwork = (network: number): any => {
  let networkName = '';
  let networkImg;
  switch (Number(network)) {
    case SOCIAL_NETWORK.FACEBOOK:
      networkName = 'Facebook';
      networkImg = (
        <FacebookIcon className="social-network-icon"></FacebookIcon>
      );
      break;
    case SOCIAL_NETWORK.INSTAGRAM:
      networkName = 'Instagram';
      networkImg = (
        <InstagramIcon className="social-network-icon"></InstagramIcon>
      );
      break;
    case SOCIAL_NETWORK.TIKTOK:
      networkName = 'TikTok';
      networkImg = <TiktokIcon className="social-network-icon"></TiktokIcon>;
      break;
    case SOCIAL_NETWORK.TWITTER:
      networkName = 'Twitter';
      networkImg = <FaTwitter className="social-network-icon" />;
      break;
    case SOCIAL_NETWORK.ONLYFANS:
      networkName = 'Only Fans';
      networkImg = <DiOpera className="social-network-icon" />;
      break;
    default:
      break;
  }

  return (
    <Fragment>
      <Figure className="social-network-container">{networkImg}</Figure>
      <span className="social-network-name"> {networkName} </span>
    </Fragment>
  );
};

export default DuplicatesTableComponent;
