import React, { FunctionComponent } from 'react';
import { TileLayer, LayersControl, ZoomControl } from 'react-leaflet';
import { RouteProps } from 'react-router-dom';
import './style.scss';
const { BaseLayer } = LayersControl;

interface ItemProps extends RouteProps {
  checked?:
    | 'Street'
    | 'Hybrid'
    | 'Satellite'
    | 'Terrain'
    | 'openStreetMap'
    | 'NAVTEQ'
    | 'Mapnik';
}

const LayerControlComponent: FunctionComponent<ItemProps> = ({
  checked = 'Street',
}: ItemProps) => (
  <>
    <ZoomControl position="bottomright" />
    <LayersControl position="topright">
      <BaseLayer checked={checked === 'Street'} name="Google Maps Street">
        <TileLayer
          url="https://maps.googleapis.com/maps/vt?pb=!1m5!1m4!1i{z}!2i{x}!3i{y}!4i256!2m3!1e0!2sm!3i349018013!3m9!2sen-US!3sUS!5e18!12m1!1e47!12m3!1e37!2m1!1ssmartmaps!4e0"
          maxZoom={20}
          subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
          attribution={'Google Maps'}
        />
      </BaseLayer>
      <BaseLayer checked={checked === 'Hybrid'} name="Google Map Hybrid">
        <TileLayer
          url="http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}"
          maxZoom={20}
          subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
          attribution={'Google Maps'}
        />
      </BaseLayer>
      <BaseLayer checked={checked === 'Satellite'} name="Google Map Satellite">
        <TileLayer
          url="http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
          maxZoom={20}
          subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
          attribution={'Google Maps'}
        />
      </BaseLayer>
      {/*<BaseLayer checked={checked === 'Terrain'} name="Google Map Terrain">
        <TileLayer
          url="http://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}"
          maxZoom={20}
          subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
          attribution={'Google Maps'}
        />
      </BaseLayer>
      <BaseLayer checked={checked === 'openStreetMap'} name="openStreetMap">
        <TileLayer url="https://stamen-tiles-{s}.a.ssl.fastly.net/toner/{z}/{x}/{y}{r}.png" />
      </BaseLayer>
      <BaseLayer checked={checked === 'NAVTEQ'} name="NAVTEQ">
        <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}" />
      </BaseLayer>
      <BaseLayer checked={checked === 'Mapnik'} name="Mapnik">
        <TileLayer url="http://{s}.tile.osm.org/{z}/{x}/{y}.png" />
      </BaseLayer>*/}
    </LayersControl>
  </>
);

export default LayerControlComponent;
