// In types.ts we will define constants for user authentication and UI loading purpose

import { Problem } from '../../superAdmin/pages/shared/general/components/problemsView';
import { User } from '../users/UsersTypes';

// user reducer types
export const SET_COUNTRIES_DATA = 'SET_COUNTRIES_DATA';
export const SET_PROBLEMS = 'SET_PROBLEMS';
export const SELECT_COUNTRY = 'SELECT_COUNTRY';
export const STATE_LOADING = 'STATE_LOADING';
export const SET_LIST_LICENSES = 'SET_LIST_LICENSES';
export const SET_SELECTED_LICENSE_CITY = 'SET_SELECTED_LICENSE_CITY';
export const USERS_CAMPAIGN_LOADING = 'USERS_CAMPAIGN_LOADING';
export const USERS_CAMPAIGN_SUCCESS = 'USERS_CAMPAIGN_SUCCESS';

export const capturistUsersList = [
  { es: 'Seccionales', en: 'Sectionals', role: 'CAPTURIST_BOSS', level: 2 },
  { es: 'Capturistas', en: 'Volunteers', role: 'CAPTURIST', level: 1 },
];

export interface Campaign {
  _id: string;
  partyId: string;
  candidateName: string;
  campaignType: string;
  states: any[];
  cities: City[];
  name: string;
  endDate: Date;
  bossId: string;
  bossName: string;
  contact: string;
  email: string;
  deleted: boolean;
  active: boolean;
  countryId: string;
}

export interface TimeZone {
  _id: string;
  offset: string;
  name: string;
  label: string;
}

export interface City {
  _id: string;
  admins: string[];
  stateId: string;
  defaultZoom: number;
  defaultCenter: [number, number];
  modules: string[];
  name: string;
  municipalityId: string;
  active: boolean;
  geoLimits: any;
  capturistUserLevels: any[];
  problemList: Problem[];
  timeZones: TimeZone[];
  startDatePollingStation: string;
  endDatePollingStation: string;
  autoSyncTerritorial: boolean;
}

export interface SetProblemsListData {
  type: typeof SET_PROBLEMS;
  problems: any[];
}
export interface SetCountriesListData {
  type: typeof SET_COUNTRIES_DATA;
  countries: any[];
}

export interface SelectCountry {
  type: typeof SELECT_COUNTRY;
  _id: string;
}

export interface SetLoading {
  type: typeof STATE_LOADING;
  value: boolean;
}

export interface SetUserLoading {
  type: typeof USERS_CAMPAIGN_LOADING;
  value: boolean;
}

export interface SetUserSuccess {
  type: typeof USERS_CAMPAIGN_SUCCESS;
  users: User[];
}

export interface SetListLicenses {
  type: typeof SET_LIST_LICENSES;
  listLicenses: Campaign[];
}

export interface SetSelectedLicenseCity {
  type: typeof SET_SELECTED_LICENSE_CITY;
  licenseSelected: Campaign;
  cityLicense: City;
}

export type CampaignDispatchTypes =
  | SetLoading
  | SetCountriesListData
  | SelectCountry
  | SetListLicenses
  | SetSelectedLicenseCity
  | SetUserSuccess
  | SetUserLoading
  | SetProblemsListData;
