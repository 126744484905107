import React, { useEffect, useState, Fragment } from "react";
import Form from "react-bootstrap/Form";
import { XLSUploadIcon } from '../../../assets/svg/index';
import i18next from 'i18next';

interface StepBoxFileProps {
  masterIndex: any;
  stepFile: any;
  t: any;
  stepData: any;
  setStepData: any;
  viewMode: any;
  isEdit: any;
  stepFiles: any;
  setStepFiles: any;
  isOldStep: any;
}

export const StepUploadVideoBoxComponent: React.FunctionComponent<StepBoxFileProps> = ({
  masterIndex,
  stepFile,
  t,
  stepData,
  setStepData,
  viewMode,
  isEdit,
  stepFiles,
  setStepFiles,
  isOldStep,
}: any) => {
    const [showUploadBtn, setShowUploadBtn] = useState(viewMode);
    const [showLabelUpload, setShowLabelUpload] = useState(String);

    console.log('stepFile', typeof stepFile)
    console.log('stepFile', stepFile.toString().replace(
        "https://giooby-elections.s3-us-west-1.amazonaws.com/",
        ""
    ))
    console.log('stepFile', stepFile.toString().substring(52));

    const updateFileField = (masterIndex: any, itemValue: any, fileValue: any) => {
        let newItems = [...stepData];
        if (isEdit) {
        if ("_id" in newItems[masterIndex]) {
            isOldStep(masterIndex)
            if(newItems[masterIndex].file.name){
                Object.assign(itemValue, {
                oldFile: newItems[masterIndex].file.oldFile
                });
            }else{
                Object.assign(itemValue, {
                oldFile: newItems[masterIndex].file.replace(
                    "https://giooby-elections.s3-us-west-1.amazonaws.com/",
                    ""
                ),
                });
            }
        }
        }
        newItems[masterIndex].file = itemValue;
        setStepData(newItems);
        setStepFiles(fileValue)
    };

    const onFileChange = (e: any, masterIndex: any) => {
        const files = e.target.files || e.dataTransfer.files;

        const weight = files[0].size / 1024;
        const limit = 100 * 1024;
        if (weight < limit) {
        if (!files.length) return;
        createFileSteps(files[0], masterIndex);
        } else {
        console.log("Máximo 100MB por archivo");
        }
    };

    const createFileSteps = (file: any, masterIndex: any) => {
        let stepString = (masterIndex).toString()
        stepFiles.append(stepString, file)

        let fileFormat = {
        name: file.name.replace(/[^a-zA-Z0-9]/g, ""),
        type: `.${file.type.split("/")[1]}`,
        humanType: file.type.split("/")[1],
        dataFull: "",
        };

        let isValidExt = false;

        const validExtensions = [
        ".vnd.openxmlformats-officedocument.wordprocessingml.document",
        ".vnd.openxmlformats-officedocument.wordprocessingml.document",
        ".msword",
        ".mp4",
        ".doc",
        ".docx",
        ".pdf",
        ];
        validExtensions.forEach((ext) => {
        if (ext === fileFormat.type) {
            isValidExt = true;
        }
        });

        if (!isValidExt) {
        return false;
        }

        updateFileField(masterIndex, fileFormat, stepFiles);
    };

    const downloadFile = () => {
        if(!stepFile.name && isEdit){
            const link = document.createElement('a');
            link.href = stepFile;
            link.setAttribute('download', stepFile); //or any other extension
            document.body.appendChild(link);
            link.click();
            link.remove();
        }
    }

    useEffect(() => {
        setShowUploadBtn(viewMode);
    }, [viewMode]);

    useEffect(() => {
        setShowLabelUpload(
            stepFile.name?
            stepFile.name:
            stepFile.toString().substring(52)
        )
    }, [stepFile]);
    
    return (
        <Fragment>
            <div className="box-row">
                <h1 className="main-title">{t('settings.trainings.form.file')}</h1>
                    <Form.File
                    id={`custom-file-${masterIndex + 1}`}
                    className={i18next.language == 'es' ? "btn-upload" : "btn-upload en-upload-style"}
                    label=""
                    data-browse={t("settings.trainings.lbl.upload")}
                    custom
                    onChange={(event: any) => {
                        onFileChange(event, masterIndex);
                    }}
                    />
            </div>
            <div className="box-upload">
                <XLSUploadIcon
                className={!stepFile.name && isEdit?'box-upload-exists':''}
                onClick={downloadFile}
                ></XLSUploadIcon>
                <p>{!showLabelUpload ? t('settings.trainings.lbl.uploadVideo') : showLabelUpload}</p>
            </div>
        </Fragment>
    );
};

export default StepUploadVideoBoxComponent;
