import { SET_USER, SET_ERRORS, LOADING_UI, CLEAR_ERRORS } from './userTypes';
import axios from 'axios';
import * as NetWorking from '../netWorking';
import store from '../Store';

export const checkInUse = (email: any, successFun: any) => (dispatch: any) => {
  dispatch({ type: LOADING_UI });
  NetWorking.post(
    NetWorking.checkInUse,
    email,
    (res: any) => {
      successFun(res.data.response);
    },
    (error: any) => {
      console.log(error);
    },
  );
};

export const loginUser = (userData: any, history: any) => (dispatch: any) => {
  dispatch({ type: LOADING_UI });
  NetWorking.post(
    NetWorking.loginUser,
    userData,
    (res: any) => {
      const token = `Bearer ${res.data.response.accessToken}`;
      localStorage.setItem('token', `Bearer ${res.data.response.accessToken}`); // setting token to local storage
      axios.defaults.headers.common['Authorization'] = token; // setting authorize token to header in axios
      // dispatch(getUserData());
      dispatch({ type: CLEAR_ERRORS });
      dispatch({
        type: SET_USER,
        payload: res.data.response.response.data,
      });
      // tslint:disable-next-line: no-console
      history.push('/'); // redirecting to index page after login success
    },
    (err: any) => {
      // tslint:disable-next-line: no-console
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data,
      });
    },
  );
};
// for fetching authenticated user information
export const getUserData = (user_id: string, fun: any, error: any) => {
  axios
    .get(NetWorking.URL + NetWorking.getUser + user_id, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        crossdomain: true,
      },
    })
    .then((res) => {
      console.log(res);
      fun(res);
    })
    .catch((err) => {
      error(err);
      console.log(err);
    });
};

export const getUserDataByCampaign = (
  user_id: string,
  fun: any,
  error: any,
) => {
  axios
    .get(NetWorking.URL + NetWorking.getUserByCampaign + user_id, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        crossdomain: true,
      },
    })
    .then((res) => {
      console.log(res);
      fun(res);
    })
    .catch((err) => {
      error(err);
      console.log(err);
    });
};

export const setDataFromCampaign = (authResult: any) => {
  let expiresAt = JSON.stringify(authResult.expiresIn + new Date().getTime());
  // If there is a value on the `scope` param from the authResult,
  // use it to set scopes in the session for the user. Otherwise
  // use the scopes as requested. If no scopes were requested,
  // set it to nothing
  const token = `Bearer ${authResult.accessToken}`;
  localStorage.setItem('token', `Bearer ${authResult.accessToken}`); // setting token to local storage
  axios.defaults.headers.common['Authorization'] = token; // setting authorize token to header in axios
  localStorage.setItem('access_token', authResult.accessToken);
  localStorage.setItem('refresh_token', authResult.refreshToken);
  localStorage.setItem('expires_at', expiresAt);
  localStorage.setItem('accessMode', 'ACTIVE');
};

export const logoutUser = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
  localStorage.removeItem('expires_at');
  localStorage.removeItem('email');
  localStorage.removeItem('firstName');
  localStorage.removeItem('lastName');
  localStorage.removeItem('id');
  localStorage.removeItem('role');
  localStorage.removeItem('districtId');
  localStorage.removeItem('typeOfUser');
  localStorage.removeItem('campaignId');
  localStorage.removeItem('team');
  localStorage.removeItem('accessMode');
  delete axios.defaults.headers.common['Authorization'];

  store.dispatch({
    type: 'USER_LOGGED_OUT',
  });
};
// NOTE:your server must return response like as follow for getting the authenticated user information getUserData function must return response as
// {
//  “credentials”: {
//  “createdAt”: “2020–01–30T10:29:44.898Z”,
//  “email”: “bikash@gmail.com”,
//  “userId”: “D4hCBB4RcAdTjawNCQ0K4ItED563”
//  }
// }
// loginUser function must return token after successful login as:
// {
//     "token": "eyJhbGciOiJSUzI1NiIsImtpZCI6IjI1OTc0MmQyNjlhY2IzNWZiNjU3YzBjNGRkMmM3YjcyYWEzMTRiNTAiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vcGFya2l0LTI3YTQ4IiwiYXVkIjoicGFya2l0LTI3YTQ4IiwiYXV0aF90aW1lIjoxNTgwNjM3MzgyLCJ1c2VyX2lkIjoiRDRoQ0JCNFJjQWRUamF3TkNRMEs0SXRFRDU2MyIsInN1YiI6IkQ0aENCQjRSY0FkVGphd05DUTBLNEl0RUQ1NjMiLCJpYXQiOjE1ODA2MzczODIsImV4cCI6MTU4MDY0MDk4MiwiZW1haWwiOiJwYWRhbTEyQGdtYWlsLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwiZmlyZWJhc2UiOnsiaWRlbnRpdGllcyI6eyJlbWFpbCI6WyJwYWRhbTEyQGdtYWlsLmNvbSJdfSwic2lnbl9pbl9wcm92aWRlciI6InBhc3N3b3JkIn19.M0hTKqNb_0_3qmqS7xiI2I3mDpm6_gaAEIzRBydiu89pzaBJPXZ1BngdLQeJ2_JOh6GJBHDDeX52yM-DZdHoK6cC93ugqMjz7bzegf5YJzdYEtBPHFKvaDUQEgzgGhGbU_jl5xL09uIixPgOChw3zWbVAbvj4ZjJrT4u5aC96txhDA9gMPtRLq-9VoL4SQTRjOc-_vP08SylWP1oklMgsCfEGFCk81zcEsGEHfHwH_dIcj208G483lzD29Ghqmo3nKGp6u3eeXLxoyop8hKQsJUbvJK9zZmobfqZfghuDgiMj5_LqImmTeQR6jw7_S1NK0uDMcQl3G0lxezvS0n53w"
// }
