export const LAWYERS_LOADING = 'LAWYERS_LOADING';
export const LAWYERS_FAIL = 'LAWYERS_FAIL';
export const LAWYERS_SUCCESS = 'LAWYERS_SUCCESS';
export const LAWYERS_COUNT_SUCCESS = 'LAWYERS_COUNT_SUCCESS';
export const LAWYERS_SEARCH_SUCCESS = 'LAWYERS_SEARCH_SUCCESS';

export type DeleteLawyers = {
    toDelete: Array<string>;
    isTeam: boolean;
};

  
export type SearchLawyersData = {
    municipalityId: string;
    search: string;
    records: number;
    order: string;
    page: number;
};

export interface LawyersLoading {
    type: typeof LAWYERS_LOADING;
}
export interface LawyersFail {
    type: typeof LAWYERS_FAIL;
}
export interface LawyersSuccess {
    type: typeof LAWYERS_SUCCESS;
    payload: object;
}
export interface LawyersCountSuccess {
    type: typeof LAWYERS_COUNT_SUCCESS;
    payload: object;
}
export interface LawyersSearchSuccess {
    type: typeof LAWYERS_SEARCH_SUCCESS;
    searchValue: string;
}

export type LawyersDispatchTypes =
  | LawyersLoading
  | LawyersFail
  | LawyersSuccess
  | LawyersCountSuccess
  | LawyersSearchSuccess;
