import React from 'react';
import { RouteProps } from 'react-router-dom';
import './style.scss';
import Menu from './MenuSComponent';

interface LayoutProps extends RouteProps {
  role: any;
  children: any;
  history: any;
}

const LayoutSComponent: React.FunctionComponent<LayoutProps> = ({
  children,
  role,
  history,
}: any) => {

  return (
    <div className="layout-s">
      <Menu></Menu>
      <div className="body-layout-s">{children}</div>
    </div>
  );
};

export default LayoutSComponent;
