import React from 'react';
import Image from 'react-bootstrap/Image';
import Switch from 'react-switch';
import AnalisisImg from '../../../../assets/img/analisis.png';
import TerritorialImg from '../../../../assets/img/territorial.png';
import ElectoralImg from '../../../../assets/img/electoral.png';
import DiaDImg from '../../../../assets/img/diad.png';

interface ItemModuleProps {
  module: string;
  active: boolean;
  moduleAction: any;
}

const ItemModulePermission: React.FunctionComponent<ItemModuleProps> = ({
  module,
  active,
  moduleAction,
}: ItemModuleProps) => {
  //const [, setChecked] = useState(false);

  // const handleChange = (value: boolean) => {
  //   setChecked(value);
  // };

  const getName = () => {
    switch (module) {
      case 'ANALYSIS':
        return 'Analysis Electoral';
      case 'TERRITORIAL':
        return 'Territorial';
      case 'ELECTORAL':
        return 'Electoral';
      case 'D_DAY':
        return 'D Day';
      default:
        return '';
    }
  };

  const getImg = () => {
    switch (module) {
      case 'ANALYSIS':
        return AnalisisImg;
      case 'TERRITORIAL':
        return TerritorialImg;
      case 'ELECTORAL':
        return ElectoralImg;
      case 'D_DAY':
        return DiaDImg;
      default:
        return '';
    }
  };

  return (
    <>
      <div className="box-module">
        <div className="title-to-active-module">
          <h2>{getName()}</h2>
          <div className="activator-box">
            <Switch
              onChange={() => {
                moduleAction(module);
              }}
              checked={active}
              height={20}
              width={40}
              onColor="#2fad74"
              // onHandleColor="#2693e6"
              handleDiameter={20}
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              activeBoxShadow="0px 0px 1px 5px rgba(0, 0, 0, 0.2)"
              className="react-switch"
              id="material-switch"
            ></Switch>
          </div>
        </div>
        <Image src={getImg()}></Image>
      </div>
    </>
  );
};

export default ItemModulePermission;
