import { Address, VotersBox } from './../votersBox/VotersBoxTypes';
import { ROLE } from '../../utils/utils';

export const RGRC_LOADING = 'RGRC_LOADING';
export const RGRC_FAIL = 'RGRC_FAIL';
export const RGRC_SUCCESS = 'RGRC_SUCCESS';
export const RGRC_DATA_SUCCESS = 'RGRC_DATA_SUCCESS';
export const RGRC_COUNT_SUCCESS = 'RGRC_COUNT_SUCCESS';
export const RGRC_SEARCH_SUCCESS = 'RGRC_SEARCH_SUCCESS';
export const RGRC_LEFT_DATA_SUCCES = 'RGRC_LEFT_DATA_SUCCES';
export const RGRC_RG_SUCCESS_ONE = 'RGRC_RG_SUCCESS_ONE';

export enum TEAM_TYPE {
  RC_RG,
  CAPTURIST,
  MOBILIZATORS,
  HELPERS,
  CAPTURIST_LV2,
  CAPTURIST_LV1,
}
export enum SEX {
  MAN,
  WOMAN,
  APACHE_HELICOPTER,
}
export type Campaigns = {
  _id: string;
  name: string;
};
export interface Team {
  _id: string;
  bossId: string;
  members: string[];
  teamType: number;
  boss?: any;
  name: string;
  municipalityId: string;
  number?: number;
  email?: string;
  cellphone?: string;
}
export interface RCRG {
  _id: string;
  blocked: boolean;
  blockedReason: string;
  campaignId: string;
  email: string;
  lastName: string;
  firstName: string;
  role: ROLE;
  extra: Extra;
  team?: Team;
  name?: string;
}
export interface Extra {
  _id: string;
  addressId?: string;
  address?: Address;
  campaigns?: string[];
  votersBoxes: VotersBox[];
  sectionId: string;
  dob: Date;
  cellphone: string;
  sex: SEX;
}
export interface IRCRG {
  _id: string;
  blocked: boolean;
  blockedReason: string;
  campaignId: string;
  email: string;
  name: string;
  lastName: string;
  firstName: string;
  role: ROLE;
  addressText: string;
  extra: Extra;
  city?: string;
  district?: string;
  section?: string;
  incidentias: string[];
  courseProgress: number;
  cellphone: string;
}

export type DeleteRGRCTeam = {
  toDelete: Array<string>;
  isTeam: boolean;
};

export type SearchRGDataById = {
  bossId: string;
};

export type SearchRCsTeamsByCity = {
  municipalityId: string;
};

export type SearchTeamData = {
  selectedId: string;
  selectedRole: string;
  municipalityId: string;
  search: string;
  records: number;
  order: string;
  page: number;
};

export type SearchLeftData = {
  coordinatorId: string;
};

export interface RgrcLoading {
  type: typeof RGRC_LOADING;
}
export interface RgrcFail {
  type: typeof RGRC_FAIL;
}
export interface RgrcSuccess {
  type: typeof RGRC_SUCCESS;
  payload: object;
}
export interface RgrcDataSuccess {
  type: typeof RGRC_DATA_SUCCESS;
  payload: object;
}
export interface RgrcCountSuccess {
  type: typeof RGRC_COUNT_SUCCESS;
  payload: object;
}
export interface TeamsLeftDataSuccess {
  type: typeof RGRC_LEFT_DATA_SUCCES;
  payload: object;
}
export interface RgrcSearchSuccess {
  type: typeof RGRC_SEARCH_SUCCESS;
  searchValue: string;
}
export interface RgrcRgSuccessOne {
  type: typeof RGRC_RG_SUCCESS_ONE;
  payload: object;
  follow: string;
}

export type RgRcDispatchTypes =
  | RgrcLoading
  | RgrcFail
  | RgrcSuccess
  | RgrcDataSuccess
  | RgrcCountSuccess
  | TeamsLeftDataSuccess
  | RgrcSearchSuccess
  | RgrcRgSuccessOne;
