import {
  Logs,
  LogsDispatchTypes,
  LOGS_FAIL,
  LOGS_LOADING,
  LOGS_OPEN,
  LOGS_SUCCESS,
  MODULE,
} from './LogsTypes';

interface DefaultStateI {
  loading: boolean;
  logs: Logs[];
  open: boolean;
  dataId: string | null;
  module: MODULE | null;
}

const defaultState: DefaultStateI = {
  loading: false,
  logs: [],
  open: false,
  dataId: null,
  module: null,
};

const logsReducer = (
  state: DefaultStateI = defaultState,
  action: LogsDispatchTypes,
): DefaultStateI => {
  switch (action.type) {
    case LOGS_FAIL:
      return {
        ...state,
        loading: true,
      };
    case LOGS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case LOGS_SUCCESS:
      const newArray: any[] = [];
      newArray.push(...action.logs);
      return {
        ...state,
        loading: false,
        logs: newArray,
        dataId: action.dataId,
        module: action.module,
      };
    case LOGS_OPEN:
      return {
        ...state,
        open: action.open,
      };

    default:
      return state;
  }
};

export default logsReducer;
