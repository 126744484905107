// In types.ts we will define constants for user authentication and UI loading purpose
// user reducer types
export const SET_LOADING = 'SET_LOADING';
export const SET_PARTIES_DATA = 'SET_PARTIES_DATA';

export interface Party {
  _id: string;
  name: string;
  logoPath: string;
  abbreviation: string;
  countryName: string;
  iso3: string;
}

export interface SetLoading {
  type: typeof SET_LOADING;
  value: boolean;
}

export interface SetPartiesData {
  type: typeof SET_PARTIES_DATA;
  parties: Party[];
}

export type PartiesDispatchTypes = SetLoading | SetPartiesData;
