import React, { Fragment } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { Trash, AddActionIcon } from "../../../assets/svg";
import { useTranslation } from "react-i18next";
import "../style.scss";

interface TabsProps {
  options: any;
  children: any;
  otherKey: any;
  activeKey: any;
  setKey: any;
  addFun: any;
  deleteFun: any;
  tabName: any;
  selectFun?: any;
}

const StepsTabComponent: React.FunctionComponent<TabsProps> = ({
  options,
  children,
  otherKey,
  activeKey,
  setKey,
  addFun,
  deleteFun,
  tabName,
  selectFun,
}: any) => {
    const { t } = useTranslation();
    return (
        <Fragment>
            <div className="box-row">
                <h1 className="main-title">{t('settings.trainings.lbl.steps')}</h1>
                <div 
                className="add-icon"
                onClick={() => {
                    addFun();
                }}>
                    <AddActionIcon className="icon"></AddActionIcon>
                </div>
                <Trash
                className="last-icon"
                onClick={() => {
                    deleteFun(activeKey);
                }}
                ></Trash>
            </div>

            <Tabs
                className="tabs-search-container"
                id="controlled-tab-example"
                activeKey={activeKey}
                key={otherKey}
                onSelect={(k: any) => {
                setKey(k);
                if (selectFun) {
                    selectFun(activeKey);
                }
                }}
            >
                {children.map((e: any, index: number) => {
                window.dispatchEvent(new Event("resize"));
                return (
                    <Tab
                    className="tab-content-container"
                    key={index}
                    eventKey={index}
                    title={options[index]}
                    >
                    {e}
                    </Tab>
                );
                })}
                {children.length === 0 && (
                    //<Tab eventKey="no-results" title={`${tabName}s (0)`} disabled></Tab>
                    <></>
                )}
            </Tabs>

        </Fragment>
    );
};

export default StepsTabComponent;
