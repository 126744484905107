import React, { useEffect, Fragment } from "react";
import LayoutBodyComponent from "../../components/LayoutBody";
import TopSeparator from "../../components/TopSeparator";
import UploadImageBox from "./components/uploadImageBox";
import DetailMainData from "./components/detailMainData";
import StepsBox from "./components/stepsBox";
import { useTranslation } from "react-i18next";
import ModalNotification from "./components/modalNotification";
import ModalAdd from "./components/modalAdd";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootStore } from "../../redux/Store";
import SimpleModal from "../../utils/dialogs/simple-dialog.js";
import LoadingDialog from "../../dialogs/loading-dialog";
import ModalEdit from "./components/modalEdit";
import "./style.scss";
 
interface TrainingSettingsProps {
  match?: object;
}
const TrainingSettings: React.SFC<TrainingSettingsProps> = ({ match }: any) => {

  const defaultFormData = new FormData();

  const [uploadData, setUploadData] = React.useState(Object);
  const [detailData, setDetailData] = React.useState(Object);
  const [stepData, setStepData] = React.useState(Object);
  const [oldSteps, setOldSteps] = React.useState(Object);
  const [stepFiles, setStepFiles] = React.useState(defaultFormData);

  const [modalNotificationShow, setModalNotificationShow] = React.useState(false);
  const [modalAddShow, setModalAddShow] = React.useState(false);
  const [modalEditShow, setModalEditShow] = React.useState(false);

  const [isRedirect, setIsRedirect] = React.useState(false);
  const [customMsg, setCustomMsg] = React.useState(String);
  
  const [formData, setFormData] = React.useState(Object);

  const [params, setParams] = React.useState(match.params);
  const [isEdit, setIsEdit] = React.useState(false);
  const [isPerformingRequest, setIsPerformingRequest] = React.useState(false);
  const [viewMode, setViewMode] = React.useState(true);

  const [activeInfo, setActiveInfo] = React.useState(true);

  const redirect = useHistory();

  const training = useSelector(
    (globalState: RootStore) => globalState.trainings.training
  );

  console.log('formData', formData)

  const { t } = useTranslation();

  const checkUploadData = () => {
    let isValid = false;
    if (isEdit) {
      isValid = true;
    } else {
      isValid = Object.keys(uploadData).length === 4 ? true : false;
    }
    return isValid;
  };

  const checkDetailData = () => {
    let invalidCount = 0;
    if (Object.keys(detailData).length === 2) {
      if (detailData.name.length <= 0) {
        invalidCount++;
      }
      if (detailData.showTo <= 0) {
        invalidCount++;
      }
    }
    const isValid = invalidCount > 0 ? false : true;
    return isValid;
  };

  const checkStepData = () => {
    let invalidCount = 0;
    if (stepData.length > 0) {
      stepData.map((step: any) => {
        if (Object.keys(step.file).length < 3) {
          invalidCount++;
        }
        step.questions.map((question: any) => {
          if (question.name.length === 0) {
            invalidCount++;
          }
          question.answers.map((answer: any) => {
            if (answer.name.length === 0) {
              invalidCount++;
            }
          });
        });
      });
    }
    const isValid = invalidCount > 0 ? false : true;
    return isValid;
  };
  const itsValidFormData = () => {
    let isValid = false;
    const isValidUpload = checkUploadData();
    const isValidDetail = checkDetailData();
    const isValidSteps = checkStepData();
    if (isValidUpload && isValidDetail && isValidSteps) {
      isValid = true;
    }
    return isValid;
  };

  const saveForm = () => {
    let isValid = itsValidFormData();
    if (isValid) {
      if (isEdit) {
        setModalEditShow(true);
      } else {
        setModalAddShow(true);
      }
    }
  };

  useEffect(() => {
    let newFormData = { ...formData, ...{uploadData: uploadData }, ...{detailData: detailData}, ...{stepData: stepData} };
    if (isEdit) {
      newFormData = {...newFormData, ...{ oldSteps: oldSteps } }
    }
    setFormData(newFormData);
  }, [uploadData, detailData, stepData, oldSteps]);

  useEffect(() => {
    if (Object.keys(params).length) {
      setIsEdit(true);
    } else {
      setViewMode(false);
    }
  }, [params]);

  return (
    <Fragment>
      <TopSeparator></TopSeparator>
      <LayoutBodyComponent>

        <div className="add-training-container">

          <div className="training-information-box">
            <UploadImageBox
              saveUploadData={setUploadData}
              t={t}
              isEdit={isEdit}
              training={training}
              viewMode={viewMode}
              filesArray={stepFiles}
              setFilesArray={setStepFiles}
            ></UploadImageBox>

            <DetailMainData
              saveDetailData={setDetailData}
              saveForm={saveForm}
              t={t}
              isEdit={isEdit}
              training={training}
              viewMode={viewMode}
              setViewMode={setViewMode}
            ></DetailMainData>
          </div>

          <div className="training-flow-box">
            <StepsBox
              saveStepData={setStepData}
              t={t}
              isEdit={isEdit}
              training={training}
              viewMode={viewMode}
              oldSteps={oldSteps}
              setOldSteps={setOldSteps}
              formData={formData}
              setIsPerformingRequest={setIsPerformingRequest}
              filesArray={stepFiles}
              setFilesArray={setStepFiles}
            ></StepsBox>
          </div>
        </div>
      </LayoutBodyComponent>
      {modalNotificationShow && (
        <ModalNotification
          modalShow={modalNotificationShow}
          setModalShow={setModalNotificationShow}
          customMsg={customMsg}
          successFun={() => {
            setIsRedirect(false);
            redirect.push("/trainings");
          }}
          isRedirect={isRedirect}
          t={t}
        ></ModalNotification>
      )}
      {modalAddShow && (
        <ModalAdd
          modalShow={modalAddShow}
          setModalShow={setModalAddShow}
          setIsPerformingRequest={setIsPerformingRequest}
          training={formData}
          filesArray={stepFiles}
          setFilesArray={setStepFiles}
          successFun={(data: any) => {
            if (data.created) {
              setIsRedirect(true);
              setCustomMsg(t("settings.trainings.created.success"));
            } else {
              setIsRedirect(false);
              setCustomMsg(t("settings.trainings.created.failure"));
            }
            setModalNotificationShow(true);
          }}
          t={t}
        ></ModalAdd>
      )}
      {isPerformingRequest && (
        <SimpleModal>
          <LoadingDialog></LoadingDialog>
        </SimpleModal>
      )}
      {modalEditShow && (
        <ModalEdit
          modalShow={modalEditShow}
          setModalShow={setModalEditShow}
          setIsPerformingRequest={setIsPerformingRequest}
          training={formData}
          filesArray={stepFiles}
          setFilesArray={setStepFiles}
          successFun={(data: any) => {
            if (data.edited) {
              setIsRedirect(true);
              setCustomMsg(t("settings.trainings.updated.success"));
            } else {
              setIsRedirect(false);
              setCustomMsg(t("settings.trainings.updated.failure"));
            }
            setModalNotificationShow(true);
          }}
          t={t}
        ></ModalEdit>
      )}
    </Fragment>
  );
};

export default TrainingSettings;
