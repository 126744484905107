import { ID } from '../../utils/utils';
import {
  CoreLicenseDispatchTypes,
  FeatureCollection,
  Feature,
  MODE_TYPE,
  SET_CITIES_DATA,
  SET_LOADING,
  SET_STATES_DATA,
  SET_STATES_DATA_TO_CREATE,
  IGNORE_FEATURE,
} from './coreLicenseTypes';

export interface PartyState {
  loading: boolean;
  mode: string;
  statesCollections?: FeatureCollection;
  citiesCollections?: FeatureCollection;
  features?: Feature[];
}

export const defaultState: PartyState = {
  loading: false,
  mode: MODE_TYPE.NONE,
};

export const coreLicenseReducer = (
  state: PartyState = defaultState,
  action: CoreLicenseDispatchTypes,
): PartyState => {
  switch (action.type) {
    case SET_STATES_DATA:
      const array1: {
        _id: string;
        geometry: {
          type: string;
          coordinates: [[[number]]];
        };
        properties: any;
      }[] = [];
      action.featureCollection.features.forEach((e) => {
        const _id = ID();
        array1.push({ ...e, _id });
      });
      return {
        ...state,
        statesCollections: {
          type: action.featureCollection.type,
          features: array1,
        },
        mode: MODE_TYPE.STATES,
      };
    case SET_CITIES_DATA:
      const array2: {
        _id: string;
        geometry: {
          type: string;
          coordinates: [[[number]]];
        };
        properties: any;
      }[] = [];
      action.featureCollection.features.forEach((e) => {
        const _id = ID();
        array2.push({ ...e, _id });
      });
      return {
        ...state,
        citiesCollections: {
          type: action.featureCollection.type,
          features: array2,
        },
        mode: MODE_TYPE.CITIES,
      };
    case SET_STATES_DATA_TO_CREATE:
      return {
        ...state,
        features: action.features,
      };
    case IGNORE_FEATURE:
      return {
        ...state,
        features: state.features?.filter((e) => e._id !== action.featureId),
      };
    case SET_LOADING:
      return {
        ...state,
        loading: action.value,
      };

    default:
      return state;
  }
};
