import { Dispatch } from 'redux';
import {
  UpDataDispatchTypes,
  UP_DATA_LOADING,
  UP_DATA_SUCCESS,
  UP_DATA_FAIL,
} from './UpDataTypes';
import * as netWorking from '../netWorking';

export const getUpData = (cityLicenseId: string) => async (
  dispatch: Dispatch<UpDataDispatchTypes>,
) => {
  dispatch({
    type: UP_DATA_LOADING,
  });
  try {
    netWorking.get(
      netWorking.GetUpData + cityLicenseId,
      (res: any) => {
        dispatch({
          type: UP_DATA_SUCCESS,
          upData: res.data.response,
        });
      },
      (error: any) => {
        dispatch({
          type: UP_DATA_FAIL,
        });
      },
    );
  } catch (error) {
    dispatch({
      type: UP_DATA_SUCCESS,
      upData: [],
    });
  }
};
