import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../style.scss";
import StaticModal from "../../../dialogs/StaticModal";

interface ModalNotificationProps {
  modalShow: any;
  setModalShow: any;
  customMsg: any;
  successFun: any;
  isRedirect: any;
  t: any;
}

export const ModalNotification: React.SFC<ModalNotificationProps> = ({
  modalShow,
  setModalShow,
  customMsg,
  successFun,
  isRedirect,
  t,
}: any) => {
  const submitFun = () => {
    if (isRedirect) {
      successFun();
    }
    setModalShow(false);
  };
  return (
    <StaticModal
      show={modalShow}
      title={t("settings.trainings.modal.notification.title")}
      closeText={t("submit.ok")}
      confirmText={t("submit.ok")}
      onHide={() => setModalShow(false)}
      submitFun={submitFun}
      validBtn={false}
      size="sm"
      showConfirmBtn={true}
      showCancelBtn={false}
    >
      <Row noGutters className="modal-text-row">
        <Col xs={12} className="description-col">
          {customMsg}
        </Col>
      </Row>
    </StaticModal>
  );
};

export default ModalNotification;
