import React from 'react';
import Image from 'react-bootstrap/Image';
import { DeleteBlackIcon, EditIcon } from '../../../../assets/svg';
import { useHistory } from 'react-router-dom';
import { SUPER_CAMPAIGNS } from '../../../../utils/paths';
import { formatPhoneNumber } from '../../../../utils/utils';
import { useDispatch } from 'react-redux';
import {
  Campaign,
  SET_SELECTED_LICENSE_CITY,
} from '../../../../redux/campaigns/campaignsTypes';
import moment from 'moment';
import { Party } from '../../../../redux/parties/partiesTypes';
import { BsShieldLockFill } from 'react-icons/bs';

interface LicenseProps {
  active: boolean;
  data: Campaign;
  parties: Party[];
  onDelete: any;
  onEdit: any;
  onAccess: any;
}

const LicenseItem: React.FunctionComponent<LicenseProps> = ({
  active,
  data,
  parties,
  onDelete,
  onEdit,
  onAccess,
}: LicenseProps) => {
  const redirect = useHistory();
  const dispatch = useDispatch();

  const getLogo = () => {
    return parties.find((e) => data.partyId === e._id)?.logoPath || '';
  };

  return (
    <>
      <div
        className={data.active ? 'item-license' : 'item-license de-activated'}
      >
        <div className="top-info-box">
          <div className="party-license-box">
            <Image src={getLogo()}></Image>
            <p>
              License: <i>{data.active ? 'Active' : 'De-Active'}</i>
            </p>
          </div>
          <div className="license-name-box">
            <h1>
              {moment(data?.endDate).format('yyyy')}
              {' - '}
              {data.name}
            </h1>
            <div className="actions-license-box">
              <BsShieldLockFill
                onClick={() => {
                  onAccess(data);
                }}
              ></BsShieldLockFill>
              <DeleteBlackIcon
                onClick={() => {
                  onDelete(data);
                }}
              ></DeleteBlackIcon>
              <EditIcon
                onClick={() => {
                  onEdit(data);
                }}
              ></EditIcon>
              <div className="empty-box"></div>
            </div>
          </div>
          <div className="type-license-box">
            <span
              className={
                data.campaignType === 'GOVERNOR'
                  ? 'governor-type'
                  : 'mayor-type'
              }
            >
              {data.campaignType === 'GOVERNOR' ? 'Governor' : 'Mayor'}
            </span>
            <p>{data.candidateName}</p>
          </div>
          <div className="extra-info-box">
            <p>
              <strong>Campaign Manager: </strong>
              {data.bossName}
            </p>
          </div>
          <div className="extra-info-box">
            <p>
              <strong>Contact: </strong>
              {formatPhoneNumber(data.contact) + ' ' + data.email}{' '}
            </p>
          </div>
        </div>
        <div className="cities-info-box">
          <p>{data.cities.length + ' '}Cities</p>
          <div className="list-cities">
            {data.cities.map((e: any) => {
              return (
                <div
                  key={e._id}
                  className="city-item"
                  onClick={() => {
                    dispatch({
                      type: SET_SELECTED_LICENSE_CITY,
                      licenseSelected: data,
                      cityLicense: e,
                    });
                    redirect.push(SUPER_CAMPAIGNS + `/${data._id}/${e._id}`);
                  }}
                >
                  <div
                    className={
                      e.active && data.active
                        ? 'triangle-green'
                        : 'triangle-grey'
                    }
                  ></div>
                  {e.name}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default LicenseItem;
