import React, { createRef, FunctionComponent } from 'react';
import { RouteProps } from 'react-router-dom';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './style.scss';
import moment from 'moment';
import {
  IoMdArrowDropleft,
  IoMdArrowDropright,
  IoMdCalendar,
} from 'react-icons/io';
import { useTranslation } from 'react-i18next';
import Dropdown from 'react-bootstrap/Dropdown';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

interface ItemDateProps extends RouteProps {
  text: string;
  activeDates?: any;
  onChange: any;
  value: any;
}

const ItemDateComponent: FunctionComponent<ItemDateProps> = ({
  text,
  activeDates,
  onChange,
  value,
}: any) => {
  const [visible, showCalendar] = React.useState(false);
  const { t } = useTranslation();
  const nextDay = () => {
    const date = moment(value).toDate();
    date.setDate(date.getDate() + 1);
    onChange(date);
  };

  const lastDay = () => {
    const date = moment(value).toDate();
    date.setDate(date.getDate() - 1);
    onChange(date);
  };

  const ref = createRef<HTMLDivElement>();
  const refDropdown = createRef<Dropdown<any>>();

  return (
    <div className="item-date-filter">
      <div className="date-picker-box">
        <div ref={ref} className="date-picker">
          <div className="arrow-box right-arrow-1" onClick={lastDay}>
            {' '}
            <IoMdArrowDropleft></IoMdArrowDropleft>
          </div>
          <div className="date-box">{moment(value).format('DD-MM-YYYY')}</div>
          <div className="arrow-box right-arrow-1" onClick={nextDay}>
            {' '}
            <IoMdArrowDropright></IoMdArrowDropright>
          </div>
          <Dropdown ref={refDropdown} as={ButtonGroup}>
            <Dropdown.Toggle split variant="success" id="dropdown-split-basic">
              <div
                className="arrow-box calendar-icon-box"
                onClick={() => {
                  showCalendar(!visible);
                }}
              >
                <IoMdCalendar></IoMdCalendar>
              </div>
            </Dropdown.Toggle>

            <Dropdown.Menu
              className="dropwwwwwww"
              style={{
                transform: `translate(${0}px, ${
                  ref.current?.clientHeight
                }px) !important`,
              }}
            >
              <Calendar
                onChange={(e: any) => {
                  onChange(e);
                  showCalendar(false);
                }}
                locale={t('language')}
                value={value}
                tileClassName={({ date, view }) => {
                  if (
                    activeDates !== undefined &&
                    activeDates.find(
                      (x: any) =>
                        moment(x).utc(false).format('DD-MM-YYYY') ===
                        moment(date).utc(false).format('DD-MM-YYYY'),
                    )
                  ) {
                    return 'highlight';
                  } else return '';
                }}
              />
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default ItemDateComponent;
