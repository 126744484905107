import React, { FunctionComponent, useState } from 'react';
import {
  TerritorialIcon,
  DashboardIcon,
  ElectoralIcon,
  SettingsIcon,
  DiaDIcon,
  Arrow,
} from '../assets/svg/index';
import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Nav from 'react-bootstrap/Nav';
import Collapse from 'react-bootstrap/Collapse';
import {
  PRIVATE_DASHBOARD,
  PRIVATE_ROUTES,
  PRIVATE_VOTERS,
  PRIVATE_TRACKING,
  PRIVATE_TEAMS,
  PRIVATE_AUDITING,
  PRIVATE_POLLWATCHERS,
  PRIVATE_LAWYERS,
  PRIVATE_POLLINGSTATIONS,
  PRIVATE_PROBLEMS,
  PRIVATE_HISTORY,
  PRIVATE_POLLS,
  PRIVATE_MAP,
  PRIVATE_POLLINGSTATIONS_ACTIVE,
  PRIVATE_BINGO,
  PRIVATE_MOBILIZATION,
  PRIVATE_POLLINGSTATIONS_SETTINGS,
  PRIVATE_COALITIONS,
  PRIVATE_VOTERS_LIST,
  PRIVATE_TRAINING_LIST,
  PRIVATE_DASHBOARD_DIAD,
} from '../utils/paths';
import { NavLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import GioobyLogo from '../assets/logo/logo_giooby.png';
import GioobyLogoAlone from '../assets/logo/logo_giooby_alone.png';
import { RiArrowLeftLine } from 'react-icons/ri';
import { RootStore } from '../redux/Store';
import { useSelector } from 'react-redux';
import { MODULES } from '../redux/coreLicense/coreLicenseTypes';

interface MenuProps {
  role: any;
  history: any;
  showMenu: boolean;
  setMenu: any;
}

const Menu: FunctionComponent<MenuProps> = ({
  role,
  history,
  showMenu,
  setMenu,
}: any) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(1);
  const location = useLocation();

  const citySelected = useSelector((globalState: RootStore) => {
    return globalState.core.globalMunicipalitySelected;
  });

  const ofType = (type: any) => {
    const path = location.pathname;
    if (
      type === 1 &&
      (path.startsWith(PRIVATE_VOTERS) ||
        path.startsWith(PRIVATE_ROUTES) ||
        path.startsWith(PRIVATE_TRACKING) ||
        path.startsWith(PRIVATE_TEAMS) ||
        path.startsWith(PRIVATE_AUDITING)) &&
      path !== PRIVATE_VOTERS_LIST
    ) {
      return true;
    }
    if (
      type === 2 &&
      (path.startsWith(PRIVATE_POLLWATCHERS) ||
        path.startsWith(PRIVATE_LAWYERS) ||
        path.startsWith(PRIVATE_POLLINGSTATIONS))
    ) {
      return true;
    }
    if (
      type === 3 &&
      (path.startsWith(PRIVATE_HISTORY) || path.startsWith(PRIVATE_POLLS))
    ) {
      return true;
    }
    if (
      type === 4 &&
      (path.startsWith(PRIVATE_DASHBOARD_DIAD) ||
        path.startsWith(PRIVATE_POLLINGSTATIONS_ACTIVE) ||
        path.startsWith(PRIVATE_BINGO) ||
        path.startsWith(PRIVATE_PROBLEMS) ||
        path.startsWith(PRIVATE_MAP) ||
        path.startsWith(PRIVATE_MOBILIZATION))
    ) {
      return true;
    }
    if (
      type === 5 &&
      (path.startsWith(PRIVATE_POLLINGSTATIONS_SETTINGS) ||
        path.startsWith(PRIVATE_COALITIONS) ||
        path.startsWith(PRIVATE_VOTERS_LIST))
    ) {
      return true;
    }
  };

  const getClass = (val: any) => {
    if (visible === val) return ' menu-item active-dropdown';
    else return ' menu-item';
  };
  return (
    <div className={showMenu ? 'menu-layout slim-menu' : 'menu-layout'}>
      <div
        className={showMenu ? 'circle-hamburger active' : 'circle-hamburger'}
        onClick={() => setMenu(!showMenu)}
      >
        <RiArrowLeftLine></RiArrowLeftLine>
        {/* <Menu></Menu> */}
      </div>
      <Container className="logo">
        <Image src={showMenu ? GioobyLogoAlone : GioobyLogo} fluid />
      </Container>
      <Nav defaultActiveKey={PRIVATE_DASHBOARD} className="flex-column nav">
        <div className="menu-item-alone">
          <NavLink
            to={PRIVATE_DASHBOARD}
            activeClassName="active-route active"
            onClick={() => {
              setVisible(0);
            }}
          >
            <DashboardIcon></DashboardIcon>
            <h4>{t('menu.dashboard')}</h4>
          </NavLink>
        </div>
        {/*<div
          className={ofType(3) ? 'active-route' + getClass(3) : getClass(3)}
          onClick={() => {
            if (showMenu) {
              setMenu(false);
            }
            setVisible(3);
          }}
        >
          <AnalysisIcon></AnalysisIcon> <h4>{t('menu.analysis')}</h4>{' '}
          <Arrow className="arrow-dropdown"></Arrow>
        </div>
        <Collapse in={visible === 3}>
          <div
            className={
              visible === 3 ? 'group-items show' : 'group-items hidden'
            }
          >
            <NavLink to={PRIVATE_HISTORY}>{t('menu.analysis.historical')}</NavLink>
            <NavLink to={PRIVATE_POLLS}>{t('menu.analysis.polls')}</NavLink>
          </div>
        </Collapse>*/}
        {citySelected?.modules.includes(MODULES.TERRITORIAL) && (
          <div
            className={ofType(1) ? 'active-route' + getClass(1) : getClass(1)}
            onClick={() => {
              if (showMenu) {
                setMenu(false);
              }
              if (visible === 1) setVisible(0);
              else setVisible(1);
            }}
          >
            <TerritorialIcon></TerritorialIcon>
            <h4>{t('menu.territorial')}</h4>
            <Arrow className="arrow-dropdown"></Arrow>
          </div>
        )}
        <Collapse in={visible === 1}>
          <div
            className={
              visible === 1 ? 'group-items show' : 'group-items hidden'
            }
          >
            <NavLink to={PRIVATE_VOTERS}>{t('menu.voters')}</NavLink>
            <NavLink to={PRIVATE_ROUTES}>{t('menu.areas')}</NavLink>
            <NavLink to={PRIVATE_TRACKING}>{t('menu.tracking')}</NavLink>
            <NavLink to={PRIVATE_TEAMS}>{t('menu.teams')}</NavLink>
            <NavLink to={PRIVATE_AUDITING}>{t('menu.auditing')}</NavLink>
          </div>
        </Collapse>
        {citySelected?.modules.includes(MODULES.ELECTORAL) && (
          <div
            className={ofType(2) ? 'active-route' + getClass(2) : getClass(2)}
            onClick={() => {
              if (showMenu) {
                setMenu(false);
              }
              if (visible === 2) setVisible(0);
              else setVisible(2);
            }}
          >
            <ElectoralIcon></ElectoralIcon>
            <h4>{t('menu.electoral')}</h4>{' '}
            <Arrow className="arrow-dropdown"></Arrow>
          </div>
        )}
        <Collapse in={visible === 2}>
          <div
            className={
              visible === 2 ? 'group-items show' : 'group-items hidden'
            }
          >
            <NavLink to={PRIVATE_POLLWATCHERS}>
              {t('menu.pollwatchers')}
            </NavLink>
            <NavLink to={PRIVATE_LAWYERS}>{t('menu.lawyers')}</NavLink>
            <NavLink to={PRIVATE_POLLINGSTATIONS}>
              {t('menu.pollingstations')}
            </NavLink>
          </div>
        </Collapse>
        {citySelected?.modules.includes(MODULES.D_DAY) && (
          <div
            className={ofType(4) ? 'active-route' + getClass(4) : getClass(4)}
            onClick={() => {
              if (showMenu) {
                setMenu(false);
              }
              if (visible === 4) setVisible(0);
              else setVisible(4);
            }}
          >
            <DiaDIcon></DiaDIcon>
            <h4>{t('menu.dia.d')}</h4>
            <Arrow className="arrow-dropdown"></Arrow>
          </div>
        )}
        <Collapse in={visible === 4}>
          <div
            className={
              visible === 4 ? 'group-items show' : 'group-items hidden'
            }
          >
            {/* <NavLink to={PRIVATE_DASHBOARD_DIAD}>
              {t('menu.dashboard.d')}
            </NavLink> */}
            <NavLink to={PRIVATE_POLLINGSTATIONS_ACTIVE}>
              {t('menu.pollingstations')}
            </NavLink>
            <NavLink to={PRIVATE_BINGO}>{t('menu.bingo')}</NavLink>
            <NavLink to={PRIVATE_PROBLEMS}>{t('menu.problems')}</NavLink>
            {/*<NavLink to={PRIVATE_MAP}>{t('menu.map')}</NavLink>*/}
            {/*<NavLink to={PRIVATE_MOBILIZATION}>{t('menu.mobilization')}</NavLink>*/}
          </div>
        </Collapse>
        <div
          className={ofType(5) ? 'active-route' + getClass(5) : getClass(5)}
          onClick={() => {
            if (showMenu) {
              setMenu(false);
            }
            if (visible === 5) setVisible(0);
            else setVisible(5);
          }}
        >
          <SettingsIcon></SettingsIcon> <h4>{t('menu.settings')}</h4>
          <Arrow className="arrow-dropdown"></Arrow>
        </div>
        <Collapse in={visible === 5}>
          <div
            className={
              visible === 5 ? 'group-items show' : 'group-items hidden'
            }
          >
            <NavLink to={PRIVATE_POLLINGSTATIONS_SETTINGS}>
              {t('menu.pollingstations')}
            </NavLink>
            {/*<NavLink to={PRIVATE_COALITIONS}>{t('menu.coalitions')}</NavLink>*/}
            <NavLink to={PRIVATE_VOTERS_LIST}>{t('menu.votersList')}</NavLink>
            <NavLink to={PRIVATE_TRAINING_LIST}>{t('menu.training')}</NavLink>
            {/*<NavLink to={PRIVATE_USERS}>{t('menu.users')}</NavLink>
            <NavLink to={PRIVATE_ROLES}>{t('menu.roles')}</NavLink>*/}
          </div>
        </Collapse>
      </Nav>
      {/* <Container className="logo-bottom">
        <Image src={GioobyLogo} fluid />
      </Container> */}
      <div className="version-box">
        {!showMenu && <p>v1.1.11 - 05/21/21</p>}
        {showMenu && <p className="version-no-showMenu">v1.1.11</p>}
      </div>
    </div>
  );
};

export default Menu;
