import React, { useEffect, useState } from 'react';
//import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { updateLicense } from '../../../../redux/campaigns/campaignsActions';
import {
  Campaign,
  City,
  SET_SELECTED_LICENSE_CITY,
} from '../../../../redux/campaigns/campaignsTypes';
import { MODULES } from '../../../../redux/coreLicense/coreLicenseTypes';
import ItemModulePermission from './itemModulePermissions';

interface ModulesListProps {
  licenseSelected?: Campaign;
  cityLicense?: City;
}

// tslint:disable-next-line: prefer-const
let defaultData: City;

const ModulesList: React.FunctionComponent<ModulesListProps> = ({
  licenseSelected,
  cityLicense,
}: any) => {
  const dispatch = useDispatch();
  const [citySelected, setCitySelected] = useState(defaultData);

  const moduleAction = (module: string) => {
    const modules = [...citySelected.modules];
    const index = modules.indexOf(module);
    if (index === -1) {
      modules.push(module);
    } else {
      modules.splice(index, 1);
    }
    setCitySelected((prevState: any) => ({
      ...prevState,
      modules,
    }));
    onSave(modules);
  };

  const onSave = (modules: string[]) => {
    if (licenseSelected && cityLicense) {
      const license = licenseSelected;
      const city = cityLicense;
      const cities: any = [];
      license.cities.forEach((e: City) => {
        if (e._id === city._id) {
          cities.push({
            ...e,
            modules,
          });
        } else {
          cities.push(e);
        }
      });
      license.cities = [...cities];
      dispatch(
        updateLicense(
          license,
          (data: any) => {
            let city2: any = null;
            data.cities.forEach((e: City) => {
              if (e._id === city._id) {
                city2 = e;
              }
            });
            dispatch({
              type: SET_SELECTED_LICENSE_CITY,
              licenseSelected: data,
              cityLicense: city2,
            });
          },
          (err: any) => {
            console.log(err);
          },
        ),
      );
    }
  };

  useEffect(() => {
    setCitySelected(cityLicense);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cityLicense]);

  return (
    <>
      <div className="module-tab">
        <h2>Modulos</h2>
        <div className="list-modules">
          <ItemModulePermission
            module={MODULES.ANALYSIS}
            active={
              citySelected?.modules.indexOf(MODULES.ANALYSIS) !== -1 || false
            }
            moduleAction={moduleAction}
          ></ItemModulePermission>
          <ItemModulePermission
            module={MODULES.TERRITORIAL}
            active={
              citySelected?.modules.indexOf(MODULES.TERRITORIAL) !== -1 || false
            }
            moduleAction={moduleAction}
          ></ItemModulePermission>
          <ItemModulePermission
            module={MODULES.ELECTORAL}
            active={
              citySelected?.modules.indexOf(MODULES.ELECTORAL) !== -1 || false
            }
            moduleAction={moduleAction}
          ></ItemModulePermission>
          <ItemModulePermission
            module={MODULES.D_DAY}
            active={
              citySelected?.modules.indexOf(MODULES.D_DAY) !== -1 || false
            }
            moduleAction={moduleAction}
          ></ItemModulePermission>
        </div>
      </div>
    </>
  );
};

export default ModulesList;
