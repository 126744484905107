import React, { useEffect, useState } from 'react';
import './style.scss';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../../redux/Store';
import LayoutBodyComponent from '../../../../components/LayoutBody';
import Top from './components/top';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import { BsSearch } from 'react-icons/bs';
import AddPartyModalComponent from './components/addParties';
import { getParties } from '../../../../redux/parties/partiesActions';
import { Party } from '../../../../redux/parties/partiesTypes';
import PartyItem from './components/partyItem';

// tslint:disable-next-line: prefer-const
let emptyEdit: any;

const Parties: React.FunctionComponent = () => {
  // const { t } = useTranslation();

  const dispatch = useDispatch();
  const [showAddModal, setShowAddModal] = useState(false);
  const [load, setLoad] = useState(false);
  const [toEdit, setToEdit] = useState(emptyEdit);
  const [search, setSearch] = useState('');
  const [countrySelected, setCountrySelected] = useState(emptyEdit);
  const countries = useSelector((globalState: RootStore) => {
    return globalState.campaignsManager.countries;
  });

  const parties = useSelector((globalState: RootStore) => {
    return globalState.party.parties;
  });

  useEffect(() => {
    if (!load && countries) {
      setCountrySelected(countries[0].country);
      dispatch(getParties(countries[0].country._id));
      setLoad(true);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [load]);

  useEffect(() => {
    if (countries) {
      setCountrySelected(countries[0].country);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countries]);

  const onEdit = (e?: Party) => {
    setToEdit(e);
    setShowAddModal(true);
  };

  return (
    <>
      {' '}
      <LayoutBodyComponent>
        <div className="body-country body-country-list">
          <Top
            title={'Parties'}
            btnText={'Add Party'}
            submit={() => {
              onEdit(undefined);
            }}
          >
            <div className={'selector-box-horizontal'}>
              <h2>Countries</h2>
              <Form.Control
                size="sm"
                as="select"
                value={countrySelected?._id || ''}
                onChange={(data: any) => {
                  // const countryName = data.target.value;
                  setCountrySelected(
                    countries.find((e) => e.country._id === data.target.value)
                      .country,
                  );
                  dispatch(getParties(data.target.value));
                }}
              >
                {countries.map((e: any, index: any) => {
                  return (
                    <option
                      // selected={e._id === defaultSelector}
                      key={e.country._id}
                      value={e.country._id}
                    >
                      {e.country.name}
                    </option>
                  );
                })}
              </Form.Control>
            </div>
          </Top>
          <div className="body-list-campaings parties-body">
            <div className="top">
              <div className="filters-campaigns">
                <div className={'item-filter-c active'}>
                  <p>
                    {
                      parties.filter(
                        (e) =>
                          e.abbreviation.includes(search) ||
                          e.name.includes(search),
                      ).length
                    }{' '}
                    Parties
                  </p>
                </div>
              </div>
              <div className="filters-search-box">
                <Form inline className={'tab-search-field-normal'}>
                  <FormControl
                    defaultValue={''}
                    type="text"
                    placeholder={'Search'}
                    className="mr-sm-2"
                    onChange={(data: any) => {
                      setSearch(data.target.value);
                    }}
                  />
                  <div className="icon">
                    <BsSearch />
                  </div>
                </Form>
              </div>
            </div>
            <div className="list">
              {parties
                .filter(
                  (e) =>
                    e.abbreviation.includes(search) || e.name.includes(search),
                )
                .map((e: Party, i: number) => {
                  return (
                    <PartyItem
                      party={e}
                      onEdit={onEdit}
                      onDelete={() => {
                        dispatch(getParties(countrySelected._id));
                      }}
                    ></PartyItem>
                  );
                })}
            </div>
          </div>
        </div>
      </LayoutBodyComponent>
      <AddPartyModalComponent
        modalShow={showAddModal}
        setModalShow={setShowAddModal}
        successFun={() => {
          dispatch(getParties(countrySelected._id));
        }}
        toEdit={toEdit}
        countrySelected={countrySelected}
      ></AddPartyModalComponent>
    </>
  );
};

export default Parties;
