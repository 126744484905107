import React from 'react';
import { useDispatch } from 'react-redux';
import StaticModal from '../../../../dialogs/StaticModal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { updateLicense } from '../../../../redux/campaigns/campaignsActions';
import { Campaign } from '../../../../redux/campaigns/campaignsTypes';

interface AddModalProps {
  modalShow: any;
  setModalShow: any;
  successFun: any;
  licenseSelected?: Campaign;
}

export const DeleteLicenseModalComponent: React.FunctionComponent<AddModalProps> = ({
  modalShow,
  setModalShow,
  successFun,
  licenseSelected,
}: AddModalProps) => {
  // const { t } = useTranslation();
  const dispatch = useDispatch();
  // const redirect = useHistory();

  const cancelFun = () => {
    setModalShow(false);
  };

  const submitFun = () => {
    onSave();
  };

  const onSave = () => {
    if (licenseSelected) {
      const license = licenseSelected;
      dispatch(
        updateLicense(
          { ...license, deleted: true },
          (data: any) => {
            successFun();
            setModalShow(false);
          },
          (err: any) => {
            console.log(err);
          },
        ),
      );
    }
  };

  return (
    <StaticModal
      show={modalShow}
      title={'Delete License'}
      closeText={'Cancel'}
      confirmText={'Delete'}
      onHide={cancelFun}
      submitFun={submitFun}
      validBtn={false}
      size="sm"
      sizeClass="delete-license-modal"
    >
      <Row noGutters className="modal-text-row">
        <Col xs={12} className="description-col">
          <div className="text-description">
            Are you sure you want to delete
            <strong> {licenseSelected?.name || ''}</strong> ?
          </div>
        </Col>
      </Row>
    </StaticModal>
  );
};

export default DeleteLicenseModalComponent;
