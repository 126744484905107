import {
  RGRC_LOADING,
  RGRC_FAIL,
  RGRC_SUCCESS,
  RGRC_DATA_SUCCESS,
  RGRC_COUNT_SUCCESS,
  RGRC_SEARCH_SUCCESS,
  RGRC_LEFT_DATA_SUCCES,
  RGRC_RG_SUCCESS_ONE,
  RgRcDispatchTypes,
  //Types
  Team,
} from './RgRcTypes';

interface RgRcState {
  loading: boolean;
  page?: number;
  records?: number;
  sort?: any[];
  search: string;
  totalPage: number;
  totalTeamData: number;
  teams: Team[];
  teamData: any[];
  teamLeftSide: any[];
  selectedRG: object;
  navigationPath: any[];
}

const defaultState: RgRcState = {
  loading: false,
  page: 1,
  records: 10,
  sort: ['firstName', 'asc'],
  search: '',
  totalPage: 1,
  totalTeamData: 0,
  teamLeftSide: [],
  teams: [],
  teamData: [],
  selectedRG: {},
  navigationPath: [],
};

export const RgRcReducer = (
  state: RgRcState = defaultState,
  action: RgRcDispatchTypes,
): RgRcState => {
  switch (action.type) {
    case RGRC_FAIL:
      return {
        ...state,
        loading: false,
      };
    case RGRC_LOADING:
      return {
        ...state,
        loading: true,
      };
    case RGRC_SUCCESS:
      const teams = [];
      const dataTeamsSuccess: any = action.payload;
      teams.push(...(dataTeamsSuccess.teams || []));

      return {
        ...state,
        teams: [...teams],
      };
    case RGRC_COUNT_SUCCESS:
      const countTeamDataSuccess: any = action.payload;
      return {
        ...state,
        totalPage: countTeamDataSuccess.pages,
        totalTeamData: countTeamDataSuccess.totalRCs,
      };
    case RGRC_DATA_SUCCESS:
      const list_teamsRcs = [];
      const dataTeamDataSuccess: any = action.payload;
      list_teamsRcs.push(...(dataTeamDataSuccess.teamData || []));
      return {
        ...state,
        teamData: list_teamsRcs,
        records: dataTeamDataSuccess.records,
        sort: ['firstName', dataTeamDataSuccess.order],
        page: dataTeamDataSuccess.page,
        loading: false,
      };
    case RGRC_SEARCH_SUCCESS:
      return {
        ...state,
        search: action.searchValue,
      };
    case RGRC_LEFT_DATA_SUCCES:
      const leftSide_teamsRCs = [];
      const leftDataSuccess: any = action.payload;
      leftSide_teamsRCs.push(...(leftDataSuccess.teamData || []));
      return {
        ...state,
        teamLeftSide: leftSide_teamsRCs,
      };
    case RGRC_RG_SUCCESS_ONE:
      const RGDataSuccess: any = action.payload;
      const followDataSuccess: any = action.follow;
      let newPath = [...state.navigationPath];

      if (followDataSuccess !== '-1') {
        let toDelete = newPath.length - parseInt(followDataSuccess);
        newPath.splice(followDataSuccess, toDelete);
      } else {
        newPath.push(
          ...[
            {
              id: RGDataSuccess._id,
              name:
              RGDataSuccess.firstName +
                ' ' +
                RGDataSuccess.lastName,
              role: RGDataSuccess.role,
              teamName: RGDataSuccess.teamName,
              teamId: RGDataSuccess.teamId,
            },
          ],
        );
      }
      return {
        ...state,
        selectedRG: RGDataSuccess,
        navigationPath: newPath,
        loading: false,
      };
    default:
      return state;
  }
};
