import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootStore } from '../../../redux/Store';

import {
  postVotersListByAddressElectionsSearch,
  getVoterById,
  postGetVotersTotal,
} from '../../../redux/voters/VotersActions';
import { getLogs } from '../../../redux/logs/LogsActions';
import { LOGS_OPEN, MODULE } from '../../../redux/logs/LogsTypes';
import { LogsIcon } from '../../../assets/svg';
import LogsListComponent from '../../../components/logsList';

import TableComponent, {
  HEADER,
  HEADER_TYPE,
  FIELD_TYPE,
  HeaderNormal,
} from '../../../components/Table';
import PaginationRecords from '../../../components/PaginationRecords';
import SelectorStatusComponent from './selectorStatus';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import LoadingModal from '../../../dialogs/loadingModal';
import { capitalizeWords } from '../../../utils/utils';

interface ListProps {
  t: any;
}

const nextVotingElections = 2021;

const List: React.FunctionComponent<ListProps> = ({ t }: any) => {
  const loading = useSelector(
    (globalState: RootStore) => globalState.voters.loading,
  );
  const voters = useSelector(
    (globalState: RootStore) => globalState.voters.voters,
  );
  const totalVoters = useSelector(
    (globalState: RootStore) => globalState.voters.totalVoters,
  );
  const page: any = useSelector(
    (globalState: RootStore) => globalState.voters.page,
  );
  const filter: any = useSelector(
    (globalState: RootStore) => globalState.voters.filters,
  );
  const totalPage = useSelector(
    (globalState: RootStore) => globalState.voters.totalPage,
  );
  const records: any = useSelector(
    (globalState: RootStore) => globalState.voters.records,
  );
  const search = useSelector(
    (globalState: RootStore) => globalState.voters.search,
  );
  const sort: any = useSelector(
    (globalState: RootStore) => globalState.voters.sort,
  );

  const citySelected = useSelector((globalState: RootStore) => {
    return globalState.core.globalMunicipalitySelected;
  });

  const [active, setActive] = React.useState(false);

  const [fieldOrder, setFieldOrder] = useState(sort[1] ? sort[1] : 'asc');
  const [fieldName, setFieldName] = useState(sort[0] ? sort[0] : 'firstName');

  const [tableData, setTableData] = useState(Array);
  const [municipalityId, setMunicipalityId] = useState(String);
  const [filters, setFilters] = useState(
    filter
      ? filter
      : {
          all: false,
          confirmed: false,
          captured: false,
          registered: true,
          problems: true,
          expiredAll: true,
          expiredYes: false,
          expiredNo: false,
          expiredEmpty: false,
        },
  );

  const redirect = useHistory();
  const dispatch = useDispatch();

  const headerVotersBox: HeaderNormal = {
    title: `${totalVoters} ${
      totalVoters > 1
        ? t('territorial.voters.lbl.confirmedVoters')
        : t('territorial.voters.lbl.voter')
    }`,
  };

  const searchByValue = (reset = false) => {
    const params = buildParam(
      fieldOrder,
      fieldName,
      !reset ? page : 1,
      records ? records : 10,
      search,
    );
    if (search.length >= 3) {
      dispatch(postVotersListByAddressElectionsSearch(params));
      dispatch(postGetVotersTotal(params));
    }
    if (search.length === 0) {
      orderByName();
    }
  };

  const orderByName = (reset = false) => {
    const params = buildParam(
      fieldOrder,
      fieldName,
      !reset ? page : 1,
      records ? records : 10,
      search,
    );
    dispatch(postVotersListByAddressElectionsSearch(params));
    dispatch(postGetVotersTotal(params));
  };

  const buildParam = (
    order: string,
    field: string,
    pageNum: number,
    recordsNum: number,
    search: string,
  ) => {
    let requestObject;
    requestObject = {
      cityLicenseId: citySelected?._id || '',
      page: pageNum,
      records: recordsNum,
      field: field,
      order: order,
      search: search.length >= 3 ? search : '',
      filters: filters,
      nextElectionsYear: nextVotingElections,
    };
    return requestObject;
  };

  const goToView = (_id: string) => {
    dispatch(
      getVoterById(
        _id,
        () => {
          redirect.push(`/voters/${_id}`);
        },
        (error: any) => {},
      ),
    );
  };

  const transformData = () => {
    let newVoters: any = [];

    voters?.forEach((voter) => {
      voter.nextVotingElections = nextVotingElections;
      let newVoter = {
        _id: voter._id,
        name: capitalizeWords(`${voter.firstName} ${voter.lastName}`),
        status: voter,
        ineStatus: voter,
        visits: 0,
        visitsHistory: [],
        globalStatus: {
          registered: voter.registered,
          captured: voter.captured,
          audited: voter.audited,
        },
        duplicates: voter.duplicates,
      };
      newVoters.push(newVoter);
    });
    setTableData(newVoters);
  };

  useEffect(() => {
    if (municipalityId && filters) {
      orderByName(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    if (citySelected && citySelected._id !== municipalityId) {
      orderByName();
      setMunicipalityId(citySelected._id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [citySelected]);

  useEffect(() => {
    if (municipalityId) {
      orderByName();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldOrder]);

  useEffect(() => {
    if (municipalityId) {
      orderByName();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldName]);

  useEffect(() => {
    if (voters) {
      transformData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [voters]);

  useEffect(() => {
    if (municipalityId && (search.length >= 3 || search.length <= 0)) {
      searchByValue(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    if (municipalityId) {
      orderByName();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [municipalityId]);

  return (
    <Fragment>
      <div className="voters-territorial-list">
        {/*<SearchFormComponent
          search={search}
          funOnInit={(data: any) => {
            setsearchValue(data);
          }}
        >
        </SearchFormComponent>*/}
        <div className="voters-filters-container">
          <div className="count-container">
            <h1 className="count">
              {t('territorial.voters.lbl.voters') + ' [' + totalVoters + ']'}
            </h1>
          </div>
          <div className="filters-container">
            <div className="status">
              <SelectorStatusComponent
                title={t('territorial.voters.lbl.status')}
                horizontal={true}
                filters={filters}
                fun={(newfilters: any) => {
                  setFilters(newfilters);
                }}
                t={t}
              ></SelectorStatusComponent>
            </div>
            <div className="ine-status">
              <p className="label">{t('territorial.voters.lbl.INE')}</p>
              <ButtonGroup
                aria-label="ine-status-to-label"
                className="ine-status-group"
              >
                <Button
                  variant="primary"
                  className={filters.expiredNo ? 'active' : ''}
                  onClick={() => {
                    let newfilters = { ...filters };
                    const isLast =
                      newfilters.expiredYes === false &&
                      !newfilters.expiredNo === false &&
                      newfilters.expiredEmpty === false
                        ? true
                        : false;
                    newfilters = {
                      ...newfilters,
                      ...{
                        expiredNo: !newfilters.expiredNo,
                        expiredAll: isLast,
                      },
                    };
                    setFilters(newfilters);
                  }}
                >
                  {t('territorial.voters.lbl.valid')}
                </Button>
                <Button
                  variant="primary"
                  className={filters.expiredYes ? 'active' : ''}
                  onClick={() => {
                    let newfilters = { ...filters };
                    const isLast =
                      !newfilters.expiredYes === false &&
                      newfilters.expiredNo === false &&
                      newfilters.expiredEmpty === false
                        ? true
                        : false;
                    newfilters = {
                      ...newfilters,
                      ...{
                        expiredYes: !newfilters.expiredYes,
                        expiredAll: isLast,
                      },
                    };
                    setFilters(newfilters);
                  }}
                >
                  {t('territorial.voters.lbl.expired')}
                </Button>
                <Button
                  variant="primary"
                  className={filters.expiredEmpty ? 'active' : ''}
                  onClick={() => {
                    let newfilters = { ...filters };
                    const isLast =
                      newfilters.expiredYes === false &&
                      newfilters.expiredNo === false &&
                      !newfilters.expiredEmpty === false
                        ? true
                        : false;
                    newfilters = {
                      ...newfilters,
                      ...{
                        expiredEmpty: !newfilters.expiredEmpty,
                        expiredAll: isLast,
                      },
                    };
                    setFilters(newfilters);
                  }}
                >
                  {t('territorial.voters.lbl.empty')}
                </Button>
              </ButtonGroup>
            </div>
            {/*<div
              className="history-box-trigger"
              onClick={() => {
                dispatch(getLogs(t('language'), 1, 30, null, MODULE.VOTERS));
                setActive(true);
              }}
            >
              <LogsIcon></LogsIcon>
            </div>*/}
          </div>
        </div>
        <div className="voters-container">
          <TableComponent
            onTrAction={true}
            onTrFun={(id: string) => {
              goToView(id);
            }}
            showActions={false}
            selectedRows={[]}
            selectRows={() => {}}
            header={headerVotersBox}
            headerType={HEADER_TYPE.HIDDEN}
            headers={[
              {
                type: HEADER.FILTER,
                name: t('territorial.voters.lbl.name'),
                fun: () => {
                  let newOrder = fieldOrder === 'asc' ? 'desc' : 'asc';
                  let newField = 'firstName';
                  setFieldOrder(newOrder);
                  setFieldName(newField);
                },
              },
              {
                type: HEADER.TEXT,
                name: t('territorial.voters.lbl.status'),
              },
              {
                type: HEADER.TEXT,
                name: t('territorial.voters.lbl.ineStatus'),
              },
              {
                type: HEADER.TEXT,
                name: t('territorial.voters.lbl.visits'),
              },
              {
                type: HEADER.TEXT,
                name: t('territorial.voters.lbl.description'),
              },
            ]}
            fieldTypes={[
              { type: FIELD_TYPE.TEXT_DUPLICATES_VOTERS },
              { type: FIELD_TYPE.VOTER_STATUS_TEXT },
              { type: FIELD_TYPE.VOTER_INE_STATUS_TEXT },
              { type: FIELD_TYPE.TEXT },
              { type: FIELD_TYPE.VOTER_VISITS_HISTORY },
            ]}
            toEdit={() => {}}
            toDelete={() => {}}
            search={search}
            regExp={undefined}
            data={tableData}
            nameOfData={[
              'name',
              'status',
              'ineStatus',
              'visits',
              'visitsHistory',
            ]}
            classBody={[
              'text-align-left selector-cell-none full-name-container',
              'text-align-center global-status-container',
              'text-align-center ine-status-container',
              'text-align-center visits-container',
              'text-align-left visits-history-container',
            ]}
          ></TableComponent>
          <PaginationRecords
            totalPages={totalPage}
            value={page}
            records={records}
            onChange={(pagina: string, record: string) => {
              let rec = record === '' ? '10' : record;
              let pag;
              if (records !== record) {
                pag = '1';
              } else {
                pag = pagina === '' ? '1' : pagina;
              }
              const params = buildParam(
                fieldOrder,
                fieldName,
                +pag,
                +rec,
                search,
              );

              dispatch(postVotersListByAddressElectionsSearch(params));
              dispatch(postGetVotersTotal(params));
            }}
          ></PaginationRecords>
        </div>
      </div>
      <LogsListComponent
        openLogs={() => {
          dispatch({ type: LOGS_OPEN, open: false });
          setActive(false);
        }}
        active={active}
      ></LogsListComponent>

      {loading && (
        <LoadingModal show={loading} onHide={() => {}}></LoadingModal>
      )}
    </Fragment>
  );
};

export default List;
