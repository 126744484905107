import React, { FunctionComponent, useEffect, useState } from 'react';
import '../style.scss';
import * as netWorking from '../../../redux/netWorking';
import { UpData } from '../../../redux/updata/UpDataTypes';
import { useTranslation } from 'react-i18next';
import NewTableComponent, {
  FIELD_TYPE,
  HEADER,
  HEADER_TYPE,
} from '../../../components/NewTable';
import ModalDeleteUpData from './modalDelete';
import { RootStore } from '../../../redux/Store';
import { useSelector } from 'react-redux';
import {
  AddressDuplicateIcon,
  CellphoneDuplicateIcon,
  PeopleDuplicateIcon,
  IneDuplicateIcon,
} from '../../../assets/svg/index';
import LoadingModal from '../../../dialogs/loadingModal';
import PaginationRecordsComponent from '../../../components/PaginationRecords';

interface ListTemporalVotersProps {
  upData: UpData;
  setFinalizeAvailable: any;
}
export interface DuplicateData {
  uniqueIds: string[];
  _id: any;
}

export interface Duplicate {
  ine: DuplicateData[];
  address: DuplicateData[];
  cellphone: DuplicateData[];
  names: DuplicateData[];
}
// tslint:disable-next-line: prefer-const
let defaultArray: any = [];
// tslint:disable-next-line: prefer-const
let defaultDuplicate: Duplicate;

export const ListTemporalVoters: FunctionComponent<ListTemporalVotersProps> = ({
  upData,
  setFinalizeAvailable,
}: any) => {
  const { t } = useTranslation();
  const [listVoters, setListVoters] = useState(defaultArray);
  const [tableData, setTableData] = useState(defaultArray);
  const [duplicates, setDuplicates] = useState(defaultDuplicate);
  const [loadData, setLoadData] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [toDelete, setToDelete] = useState(defaultArray);
  const [listTitle, setListTitle] = useState('');
  const [loading, setLoading] = useState({
    loadingTotal: true,
    loadingData: true,
  });

  console.log('upData', upData);
  console.log('listVoters', listVoters);

  const search = useSelector(
    (globalState: RootStore) => globalState.upData.search,
  );

  const [filters, setFilters] = useState({
    search,
    page: 1,
    field: 'firstName',
    order: 'asc',
    totalPages: 0,
    totalVoters: 0,
    totalGeneral: 0,
    type: 'NONE',
    list: [],
    records: '10',
  });

  useEffect(() => {
    setLoading({
      loadingTotal: true,
      loadingData: true,
    });
    getInitialData(
      1,
      +filters.records,
      'asc',
      search.length > 2 ? search : 'empty',
      filters.type,
      filters.list,
    );
    getInitialDuplicates(
      filters.page,
      'asc',
      search.length > 2 ? search : 'empty',
      filters.type,
      filters.list,
    );
    setFilters((prevState: any) => ({
      ...prevState,
      page: 1,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    if (!loadData) {
      setLoading({
        loadingTotal: true,
        loadingData: true,
      });
      getInitialDuplicates(
        filters.page,
        'asc',
        search.length > 2 ? search : 'empty',
        filters.type,
        filters.list,
      );
      getInitialData(
        filters.page,
        +filters.records,
        'asc',
        search.length > 2 ? search : 'empty',
        filters.type,
        filters.list,
      );
      setLoadData(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadData]);

  useEffect(() => {}, [listVoters]);

  useEffect(() => {
    if (duplicates?.ine?.length === 0) {
      setFinalizeAvailable(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duplicates]);

  useEffect(() => {
    if (filters.type === 'NONE') {
      setListTitle(t('territorial.voters.lbl.voters'));
    } else if (filters.type === 'INE') {
      setListTitle(
        t('territorial.voters.lbl.voters') +
          ' - ' +
          t('settings.votersList.card.lbl.document') +
          ' ' +
          t('settings.votersList.card.lbl.duplicated'),
      );
    } else if (filters.type === 'Nombre') {
      setListTitle(
        t('territorial.voters.lbl.voters') +
          ' - ' +
          t('settings.votersList.card.lbl.name') +
          ' ' +
          t('settings.votersList.card.lbl.duplicated'),
      );
    } else if (filters.type === 'Celular') {
      setListTitle(
        t('territorial.voters.lbl.voters') +
          ' - ' +
          t('settings.votersList.card.lbl.cellphone') +
          ' ' +
          t('settings.votersList.card.lbl.duplicated'),
      );
    } else if (filters.type === 'Residencia') {
      setListTitle(
        t('territorial.voters.lbl.voters') +
          ' - ' +
          t('settings.votersList.card.lbl.address') +
          ' ' +
          t('settings.votersList.card.lbl.duplicated'),
      );
    }
    setFilters((prevState: any) => ({
      ...prevState,
      page: 1,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.type]);

  const getInitialDuplicates = (
    page: number,
    order: string,
    search: string,
    type: string,
    list: any,
  ) => {
    netWorking.post(
      netWorking.GetTemporalVotersDuplicates +
        `${upData._id}/${search}/${page}/${filters.field}/${order}/${filters.type}`,
      {
        upDataId: upData._id,
        type,
        duplicateIds: list,
      },
      (resp: any) => {
        setDuplicates(resp.data.response.duplicates);
        setLoading((data) => {
          return {
            ...data,
            loadingTotal: false,
          };
        });
      },
      (err: any) => {},
    );
  };

  const getInitialData = (
    page: number,
    rec: number,
    order: string,
    search: string,
    type: string,
    list: any,
  ) => {
    netWorking.post(
      netWorking.GetTemporalVoters +
        `${search}/${page}/${filters.field}/${order}/${type}/${rec}`,
      { upDataId: upData._id, type, duplicateIds: list },
      (resp: any) => {
        setToDelete([]);
        setListVoters(resp.data.response.votersList.voters);
        setFilters((prevState: any) => ({
          ...prevState,
          totalPages: resp.data.response.votersList.pages,
          totalVoters: resp.data.response.votersList.totalVoters,
          totalGeneral: resp.data.response.votersList.totalVotersGeneral,
        }));
        setLoading((data) => {
          return {
            ...data,
            loadingData: false,
          };
        });
        setTableData(transformData(resp.data.response.votersList.voters));
      },
      (err: any) => {},
    );
  };

  // const submitFun = () => {
  //   // let formData = new FormData();
  //   // formData.append('file', file);
  //   // netWorking.post(
  //   //   netWorking.UpData,
  //   //   formData,
  //   //   (res: any) => {
  //   //     console.log(res);
  //   //     let data = toSave;
  //   //     data.filePath = res.data.response.route;
  //   //     netWorking.post(
  //   //       netWorking.CreateUpData,
  //   //       data,
  //   //       (res: any) => {
  //   //         successFun();
  //   //         setModalShow(false);
  //   //       },
  //   //       (err: any) => {},
  //   //     );
  //   //   },
  //   //   (err: any) => {},
  //   // );
  // };

  const filterCardAll = () => {
    return (
      <div
        className={
          filters.type === 'NONE' ? 'filter-card-all active' : 'filter-card-all'
        }
        onClick={() => {
          if (filters.type !== 'NONE') {
            setFilters((prevState: any) => ({
              ...prevState,
              type: 'NONE',
              list: [],
            }));
            getInitialData(
              1,
              +filters.records,
              filters.order,
              search.length > 2 ? search : 'empty',
              'NONE',
              [],
            );
          }
        }}
      >
        <div className="card-image">
          <PeopleDuplicateIcon></PeopleDuplicateIcon>
        </div>
        <div className="card-content">
          <p className="amount-text">{filters.totalGeneral}</p>
          <div className="title-filter">
            <p>
              {t('settings.votersList.card.lbl.show')}{' '}
              {t('settings.votersList.card.lbl.all')}
            </p>
          </div>
        </div>
      </div>
    );
  };

  const filterCard = (
    type: any,
    title: any,
    subtype: any,
    data: DuplicateData[],
  ) => {
    const countAuxiliar = getCounter(data);
    let classString = 'filter-card';
    if (filters.type === type) classString += ' active';
    return (
      <div
        className={classString}
        onClick={() => {
          if (filters.type !== type) {
            setFilters((prevState: any) => ({
              ...prevState,
              type,
              list: getIds(data),
            }));
            getInitialData(
              1,
              +filters.records,
              filters.order,
              search.length > 2 ? search : 'empty',
              type,
              getIds(data),
            );
          }
        }}
      >
        <div className="card-image">
          {type === 'Residencia' && (
            <AddressDuplicateIcon></AddressDuplicateIcon>
          )}
          {type === 'Celular' && (
            <CellphoneDuplicateIcon></CellphoneDuplicateIcon>
          )}
          {type === 'Nombre' && <></>}
          {type === 'INE' && <IneDuplicateIcon></IneDuplicateIcon>}
        </div>
        <div className="card-content">
          <p className="amount-text">{countAuxiliar}</p>
          <div className="title-filter">
            <p>
              {title} {subtype}
            </p>
          </div>
        </div>
        {countAuxiliar > 0 && <div className="card-duplicates">{'!'}</div>}
      </div>
    );
  };

  const getIds = (data: DuplicateData[]) => {
    const counter: string[] = [];
    data.forEach((e) => {
      counter.push(...e.uniqueIds);
    });
    return counter.filter((item, index) => counter.indexOf(item) === index);
  };

  const getDuplicatesIds = (data: Duplicate) => {
    const counter: string[] = [];
    if (data && data.address)
      data.address.forEach((e) => {
        counter.push(...e.uniqueIds);
      });
    if (data && data.names)
      data.names.forEach((e) => {
        counter.push(...e.uniqueIds);
      });
    if (data && data.ine)
      data.ine.forEach((e) => {
        counter.push(...e.uniqueIds);
      });
    if (data && data.cellphone)
      data.cellphone.forEach((e) => {
        counter.push(...e.uniqueIds);
      });
    return counter.filter((item, index) => counter.indexOf(item) === index);
  };

  const getCounter = (data: DuplicateData[]) => {
    let counter = 0;
    if (data)
      data.forEach((e) => {
        counter += e.uniqueIds.length;
      });
    return counter;
  };

  const transformData = (data: any) => {
    console.log('data', data);
    const listData: any = [];
    data.forEach((voter: any) => {
      let text = '';
      if (voter.address) {
        if (voter.address.street && voter.address.street.trim() !== '')
          text = text + voter.address.street;
        if (
          voter.address.interiorNumber &&
          voter.address.interiorNumber.trim() !== ''
        )
          text = text + ', ' + voter.address.interiorNumber;
        if (
          voter.address.exteriorNumber &&
          voter.address.exteriorNumber.trim() !== ''
        )
          text = text + ', ' + voter.address.exteriorNumber;
        if (voter.address.suburb && voter.address.suburb.trim() !== '')
          text = text + ', ' + voter.address.suburb;
      }
      listData.push({
        _id: voter._id,
        ineVoterKey: voter.ineVoterKey,
        sectionBox: voter.sectionVote,
        name: voter.firstName + ' ' + voter.lastName,
        email: voter.email,
        cellphone: voter.cellphone,
        sex: voter.sex,
        stateBox: voter.stateVote,
        municipalityBox: voter.municipalityVote,
        address: text,
        municipality: voter.address.municipality,
      });
    });
    return listData;
  };

  // const getCounterAll = (data: Duplicate) => {
  //   let counter = 0;
  //   if (data && data.address)
  //     data.address.forEach((e) => {
  //       counter += e.uniqueIds.length;
  //     });
  //   if (data && data.names)
  //     data.names.forEach((e) => {
  //       counter += e.uniqueIds.length;
  //     });
  //   if (data && data.ine)
  //     data.ine.forEach((e) => {
  //       counter += e.uniqueIds.length;
  //     });
  //   if (data && data.cellphone)
  //     data.cellphone.forEach((e) => {
  //       counter += e.uniqueIds.length;
  //     });
  //   return counter;
  // };

  return (
    <>
      <div className="temps-voters-container">
        <div className="row-filters">
          {filterCardAll()}
          {filterCard(
            'INE',
            t('settings.votersList.card.lbl.document'),
            t('settings.votersList.card.lbl.duplicated'),
            duplicates?.ine,
          )}
          {filterCard(
            'Nombre',
            t('settings.votersList.card.lbl.name'),
            t('settings.votersList.card.lbl.duplicated'),
            duplicates?.names,
          )}
          {filterCard(
            'Celular',
            t('settings.votersList.card.lbl.cellphone'),
            t('settings.votersList.card.lbl.duplicated'),
            duplicates?.cellphone,
          )}
          {filterCard(
            'Residencia',
            t('settings.votersList.card.lbl.address'),
            t('settings.votersList.card.lbl.duplicated'),
            duplicates?.address,
          )}
        </div>
        <div className="voters-area">
          <NewTableComponent
            showActions={true}
            // actionsStartRow={true}
            showOnlyDelete={true}
            selectedRows={toDelete}
            selectRows={setToDelete}
            header={{
              title: listTitle,
            }}
            duplicates={getDuplicatesIds(duplicates)}
            headerType={HEADER_TYPE.NORMAL}
            headers={[
              {
                type: HEADER.TEXT,
                name: t('territorial.voters.lbl.ineVoterKey'),
              },
              {
                type: HEADER.TEXT,
                name: t('territorial.voters.lbl.votingSection'),
              },
              {
                type: HEADER.TEXT,
                name: t('territorial.voters.lbl.name'),
                fun: () => {
                  const newOrder = filters.order === 'asc' ? 'desc' : 'asc';
                  setLoading({
                    loadingTotal: false,
                    loadingData: true,
                  });
                  getInitialData(
                    filters.page,
                    +filters.records,
                    newOrder,
                    search.length > 2 ? search : 'empty',
                    filters.type,
                    filters.list,
                  );
                  setFilters((prevState: any) => ({
                    ...prevState,
                    order: newOrder,
                  }));
                },
              },
              {
                type: HEADER.TEXT,
                name: t('territorial.voters.lbl.phone'),
              },
              {
                type: HEADER.TEXT,
                name: t('territorial.voters.lbl.sex'),
              },
              {
                type: HEADER.TEXT,
                name: t('territorial.voters.lbl.votingState'),
              },
              {
                type: HEADER.TEXT,
                name: t('territorial.voters.lbl.votingCity'),
              },
              {
                type: HEADER.TEXT,
                name: t('territorial.voters.lbl.address'),
              },
              {
                type: HEADER.TEXT,
                name: t('territorial.voters.lbl.city'),
              },
            ]}
            columns={[
              {
                type: FIELD_TYPE.TEXT,
                dataIndex: 'ineVoterKey',
                classBody: 'text-align-left selector-cell',
              },
              {
                type: FIELD_TYPE.TEXT,
                dataIndex: 'sectionBox',
                classBody: 'text-align-center section-box-cell',
              },
              {
                type: FIELD_TYPE.TEXT,
                dataIndex: 'name',
                classBody: 'text-align-left name-cell',
              },
              {
                type: FIELD_TYPE.TEXT,
                dataIndex: 'cellphone',
                classBody: 'text-align-left cellphone-cell',
              },
              {
                type: FIELD_TYPE.SEX,
                dataIndex: 'sex',
                classBody: 'text-align-center  sex-cell',
              },
              {
                type: FIELD_TYPE.TEXT,
                dataIndex: 'stateBox',
                classBody: 'text-align-left state-box-cell',
              },
              {
                type: FIELD_TYPE.TEXT,
                dataIndex: 'municipalityBox',
                classBody: 'text-align-left municipality-box-cell',
              },
              {
                type: FIELD_TYPE.TEXT,
                dataIndex: 'address',
                classBody: 'text-align-left address-cell',
              },
              {
                type: FIELD_TYPE.TEXT,
                dataIndex: 'municipality',
                classBody: 'text-align-left municipality-cell',
              },
            ]}
            toEdit={() => {}}
            toDelete={(data: any) => {
              setToDelete(data);
              setShowDelete(true);
            }}
            search={() => {}}
            regExp={undefined}
            data={tableData}
          ></NewTableComponent>
          <PaginationRecordsComponent
            totalPages={filters.totalPages}
            value={filters.page}
            records={filters.records}
            onChange={(pagina: string, record: string) => {
              const rec = record === '' ? '10' : record;
              let pag: string = '';
              if (filters.records !== record) {
                pag = '1';
              } else {
                pag = pagina === '' ? '1' : pagina;
              }
              setLoading({
                loadingTotal: false,
                loadingData: true,
              });
              getInitialData(
                +pag,
                +rec,
                filters.order,
                search.length > 2 ? search : 'empty',
                filters.type,
                filters.list,
              );
              setFilters((prevState: any) => ({
                ...prevState,
                page: +pag,
                records: rec,
              }));
              // const url = buildParamUrl('firstName', nameOrder, Number(pag));
              // dispatch(getVotersListByAddressElections(url));
            }}
          ></PaginationRecordsComponent>
        </div>
      </div>
      {showDelete && (
        <ModalDeleteUpData
          modalShow={showDelete}
          successFun={() => {
            setLoading({
              loadingTotal: true,
              loadingData: true,
            });
            getInitialData(
              filters.page,
              +filters.records,
              filters.order,
              search.length > 2 ? search : 'empty',
              filters.type,
              filters.list,
            );
            getInitialDuplicates(
              filters.page,
              'asc',
              search.length > 2 ? search : 'empty',
              filters.type,
              filters.list,
            );
            setToDelete([]);
          }}
          upDataId={upData._id}
          toDelete={toDelete}
          tempVoters={listVoters}
          setModalShow={setShowDelete}
        ></ModalDeleteUpData>
      )}
      {(loading.loadingData || loading.loadingTotal) && (
        <LoadingModal
          show={loading.loadingData || loading.loadingTotal}
          onHide={() => {}}
        ></LoadingModal>
      )}
    </>
  );
};

export default ListTemporalVoters;
