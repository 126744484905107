import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  deleteRoute,
  postCreateArea,
  postGetAreasByUsers,
} from '../../../redux/routes/RoutesActions';
import { Routes } from '../../../redux/routes/RoutesTypes';
import { RootStore } from '../../../redux/Store';
import HeatmapLayer from 'react-leaflet-heatmap-layer';
import { Map, MapProps } from 'react-leaflet';
import * as turf from '@turf/turf';
import L from 'leaflet';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import '@geoman-io/leaflet-geoman-free';
import LayerControlComponent from '../../../components/sub-component/layerControl';
import ModalDeleteArea from './modalDeleteArea';
import { DeleteIcon, HelpIcon } from '../../../assets/svg';
import { ROLE } from '../../../utils/utils';
import { changeSelectedCoordinator } from '../../../redux/areas/areasActions';

// tslint:disable-next-line: prefer-const
let layerArray: L.Layer[] = [];
// tslint:disable-next-line: prefer-const
let emptyArray2: string[] = [];

// tslint:disable-next-line: prefer-const
let newLayersEmpty: Routes;
const layersGeoJsonL: any = [];
const colorActive = `rgba(78, 205, 196, .5)`;
const colorBaseActive = `rgba(78, 205, 196, 1)`;
const colorInActive = `rgba(253, 227, 167, .5)`;
const colorBaseInActive = `rgba(253, 227, 167, 1)`;
interface Props extends MapProps {
  onSelectionPolygonAdded: (data: object) => void;
  onSelectionPolygonMoved: (data: object) => void;
  onSelectionPolygonRemoved: () => void;
  setSelected: any;
  selected: any;
  layersGeoJson: any;
  setLayersGeoJson: any;
  setGeneralLayers: any;
  generalLayers: any;
  loadData: any;
  center: any;
  zoom: any;
  setBounds: any;
  bounds: any;
  setListBounds: any;
  listBounds: any;
  getBounds: any;
  leafletMapRef: any;
  updateArray: any;
}

const MapWithGeoman: React.FunctionComponent<Props> = (props) => {
  const {
    children,
    onSelectionPolygonAdded: onPolygonAdded,
    onSelectionPolygonMoved: onPolygonMoved,
    onSelectionPolygonRemoved: onPolygonRemoved,
    selected,
    setSelected,
    layersGeoJson,
    setLayersGeoJson,
    setGeneralLayers,
    generalLayers,
    loadData,
    center,
    zoom,
    setBounds,
    bounds,
    setListBounds,
    listBounds,
    getBounds,
    updateArray,
    leafletMapRef,
  } = props;

  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const byId = localStorage.id;
  const [, setLayers] = useState(layerArray);
  const [newLayer, setNewLayer] = useState(newLayersEmpty);
  const [, setTempLayers] = useState(layersGeoJsonL);
  const [showDelete, setShowDelete] = useState(false);
  const [drawFinish, setDrawFinish] = useState(String);
  const [selectedId, setSelectedId] = useState(String);

  const teams = useSelector(
    (globalState: RootStore) => globalState.areas.teams,
  );
  const selectedCoordinator = useSelector(
    (globalState: RootStore) => globalState.areas.selectedCoordinator,
  );
  const generalAreas: Routes[] = useSelector(
    (globalState: RootStore) => globalState.routes.generalAreas,
  );
  const heatMap = useSelector(
    (globalState: RootStore) => globalState.areas.heatMap,
  );
  const citySelected = useSelector(
    (globalState: RootStore) => globalState.core.globalMunicipalitySelected,
  );

  useEffect(() => {
    const mapElement = leafletMapRef.current?.leafletElement;
    if (mapElement) {
      if (generalLayers.length === 0) {
        generalAreas.forEach((e) => {
          L.geoJSON(
            {
              type: 'Feature',
              geometry: {
                type: 'Polygon',
                coordinates: e.coordinates,
              },
            } as any,
            {
              style: {
                weight: 0,
                opacity: 1,
                color: 'rgba(0,135,239,0.15)',
                dashArray: '',
                fillOpacity: 1,
                fillColor: 'rgba(0,135,239,0.15)',
              },
            },
          ).addTo(mapElement);
        });
        setGeneralLayers(generalAreas);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generalAreas]);

  useEffect(() => {
    if (newLayer) {
      let arrayGeneral: any[] = [];
      // if (localStorage.role === ROLE.ADMIN_BOSS) {
      //   arrayGeneral.push({
      //     type: 'Feature',
      //     ...citySelected?.geoLimits,
      //   });
      // } else
      //   arrayGeneral = generalLayers.map((e: Routes) => {
      //     return {
      //       type: 'Feature',
      //       geometry: {
      //         type: 'Polygon',
      //         coordinates: e.coordinates,
      //       },
      //     };
      //   });
      let value = {
        ...newLayer,
        originalCoordinates: newLayer.geometry as any,
      };
      setTempLayers(layersGeoJson);

      const array: any[] = [];
      try {
        for (let i = 0; i < layersGeoJson.length; i++) {
          for (let j = 0; j < layersGeoJson.length; j++) {
            if (layersGeoJson[i] && layersGeoJson[j]) {
              var union = null;
              try {
                union = turf.union(layersGeoJson[i], layersGeoJson[j]);
              } catch (error) {}
              if (union && union.geometry?.type !== 'MultiPolygon')
                array.push(union);
            }
          }
        }
        if (array.length > 0) {
          var union3 = null;
          try {
            union3 = turf.union(...array);
          } catch (error) {}

          if (union3) {
            let intersect;

            try {
              intersect = turf.difference(
                {
                  type: value.type,
                  geometry: value.geometry,
                } as any,
                union3,
              );
            } catch (error) {}
            if (
              intersect &&
              intersect.geometry &&
              intersect.geometry.type === 'Polygon'
            )
              value = {
                ...value,
                geometry: {
                  ...value.geometry,
                  coordinates:
                    intersect && intersect.geometry
                      ? intersect.geometry.coordinates
                      : value.geometry.coordinates,
                },
              };
          }
        }
        // if (
        //   arrayGeneral.length === 0 &&
        //   localStorage.role !== ROLE.ADMIN_BOSS
        // ) {
        //   value = newLayersEmpty;
        // }
        if (arrayGeneral.length > 0) {
          var union2 = null;
          try {
            union2 = turf.union(...arrayGeneral);
          } catch (error) {}
          if (union2) {
            let intersect2 = null;
            if (union2.geometry?.type === 'MultiPolygon') {
              union2.geometry?.coordinates.forEach((e: any) => {
                let valueInter = null;
                try {
                  valueInter = turf.intersect(
                    {
                      type: 'Feature',
                      geometry: {
                        type: 'Polygon',
                        coordinates: e,
                      },
                    } as any,
                    value,
                  );
                } catch (error) {}

                if (valueInter) {
                  if (
                    valueInter &&
                    valueInter.geometry &&
                    valueInter.geometry.type === 'Polygon'
                  )
                    value = {
                      ...value,
                      geometry: {
                        ...value.geometry,
                        coordinates:
                          valueInter && valueInter.geometry
                            ? valueInter.geometry.coordinates
                            : value.geometry.coordinates,
                      },
                    };
                }
              });
            } else {
              try {
                intersect2 = turf.intersect(union2 as any, value);
              } catch (error) {}
            }
            if (
              intersect2 &&
              intersect2.geometry &&
              intersect2.geometry.type === 'Polygon'
            )
              value = {
                ...value,
                geometry: {
                  ...value.geometry,
                  coordinates:
                    intersect2 && intersect2.geometry
                      ? intersect2.geometry.coordinates
                      : value.geometry.coordinates,
                },
              };
          }
          if (union2 && union2.geometry?.type === 'MultiPolygon') {
            let result = false;
            union2.geometry?.coordinates.forEach((e: any) => {
              let valueInter = null;
              try {
                valueInter = turf.intersect(
                  {
                    type: 'Feature',
                    geometry: {
                      type: 'Polygon',
                      coordinates: e,
                    },
                  } as any,
                  value,
                );
              } catch (error) {}
              if (valueInter && !result) {
                result = true;
              } else {
                if (!result) result = false;
              }
            });
            if (!result) value = newLayersEmpty;
          } else {
            try {
              if (
                turf.intersect(union2, {
                  type: value.type,
                  geometry: value.geometry,
                } as any) === null
              ) {
                value = newLayersEmpty;
              }
            } catch (error) {}
          }
        }
      } catch (error) {
        console.log(error);
        value = { ...newLayer, originalCoordinates: newLayer.geometry as any };
      }
      if (value) {
        dispatch(
          postCreateArea(
            {
              ...value,
              members: [selectedId],
              originalCoordinates: value.originalCoordinates,
              createdBy: byId,
              indexValue: layersGeoJson.length || 0,
            },
            (data: any) => {
              console.log(data);
              setSelected((prevState: any) => {
                prevState?.pm.disable();
                return null;
              });

              if (teams.length > 0)
                dispatch(
                  postGetAreasByUsers(
                    teams.reduce(
                      // eslint-disable-next-line
                      (a: any, o: any) => (o.bossId && a.push(o.bossId), a),
                      [],
                    ),
                  ),
                );

              setLayersGeoJson((prevState: any) => {
                let arrayA = [];
                value._id = data._id;
                if (value) arrayA = [...prevState, value];
                else arrayA = [...prevState];
                return arrayA;
              });
            },
            (error: any) => {
              setLayersGeoJson((prevState: any) => {
                let arrayA = [];
                if (value) arrayA = [...prevState, value];
                else arrayA = [...prevState];
                return arrayA;
              });
              console.log(error);
            },
          ),
        );
      } else {
        setLayersGeoJson((prevState: any) => {
          let arrayA = [];
          if (value) arrayA = [...prevState, value];
          else arrayA = [...prevState];
          return arrayA;
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newLayer]);

  /*const undo = () => {
    setLayersGeoJson(tempLayers);
  };*/

  const addNewLayer = (polygon: any) => {
    // const r1 = (Math.random() * 255).toFixed(0);
    // const r2 = (Math.random() * 255).toFixed(0);
    // const r3 = (Math.random() * 255).toFixed(0);
    // const color = `rgba(${r1},${r2},${r3},0.5)`;
    // const colorBase = `rgba(${r1},${r2},${r3},1)`;
    setNewLayer({
      ...polygon,
      _id: Math.ceil(Math.random() * 15684).toString(),
      name: '',
      color: colorActive,
      colorBase: colorBaseActive,
      limit: 1,
      limitType: 0,
      extraGoal: 0,
      enable: true,
      createdBy: byId,
      datesWorked: emptyArray2,
      members: [selectedId],
      coordinates: [],
    });
  };

  const setUser = (feature: any) => {
    console.log(feature);
    if (feature.members.length > 0) {
      const capturist = teams.find((c: any) => c.bossId === feature.members[0]);
      dispatch(
        changeSelectedCoordinator({
          _id: feature.members[0],
          name: capturist?.name,
        }),
      );
    }
  };

  const AddLayer = (polygon: any, index: number) => {
    const mapElement = leafletMapRef.current?.leafletElement;
    if (mapElement) {
      return L.geoJSON(
        { ...polygon, index: index + 1 },
        {
          style: {
            weight: 0,
            opacity: 0,
            color:
              polygon.members.indexOf(selectedId) !== -1
                ? colorBaseActive
                : colorBaseInActive,
            dashArray: '',
            fillOpacity: 1,
            fillColor:
              polygon.members.indexOf(selectedId) !== -1
                ? colorActive
                : colorInActive,
          },
          onEachFeature: (feature, layer) => {
            layer
              .addTo(mapElement)
              .bindTooltip(String(index + 1), {
                permanent: true,
                direction: 'auto',
                className: 'my-labels',
              })
              .openTooltip();
            setLayers((prevState) => [...prevState, layer]);

            layer.on('pm:edit', (e) => {
              setBounds(undefined);
              if (e.target && e.target.pm) {
                setLayersGeoJson((prevState: any) => {
                  const array1: any = [];
                  prevState.forEach((d: any) => {
                    if (d._id === e.target.feature._id) {
                      const lay = e.layer.toGeoJSON();
                      array1.push({
                        ...lay,
                        coordinates: lay.geometry.coordinates,
                        originalCoordinates: lay.geometry,
                      });
                    } else {
                      array1.push(d);
                    }
                  });
                  updateArray(array1);
                  return array1;
                });
                setSelected((prevState: any) => {
                  if (prevState?.feature._id === e.target.feature._id) {
                    return {
                      ...e.target,
                      feature: { ...e.target.feature, ...e.target.toGeoJSON() },
                    };
                  } else return prevState;
                });
              }
            });
            layer.on('pm:cut', function (e) {
              if (e.target && e.target.pm) {
                layer.off('pm:edit');
                setLayersGeoJson((prevState: any) => {
                  const array1: any = [];

                  prevState.forEach((d: any) => {
                    if (d._id === e.target.feature._id) {
                      const lay = e.layer.toGeoJSON();
                      const value = {
                        ...e.target.feature,
                        ...lay,
                      };
                      array1.push(value);
                    } else {
                      array1.push(d);
                    }
                  });
                  return array1;
                });
              }
            });
            layer.on('changeOfColorsActive', function (e) {
              e.target.setStyle({
                weight: 2,
                opacity: 1,
                color: colorBaseActive,
                dashArray: '',
                fillOpacity: 1,
                fillColor: colorActive,
              });
            });
            layer.on('changeOfColorsInactive', function (e) {
              e.target.setStyle({
                weight: 2,
                opacity: 1,
                color: colorBaseInActive,
                dashArray: '',
                fillOpacity: 1,
                fillColor: colorInActive,
              });
            });
            layer.on('click', function (e) {
              setBounds(undefined);
              setUser(e.target.feature);
              setSelected((prevState: any) => {
                if (prevState?.setStyle) {
                  // prevState?.setStyle({
                  //   weight: 2,
                  //   opacity: 0,
                  //   color: prevState.feature.colorBase,
                  //   dashArray: '',
                  //   fillOpacity: 1,
                  //   fillColor: prevState.feature.color,
                  // });
                  prevState.unbindTooltip();
                  prevState
                    .bindTooltip(String(prevState.feature.index), {
                      permanent: true,
                      direction: 'center',
                      className: 'my-labels',
                    })
                    .openTooltip();
                }
                e.target.unbindTooltip();
                e.target
                  .bindTooltip(String(e.target.feature.index), {
                    permanent: true,
                    direction: 'center',
                    className: `my-labels selected-${e.target.feature._id}`,
                  })
                  .openTooltip();
                e.target.setStyle({
                  weight: 2,
                  opacity: 1,
                  color: colorBaseActive,
                  dashArray: '',
                  fillOpacity: 1,
                  fillColor: colorActive,
                });
                prevState?.pm.disable();
                return e.target;
              });
              e.target.pm.enable();
            });
            if ((layer as any).feature._id === selected?.feature._id) {
              layer.fireEvent('click');
            }
          },
        },
      ).getBounds();
    } else return null;
  };

  const getStyles = () => {
    let styles = '';
    layersGeoJson?.forEach((e: any, index: number) => {
      if (e) {
        if (styles === '') {
          styles = `.selected-${e._id} {
            background-color: ${colorBaseActive} !important;
            color: #fff !important;
          }
          `;
        } else {
          styles =
            styles +
            `   .selected-${e._id} {
            background-color: ${colorBaseActive} !important;
            color: #fff !important;
          }
          `;
        }
      }
    });
    return styles;
  };

  useEffect(() => {
    const mapElement = leafletMapRef.current?.leafletElement;
    if (mapElement) {
      mapElement.eachLayer((layer: any) => {
        if (
          layer._url === undefined &&
          // layer._container === undefined
          layer._events !== undefined &&
          layer instanceof L.Layer
        )
          layer.remove();
      });
      if (
        localStorage.role === ROLE.ADMIN_BOSS ||
        localStorage.role === ROLE.SUPER_ADMIN
      ) {
        L.geoJSON(
          {
            type: 'Feature',
            ...citySelected?.geoLimits,
          } as any,
          {
            style: {
              weight: 0,
              opacity: 1,
              color: 'rgba(0,135,239,0.15)',
              dashArray: '',
              fillOpacity: 1,
              fillColor: 'rgba(0,135,239,0.15)',
            },
            onEachFeature: (feature, layer) => {
              layer.addTo(mapElement);
              layer.off('click');
            },
          },
        );
      } else {
        generalLayers.forEach((e: any) => {
          L.geoJSON(
            {
              type: 'Feature',
              geometry: {
                type: 'Polygon',
                coordinates: e.coordinates,
              },
            } as any,
            {
              style: {
                weight: 0,
                opacity: 1,
                color: 'rgba(0,135,239,0.15)',
                dashArray: '',
                fillOpacity: 1,
                fillColor: 'rgba(0,135,239,0.15)',
              },
              onEachFeature: (feature, layer) => {
                layer.addTo(mapElement);
                layer.off('click');
              },
            },
          );
        });
      }

      const array: any = [];
      layersGeoJson?.forEach((e: any, index: number) => {
        let bound = null;
        if (e) bound = AddLayer(e, index);
        if (bound) array.push({ _id: e._id, members: e.members, bound });
      });
      setDrawFinish('FINISH');
      setListBounds(array);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layersGeoJson]);

  useEffect(() => {
    if (leafletMapRef.current) {
      const mapElement = leafletMapRef.current.leafletElement;

      (mapElement as any).pm.setGlobalOptions({ pmIgnore: false });
      (mapElement as any).pm.setLang(i18n.language);

      (mapElement as any).pm.addControls({
        drawMarker: false,
        drawCircle: false,
        drawCircleMarker: false,
        drawPolyline: false,
        drawRectangle: false,
        drawPolygon: true,
        editMode: false,
        dragMode: false,
        cutPolygon: false,
        removalMode: false,
        zoomMode: false,
      });
      mapElement.off('pm:create');
      mapElement.on('pm:create', (e: any) => {
        if (e.target && e.target.pm) {
          setBounds(undefined);
          addNewLayer(e.layer.toGeoJSON());
        }
      });
      mapElement.off('pm:remove');
      mapElement.on('pm:remove', (e: any) => {
        // (mapElement as any).pm.addControls({
        //   drawPolygon: true,
        //   removalMode: false,
        // });
        // onPolygonRemoved();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leafletMapRef.current, i18n.language]);

  const [style, updateStyle] = useState(`
    .leaflet-pm-toolbar .leaflet-pm-icon-polygon::after {
      content: '${t('territorial.goals.lbl.draw')}';
    } .button-container.active .leaflet-pm-actions-container .action-removeLastVertex::after{
      content: '${t('territorial.goals.lbl.undo')}';
    }
  `);

  useEffect(() => {
    updateStyle(`
      .leaflet-pm-toolbar .leaflet-pm-icon-polygon::after {
        content: '${t('territorial.goals.lbl.draw')}';
      }
      .button-container.active .leaflet-pm-actions-container .action-removeLastVertex::after{
        content: '${t('territorial.goals.lbl.undo')}';
      }
    `);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  useEffect(() => {
    if (selectedCoordinator) {
      setSelectedId(selectedCoordinator._id);
    }
  }, [selectedCoordinator]);

  useEffect(() => {
    const mapElement = leafletMapRef.current?.leafletElement;
    if (mapElement) {
      mapElement.eachLayer((layer: any) => {
        if (
          layer._url === undefined &&
          // layer._container === undefined
          layer._events !== undefined &&
          layer instanceof L.Layer
        ) {
          console.log('layer', layer);
          if (
            (layer as any).feature &&
            (layer as any).feature.members &&
            (layer as any).feature.members.indexOf(selectedId) !== -1
          ) {
            layer.fireEvent('changeOfColorsActive');
          } else {
            layer.fireEvent('changeOfColorsInactive');
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedId, drawFinish]);

  /*const transformDataPoints = (pointsTestData: any) => {
    const array: any[] = [];

    pointsTestData.response.forEach((d: any) => {
      array.push([d._id.lat, d._id.lng, d.count]);
    });
    return array;
  };*/

  const gradient = {
    0.2: '#fff33b',
    0.4: '#fdc70c',
    0.6: '#f3903f	',
    0.8: '#ed683c',
    1.0: '#e93e3a',
  };

  return (
    <>
      <Map
        ref={leafletMapRef}
        center={center}
        zoom={zoom}
        bounds={bounds || undefined}
        boundsOptions={{ padding: [0, 0] }}
      >
        <style>{style}</style>
        <style>{getStyles()}</style>
        <HeatmapLayer
          // fitBoundsOnLoad
          // fitBoundsOnUpdate
          points={heatMap}
          key={'heatMap'}
          gradient={gradient}
          blur={Number(8)}
          max={0.5}
          longitudeExtractor={(m: any) => m[1]}
          latitudeExtractor={(m: any) => m[0]}
          intensityExtractor={(m: any) => parseFloat(m[2])}
        />
        <LayerControlComponent checked={'Street'}></LayerControlComponent>
        {/* {generalAreas.map((e) => {
          return <GeoJSON key={e._id} data={e.coordinates} />;
        })} */}

        {selected && (
          <button
            className="delete-button"
            onClick={() => {
              setShowDelete(true);
            }}
          >
            {t('territorial.goals.lbl.delete')}
            <DeleteIcon></DeleteIcon>
          </button>
        )}
        <button
          className="help-button"
          onClick={() => {
            // undo();
          }}
        >
          {t('territorial.goals.lbl.help')}
          <HelpIcon></HelpIcon>
        </button>
        {children}
      </Map>
      {showDelete && (
        <ModalDeleteArea
          modalShow={showDelete}
          setModalShow={setShowDelete}
          successFun={() => {
            setShowDelete(false);
            if (selected.feature._id.length > 6) {
              dispatch(
                deleteRoute(
                  selected.feature._id,
                  () => {
                    const array: any[] = [];
                    layersGeoJson.forEach((e: any) => {
                      if (e._id !== selected.feature._id) {
                        array.push(e);
                      }
                    });
                    setLayersGeoJson(array);
                    setSelected((prevState: any) => {
                      prevState?.pm.disable();
                      prevState?.remove();
                      return null;
                    });
                    loadData();
                  },
                  () => {},
                ),
              );
            } else {
              const array: any[] = [];
              layersGeoJson.forEach((e: any) => {
                if (e._id !== selected.feature._id) {
                  array.push(e);
                }
              });
              setLayersGeoJson(array);
              setSelected((prevState: any) => {
                prevState?.pm.disable();
                prevState?.remove();
                return null;
              });
              // loadData();
            }
          }}
        ></ModalDeleteArea>
      )}
    </>
  );
};

export default MapWithGeoman;
