import React from 'react';
import { RiArrowLeftLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import LayoutBodyComponent from '../../../../components/LayoutBody';
import TabComponent from '../../../../components/Tabs';
import { SET_SELECTED_LICENSE_CITY } from '../../../../redux/campaigns/campaignsTypes';
import { RootStore } from '../../../../redux/Store';
import CardLicense from './cardLicense';
import MapLimits from './mapLimits';
import MapZoom from './mapZoom';
import ModulesList from './modulesList';
import SettingsTab from './settingsTab';
import UserList from './usersList';
import Image from 'react-bootstrap/Image';
import { useHistory } from 'react-router-dom';
import { SUPER_CAMPAIGNS } from '../../../../utils/paths';
import moment from 'moment';

const LicenseView: React.FunctionComponent = () => {
  const redirect = useHistory();
  const dispatch = useDispatch();

  const licenseSelected = useSelector((globalState: RootStore) => {
    return globalState.campaignsManager.licenseSelected;
  });

  const cityLicense = useSelector((globalState: RootStore) => {
    return globalState.campaignsManager.cityLicense;
  });

  const parties = useSelector((globalState: RootStore) => {
    return globalState.party.parties;
  });

  const countrySelected = useSelector((globalState: RootStore) => {
    return globalState.campaignsManager.selectedCountry;
  });

  const getParty = () => {
    return parties.find((e) => licenseSelected?.partyId === e._id);
  };

  return (
    <>
      <LayoutBodyComponent>
        <div className="license-view">
          <div className="top-divider">
            <div className="column-divider">
              <div className="city-divider">
                <h3>{countrySelected?.country.name + ' >'}</h3>
              </div>
              <div className="license-divider">
                <RiArrowLeftLine
                  onClick={() => {
                    dispatch({
                      type: SET_SELECTED_LICENSE_CITY,
                      licenseSelected: undefined,
                      cityLicense: undefined,
                    });
                    redirect.push(SUPER_CAMPAIGNS);
                  }}
                ></RiArrowLeftLine>
                <div className="name-and-party">
                  <Image src={getParty()?.logoPath || ''}></Image>
                  <h1>
                    {moment(licenseSelected?.endDate).format('yyyy')}
                    {' - '}
                    {licenseSelected?.name} / {cityLicense?.name}
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <div className="body-view-box">
            <CardLicense
              licenseSelected={licenseSelected}
              cityLicense={cityLicense}
            ></CardLicense>
            <div className="tabs-box-body">
              <TabComponent
                options={[
                  'Module',
                  'Geo Limits',
                  'Map',
                  'Campaign Manager',
                  'Settings',
                ]}
              >
                <ModulesList
                  licenseSelected={licenseSelected}
                  cityLicense={cityLicense}
                ></ModulesList>
                <MapLimits
                  licenseSelected={licenseSelected}
                  cityLicense={cityLicense}
                ></MapLimits>
                <MapZoom
                  licenseSelected={licenseSelected}
                  cityLicense={cityLicense}
                ></MapZoom>
                <UserList
                  licenseSelected={licenseSelected}
                  cityLicense={cityLicense}
                ></UserList>
                <SettingsTab
                  licenseSelected={licenseSelected}
                  cityLicense={cityLicense}
                ></SettingsTab>
              </TabComponent>
            </div>
          </div>
        </div>
      </LayoutBodyComponent>
    </>
  );
};

export default LicenseView;
