import React, { useEffect, useState } from 'react';
import { RouteProps } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import { BsSearch } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';
import './style.scss';
interface SelectProps extends RouteProps {
  search?: any;
  funOnInit?: any;
  additionalClass?: string;
  regExp?: any;
  setEnterOn?: any;
}

const SearchFormComponent: React.FunctionComponent<SelectProps> = ({
  search = '',
  funOnInit,
  additionalClass = '',
  regExp,
  setEnterOn,
}: SelectProps) => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState(String);

  useEffect(() => {
    setSearchValue(search);
  }, [search]);

  return (
    <div className={'tab-search-field ' + additionalClass}>
      <Form.Control
        value={searchValue}
        type="text"
        placeholder={t('placeholder.search')}
        className="mr-sm-2"
        onChange={(data: any) => {
          if (
            regExp === undefined ||
            regExp.test(data.target.value.trim()) ||
            data.target.value === ''
          ) {
            setSearchValue(data.target.value);
            funOnInit(data.target.value);
          }
        }}
        onKeyPress={(e: any) => {
          if (e.key === 'Enter') {
            setEnterOn(true)
          }
        }}
      ></Form.Control>
      <div className="icon">
        <BsSearch />
      </div>
    </div>
  );
};

export default SearchFormComponent;
