import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import './style.scss';

import RoutesCreation from './components/RoutesCreation';
import TopSeparator from '../../components/TopSeparator';

const RoutesTerritorial: React.FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <TopSeparator additionalClass='top-search-separator'></TopSeparator>
      <RoutesCreation t={t}></RoutesCreation>
    </Fragment>
  );
};

export default RoutesTerritorial;
