import { Address } from './../votersBox/VotersBoxTypes';
// In types.ts we will define constants for user authentication and UI loading purpose
// user reducer types
export const SET_BINGO = 'SET_BINGO';
export const UPDATE_BINGO = 'UPDATE_BINGO';
export const UPDATE_STATS = 'UPDATE_STATS';

export const SET_DISTRICTS = 'SET_DISTRICTS';
export const SET_SECTIONS = 'SET_SECTIONS';
export const SET_POLLING_STATIONS = 'SET_POLLING_STATIONS';

export const SET_DISTRICTS_SELECTED = 'SET_DISTRICTS_SELECTED';
export const SET_SECTIONS_SELECTED = 'SET_SECTIONS_SELECTED';
export const SET_POLLING_STATIONS_SELECTED = 'SET_POLLING_STATIONS_SELECTED';

export enum STATUS_BINGO_VOTERS {
  NONE = 'NONE',
  MARKED = 'MARKED',
}

export interface VoterBingo {
  _id: string;
  number: string;
  name: string;
  address: string;
  key: string;
  status: string;
  inDatabase: boolean;
}

export interface Bingo {
  status: string;
  voters: VoterBingo[];
}

export interface PollingStation {
  _id: string;
  address: Address;
  bingoId: string;
  number: number;
  status: string;
  rgId: string;
  rcId: string;
  substituteId: string;
  winningNumber: number;
  section: string;
  district: string;
  preparation: {
    tableReady: [string];
    documents: [string];
    pollingStation: [string];
  };
  startDate: Date;
  prepareDate: Date;
  openDate: Date;
  closeDate: Date;
  bingo: Bingo;
}

export interface SetBingo {
  type: typeof SET_BINGO;
  data: PollingStation;
}

export interface UpdateBingo {
  type: typeof UPDATE_BINGO;
  data: VoterBingo;
}

export interface UpdateStats {
  type: typeof UPDATE_STATS;
  data: PollingStation;
}

export interface SetDistrict {
  type: typeof SET_DISTRICTS;
  data: { _id: string; name: string }[];
}

export interface SetSection {
  type: typeof SET_SECTIONS;
  data: { _id: string; name: string }[];
}

export interface SetPollingStations {
  type: typeof SET_POLLING_STATIONS;
  data: { _id: string; name: string }[];
}

export interface SetDistrictSelected {
  type: typeof SET_DISTRICTS_SELECTED;
  data: { _id: string; name: string };
}

export interface SetSectionSelected {
  type: typeof SET_SECTIONS_SELECTED;
  data: { _id: string; name: string };
}

export interface SetPollingStationsSelected {
  type: typeof SET_POLLING_STATIONS_SELECTED;
  data: { _id: string; name: string };
}

export type BingoDispatchTypes =
  | SetBingo
  | UpdateBingo
  | UpdateStats
  | SetDistrict
  | SetSection
  | SetPollingStations
  | SetDistrictSelected
  | SetSectionSelected
  | SetPollingStationsSelected;
