import {
  DASHBOARD_ALL,
  DASHBOARD_CREATE,
  DASHBOARD_UPDATE,
  TAB_CHANGE,
  //======================
  DASHBOARD_LOADING,
  DASHBOARD_FAIL,
  DASHBOARD_SELECTED,
  DASHBOARD_SEARCH_SUCCESS,
  DASHBOARD_LINE,
  DASHBOARD_PIE,
  //======================
  DASHBOARD_D_LOADING,
  DASHBOARD_D_FAIL,
  DASHBOARD_D_SELECTED,
  DASHBOARD_D_SEARCH_SUCCESS,
  DashboardDispatchTypes,
} from './dashboardTypes';
import moment from 'moment';
import { getMonth, getDaysPerMonth } from '../../utils/utils';

interface DashboardState {
  dashboardData: any[];
  tabChange: string;
  //======================
  loading: boolean;
  selected: object;
  search: string;
  filter: Object;
  lineData: any[];
  pieData: any[];
  //======================
  loadingD: boolean;
  selectedD: object;
  searchD: string;
  filterD: string;
}

const defaultState: DashboardState = {
  dashboardData: [],
  tabChange: 'dashboard.tab.general',
  //======================
  loading: false,
  selected: {},
  search: '',
  filter: {
    progress: 'REGISTERED',
    showBy: 'MONTH',
    complaints: 'ALL',
    district: 'ALL',
    section: 'ALL',
  },
  lineData: [],
  pieData: [],
  //======================
  loadingD: false,
  selectedD: {},
  searchD: '',
  filterD: 'REGISTERED',
};

const dashboardReducer = (
  state: DashboardState = defaultState,
  action: DashboardDispatchTypes,
): DashboardState => {
  switch (action.type) {
    case DASHBOARD_ALL:
      const list_dashboard = [];
      const allSuccess = action.data;
      list_dashboard.push(...(allSuccess || []));
      return {
        ...state,
        dashboardData: list_dashboard,
        loading: false,
        loadingD: false,
      };
    case DASHBOARD_CREATE:
      const createSuccess = action.data;
      let allDataCreate = [...state.dashboardData];
      let selectedCreate: any = { ...state.selected };

      if (selectedCreate._id && createSuccess.type === 'SECTION') {
        allDataCreate.push(createSuccess.response);
      }
      if (!selectedCreate._id && createSuccess.type === 'DISTRICT') {
        allDataCreate.push(createSuccess.response);
      }
      /* const createSuccess = action.data;
      const array = [...state.dashboardData];
      if (state.selected && createSuccess.type === 'DISTRICT') {
        const index = array.findIndex(
          (e) => e._id === createSuccess.response._id,
        );
        if (index === -1) {
          array.push(createSuccess.response);
        } else {
          array[index] = createSuccess.response;
        }
      } else if (!state.selected && createSuccess.type === 'SECTION') {
        const index = array.findIndex(
          (e) => e._id === createSuccess.response._id,
        );
        if (index === -1) {
          array.push(createSuccess.response);
        } else {
          array[index] = createSuccess.response;
        }
      } */
      return {
        ...state,
        dashboardData: allDataCreate,
      };
    case DASHBOARD_UPDATE:
      const updateSuccess = action.data;
      let allDataUpdate = [...state.dashboardData];
      let selectedUpdate: any = { ...state.selected };
      let newDataUpdated: any = [];

      allDataUpdate.map((n: any) => {
        if (updateSuccess.response._id === n._id) {
          if (selectedUpdate._id && updateSuccess.type === 'SECTION') {
            newDataUpdated.push(updateSuccess.response);
          }
          if (!selectedUpdate._id && updateSuccess.type === 'DISTRICT') {
            newDataUpdated.push(updateSuccess.response);
          }
        } else {
          newDataUpdated.push(n);
        }
      });
      /* const updateSuccess = action.data;
      const arrayS = [...state.dashboardData];
      if (state.selected && updateSuccess.type === 'DISTRICT') {
        const index = arrayS.findIndex(
          (e) => e._id === updateSuccess.response._id,
        );
        if (index === -1) {
          arrayS.push(updateSuccess.response);
        } else {
          arrayS[index] = updateSuccess.response;
        }
      } else if (!state.selected && updateSuccess.type === 'SECTION') {
        const index = arrayS.findIndex(
          (e) => e._id === updateSuccess.response._id,
        );
        if (index === -1) {
          arrayS.push(updateSuccess.response);
        } else {
          arrayS[index] = updateSuccess.response;
        }
      } */
      return {
        ...state,
        dashboardData: newDataUpdated,
      };
    case TAB_CHANGE:
      return {
        ...state,
        tabChange: action.tabChange,
      };
    //======================
    case DASHBOARD_LOADING:
      return {
        ...state,
        loading: false,
      };
    case DASHBOARD_FAIL:
      return {
        ...state,
        loading: false,
      };
    case DASHBOARD_SELECTED:
      return {
        ...state,
        selected: action.payload,
      };
    case DASHBOARD_SEARCH_SUCCESS:
      return {
        ...state,
        search: action.searchValue,
      };
    case DASHBOARD_LINE:
      const data_line: any = action.payload;
      let list_line: any = [];
      if (data_line.showBy === 'MONTH') {
        for (let index = 1; index < 13; index++) {
          const actualMonth = data_line.lineList.find(
            (value: any) => value._id === index,
          );
          list_line.push({
            x: getMonth(index),
            y: actualMonth ? actualMonth.count : 0,
          });
        }
      } else {
        let month = parseInt(moment().format('MM'));
        for (let index = 1; index <= getDaysPerMonth(month); index++) {
          const actualDay = data_line.lineList.find(
            (value: any) => value._id === index,
          );
          list_line.push({
            x: index,
            y: actualDay ? actualDay.count : 0,
          });
        }
      }
      return {
        ...state,
        filter: {
          ...state.filter,
          progress: data_line.progress,
          showBy: data_line.showBy,
        },
        lineData: [
          {
            id: data_line.filter,
            color: 'hsl(49, 70%, 50%)',
            data: list_line,
          },
        ],
      };
    case DASHBOARD_PIE:
      const list_pie = [];
      const data_pie: any = action.payload;
      list_pie.push(...(data_pie.pieList || []));
      return {
        ...state,
        filter: {
          ...state.filter,
          complaints: data_pie.complaints,
          district: data_pie.district,
          section: data_pie.section,
        },
        pieData: list_pie,
      };
    //======================
    case DASHBOARD_D_LOADING:
      return {
        ...state,
        loadingD: false,
      };
    case DASHBOARD_D_FAIL:
      return {
        ...state,
        loadingD: false,
      };
    case DASHBOARD_D_SELECTED:
      return {
        ...state,
        selectedD: action.payload,
      };
    case DASHBOARD_D_SEARCH_SUCCESS:
      return {
        ...state,
        searchD: action.searchValue,
      };
    default:
      return state;
  }
};

export default dashboardReducer;
