export const LOGS_OPEN = 'LOGS_OPEN';
export const LOGS_LOADING = 'LOGS_LOADING';
export const LOGS_FAIL = 'LOGS_FAIL';
export const LOGS_SUCCESS = 'LOGS_SUCCESS';

export enum MODULE {
  DASHBOARD = 'DASHBOARD',
  VOTERS = 'VOTERS',
}

export enum LOGS_FILE_TYPE {
  PDF,
  EXCEL,
}

export type Logs = {
  _id: string;
  createdBy: string;
  createdDate: string;
  module: string;
  action: string;
  dataType: string;
  message: string;
  updatedIds: string[];
};

//
export interface LogsLoading {
  type: typeof LOGS_LOADING;
}

export interface LogsFail {
  type: typeof LOGS_FAIL;
}

export interface LogsSuccess {
  type: typeof LOGS_SUCCESS;
  logs: Logs[];
  dataId: string;
  module: MODULE;
}

export interface LogsOpen {
  type: typeof LOGS_OPEN;
  open: boolean;
}

export type LogsDispatchTypes = LogsLoading | LogsFail | LogsOpen | LogsSuccess;
