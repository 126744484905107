import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootStore } from '../../../redux/Store';
import * as netWorking from '../../../redux/netWorking';

import {
  postListRCsTeamsByCity,
  postGetRCsTeamDataTotal,
  postRCsTeamDataByRG,
  getCoordinatorViewByIdRGRC,
} from '../../../redux/RgRc/RgRcActions';
import { getTrainingsListByRole } from '../../../redux/trainings/TrainingsActions';
import { RGRC_RG_SUCCESS_ONE } from '../../../redux/RgRc/RgRcTypes';
import { ROLE, XLS_TYPE } from '../../../utils/utils';

import TableComponent, {
  HEADER,
  HEADER_TYPE,
  FIELD_TYPE,
} from '../../../components/Table';
import PaginationRecords from '../../../components/PaginationRecords';
import ModalAddRc from './addRgRc';
import ModalDeleteRcRg from './deleteRcRg';
import ModalAddXLS from '../../../dialogs/modalXLS';
import ModalAdministrer from './administrerModal';
import LoadingModal from '../../../dialogs/loadingModal';
import CodeGenerationDialog from './codeGenerationDialog';

const List: React.FunctionComponent = () => {
  const citySelected: any = useSelector((globalState: RootStore) => {
    return globalState.core.globalMunicipalitySelected;
  });

  const stateSelected = useSelector((globalState: RootStore) => {
    return globalState.core.globalStateSelected;
  });

  const loading = useSelector(
    (globalState: RootStore) => globalState.rgRc.loading,
  );
  const selectedRG: any = useSelector(
    (globalState: RootStore) => globalState.rgRc.selectedRG,
  );
  const navigationPath: any = useSelector(
    (globalState: RootStore) => globalState.rgRc.navigationPath,
  );
  const teamData: Array<any> = useSelector(
    (globalState: RootStore) => globalState.rgRc.teamData,
  );
  const totalTeamData: number = useSelector(
    (globalState: RootStore) => globalState.rgRc.totalTeamData,
  );

  const search = useSelector(
    (globalState: RootStore) => globalState.rgRc.search,
  );
  const sort: any = useSelector(
    (globalState: RootStore) => globalState.rgRc.sort,
  );
  const records: any = useSelector(
    (globalState: RootStore) => globalState.rgRc.records,
  );
  const page: any = useSelector(
    (globalState: RootStore) => globalState.rgRc.page,
  );
  //======================================================================================
  const totalPage = useSelector(
    (globalState: RootStore) => globalState.rgRc.totalPage,
  );
  //======================================================================================
  const trainingsByRole: Array<any> = useSelector(
    (globalState: RootStore) => globalState.trainings.trainingsByRole,
  );
  const totalByRole = useSelector(
    (globalState: RootStore) => globalState.trainings.totalByRole,
  );

  const [modalShowAdd, setModalShowAdd] = useState(false);
  const [modalDeleteShow, setModalDeleteShow] = useState(false);

  const [tableData, setTableData] = useState(Array);
  const [fieldOrder, setFieldOrder] = useState(sort[1] ? sort[1] : 'asc');
  const [municipalityId, setMunicipalityId] = useState(String);

  const [modalShowAdministrer, setModalShowAdministrer] = useState(false);
  const [modalShowXls, setModalShowXls] = useState(false);
  const [modalShowCodeGeneration, setModalShowCodeGeneration] = useState(false);
  const [edit, setEdit] = useState(Object);
  const [toDelete, setToDelete] = useState(Array);
  const [toResetPin, setToResetPin] = useState('');

  let defaultValue: any;

  const [addRole, setAddRole] = useState(defaultValue);
  const [selectedId, setSelectedId] = useState(defaultValue);
  const [selectedRole, setSelectedRole] = useState(defaultValue);
  const [selectedTeam, setSelectedTeam] = useState(defaultValue);
  const [isTeam, setIsTeam] = useState(defaultValue);

  let defaultUser: any;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  let teamLocalStorage = JSON.parse(localStorage.team);

  const getLevelValues = (reset = false) => {
    let selectedId =
      navigationPath.length < 1 ? localStorage?.id : selectedRG._id;
    let selectedRole =
      navigationPath.length < 1 ? localStorage?.role : selectedRG.role;
    let selectedTeam =
      navigationPath.length < 1 ? teamLocalStorage?._id : selectedRG.teamId;

    let role: any;

    if (selectedRole === ROLE.SUPER_ADMIN || selectedRole === ROLE.ADMIN_BOSS) {
      setIsTeam(false);
      role = ROLE.RG;
    } else if (selectedRole === ROLE.RG) {
      setIsTeam(false);
      role = ROLE.RC;
    }

    setAddRole(role);
    setSelectedId(selectedId);
    setSelectedRole(selectedRole);
    setSelectedTeam(selectedTeam);

    const params = buildParamSearchTeamDataByRG(
      selectedId,
      selectedRole,
      !reset ? search : '',
      !reset ? records : 10,
      fieldOrder,
      !reset ? page : 1,
    );
    dispatch(postRCsTeamDataByRG(params));
    dispatch(postGetRCsTeamDataTotal(params));

    const params2 = buildParamsRCsTeamsByCity();
    dispatch(postListRCsTeamsByCity(params2));

    dispatch(getTrainingsListByRole({ role: role }));
  };

  const headerRg: any = {
    totalTeamData: totalTeamData || 0,
    labelPlural: addRole,

    uploadButtonAppear: true,
    isUploadButtonDisabled: false,
    uploadFun: () => {
      setModalShowXls(true);
    },

    downloadButtonAppear:
      ROLE.ADMIN_BOSS || ROLE.SUPER_ADMIN || ROLE.RG ? true : false,
    downloadFun: () => {
      netWorking.downloadXls(XLS_TYPE.RG_RC_XLS_TYPE, citySelected?.name || '');
    },

    firstButtonAppear: navigationPath.length > 0 ? true : false,
    isFirstButtonDisabled: false,
    funTextAdmin: t('territorial.volunteer.table.transfer'),
    funAdmin: () => {
      setModalShowAdministrer(true);
    },

    secondButtonAppear: true,
    isSecondButtonDisabled: false,
    funText: t('electoral.rcrg.add.rc'),
    fun: () => {
      setEdit(defaultUser);
      setModalShowAdd(() => !modalShowAdd);
    },
  };

  const openEdit = (_id: any) => {
    const user = teamData?.find((value: any) => value._id === _id);
    if (user) {
      setEdit(user);
      setModalShowAdd(true);
    }
  };

  const openDelete = (deleteArray: any) => {
    setToDelete(deleteArray);
    setModalDeleteShow(true);
  };

  const getProgress = (trainingHistory: any) => {
    let stepCounter = 0;
    trainingHistory.viewedCourses.forEach((course: any) => {
      let training = trainingsByRole?.find((e) => {
        return e._id === course._id;
      });

      if (training) {
        course.stepsFinished.forEach((step: any) => {
          if (training.stepsArray.includes(step)) {
            stepCounter++;
          }
        });
      }
    });
    return (stepCounter / totalByRole).toFixed(2);
  };

  const transformData = () => {
    let newRCs: any = [];
    let dataArray: any = teamData;
    dataArray.forEach((rc: any) => {
      let newCapturist = {
        _id: rc._id,
        name: `${rc.firstName} ${rc.lastName}`,
        email: rc.email,
        cellphone: rc.cellphone,
        members: rc.members.length,
        votersBoxes: rc.votersB ? rc.votersB : {},
        courseProgress: rc.trainingHistory
          ? getProgress(rc.trainingHistory)
          : 0,
        level: rc.role,
        permission: true,
      };
      newRCs.push(newCapturist);
    });

    setTableData(newRCs);
  };

  const goToView = (_id: string) => {
    dispatch(getCoordinatorViewByIdRGRC(_id, '-1'));
  };

  const getPin = (_id: string) => {
    setToResetPin(_id);
    setModalShowCodeGeneration(true);
  };

  const getMainTitle = () => {
    if (edit === undefined) {
      return t('settings.trainings.form.add') + ' ' + addRole;
    } else {
      return t('settings.trainings.form.edit') + ' ' + addRole;
    }
  };

  const searchByValue = (reset = false) => {
    const params = buildParamSearchTeamDataByRG(
      selectedId,
      selectedRole,
      search,
      records ? records : 10,
      fieldOrder,
      !reset ? page : 1,
    );
    dispatch(postRCsTeamDataByRG(params));
    dispatch(postGetRCsTeamDataTotal(params));
  };

  const buildParamSearchTeamDataByRG = (
    selectedId: string,
    selectedRole: string,
    search: string,
    recordsNum: number,
    order: string,
    pageNum: number,
  ) => {
    let requestObject;
    requestObject = {
      selectedId: selectedId,
      selectedRole: selectedRole,
      municipalityId: citySelected._id,
      search: search.length >= 3 ? search : '',
      records: recordsNum,
      order: order,
      page: pageNum,
    };
    return requestObject;
  };

  const buildParamsRCsTeamsByCity = () => {
    let requestObject;
    requestObject = {
      municipalityId: citySelected._id,
    };
    return requestObject;
  };

  useEffect(() => {
    if (municipalityId && municipalityId !== citySelected._id) {
      dispatch({
        type: RGRC_RG_SUCCESS_ONE,
        payload: {},
        follow: '0',
      });
      getLevelValues(true);
    } else {
      getLevelValues(false);
    }
    if (citySelected) {
      setMunicipalityId(citySelected._id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [citySelected]);

  useEffect(() => {
    if (municipalityId) {
      setToDelete([]);
      getLevelValues(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigationPath]);

  useEffect(() => {
    if (teamData) {
      transformData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamData]);

  useEffect(() => {
    if (municipalityId && (search.length >= 3 || search.length <= 0)) {
      searchByValue();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    if (municipalityId) {
      searchByValue();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldOrder]);

  return (
    <Fragment>
      <div className="voter-box-list">
        <div className="voter-box-container">
          <div className="rgrc-container">
            <TableComponent
              showActions={true}
              selectedRows={toDelete}
              selectRows={setToDelete}
              selectMessage={true}
              header={headerRg}
              headerType={HEADER_TYPE.TERRITORIAL_TEAM_NEW}
              headers={[
                {
                  type: HEADER.FILTER,
                  name: t('electoral.rcrg.table.rgs.name'),
                  fun: () => {
                    let newOrder = fieldOrder === 'asc' ? 'desc' : 'asc';
                    setFieldOrder(newOrder);
                  },
                },
                {
                  type: HEADER.TEXT,
                  name: t('electoral.rcrg.table.rgs.email'),
                },
                {
                  type: HEADER.TEXT,
                  name: t('electoral.rcrg.table.rgs.cellphone'),
                },
                {
                  type: HEADER.TEXT,
                  name: t('electoral.rcrg.table.rgs.polling'),
                },
                {
                  type: HEADER.TEXT,
                  name:
                    t('electoral.rcrg.table.rgs.capacitations') + ' ' + addRole,
                },
                {
                  type: HEADER.TEXT,
                  name: t('territorial.volunteer.table.list'),
                },
                {
                  type: HEADER.TEXT,
                  name: t('territorial.volunteer.table.pin'),
                },
              ]}
              fieldTypes={[
                { type: FIELD_TYPE.TEXT },
                { type: FIELD_TYPE.TEXT },
                { type: FIELD_TYPE.TEXT },
                { type: FIELD_TYPE.BUTTON_ARRAY_VOTERS_BOX },
                { type: FIELD_TYPE.PERCENTAJE_PROGRESS },
                {
                  type: FIELD_TYPE.TEXT_LINK,
                  fun: (value: any, level: any) => {
                    goToView(value);
                  },
                },
                {
                  type: FIELD_TYPE.BUTTON_PIN,
                  fun: (value: any) => {
                    getPin(value);
                  },
                },
              ]}
              toEdit={(value: any) => {
                openEdit(value);
              }}
              toDelete={(value: any) => {
                openDelete(value);
              }}
              search={() => {}}
              regExp={undefined}
              data={tableData}
              nameOfData={[
                'name',
                'email',
                'cellphone',
                'votersBoxes',
                'courseProgress',
                '_id',
                'role',
              ]}
              classBody={[
                'text-align-left selector-cell full-name-container',
                'text-align-left email-container',
                'text-align-left cellphone-container',
                addRole === ROLE.RG
                  ? 'text-align-center voter-boxes-container-hidden'
                  : 'text-align-center voter-boxes-container',
                'text-align-left course-progress-container',
                addRole !== ROLE.RG
                  ? 'text-align-center list-container-hidden'
                  : 'text-align-center list-container',
                'text-align-center pin-container',
              ]}
            ></TableComponent>
            <PaginationRecords
              totalPages={totalPage}
              value={page}
              records={records}
              onChange={(pagina: string, record: string) => {
                let rec = record === '' ? '10' : record;
                let pag;
                if (records !== record) {
                  pag = '1';
                } else {
                  pag = pagina === '' || pagina === '0' ? '1' : pagina;
                }
                const params = buildParamSearchTeamDataByRG(
                  selectedId,
                  selectedRole,
                  search,
                  +rec,
                  fieldOrder,
                  +pag,
                );
                dispatch(postRCsTeamDataByRG(params));
                dispatch(postGetRCsTeamDataTotal(params));
              }}
            ></PaginationRecords>
          </div>
        </div>
      </div>
      {modalShowXls && (
        <ModalAddXLS
          modalShow={modalShowXls}
          setModalShow={setModalShowXls}
          type={XLS_TYPE.RG_RC_XLS_TYPE}
          states={[stateSelected?._id]}
          cities={[citySelected?._id]}
          municipalityId={municipalityId}
          successFun={() => {
            searchByValue();
            setToDelete([]);
          }}
        ></ModalAddXLS>
      )}
      {modalShowAdd && (
        <ModalAddRc
          mainTitle={getMainTitle()}
          modalShow={modalShowAdd}
          successFun={() => {
            searchByValue();
          }}
          setModalShow={setModalShowAdd}
          edit={edit}
          type={addRole}
          isTeam={isTeam}
          selectedTeam={{ teamId: selectedTeam, bossId: selectedId }}
          getPin={getPin}
        ></ModalAddRc>
      )}
      {modalDeleteShow && (
        <ModalDeleteRcRg
          modalShow={modalDeleteShow}
          successFun={() => {
            searchByValue();
            setToDelete([]);
          }}
          toDelete={toDelete}
          rcrg={teamData}
          isTeam={isTeam}
          setModalShow={setModalDeleteShow}
          deleteMessage={addRole}
        ></ModalDeleteRcRg>
      )}
      {modalShowAdministrer && (
        <ModalAdministrer
          modalShow={modalShowAdministrer}
          selectedTeam={selectedTeam}
          selectedMunicipality={municipalityId}
          successFun={() => {}}
          setModalShow={setModalShowAdministrer}
        ></ModalAdministrer>
      )}

      {loading && (
        <LoadingModal show={loading} onHide={() => {}}></LoadingModal>
      )}
      {modalShowCodeGeneration && (
        <CodeGenerationDialog
          setModalShow={setModalShowCodeGeneration}
          modalShow={modalShowCodeGeneration}
          userId={toResetPin}
        ></CodeGenerationDialog>
      )}
    </Fragment>
  );
};

export default List;
