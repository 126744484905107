import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PaginationRecords from '../../../../components/PaginationRecords';
import TableComponent, {
  FIELD_TYPE,
  HEADER,
  HEADER_TYPE,
} from '../../../../components/Table';
import CodeGenerationDialog from '../../../../pages/teamTerritorial/components/codeGenerationDialog';
import {
  blockUser,
  postListByStateCityUsers,
} from '../../../../redux/campaigns/campaignsActions';
import { City, Campaign } from '../../../../redux/campaigns/campaignsTypes';
import { RootStore } from '../../../../redux/Store';
import AddAdminModalComponent from './addAdmin';
import DeleteAdminModalComponent from './deleteAdmin';

interface UserListProps {
  licenseSelected?: Campaign;
  cityLicense?: City;
}

const UserList: React.FunctionComponent<UserListProps> = ({
  licenseSelected,
  cityLicense,
}: UserListProps) => {
  const dispatch = useDispatch();
  const [toDelete, setToDelete] = useState(Array);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [showAddModal, setShowAddModal] = React.useState(false);
  const [fieldOrder, setFieldOrder] = useState('asc');
  const [fieldName] = useState('firstName');
  const [toEdit, setToEdit] = useState(Object);
  const [toResetPin, setToResetPin] = useState('');
  const [modalShowCodeGeneration, setModalShowCodeGeneration] = useState(false);

  const users = useSelector((globalState: RootStore) => {
    return globalState.campaignsManager.users;
  });

  const buildParamSearch = (
    order: string,
    field: string,
    pageNum: number,
    recordsNum: number,
    search: string,
  ) => {
    let requestObject;
    requestObject = {
      page: pageNum,
      records: recordsNum,
      field,
      order,
      search: search.length >= 3 ? search : '',
      states: licenseSelected?.states[0]._id || '',
      cities: cityLicense?._id || '',
    };
    return requestObject;
  };

  const getPin = (_id: string) => {
    setToResetPin(_id);
    setModalShowCodeGeneration(true);
  };

  useEffect(() => {
    if (cityLicense && licenseSelected) {
      getList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cityLicense, licenseSelected]);

  const getList = () => {
    const params = buildParamSearch(fieldOrder, fieldName, 1, 50, '');
    dispatch(postListByStateCityUsers(params));
  };

  const openDelete = (deleteArray: any) => {
    setToDelete(deleteArray);
    setShowDeleteModal(true);
  };

  const openEdit = (userId: any) => {
    const user = users?.find((value: any) => value._id === userId);
    if (user) {
      setToEdit(user);
      setShowAddModal(true);
    }
  };

  return (
    <>
      <div className="list-admin-bosses-tab">
        <TableComponent
          showActions={true}
          selectedRows={toDelete}
          selectRows={setToDelete}
          selectMessage={true}
          header={{
            title: 'Administradores',
            fun: () => {
              setToEdit(undefined);
              setShowAddModal(true);
            },
          }}
          headerType={HEADER_TYPE.CAMPAIGN_HEADER}
          headers={[
            {
              type: HEADER.FILTER,
              name: 'Nombre',
              fun: () => {
                const newOrder = fieldOrder === 'asc' ? 'desc' : 'asc';
                setFieldOrder(newOrder);
                getList();
              },
            },
            {
              type: HEADER.TEXT,
              name: 'Email',
            },
            {
              type: HEADER.TEXT,
              name: 'Cellphone',
            },
            {
              type: HEADER.TEXT,
              name: 'Status',
            },
            {
              type: HEADER.TEXT,
              name: 'PIN',
            },
          ]}
          fieldTypes={[
            { type: FIELD_TYPE.IMAGE_TEXT_TWO_INITIALS },
            { type: FIELD_TYPE.TEXT },
            { type: FIELD_TYPE.TEXT },
            {
              type: FIELD_TYPE.SWITCH,
              fun: (value: any) => {
                dispatch(
                  blockUser(
                    {
                      userId: value._id,
                      block: !value.blocked,
                      motive: 'none',
                    },
                    () => {
                      getList();
                    },
                  ),
                );
              },
            },
            {
              type: FIELD_TYPE.BUTTON_PIN,
              fun: (value: any) => {
                getPin(value);
              },
            },
          ]}
          toEdit={(value: any) => {
            // setIsCoordinator(false);
            openEdit(value);
          }}
          toDelete={(value: any) => {
            openDelete(value);
          }}
          search={() => {}}
          regExp={undefined}
          data={users}
          nameOfData={['firstName', 'email', 'cellphone', 'blocked','role',]}
          classBody={[
            'text-align-left selector-cell full-name-container',
            'text-align-left cellphone-container',
            'text-align-center home-container',
            'text-align-center people-container',
            'text-align-center pin-container',
          ]}
        ></TableComponent>
        <PaginationRecords
          totalPages={1}
          value={0}
          records={0}
          onChange={(pagina: string, record: string) => {
            // let rec = record === '' ? '10' : record;
            // let pag;
            // if (records != record) {
            //   pag = '1';
            // } else {
            //   pag = pagina === '' || pagina === '0' ? '1' : pagina;
            // }
            // dispatch(
            //   searchTeamData(searchValue, +rec, +pag, fieldOrder),
            // );
          }}
        ></PaginationRecords>
      </div>
      <AddAdminModalComponent
        modalShow={showAddModal}
        setModalShow={setShowAddModal}
        successFun={() => {
          getList();
        }}
        toEdit={toEdit}
        licenseSelected={licenseSelected}
        cityLicense={cityLicense}
      ></AddAdminModalComponent>
      <DeleteAdminModalComponent
        modalShow={showDeleteModal}
        setModalShow={setShowDeleteModal}
        successFun={() => {
          getList();
        }}
        licenseSelected={licenseSelected}
        cityLicense={cityLicense}
        toDelete={toDelete}
        users={users}
      ></DeleteAdminModalComponent>{' '}
      {modalShowCodeGeneration && (
        <CodeGenerationDialog
          setModalShow={setModalShowCodeGeneration}
          modalShow={modalShowCodeGeneration}
          userId={toResetPin}
        ></CodeGenerationDialog>
      )}
    </>
  );
};

export default UserList;
