export const TRAININGS_LOADING = "TRAININGS_LOADING";
export const TRAININGS_FAIL = "TRAININGS_FAIL";
export const TRAININGS_SUCCESS = "TRAININGS_SUCCESS";
export const TRAININGS_ROLE_SUCCESS = "TRAININGS_ROLE_SUCCESS";
export const TRAININGS_PAGE_SUCCESS = "TRAININGS_PAGE_SUCCESS";
export const TRAININGS_SINGLE_SUCCESS = "TRAININGS_SINGLE_SUCCESS";
export const TRAININGS_SEARCH_SUCCESS = "TRAININGS_SEARCH_SUCCESS";

export type Trainings = {
  _id: string;
  addressId: string;
  bingoId: string;
  number: string;
  status: string;
};

export type TrainingsCreate = {
  uploadData: any;
  detailData: any;
  stepData: any;
};

export type TrainingsEdit = {
  uploadData: any;
  detailData: any;
  stepData: any;
};

export type SearchTrainings = {
  search: string;
};

export type SearchTrainingsByRole = {
  role: string;
};

export interface ITrainings {
  _id: string;
  number: string;
  addressText: string;
  addressId: string;
  city?: string;
  ubication?: string;
  district?: string;
  section?: string;
}

export type Training = {
  _id: string;
  name: string;
  showTo: Number;
  urlPicture: string;
  steps: Array<any>;
}

export interface TrainingsLoading {
  type: typeof TRAININGS_LOADING;
}
export interface TrainingsFail {
  type: typeof TRAININGS_FAIL;
}

export interface TrainingsSuccess {
  type: typeof TRAININGS_SUCCESS;
  payload: Trainings[];
}

export interface TrainingsRoleSuccess {
  type: typeof TRAININGS_ROLE_SUCCESS;
  payload: any;
}

export interface TrainingsSingleSuccess {
  type: typeof TRAININGS_SINGLE_SUCCESS;
  payload: Training[];
}

export interface TrainingsSearchSuccess {
  type: typeof TRAININGS_SEARCH_SUCCESS;
  searchValue: string;
}

export interface TrainingsPageSuccess {
  type: typeof TRAININGS_PAGE_SUCCESS;
  page: string;
}


export type TrainingsDispatchTypes =
  | TrainingsLoading
  | TrainingsFail
  | TrainingsSuccess
  | TrainingsRoleSuccess
  | TrainingsPageSuccess
  | TrainingsSingleSuccess
  | TrainingsSearchSuccess;
