import L from 'leaflet';
import { renderToString } from 'react-dom/server';
import {
  SelectedMarker,
  UnSelectedMarker,
  PinBlackIcon,
  PinMarkerIcon,
  SelectedRedIcon,
  UnSelectedRedIcon,
  PinMarkerBlueIcon,
} from '../../assets/svg';
import './style.scss';
import React from 'react';

export const neutralMarker = L.divIcon({
  html: renderToString(
    <div>
      <PinMarkerIcon></PinMarkerIcon>
    </div>,
  ),
  iconSize: new L.Point(30, 30),
  className: 'leaflet-div-icon-selected',
});

export const neutralBlueMarker = L.divIcon({
  html: renderToString(
    <div>
      <PinMarkerBlueIcon></PinMarkerBlueIcon>
    </div>,
  ),
  iconSize: new L.Point(30, 30),
  className: 'leaflet-div-icon-selected blue-icon-marker',
});

export const selectedMarker = L.divIcon({
  html: renderToString(
    <div>
      <SelectedMarker></SelectedMarker>
    </div>,
  ),
  iconSize: new L.Point(30, 30),
  className: 'leaflet-div-icon-selected',
});

export const unSelectedMarker = L.divIcon({
  html: renderToString(
    <div>
      <UnSelectedMarker></UnSelectedMarker>
    </div>,
  ),
  iconSize: new L.Point(30, 30),
  className: 'leaflet-div-icon-unSelected',
});

export const selectedRedMarker = L.divIcon({
  html: renderToString(
    <div>
      <SelectedRedIcon></SelectedRedIcon>
    </div>,
  ),
  iconSize: new L.Point(30, 30),
  className: 'leaflet-div-icon-selected',
});

export const unSelectedRedMarker = L.divIcon({
  html: renderToString(
    <div>
      <UnSelectedRedIcon></UnSelectedRedIcon>
    </div>,
  ),
  iconSize: new L.Point(30, 30),
  className: 'leaflet-div-icon-unSelected',
});

export const createClusterCustomIcon = function (cluster: any) {
  return L.divIcon({
    html: renderToString(
      <span>
        {cluster.getChildCount()}{' '}
        <div className="cluster-marker">
          <PinBlackIcon></PinBlackIcon>
        </div>
      </span>,
    ),
    className: 'marker-cluster-custom',
    iconSize: L.point(30, 30, true),
  });
};
