import React, { useEffect, useState } from 'react';
import { RootStore } from '../../../../src/redux/Store';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { LatLng, Map } from 'react-leaflet';
import L from 'leaflet';

import { setCapturistSelected } from '../../../redux/teams/teamsActions';
import { Team } from '../../../redux/RgRc/RgRcTypes';

import TableComponent, {
  HEADER,
  HEADER_TYPE,
  FIELD_TYPE,
} from '../../../components/Table';
import LayerControlComponent from '../../../components/sub-component/layerControl';

// tslint:disable-next-line: prefer-const
let generalCapturist: any;
// tslint:disable-next-line: prefer-const
let boundEmpty: any;

interface TabsProps {
  selectedRows: any;
  teamData: any;
}

const VolunteerSectionsComponent: React.FunctionComponent<TabsProps> = ({
  selectedRows,
  teamData,
}: any) => {
  const citySelected = useSelector((globalState: RootStore) => {
    return globalState.core.globalMunicipalitySelected;
  });

  const capturist: Team[] = useSelector(
    (globalState: RootStore) => globalState.teams.selectedCapturist,
  );

  const [selected, setSelected] = useState(Array);
  const [tableData, setTableData] = useState(Array);
  const [selectedCapturist, setSelectedCapturist] = useState(generalCapturist);
  const [capturistCatches, setCapturistCatches] = useState(Number);

  const defaultPosition = {
    lat: 32.7157431,
    lng: -117.1888535,
    zoom: 11,
  };
  const position: LatLng =
    citySelected?.defaultCenter !== undefined
      ? citySelected?.defaultCenter
      : [defaultPosition.lat, defaultPosition.lng];
  const leafletMapRef = React.useRef<Map>(null);
  const [center, setCenter] = useState(position);
  const [bounds, setBounds] = useState(boundEmpty);
  const [zoom, setZoom] = React.useState(
    citySelected?.defaultZoom !== undefined
      ? citySelected?.defaultZoom
      : defaultPosition.zoom,
  );

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const headerCoordinatorBox: any = {};

  const getBounds = (boundsList: any[]) => {
    var bounds = L.latLngBounds([]);
    boundsList.forEach((e) => {
      bounds.extend(e);
    });

    if (bounds.isValid()) {
      setBounds(bounds);
      setCenter(bounds.getCenter());
    }
  };

  const drawMap = (generalAreas: any) => {
    const mapElement = leafletMapRef.current?.leafletElement;
    if (mapElement) {
      // let count = 0;
      mapElement.eachLayer((layer: any) => {
        if (layer._url === undefined && layer._container === undefined)
          layer.remove();
        // count++;
      });
      let boundsArray: any = [];
      generalAreas.forEach((e: any, index: number) => {
        let bounds = L.geoJSON(
          {
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              coordinates: e.area.coordinates,
            },
          } as any,
          {
            style: {
              weight: 2,
              opacity: 1,
              color: e.color,
              dashArray: '',
              fillOpacity: 1,
              fillColor: e.color,
            },
            onEachFeature: (feature, layer) => {
              layer
                .addTo(mapElement)
                .bindTooltip(String(index + 1), {
                  permanent: true,
                  direction: 'auto',
                  className: 'my-labels',
                })
                .openTooltip();
              layer.addTo(mapElement);
            },
          },
        ).getBounds();
        boundsArray.push(bounds);
      });
      getBounds(boundsArray);
    }
  };

  const transformData = (generalAreas: any) => {
    let newAreasData: any = [];
    let countAux = 1;
    let catchesAux = 0;
    generalAreas?.forEach((generalArea: any) => {
      let remainString = '';
      remainString =
        generalArea.area.limitType === 0
          ? generalArea.area.limit * 1 -
            generalArea.area.datesWorked.length +
            'd'
          : generalArea.area.limit * 7 -
            generalArea.area.datesWorked.length +
            'd';
      const extraGoal = generalArea.area.extraGoal
        ? generalArea.area.extraGoal
        : 0;
      const total = extraGoal + generalArea.dataOfArea[0].total;
      const captured =
        generalArea.dataOfArea[0].toAudit + generalArea.dataOfArea[0].audited;

      let progressString = '0%';
      progressString = ((captured * 100) / total).toFixed(2) + '%';

      catchesAux += captured;

      let newArea = {
        _id: generalArea.area._id,
        area: countAux,
        catches: captured,
        total,
        goal: extraGoal,
        progress: progressString,
        remain: remainString,
        color: generalArea.color,
        colorbase: generalArea.colorBase,
      };

      newAreasData.push(newArea);
      countAux++;
    });
    setCapturistCatches(catchesAux);
    setTableData(newAreasData);
  };

  const callCapturistRoutes = (capturist: any) => {
    let array: any = [];
    if (
      capturist &&
      capturist instanceof Array !== true &&
      capturist._id !== '1'
    ) {
      capturist.areas.forEach((e: any) => {
        let r1 = (Math.random() * 255).toFixed(0);
        let r2 = (Math.random() * 255).toFixed(0);
        let r3 = (Math.random() * 255).toFixed(0);
        let color = `rgba(${r1},${r2},${r3},0.5)`;
        let colorBase = `rgba(${r1},${r2},${r3},1)`;
        array.push({ ...e, color, colorBase });
      });
      transformData(array);
      drawMap(array);
      setSelectedCapturist(capturist);
    } else {
      transformData(array);
      drawMap(array);
    }
  };

  useEffect(() => {
    if (capturist) {
      callCapturistRoutes(capturist);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [capturist]);

  useEffect(() => {
    setSelected(selectedRows);
    teamData.forEach((e: any) => {
      if (selectedRows.length === 1) {
        if (e._id === selectedRows[0]) {
          dispatch(setCapturistSelected(e));
        }
      } else {
        dispatch(setCapturistSelected({ _id: '1' }));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRows]);

  useEffect(() => {
    if (citySelected) {
      setCenter(
        citySelected?.defaultCenter !== undefined
          ? citySelected?.defaultCenter
          : [defaultPosition.lat, defaultPosition.lng],
      );
      setZoom(
        citySelected?.defaultZoom !== undefined
          ? citySelected?.defaultZoom
          : defaultPosition.zoom,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [citySelected]);

  return (
    <div className="team-sections-container">
      <div className="title">
        <div className="header-main-title">
          {selected.length === 1 && (
            <div className="user-info">
              <h1>
                {(selectedCapturist?.firstName || '') +
                  ' ' +
                  (selectedCapturist?.lastName || '-')}
              </h1>
              <p className="">
                {capturistCatches}{' '}
                {t('territorial.volunteer.slider.lbl.catches')}
              </p>
            </div>
          )}
          {(selected.length > 1 || !selected.length) && (
            <div className="user-info-message">
              <h1>{t('territorial.volunteer.slider.lbl.title')}</h1>
              <h1>{t('territorial.volunteer.slider.lbl.title2')}</h1>
            </div>
          )}
        </div>
      </div>
      <div className="map-box-box">
        <Map
          center={center as any}
          zoomControl={false}
          zoom={zoom}
          ref={leafletMapRef}
          bounds={bounds || undefined}
          boundsOptions={{ padding: [0, 0] }}
        >
          <LayerControlComponent></LayerControlComponent>
        </Map>
      </div>
      <div className="list-nav">
        <TableComponent
          showActions={false}
          selectedRows={[]}
          selectRows={() => {}}
          header={headerCoordinatorBox}
          headerType={HEADER_TYPE.HIDDEN}
          headers={[
            {
              type: HEADER.TEXT,
              name: t('territorial.volunteer.slider.lbl.area'),
            },
            {
              type: HEADER.TEXT,
              name: t('territorial.volunteer.slider.lbl.total'),
            },
            {
              type: HEADER.TEXT,
              name: t('territorial.volunteer.table.goal'),
            },
            {
              type: HEADER.TEXT,
              name: t('territorial.volunteer.slider.lbl.catches'),
            },
            {
              type: HEADER.TEXT,
              name: t('territorial.volunteer.slider.lbl.progress'),
            },
            {
              type: HEADER.TEXT,
              name: t('territorial.volunteer.slider.lbl.remain'),
            },
          ]}
          fieldTypes={[
            { type: FIELD_TYPE.TEXT_TEAMS },
            { type: FIELD_TYPE.TEXT },
            { type: FIELD_TYPE.TEXT },
            { type: FIELD_TYPE.TEXT },
            { type: FIELD_TYPE.TEXT },
            { type: FIELD_TYPE.TEXT },
          ]}
          toEdit={() => {}}
          toDelete={() => {}}
          search={''}
          regExp={undefined}
          data={tableData}
          nameOfData={[
            'area',
            'total',
            'goal',
            'catches',
            'progress',
            'remain',
          ]}
          classBody={[
            'text-align-center selector-cell-none teams-area-justify',
            'text-align-center',
            'text-align-center',
            'text-align-center',
            'text-align-center',
            'text-align-center',
          ]}
        ></TableComponent>
      </div>
    </div>
  );
};
export default VolunteerSectionsComponent;
