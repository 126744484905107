import React, { useState, createRef, useEffect } from 'react';
import { RouteProps } from 'react-router-dom';
import { CheckGreenIcon } from '../../assets/svg/index';
import { IoMdArrowDropdown } from 'react-icons/io';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import Image from 'react-bootstrap/Image';
import { findFlagUrlByCountryName } from 'country-flags-svg';

interface SelectProps extends RouteProps {
  title: string;
  horizontal: boolean;
  filters: any;
  filtersSelected: any;
  fun: any;
  t: any;
}

const SelectorCustomComponent: React.FunctionComponent<SelectProps> = ({
  title,
  horizontal,
  filters,
  filtersSelected,
  fun,
  t,
}: any) => {
  const ref = createRef<HTMLDivElement>();

  const [style, updateStyle] = useState(`
  .dropwwwwwww {
    background-color: #fff;
    border-bottom: 1px solid #eee;
    transform: translate(${0}px, ${ref.current?.clientHeight}px) !important
  }
`);

  useEffect(() => {
    updateStyle(`
    .dropwwwwwww {
      background-color: #fff;
      border-bottom: 1px solid #eee;
      transform: translate(${0}px, ${ref.current?.clientHeight}px) !important
    }
  `);
  }, [ref]);

  const getFiltersSelected = () => {
    let result = (
      <>
        <p> {t('select.lbl.none')}</p>
      </>
    );
    if (filtersSelected)
      result = (
        <>
          <Image src={findFlagUrlByCountryName(filtersSelected.name)} />
          <p> {filtersSelected.name}</p>
        </>
      );

    return result;
  };
  return (
    <div className={horizontal ? 'tags-box-horizontal' : 'tags-box'}>
      <h2>{title}</h2>
      <div className="dropdown-tags dropdown-filters">
        <div ref={ref} className="tag-box">
          <Dropdown as={ButtonGroup}>
            <style>{style}</style>
            <div className="tags-items-box-2">{getFiltersSelected()}</div>

            <Dropdown.Toggle split variant="success" id="dropdown-split-basic">
              <IoMdArrowDropdown></IoMdArrowDropdown>
            </Dropdown.Toggle>

            <Dropdown.Menu
              className="dropwwwwwww"
              style={{
                transform: `translate(${0}px, ${
                  ref.current?.clientHeight
                }px) !important`,
              }}
            >
              {filters.map((e: any, index: number) => {
                return (
                  <div
                    key={index}
                    onClick={() => {
                      fun(e);
                    }}
                    className={
                      filtersSelected !== undefined &&
                      filtersSelected.name === e.name
                        ? 'item-active dropdown-item'
                        : 'dropdown-item'
                    }
                  >
                    <div className="data-box-items">
                      <Image src={findFlagUrlByCountryName(e.name)} />
                      <p> {e.name}</p>
                    </div>

                    <div className="icon">
                      <CheckGreenIcon></CheckGreenIcon>
                    </div>
                  </div>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default SelectorCustomComponent;
