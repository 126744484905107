import {
  USERS_LOADING,
  USERS_FAIL,
  USERS_SUCCESS,
  USERS_COUNT,
  UsersDispatchTypes,
} from './UsersTypes';

interface UsersState {
  loading: boolean;
  user: Object;
  users?: any[];
  totalUsers: number;
  page?: number;
  totalPage: number;
  records?: number;
  sort?: any[];
  search: string;
}

const defaultState: UsersState = {
  loading: false,
  user: {},
  users: [],
  totalUsers: 0,
  page: 1,
  totalPage: 1,
  records: 10,
  sort: ['firstName', 'asc'],
  search: '',
};

const usersReducer = (
  state: UsersState = defaultState,
  action: UsersDispatchTypes,
): UsersState => {
  switch (action.type) {
    case USERS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case USERS_FAIL:
      return {
        ...state,
        loading: false,
      };
    case USERS_SUCCESS:
      const list_users = [];
      const data_success: any = action.payload;
      list_users.push(...(data_success.users || []));
      return {
        ...state,
        users: list_users,
        page: data_success.page,
        records: data_success.records,
        sort: data_success.sort,
        search: data_success.search,
      };
    case USERS_COUNT:
      const data_count: any = action.payload;
      return {
        ...state,
        totalPage: data_count.totalPage,
        totalUsers: data_count.totalUsers,
      };
    default:
      return state;
  }
};

export default usersReducer;
