import {
  AREAS_FAIL,
  AREAS_SUCCESS,
  AREAS_TEAM_SUCCESS,
  AREAS_COORDINATOR_CHANGE,
  AREAS_CAPTURIST_CHANGE,
  SearchCapturistTeamsByCity,
  AREAS_HEAT_MAP,
} from './areasTypes';
import * as netWorking from '../netWorking';

export const postListCapturistTeamsByCity = (
  data: SearchCapturistTeamsByCity,
  successFun?: any,
) => {
  return async (dispatch: any) => {
    return await netWorking.post(
      netWorking.postByCityCapturistTeams,
      data,
      (res: any) => {
        if (successFun) {
          successFun();
        }
        return dispatch({
          type: AREAS_SUCCESS,
          payload: res.data.response,
        });
      },
      (error: any) => {
        return dispatch({
          type: AREAS_FAIL,
        });
      },
    );
  };
};

export function getCapturistTeamsData(users: string[]) {
  return async (dispatch: any) => {
    return await netWorking.post(
      netWorking.getCapturistTeamsData,
      { users },
      (res: any) => {
        return dispatch({
          type: AREAS_TEAM_SUCCESS,
          payload: res.data.response,
        });
      },
      (error: any) => {
        return dispatch({
          type: AREAS_FAIL,
        });
      },
    );
  };
}

export const changeSelectedCoordinator = (
  coordinator: any,
  successFun?: any,
) => {
  return async (dispatch: any) => {
    dispatch({
      type: AREAS_COORDINATOR_CHANGE,
      payload: coordinator,
    });
    if (successFun) {
      successFun();
    }
  };
};

export const changeSelectedCapturist = (capturist: any, successFun?: any) => {
  return async (dispatch: any) => {
    dispatch({
      type: AREAS_CAPTURIST_CHANGE,
      payload: capturist,
    });
    if (successFun) {
      successFun();
    }
  };
};

export function getHeatMapData(cityLicenseId: string) {
  return async (dispatch: any) => {
    return await netWorking.post(
      netWorking.getHeatMap + cityLicenseId,
      {},
      (res: any) => {
        return dispatch({
          type: AREAS_HEAT_MAP,
          payload: res.data.response.clusters,
        });
      },
      (error: any) => {
        return dispatch({
          type: AREAS_HEAT_MAP,
          payload: [],
        });
      },
    );
  };
}
