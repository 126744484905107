export const UP_DATA_LOADING = 'UP_DATA_LOADING';
export const UP_DATA_FAIL = 'UP_DATA_FAIL';
export const UP_DATA_SUCCESS = 'UP_DATA_SUCCESS';
export const SELECT_DATA_SUCCESS = 'SELECT_DATA_SUCCESS';
export const SEARCH_DATA_SUCCESS = 'SEARCH_DATA_SUCCESS';
export const NEW_DATA_SUCCESS = 'NEW_DATA_SUCCESS';

export type UpData = {
  _id: string;
  code: string;
  filePath: string;
  createdDate: Date;
  approvalDate: Date;
  byId: string;
  byName: string;
  campaignId: string;
  type: number;
  name: string;
  fileName: string;
  errorsCount: number;
  winsCount: number;
  totalData: number;
  status: number;
  delete: boolean;
  reviewedId: string;
  reviewedByName: string;
};
//
export interface UpDataLoading {
  type: typeof UP_DATA_LOADING;
}

export interface UpDataFail {
  type: typeof UP_DATA_FAIL;
}

export interface UpDataSuccess {
  type: typeof UP_DATA_SUCCESS;
  upData: UpData[];
}
export interface SelectDataSuccess {
  type: typeof SELECT_DATA_SUCCESS;
  selectData: boolean;
}
export interface SearchDataSuccess {
  type: typeof SEARCH_DATA_SUCCESS;
  searchValue: string;
}

export interface NewDataSuccess {
  type: typeof NEW_DATA_SUCCESS;
  upData: UpData;
}

//
export type UpDataDispatchTypes =
  | UpDataLoading
  | UpDataFail
  | UpDataSuccess
  | SelectDataSuccess
  | SearchDataSuccess
  | NewDataSuccess;
