import {
  CAPTURISTS_LOADING,
  CAPTURISTS_FAIL,
  CAPTURISTS_SUCCESS,
  CAPTURISTS_TEAM_SUCCESS,
  CAPTURISTS_COORDINATOR_CHANGE,
  CAPTURISTS_CAPTURIST_CHANGE,
  CapturistsDispatchTypes,
} from './capturistsTypes';
import { Team } from '../RgRc/RgRcTypes';

interface Coordinator {
  _id: string;
  name: string;
  members: Array<string>;
}

interface Capturist {
  _id: string;
  name: string;
}

interface CapturistsState {
  loading: boolean;
  teams: Array<Team>;
  teamData: Array<any>;
  selectedCoordinator: Coordinator;
  selectedCapturist?: Capturist;
  selectedTeam: Team | null | undefined;
}

const defaultState: CapturistsState = {
  loading: false,
  teams: [],
  teamData: [],
  selectedCoordinator: {
      _id: '',
      name: '',
      members: [],
  },
  selectedCapturist: {
      _id: '',
      name: '',
  },
  selectedTeam: null,
};

const capturistsReducer = (
  state: CapturistsState = defaultState,
  action: CapturistsDispatchTypes,
): CapturistsState => {
  switch (action.type) {
    case CAPTURISTS_FAIL:
        return {
        ...state,
        loading: false,
        };
    case CAPTURISTS_LOADING:
        return {
        ...state,
        loading: true,
        };
    case CAPTURISTS_SUCCESS:
        const dataTeamsSuccess: any = action.payload;
        const actualCoordinator = dataTeamsSuccess.teams.find(
            (team: any) => team._id === state.selectedCoordinator._id,
        );

        const teams = [];
        teams.push(...(dataTeamsSuccess.teams || []));
        const coordinator =
        teams.length > 0
            ? state.selectedCoordinator._id === '' || dataTeamsSuccess.isFirst
            ? {
                _id: teams[0]._id,
                name: teams[0].name,
                members: teams[0].members,
                }
            : {
                ...state.selectedCoordinator,
                members: actualCoordinator
                    ? actualCoordinator.members
                    : state.selectedCoordinator.members,
                }
            : {
                _id: '',
                name: '',
                members: [],
            };
        const selectedTeam = teams.find(
            (team: Team) => team.bossId === coordinator._id,
        );
        return {
            ...state,
            teams,
            selectedCoordinator: {
                _id: coordinator._id,
                name: coordinator.name,
                members: coordinator.members,
            },
            selectedTeam,
            loading: false,
        };
    case CAPTURISTS_TEAM_SUCCESS:
        const dataTeamsTotalSuccess: any = action.payload;
        return {
          ...state,
          teamData: dataTeamsTotalSuccess
        }
    case CAPTURISTS_COORDINATOR_CHANGE:
        const selectedCoordinator = { ...action.payload };
        const selectedTeam2 = state.teams.find(
        (team) => team.bossId === selectedCoordinator._id,
        );

        return {
            ...state,
            selectedCoordinator,
            selectedTeam: selectedTeam2,
            loading: false,
        };
    case CAPTURISTS_CAPTURIST_CHANGE:
        const selectedCapturist = { ...action.payload };
        return {
            ...state,
            selectedCapturist,
            loading: false,
        };
    default:
        return state;
  }
};

export default capturistsReducer;
