import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';
import StaticModalCustom from '../../../dialogs/StaticModalCustom';
import '../style.scss';

interface ModalPhotoIdProps {
  modalShow: any;
  setModalShow: any;
  photosIds: any;
  active: number;
  setActive: any;
  t: any;
}

export const ModalPhotoId: React.SFC<ModalPhotoIdProps> = ({
  modalShow,
  setModalShow,
  photosIds,
  active,
  setActive,
  t,
}: any) => {
  const handleSelect = (selectedIndex: number, e: any) => {
    setActive(selectedIndex);
  };

  const modalTitleSetter = () => {
    let modalTitle;

    switch (Number(active)) {
      case 0:
        modalTitle = `${t('territorial.voters.lbl.photosId')} (${t(
          'territorial.voters.lbl.photosIdFront',
        )})`;
        break;
      case 1:
        modalTitle = `${t('territorial.voters.lbl.photosId')} (${t(
          'territorial.voters.lbl.photosIdBack',
        )})`;
        break;
      default:
        break;
    }

    return modalTitle;
  };

  return (
    <StaticModalCustom
      show={modalShow}
      title={modalTitleSetter()?.toUpperCase() || ''}
      closeText={t('submit.ok')}
      confirmText={t('submit.ok')}
      onHide={() => setModalShow(false)}
      submitFun={() => setModalShow(false)}
      validBtn={false}
      size="lg"
      sizeClass="custom-photo-id-modal"
      showConfirmBtn={false}
      showCancelBtn={false}
    >
      <Row noGutters className="modal-text-row">
        <Col xs={12} className="description-col">
          <Carousel
            activeIndex={active}
            onSelect={handleSelect}
            interval={null}
            fade={false}
            indicators={false}
            className="carousel-photo-id"
          >
            {photosIds.front.length > 0 ? (
              <Carousel.Item className="photo-id-item">
                <img className="photo-id" src={photosIds.front} alt="Front" />
              </Carousel.Item>
            ) : null}
            {photosIds.back.length > 0 ? (
              <Carousel.Item className="photo-id-item">
                <img className="photo-id" src={photosIds.back} alt="Back" />
              </Carousel.Item>
            ) : null}
          </Carousel>
        </Col>
      </Row>
    </StaticModalCustom>
  );
};

export default ModalPhotoId;
