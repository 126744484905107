import { Dispatch } from 'react';
import * as NetWorking from '../netWorking';
import {
  BingoDispatchTypes,
  SET_DISTRICTS,
  SET_POLLING_STATIONS,
  SET_SECTIONS,
} from './bingoTypes';

export const getDistricts = (_id: string, fun?: any) => async (
  dispatch: Dispatch<BingoDispatchTypes>,
) => {
  NetWorking.get(
    NetWorking.getDistrict + _id,
    (res: any) => {
      dispatch({
        type: SET_DISTRICTS,
        data: res.data.response,
      });
      if (fun && res.data.response[0]) {
        fun(res.data.response[0]);
      }
    },
    (error: any) => {
      dispatch({
        type: SET_DISTRICTS,
        data: [],
      });
    },
  );
};

export const getSections = (_id: string, fun?: any) => async (
  dispatch: Dispatch<BingoDispatchTypes>,
) => {
  NetWorking.get(
    NetWorking.getSection + _id,
    (res: any) => {
      dispatch({
        type: SET_SECTIONS,
        data: res.data.response,
      });
      if (fun && res.data.response[0]) {
        fun(res.data.response[0]);
      }
    },
    (error: any) => {
      dispatch({
        type: SET_SECTIONS,
        data: [],
      });
    },
  );
};

export const getPollingStations = (
  cityLicenseId: string,
  district: string,
  section: string,
  fun?: any,
) => async (dispatch: Dispatch<BingoDispatchTypes>) => {
  NetWorking.get(
    NetWorking.getVotersBoxNames +
      cityLicenseId +
      '/' +
      district +
      '/' +
      section,
    (res: any) => {
      dispatch({
        type: SET_POLLING_STATIONS,
        data: res.data.response.map((e: any) => {
          return { _id: e._id, name: e.number };
        }),
      });
      if (fun && res.data.response[0]) {
        fun({
          _id: res.data.response[0]?._id,
          name: res.data.response[0]?.number,
        });
      }
    },
    (error: any) => {
      dispatch({
        type: SET_POLLING_STATIONS,
        data: [],
      });
    },
  );
};
