import React, { Fragment } from "react";
import "../style.scss";
import { Trash, CheckStateIcon, InactiveStateIcon } from '../../../assets/svg/index';
import Form from "react-bootstrap/Form";

interface AnswerItemProps {
    masterIndex: any;
    stepDataAnswers: any;
    setStepData: any;
    stepData: any;
    t: any;
}

export const AnswerItem: React.FunctionComponent<AnswerItemProps> = ({
    masterIndex,
    stepDataAnswers,
    setStepData,
    stepData,
    t,
}: any) => {

    const updateAnswerFieldName = (masterIndex: any, value: any) => {
        let newItems = {...stepData};
        newItems.answers[masterIndex].name = value;
        setStepData(newItems);
    }

    const updateAnswerFieldIsValid = (masterIndex: any, value: any) => {
        let newItems = {...stepData};
        newItems.answers[masterIndex].isValid = value;
        setStepData(newItems);
    }

    const deleteAnswer = (masterIndex: any) => {
        let newItems = {...stepData};
        newItems.answers.splice(masterIndex,1);
        setStepData(newItems);
    };

    return (
        <Fragment>
            <div 
                className="answer-box-row"
                id={`answer-${masterIndex}`}
            >
                <div className="answer-information">
                    <h1>{t('settings.trainings.lbl.answer')+' '+(masterIndex+1)+':'}</h1>
                    <Form.Control
                        className="input-box"
                        value={stepDataAnswers.name}
                        onChange={(data: any) => {
                            updateAnswerFieldName(masterIndex, data.target.value)
                        }} 
                        type="text"
                        placeholder={t("settings.trainings.lbl.answer")}
                    ></Form.Control>
                    <div 
                    className={stepDataAnswers.isValid?"answer-style-button":"answer-style-button active"}
                    onClick={() => {
                        updateAnswerFieldIsValid(masterIndex, false)
                    }}
                    >
                        <InactiveStateIcon></InactiveStateIcon>
                    </div>
                    <div 
                    className={stepDataAnswers.isValid?"answer-style-button active":"answer-style-button"}
                    onClick={() => {
                        updateAnswerFieldIsValid(masterIndex, true)
                    }}
                    >
                        <CheckStateIcon></CheckStateIcon>
                    </div>
                </div>
                <div className="svg-information">
                    <Trash
                    onClick={() => {
                        //fun({}, masterIndex)
                        deleteAnswer(masterIndex)
                    }}
                    ></Trash>
                </div>
            </div>
        </Fragment>
    );
};

export default AnswerItem;
