import React, { FunctionComponent } from 'react';
import * as netWorking from '../../../redux/netWorking';

import StaticModal from '../../../dialogs/StaticModal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

interface ModalDeleteProps {
  modalShow: any;
  setModalShow: any;
  toDelete: any;
  successFun: any;
  votersBox: any;
  deleteMessage: any;
  t: any;
}

export const ModalDelete: FunctionComponent<ModalDeleteProps> = ({
  modalShow,
  setModalShow,
  toDelete,
  votersBox,
  successFun,
  deleteMessage,
  t,
}: any) => {
  const submitFun = () => {
    if (toDelete.length > 0) {
      netWorking.post(
        netWorking.deleteVotersBox,
        {
          toDelete,
        },
        (res: any) => {
          successFun();
          setModalShow(false);
        },
        () => {},
      );
    }
  };

  const getNumbers = () => {
    let text = '';
    if (votersBox !== undefined)
      for (const vB of votersBox) {
        if (toDelete.indexOf(vB._id) !== -1) {
          if (text === '') text = text + ' ' + vB.number;
          else text = text + ', ' + vB.number;
        }
      }
    return text;
  };

  return (
    <StaticModal
      show={modalShow}
      title={t('general.table.delete.button') + ' ' + deleteMessage}
      closeText={t('submit.cancel')}
      confirmText={t('submit.yes.delete')}
      onHide={() => setModalShow(false)}
      submitFun={submitFun}
      validBtn={false}
      size="md"
      sizeClass="md modal-rc"
    >
      <>
        <Container className="body-modal">
          <Row noGutters className="modal-text-row">
            <Col xs={12} className="description-col">
              <div className="text-description">
                {t('settings.polling.modal.delete.body')}
              </div>
              <div className="text-description">{getNumbers()}</div>
            </Col>
          </Row>
        </Container>
      </>
    </StaticModal>
  );
};

export default ModalDelete;
