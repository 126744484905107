import React, { useState } from "react";
import { Usa, Italia, Mexico, Brazil } from "../assets/svg/index";
import { useTranslation } from "react-i18next";

const LanguageSelector = () => {
  const { t, i18n } = useTranslation();
  let value = 0;

  if (t("language") !== undefined) {
    if (t("language") === "es") value = 0;
    if (t("language") === "en") value = 1;
    if (t("language") === "it") value = 2;
    if (t("language") === "pt") value = 3;
  }

  const getFlag = () => {
    if (value === 0) return <Mexico></Mexico>;
    if (value === 1) return <Usa></Usa>;
    if (value === 2) return <Italia></Italia>;
    if (value === 3) return <Brazil></Brazil>;
  };

  const changeLocalLanguage = (e: string) => {
    localStorage.setItem("language", e);
  };

  const [visible, setVisible] = useState(false);

  return (
    <div
      className="lng-selector"
      onClick={(event: any) => {
        setVisible(!visible);
      }}
    >
      {getFlag()}
      {visible && (
        <div className="flags-box">
          <Mexico
            onClick={() => {
              setVisible(!visible);
              i18n.changeLanguage("es");
              changeLocalLanguage("es");
            }}
          ></Mexico>
          <Usa
            onClick={() => {
              setVisible(!visible);
              i18n.changeLanguage("en");
              changeLocalLanguage("en");
            }}
          ></Usa>
          {/* <Italia
            onClick={() => {
              setVisible(!visible);
              i18n.changeLanguage("it");
              props.resetSelector();
            }}
          ></Italia>
          <Brazil
            onClick={() => {
              setVisible(!visible);
              i18n.changeLanguage("pt");
              props.resetSelector();
            }}
          ></Brazil> */}
        </div>
      )}
      {visible && (
        <div
          className="hidden-wall"
          onClick={() => {
            setVisible(!visible);
          }}
        ></div>
      )}
    </div>
  );
};

export default LanguageSelector;
