import React, { useState, useEffect } from "react";
import "../style.scss";
import { useSelector } from 'react-redux';
import { RootStore } from '../../../redux/Store';
import StaticModal from "../../../dialogs/StaticModal";
import Container from 'react-bootstrap/Container';
import { AddActionIcon } from '../../../assets/svg/index';
import Form from 'react-bootstrap/Form';
import AnswerItem from './answerItem';

interface ModalAdminQuestionsProps {
  modalShow: any;
  setModalShow: any;
  training: any;
  fun: any;
  activeKey: any;
  t: any;
  isEdit: any;
  oldIndex: any;
  setOldIndex: any;
  oldKey: any;
  setOldKey: any;
}

export const ModalAdminQuestions: React.FunctionComponent<ModalAdminQuestionsProps> = ({
  modalShow,
  setModalShow,
  training,
  fun,
  activeKey,
  t,
  isEdit,
  oldIndex,
  setOldIndex,
  oldKey,
  setOldKey,
}: any) => {
  const defaultData = {
    answers: [{ name: '', isValid: true }],
    name: '',
  };

  const defaultAnswer = { name: '', isValid: true };

  const [stepData, setStepData] = useState(defaultData);

  const updateQuestionFieldName = (value: any) => {
      let newItem = {...stepData};
      newItem.name = value;
      setStepData(newItem);
  }

  const addAnswerItem = () => {
    let answersArray = { ...stepData };
    answersArray.answers.push(defaultAnswer);
    setStepData(answersArray);
  };

    const submitFun = () => {
        let trainingData = [...training];
        if (oldIndex !== undefined && oldKey !== undefined) {
            trainingData[oldKey].questions[oldIndex] = stepData;
        }else{
            trainingData[activeKey].questions.push(stepData);
        }
        fun(trainingData)
        setOldIndex(undefined)
        setOldKey(undefined)
        setModalShow(false);
    };

  const cancelFun = () => {
    setOldIndex(undefined);
    setOldKey(undefined);
    setModalShow(false);
  };

  useEffect(() => {
    if (oldIndex !== undefined && oldKey !== undefined) {
      let trainingData = [...training];
      setStepData(trainingData[oldKey].questions[oldIndex]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oldIndex, oldKey]);

    useEffect(() => {
        if (oldIndex !== undefined && oldKey !== undefined) {
            let trainingData = [...training];
            setStepData(trainingData[oldKey].questions[oldIndex]);
        }
    }, [oldIndex, oldKey]);
    
    return (
        <StaticModal
        show={modalShow}
        title={oldKey !== undefined && oldIndex !== undefined?t("settings.trainings.form.edit")+ " " +t("settings.trainings.lbl.question"):t("settings.trainings.form.add")+ " " + t("settings.trainings.lbl.question")}
        closeText={t("submit.cancel")}
        confirmText={t("submit.yes")}
        onHide={cancelFun}
        submitFun={submitFun}
        validBtn={false}
        size="lg"
        >
            <Container className="question-body-modal">
                <div className="modal-main-information">
                    <h1>{t('settings.trainings.lbl.question')}</h1>
                    <Form.Control
                        className="input-box"
                        defaultValue={stepData.name}
                        onChange={(data: any) => {
                            updateQuestionFieldName(
                                data.target.value
                            );
                        }}
                        type="text"
                        placeholder={t("settings.trainings.lbl.question")}
                    ></Form.Control>
                </div>
                <div className="modal-answers-section">
                    <div className="answers-header">
                        <h1>{t('settings.trainings.lbl.answers')}</h1>
                        <div 
                        className="add-icon"
                        onClick={() => {
                            addAnswerItem();
                        }}
                        >
                            <AddActionIcon></AddActionIcon>
                        </div>
                    </div>
                    <div className="answers-body">
                        {stepData.answers.map((step: any, index: any) => {
                            return(
                                <AnswerItem
                                    key={index}
                                    masterIndex={index}
                                    stepDataAnswers={step}
                                    setStepData={setStepData}
                                    stepData={stepData}
                                    t={t}
                                >
                                </AnswerItem>
                            )
                        })}
                    </div>
                </div>
            </Container>
        </StaticModal>
    );
};

export default ModalAdminQuestions;
