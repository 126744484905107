import React, { Fragment, useState, useEffect } from 'react';
import { RootStore } from '../../redux/Store';
import { useSelector, useDispatch } from 'react-redux';
import './style.scss';

import moment from 'moment';

import { TAB_CHANGE } from '../../redux/dashboard/dashboardTypes';

import TopSeparator from '../../components/TopSeparator';
import TabComponent from '../../components/Tabs';
import List from './components/List';
import DashboardDDay from './components/dashboardDDay';

const Dashboard: React.FunctionComponent = () => {
  const userData: any = useSelector((globalState: RootStore) => {
    return globalState.core.userData;
  });

  const [isDDay, setIsDDay] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (userData) {
      let now = moment(new Date());
      let end = moment(userData.endDate);
      let duration = moment.duration(end.diff(now));

      if (duration.asMilliseconds() < 0) {
        setIsDDay(true);
      }
    }
  }, [userData]);

  return (
    <Fragment>
      {!isDDay && (
        <>
          <TopSeparator></TopSeparator>
          <List key={(Math.random() * 255).toFixed(0)}></List>
        </>
      )}
      {isDDay && (
        <TabComponent
          classAdditional={''}
          fun={(k: any) => {
            dispatch({
              type: TAB_CHANGE,
              tabChange: k,
            });
          }}
          options={['dashboard.tab.general', 'dashboard.tab.dDay']}
          children={[<List  key={(Math.random() * 255).toFixed(0)}></List>, <DashboardDDay></DashboardDDay>]}
        ></TabComponent>
      )}
    </Fragment>
  );
};

export default Dashboard;
