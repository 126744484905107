import {
  TEAMS_LOADING,
  TEAMS_SUCCESS,
  TEAMS_FAIL,
  TEAMS_DATA_SUCCESS,
  TEAMS_COUNT_SUCCESS,
  TEAMS_LEFT_DATA_SUCCES,
  TEAMS_SELECT_CAPTURIST_SUCCESS,
  TEAMS_COORDINATOR_SUCCESS_ONE,
  TEAMS_TOTAL_CAMPAIGN_SUCCES,
  TEAMS_BREADCUM_SUCCES,
  DeleteCapturistTeam,
  SearchCapturistTeamsByCityDuplicate,
  SearchTeamData,
  SearchLeftData,
  COVERAGE_DATA,
  COVERAGE_LOADING,
} from './teamsTypes';
import * as netWorking from '../netWorking';

export const postCoverageMap = (data: any) => {
  return async (dispatch: any) => {
    dispatch({
      type: COVERAGE_LOADING,
      loading: true,
    });
    return await netWorking.post(
      netWorking.CoverageMap,
      data,
      (res: any) => {
        dispatch({
          type: COVERAGE_LOADING,
          loading: false,
        });
        dispatch({
          type: COVERAGE_DATA,
          coverageMap: res.data.response,
        });
      },
      (error: any) => {
        dispatch({
          type: COVERAGE_LOADING,
          loading: false,
        });
      },
    );
  };
};

export const postCreateUserTeam = (data: any, successFun: any) => {
  return async (dispatch: any) => {
    dispatch({
      type: TEAMS_LOADING,
    });
    return await netWorking.post(
      netWorking.createUsers,
      data,
      (res: any) => {
        successFun(res);
      },
      (error: any) => {
        return dispatch({
          type: TEAMS_FAIL,
        });
      },
    );
  };
};

export const putUpdateUserTeam = (data: any, successFun: any) => {
  return async (dispatch: any) => {
    dispatch({
      type: TEAMS_LOADING,
    });
    return await netWorking.put(
      netWorking.updateCreateUsers,
      data,
      (res: any) => {
        successFun();
      },
      (error: any) => {
        return dispatch({
          type: TEAMS_FAIL,
        });
      },
    );
  };
};

export const putUpdateUserTeamCoordinates = (data: any, successFun: any) => {
  return async (dispatch: any) => {
    dispatch({
      type: TEAMS_LOADING,
    });
    return await netWorking.put(
      netWorking.updateUserCoordinates,
      data,
      (res: any) => {
        successFun();
        dispatch({
          type: TEAMS_FAIL,
        });
      },
      (error: any) => {
        return dispatch({
          type: TEAMS_FAIL,
        });
      },
    );
  };
};

export const postDeleteCapturistTeam = (
  data: DeleteCapturistTeam,
  successFun: any,
) => {
  return async (dispatch: any) => {
    dispatch({
      type: TEAMS_LOADING,
    });
    return await netWorking.post(
      netWorking.deleteCapturistTeam,
      data,
      (res: any) => {
        successFun();
      },
      (error: any) => {
        return dispatch({
          type: TEAMS_FAIL,
        });
      },
    );
  };
};

export const getCoordinatorViewByIdTeam = (
  _id: string,
  followValue: any,
  subLevel: any,
) => {
  return async (dispatch: any) => {
    dispatch({
      type: TEAMS_LOADING,
    });
    return await netWorking.get(
      netWorking.getCoordinatorViewById + _id,
      (res: any) => {
        return dispatch({
          type: TEAMS_COORDINATOR_SUCCESS_ONE,
          payload: res.data.response,
          follow: followValue,
          subLevel: subLevel,
        });
      },
      (error: any) => {
        return dispatch({
          type: TEAMS_FAIL,
        });
      },
    );
  };
};

export const postListTeamMembersLeftSide = (data: SearchLeftData) => {
  return async (dispatch: any) => {
    return await netWorking.post(
      netWorking.postListTeamMembersLeftSide,
      data,
      (res: any) => {
        return dispatch({
          type: TEAMS_LEFT_DATA_SUCCES,
          payload: res.data.response,
        });
      },
      (error: any) => {
        return dispatch({
          type: TEAMS_FAIL,
        });
      },
    );
  };
};

export const postListCapturistTeamsByCity = (
  data: SearchCapturistTeamsByCityDuplicate,
) => {
  return async (dispatch: any) => {
    return await netWorking.post(
      netWorking.postByCityCapturistTeamsDuplicate,
      data,
      (res: any) => {
        return dispatch({
          type: TEAMS_SUCCESS,
          payload: res.data.response,
        });
      },
      (error: any) => {
        return dispatch({
          type: TEAMS_FAIL,
        });
      },
    );
  };
};

export const postGetCapturistTeamDataTotal = (data: SearchTeamData) => {
  return async (dispatch: any) => {
    return await netWorking.post(
      netWorking.getTotalCapturistTeamData,
      data,
      (res: any) => {
        return dispatch({
          type: TEAMS_COUNT_SUCCESS,
          payload: res.data.response,
        });
      },
      (error: any) => {
        return dispatch({
          type: TEAMS_FAIL,
        });
      },
    );
  };
};

export const postCapturistTeamDataByCoordinator = (data: SearchTeamData) => {
  return async (dispatch: any) => {
    dispatch({
      type: TEAMS_LOADING,
    });
    return await netWorking.post(
      netWorking.postByCoordinatorCapturistTeamData,
      data,
      (res: any) => {
        return dispatch({
          type: TEAMS_DATA_SUCCESS,
          payload: res.data.response,
        });
      },
      (error: any) => {
        return dispatch({
          type: TEAMS_FAIL,
        });
      },
    );
  };
};

export const postGetCampaignDataTotal = () => {
  return async (dispatch: any) => {
    return await netWorking.post(
      netWorking.getTotalCampaignTeamData,
      {},
      (res: any) => {
        return dispatch({
          type: TEAMS_TOTAL_CAMPAIGN_SUCCES,
          payload: res.data.response,
        });
      },
      (error: any) => {
        return dispatch({
          type: TEAMS_FAIL,
        });
      },
    );
  };
};

export const postBreadcumByCoordinator = (levelQty: any, successFun?: any) => {
  return async (dispatch: any) => {
    return await netWorking.post(
      netWorking.getBreadcumByCoordinator,
      {
        levelQty: levelQty,
      },
      (res: any) => {
        dispatch({
          type: TEAMS_BREADCUM_SUCCES,
          payload: res.data.response,
        });
        if (successFun) {
          successFun();
        }
      },
      (error: any) => {
        return dispatch({
          type: TEAMS_FAIL,
        });
      },
    );
  };
};

export const setCapturistSelected = (selectedCapturist: any) => {
  return (dispatch: any) => {
    dispatch({
      type: TEAMS_SELECT_CAPTURIST_SUCCESS,
      selectedCapturist,
    });
  };
};
