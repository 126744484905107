export const INCIDENCE_LOADING = 'INCIDENCE_LOADING';
export const INCIDENCE_FAIL = 'INCIDENCE_FAIL';
export const INCIDENCE_ALL = 'INCIDENCE_ALL';
export const INCIDENCE_CREATE = 'INCIDENCE_CREATE';
export const INCIDENCE_UPDATE = 'INCIDENCE_UPDATE';
export const INCIDENCE_SELECTED = 'INCIDENCE_SELECTED';

export interface IncidenceLoading {
  type: typeof INCIDENCE_LOADING;
}
export interface IncidenceFail {
  type: typeof INCIDENCE_FAIL;
}
export interface IncidenceAllSuccess {
  type: typeof INCIDENCE_ALL;
  payload: object;
}
export interface IncidenceCreateSuccess {
  type: typeof INCIDENCE_CREATE;
  data: any;
}
export interface IncidenceUpdateSuccess {
  type: typeof INCIDENCE_UPDATE;
  data: any;
}
export interface IncidenceSelectedSuccess {
  type: typeof INCIDENCE_SELECTED;
  payload: object;
}

export type IncidenceDispatchTypes =
  | IncidenceLoading
  | IncidenceFail
  | IncidenceAllSuccess
  | IncidenceCreateSuccess
  | IncidenceUpdateSuccess
  | IncidenceSelectedSuccess;
