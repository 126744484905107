import React, { Fragment } from 'react';
import './style.scss';

import TabComponent from '../../components/Tabs';
import List from './components/List';
import Map from './components/Map';

const VotersBoxDDay: React.FunctionComponent = () => {
  return (
    <Fragment>
      <TabComponent
        classAdditional={''}
        options={['settings.polling.tab.1', 'settings.polling.tab.2']}
        children={[<List></List>, <Map></Map>]}
      ></TabComponent>
    </Fragment>
  );
};

export default VotersBoxDDay;
