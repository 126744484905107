import { Party } from './../parties/partiesTypes';
import { ROLE } from '../../utils/utils';
import { Team } from '../RgRc/RgRcTypes';

export const COUNTRY_LOADING = 'COUNTRY_LOADING';
export const STATE_LOADING = 'STATE_LOADING';
export const MUNICIPALITY_LOADING = 'MUNICIPALITY_LOADING';
export const DISTRICT_LOADING = 'DISTRICT_LOADING';
export const SECTION_LOADING = 'SECTION_LOADING';

export const COUNTRY_FAIL = 'COUNTRY_FAIL';
export const COUNTRY_SUCCESS = 'COUNTRY_SUCCESS';
export const STATE_FAIL = 'STATE_FAIL';
export const STATE_SUCCESS = 'STATE_SUCCESS';
export const MUNICIPALITY_FAIL = 'MUNICIPALITY_FAIL';
export const MUNICIPALITY_SUCCESS = 'MUNICIPALITY_SUCCESS';
export const DISTRICT_FAIL = 'DISTRICT_FAIL';
export const DISTRICT_SUCCESS = 'DISTRICT_SUCCESS';
export const SECTION_FAIL = 'SECTION_FAIL';
export const SECTION_SUCCESS = 'SECTION_SUCCESS';

export const SET_GLOBAL = 'SET_GLOBAL';
export const SET_GLOBAL_SELECTED = 'SET_GLOBAL_SELECTED';

export type citiesLogin = {
  _id: string;
  admins: string[];
  stateId: string;
  defaultZoom: number;
  defaultCenter: any;
  modules: string[];
  capturistUserLevels: {
    en: string;
    es: string;
    role: string;
    level: number;
  }[];
  name: string;
  municipalityId: string;
  active: boolean;
  geoLimits: any;
  startDatePollingStation: string;
  endDatePollingStation: string;
  timeZones: any[];
};

export type statesLogin = {
  _id: string;
  countryId: string;
  description: string;
  geoCoordinates: any;
  name: string;
};

export type DecodedToken = {
  _id: string;
  campaignId: string;
  cities: citiesLogin[];
  states: statesLogin[];
  email: string;
  exp: number;
  firstName: string;
  lastName: string;
  iat: number;
  role: string;
  level: number;
  teamName: string;
  focus: string;
  team: Team;
  candidate: string;
};

export type Country = {
  _id: string;
  name: string;
  description: string;
  countryCode: string;
};

export type State = {
  _id: string;
  name: string;
  description: string;
  countryId: string;
  geoCoordinates: any;
};

export type Municipality = {
  _id: string;
  name: string;
  description: string;
  stateId: string;
  geoCoordinates: any;
};

export type District = {
  _id: string;
  name: string;
  description: string;
  municipalityId: string;
};

export type Section = {
  _id: string;
  name: string;
  description: string;
  districtId: string;
};
//
export interface CountryLoading {
  type: typeof COUNTRY_LOADING;
}

export interface CountryFail {
  type: typeof COUNTRY_FAIL;
}

export interface CountrySuccess {
  type: typeof COUNTRY_SUCCESS;
  payload: Country[];
}
//
export interface StateLoading {
  type: typeof STATE_LOADING;
}

export interface StateFail {
  type: typeof STATE_FAIL;
}

export interface StateSuccess {
  type: typeof STATE_SUCCESS;
  payload: State[];
}
//
export interface MunicipalityLoading {
  type: typeof MUNICIPALITY_LOADING;
}

export interface MunicipalityFail {
  type: typeof MUNICIPALITY_FAIL;
}

export interface MunicipalitySuccess {
  type: typeof MUNICIPALITY_SUCCESS;
  payload: Municipality[];
}
//
export interface DistrictLoading {
  type: typeof DISTRICT_LOADING;
}

export interface DistrictFail {
  type: typeof DISTRICT_FAIL;
}

export interface DistrictSuccess {
  type: typeof DISTRICT_SUCCESS;
  payload: District[];
}
//
export interface SectionLoading {
  type: typeof SECTION_LOADING;
}

export interface SectionFail {
  type: typeof SECTION_FAIL;
}

export interface SectionSuccess {
  type: typeof SECTION_SUCCESS;
  payload: Section[];
}

export interface globalSet {
  type: typeof SET_GLOBAL;
  state: statesLogin[];
  municipality: citiesLogin[];
  userData: DecodedToken;
}

export interface globalSetSelected {
  type: typeof SET_GLOBAL_SELECTED;
  state: statesLogin;
  municipality: citiesLogin;
}

export interface User {
  _id: string;
  blocked: boolean;
  blockedReason: string;
  campaignId: string;
  email: string;
  lastName: string;
  firstName: string;
  role: ROLE;
  extra?: any;
  team?: Team;
  name?: string;
}

export type CoreDispatchTypes =
  | CountryLoading
  | CountryFail
  | CountrySuccess
  | StateLoading
  | StateFail
  | StateSuccess
  | MunicipalityLoading
  | MunicipalityFail
  | MunicipalitySuccess
  | DistrictLoading
  | DistrictFail
  | DistrictSuccess
  | SectionLoading
  | SectionFail
  | SectionSuccess
  | globalSet
  | globalSetSelected;
