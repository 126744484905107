import './App.css';
import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect } from 'react';
import './App.css';
import { CheckAuthentication } from './utils/CheckAuthentication';
import SwitchRoute from './utils/switchRoute';
import { Provider } from 'react-redux';
import Store from './redux/Store';
import { useTranslation } from 'react-i18next';
import './i18n.js';
import { logoutUser } from './redux/User/userActions';
import store from './redux/Store';
import { SET_SUPER_ADMIN, SET_UNAUTHENTICATED } from './redux/types';
import { ContextWs, WSProvider } from './redux/socket/wsContext';

const App: React.FC = () => {
  const { i18n } = useTranslation();
  useEffect(() => {
    CheckAuthentication();
    const language = localStorage.getItem('language');
    if (language !== undefined && language !== null) {
      i18n.changeLanguage(language);
    }
  }, [i18n]);

  window.addEventListener('storage', function (event) {
    if (event.key === 'logoutEvent') {
      logoutUser();
      store.dispatch({
        type: SET_UNAUTHENTICATED,
      });
      store.dispatch({
        type: SET_SUPER_ADMIN,
        payload: false,
      });
    }
  });

  return (
    <div className="App">
      <Provider store={Store}>
        <WSProvider>
          <SwitchRoute></SwitchRoute>{' '}
        </WSProvider>
      </Provider>
    </div>
  );
};
export default App;
