import { XLS_TYPE } from './../utils/utils';
import axios from 'axios';
export const WEB = process.env.REACT_APP_WEB;
export const URL = process.env.REACT_APP_URL;

/// EndPoints
export const getUser = 'users/find/';
export const getUserByCampaign = 'users/findByCampaign/';
export const checkInUse = 'users/inUse';
export const loginUser = 'auth/login';
export const CheckCode = 'users/checkCode';
export const ConfirmPassword = 'users/confirmPassword';
export const SendEmailForgot = 'users/sendEmail';
export const checkCellphone = 'users/checkCellphone';
export const resetPwd = 'users/resetPwd';
// FILES
export const UpData = 'files/upData';
export const CreateUpData = 'files/createUpData';
export const GetUpData = 'files/upData/';
export const generateBingoDemo = 'files/generateBingoDemo';
export const GetTemporalVoters = 'files/getVoters/';
export const GetTemporalVotersDuplicates = 'files/getVoters/duplicates/';
export const CheckUpData = 'files/checkUpData';
export const ReplaceVoters = 'files/replaceVoters';
export const ForceReplaceVoters = 'files/force/replaceVoters';
export const deleteTempVoters = 'files/deleteTempVoters';
export const deleteUpData = 'files/deleteUpData/';
export const ApproveUpData = 'files/approvedData';
export const Upload = 'files/upload';
export const UploadTeam = 'files/uploadTeam';
export const UploadVoterBox = 'files/uploadVoterBox';
export const ConfirmUpload = 'files/confirmUpload';
export const DowloadSampleXLS = 'files/download';
export const UploadVotersXLSAuditory = 'files/uploadVotersXLS/';

// License Manager
export const getLicenses = 'campaign/';
export const postLicense = 'campaign/';
export const specialToken = 'specialToken';

// CORE
export const getCountriesWithCampaignsCount =
  'core/getCountriesWithCampaignsCount/';
export const createCountry = 'core/createCountry/';
export const createState = 'core/createState/';
export const createMunicipality = 'core/createMunicipality/';
export const deleteState = 'core/deleteState/';
export const deleteMunicipality = 'core/deleteMunicipality/';

export const getCountries = 'core/getCountries/';
export const getState = 'core/getState/';
export const getMunicipality = 'core/getMunicipality/';
export const getDistrict = 'core/getDistrict/';
export const getComplete = 'core/getDistrictComplete/';
export const getSection = 'core/getSection/';
export const getParty = 'party/';
export const postParty = 'party/';
export const uploadParty = 'party/upload';
export const problems = 'core/problems';
export const assignNecessary = 'core/postUpdateNecessaryValue';

// Voters Box
export const getVotersBoxNames = 'pollingStations/names/';
export const getVotersBoxByCoordinates =
  'pollingStations/postVoterBoxesByCoordinates';
export const getTotalVotersBoxByCoordinates =
  'pollingStations/postTotalVoterBoxesByCoordinates';
export const getVotersBoxBySection =
  'pollingStations/postVoterBoxesByMunDisSec';
export const getTotalVotersBoxBySection =
  'pollingStations/postTotalVoterBoxesByMunDisSec';
export const getNumberInUse = 'pollingStations/numberInUse';
export const getDistrictsInUse = 'pollingStations/postDistrictsInUse';
export const getSectionsInUse = 'pollingStations/postSecionsInUse';
//=======================================================================
export const getVotersBoxElectoralByCoordinates =
  'pollingStations/postVoterBoxesElectoralByCoordinates';
export const getTotalVotersBoxElectoralByCoordinates =
  'pollingStations/postTotalVoterBoxesElectoralByCoordinates';
//=======================================================================
export const getVotersBoxByPage = 'pollingStations/';
export const deleteVotersBox = 'pollingStations/delete';
export const getCountVotersBox = 'pollingStations/getCount/';
export const searchVotersBox = 'pollingStations/search/';
export const getVotersBoxBySectionElectoral = 'pollingStations/Electoral/';
export const searchVotersBoxElectoral = 'pollingStations/Electoral/search/';
export const postAssignVoterBoxGeneral =
  'pollingStations/postAssignVoterBoxGeneral/';
export const postVotersBoxByRgSection =
  'pollingStations/postVotersBoxByRgSection/';
export const postVotersBoxUnassignRC =
  'pollingStations/postVotersBoxUnassignRC/';

// Rc & Rg
export const getRcs = 'users/rc/';
export const getRgs = 'users/rg/';
export const getRgsSearch = 'users/rg/search';
export const getRgsExclude = 'users/rg/exclude';
export const getTeams = 'teams/rcrg/';
export const createUpdateTeam = 'teams/';
export const getTeamsData = 'teams/rcrg/data';
export const updateRcRg = 'users/update/rcrg/voterbox';
export const createUsers = 'users/create';
export const CoverageMap = 'users/getCoverageMap';
export const updateCreateUsers = 'users';
export const updateUserCoordinates = 'users/updateCoordinates';
export const deleteRgRc = 'users/delete';
export const getRcWithoutTeam = 'teams/rcrg/withoutAssign/';
export const assignToTeam = 'teams/assignToTeam';

//======================================
export const postByCityRCsTeams = 'teams/rcsTeamsDataByCity';
export const postByRGRCsTeamData = 'teams/rcsTeamDataByRG';
export const getTotalRCsTeamData = 'teams/totalRCsTeamData';
export const getRGData = 'teams/rgData';
export const getRcsWithoutAssign = 'teams/rgrc/withoutAssign';
export const createForRgRcCpCb = 'users/createForRgRcCpCb';
export const updateForRgRcCpCb = 'users/updateForRgRcCpCb';
export const getTotalRCs = 'teams/totalRCs';

//====Users
export const postByStateCityUsers = 'users/byStateCityUsers';
export const postByStateCityUsersTotal = 'users/byStateCityUsersTotal';
export const deleteUser = 'users/deleteUser';
export const createUser = 'users/createUser';
export const updateUser = 'users/updateUser';
export const updateStatusUser = 'users/changeStatus';
export const blockUser = 'users/block';

// Trainings
export const getTrainings = 'trainings';
export const getTrainingsByRole = 'trainings/getTrainingsByRole';
export const getTraining = 'trainings/';
export const postTraining = 'trainings/create';
export const putTraining = 'trainings/update';
export const postTrainingUploadFile = 'trainings/uploadFile';
export const deleteTraining = 'trainings/delete/';
export const getHistoryByUserId = 'trainings/getHistoryByUserId/';

// Voters
export const getVoters = 'voters';
export const getVoterById = 'voters/';
export const postGetTotalVoters = 'voters/totalVoters';
export const postVotersByAddressElectionsSearch =
  'voters/byAddressElectionsSearch';
export const postConcentratedVoters = 'voters/concentratedVoters';
export const getHeatMap = 'voters/getHeatMap/';

//====Auditing
export const postGetUnauditedVotersTotal = 'voters/totalUnauditedVoters';
export const postVotersByAddressElectionsUnauditedSearch =
  'voters/byAddressElectionsUnauditedSearch';
export const postConfirmVoterById = 'voters/auditory/confirmVoter';
export const postDenyVoterById = 'voters/auditory/denyVoter';
export const getVoterDuplicatesById = 'voters/auditory/duplicates/';

// Capturits
export const getCapturistByType = 'teams/capturistsByType/';
export const getTeamsCapturist = 'teams/capturists/';
export const getCapturistTeamsData = 'teams/capturists/data';
export const getCapturistsListByCitySearch = 'teams/byCitySearch/';
export const removeCpCbRgRcFromTeam = 'teams/removeCpCbRgRcFromTeam';
export const addCpCbRgRcFromTeam = 'teams/addCpCbRgRcFromTeam';
export const getCapturistsWithoutAssign = 'teams/capturists/withoutAssign';
export const deleteCapturistTeam = 'teams/capturists/removeTeam';
export const generateCode = 'users/createNewPin';
//======================================
export const postByCityCapturistTeams = 'teams/capturistTeamsByCity';
export const postByCityCapturistTeamsDuplicate =
  'teams/capturistTeamsByCityDuplicate';
export const postByCoordinatorCapturistTeamData =
  'teams/capturistTeamDataByCoordinator';
export const postListTeamMembersLeftSide = 'teams/capturistTeamMembersLeftSide';
export const getTotalCapturistTeamData = 'teams/totalCapturistTeamData';
export const getTotalCampaignTeamData = 'teams/totalCampaignTeamData';
export const getBreadcumByCoordinator = 'teams/getBreadcumByCoordinator';
export const getBossCapturistData = 'teams/bossCapturistData';
export const getCpCbRgRcTeamMembers = 'teams/getCpCbRgRcTeamMembers';
export const getCoordinatorViewById = 'teams/';

// Routes
export const getByCoordinator = 'routing/byCoordinator/';
export const getAreasByUser = 'routing/';
export const getAreasByUsers = 'routing/getAreasByUsers';
export const postSearchCoordinatesData = 'routing/searchCoordinatesData';
export const postCreateRoutes = 'routing/create';
export const putListRoutes = 'routing/updateArray';
export const postUpdateRouteState = 'routing/updateRouteState';
export const deleteRoute = 'routing/delete/';

// trackers
export const activeDates = 'tracker/activeDates';
export const postMarkPointIncidence = 'tracker/markPointIncidence';
export const getTrackerByUserIdAndDate = 'tracker/byUserIdAndDate';
export const getDataOfAreaOfTrackers = 'tracker/getDataByArea';

//logs
export const getLogs = 'logs/get';
export const getLogsFiles = 'logs/files';

//lawyers
export const postByCityLawyers = 'teams/byCityLawyers';
export const getByCityTotalLawyers = 'teams/byCityTotalLawyers';
export const postListAllLawyersByCampaign =
  'teams/postListAllLawyersByCampaign';

//incidence
export const postAllIncidences = 'incidence/allByCampaign/';
export const postAssignProblemGeneral = 'incidence/postAssignProblemGeneral';
export const getPdfIncidence = 'incidence/pdf/';

//dashboard
export const postGetLineChartData = 'voters/lineChartData';
export const postGetPieChartData = 'voters/pieChartData';

export const get = (endpoint: string, fun: any, errorFun: any, data?: any) => {
  try {
    axios
      .get(URL + endpoint, {
        headers: {
          'Access-Control-Allow-Origin': `${WEB}`,
          crossdomain: true,
        },
        data: data,
      })
      .then((response) => {
        if (fun !== undefined) fun(response);
      })
      .catch((error) => {
        errorFun(error);
      });
  } catch (e) {
    errorFun(e);
  }
};

export const post = (
  endpoint: string,
  data: object,
  fun: any,
  errorFun: any,
) => {
  axios
    .post(URL + endpoint, data, {
      headers: {
        'Access-Control-Allow-Origin': `${WEB}`,
        crossdomain: true,
      },
    })
    .then((response) => {
      if (fun !== undefined) fun(response);
    })
    .catch((error) => {
      if (errorFun !== undefined) errorFun(error);
    });
};

export const put = (
  endpoint: string,
  data: object,
  fun: any,
  errorFun: any,
) => {
  axios
    .put(URL + endpoint, data, {
      headers: {
        'Access-Control-Allow-Origin': `${WEB}`,
        crossdomain: true,
      },
    })
    .then((response) => {
      if (fun !== undefined) fun(response);
    })
    .catch((error) => {
      if (errorFun !== undefined) errorFun(error);
    });
};

export const deleteRequest = (endpoint: string, fun: any, errorFun: any) => {
  axios
    .delete(URL + endpoint, {
      headers: {
        'Access-Control-Allow-Origin': `${WEB}`,
        crossdomain: true,
      },
    })
    .then((response) => {
      if (fun !== undefined) fun(response);
    })
    .catch((error) => {
      if (errorFun !== undefined) errorFun(error);
    });
};

export const sendRequest = (
  endpoint: string,
  formData: any,
  uploadProgress: any,
  setUploadStatus: any,
  setRouteFile: any,
  blob: boolean = false,
  setUploadProblem?: any,
  errorFun?: any,
) => {
  let header: any = {
    headers: {
      'Access-Control-Allow-Origin': `${WEB}`,
      crossdomain: true,
    },
    onUploadProgress: (ev: ProgressEvent) => {
      const progress = (ev.loaded / ev.total) * 100;
      uploadProgress(Math.round(progress));
    },
  };
  if (blob) {
    header = { ...header, responseType: 'blob' };
  }
  axios
    .post(URL + endpoint, formData, header)
    .then((resp) => {
      // our mocked response will always return true
      // in practice, you would want to use the actual response object
      setUploadStatus('DONE');
      if (setUploadProblem !== undefined) setUploadProblem(undefined);
      //if (resFun !== undefined) resFun(resp);
      if (!blob) {
        setRouteFile(resp.data.response.route);
      } else {
        setRouteFile(resp);
      }
    })
    .catch((err) => {
      setUploadStatus('NONE');
      if (errorFun !== undefined) errorFun(err);
      console.error(err);
    });
};

export const downloadXls = (type: any, cityName: string) => {
  axios
    .get(URL + DowloadSampleXLS + '/' + type, {
      headers: {
        'Access-Control-Allow-Origin': `${WEB}`,
        crossdomain: true,
      },
      responseType: 'blob',
    })
    .then((resp) => {
      // our mocked response will always return true
      // in practice, you would want to use the actual response object

      const url = window.URL.createObjectURL(new Blob([resp.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', getNameSample(type, cityName)); //or any other extension
      document.body.appendChild(link);
      link.click();
      link.remove();
    })
    .catch((err) => {
      console.error(err);
    });
};

const getNameSample = (type: number, cityName: string) => {
  if (type === XLS_TYPE.VOTERS_BOX_XLS_TYPE) {
    return 'PollingStationTemplate.xlsx';
  } else if (type === XLS_TYPE.RG_RC_XLS_TYPE) {
    return 'RGRCTemplate.xlsx';
  } else if (type === XLS_TYPE.VOTERS_TYPE) {
    return cityName + ' Voters.xlsx';
  } else if (type === XLS_TYPE.CAPTURIST_XLS_TYPE) {
    return cityName + ' Territorial.xlsx';
  } else if (type === XLS_TYPE.CAPTURIST_LV1_XLS_TYPE) {
    return cityName + ' TerritorialLv1.xlsx';
  } else if (type === XLS_TYPE.CAPTURIST_BOSS_XLS_TYPE) {
    return cityName + ' TerritorialAdmin.xlsx';
  } else if (type === XLS_TYPE.VB_ASSIGN_RGRC_XLS_TYPE) {
    return 'AssignRGRCTemplate.xlsx';
  } else {
    return 'Sample.xlsx';
  }
};
