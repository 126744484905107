import React, { Fragment, useEffect, createRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../redux/Store';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import '../styleDDay.scss';

import {
  DASHBOARD_D_SELECTED,
  DASHBOARD_D_SEARCH_SUCCESS,
} from '../../../redux/dashboard/dashboardTypes';
import { numberWithCommas } from '../../../utils/utils';
import { citiesLogin } from '../../../redux/core/CoreTypes';
import { onlyLettersNumbersSpaces } from '../../../utils/utils';

import SearchFormComponent from '../../../components/sub-component/searchForm';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import ProgressBar from 'react-bootstrap/ProgressBar';
import 'react-circular-progressbar/dist/styles.css';

enum AMOUNT_TYPES {
  FOR_WIN = 'FOR_WIN',
  EXPECTED = 'EXPECTED',
  DONE = 'DONE',
  MISSING = 'MISSING',
  INCIDENCE = 'INCIDENCE',
}

const DashboardDDay: React.FunctionComponent = () => {
  const citySelected: citiesLogin | undefined = useSelector(
    (globalState: RootStore) => {
      return globalState.core.globalMunicipalitySelected;
    },
  );

  const districtSelected: any = useSelector((globalState: RootStore) => {
    return globalState.dashboard.selectedD;
  });

  const dashboardData: any = useSelector((globalState: RootStore) => {
    return globalState.dashboard.dashboardData;
  });

  const search: any = useSelector((globalState: RootStore) => {
    return globalState.dashboard.searchD;
  });

  let defaultArray: any = [];

  const ref = createRef<HTMLDivElement>();
  const [style, updateStyle] = useState(`
    .d-d-d {
      width: calc(100% - ${ref.current?.clientWidth}px);
    }
  `);

  useEffect(() => {
    updateStyle(`
    .d-d-d {
      width: calc(100% - ${ref.current?.clientWidth}px);
    }
  `);
  }, [ref]);

  const [bingoRoomHook, setBingoRoom] = useState<string>('');

  const [counter, setCounter] = React.useState(2);
  const [toggle, setToggle] = React.useState(false);
  const [data, setData] = React.useState('');
  const [enterOn, setEnterOn] = useState(false);

  const [dateGlobal, setDate] = useState(moment(Date.now()).toDate());
  const [toCloseArray, setToCloseArray] = useState(defaultArray);
  const [alreadyCloseArray, setAlreadyCloseArray] = useState(defaultArray);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  React.useEffect(() => {
    setTimeout(() => {
      setDate(moment(Date.now()).toDate());
      let auxToCloseArray: any = [];
      let auxAlreadyCloseArray: any = [];

      citySelected?.timeZones.map((d: any, i: any) => {
        const time = moment(dateGlobal);

        if (citySelected?.endDatePollingStation) {
          const endDate = citySelected?.endDatePollingStation.split(':');
          const withTZ = moment
            .tz(dateGlobal.toString(), d._id)
            .hour(+endDate[0] || 0)
            .minute(+endDate[1] || 0);

          const time1 = moment.duration(withTZ.diff(time));

          const hours = time1.hours();
          const minutes = time1.minutes();

          let newElement = {
            _id: d._id,
            offset: d.offset,
            hours: hours,
            minutes: minutes,
          };

          if (time1.asMilliseconds() > 0) {
            auxToCloseArray.push(newElement);
          } else {
            auxAlreadyCloseArray.push(newElement);
          }
        }
      });

      setToCloseArray(auxToCloseArray);
      setAlreadyCloseArray(auxAlreadyCloseArray);
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateGlobal]);

  const goToView = (_id: string) => {
    const district = dashboardData?.find((value: any) => value._id === _id);

    dispatch({
      type: DASHBOARD_D_SELECTED,
      payload: district,
    });
  };

  const getClassColor = () => {
    let counts = {
      forWin: 0,
      toWin: 0,
      necessaryVote: 0,
      totalMarked: 0,
      total: 0,
      incidence: 0,
      available: 0,
      ourVotes: 0,
    };
    for (const data of dashboardData) {
      for (const polling of data.pollingStations) {
        counts.forWin = counts.forWin + (polling.forWin || 0);
        counts.necessaryVote =
          counts.necessaryVote + (polling.necessaryVote || 0);
        counts.totalMarked = counts.totalMarked + (polling.totalMarked || 0);
        counts.total = counts.total + (polling.total || 0);
        counts.toWin = counts.toWin + (polling.toWin || 0);
        counts.available = counts.available + (polling.available || 0);
        counts.ourVotes = counts.ourVotes + (polling.ourVotes || 0);
      }
      counts.incidence = counts.incidence + (data.incidence || 0);
    }
    if (counts.ourVotes < 0) {
      counts.ourVotes = counts.toWin + Math.abs(counts.ourVotes);
    }
    const porcentage = Math.round((counts.ourVotes * 100) / counts.toWin);
    if (porcentage < 80) {
      return 'red-bg';
    } else if (porcentage >= 81 && porcentage <= 94) {
      return 'orange-bg';
    } else if (porcentage >= 95 && porcentage <= 99) {
      return 'green-bg';
    } else if (porcentage >= 100) {
      return 'gold-bg';
    }
  };

  const getColor = (value: number, total: number) => {
    let porcentage = Math.round((value * 100) / total);
    if (isNaN(porcentage)) {
      porcentage = 0;
    }
    if (porcentage < 80) {
      return '#e7495d';
    } else if (porcentage >= 81 && porcentage <= 94) {
      return '#f29100';
    } else if (porcentage >= 95 && porcentage <= 99) {
      return '#2fad74';
    } else if (porcentage >= 100) {
      return '#ffd700';
    }
  };

  const getListElement = (value: any) => {
    let counts = {
      forWin: 0,
      toWin: 0,
      necessaryVote: 0,
      totalMarked: 0,
      total: 0,
      incidence: 0,
      ourVotes: 0,
      available: 0,
    };
    for (const polling of value.pollingStations) {
      counts.forWin = counts.forWin + (polling.forWin || 0);
      counts.necessaryVote =
        counts.necessaryVote + (polling.necessaryVote || 0);
      counts.totalMarked = counts.totalMarked + (polling.totalMarked || 0);
      counts.total = counts.total + (polling.total || 0);
      counts.toWin = counts.toWin + (polling.toWin || 0);
      counts.available = counts.available + (polling.available || 0);
      counts.ourVotes = counts.ourVotes + (polling.ourVotes || 0);
    }
    counts.incidence = counts.incidence + (value.incidence || 0);
    let porcentageValue = 0;
    porcentageValue = (+counts.ourVotes * 100) / +counts.total;
    if (isNaN(porcentageValue)) {
      porcentageValue = 0;
    }
    // console.log('value', porcentageValue);
    return (
      <div
        className="list-element"
        key={'list-element' + value._id}
        onClick={() => {
          if (!districtSelected._id) goToView(value._id);
        }}
      >
        <p>
          {(districtSelected._id
            ? t('dashboard.lbl.section')
            : t('dashboard.lbl.district')) +
            ' ' +
            value.name}
        </p>
        {/* <div className="progress-circle">
          {porcentageValue ? Math.round(porcentageValue) + '%' : '0%'}
        </div> */}
        <div className="svg-progress">
          <CircularProgressbar
            value={porcentageValue}
            strokeWidth={15}
            text={`${Math.round(porcentageValue)}%`}
            styles={buildStyles({
              pathTransitionDuration: 0.15,
              strokeLinecap: 'butt',
              pathColor: getColor(counts.ourVotes, counts.toWin),
              textColor: getColor(counts.ourVotes, counts.toWin),
            })}
          />
        </div>

        <p>
          {+counts.totalMarked +
            ' ' +
            t('dashboard.lbl.of') +
            ' ' +
            +counts.total}
        </p>
      </div>
    );
  };

  const getCount = () => {
    let value = 0;
    let counts = {
      forWin: 0,
      toWin: 0,
      necessaryVote: 0,
      totalMarked: 0,
      total: 0,
      incidence: 0,
      available: 0,
      ourVotes: 0,
    };
    for (const data of dashboardData) {
      for (const polling of data.pollingStations) {
        counts.forWin = counts.forWin + (polling.forWin || 0);
        counts.necessaryVote =
          counts.necessaryVote + (polling.necessaryVote || 0);
        counts.totalMarked = counts.totalMarked + (polling.totalMarked || 0);
        counts.total = counts.total + (polling.total || 0);
        counts.toWin = counts.toWin + (polling.toWin || 0);
        counts.available = counts.available + (polling.available || 0);
        counts.ourVotes = counts.ourVotes + (polling.ourVotes || 0);
      }
      counts.incidence = counts.incidence + (data.incidence || 0);
    }
    // console.log('counts', counts);
    // switch (type) {
    //   case AMOUNT_TYPES.FOR_WIN:
    //     return {
    //       forWin: counts.forWin,
    //       ourVotes: counts.ourVotes,
    //       toWin: counts.toWin,
    //     };
    //   case AMOUNT_TYPES.EXPECTED:
    //     return counts.toWin;
    //   case AMOUNT_TYPES.DONE:
    //     return counts.totalMarked;
    //   case AMOUNT_TYPES.MISSING:
    //     return +(counts.total - counts.totalMarked);
    //   case AMOUNT_TYPES.INCIDENCE:
    //     return counts.incidence;
    //   default:
    //     return 0;
    // }
    return counts;
  };

  const getAmountBox = (type: AMOUNT_TYPES) => {
    let title = '';
    let value = 0;
    let response: any = getCount();
    if (type === AMOUNT_TYPES.FOR_WIN && response.forWin < 0) {
      value = Math.abs(response.forWin);
    }
    switch (type) {
      case AMOUNT_TYPES.FOR_WIN:
        title = t('dashboard.lbl.missing');
        if (response.forWin < 0) {
          value = Math.abs(response.forWin);
        } else {
          value = response.forWin;
        }
        break;
      case AMOUNT_TYPES.EXPECTED:
        title = t('dashboard.lbl.necesary');
        value = response.toWin;
        break;
      case AMOUNT_TYPES.DONE:
        title = t('dashboard.dday.lbl.voted');
        value = response.ourVotes;
        break;
      case AMOUNT_TYPES.MISSING:
        title = t('dashboard.dday.lbl.noVoted');
        value = +(response.total - response.totalMarked);
        break;
      case AMOUNT_TYPES.INCIDENCE:
        title = t('dashboard.dday.lbl.problems');
        value = response.incidence;
        break;
      default:
        break;
    }

    return (
      <div className="amount-box">
        <div className="title">
          <p>{title}</p>
        </div>
        <div
          className="amount-text"
          style={{
            background:
              type === AMOUNT_TYPES.FOR_WIN
                ? getColor(response.ourVotes, response.toWin)
                : 'transparent',
          }}
        >
          <h2
            className={
              type === AMOUNT_TYPES.FOR_WIN ? 'amount for-win' : 'amount'
            }
            style={{
              color: type === AMOUNT_TYPES.FOR_WIN ? 'white' : 'black',
            }}
          >
            {numberWithCommas(value ? value : 0)}
          </h2>
        </div>

        {type === AMOUNT_TYPES.DONE && <span>{response.available}</span>}
        {type === AMOUNT_TYPES.MISSING && <span>{response.total}</span>}
      </div>
    );
  };

  const getListTimeZonesTime = () => {
    /* let map = new Map();
    for (const data of dashboardData) {
      for (const polling of data.pollingStations) {
        if (polling.status === 'OPEN')
          map.set(polling.timeZone, (map.get(polling.timeZone) || 0) + 1);
      }
    } */
    return (
      <div className="timezones-box" ref={ref}>
        {citySelected?.endDatePollingStation && toCloseArray.length > 0 && (
          <div className="title">
            <ul>
              <li>{t('dashboard.dday.lbl.openPool')}</li>
            </ul>
          </div>
        )}
        <div className="list-timezones">
          {toCloseArray.map((d: any, i: any) => {
            return (
              <div className={'timezone-item'} key={'tz-' + i}>
                <p>
                  {d._id + ' [ ' + d.offset?.split(':')[0] + ' ] '}
                  {' >> '}
                  <span>{d.hours + 'h' + ' ' + d.minutes + 'm'}</span>
                </p>
              </div>
            );
          })}
        </div>

        {citySelected?.endDatePollingStation && alreadyCloseArray.length > 0 && (
          <div className="title">
            <ul>
              <li>{t('dashboard.dday.lbl.closedPool')}</li>
            </ul>
          </div>
        )}
        <div className="list-timezones">
          {alreadyCloseArray.map((d: any, i: any) => {
            return (
              <div className={'timezone-item'} key={'tz-' + i}>
                <p>
                  {d._id + ' [ ' + d.offset?.split(':')[0] + ' ] '}
                  {' >> '}
                  <span>{d.hours + 'h' + ' ' + d.minutes + 'm'}</span>
                </p>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const onChange = (data: any) => {
    dispatch({ type: DASHBOARD_D_SEARCH_SUCCESS, searchValue: data });
  };

  React.useEffect(() => {
    if (counter > 0) setTimeout(() => setCounter(counter - 1), 1000);
    if (counter <= 0) {
      if (toggle) {
        setToggle(false);
        onChange(data);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter, toggle]);

  React.useEffect(() => {
    if (enterOn) {
      setToggle(false);
      setEnterOn(false);
      onChange(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enterOn]);

  return (
    <Fragment>
      <div className="dashboard-dia-d">
        <div className="totals-box">
          <div className="header">
            <div className="list-breadcrumb">
              <h2
                className={districtSelected?._id ? 'back-link' : ''}
                onClick={() => {
                  if (districtSelected?._id) {
                    dispatch({
                      type: DASHBOARD_D_SELECTED,
                      payload: {},
                    });
                  }
                }}
              >
                {t('dashboard.dday.lbl.bingoVotes')}
              </h2>
              {districtSelected?._id && (
                <>
                  <p className="show-link-separator">&nbsp;/&nbsp;</p>
                  <h2>{t('dashboard.lbl.district') + districtSelected.name}</h2>
                </>
              )}
            </div>
            <div></div>
          </div>
          <div className="progress-box">
            <p>
              {getCount().totalMarked} {t('dashboard.lbl.of')}{' '}
              {getCount().toWin} {t('dashboard.dday.lbl.expectedVotes')}
            </p>
            <ProgressBar
              className={'box-progress' + ' ' + getClassColor()}
              variant="success"
              now={getCount().totalMarked}
              max={getCount().toWin}
            />
          </div>
          <div className="amounts-box">
            <div className="group d-d-d">
              <style>{style}</style>
              {getAmountBox(AMOUNT_TYPES.EXPECTED)}
              {getAmountBox(AMOUNT_TYPES.FOR_WIN)}
              <div className="separator"></div>
              {getAmountBox(AMOUNT_TYPES.DONE)}
              {getAmountBox(AMOUNT_TYPES.MISSING)}
              <div className="separator"></div>
              {getAmountBox(AMOUNT_TYPES.INCIDENCE)}
            </div>
            {getListTimeZonesTime()}
          </div>
        </div>
        <div className="list-docs">
          <div className="header">
            <div></div>
            <SearchFormComponent
              search={search}
              funOnInit={(data: any) => {
                setCounter(2);
                setToggle(true);
                setData(data);
              }}
              regExp={onlyLettersNumbersSpaces}
              additionalClass={''}
              setEnterOn={setEnterOn}
            ></SearchFormComponent>
          </div>
          <div className="list">
            {dashboardData
              .filter((d: any) => {
                return (d.name as string)
                  .toLowerCase()
                  .includes(search.toLowerCase());
              })
              .map((e: any) => {
                return getListElement(e);
              })}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default DashboardDDay;
