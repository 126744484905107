// In types.ts we will define constants for user authentication and UI loading purpose
// user reducer types
export const SET_LOADING = 'SET_LOADING';
export const SET_STATES_DATA = 'SET_STATES_DATA';
export const SET_STATES_DATA_TO_CREATE = 'SET_STATES_DATA_TO_CREATE';
export const SET_CITIES_DATA = 'SET_CITIES_DATA';
export const SET_CITIES_DATA_TO_CREATE = 'SET_CITIES_DATA_TO_CREATE';
export const IGNORE_FEATURE = 'IGNORE_FEATURE';

export enum MODE_TYPE {
  NONE = 'NONE',
  STATES = 'STATES',
  CITIES = 'CITIES',
}

export enum MODULES {
  ANALYSIS = 'ANALYSIS',
  TERRITORIAL = 'TERRITORIAL',
  ELECTORAL = 'ELECTORAL',
  D_DAY = 'D_DAY',
}

export interface Feature {
  _id: string;
  geometry: {
    type: string;
    coordinates: [[[number]]];
  };
  properties: any;
}

export interface FeatureCollection {
  features: Feature[];
  type: string;
}

export interface SetLoading {
  type: typeof SET_LOADING;
  value: boolean;
}

export interface SetStatesData {
  type: typeof SET_STATES_DATA;
  featureCollection: FeatureCollection;
}

export interface SetCitiesData {
  type: typeof SET_CITIES_DATA;
  featureCollection: FeatureCollection;
}

export interface SetStatesDataToCreate {
  type: typeof SET_STATES_DATA_TO_CREATE;
  features: Feature[];
}

export interface SetIgnoreFeature {
  type: typeof IGNORE_FEATURE;
  featureId: string;
}

export type CoreLicenseDispatchTypes =
  | SetLoading
  | SetStatesData
  | SetCitiesData
  | SetStatesDataToCreate
  | SetIgnoreFeature;
