import React from 'react';
import { RouteProps } from 'react-router-dom';
import './style.scss';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
interface PopupProps extends RouteProps {
  title?: any;
  contentText?: any;
  id: any;
  children: any;
}

const PopupComponent: React.FunctionComponent<PopupProps> = ({
  title,
  contentText,
  id,
  children,
}: any) => {
  // const [isValid, setIsValid] = React.useState(false);
  const getOverlay = () => {
    return (
      <Popover id={id} placement={'bottom'} className="pop-over-any">
        {title && (
          <Popover.Title as="h3" bsPrefix="title">
            {title}
          </Popover.Title>
        )}
        {(contentText) && (
          <Popover.Content bsPrefix="content">
            {
              <>
                {contentText}
              </>
            }
          </Popover.Content>
        )}
      </Popover>
    );
  };
  return (
    <OverlayTrigger
      key={id + 'popup-item'}
      placement={'bottom'}
      overlay={getOverlay()}
    >
      {children}
    </OverlayTrigger>
  );
};

export default PopupComponent;
