import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootStore } from '../../../redux/Store';
import moment from 'moment';
import 'moment/locale/es';
import '../style.scss';

import { LOGS_OPEN, MODULE } from '../../../redux/logs/LogsTypes';
import { getLogs } from '../../../redux/logs/LogsActions';
import { formatPhoneNumber } from '../../../utils/utils';

import {
  FacebookIcon,
  InstagramIcon,
  TiktokIcon,
  MaleIcon,
  FemaleIcon,
  LogsIcon,
} from '../../../assets/svg';
import TableComponent, {
  HEADER,
  HEADER_TYPE,
  FIELD_TYPE,
  HeaderNormal,
} from '../../../components/Table';
import { AiFillTwitterCircle } from 'react-icons/ai';
import { IoIosTransgender } from 'react-icons/io';
import { DiOpera } from 'react-icons/di';
import photoIdPlaceHolderFrontNone from '../../../assets/img/photo-id-placeholder-front-none.png';
import photoIdPlaceHolderFront from '../../../assets/img/photo-id-placeholder-front.png';
import LayoutBodyComponent from '../../../components/LayoutBody';
import LogsListComponent from '../../../components/logsList';
import TopSeparator from '../../../components/TopSeparator';
import DuplicateCardComponent from './duplicateCard';
import TabComponent from '../../../components/Tabs';
import Image from 'react-bootstrap/Image';
import ModalPhotoId from './modalPhotoId';

export enum SOCIAL_NETWORK {
  UNDEFINED,
  FACEBOOK,
  INSTAGRAM,
  TIKTOK,
  TWITTER,
  ONLYFANS,
}

export enum SEX {
  MAN,
  WOMAN,
  APACHE_HELICOPTER,
}

export enum INCOME {
  INCOME_10,
  INCOME_20,
}

export enum SCHOLARSHIP {
  DEGREE,
  NO_STUDIES,
}

const VoterView: React.FunctionComponent = () => {
  const { t, i18n } = useTranslation();
  const [voterData, setVoterData] = useState(Object);
  const [modalPhotoIdShow, setModalPhotoIdShow] = useState(false);
  const [photosIds, setPhotosIds] = useState(Object);
  const [active, setActive] = useState(0);
  const dispatch = useDispatch();

  const [activeLogs, setActiveLogs] = React.useState(false);

  const voter: any = useSelector(
    (globalState: RootStore) => globalState.voters.voter,
  );

  const nextVotingElections = 2021; //TODO: mock-up | replace in future with server real data[module no yet]

  const getSexWithIcon = (network: number): any => {
    let sexImg;
    let sexName;
    switch (Number(network)) {
      case SEX.MAN:
        sexName = <p> {t('territorial.voters.lbl.sexMale')} </p>;
        sexImg = <MaleIcon className="icon"></MaleIcon>;
        break;
      case SEX.WOMAN:
        sexName = <p> {t('territorial.voters.lbl.sexFemale')}</p>;
        sexImg = <FemaleIcon className="icon"></FemaleIcon>;
        break;
      case SEX.APACHE_HELICOPTER:
        sexName = <p> {t('territorial.voters.lbl.sexTrans')}</p>;
        sexImg = <IoIosTransgender className="icon"></IoIosTransgender>;
        break;
      default:
        break;
    }

    return (
      <Fragment>
        {sexName}
        {sexImg}
      </Fragment>
    );
  };

  const getDob = (dob: any) => {
    let lang = i18n.language;
    const newDob = moment(dob).locale(lang).format('DD MMMM YYYY');
    return newDob;
  };

  const getSocialNetworkIcon = (network: number): any => {
    let networkImg;
    switch (Number(network)) {
      case SOCIAL_NETWORK.FACEBOOK:
        networkImg = <FacebookIcon className="icon-social"></FacebookIcon>;
        break;
      case SOCIAL_NETWORK.INSTAGRAM:
        networkImg = <InstagramIcon className="icon-social"></InstagramIcon>;
        break;
      case SOCIAL_NETWORK.TIKTOK:
        networkImg = <TiktokIcon className="icon-social"></TiktokIcon>;
        break;
      case SOCIAL_NETWORK.TWITTER:
        networkImg = <AiFillTwitterCircle className="icon-social" />;
        break;
      case SOCIAL_NETWORK.ONLYFANS:
        networkImg = <DiOpera className="icon-social" />;
        break;
      default:
        break;
    }

    return networkImg;
  };

  const getInitials = (value: string) => {
    let result = '*';
    if (value) {
      const array = value.split(' ');
      result = '';
      array.forEach((e) => {
        result = result + e.slice(0, 1);
      });
    }
    return result;
  };

  const getAddress = (address: any) => {
    let result = '';
    const newAddress = address;
    if (newAddress) {
      if (newAddress.street && newAddress.street !== '')
        result = newAddress.street;
      if (newAddress.interiorNumber && newAddress.interiorNumber !== '')
        result = result + `, i ${newAddress.interiorNumber}`;
      if (newAddress.exteriorNumber && newAddress.exteriorNumber !== '')
        result = result + `, ext ${newAddress.exteriorNumber}`;
      if (newAddress.suburb && newAddress.suburb !== '')
        result = result + `, ${newAddress.suburb}`;
      if (newAddress.postalCode && newAddress.postalCode !== '')
        result = result + `, ${newAddress.postalCode}`;
      if (newAddress.municipality) {
        result = result + `, ${newAddress.municipality.name}`;
      }
    }
    return result;
  };

  const getVoting = (address: any) => {
    let result = '';
    const newAddress = address;
    console.log('newAddress', newAddress);
    if (newAddress) {
      if (newAddress.state) result = result + `${newAddress.state.name}`;
      if (newAddress.municipality)
        result = result + `, ${newAddress.municipality.name}`;
      if (newAddress.section) result = result + `, ${getSection(newAddress)}`;
    }
    return result;
  };

  const getVoting2 = (voter: any) => {
    let result = '';
    if (voter) {
      if (voter.districtVote) {
        result = result + `${voter.districtVote}`;
      }
      if (voter.sectionVote) {
        if (result === '') {
          result = result + `${voter.sectionVote}`;
        } else result = result + `, ${voter.sectionVote}`;
      }
    }
    return result;
  };

  const getSection = (address: any) => {
    let result = '';
    const newAddress = address;
    if (newAddress) {
      if (newAddress.section) {
        result = `${newAddress.section.name}`;
      } else {
        result = '-';
      }
    }
    return result;
  };

  const getIneStatus = (voter: any) => {
    let status = t('territorial.voters.lbl.missingData');
    let bg = '';
    let exp = '';
    let ineExpiration = +voter.ineExpirationYear;
    let electionsDate = +nextVotingElections;

    if (ineExpiration >= electionsDate) {
      status = t('territorial.voters.lbl.valid');
      bg = 'valid';
      exp = 'valid-year';
    } else {
      status = t('territorial.voters.lbl.expired');
      bg = 'expired';
      exp = 'expired-year';
    }

    return (
      <>
        <div className={`ine-status-container ${bg}`}>
          <p>{status}</p>
        </div>
        <div className="ine-exp-container">
          <p className={`${exp}`}>
            {'Exp: '}
            {ineExpiration}
          </p>
        </div>
      </>
    );
  };

  const headerVotersBox: HeaderNormal = {
    title: '',
  };

  const transformData = () => {
    return [
      {
        _id: '#1',
        index: '#1',
        date: '15 Mar 2021',
        visitor: 'Capturista por defecto',
        description: 'Descripción por defecto.',
      },
      {
        _id: '#2',
        index: '#2',
        date: '12 Mar 2020',
        visitor: 'Capturista por defecto',
        description: 'Descripción por defecto.',
      },
      {
        _id: '#3',
        index: '#3',
        date: '12 Mar 2020',
        visitor: 'Capturista por defecto',
        description: 'Descripción por defecto.',
      },
    ];
  };

  useEffect(() => {
    let newVoterData = { ...voter };
    let socialNetworks = [];
    if (newVoterData.mostUsedNetworks) {
      if (newVoterData.mostUsedNetworks.facebook) {
        socialNetworks.push({
          username: `@${newVoterData.mostUsedNetworks.facebook}`,
          network: SOCIAL_NETWORK.FACEBOOK,
        });
      }
      if (newVoterData.mostUsedNetworks.instagram) {
        socialNetworks.push({
          username: `@${newVoterData.mostUsedNetworks.instagram}`,
          network: SOCIAL_NETWORK.INSTAGRAM,
        });
      }
      if (newVoterData.mostUsedNetworks.twitter) {
        socialNetworks.push({
          username: `@${newVoterData.mostUsedNetworks.twitter}`,
          network: SOCIAL_NETWORK.TWITTER,
        });
      }
    } else {
      socialNetworks = [
        {
          username: `@`,
          network: SOCIAL_NETWORK.FACEBOOK,
        },
        {
          username: `@`,
          network: SOCIAL_NETWORK.INSTAGRAM,
        },
        {
          username: `@`,
          network: SOCIAL_NETWORK.TWITTER,
        },
      ];
    }

    newVoterData = { ...newVoterData, ...{ socialNetworks: socialNetworks } };
    setVoterData(newVoterData);
    setPhotosIds({
      front: newVoterData.ineFrontPath,
      back: newVoterData.ineBackPath,
    });
  }, [voter]);

  const getCaptureDate = (create: any) => {
    let lang = i18n.language;
    const newDob = moment(create).locale(lang).format('DD MMM YYYY, h:mma.');
    return newDob;
  };

  const VoterCard = () => {
    console.log('voterData', voterData);
    return (
      <div className="voter-view-container">
        <div className="voter-container">
          <div className="top-accordion">
            <div className="label-container" onClick={() => {}}>
              <h1 className="title">
                <strong>
                  {voterData.firstName?.toUpperCase()}{' '}
                  {voterData.lastName?.toUpperCase()}
                </strong>
              </h1>
            </div>
            {/*<div
              className="history-box-trigger"
              onClick={() => {
                dispatch(
                  getLogs(t('language'), 1, 60, voterData._id, MODULE.VOTERS),
                );
                setActiveLogs(true);
              }}
            >
              <LogsIcon></LogsIcon>
            </div>*/}
          </div>
          <div className="body-accordion">
            <div className="voting-information">
              <div className="information-principal">
                <div className="item with-firstname">
                  <h3 className="title">{t('territorial.voters.lbl.name')}</h3>
                  <p className="content">
                    {voterData.firstName} {voterData.lastName}
                  </p>
                </div>

                <div className="item">
                  <h3 className="title">{t('territorial.voters.lbl.phone')}</h3>
                  <p className="content">
                    {formatPhoneNumber(voterData.cellphone)}
                  </p>
                </div>

                {/* <div className="item">
                    <h3 className="title">
                      {t('territorial.voters.lbl.email')}
                    </h3>
                    <p className="content"> {voterData.email} </p>
                  </div> */}

                <div className="item with-icon">
                  <h3 className="title">{t('territorial.voters.lbl.sex')}</h3>
                  <div className="content">{getSexWithIcon(voterData.sex)}</div>
                </div>

                {/* <div className="item with-date">
                    <h3 className="title">
                      {t('territorial.voters.lbl.dob')}
                    </h3>
                    <p className="content">{getDob(voterData.dob)}</p>
                  </div> */}
                <div className="item">
                  <h3 className="title">
                    {t('territorial.voters.lbl.capturedBy')}
                  </h3>
                  <p className="content">{voterData.capturer || '-'}</p>
                </div>
                <div className="item">
                  <h3 className="title">
                    {t('territorial.voters.lbl.captured')}
                  </h3>
                  <p className="content">
                    {' '}
                    {getCaptureDate(voterData.created)}
                  </p>
                </div>

                {/* <div className="item">
                  <h3 className="title">
                    {t('territorial.voters.lbl.addressOfVoting')}
                  </h3>
                  <p className="content">
                    {getVoting2(voterData)}.
                  </p>
                </div> */}
              </div>
              <div className="information-secondary">
                <div className="item">
                  <h3 className="title">
                    {t('territorial.voters.lbl.addressOfHome')}
                  </h3>
                  <p className="content">
                    {getAddress(voterData.homeAddress)}.
                  </p>
                </div>

                <div className="item">
                  <h3 className="title">
                    {t('territorial.voters.lbl.addressOfVoting')}
                  </h3>
                  <p className="content">{getVoting2(voterData)}.</p>
                </div>
              </div>
            </div>
            <div className="detail-information">
              <div className="detail-fragment extra-information-photo">
                <div className="label-container">
                  <h1 className="title">
                    {t('territorial.voters.lbl.photosId')}
                  </h1>
                </div>
                <div className="information-container">
                  <div className="item-with-photo">
                    {voterData.ineFrontPath && (
                      <Image
                        className="photo-id"
                        src={photoIdPlaceHolderFront}
                        fluid
                        onClick={() => {
                          //{
                          /* todo:use the permission id here  */
                          //}
                          setActive(0);
                          setModalPhotoIdShow(true);
                        }}
                      />
                    )}
                    {!voterData.ineFrontPath && (
                      <Image
                        className="photo-id"
                        src={photoIdPlaceHolderFrontNone}
                        fluid
                        onClick={() => {}}
                      />
                    )}
                  </div>
                  <div className="extra-information">
                    {/*<h3 className="title">
                        {t('territorial.voters.lbl.ineStatus')}
                      </h3>*/}
                    <div className="content">{getIneStatus(voterData)}</div>
                  </div>
                </div>
              </div>

              {/* 
                <div className="detail-fragment contact-information">
                  <div className="label-container">
                    <h1 className="title">
                      {t('territorial.voters.lbl.questions')}
                    </h1>
                  </div>
                  <div className="information-container">
                    <div className="with-vote-information first-with-vote">
                      <h3 className="title">
                        {t('territorial.voters.lbl.forWho')}
                      </h3>
                      <p className="content">
                        <span className="initials">
                          {getInitials(voterData.forWho)}
                        </span>
                      </p>
                    </div>
                    <div className="with-vote-information">
                      <h3 className="title">
                        {t('territorial.voters.lbl.willYouVote')}
                      </h3>
                      <p className="content">
                        <span className="initials">
                          {getInitials(voterData.forWho)}
                        </span>
                      </p>
                    </div>
                  </div>
                </div> */}

              <div className="detail-fragment social-information">
                <div className="label-container">
                  <h1 className="title">
                    {t('territorial.voters.lbl.socialNetworks')}
                  </h1>
                </div>
                <div className="information-container">
                  {voterData.socialNetworks &&
                  voterData.socialNetworks.length ? (
                    <Fragment>
                      {voterData.socialNetworks?.map(
                        (social: any, index: any) => {
                          return (
                            <div
                              className={
                                index < voterData.socialNetworks?.length - 1
                                  ? 'with-social-information first-with-social'
                                  : 'with-social-information'
                              }
                              key={index}
                            >
                              {getSocialNetworkIcon(social.network)}
                              <p className="content"> {social.username} </p>
                            </div>
                          );
                        },
                      )}
                    </Fragment>
                  ) : (
                    <Fragment>
                      {[
                        { network: '1' },
                        { network: '2' },
                        { network: '4' },
                      ].map((social: any, index: any) => {
                        return (
                          <div
                            className={
                              index < 3 - 1
                                ? 'with-social-information first-with-social'
                                : 'with-social-information'
                            }
                            key={index}
                          >
                            {getSocialNetworkIcon(social.network)}
                            <p className="content">@ </p>
                          </div>
                        );
                      })}
                    </Fragment>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="visits-container">
          <div className="top-accordion">
            <div className="label-container">
              <h1 className="title">
                <strong>{t('territorial.voters.lbl.visits')}</strong>
              </h1>
            </div>
          </div>
          <div className="voters-territorial-list">
            <div className="voters-container voter-justify">
              <TableComponent
                onTrAction={true}
                onTrFun={() => {}}
                showActions={false}
                selectedRows={[]}
                selectRows={() => {}}
                header={headerVotersBox}
                headerType={HEADER_TYPE.HIDDEN}
                headers={[
                  {
                    type: HEADER.TEXT,
                    name: t('territorial.voters.lbl.counter'),
                  },
                  {
                    type: HEADER.TEXT,
                    name: t('territorial.voters.lbl.dateOfVisit'),
                  },
                  {
                    type: HEADER.TEXT,
                    name: t('territorial.voters.lbl.visitor'),
                  },
                  {
                    type: HEADER.TEXT,
                    name: t('territorial.voters.lbl.description'),
                  },
                ]}
                fieldTypes={[
                  { type: FIELD_TYPE.TEXT },
                  { type: FIELD_TYPE.TEXT },
                  { type: FIELD_TYPE.TEXT },
                  { type: FIELD_TYPE.TEXT },
                ]}
                toEdit={() => {}}
                toDelete={() => {}}
                search={() => {}}
                regExp={undefined}
                data={transformData()}
                nameOfData={['index', 'date', 'visitor', 'description']}
                classBody={[
                  'text-align-center selector-cell-none order-container',
                  'text-align-left date-container',
                  'text-align-left visitor-container',
                  'text-align-left description-container',
                ]}
              ></TableComponent>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      {modalPhotoIdShow && (
        <ModalPhotoId
          modalShow={modalPhotoIdShow}
          setModalShow={setModalPhotoIdShow}
          photosIds={photosIds}
          active={active}
          setActive={setActive}
          t={t}
        ></ModalPhotoId>
      )}

      {Object.keys(voterData).length > 0 ? (
        <>
          {voterData.duplicates && voterData.duplicates.length >= 1 ? (
            <TabComponent
              qty={voterData.duplicates.length}
              options={[
                'territorial.voters.lbl.voter',
                'territorial.voters.lbl.duplicates',
              ]}
              children={[
                <LayoutBodyComponent>{VoterCard()}</LayoutBodyComponent>,
                <div className="list-duplicates">
                  {
                    <DuplicateCardComponent
                      voterData={voterData}
                      number={1}
                      t={t}
                    ></DuplicateCardComponent>
                  }
                  {voterData.duplicates.map((d: any, index: number) => {
                    return (
                      <DuplicateCardComponent
                        key={d._id}
                        voterData={d}
                        number={index + 2}
                        t={t}
                      ></DuplicateCardComponent>
                    );
                  })}
                </div>,
              ]}
            ></TabComponent>
          ) : (
            <>
              <TopSeparator></TopSeparator>
              <LayoutBodyComponent>{VoterCard()}</LayoutBodyComponent>
            </>
          )}
        </>
      ) : null}
      <LogsListComponent
        openLogs={() => {
          dispatch({ type: LOGS_OPEN, open: false });
          setActiveLogs(false);
        }}
        active={activeLogs}
      ></LogsListComponent>
    </Fragment>
  );
};

export default VoterView;
