import React from 'react';
import { useDispatch } from 'react-redux';
import StaticModal from '../../../../dialogs/StaticModal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { accessLicense } from '../../../../redux/campaigns/campaignsActions';
import { Campaign } from '../../../../redux/campaigns/campaignsTypes';
import { setDataFromCampaign } from '../../../../redux/User/userActions';
import { CheckAuthentication } from '../../../../utils/CheckAuthentication';

interface AddModalProps {
  modalShow: any;
  setModalShow: any;
  successFun: any;
  licenseSelected?: Campaign;
}

export const AccessLicenseModalComponent: React.FunctionComponent<AddModalProps> = ({
  modalShow,
  setModalShow,
  successFun,
  licenseSelected,
}: AddModalProps) => {
  // const { t } = useTranslation();
  const dispatch = useDispatch();
  // const redirect = useHistory();

  const cancelFun = () => {
    setModalShow(false);
  };

  const submitFun = () => {
    onSave();
  };

  const onSave = () => {
    if (licenseSelected) {
      const license = licenseSelected;
      dispatch(
        accessLicense(
          {
            license_manager_token: localStorage.access_token,
            campaignId: license._id,
          },
          (result: any) => {
            const authResult = {
              accessToken: result.accessToken,
              refreshToken: result.refreshToken,
              expiresIn: result.expiresIn,
            };
            setDataFromCampaign(authResult);
            successFun();
            setModalShow(false);
            CheckAuthentication();
          },
          (err: any) => {
            console.log(err);
          },
        ),
      );
    }
  };

  return (
    <StaticModal
      show={modalShow}
      title={'Access License'}
      closeText={'Cancel'}
      confirmText={'Yes, Access'}
      onHide={cancelFun}
      submitFun={submitFun}
      validBtn={false}
      size="sm"
      sizeClass="delete-license-modal"
    >
      <Row noGutters className="modal-text-row">
        <Col xs={12} className="description-col">
          <div className="text-description">
            Are you sure you want to access
            <strong> {licenseSelected?.name || ''}</strong> ?
          </div>
        </Col>
      </Row>
    </StaticModal>
  );
};

export default AccessLicenseModalComponent;
