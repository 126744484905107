import React, { useEffect, useState, Fragment } from "react";
import StepsTabComponent from "./setpsTabs";
import QuestionItemComponent from "./questionItem";
import StepUploadVideoBoxComponent from "./stepUploadVideoBox";
import ModalOnEdit from "./modalOnEdit";
import ModalAdminQuestions from "./modalAdminQuestions";
import { putTraining } from "../../../redux/trainings/TrainingsActions";
import { AddActionIcon } from '../../../assets/svg/index';


interface StepsBoxProps {
  saveStepData: any;
  t: any;
  isEdit: any;
  training?: any;
  viewMode: any;
  oldSteps?: any;
  setOldSteps?: any;
  formData?: any;
  setIsPerformingRequest?: any;
  filesArray: any;
  setFilesArray: any;
}

export const StepsBox: React.FunctionComponent<StepsBoxProps> = ({
  saveStepData,
  t,
  isEdit,
  training,
  viewMode,
  oldSteps,
  setOldSteps,
  formData,
  setIsPerformingRequest,
  filesArray,
  setFilesArray,
}: any) => {
  const [otherKey, setOtherKey] = useState(Math.random());
  const [activeKey, setActiveKey] = useState(0);
  const [otherQuestionKey, setOtherQuestionKey] = useState(Math.random());
  const [activeQuestionKey, setActiveQuestionKey] = useState(0);
  const [currentStepChange, setCurrentStepChange] = useState(false);
  const [modalOnEditStepShow, setModalOnEditStepShow] = React.useState(false);
  const [modalOnDeleteStepShow, setModalOnDeleteStepShow] = React.useState(false);
  const [modalAdminQuestionsShow, setModalAdminQuestionsShow] = React.useState(false);
  const [deleteStepIndex, setDeleteStepIndex] = React.useState(String);

  const [oldQuestionIndex, setOldQuestionIndex] = React.useState(undefined);
  const [oldQuestionKey, setOldQuestionKey] = React.useState(undefined);

  const questionTpl = {
    name: "",
    answers: [{ name: "", isValid: false }],
  };

  const stepTpl = {
    file: { name: t("settings.trainings.form.selectFile") },
    questions: [] as any,
  };
  const [stepData, setStepData] = useState([stepTpl]);

  const loadOldQuestion = (activeKey: any, masterIndex: any) => {
    setOldQuestionIndex(masterIndex);
    setOldQuestionKey(activeKey);
    setModalAdminQuestionsShow(true);
  }

  const addNewStepItem = () => {
    setStepData((step) => [...step, ...[stepTpl]]);
    setActiveKey(stepData.length)
  };

  const addNewStepItemQuestion = () => {
    let canAddQuestion = checkAllStepQuestions(activeKey)
    if(canAddQuestion) {
      setModalAdminQuestionsShow(true)
    }
  }

  const isOldStep = (masterIndex: number) => {
    let isOld = false;
    let newItems = [...stepData];
    if (isEdit) {
      let newOldSteps = [...oldSteps];
      let oldStep = newItems[masterIndex];
      if ("_id" in oldStep) {
        newOldSteps.push(oldStep);
        isOld = true;
      }
      setOldSteps(newOldSteps);
    }

    return isOld;
  };

  const deleteStep = (masterIndex: number) => {
    return new Promise(function (resolve, reject) {
      let newItems = [...stepData];
      newItems.splice(masterIndex, 1);
      setStepData(newItems);
      const stepKey = masterIndex <= 0 ? masterIndex : masterIndex - 1;
      setOtherKey(Math.random());
      setActiveKey(stepKey);
      setOtherQuestionKey(Math.random());
      setActiveQuestionKey(0);
      resolve(newItems);
    });
  };

  const checkAllSteps = () => {
    let invalidCount = 0;
    stepData.map((step: any) => {
      if (Object.keys(step.file).length < 3) {
        invalidCount++;
      }
      step.questions.map((question: any) => {
        if (question.name.length === 0) {
          invalidCount++;
        }
        question.answers.map((answer: any) => {
          if (answer.name.length === 0) {
            invalidCount++;
          }
        });
      });
    });
    const canAddNewstep = invalidCount > 0 ? false : true;
    return canAddNewstep;
  };

  const checkAllStepQuestions = (masterIndex: number) => {
    let invalidCount = 0;
    let newItems = [...stepData];
    newItems[masterIndex].questions.map((question: any) => {
      if (question.name.length === 0) {
        invalidCount++;
      }
      question.answers.map((answer: any) => {
        if (answer.name.length === 0) {
          invalidCount++;
        }
      });
    });
    const canAddNewQuestion = invalidCount > 0 ? false : true;
    return canAddNewQuestion;
  };

  const reorderList = (source: any, destination: any, active: any, master: any) => {
    let newItems = [...stepData];
    let newQuestion = newItems[active].questions[master];

    let answerSource = newQuestion.answers[source];
    //let answerDestination = newQuestion.answers[destination];
    let answersArray = [] as any;
    
    newQuestion?.answers.map((answer: any, index: any) => {
      if(index===destination && source>destination){
        answersArray.push(answerSource)
      }
      if(index!==source){
        answersArray.push(answer)
      }
      if(index===destination && destination>source){
        answersArray.push(answerSource)
      }
    })

    newItems[active].questions[master].answers = answersArray
    console.log('answerSource', answerSource)
    //console.log('answerDestination', answerDestination)
    console.log('answersArray', answersArray)
    console.log('newItems', newItems)
    setStepData(newItems)
  }

  useEffect(() => {
    saveStepData(stepData);
    if (isEdit && !viewMode) {
      setCurrentStepChange(true);
    }
  }, [stepData]);

  useEffect(() => {
    if (isEdit) {
      const { steps } = training;
      setStepData(steps);
      setOldSteps([]);
    }
  }, [isEdit]);

  return (
    <Fragment>
        <StepsTabComponent
          selectFun={(thisStep: any) => {
            if (currentStepChange) {
              setOtherKey(Math.random());
              setActiveKey(thisStep);
              setModalOnEditStepShow(true);
            } else {
              setOtherQuestionKey(Math.random());
              setActiveQuestionKey(0);
            }
          }}
          tabName={t("settings.trainings.lbl.step")}
          addFun={() => {
            if (checkAllSteps()) {
              addNewStepItem();
              setTimeout(() => setCurrentStepChange(false));
            }
          }}
          deleteFun={(currentIndex: any) => {
            /*if (isEdit) {
              const isOldStepItem = isOldStep(currentIndex);
              if (isOldStepItem) {*/
                setDeleteStepIndex(currentIndex);
                setModalOnDeleteStepShow(true);
              /*} else {
                deleteStep(currentIndex);
              }
            } else {
              deleteStep(currentIndex);
            }*/
          }}
          setKey={setActiveKey}
          activeKey={activeKey}
          otherKey={otherKey}
          options={stepData.map((step: any, index: any) => {
            return `${t("settings.trainings.lbl.step")} ${index + 1}`;
          })}
          children={stepData.map((step: any, index: any) => {
            const masterIndex = index;
            return (
                <div 
                className="training-content-box"
                key={`step-${masterIndex}`}
                >
                    <div className="upload-video-container">
                        <StepUploadVideoBoxComponent
                            masterIndex={masterIndex}
                            stepFile={step.file}
                            t={t}
                            stepData={stepData}
                            setStepData={setStepData}
                            viewMode={viewMode}
                            isEdit={isEdit}
                            stepFiles={filesArray}
                            setStepFiles={setFilesArray}
                            isOldStep={isOldStep}
                        ></StepUploadVideoBoxComponent>
                    </div>

                    <div className="training-questions-container">
                        <div className="box-row">
                            <h1 className="main-title">{t('settings.trainings.lbl.questions')}</h1>
                            <div 
                            className="add-icon"
                            onClick={() => {
                                //setModalAdminQuestionsShow(true);
                                addNewStepItemQuestion();
                            }}
                            >
                                <AddActionIcon></AddActionIcon>
                            </div>
                        </div>

                        <div className="box-questions">
                          {step.questions.map((question: any, index: any) => {
                              return(
                                  <QuestionItemComponent
                                      key={index}
                                      activeKey={activeKey}
                                      masterIndex={index}
                                      stepDataQuestion={question}
                                      setStepData={setStepData}
                                      stepData={stepData}
                                      editFun={loadOldQuestion}
                                      reorderList={reorderList}
                                      t={t}
                                  >
                                  </QuestionItemComponent>
                              )
                          })}
                        </div>
                    </div>

                </div>
            );
          })}
        ></StepsTabComponent>
      {modalAdminQuestionsShow && (
        <ModalAdminQuestions
            modalShow={modalAdminQuestionsShow}
            setModalShow={setModalAdminQuestionsShow}
            training={stepData}
            fun={setStepData}
            activeKey={activeKey}
            t={t}
            isEdit={isEdit}
            oldIndex={oldQuestionIndex}
            setOldIndex={setOldQuestionIndex}
            oldKey={oldQuestionKey}
            setOldKey={setOldQuestionKey}
        ></ModalAdminQuestions>
      )}
      {modalOnEditStepShow && (
        <ModalOnEdit
          modalShow={modalOnEditStepShow}
          customMsg={"Antes de continuar debe guardar los cambios del paso"}
          successFun={() => {
            setIsPerformingRequest(true);
            putTraining(formData)
              .then((data: any) => {
                setStepData(data.response.steps);
                setCurrentStepChange(false);
                setIsPerformingRequest(false);
                setModalOnEditStepShow(false);
              })
              .catch((err) => {
                setIsPerformingRequest(false);
                setModalOnEditStepShow(false);
              });
          }}
          cancelFun={() => {
            setModalOnEditStepShow(false);
          }}
          closeText={"continuar modificando"}
          confirmText={"Actualizar"}
          t={t}
        ></ModalOnEdit>
      )}
      {modalOnDeleteStepShow && (
        <ModalOnEdit
          modalShow={modalOnDeleteStepShow}
          customMsg={t("settings.trainings.modal.notification.body")}
          successFun={() => {
            setIsPerformingRequest(true);
            deleteStep(Number(deleteStepIndex)).then((steps: any) => {
              formData = { ...formData, ...{ stepData: steps } };
              putTraining(formData)
                .then((data: any) => {
                  setStepData(data.response.steps);
                  setCurrentStepChange(false);
                  setIsPerformingRequest(false);
                  setModalOnDeleteStepShow(false);
                })
                .catch((err) => {
                  setIsPerformingRequest(false);
                  setModalOnDeleteStepShow(false);
                });
            });
          }}
          cancelFun={() => {
            setOldSteps([]);
            setModalOnDeleteStepShow(false);
          }}
          closeText={t("settings.trainings.modal.button.cancel")}
          confirmText={t("settings.trainings.modal.button.yesDelete")}
          t={t}
        ></ModalOnEdit>
      )}
    </Fragment>
  );
};

export default StepsBox;
