import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootStore } from '../../../redux/Store';
import * as netWorking from '../../../redux/netWorking';

import {
  getVotersBox,
  getVotersBoxTotal,
  setSelectedValues,
} from '../../../redux/votersBox/VotersBoxActions';
import { XLS_TYPE } from '../../../utils/utils';

import TableComponent, {
  FIELD_TYPE,
  HEADER_TYPE,
  HEADER,
} from '../../../components/Table';
import PaginationRecords from '../../../components/PaginationRecords';
import TopXlsBar from '../../../components/TopXlsBar';
import ModalAddVotersBox from './addVotersbox';
import ModalDelete from './modalDelete';
import ModalAddXLS from '../../../dialogs/modalXLS';
import LoadingModal from '../../../dialogs/loadingModal';

const List: React.FunctionComponent = () => {
  const citySelected: any = useSelector((globalState: RootStore) => {
    return globalState.core.globalMunicipalitySelected;
  });

  const loading = useSelector(
    (globalState: RootStore) => globalState.votersBox.loading,
  );
  const voterBoxs = useSelector(
    (globalState: RootStore) => globalState.votersBox.voterBoxs,
  );
  const selected: any = useSelector(
    (globalState: RootStore) => globalState.votersBox.sectionSelected,
  );
  const totalVoterBoxs: number = useSelector(
    (globalState: RootStore) => globalState.votersBox.totalVoterBoxs,
  );

  const search = useSelector(
    (globalState: RootStore) => globalState.votersBox.search,
  );
  const sort: any = useSelector(
    (globalState: RootStore) => globalState.votersBox.sort,
  );
  const records: any = useSelector(
    (globalState: RootStore) => globalState.votersBox.records,
  );
  const page: any = useSelector(
    (globalState: RootStore) => globalState.votersBox.page,
  );
  //======================================================================================
  const totalPage = useSelector(
    (globalState: RootStore) => globalState.votersBox.totalPage,
  );

  const [tableData, setTableData] = useState(Array);
  const [fieldOrder, setFieldOrder] = useState(sort[1] ? sort[1] : 'asc');

  const [modalShowAdd, setModalShowAdd] = useState(false);
  const [modalDeleteShow, setModalDeleteShow] = useState(false);
  const [modalShowXls, setModalShowXls] = useState(false);
  const [modalShowBingo, setModalShowBingo] = useState(false);
  const [edit, setEdit] = useState(Object);
  const [toDelete, setToDelete] = useState(Array);
  const [bingo, setBingo] = useState('');

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const headerVotersBox: any = {};
  let defaultVoterBox: any;

  const handleAddVBClick = () => {
    setEdit(defaultVoterBox);
    setModalShowAdd(true);
  };

  const openDelete = (deleteArray: any) => {
    setToDelete(deleteArray);
    setModalDeleteShow(true);
  };

  const setSelected = (data: any, value: string) => {
    dispatch(setSelectedValues(data));
  };

  const openEdit = (_id: any) => {
    const vb = voterBoxs?.find((value: any) => value._id === _id);
    if (vb) {
      setEdit(vb);
      setModalShowAdd(true);
    }
  };

  const getAddress = (address: any) => {
    let result = '';
    const newAddress = address;
    if (newAddress) {
      if (newAddress.street && newAddress.street !== '')
        result = newAddress.street;
      if (newAddress.exteriorNumber && newAddress.exteriorNumber !== '')
        result = result + `, ${newAddress.exteriorNumber}`;
      if (newAddress.interiorNumber && newAddress.interiorNumber !== '')
        result = result + `, ${newAddress.interiorNumber}`;
      if (newAddress.postalCode && newAddress.postalCode !== '')
        result = result + `, ${newAddress.postalCode}`;
      if (newAddress.suburb && newAddress.suburb !== '')
        result = result + `, ${newAddress.suburb}`;
    }
    return result;
  };

  const getUbication = (ubication: any) => {
    let result = '';
    let aux_count = 0;
    const newUbication = ubication;
    newUbication.forEach((ub: any) => {
      if (aux_count === 0) {
        result = ub.toFixed(5);
      } else {
        result = result + ', ' + ub.toFixed(5);
      }
      aux_count++;
    });
    return result;
  };

  const assignBingo = (_id: any) => {
    setBingo(_id);
    setModalShowBingo(true);
  };

  const transformData = () => {
    let newVBs: any = [];
    let dataArray: any = voterBoxs;
    dataArray.forEach((vb: any) => {
      console.log(
        'vb.address.geoLocation.coordinates',
        vb.address.geoLocation.coordinates,
      );
      let newVoterBox = {
        _id: vb._id,
        number: vb.number,
        winningNumber: vb.winningNumber,
        address: getAddress(vb.address),
        city: vb.address?.municipality ? vb.address.municipality : '',
        ubication: vb.address?.geoLocation?.coordinates
          ? getUbication(vb.address.geoLocation.coordinates)
          : getUbication([0, 0]),
        district: vb?.district ? vb.district : '',
        section: vb?.section ? vb.section : '',
      };
      newVBs.push(newVoterBox);
    });
    setTableData(newVBs);
  };

  const searchByValue = (reset = false) => {
    const params = buildParamSearchVoterBoxData(
      search,
      records ? records : 10,
      fieldOrder,
      !reset ? page : 1,
    );
    dispatch(getVotersBox(params));
    dispatch(getVotersBoxTotal(params));
  };

  const buildParamSearchVoterBoxData = (
    search: string,
    recordsNum: number,
    order: string,
    pageNum: number,
  ) => {
    let requestObject;
    requestObject = {
      cityLicenseId: citySelected?._id,
      municipalityId: citySelected?.municipalityId,
      districtId: selected.district,
      sectionId: selected.section,
      search: search.length >= 3 ? search : '',
      records: recordsNum,
      order: order,
      page: pageNum,
    };
    return requestObject;
  };

  useEffect(() => {
    if (voterBoxs) {
      transformData();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [voterBoxs]);

  useEffect(() => {
    if (
      selected.district &&
      selected.section &&
      (search.length >= 3 || search.length <= 0)
    ) {
      searchByValue();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    if (selected.district && selected.section) {
      searchByValue();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldOrder]);

  useEffect(() => {
    if (selected.district && selected.section) {
      searchByValue(true);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    //if (selected.district && selected.section) {
    searchByValue(true);
    //} // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  return (
    <div className="box-settings-list">
      <TopXlsBar
        title={t('settings.polling.title.1')}
        downloadFun={() =>
          netWorking.downloadXls(
            XLS_TYPE.VOTERS_BOX_XLS_TYPE,
            citySelected?.name || '',
          )
        }
        uploadFun={() => setModalShowXls(true)}
        buttonFun={handleAddVBClick}
        setSelected={setSelected}
        selected={selected}
        showSelects={true}
        selectsToShow={3}
        total={totalVoterBoxs}
        deleteFun={() => {
          openDelete(toDelete);
        }}
        toDelete={toDelete}
      ></TopXlsBar>
      <div className="box-settings-container">
        <TableComponent
          loading={false}
          showActions={true}
          selectedRows={toDelete}
          selectRows={setToDelete}
          selectMessage={true}
          header={headerVotersBox}
          headerType={HEADER_TYPE.HIDDEN}
          headers={[
            {
              type: HEADER.FILTER,
              name: t('settings.polling.table.polling'),
              fun: () => {
                let newOrder = fieldOrder === 'asc' ? 'desc' : 'asc';
                setFieldOrder(newOrder);
              },
            },
            {
              type: HEADER.TEXT,
              name: t('settings.polling.table.toWin'),
            },
            {
              type: HEADER.TEXT,
              name: t('settings.polling.table.address'),
            },
            {
              type: HEADER.TEXT,
              name: t('settings.polling.table.location'),
            },
            {
              type: HEADER.TEXT,
              name: t('settings.polling.table.district'),
            },
            {
              type: HEADER.TEXT,
              name: t('settings.polling.table.section'),
            },
            {
              type: HEADER.TEXT,
              name: t('menu.bingo'),
            },
          ]}
          fieldTypes={[
            { type: FIELD_TYPE.TEXT },
            { type: FIELD_TYPE.TEXT },
            { type: FIELD_TYPE.TEXT },
            { type: FIELD_TYPE.TEXT },
            { type: FIELD_TYPE.TEXT },
            { type: FIELD_TYPE.TEXT },
            {
              type: FIELD_TYPE.BUTTON_TEXT,
              fun: (value: any) => {
                assignBingo(value);
              },
            },
          ]}
          toEdit={(value: any) => {
            openEdit(value);
          }}
          toDelete={(value: any) => {
            openDelete(value);
          }}
          search={() => {}}
          regExp={undefined}
          data={tableData}
          nameOfData={[
            'number',
            'winningNumber',
            'address',
            'ubication',
            'district',
            'section',
            '_id',
          ]}
          classBody={[
            'text-align-left selector-cell full-name-container',
            'text-align-center winning-number-container',
            'text-align-left addres-container',
            'text-align-left ubication-container',
            'text-align-center district-container',
            'text-align-center section-container',
            'text-align-center bingo-container',
          ]}
        ></TableComponent>
        <PaginationRecords
          totalPages={totalPage}
          value={page}
          records={records}
          onChange={(pagina: string, record: string) => {
            let rec = record === '' ? '10' : record;
            let pag;
            if (records !== record) {
              pag = '1';
            } else {
              pag = pagina === '' || pagina === '0' ? '1' : pagina;
            }
            const params = buildParamSearchVoterBoxData(
              search,
              +rec,
              fieldOrder,
              +pag,
            );
            dispatch(getVotersBox(params));
            dispatch(getVotersBoxTotal(params));
          }}
        ></PaginationRecords>
      </div>
      {modalShowXls && (
        <ModalAddXLS
          modalShow={modalShowXls}
          setModalShow={setModalShowXls}
          type={XLS_TYPE.VOTERS_BOX_XLS_TYPE}
          cityLicenseId={citySelected?._id}
          municipalityId={citySelected?.municipalityId}
          successFun={(res: any) => {
            searchByValue();
            setToDelete([]);
          }}
        ></ModalAddXLS>
      )}
      {modalShowBingo && (
        <ModalAddXLS
          modalShow={modalShowBingo}
          setModalShow={setModalShowBingo}
          type={XLS_TYPE.BINGO_XLS_TYPE}
          cityLicenseId={citySelected?._id}
          municipalityId={citySelected?.municipalityId}
          voterBoxId={bingo}
          successFun={(res: any) => {
            searchByValue();
            setToDelete([]);
          }}
        ></ModalAddXLS>
      )}
      {modalShowAdd && (
        <ModalAddVotersBox
          modalShow={modalShowAdd}
          successFun={() => {
            searchByValue();
          }}
          setModalShow={setModalShowAdd}
          edit={edit}
        ></ModalAddVotersBox>
      )}
      {modalDeleteShow && (
        <ModalDelete
          modalShow={modalDeleteShow}
          successFun={() => {
            searchByValue();
            setToDelete([]);
          }}
          toDelete={toDelete}
          setModalShow={setModalDeleteShow}
          votersBox={voterBoxs}
          deleteMessage={t('electoral.rcrg.modal.lbl.polling')}
          t={t}
        ></ModalDelete>
      )}

      {loading && (
        <LoadingModal show={loading} onHide={() => {}}></LoadingModal>
      )}
    </div>
  );
};

export default List;
