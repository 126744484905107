import React, { useContext, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootStore } from '../../../redux/Store';

import {
  VOTERSBOX_DDAY_ALL,
  VOTERSBOX_DDAY_CREATE,
  VOTERSBOX_DDAY_UPDATE,
  VOTERSBOX_DDAY_SEARCH_SUCCESS,
} from '../../../redux/votersBox/VotersBoxTypes';
import { getMaxDistance, onlyLettersNumbersSpaces } from '../../../utils/utils';

import VotersBoxMapIconGreen from '../../../assets/svg/green_place.svg';
import VotersBoxMapIcon from '../../../assets/svg/icon_casilla.svg';
import SelectorStatusComponent from './selectorStatus';
import ItemVoterBox from './itemVoterBox';
import { createClusterCustomIcon } from '../../../components/sub-component/markers';
import LayerControlComponent from '../../../components/sub-component/layerControl';
import SearchFormComponent from '../../../components/sub-component/searchForm';

import MarkerClusterGroup from 'react-leaflet-markercluster';
import { Map, Marker } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

import { ContextWs, WS_STATES } from '../../../redux/socket/wsContext';

const Maps: React.FunctionComponent = () => {
  const citySelected: any = useSelector((globalState: RootStore) => {
    return globalState.core.globalMunicipalitySelected;
  });

  const loading = useSelector(
    (globalState: RootStore) => globalState.votersBox.loadingDiaD,
  );
  const voterBoxs = useSelector(
    (globalState: RootStore) => globalState.votersBox.voterBoxsDiaD,
  );

  const search = useSelector(
    (globalState: RootStore) => globalState.votersBox.searchDiaD,
  );
  const filters: any = useSelector(
    (globalState: RootStore) => globalState.votersBox.filtersDiaD,
  );

  const [counter, setCounter] = React.useState(2);
  const [toggle, setToggle] = React.useState(false);
  const [data, setData] = React.useState('');
  const [enterOn, setEnterOn] = useState(false);

  const [tableData, setTableData] = useState(Array);
  const [filter, setFilter] = useState(
    filters
      ? filters
      : {
          open: false,
          close: false,
          problem: false,
        },
  );

  let defaultCoordinate: any;
  const [radius, setRadius] = useState(144447.6442);
  const [coordinates, setCoordinates] = useState(defaultCoordinate);
  const [selectedItem, setSelectedItem] = useState('');
  const [center, setCenter] = React.useState(defaultCoordinate);
  const [zoom, setZoom] = React.useState(defaultCoordinate);

  const defaultPosition = {
    lat: 32.7157431,
    lng: -117.1888535,
    zoom: 11,
  };

  useEffect(() => {
    if (citySelected) {
      setCenter(
        citySelected?.defaultCenter !== undefined
          ? citySelected?.defaultCenter
          : [defaultPosition.lat, defaultPosition.lng],
      );
      setZoom(
        citySelected?.defaultZoom !== undefined
          ? citySelected?.defaultZoom
          : defaultPosition.zoom,
      );
      setCoordinates(
        citySelected?.defaultCenter !== undefined
          ? [citySelected?.defaultCenter.lng, citySelected?.defaultCenter.lat]
          : [defaultPosition.lng, defaultPosition.lat],
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [citySelected]);

  let markerRefs: any = [];
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { socket, wsState, rooms, join, disconnect, off } =
    useContext(ContextWs);

  const getMarkerIcon = (voterboxId: any) => {
    if (voterboxId === selectedItem) {
      return L.icon({
        iconUrl: VotersBoxMapIconGreen,
        iconSize: [70, 70],
        iconAnchor: [20, 40],
        className: 'green-icon-map',
      });
    } else {
      return L.icon({
        iconUrl: VotersBoxMapIcon,
        iconSize: [40, 40],
        iconAnchor: [20, 40],
      });
    }
  };

  const transformData = () => {
    let newArray: any = [];

    voterBoxs?.forEach((vb) => {
      let newElement = {
        _id: vb._id,
        name: vb.name,
        confirmed: vb.confirmed,
        forWin: vb.forWin,
        necessary: vb.necessary,
      };
      newArray.push(newElement);
    });

    setTableData(newArray);
  };

  const onChange = (data: any) => {
    dispatch({ type: VOTERSBOX_DDAY_SEARCH_SUCCESS, searchValue: data });
  };

  useEffect(() => {
    if (citySelected)
      if (wsState === WS_STATES.OPEN && socket) {
        const voterboxRoom = 'voterbox' + citySelected._id;
        socket.once('RoomsJoined', () => {
          socket.emit('getVoterboxAll', {
            campaignId: localStorage.campaignId,
            cityLicenseId: citySelected._id,
            municipalityId: citySelected.municipalityId,
            filter: filter,
            search: search,
          });
        });
        if (off) {
          off(voterboxRoom);
        }
        if (join)
          join(
            voterboxRoom,
            VOTERSBOX_DDAY_UPDATE,
            VOTERSBOX_DDAY_CREATE,
            '',
            VOTERSBOX_DDAY_ALL,
            (response: any) => {},
          );
      }
  }, [wsState, citySelected, filter]);

  useEffect(() => {
    if (voterBoxs) {
      transformData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [voterBoxs]);

  React.useEffect(() => {
    if (counter > 0) setTimeout(() => setCounter(counter - 1), 1000);
    if (counter <= 0) {
      if (toggle) {
        setToggle(false);
        onChange(data);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter, toggle]);

  React.useEffect(() => {
    if (enterOn) {
      setToggle(false);
      setEnterOn(false);
      onChange(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enterOn]);

  return (
    <div className="voterbox-dday-container">
      <div className="voterbox-top-section">
        <div className="top-label">
          <h1 className="count">
            {t('dday.pollingStation.lbl.expectedToWin') +
              ' [' +
              tableData.length +
              ']'}
          </h1>
        </div>
        <div className="top-filters">
          <div className="status">
            <SelectorStatusComponent
              title={t('territorial.auditory.lbl.show')}
              horizontal={true}
              filters={filters}
              fun={(newfilters: any) => {
                setFilter(newfilters);
              }}
            ></SelectorStatusComponent>
          </div>
          <SearchFormComponent
            search={search}
            funOnInit={(data: any) => {
              setCounter(2);
              setToggle(true);
              setData(data);
            }}
            regExp={onlyLettersNumbersSpaces}
            additionalClass={''}
            setEnterOn={setEnterOn}
          ></SearchFormComponent>
        </div>
      </div>
      <div className="voterbox-main-section">
        <div className="voterbox-list">
          <div className="slider-title">
            <h1>
              {t('electoral.rcrg.table.rgs.polling') +
                ' [' +
                tableData.length +
                ']'}
            </h1>
          </div>
          {[].map((e: any, i: number) => {
            return (
              <ItemVoterBox
                key={i}
                voterBox={e}
                index={i}
                selectedItem={selectedItem}
                setSelectedItem={setSelectedItem}
                t={t}
              ></ItemVoterBox>
            );
          })}
        </div>
        <div className="voterbox-map">
          <Map
            closePopupOnClick={false}
            center={center}
            zoom={zoom}
            ondragend={(map: any) => {
              setCoordinates([
                map.target.getCenter().lng,
                map.target.getCenter().lat,
              ]);
              let zoom = map.target.getZoom();
              setRadius(getMaxDistance(zoom));
            }}
            onzoomend={(map: any) => {
              setCoordinates([
                map.target.getCenter().lng,
                map.target.getCenter().lat,
              ]);
              let zoom = map.target.getZoom();
              setRadius(getMaxDistance(zoom));
            }}
          >
            <LayerControlComponent></LayerControlComponent>
            <MarkerClusterGroup
              showCoverageOnHover={false}
              spiderfyDistanceMultiplier={2}
              iconCreateFunction={createClusterCustomIcon}
            >
              {[].map((voterBoxes: any) => {
                return (
                  <Marker
                    key={voterBoxes._id}
                    position={[
                      voterBoxes.address?.geoLocation.coordinates[1],
                      voterBoxes.address?.geoLocation.coordinates[0],
                    ]}
                    icon={getMarkerIcon(voterBoxes._id)}
                    ref={(ref) => (markerRefs[voterBoxes._id] = ref)}
                    onclick={() => {
                      setSelectedItem(
                        voterBoxes._id === selectedItem ? '' : voterBoxes._id,
                      );
                    }}
                  ></Marker>
                );
              })}
            </MarkerClusterGroup>
          </Map>
        </div>
      </div>
    </div>
  );
};

export default Maps;
