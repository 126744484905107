import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import './style.scss';
import Image from 'react-bootstrap/Image';
import { findFlagUrlByCountryName } from 'country-flags-svg';
import Button from 'react-bootstrap/Button';

interface TopSelectProps {
  children?: any;
  countrySelected?: any;
  submit?: any;
}

const TopBarS: FunctionComponent<TopSelectProps> = ({
  children,
  countrySelected,
  submit,
}: any) => {
  const { t } = useTranslation();

  return (
    <div className="top-bar-s">
      <div className="title">
        <Image
          src={
            findFlagUrlByCountryName(countrySelected?.country.name || '') || ''
          }
        />
        <h2>{countrySelected?.country.name || t('select.lbl.none')}</h2>
      </div>
      <div className="selects">
        {children}
        {submit && (
          <Button className="c-button" variant="primary" onClick={submit}>
            Add Campaign
          </Button>
        )}
      </div>
    </div>
  );
};

export default TopBarS;
