import React, { Fragment } from "react";
import "../style.scss";
import { Trash, CorrectIcon, IncorrectIcon, EditIcon, HamburgerIcon } from '../../../assets/svg/index';
import { IoMdArrowDropright } from 'react-icons/io';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ModalDeleteQuestionComponent from "./modalDeleteQuestion";

interface QuestionItemProps {
    activeKey: any;
    masterIndex: any;
    stepDataQuestion: any;
    setStepData: any;
    stepData: any;
    editFun: any;
    reorderList: any;
    t: any;
}

export const QuestionItem: React.FunctionComponent<QuestionItemProps> = ({
    activeKey,
    masterIndex,
    stepDataQuestion,
    setStepData,
    stepData,
    editFun,
    reorderList,
    t,
}: any) => {

    const [accordionState, setAccordionState] = React.useState(false);
    const [modalDeleteQuestion, setModalDeleteQuestion] = React.useState(false);
    const [questionIndex, setQuestionIndex] = React.useState(Number);

    const deleteQuestion = (masterIndex: any) => {
        let newItems = [...stepData];
        newItems[activeKey].questions.splice(masterIndex,1);
        setStepData(newItems);
        setModalDeleteQuestion(false)
    };

    const onDragEnd = (result: any) => {
        const id = result.draggableId;
        if (result.destination && result.source.index !== result.destination.index)
        reorderList(result.source.index, result.destination.index, activeKey, masterIndex);
    };

    return (
        <Fragment>
            <div className="general-question">
                <div 
                className="question-box-row">
                    <div className="question-information">
                        <h1>{t('settings.trainings.lbl.question')}{' '}{masterIndex+1}:</h1>
                        <p>{stepDataQuestion.name}</p>
                    </div>
                    <div className="svg-information">
                        <EditIcon
                        onClick={() => {
                            editFun(activeKey, masterIndex);
                        }}
                        ></EditIcon>
                        <Trash
                        onClick={() => {
                            setModalDeleteQuestion(true);
                            setQuestionIndex(masterIndex);
                        }}
                        ></Trash>
                        <div 
                        className={accordionState?"accordion-arrow active":"accordion-arrow"}
                        onClick={() => {
                            setAccordionState(accordionState?false:true)
                        }}>
                            <IoMdArrowDropright
                                onClick={() => {}}
                            ></IoMdArrowDropright>
                        </div>
                    </div>
                </div>

                <div className={accordionState?"answer-box-row active":"answer-box-row"}>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable
                        droppableId="droppable"
                        ignoreContainerClipping={false}
                        >
                            {(provided, snapshot) => (
                                <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                className="list-droppable">
                                    {stepDataQuestion.answers?.map((answer: any, index: any) => {
                                        return(
                                        <Draggable
                                        key={index+''}
                                        draggableId={index+''}
                                        index={index}
                                        >
                                            {(provided, snapshot) => (
                                                <div 
                                                className="answer-detail-row" 
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}>
                                                    <div className="answer-information">
                                                        <HamburgerIcon></HamburgerIcon>
                                                        <h1>{t('settings.trainings.lbl.answer')}{' '}{index+1}:</h1>
                                                        <p>{answer.name}</p>
                                                    </div>
                                                    {answer.isValid && (
                                                        <div className="svg-information">
                                                            <p>{'Correcto'}</p>
                                                            <CorrectIcon
                                                                onClick={() => {}}
                                                            ></CorrectIcon>
                                                        </div>
                                                    )}
                                                    {!answer.isValid && (
                                                        <div className="svg-information">
                                                            <p>{'Incorrecto'}</p>
                                                            <IncorrectIcon
                                                                onClick={() => {}}
                                                            ></IncorrectIcon>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </Draggable>
                                        )
                                    })}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>

            </div>
            {modalDeleteQuestion && (
                <ModalDeleteQuestionComponent
                modalShow={modalDeleteQuestion}
                setModalShow={setModalDeleteQuestion}
                questionIndex={questionIndex}
                successFun={deleteQuestion}
                t={t}
                ></ModalDeleteQuestionComponent>
            )}
        </Fragment>
        
    );
};

export default QuestionItem;
