import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../redux/Store';
import { useTranslation } from 'react-i18next';

import { onlyDigits } from '../../../utils/utils';
import { assignNecessary } from '../../../redux/dashboard/dashboardAction';

import InputComponent from '../../../components/sub-component/input';
import StaticModal from '../../../dialogs/StaticModal';
import Container from 'react-bootstrap/Container';

interface ModalDeleteProps {
  modalShow: any;
  setModalShow: any;
  successFun: any;
  assign: any;
  alreadyAssigned: any;
}

export const ModalAssignBox: React.FunctionComponent<ModalDeleteProps> = ({
  modalShow,
  setModalShow,
  successFun,
  assign,
  alreadyAssigned,
}: any) => {
  const citySelected = useSelector((globalState: RootStore) => {
    return globalState.core.globalMunicipalitySelected;
  });

  const districtSelected: any = useSelector((globalState: RootStore) => {
    return globalState.dashboard.selected;
  });

  const defaultEdit = {
    _id: '',
    name: '',
    necessary: '',
    cityLicenseId: '',
    campaignId: '',
    isDistrict: true,
  };

  const [toSave, setToSave] = useState(defaultEdit);

  const [submitMessage, setSubmitMessage] = useState(String);
  const [submitConfirmation, setSubmitConfirmation] = useState(false);

  const [loadData, setLoadData] = useState(false);

  const inputNecessaryRef: any = useRef(null);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  console.log('assign', assign);
  console.log('alreadyAssigned', alreadyAssigned);

  const getMunicipality = () => {
    setToSave((prevState) => ({
      ...prevState,
      cityLicenseId: citySelected?._id || '',
      campaignId: localStorage.campaignId,
    }));
  };

  const submitFun = (validSubmit: any) => {
    if (validSubmit) {
      setSubmitMessage(validSubmit);
      setSubmitConfirmation(true);
    } else {
      setModalShow(false);
      dispatch(
        assignNecessary({
          ...toSave,
        }),
      );
    }
  };

  const validSubmit = () => {
    let newStringValue = verifyFields();
    submitFun(newStringValue);
  };

  const verifyFields = () => {
    if (toSave.necessary === '') {
      return t('territorial.volunteer.modal.error.name');
    } else {
      return '';
    }
  };

  useEffect(() => {
    setToSave((prevState) => ({
      ...prevState,
      _id: assign._id,
      name: assign.name,
      necessary: assign.necessary,
      isDistrict: districtSelected._id ? false : true,
    }));
    setLoadData(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assign]);

  useEffect(() => {
    if (loadData) {
      getMunicipality();
      if (
        (districtSelected._id && districtSelected.necessary !== 0) ||
        !districtSelected._id
      ) {
        inputNecessaryRef.current.focus();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadData]);

  return (
    <StaticModal
      show={modalShow}
      title={
        t('dashboard.dropdown.assignTo') +
        ' ' +
        (districtSelected._id
          ? t('dashboard.lbl.section')
          : t('dashboard.lbl.district')) +
        ' ' +
        toSave.name
      }
      closeText={t('submit.cancel')}
      showConfirmBtn={
        districtSelected._id && districtSelected.necessary === 0 ? false : true
      }
      confirmText={t('submit.confirm')}
      onHide={() => setModalShow(false)}
      submitFun={validSubmit}
      validBtn={false}
      size="sm"
      sizeClass="md modal-rc"
      showToAssign={
        districtSelected._id && districtSelected.necessary !== 0 ? true : false
      }
      toAssign={
        districtSelected.necessary
          ? districtSelected.necessary - alreadyAssigned
          : 0
      }
    >
      <>
        <Container className="dialog-assign-needed">
          {submitConfirmation && (
            <div className="col-item-message">
              <p>{submitMessage}</p>
            </div>
          )}
          <div className="row-item-modal first-row">
            {((districtSelected._id && districtSelected.necessary !== 0) ||
              !districtSelected._id) && (
              <div className="col-item-modal">
                <InputComponent
                  placeholder={t('dashboard.lbl.necesary')}
                  length={50}
                  onChange={(value: string) => {
                    let valueToSet = value;
                    let valueRemain;
                    if (districtSelected.necessary) {
                      valueRemain =
                        districtSelected.necessary -
                        alreadyAssigned +
                        assign.necessary;
                      if (parseInt(value) > valueRemain) {
                        valueToSet = valueRemain.toString();
                      }
                    }

                    setToSave((prevState) => ({
                      ...prevState,
                      necessary: valueToSet,
                    }));
                  }}
                  title={t('dashboard.lbl.necesary')}
                  value={toSave.necessary}
                  regExp={onlyDigits}
                  horizontal={false}
                  refElement={inputNecessaryRef}
                  focusAction={() => {
                    validSubmit();
                  }}
                ></InputComponent>
              </div>
            )}
            {districtSelected._id && districtSelected.necessary === 0 && (
              <div className="col-item-message">
                <p>{t('dashboard.modal.lbl.assignMessage')}</p>
              </div>
            )}
          </div>
        </Container>
      </>
    </StaticModal>
  );
};

export default ModalAssignBox;
