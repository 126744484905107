import {
  ROUTES_FAIL,
  ROUTES_LOADING,
  ROUTES_SUCCESS,
  Routes,
  RoutesDispatchTypes,
  ROUTES_SINGLE_SUCCESS,
  ROUTES_LOAD_SUCCESS,
  ROUTES_LOAD_LOADING,
  ROUTES_LOAD_FAIL,
  ROUTES_SELECT_AREA,
  ROUTES_GENERAL_LOAD_SUCCESS,
  UPDATE_OF_ENABLE,
} from './RoutesTypes';

interface RoutesState {
  loading: boolean;
  Routes?: any[];
  route?: any[];
  RoutesGlobal?: Routes[];
  generalAreas: any[];
  createdAreas: any[];
  selectedArea?: any;
}

const defaultState: RoutesState = {
  loading: false,
  Routes: [],
  route: [],
  generalAreas: [],
  createdAreas: [],
};

const RoutesReducer = (
  state: RoutesState = defaultState,
  action: RoutesDispatchTypes,
): RoutesState => {
  switch (action.type) {
    case ROUTES_FAIL:
      return {
        ...state,
        loading: false,
      };
    case ROUTES_LOADING:
      return {
        ...state,
        loading: true,
      };
    case ROUTES_SUCCESS:
      const list1 = [];
      list1.push(...(action.payload || []));
      return {
        ...state,
        loading: false,
        Routes: list1,
        RoutesGlobal: action.payload,
      };
    case ROUTES_SINGLE_SUCCESS:
      return {
        ...state,
        loading: false,
        route: action.payload || [],
      };
    case ROUTES_GENERAL_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        generalAreas: action.generalAreas || [],
      };
    case ROUTES_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        createdAreas: action.createdAreas || [],
      };
    case UPDATE_OF_ENABLE:
      return {
        ...state,
        createdAreas: state.createdAreas.map((e) => {
          if (e._id === action.payload._id) {
            return { ...e, enable: action.payload.enable };
          } else return e;
        }),
      };
    case ROUTES_LOAD_LOADING:
      return {
        ...state,
        loading: false,
      };
    case ROUTES_LOAD_FAIL:
      return {
        ...state,
        loading: false,
      };
    case ROUTES_SELECT_AREA:
      return {
        ...state,
        selectedArea: action.selectedArea,
      };
    default:
      return state;
  }
};

export default RoutesReducer;
