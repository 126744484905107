import React, { useState, useEffect, FunctionComponent } from 'react';
import { RootStore } from '../../../redux/Store';
import { useDispatch, useSelector } from 'react-redux';
import '../style.scss';
import VotersBoxMapIconGreen from '../../../assets/svg/green_place.svg';
import L from 'leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { Map, Marker, Popup } from 'react-leaflet';
import LayerControlComponent from '../../../components/sub-component/layerControl';
import { useTranslation } from 'react-i18next';
import LoadingModal from '../../../dialogs/loadingModal';
import {
  postCoverageMap,
  putUpdateUserTeamCoordinates,
} from '../../../redux/teams/teamsActions';
import { CoverageLevel } from '../../../redux/teams/teamsReducer';
import { createClusterCustomIcon } from '../../../components/sub-component/markers';
import { renderToString } from 'react-dom/server';
import {
  CheckGreenIcon,
  ClosedEye,
  EditIcon,
  Eye,
  Hide,
  See,
  Trash,
  UserMarker,
} from '../../../assets/svg';
import {
  getMaxDistance,
  getMaxZoom,
  numberWithCommas,
} from '../../../utils/utils';
import SearchSelectorComponent from '../../../components/sub-component/searchSelector';
import Button from 'react-bootstrap/Button';

const empty: {
  en: string;
  es: string;
  role: string;
  level: number;
  color: string;
}[] = [];
const emptyArray: string[] = [];
let boundEmpty: any;
const CoverageMap: FunctionComponent = () => {
  //const selectedCoordinator = useSelector((globalState: RootStore) => globalState.teams.selectedCoordinator);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const citySelected = useSelector((globalState: RootStore) => {
    return globalState.core.globalMunicipalitySelected;
  });

  const userData = useSelector((globalState: RootStore) => {
    return globalState.core.userData;
  });

  const coverageMap = useSelector((globalState: RootStore) => {
    return globalState.teams.coverageMap;
  });

  const coverageLoading = useSelector((globalState: RootStore) => {
    return globalState.teams.coverageLoading;
  });

  const [listColors, setListColors] = useState(empty);
  const [activeLevels, setActiveLevels] = useState(emptyArray);
  const [bounds, setBounds] = useState(boundEmpty);
  const [selected, setSelected] = useState(boundEmpty);

  const [marker, setMarker] = useState(boundEmpty);

  const defaultPosition = {
    lat: 32.7157431,
    lng: -117.1888535,
    zoom: 11,
  };

  const position: [number, number] =
    citySelected?.defaultCenter !== undefined
      ? citySelected?.defaultCenter
      : [defaultPosition.lat, defaultPosition.lng];

  const [center, setCenter] = React.useState(position);
  const [zoom, setZoom] = React.useState(
    citySelected?.defaultZoom !== undefined
      ? citySelected?.defaultZoom
      : defaultPosition.zoom,
  );

  useEffect(() => {
    if (citySelected) {
      setCenter(
        citySelected?.defaultCenter !== undefined
          ? citySelected?.defaultCenter
          : [defaultPosition.lat, defaultPosition.lng],
      );
      setZoom(
        citySelected?.defaultZoom !== undefined
          ? citySelected?.defaultZoom
          : defaultPosition.zoom,
      );
    }
    const array: any[] = [];
    citySelected?.capturistUserLevels.forEach((e) => {
      let r1 = (Math.random() * 255).toFixed(0);
      let r2 = (Math.random() * 255).toFixed(0);
      let r3 = (Math.random() * 255).toFixed(0);
      let color = `rgba(${r1},${r2},${r3},1)`;
      // if (localStorage.level === '' || e.level < +localStorage.level)
      array.push({
        ...e,
        color,
      });
    });
    setListColors(array);
    setActiveLevels(
      array.map((e) => {
        return e.level.toString();
      }) || [],
    );
    getData(
      array.map((e) => {
        return e.level.toString();
      }) || [],
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [citySelected]);

  useEffect(() => {
    if (coverageMap) {
      getBounds(activeLevels);
    }
  }, [coverageMap]);

  const calcCrow = (lat1: any, lon1: any, lat2: any, lon2: any) => {
    const R = 6371; // km
    const dLat = toRad(lat2 - lat1);
    const dLon = toRad(lon2 - lon1);
    lat1 = toRad(lat1);
    lat2 = toRad(lat2);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c;
    return d * 10000;
  };

  // Converts numeric degrees to radians
  const toRad = (Value: any) => {
    return (Value * Math.PI) / 180;
  };

  const getBounds = (activeLevel: string[]) => {
    const lngs: any[] = [];
    const lats: any[] = [];

    coverageMap.forEach((coverageByLevel: CoverageLevel) => {
      if (activeLevel.indexOf(coverageByLevel._id.level.toString()) !== -1)
        coverageByLevel.users.forEach((u) => {
          if (
            u.coordinates &&
            u.coordinates.length === 2 &&
            u.coordinates[0] !== -117.18885351 &&
            u.coordinates[1] !== 32.71574311
          ) {
            lngs.push(u.coordinates[0]);
            lats.push(u.coordinates[1]);
          }
        });
    });
    if (lngs.length > 0 && lats.length > 0) {
      const topMost = Math.max(...lngs);
      const leftMost = Math.min(...lats);
      const rightMost = Math.max(...lats);
      const bottomMost = Math.min(...lngs);

      const lat = (leftMost + rightMost) / 2;
      const lng = (topMost + bottomMost) / 2;
      const km = calcCrow(rightMost, topMost, leftMost, bottomMost);

      const zoom = getMaxZoom(km);
      if (lat && lng) {
        setCenter([lat, lng]);
      }
      if (zoom) setZoom(zoom);
    }
    // console.log('positions', positions);
    // const bounds = L.latLngBounds(positions);
    // if (bounds.isValid()) {
    //   setBounds(bounds);
    // } else {
    //   setBounds(undefined);
    // }
  };

  const getData = (list: string[]) => {
    dispatch(
      postCoverageMap({
        campaignId: userData?.campaignId,
        cities: citySelected?._id,
        levels: list.map((e) => +e),
      }),
    );
  };

  const getMarkerIcon = (name: string, level: number) => {
    return L.divIcon({
      html: renderToString(
        <div>
          <UserMarker></UserMarker>
        </div>,
      ),
      iconSize: new L.Point(30, 30),
      className: 'leaflet-div-icon-unSelected marker-level-' + level,
    });
  };

  const getStyles = () => {
    let styles = '';
    listColors?.forEach((e: any, index: number) => {
      if (e) {
        if (styles === '') {
          styles = `.marker-level-${e.level} .Path-2, .marker-level-${e.level} .Path-1{
            fill:${e.color} !important;
            }
          `;
        } else {
          styles =
            styles +
            `   .marker-level-${e.level} .Path-2, .marker-level-${e.level} .Path-1{
                    fill:${e.color} !important;
            }
          `;
        }
      }
    });
    return styles;
  };

  const getAmount = (type: number) => {
    let amount = 0;
    if (type === 0) {
      coverageMap.forEach((e) => {
        e.users.forEach((u) => {
          if (
            u.coordinates &&
            u.coordinates.length === 2 &&
            u.coordinates[0] !== -117.18885351 &&
            u.coordinates[1] !== 32.71574311
          )
            amount = amount + 1;
        });
      });
      return amount;
    } else {
      coverageMap.forEach((e) => {
        if (e._id.level === type)
          e.users.forEach((u) => {
            if (
              u.coordinates &&
              u.coordinates.length === 2 &&
              u.coordinates[0] !== -117.18885351 &&
              u.coordinates[1] !== 32.71574311
            )
              amount = amount + 1;
          });
      });
      return amount;
    }
  };

  const getColor = (type: number) => {
    let color = '';
    listColors.forEach((e) => {
      if (e.level === type) color = e.color;
    });
    return color;
  };

  const getRoleName = (type: number) => {
    let role = '';
    listColors.forEach((e: any) => {
      if (e.level === type) role = e[t('language')];
    });
    return role;
  };

  const updatePosition = (selected: any) => {
    let geoLocation = null;
    if (marker) {
      geoLocation = {
        type: 'Point',
        coordinates: [marker.lng, marker.lat],
      };
    }
    dispatch(
      putUpdateUserTeamCoordinates(
        {
          _id: selected._id,
          geoLocation,
        },
        () => {
          setMarker(undefined);
          setSelected(undefined);

          getData(
            listColors.map((e) => {
              return e.level.toString();
            }) || [],
          );
        },
      ),
    );
  };

  const sendToHide = (selected: any) => {
    let geoLocation = null;
    geoLocation = {
      type: 'Point',
      coordinates: [-117.18885351, 32.71574311],
    };
    dispatch(
      putUpdateUserTeamCoordinates(
        {
          _id: selected._id,
          geoLocation,
        },
        () => {
          setMarker(undefined);
          setSelected(undefined);

          getData(
            listColors.map((e) => {
              return e.level.toString();
            }) || [],
          );
        },
      ),
    );
  };

  return (
    <>
      <div className="coverage-map-container">
        <div className="selector-users-box">
          {marker && (
            <div className="button-box">
              <Button
                variant="primary"
                onClick={() => {
                  updatePosition(selected);
                }}
              >
                {t('submit.save')}
              </Button>
            </div>
          )}
          {coverageMap.filter((e) => {
            if (
              e.users.filter((u) => {
                if (
                  u.coordinates === undefined ||
                  (u.coordinates[0] === -117.18885351 &&
                    u.coordinates[1] === 32.71574311)
                )
                  return true;
              }).length > 0
            )
              return true;
          }).length > 0 && (
            <SearchSelectorComponent
              title={''}
              options={coverageMap.sort((a, b) => b._id.level - a._id.level)}
              horizontal={false}
              fun={(value: any) => {
                setSelected(value);
              }}
              listColors={listColors}
              optionsSelected={selected}
              sendToHide={sendToHide}
            ></SearchSelectorComponent>
          )}
        </div>
        <style>{getStyles()}</style>
        <Map
          closePopupOnClick={false}
          center={center}
          zoom={zoom}
          zoomControl={false}
          bounds={bounds}
          onclick={(e) => {
            if (selected) {
              setMarker(e.latlng);
            }
          }}
        >
          <div className="filter-legend-box">
            <div className="title">
              <h2>{t('territorial.teams.coverage.lbl.teams')}</h2>
            </div>
            <div className="total-box">
              <h2>{t('territorial.volunteer.slider.lbl.total')}:</h2>
              <h2>{numberWithCommas(getAmount(0))}</h2>
            </div>
            {listColors?.map((c: any) => {
              return (
                <div
                  className={
                    activeLevels.indexOf(c.level.toString()) !== -1
                      ? 'level-item'
                      : 'level-item active'
                  }
                  key={c.level}
                  onClick={() => {
                    const array = [...activeLevels];
                    const index = array.indexOf(c.level.toString());
                    if (index === -1) {
                      array.push(c.level.toString());
                      setActiveLevels(array);
                    } else {
                      array.splice(index, 1);
                      setActiveLevels(array);
                    }
                    getBounds(array);
                    // getData(array);
                  }}
                >
                  <div className="item-title">
                    <div
                      className="color-box"
                      style={{ backgroundColor: c.color }}
                    ></div>
                    <h3>{c[t('language')]}</h3>
                  </div>
                  <div className="item-action">
                    <h3>{numberWithCommas(getAmount(c.level))}</h3>
                    {activeLevels.indexOf(c.level.toString()) === -1 ? (
                      <See></See>
                    ) : (
                      <Hide></Hide>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          <LayerControlComponent></LayerControlComponent>
          {marker && (
            <Marker
              position={marker}
              icon={getMarkerIcon(selected?.name || '', 0)}
              onclick={() => {
                //   setSelectedItem(voterBoxes._id);
              }}
            ></Marker>
          )}

          <MarkerClusterGroup
            showCoverageOnHover={false}
            spiderfyDistanceMultiplier={2}
            iconCreateFunction={createClusterCustomIcon}
          >
            {coverageMap.map((coverageByLevel: CoverageLevel) => {
              const color = getColor(coverageByLevel._id.level);
              const roleName = getRoleName(coverageByLevel._id.level);
              if (
                activeLevels.indexOf(coverageByLevel._id.level.toString()) !==
                -1
              )
                return coverageByLevel.users.map((u) => {
                  if (
                    u.coordinates &&
                    u.coordinates.length === 2 &&
                    u.coordinates[0] !== -117.18885351 &&
                    u.coordinates[1] !== 32.71574311 &&
                    (!selected || selected._id !== u._id)
                  )
                    return (
                      <Marker
                        key={u._id}
                        position={[u.coordinates[1], u.coordinates[0]]}
                        icon={getMarkerIcon(u.name, coverageByLevel._id.level)}
                        onclick={() => {
                          //   setSelectedItem(voterBoxes._id);
                        }}
                      >
                        <Popup>
                          <div
                            className="coverage-popup-box"
                            onClick={() => {
                              // closePopupOnClick(popup);
                            }}
                          >
                            <div className="body">
                              <h2>{u.name}</h2>
                              <div className="body-box">
                                <div className="role-box">
                                  <div
                                    className="color-box"
                                    style={{ backgroundColor: color }}
                                  ></div>
                                  <h3>{roleName}</h3>
                                </div>
                                <div className="actions-box">
                                  <EditIcon
                                    onClick={() => {
                                      // updatePosition();
                                      setMarker({
                                        lat: u.coordinates[1],
                                        lng: u.coordinates[0],
                                      });
                                      setSelected(u);
                                    }}
                                  >
                                    {t('settings.polling.dropdown.edit')}
                                  </EditIcon>
                                  <Trash
                                    onClick={() => {
                                      setMarker(undefined);
                                      setSelected(u);
                                      updatePosition(u);
                                    }}
                                  >
                                    {t('settings.polling.dropdown.delete')}
                                  </Trash>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Popup>
                      </Marker>
                    );
                });
            })}
          </MarkerClusterGroup>
        </Map>
      </div>
      {coverageLoading && (
        <LoadingModal show={coverageLoading} onHide={() => {}}></LoadingModal>
      )}
    </>
  );
};

export default CoverageMap;
