import React, { useEffect, useState } from 'react';
//import { useTranslation } from 'react-i18next';
import Image from 'react-bootstrap/Image';
import { DeleteBlackIcon, EditIcon } from '../../../../assets/svg';
import moment from 'moment';
import Button from 'react-bootstrap/Button';
import { useDispatch, useSelector } from 'react-redux';
import { updateLicense } from '../../../../redux/campaigns/campaignsActions';
import {
  Campaign,
  City,
  SET_SELECTED_LICENSE_CITY,
} from '../../../../redux/campaigns/campaignsTypes';
import SelectorFormsComponent from '../../../../components/sub-component/selectorForms';
import { RootStore } from '../../../../redux/Store';
import { getMunicipality } from '../../../../redux/core/CoreActions';
import Col from 'react-bootstrap/Col';
import DeleteCityLicenseModalComponent from './deleteCityLicense';
import { capitalizeWords } from '../../../../utils/utils';

interface CardLicenseProps {
  licenseSelected?: Campaign;
  cityLicense?: City;
}

// tslint:disable-next-line: prefer-const
let defaultData: Campaign;
// tslint:disable-next-line: prefer-const
let defaultData2: City;

const CardLicense: React.FunctionComponent<CardLicenseProps> = ({
  licenseSelected,
  cityLicense,
}: CardLicenseProps) => {
  const [editMode, setEditMode] = React.useState(false);
  const [editData, setEditData] = React.useState(defaultData);
  const [editDataCity, setEditDataCity] = React.useState(defaultData2);
  const [submitConfirmation, setSubmitConfirmation] = useState(false);
  const [submitMessage, setSubmitMessage] = useState(String);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);

  const municipality = useSelector((globalState: RootStore) => {
    return globalState.core.municipality;
  });
  const parties = useSelector((globalState: RootStore) => {
    return globalState.party.parties;
  });

  const getParty = () => {
    return parties.find((e) => licenseSelected?.partyId === e._id);
  };

  const dispatch = useDispatch();

  const onSave = () => {
    if (licenseSelected && cityLicense) {
      const license = editData;
      const city = cityLicense;
      const cities: any = [];
      license.cities.forEach((e: City) => {
        if (e._id === city._id) {
          cities.push({
            ...editDataCity,
          });
        } else {
          cities.push(e);
        }
      });
      license.cities = [...cities];
      dispatch(
        updateLicense(
          license,
          (data: any) => {
            let city2: any = null;
            data.cities.forEach((e: City) => {
              if (e._id === editDataCity._id) {
                city2 = e;
              }
            });
            dispatch({
              type: SET_SELECTED_LICENSE_CITY,
              licenseSelected: data,
              cityLicense: city2,
            });
            setEditMode(false);
          },
          (err: any) => {
            console.log(err);
          },
        ),
      );
    }
  };

  useEffect(() => {
    if (licenseSelected) {
      setEditData(licenseSelected);
    }
    if (cityLicense) {
      setEditDataCity(cityLicense);
      getMuni(cityLicense.stateId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [licenseSelected, cityLicense]);

  const getMuni = (id: string) => {
    dispatch(getMunicipality(id, () => {}));
  };

  const changeCity = (_id: string) => {
    const mun = municipality?.find((e) => e._id === _id);
    if (mun)
      setEditDataCity({
        ...editDataCity,
        municipalityId: mun._id,
        name: mun.name,
        geoLimits: mun.geoCoordinates,
      });
  };

  const validSubmit = (data: any) => {
    if (editData.candidateName === '') {
      return (
        (editData.campaignType === 'GOVERNOR' ? 'Governor' : 'Mayor') +
        ' name is empty'
      );
    } else if (editData.contact === '' || editData.contact.length < 10) {
      return 'Please enter a valid cellphone';
    } else {
      return '';
    }
  };

  const submitFun = () => {
    if (validSubmit(1) !== '') {
      setSubmitMessage(validSubmit(1));
      setSubmitConfirmation(true);
    } else {
      onSave();
    }
  };

  return (
    <>
      <div className="card-license-info">
        <div className="top-header">
          <h2>General Info</h2>
          <div
            className={
              (editDataCity?.active && editData.active
                ? 'license-box'
                : 'license-box-de-active') + (editMode ? ' active' : '')
            }
            onClick={() => {
              if (editMode && editData.active) {
                setEditDataCity((prevState: any) => ({
                  ...prevState,
                  active: !prevState.active,
                }));
              }
            }}
          >
            <div
              className={
                editDataCity?.active && editData.active
                  ? 'triangle-green'
                  : 'triangle-grey'
              }
            ></div>
            <p>
              License:{' '}
              <i>
                {editDataCity?.active && editData.active
                  ? 'Active'
                  : 'De-Active'}
              </i>
            </p>
          </div>
        </div>
        <div className="body-box">
          {submitConfirmation && (
            <Col xs={12} md={12} className="col-item-message">
              <p className="">{submitMessage}</p>
            </Col>
          )}
          <div className="party-box">
            <div className="box-column">
              <div className="title-box">
                <h3>Party</h3>
              </div>
              <div className="name-box">
                <Image src={getParty()?.logoPath || ''}></Image>
                <p>{capitalizeWords(getParty()?.name || '')}</p>
              </div>
            </div>
            {!editMode && (
              <div className="action-buttons">
                <DeleteBlackIcon
                  onClick={() => {
                    setShowDeleteModal(true);
                  }}
                ></DeleteBlackIcon>
                <EditIcon
                  onClick={() => {
                    setEditMode(true);
                  }}
                ></EditIcon>
              </div>
            )}
          </div>
          <div className="double-box">
            <div className="box-column">
              <div className="title-box">
                <h3>State</h3>
              </div>
              {!editMode ? (
                <div className="name-box">
                  <p>{licenseSelected?.states[0].name}</p>
                </div>
              ) : (
                <div className="name-box">
                  <p>{licenseSelected?.states[0].name}</p>
                </div>
              )}
            </div>
            <div className="box-column">
              <div className="title-box">
                <h3>City</h3>
              </div>
              {!editMode ? (
                <div className="name-box">
                  <p>{cityLicense?.name}</p>
                </div>
              ) : (
                <SelectorFormsComponent
                  options={municipality?.filter((e) => {
                    if (
                      e._id === editDataCity?.municipalityId ||
                      editData.cities.find(
                        (d) => d.municipalityId === e._id,
                      ) === undefined
                    )
                      return e;
                  })}
                  title={''}
                  defaultSelector={editDataCity?.municipalityId || ''}
                  horizontal={false}
                  fun={(value: string) => {
                    changeCity(value);
                  }}
                ></SelectorFormsComponent>
              )}
            </div>
          </div>
          <div className="license-type-box">
            <div className="box-column">
              <div className="title-box">
                <h3>License</h3>
              </div>
              {!editMode ? (
                <div className="name-box">
                  <span
                    className={
                      licenseSelected?.campaignType === 'GOVERNOR'
                        ? 'governor-type'
                        : 'mayor-type'
                    }
                  >
                    {licenseSelected?.campaignType === 'GOVERNOR'
                      ? 'Governor'
                      : 'Mayor'}
                  </span>
                </div>
              ) : (
                <div className="name-box">
                  <span
                    className={
                      licenseSelected?.campaignType === 'GOVERNOR'
                        ? 'governor-type'
                        : 'mayor-type'
                    }
                  >
                    {licenseSelected?.campaignType === 'GOVERNOR'
                      ? 'Governor'
                      : 'Mayor'}
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className="governor-box">
            <div className="box-column">
              <div className="title-box">
                <h3>
                  {licenseSelected?.campaignType === 'GOVERNOR'
                    ? 'Governor'
                    : 'Mayor'}
                </h3>
              </div>
              {!editMode ? (
                <div className="name-box">
                  <p>{licenseSelected?.candidateName}</p>
                </div>
              ) : (
                <div className="name-box">
                  <p>{licenseSelected?.candidateName}</p>
                </div>
              )}
            </div>
          </div>
          <div className="boss-box">
            <div className="box-column">
              <div className="title-box">
                <h3>Campaign Manager</h3>
              </div>
              {!editMode ? (
                <div className="name-box">
                  <p>{licenseSelected?.bossName || ''}</p>
                </div>
              ) : (
                <div className="name-box">
                  <p>{licenseSelected?.bossName || ''}</p>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="extra-box">
          <div className="double-box">
            <div className="box-column">
              <div className="title-box">
                <h3>Duration</h3>
              </div>
              {!editMode ? (
                <div className="name-box">
                  <p>
                    {moment(licenseSelected?.endDate)
                      .locale('en')
                      .format('D/MMM/YY')}
                  </p>
                </div>
              ) : (
                <div className="name-box">
                  <p>
                    {moment(licenseSelected?.endDate)
                      .locale('en')
                      .format('D/MMM/YY')}
                  </p>
                </div>
              )}
            </div>
            <div className="box-column">
              <div className="title-box">
                <h3>Contact</h3>
              </div>
              {!editMode ? (
                <div className="name-box">
                  <p>{licenseSelected?.contact}</p>
                </div>
              ) : (
                <div className="name-box">
                  <p>{licenseSelected?.contact}</p>
                </div>
              )}
            </div>
          </div>
          <div className="double-box">
            <div className="box-column">
              <div className="title-box">
                <h3>Email</h3>
              </div>
              <div className="name-box">
                <p>{licenseSelected?.email}</p>
              </div>
            </div>
            <div className="box-column">
              <div className="title-box">
                {/*<h3></h3>*/}
              </div>
              <div className="name-box">
                <p></p>
              </div>
            </div>
          </div>
          {editMode && (
            <div className="buttons-row">
              <Button
                variant="outline-dark"
                onClick={() => {
                  setEditMode(false);
                  setSubmitConfirmation(false);
                  if (licenseSelected) {
                    setEditData(licenseSelected);
                  }
                  if (cityLicense) {
                    setEditDataCity(cityLicense);
                  }
                }}
              >
                Cancel
              </Button>
              <Button
                // disabled={validBtn}
                variant="primary"
                onClick={() => {
                  submitFun();
                }}
              >
                Save
              </Button>
            </div>
          )}
        </div>
      </div>
      <DeleteCityLicenseModalComponent
        modalShow={showDeleteModal}
        setModalShow={setShowDeleteModal}
        licenseSelected={licenseSelected}
        cityLicense={cityLicense}
      ></DeleteCityLicenseModalComponent>
    </>
  );
};

export default CardLicense;
