import {
  TRAININGS_FAIL,
  TRAININGS_LOADING,
  TRAININGS_SUCCESS,
  TRAININGS_ROLE_SUCCESS,
  TRAININGS_PAGE_SUCCESS,
  Trainings,
  TrainingsDispatchTypes,
  TRAININGS_SINGLE_SUCCESS,
} from './TrainingsTypes';

interface TrainingsState {
  loading: boolean;
  trainings: any[];
  training?: any[];
  search: string;
  trainingsGlobal?: Trainings[];
  page?: string;
  totalPage: number;
  //=========================
  trainingsByRole: any[];
  totalByRole: number;
}

const defaultState: TrainingsState = {
  loading: false,
  totalPage: 1,
  trainings: [],
  training: [],
  search: '',
  trainingsByRole: [],
  totalByRole: 0,
};

const trainingsReducer = (
  state: TrainingsState = defaultState,
  action: TrainingsDispatchTypes,
): TrainingsState => {
  switch (action.type) {
    case TRAININGS_FAIL:
      return {
        ...state,
        loading: false,
      };
    case TRAININGS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case TRAININGS_SUCCESS:
      const list1 = [];
      const data: any = action.payload;
      list1.push(...(data.trainings || []));
      return {
        ...state,
        loading: false,
        trainings: list1,
        trainingsGlobal: action.payload,
        totalPage: Math.ceil(action.payload.length / 11),
        search: data.search,
      };
    case TRAININGS_ROLE_SUCCESS:
      const list3 = [];
      let listCount;
      const data2: any = action.payload;
      list3.push(...(data2.trainings || []));
      listCount = data2.totalSteps;
      return {
        ...state,
        trainingsByRole: list3,
        totalByRole: listCount,
      };
    case TRAININGS_SINGLE_SUCCESS:
      return {
        ...state,
        loading: false,
        training: action.payload || [],
      };
    case TRAININGS_PAGE_SUCCESS:
      const page = Number.parseInt(action.page, 0) - 1;
      const skip = page * 11;
      const list2 = [];
      list2.push(...(state.trainingsGlobal || []));
      const vBP = list2?.splice(skip, 11);
      return {
        ...state,
        loading: false,
        trainings: vBP,
        page: action.page,
      };
    default:
      return state;
  }
};

export default trainingsReducer;
