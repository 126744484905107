import React from 'react';
import { RootStore } from '../redux/Store';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as netWorking from '../redux/netWorking';

import { XLS_TYPE } from '../utils/utils';
import { RGRC_LOADING, RGRC_FAIL } from '../redux/RgRc/RgRcTypes';
import {
  VOTERSBOX_ELECTORAL_LOADING,
  VOTERSBOX_ELECTORAL_FAIL,
  VOTERSBOX_LOADING,
  VOTERSBOX_FAIL,
} from '../redux/votersBox/VotersBoxTypes';

import Dropzone from '../components/sub-component/dropzone';
import Container from 'react-bootstrap/Container';
import StaticModal from './StaticModal';

interface ModalXLSProps {
  modalShow: any;
  setModalShow: any;
  type: any;
  teamId?: any;
  voterBoxId?: any;
  successFun?: any;
  cityLicenseId?: any;
  municipalityId?: any;
  role?: any;
  states?: any;
  cities?: any;
  levelList?: any;
}

export const ModalAddXLS: React.FunctionComponent<ModalXLSProps> = ({
  modalShow,
  setModalShow,
  type,
  voterBoxId = '',
  cityLicenseId = null,
  municipalityId = null,
  states = null,
  cities = null,
  successFun,
}: any) => {
  const citySelected: any = useSelector((globalState: RootStore) => {
    return globalState.core.globalMunicipalitySelected;
  });

  const [routeFile, setRouteFile] = React.useState(null);
  const dispatch = useDispatch();

  const startLoadingType = () => {
    if (type === XLS_TYPE.VOTERS_BOX_XLS_TYPE) {
      dispatch({ type: VOTERSBOX_LOADING });
    }
    if (type === XLS_TYPE.BINGO_XLS_TYPE) {
      dispatch({ type: VOTERSBOX_LOADING });
    }
    if (type === XLS_TYPE.RG_RC_XLS_TYPE) {
      dispatch({ type: RGRC_LOADING });
    }
    if (type === XLS_TYPE.VB_ASSIGN_RGRC_XLS_TYPE) {
      dispatch({ type: VOTERSBOX_ELECTORAL_LOADING });
    }
  };

  const finishLoadingType = () => {
    if (type === XLS_TYPE.VOTERS_BOX_XLS_TYPE) {
      dispatch({ type: VOTERSBOX_FAIL });
    }
    if (type === XLS_TYPE.BINGO_XLS_TYPE) {
      dispatch({ type: VOTERSBOX_FAIL });
    }
    if (type === XLS_TYPE.RG_RC_XLS_TYPE) {
      dispatch({ type: RGRC_FAIL });
    }
    if (type === XLS_TYPE.VB_ASSIGN_RGRC_XLS_TYPE) {
      dispatch({ type: VOTERSBOX_ELECTORAL_FAIL });
    }
  };

  const errorLoadingType = () => {
    if (type === XLS_TYPE.VOTERS_BOX_XLS_TYPE) {
      dispatch({ type: VOTERSBOX_FAIL });
    }
    if (type === XLS_TYPE.BINGO_XLS_TYPE) {
      dispatch({ type: VOTERSBOX_FAIL });
    }
    if (type === XLS_TYPE.RG_RC_XLS_TYPE) {
      dispatch({ type: RGRC_FAIL });
    }
    if (type === XLS_TYPE.VB_ASSIGN_RGRC_XLS_TYPE) {
      dispatch({ type: VOTERSBOX_ELECTORAL_FAIL });
    }
  };

  const { t } = useTranslation();
  const hideFun = () => {
    if (routeFile !== null) {
      netWorking.post(
        netWorking.ConfirmUpload,
        {
          type,
          route: routeFile,
          isValid: false,
          municipalityId,
          cityLicenseId,
          timezone:
            citySelected?.timeZones.length > 0
              ? citySelected?.timeZones[0]._id
              : '',
          states,
          cities,
          voterBoxId,
        },
        (res: any) => {
          successFun(res);
        },
        () => {},
      );
    }
    setModalShow(false);
  };

  const submitFun = () => {
    if (routeFile !== null) {
      startLoadingType();
      netWorking.post(
        netWorking.ConfirmUpload,
        {
          type,
          route: routeFile,
          isValid: true,
          municipalityId,
          cityLicenseId,
          timezone:
            citySelected?.timeZones.length > 0
              ? citySelected?.timeZones[0]._id
              : '',
          states,
          cities,
          voterBoxId,
        },
        (res: any) => {
          console.log('res', res);
          finishLoadingType();
          successFun(res);
          setModalShow(false);
        },
        () => {
          errorLoadingType();
        },
      );
    }
  };

  return (
    <StaticModal
      show={modalShow}
      title={t('XLS.modal.lbl.uploadFile')}
      closeText={t('XLS.modal.button.cancel')}
      confirmText={t('XLS.modal.button.save')}
      onHide={hideFun}
      submitFun={submitFun}
      validBtn={routeFile === null}
      size="xl"
      sizeClass="md modal-rc"
    >
      <Container>
        <Dropzone
          type={type}
          municipalityId={municipalityId}
          cityLicenseId={citySelected?._id}
          setRouteFile={setRouteFile}
        ></Dropzone>
      </Container>
    </StaticModal>
  );
};

export default ModalAddXLS;
