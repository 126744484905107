import React, { Fragment, useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as netWorking from '../../../redux/netWorking';
import { RootStore } from '../../../redux/Store';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

import { postListAllIncidence } from '../../../redux/incidence/incidenceAction';
import {
  INCIDENCE_CREATE,
  INCIDENCE_UPDATE,
  INCIDENCE_SELECTED,
} from '../../../redux/incidence/incidenceTypes';
import { INCIDENCE_TYPE, ROLE } from '../../../utils/utils';

import TableComponent, {
  HEADER,
  HEADER_TYPE,
  FIELD_TYPE,
} from '../../../components/Table';
import ModalAssignLawyer from './assignIncidence';
import IncidenceDetailComponent from './incidenceDetail';

import { ContextWs, WS_STATES } from '../../../redux/socket/wsContext';
import moment from 'moment';

const List: React.FunctionComponent = () => {
  const citySelected: any = useSelector((globalState: RootStore) => {
    return globalState.core.globalMunicipalitySelected;
  });

  const loading = useSelector(
    (globalState: RootStore) => globalState.incidence.loading,
  );
  const incidences = useSelector(
    (globalState: RootStore) => globalState.incidence.incidencesData,
  );
  const selectedIncidence: any = useSelector(
    (globalState: RootStore) => globalState.incidence.selected,
  );

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const headerProblems: any = {};
  let defaultValue: any;

  const [detailView, setDetailView] = React.useState(defaultValue);
  const [modalAssignLawyer, setModalAssignLawyer] = useState(false);
  const [tableData, setTableData] = React.useState<string[]>([]);
  const [edit, setEdit] = useState(Object);

  const { socket, wsState, rooms, join, disconnect, off } = useContext(
    ContextWs,
  );

  const openAssign = (_id: any) => {
    const ic = incidences?.find((value: any) => value._id === _id);
    setEdit(ic);
    setModalAssignLawyer(true);
  };

  const finishIncidence = (_id: any) => {
    netWorking.post(
      netWorking.postAssignProblemGeneral,
      {
        _id: _id,
        lawyerId: localStorage.id,
        status: INCIDENCE_TYPE.COMPLETED,
      },
      () => {},
      () => {},
    );
  };

  const getAddress = (address: any) => {
    let result = '';
    const newAddress = address;
    if (newAddress) {
      if (newAddress.street && newAddress.street !== '')
        result = newAddress.street;
      if (newAddress.exteriorNumber && newAddress.exteriorNumber !== '')
        result = result + `, ${newAddress.exteriorNumber}`;
      if (newAddress.section && newAddress.section !== '')
        result = result + `, ${newAddress.section}`;
      if (newAddress.district && newAddress.district !== '')
        result = result + `, ${newAddress.district}`;
    }
    return result;
  };

  const transformData = () => {
    let newIncidences: any = [];
    let dataArray: any = incidences;
    dataArray.forEach((incidence: any) => {
      let newObject = {
        _id: incidence._id,
        pollingStation: incidence.voterBox.number,
        type: incidence.type._id ? incidence.type[i18next.language] : '-',
        time:
          moment(incidence.created).format('l') +
          ' ' +
          moment(incidence.created).format('LT'),
        address: getAddress(incidence.voterBox.address),
        rgrc: {
          rg: incidence.voterBox.rg,
          rc: incidence.voterBox.rc,
        },
        status: incidence.status,
        lawyer: incidence.lawyer._id
          ? incidence.lawyer.firstName + ' ' + incidence.lawyer.lastName
          : undefined,
        auxiliarId: incidence._id,
        incidenceId: incidence._id,
        permission: true,
        tableView: 'problems',
        role: localStorage.role,
      };
      if (
        localStorage.role === ROLE.LAWYER ||
        localStorage.role === ROLE.LAWYER_BOSS
      ) {
        if (localStorage.id === incidence.lawyer._id) {
          newIncidences.push(newObject);
        }
      } else {
        newIncidences.push(newObject);
      }
    });
    setTableData(newIncidences);
  };

  const download = (id: string) => {
    console.log('id', id);
    netWorking.sendRequest(
      netWorking.getPdfIncidence + `${i18next.language}/${id}`,
      {},
      () => {},
      () => {},
      (resp: any) => {
        console.log(resp);
        const url = window.URL.createObjectURL(new Blob([resp.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'incidence.pdf'); //or any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();
      },
      true,
    );
  };

  React.useEffect(() => {
    if (wsState === WS_STATES.OPEN) {
      if (join)
        join(
          'incidences' + localStorage.campaignId,
          INCIDENCE_UPDATE,
          INCIDENCE_CREATE,
          '',
          '',
          (response: any) => {
            console.log('response', response);
          },
        );
    }
  }, [wsState]);

  useEffect(() => {
    if (detailView) {
      dispatch({
        type: INCIDENCE_SELECTED,
        payload: detailView,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailView]);

  useEffect(() => {
    if (incidences) {
      transformData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incidences, i18next.language]);

  useEffect(() => {
    if (citySelected._id) {
      dispatch(postListAllIncidence(localStorage.campaignId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [citySelected]);

  return (
    <Fragment>
      {!selectedIncidence._id && (
        <div className="incidences-list">
          <div className="incidences-filters-container">
            <div className="count-container">
              <h1 className="count">
                {t('dday.problems.lbl.incidences') +
                  ' [' +
                  tableData.length +
                  ']'}
              </h1>
            </div>
          </div>
          <div className="incidences-container">
            <TableComponent
              onTrAction={false}
              onTrFun={() => {}}
              showActions={undefined}
              selectedRows={[]}
              selectRows={() => {}}
              header={headerProblems}
              headerType={HEADER_TYPE.HIDDEN}
              headers={[
                {
                  type: HEADER.TEXT,
                  name: t('dday.problems.table.pollingStation'),
                },
                {
                  type: HEADER.TEXT,
                  name: t('dday.problems.table.incidenceType'),
                },
                {
                  type: HEADER.TEXT,
                  name: t('dday.problems.table.time'),
                },
                {
                  type: HEADER.TEXT,
                  name: t('dday.problems.table.street'),
                },
                {
                  type: HEADER.TEXT,
                  name: t('dday.problems.table.rgrc'),
                },
                {
                  type: HEADER.TEXT,
                  name: t('dday.problems.table.status'),
                },
                {
                  type: HEADER.TEXT,
                  name: t('dday.problems.table.assignedTo'),
                },
                {
                  type: HEADER.TEXT,
                  name: t('dday.problems.table.status'),
                },
                {
                  type: HEADER.TEXT,
                  name: t('dday.problems.table.detail'),
                },
                {
                  type: HEADER.TEXT,
                  name: t('dday.problems.table.document'),
                },
              ]}
              fieldTypes={[
                { type: FIELD_TYPE.TEXT },
                { type: FIELD_TYPE.TEXT },
                { type: FIELD_TYPE.TEXT },
                { type: FIELD_TYPE.TEXT },
                { type: FIELD_TYPE.TEXT_RGRC },
                { type: FIELD_TYPE.INCIDENCE_STATUS },
                {
                  type: FIELD_TYPE.BUTTON_ARRAY,
                  fun: (value: any) => {
                    openAssign(value);
                  },
                },
                {
                  type: FIELD_TYPE.INCIDENCE_FINISH,
                  fun: (value: any) => {
                    finishIncidence(value);
                  },
                },
                {
                  type: FIELD_TYPE.TEXT_LINK,
                  fun: (_id: any, level: any) => {
                    const incidence = incidences?.find(
                      (value: any) => value._id === _id,
                    );
                    setDetailView(incidence);
                  },
                },
                {
                  type: FIELD_TYPE.BUTTON_DOWNLOAD_PDF,
                  fun: (value: any) => {
                    download(value);
                  },
                },
              ]}
              toEdit={() => {}}
              toDelete={() => {}}
              search={() => {}}
              regExp={undefined}
              data={tableData}
              nameOfData={[
                'pollingStation',
                'type',
                'time',
                'address',
                'rgrc',
                'status',
                'lawyer',
                'role',
                'auxiliarId',
                'incidenceId',
              ]}
              classBody={[
                'text-align-center selector-cell-none polling-station-container',
                'text-align-left incidence-container',
                'text-align-center time-container',
                'text-align-left street-container',
                'text-align-left rgrc-container',
                localStorage.role === ROLE.LAWYER_BOSS ||
                localStorage.role === ROLE.LAWYER
                  ? 'text-align-center status-container-hidden'
                  : 'text-align-center status-container',
                localStorage.role === ROLE.LAWYER_BOSS ||
                localStorage.role === ROLE.LAWYER
                  ? 'text-align-center assign-container-hidden'
                  : 'text-align-center assign-container',
                localStorage.role === ROLE.LAWYER_BOSS ||
                localStorage.role === ROLE.LAWYER
                  ? 'text-align-center finish-container'
                  : 'text-align-center finish-container-hidden',
                'text-align-center detail-container',
                'text-align-center document-container',
              ]}
            ></TableComponent>
          </div>
        </div>
      )}

      {!selectedIncidence._id && modalAssignLawyer && (
        <ModalAssignLawyer
          modalShow={modalAssignLawyer}
          setModalShow={setModalAssignLawyer}
          edit={edit}
          successFun={() => {}}
        ></ModalAssignLawyer>
      )}

      {selectedIncidence._id && (
        <IncidenceDetailComponent
          setDetailView={setDetailView}
        ></IncidenceDetailComponent>
      )}
    </Fragment>
  );
};

export default List;
