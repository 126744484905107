import React from 'react';

interface TopBarProps {
  additionalClass?: string;
}

const TopSeparator: React.FunctionComponent<TopBarProps> = ({
  additionalClass = '',
}: any) => {
  return <div className={'top-separator ' + additionalClass}></div>;
};

export default TopSeparator;
