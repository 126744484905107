import React, { createRef } from 'react';
import { useTranslation } from 'react-i18next';
import { IoIosClose } from 'react-icons/io';
import { Map, Polyline, Marker } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import LayerControlComponent from '../../../components/sub-component/layerControl';
import Button from 'react-bootstrap/Button';
import ModalIncidence from './modalIncidence';
import {
  neutralBlueMarker,
  neutralMarker,
} from '../../../components/sub-component/markers';
import ModalNoIncidence from './modalNoIncidence';
import { LatLng } from 'leaflet';
import L from 'leaflet';

interface TabsProps {
  data: any;
  tracking: any;
  active: any;
  setActive: any;
  submitFun: any;
}

const CheckMarkerComponent: React.FunctionComponent<TabsProps> = ({
  data,
  active,
  setActive,
  tracking,
  submitFun,
}: any) => {
  const ref = createRef<HTMLDivElement>();
  const [showDialogIssue, setShowDialogIssue] = React.useState(false);
  const [showDialogNoIssue, setShowDialogNoIssue] = React.useState(false);
  const { t } = useTranslation();

  console.log('data', data);

  return (
    <>
      <div
        ref={ref}
        className={!active ? 'check-marker ' : 'check-marker is-open'}
      >
        <div className="header-box">
          <div className="close-button" onClick={() => {}}>
            <IoIosClose
              onClick={() => {
                setActive();
              }}
            ></IoIosClose>
          </div>
          <div className="title-box">
            <h2>{t('territorial.tracker.lbl.review')}</h2>
          </div>{' '}
        </div>
        <div className="list-maps">
          <div className="map-item">
            <div className="address-box">
              <div className="address-text">
                {/* <h2> */} <div className="square-address"></div>
                <p>
                  {' '}
                  <strong>
                    {' '}
                    {t('territorial.tracker.lbl.voterAddress') + ': '}
                  </strong>
                  {/* </h2> */}
                  {data.address}
                </p>
              </div>
              <div className="address-text">
                <div className="square-gps"></div>
                <p>
                  {' '}
                  {/* <h2> */}
                  <strong>
                    {t('territorial.tracker.lbl.volunteerGeo') + ': '}
                  </strong>
                  {/* </h2> */}
                  {data.realAddress
                    ? data.realAddress
                    : t('territorial.tracker.popup.lbl.NoAddress')}
                </p>
              </div>
              <div className="map-box">
                <Map
                  center={data.positionAddress}
                  zoomControl={false}
                  zoom={13}
                  bounds={
                    data.position && data.positionAddress
                      ? new L.LatLngBounds([
                          [data.position.lat, data.position.lng],
                          [data.positionAddress.lat, data.positionAddress.lng],
                        ])
                      : data.position && !data.positionAddress
                      ? new L.LatLngBounds([
                          [data.position.lat, data.position.lng],
                        ])
                      : undefined
                  }
                >
                  <LayerControlComponent></LayerControlComponent>
                  {data?.positionAddress && (
                    <Marker
                      position={data?.positionAddress}
                      icon={neutralBlueMarker}
                    ></Marker>
                  )}
                  {data?.position && (
                    <Marker
                      position={data?.position}
                      icon={neutralMarker}
                    ></Marker>
                  )}
                  {data?.positionAddress && data?.position && (
                    <Polyline
                      color="#141c21"
                      positions={[
                        new LatLng(data?.position.lng, data?.position.lat),
                        new LatLng(
                          data?.positionAddress.lng,
                          data?.positionAddress.lat,
                        ),
                      ]}
                      dashArray="4"
                    ></Polyline>
                  )}
                </Map>
              </div>
              <div className="bottom-box-review">
                <div className="address-text">
                  <h2>{t('territorial.tracker.lbl.distance')}:</h2>
                  <p>
                    {data.distance.toFixed(2)}
                    {' ' + t('territorial.tracker.lbl.km')}
                  </p>
                </div>
                <div className="address-text">
                  <h2>{t('territorial.tracker.lbl.typeOfCapture')}:</h2>
                  <div className="type-box">
                    <p>
                      {data.inHouse
                        ? t('territorial.tracker.lbl.inHouse')
                        : t('territorial.tracker.lbl.outHouse')}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="buttons-box">
            {data.status === 2 && (
              <Button
                variant="primary"
                onClick={() => {
                  setShowDialogNoIssue(true);
                }}
              >
                {t('territorial.tracker.btn.noProblem')}
              </Button>
            )}
            <Button
              variant="danger"
              onClick={() => {
                setShowDialogIssue(true);
              }}
            >
              {t('territorial.tracker.btn.markProblem')}
            </Button>
          </div>
        </div>
      </div>
      {showDialogIssue && (
        <ModalIncidence
          modalShow={showDialogIssue}
          successFun={() => {
            setActive();
            submitFun();
            // if (selected.section) getData(selected.section);
          }}
          data={data}
          tracking={tracking}
          setModalShow={setShowDialogIssue}
        ></ModalIncidence>
      )}
      {showDialogNoIssue && (
        <ModalNoIncidence
          modalShow={showDialogNoIssue}
          successFun={() => {
            setActive();
            submitFun();
            // if (selected.section) getData(selected.section);
          }}
          data={data}
          tracking={tracking}
          setModalShow={setShowDialogNoIssue}
        ></ModalNoIncidence>
      )}
    </>
  );
};
export default CheckMarkerComponent;
