import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../style.scss';
import StaticModal from '../../../dialogs/StaticModal';
import InputComponent from '../../../components/sub-component/input';
import * as netWorking from '../../../redux/netWorking';
import { NEW_DATA_SUCCESS, UpData } from '../../../redux/updata/UpDataTypes';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import InputFileComponent from '../../../components/sub-component/inputFile';
import { XLS_TYPE } from '../../../utils/utils';
import LoadingModal from '../../../dialogs/loadingModal';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../redux/Store';

// tslint:disable-next-line: prefer-const
let defaultFile: File;

interface ModalAddFileProps {
  modalShow: boolean;
  setModalShow: any;
  successFun: any;
  setRouteFile: any;
}

export const ModalAddFile: React.FunctionComponent<ModalAddFileProps> = ({
  modalShow,
  setModalShow,
  successFun,
  setRouteFile,
}: ModalAddFileProps) => {
  const userData = useSelector((globalState: RootStore) => {
    return globalState.core.userData;
  });

  const citySelected = useSelector((globalState: RootStore) => {
    return globalState.core.globalMunicipalitySelected;
  });

  const stateSelected = useSelector((globalState: RootStore) => {
    return globalState.core.globalStateSelected;
  });

  const defaultEdit: UpData = {
    _id: '',
    code: '',
    filePath: '',
    createdDate: moment().toDate(),
    approvalDate: moment().toDate(),
    byId: '',
    byName: '',
    campaignId: '',
    type: XLS_TYPE.VOTERS_TYPE,
    name: '',
    fileName: '',
    reviewedId: '',
    reviewedByName: '',
    errorsCount: 0,
    winsCount: 0,
    totalData: 0,
    status: 0,
    delete: false,
  };
  const { t } = useTranslation();
  const [toSave, setToSave] = useState(defaultEdit);
  const [file, setFile] = useState(defaultFile);
  const [modalShowLoading, setModalShowLoading] = React.useState(false);
  const dispatch = useDispatch();
  const [, setUploadProgress] = React.useState(0);
  const [, setUploadStatus] = React.useState('NONE');

  //   useEffect(() => {
  //     if (!loadData) {
  //       if (edit !== undefined) {
  //         console.log("entro");
  //         setToSave(edit);
  //       }
  //       getMunicipality(true);
  //       setLoadData(true);
  //     }
  //   }, [loadData]);

  const submitFun = () => {
    setModalShowLoading(true);
    let formData = new FormData();
    formData.append('file', file);
    formData.append('type', '69');
    netWorking.sendRequest(
      netWorking.Upload,
      formData,
      setUploadProgress,
      setUploadStatus,
      (res: any) => {
        let data = {
          ...toSave,
          byId: userData?._id,
          byName:
            (userData?.firstName || '') + ' ' + (userData?.lastName || ''),
          cityLicenseId: citySelected?._id,
          state: stateSelected?.name,
        };
        data.filePath = res;
        netWorking.post(
          netWorking.CreateUpData,
          data,
          (res: any) => {
            setModalShowLoading(false);
            dispatch({
              type: NEW_DATA_SUCCESS,
              upData: res.data.response.upData,
            });
            // successFun();
            setModalShow(false);
          },
          (err: any) => {},
        );
      },
    );
  };

  const validateSubmit = () => {
    if (
      toSave.name !== '' &&
      toSave.code !== '' &&
      toSave.code.length >= 7 &&
      file
    ) {
      return false;
    } else return true;
  };

  return (
    <>
      <StaticModal
        show={modalShow}
        title={t('settings.votersList.modal.lbl.title')}
        closeText={t('submit.cancel')}
        confirmText={t('submit.save')}
        onHide={() => setModalShow(false)}
        submitFun={submitFun}
        validBtn={validateSubmit()}
        size="lg"
        sizeClass="md modal-rc"
      >
        <>
          <Container className="body-modal">
            <Row>
              <Col xs={12} md={12} className="col-item-modal">
                <InputComponent
                  placeholder={t(
                    'settings.votersList.modal.placeholder.titleFile',
                  )}
                  onChange={(value: string) => {
                    setToSave((prevState) => ({
                      ...prevState,
                      name: value,
                    }));
                  }}
                  title={t('settings.votersList.modal.lbl.titleFile')}
                  value={toSave.name}
                  length={50}
                  horizontal={false}
                ></InputComponent>
              </Col>
              <Col xs={12} md={12} className="col-item-modal">
                <InputFileComponent
                  placeholder={
                    file
                      ? file.name
                      : t('settings.votersList.modal.placeholder.uploadFile')
                  }
                  validFilesTypes={/\.(xls|xlsx|csv)$/}
                  onChange={(value: any) => {
                    if (value && value.name) {
                      setFile(value);
                      setToSave((prevState) => ({
                        ...prevState,
                        fileName: value.name,
                      }));
                    }
                  }}
                  title={t('settings.votersList.modal.lbl.uploadFile')}
                  horizontal={false}
                ></InputFileComponent>
              </Col>
              <Col xs={12} md={12} className="col-item-modal">
                <InputComponent
                  placeholder={t(
                    'settings.votersList.modal.placeholder.codeOfDownload',
                  )}
                  onChange={(value: string) => {
                    setToSave((prevState) => ({
                      ...prevState,
                      code: value.trim(),
                    }));
                  }}
                  title={t('settings.votersList.modal.lbl.codeOfDownload')}
                  value={toSave.code}
                  horizontal={false}
                  subTitle={t('settings.voterList.modal.lbl.minimum')}
                  // regExp={onlyDigits}
                  length={30}
                ></InputComponent>
              </Col>
            </Row>
          </Container>
        </>
      </StaticModal>
      {modalShowLoading && (
        <LoadingModal
          show={modalShowLoading}
          onHide={setModalShowLoading}
        ></LoadingModal>
      )}
    </>
  );
};

export default ModalAddFile;
