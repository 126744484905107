import React, { createRef } from 'react';
import { BsCaretRightFill, BsCaretLeftFill } from 'react-icons/bs';
import './style.scss';

interface ListFilterCustomProps {
  children: any;
  direction: any;
  className?: string;
  size?: 'lg-filter' | 'md-filter' | 'sm-filter';
  onHide?: any;
}

const ListFilterClosedCustomComponent: React.FunctionComponent<ListFilterCustomProps> = ({
  children,
  direction,
  className = '',
  size = '',
  onHide,
}: any) => {
  const ref = createRef<HTMLDivElement>();

  const setOpen = () => {
    const current = ref.current;
    if (current) current.classList.toggle('is-open');
    if (onHide) onHide();
  };

  const getDirectionIcon = () => {
    let icon = <BsCaretRightFill />;
    if (direction === 'right') {
      icon = <BsCaretLeftFill />;
    }
    return icon;
  };

  return (
    <div
      ref={ref}
      className={className + ` list-filter-custom ${direction} ` + size}
    >
      <div className="list-nav">
        <div className="nav__icon" onClick={setOpen}>
          {getDirectionIcon()}
        </div>
        <div className="nav__body">{children}</div>
      </div>
    </div>
  );
};
export default ListFilterClosedCustomComponent;
