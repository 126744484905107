import React, { FunctionComponent } from 'react';
import { RouteProps } from 'react-router-dom';
import { Popup } from 'react-leaflet';
import Button from 'react-bootstrap/Button';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { getTime } from '../../../utils/utils';
import { PinIcon, TimeIcon, UserIcon } from '../../../assets/svg';
import TagStatusComponent from './tagStatus';

interface ItemProps extends RouteProps {
  data: any;
  index: number;
  onReview: any;
}

const MarkerPopupComponent: FunctionComponent<ItemProps> = ({
  data,
  index,
  onReview,
}: ItemProps) => {
  const { t } = useTranslation();
  const popupRef = React.createRef<Popup>();
  // React.useEffect(() => {
  //   if (popupRef.current) {
  //     onChangeRefs((prev: any) => {
  //       return { ...prev, [index]: popupRef };
  //     });
  //   }
  //   // popupRef.current?.leafletElement.openPopup();
  //   // console.log('popupRef', popupRef);
  // }, [popupRef]);

  return (
    <Popup ref={popupRef}>
      <div
        className="tracker-popup-box"
        onClick={() => {
          // closePopupOnClick(popup);
        }}
      >
        <div className="title-box-tracker-popup">
          <h2>
            <strong>
              {t('territorial.tracker.popup.lbl.capture') + ' '} {index + 1}
            </strong>
          </h2>{' '}
          <p>{moment(data.startDate).format('hh:mmA')}</p>
        </div>
        <div className="voter-box-tracker-popup">
          <p>
            <strong>
              {data.name || t('territorial.tracker.popup.lbl.noName')}
            </strong>
          </p>
        </div>
        <div className="data-box-tracker-popup">
          <UserIcon></UserIcon>
          <p>
            {data.inHouse
              ? t('territorial.tracker.popup.lbl.inHouse')
              : t('territorial.tracker.popup.lbl.outHouse')}
          </p>
        </div>
        <div className="data-box-tracker-popup">
          <PinIcon></PinIcon>
          <p>{data.address || t('territorial.tracker.popup.lbl.NoAddress')}</p>
        </div>
        <div className="data-box-tracker-popup">
          <TimeIcon></TimeIcon>
          <p>{getTime(t, data.startDate, data.endDate)}</p>
        </div>
        <div className="box-buttons-tracker-popup">
          {<TagStatusComponent status={data.status}></TagStatusComponent>}
          <Button variant="primary" onClick={onReview}>
            {t('territorial.tracker.btn.review')}
          </Button>
        </div>
      </div>
    </Popup>
  );
};

export default MarkerPopupComponent;
