import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { GUEST_LOGIN } from './paths';

interface MyRouteProps extends RouteProps {
  component: any;
  authenticated: boolean;
  allowByRole: boolean;
  rest?: any;
}

const PrivateRoute: React.SFC<MyRouteProps> = ({
  component: Component,
  authenticated,
  allowByRole,
  ...rest
}: any) => (
  <Route
    {...rest}
    render={(props) => {
      if (authenticated) {
        if (allowByRole) {
          return <Component {...props} />;
        } else {
          return <></>;
        }
      } else {
        console.log('Executed Login Redirect');
        return <Redirect to={GUEST_LOGIN} />;
      }
    }}
  />
);

const mapStateToProps = (state: any) => ({
  authenticated: state.user.authenticated,
});

export default connect(mapStateToProps)(PrivateRoute);
