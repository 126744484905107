export const USERS_LOADING = 'USERS_LOADING';
export const USERS_FAIL = 'USERS_FAIL';
export const USERS_SUCCESS = 'USERS_SUCCESS';
export const USERS_COUNT = 'USERS_COUNT';

export type User = {
    _id: string;
    firstName: string;
    lastName: string;
    email: string;
    cellphone: string;
    countryCode: string;
    supportInNetworks: string;
    mostUsedNetwork: string;
    address: string;
};

export type SearchUsers = {
    page: number;
    records?: number;
    field: string;
    order: string;
    search: string;
    states: string;
    cities: string;
};

export type CreateUsers = {
    _id: string,
    blocked: boolean,
    blockedReason: string,
    campaignId: string,
    email: string,
    lastName: string,
    firstName: string,
    role: number,
    extra: ExtraUser,
    name: string,
    addressText: string,
    municipalityId: string,
    districtId: string,
};

export type ExtraUser = {
    _id: string,
    address: AddressUser,
    campaigns: Array<any>,
    votersBoxes: Array<any>,
    sectionId: string,
    dob: Date,
    cellphone: string,
    sex: number,
};

export type AddressUser = {
    _id: string,
    street: string,
    addressType: number,
    interiorNumber: string,
    exteriorNumber: string,
    postalCode: string,
    suburb: string,
    geoLocation: Object,
    sectionId: string,
};

export type DeleteUser = {
    toDelete: Array<string>;
};

export type ChangeStatusUser = {
    _id: string;
    blocked: boolean;
};

export interface UsersLoading {
    type: typeof USERS_LOADING;
}

export interface UsersFail {
    type: typeof USERS_FAIL;
}

export interface UsersSuccess {
    type: typeof USERS_SUCCESS;
    payload: User[];
}

export interface UsersCount {
    type: typeof USERS_COUNT;
    payload: object;
}

export type UsersDispatchTypes =
  | UsersLoading
  | UsersFail
  | UsersSuccess
  | UsersCount;