import { getSex } from './../../utils/utils';
import {
  UpDataDispatchTypes,
  UpData,
  UP_DATA_FAIL,
  UP_DATA_LOADING,
  UP_DATA_SUCCESS,
  SELECT_DATA_SUCCESS,
  SEARCH_DATA_SUCCESS,
  NEW_DATA_SUCCESS,
} from './UpDataTypes';

interface DefaultStateI {
  loading: boolean;
  upDataList: UpData[];
  selectedList: boolean;
  search: string;
}

const defaultState: DefaultStateI = {
  loading: false,
  upDataList: [],
  selectedList: false,
  search: '',
};

const upDataReducer = (
  state: DefaultStateI = defaultState,
  action: UpDataDispatchTypes,
): DefaultStateI => {
  switch (action.type) {
    case UP_DATA_FAIL:
      return {
        ...state,
        loading: true,
      };
    case UP_DATA_LOADING:
      return {
        ...state,
        loading: true,
      };
    case UP_DATA_SUCCESS:
      const list = [...action.upData];
      return {
        ...state,
        loading: false,
        upDataList: list,
      };
    case SELECT_DATA_SUCCESS:
      return {
        ...state,
        selectedList: action.selectData,
      };
    case SEARCH_DATA_SUCCESS:
      return {
        ...state,
        search: action.searchValue,
      };
    case NEW_DATA_SUCCESS:
      const list2 = [...state.upDataList, action.upData];
      return {
        ...state,
        upDataList: list2,
      };
    default:
      return state;
  }
};

export default upDataReducer;
