import React, { Fragment } from 'react';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import { useTranslation } from 'react-i18next';
import { IoMdArrowDropdown } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import SelectorComponent from '../../../components/sub-component/selector';
import {
  getDistricts,
  getPollingStations,
  getSections,
} from '../../../redux/bingo/bingoActions';
import {
  SET_DISTRICTS_SELECTED,
  SET_POLLING_STATIONS_SELECTED,
  SET_SECTIONS_SELECTED,
} from '../../../redux/bingo/bingoTypes';
import { RootStore } from '../../../redux/Store';

interface Select {
  _id: string;
  name: string;
}

const TopBarBingo: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [first, setFirst] = React.useState<boolean>(false);

  const districtSelected = useSelector(
    (globalState: RootStore) => globalState.bingo.districtSelected,
  );
  const sectionSelected = useSelector(
    (globalState: RootStore) => globalState.bingo.sectionSelected,
  );
  const pollingStationSelected = useSelector(
    (globalState: RootStore) => globalState.bingo.pollingStationSelected,
  );

  const [districtL, setDistrict] = React.useState<Select | undefined>(
    districtSelected,
  );
  const [sectionL, setSection] = React.useState<Select | undefined>(
    sectionSelected,
  );
  const [pollingStationL, setPollingStation] = React.useState<
    Select | undefined
  >(pollingStationSelected);

  const citySelected: any = useSelector((globalState: RootStore) => {
    return globalState.core.globalMunicipalitySelected;
  });

  const districts = useSelector(
    (globalState: RootStore) => globalState.bingo.districts,
  );

  const sections = useSelector(
    (globalState: RootStore) => globalState.bingo.sections,
  );

  const pollingStations = useSelector(
    (globalState: RootStore) => globalState.bingo.pollingStations,
  );

  React.useEffect(() => {
    if (!first) {
      dispatch(
        getDistricts(
          citySelected?._id,
          (data: { _id: string; name: string }) => {
            setDistrict(data);
          },
        ),
      );
      setFirst(!first);
    }
  }, [first]);

  React.useEffect(() => {
    if (districtL) {
      dispatch(
        getSections(districtL?._id, (data: { _id: string; name: string }) => {
          setSection(data);
        }),
      );
    }
  }, [districtL]);

  React.useEffect(() => {
    if (districtL && sectionL) {
      dispatch(
        getPollingStations(
          citySelected?._id,
          districtL.name || '',
          sectionL.name || '',
          (data: { _id: string; name: string }) => {
            setPollingStation(data);
          },
        ),
      );
    }
  }, [sectionL]);

  return (
    <div className="top-bar-bingo">
      <div className="selector-bingo">
        <Dropdown as={ButtonGroup}>
          <Dropdown.Toggle split variant="success" id="dropdown-split-basic">
            <h2>
              {t('general.form.district')}:{' '}
              <span>{districtSelected?.name || t('select.lbl.none')}</span> -{' '}
              {t('general.form.section')}:{' '}
              <span>{sectionSelected?.name || t('select.lbl.none')}</span> -{' '}
              {t('settings.polling.label.pollingStations')}{' '}
              <span>
                {pollingStationSelected?.name || t('select.lbl.none')}
              </span>
            </h2>
            <IoMdArrowDropdown></IoMdArrowDropdown>
          </Dropdown.Toggle>

          <Dropdown.Menu className="dropwwwwwww">
            <div className="item">
              <SelectorComponent
                title={t('general.form.district')}
                defaultSelector={''}
                options={districts}
                horizontal={false}
                fun={(value: string) => {
                  setDistrict(districts.find((e) => e._id === value));
                  // getSect(value, citySelected?.municipalityId);
                }}
              ></SelectorComponent>
              <SelectorComponent
                title={t('general.form.section')}
                defaultSelector={''}
                options={sections}
                horizontal={false}
                fun={(value: string) => {
                  setSection(sections.find((e) => e._id === value));
                  // getSect(value, citySelected?.municipalityId);
                }}
              ></SelectorComponent>
              <SelectorComponent
                title={t('settings.polling.label.pollingStations')}
                defaultSelector={''}
                options={pollingStations}
                horizontal={false}
                fun={(value: string) => {
                  setPollingStation(
                    pollingStations.find((e) => e._id === value),
                  );
                  // getSect(value, citySelected?.municipalityId);
                }}
              ></SelectorComponent>
              <div className="btn-box">
                <Button
                  variant="primary"
                  onClick={() => {
                    dispatch({ type: SET_DISTRICTS_SELECTED, data: districtL });
                    dispatch({ type: SET_SECTIONS_SELECTED, data: sectionL });
                    dispatch({
                      type: SET_POLLING_STATIONS_SELECTED,
                      data: pollingStationL,
                    });
                  }}
                >
                  {t('submit.confirm')}
                </Button>
              </div>
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};

export default TopBarBingo;
