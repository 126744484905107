import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../redux/Store';
import { useTranslation } from 'react-i18next';

import { postConfirmVoterById } from '../../../../src/redux/auditory/AuditoryActions';
import {
  formatPhoneNumber,
  getAddress,
  onlyDigits,
  onlyLetters,
} from '../../../utils/utils';

import TagsOptionsTextFieldComponent from '../../../../src/components/sub-component/tagsOptionsTextField';
import { ModalDenyVoter } from '../../../pages/auditoryTerritorial/components/DenyVoter';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import { RiCloseLine } from 'react-icons/ri';
import photoIdPlaceHolderFrontNoINE from '../../../../src/assets/img/photo-id-placeholder-front-none.png';
import photoIdPlaceHolderFront from '../../../../src/assets/img/photo-id-placeholder-front.png';

export enum SEX {
  MAN,
  WOMAN,
  APACHE_HELICOPTER,
}

interface TabsProps {
  openVoters: any;
  active: any;
}

const VoterListComponent: React.FunctionComponent<TabsProps> = ({
  openVoters,
  active,
}: any) => {
  const citySelected = useSelector((globalState: RootStore) => {
    return globalState.core.globalMunicipalitySelected;
  });

  const voter: any = useSelector(
    (globalState: RootStore) => globalState.auditory.voterAudition,
  );

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [voterData, setVoterData] = useState(Object);
  const [modalShowAdd, setModalShowAdd] = React.useState(false);

  const [ineSelectionMode, setIneSelectionMode] = useState(false);

  const [submitMessage, setSubmitMessage] = useState(String);
  const [submitConfirmation, setSubmitConfirmation] = useState(false);

  const [nameSelectArray, setNameSelectArray] = useState(Array);
  const [lastNameSelectArray, setLastNameSelectArray] = useState(Array);
  const [phoneSelectArray, setPhoneSelectArray] = useState(Array);
  const [streetSelectArray, setStreetSelectArray] = useState(Array);
  const [extNumberSelectArray, setExtNumberSelectArray] = useState(Array);
  const [intNumberSelectArray, setIntNumberSelectArray] = useState(Array);
  const [suburbSelectArray, setSuburbSelectArray] = useState(Array);
  const [homeDistrictSelectArray, setHomeDistrictSelectArray] = useState(Array);
  const [homeSectionSelectArray, setHomeSectionSelectArray] = useState(Array);
  const [votingDistrictSelectArray, setVotingDistrictSelectArray] = useState(
    Array,
  );
  const [votingSectionSelectArray, setVotingSectionSelectArray] = useState(
    Array,
  );
  const [INESelectArray, setINESelectArray] = useState(Array);

  const inputNameRef: any = useRef(null);
  const inputLastnameRef: any = useRef(null);
  const inputCellphoneRef: any = useRef(null);
  const inputStreetRef: any = useRef(null);
  const inputExteriorRef: any = useRef(null);
  const inputInteriorRef: any = useRef(null);
  const inputSuburbRef: any = useRef(null);
  const inputHomeDistrictRef: any = useRef(null);
  const inputHomeSectionRef: any = useRef(null);
  const inputVotingDistrictRef: any = useRef(null);
  const inputVotingSectionRef: any = useRef(null);

  console.log('voterData', voterData);

  const setOpen = () => {
    openVoters(false);
    setVoterData({});
  };

  const formatAddress = (value: string) => {
    let street = '';
    let exteriorNumber = '';
    let interiorNumber = '';
    let postalCode = '';
    let suburb = '';

    const split = value.split(',');
    street = split[0]?.trim() || value;
    exteriorNumber = split[1]?.trim() || '';
    interiorNumber = split[2]?.trim() || '';
    postalCode = split[3]?.trim() || '';
    suburb = split[4]?.trim() || '';

    let requestObject;
    requestObject = {
      addressType: 0,
      street: street,
      municipalityId: citySelected?._id,
      interiorNumber: interiorNumber,
      geoLocation: {
        type: 'Point',
        coordinates: [-77.915039, -8.303232],
      },
      suburb: suburb,
      exteriorNumber: exteriorNumber,
      postalCode: postalCode,
    };

    return requestObject;
  };

  const validSubmit = () => {
    if (voterData.firstName === '') {
      return t('territorial.volunteer.modal.error.name');
    } else if (voterData.lastName === '') {
      return t('territorial.volunteer.modal.error.lastname');
    } else if (voterData.address.street === '' || !voterData.address.street) {
      return t('territorial.volunteer.modal.error.street');
    } else if (
      voterData.address.exteriorNumber === '' ||
      !voterData.address.exteriorNumber
    ) {
      return t('territorial.volunteer.modal.error.exteriorNumber');
    } else if (voterData.districtVote === '' || !voterData.districtVote) {
      return t('territorial.volunteer.modal.error.votingDistrict');
    } else if (voterData.sectionVote === '' || !voterData.sectionVote) {
      return t('territorial.volunteer.modal.error.votingSection');
    } else if (voterData.cellphone === '' || !voterData.cellphone) {
      return t('territorial.volunteer.modal.error.cellphoneComplete');
    } else {
      return '';
    }
  };

  const handleDenyVoter = () => {
    setSubmitConfirmation(false);
    setModalShowAdd(true);
  };

  const handleConfirmVoter = () => {
    if (validSubmit()) {
      setSubmitMessage(validSubmit());
      setSubmitConfirmation(true);
    } else {
      setSubmitConfirmation(false);

      const params_confirm = buildParamConfirmVoter(voterData);
      dispatch(postConfirmVoterById(params_confirm, getAllData));
    }
  };

  const getAllData = () => {
    setOpen();
  };

  const buildParamConfirmVoter = (voter: any) => {
    let votersArray = [] as any;

    let originalRegistered = voter.registered === 2 ? 1 : voter.registered;
    let originalCaptured = voter.captured === 2 ? 1 : voter.captured;
    let originalVoter = {
      id: voter._id,
      registered: originalRegistered,
      captured: originalCaptured,
      deleted: true,
      audited: 1,
    };
    votersArray.push(originalVoter);

    voter?.duplicates.map((e: any) => {
      let duplicateRegistered = e.registered === 2 ? 1 : e.registered;
      let duplicateCaptured = e.captured === 2 ? 1 : e.captured;
      let duplicateVoter = {
        id: e._id,
        registered: duplicateRegistered,
        captured: duplicateCaptured,
        deleted: true,
        audited: 1,
      };
      votersArray.push(duplicateVoter);
    });

    let newVoter = { ...voterData };
    newVoter.registered = originalRegistered;
    newVoter.captured = originalCaptured;
    newVoter.audited = 1;
    newVoter.deleted = false;
    newVoter.cityLicenseId = citySelected?._id;

    if (newVoter.duplicates.length > 0 && !ineSelectionMode) {
      newVoter.ineExpirationDate = '';
      newVoter.ineFrontPath = '';
      newVoter.ineBackPath = '';
    }

    newVoter.isNewAddress = true;
    newVoter.duplicates = [];

    delete newVoter['_id'];
    delete newVoter['homeAddress'];
    delete newVoter['votingAddress'];
    delete newVoter['municipality'];

    let confirmData = [] as any;
    confirmData.push(votersArray);
    confirmData.push(newVoter);

    let requestObject;
    requestObject = {
      voterArray: confirmData,
    };

    return requestObject;
  };

  const checkElementArray = (objectArray: Array<any>, elemValue: string) => {
    var found_element = false;
    for (var i = 0; i < objectArray.length; i++) {
      if (objectArray[i].value === elemValue) {
        found_element = true;
      }
    }
    return found_element;
  };

  useEffect(() => {
    if (Object.keys(voter).length !== 0) {
      setSubmitConfirmation(false);
      let newVoterData = { ...voter };
      newVoterData.address = formatAddress(
        getAddress(newVoterData.homeAddress),
      );
      newVoterData.cellphone = newVoterData.cellphone.replace(/\D/g, '');
      newVoterData.districtHome = newVoterData.homeAddress.district;
      newVoterData.sectionHome = newVoterData.homeAddress.section;
      setVoterData(newVoterData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [voter]);

  useEffect(() => {
    if (voterData && active) {
      inputNameRef.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [voterData]);

  useEffect(() => {
    let newVoterData = { ...voter };

    if (newVoterData.duplicates?.length > 0) {
      let nameArray = [] as any;
      let lastNameArray = [] as any;
      let phoneArray = [] as any;
      let streetArray = [] as any;
      let exteriorNumberArray = [] as any;
      let interiorNumberArray = [] as any;
      let suburbArray = [] as any;
      let homeDistrictArray = [] as any;
      let homeSectionArray = [] as any;
      let votingDistrictArray = [] as any;
      let votingSectionArray = [] as any;
      let INEArray = [] as any;

      if (newVoterData.ineExpirationDate) {
        INEArray.push({
          key: newVoterData._id + '-0',
          value:
            newVoterData.ineExpirationDate +
            '&' +
            newVoterData.ineFrontPath +
            '&' +
            newVoterData.ineBackPath,
          ineExpirationDate: newVoterData.ineExpirationDate,
        });
      }

      if (newVoterData.firstName) {
        nameArray.push({
          key: newVoterData._id + '-' + 0,
          value: newVoterData.firstName,
        });
      }

      if (newVoterData.lastName) {
        lastNameArray.push({
          key: newVoterData._id + '-' + 0,
          value: newVoterData.lastName,
        });
      }

      if (newVoterData.cellphone.replace(/\D/g, '')) {
        phoneArray.push({
          key: newVoterData._id + '-' + 0,
          value: newVoterData.cellphone.replace(/\D/g, ''),
        });
      }

      if (newVoterData.homeAddress.street) {
        streetArray.push({
          key: newVoterData._id + '-' + 0,
          value: newVoterData.homeAddress.street,
        });
      }

      if (newVoterData.homeAddress.exteriorNumber) {
        exteriorNumberArray.push({
          key: newVoterData._id + '-' + 0,
          value: newVoterData.homeAddress.exteriorNumber,
        });
      }

      if (newVoterData.homeAddress.interiorNumber) {
        interiorNumberArray.push({
          key: newVoterData._id + '-' + 0,
          value: newVoterData.homeAddress.interiorNumber,
        });
      }

      if (newVoterData.homeAddress.suburb) {
        suburbArray.push({
          key: newVoterData._id + '-' + 0,
          value: newVoterData.homeAddress.suburb,
        });
      }

      if (newVoterData.homeAddress.district) {
        homeDistrictArray.push({
          key: newVoterData._id + '-' + 0,
          value: newVoterData.districtHome,
        });
      }

      if (newVoterData.homeAddress.section) {
        homeSectionArray.push({
          key: newVoterData._id + '-' + 0,
          value: newVoterData.sectionHome,
        });
      }

      if (newVoterData.districtVote) {
        votingDistrictArray.push({
          key: newVoterData._id + '-' + 0,
          value: newVoterData.districtVote,
        });
      }

      if (newVoterData.sectionVote) {
        votingSectionArray.push({
          key: newVoterData._id + '-' + 0,
          value: newVoterData.sectionVote,
        });
      }

      newVoterData.duplicates?.map((d: any) => {
        if (d.firstName && d.firstName !== newVoterData.firstName) {
          if (!checkElementArray(nameArray, d.firstName))
            nameArray.push({
              key: d._id + '-' + (d.index + 1),
              value: d.firstName,
            });
        }
        if (d.lastName && d.lastName !== newVoterData.lastName) {
          if (!checkElementArray(lastNameArray, d.lastName))
            lastNameArray.push({
              key: d._id + '-' + (d.index + 1),
              value: d.lastName,
            });
        }
        if (
          d.cellphone.replace(/\D/g, '') &&
          d.cellphone.replace(/\D/g, '') !==
            newVoterData.cellphone.replace(/\D/g, '')
        ) {
          if (!checkElementArray(phoneArray, d.cellphone.replace(/\D/g, '')))
            phoneArray.push({
              key: d._id + '-' + (d.index + 1),
              value: d.cellphone.replace(/\D/g, ''),
            });
        }
        if (
          d.homeAddress.street &&
          d.homeAddress.street !== newVoterData.homeAddress.street
        ) {
          if (!checkElementArray(streetArray, d.homeAddress.street))
            streetArray.push({
              key: d._id + '-' + (d.index + 1),
              value: d.homeAddress.street,
            });
        }
        if (
          d.homeAddress.exteriorNumber &&
          d.homeAddress.exteriorNumber !==
            newVoterData.homeAddress.exteriorNumber
        ) {
          if (
            !checkElementArray(
              exteriorNumberArray,
              d.homeAddress.exteriorNumber,
            )
          )
            exteriorNumberArray.push({
              key: d._id + '-' + (d.index + 1),
              value: d.homeAddress.exteriorNumber,
            });
        }
        if (
          d.homeAddress.interiorNumber &&
          d.homeAddress.interiorNumber !==
            newVoterData.homeAddress.interiorNumber
        ) {
          if (
            !checkElementArray(
              interiorNumberArray,
              d.homeAddress.interiorNumber,
            )
          )
            interiorNumberArray.push({
              key: d._id + '-' + (d.index + 1),
              value: d.homeAddress.interiorNumber,
            });
        }
        if (
          d.homeAddress.suburb &&
          d.homeAddress.suburb !== newVoterData.homeAddress.suburb
        ) {
          if (!checkElementArray(suburbArray, d.homeAddress.suburb))
            suburbArray.push({
              key: d._id + '-' + (d.index + 1),
              value: d.homeAddress.suburb,
            });
        }
        if (
          d.districtHome &&
          d.districtHome !== newVoterData.homeAddress.district
        ) {
          if (!checkElementArray(homeDistrictArray, d.districtHome))
            homeDistrictArray.push({
              key: d._id + '-' + (d.index + 1),
              value: d.districtHome,
            });
        }
        if (
          d.sectionHome &&
          d.sectionHome !== newVoterData.homeAddress.section
        ) {
          if (!checkElementArray(homeSectionArray, d.sectionHome))
            homeSectionArray.push({
              key: d._id + '-' + (d.index + 1),
              value: d.sectionHome,
            });
        }
        if (d.districtVote && d.districtVote !== newVoterData.districtVote) {
          if (!checkElementArray(votingDistrictArray, d.districtVote))
            votingDistrictArray.push({
              key: d._id + '-' + (d.index + 1),
              value: d.districtVote,
            });
        }
        if (d.sectionVote && d.sectionVote !== newVoterData.sectionVote) {
          if (!checkElementArray(votingSectionArray, d.sectionVote))
            votingSectionArray.push({
              key: d._id + '-' + (d.index + 1),
              value: d.sectionVote,
            });
        }
        if (
          d.ineExpirationDate &&
          d.ineFrontPath &&
          d.ineExpirationDate !== newVoterData.ineExpirationDate
        ) {
          if (!checkElementArray(INEArray, d.ineExpirationDate))
            INEArray.push({
              key: d._id + '-' + (d.index + 1),
              value:
                d.ineExpirationDate +
                '&' +
                d.ineFrontPath +
                '&' +
                newVoterData.ineBackPath,
              ineExpirationDate: d.ineExpirationDate,
            });
        }
      });

      setNameSelectArray(nameArray.length > 1 ? nameArray : []);
      setLastNameSelectArray(lastNameArray.length > 1 ? lastNameArray : []);
      setPhoneSelectArray(phoneArray.length > 1 ? phoneArray : []);
      setStreetSelectArray(streetArray.length > 1 ? streetArray : []);
      setExtNumberSelectArray(
        exteriorNumberArray.length > 1 ? exteriorNumberArray : [],
      );
      setIntNumberSelectArray(
        interiorNumberArray.length > 1 ? interiorNumberArray : [],
      );
      setSuburbSelectArray(suburbArray.length > 1 ? suburbArray : []);
      setHomeDistrictSelectArray(
        homeDistrictArray.length > 1 ? homeDistrictArray : [],
      );
      setHomeSectionSelectArray(
        homeSectionArray.length > 1 ? homeSectionArray : [],
      );
      setVotingDistrictSelectArray(
        votingDistrictArray.length > 1 ? votingDistrictArray : [],
      );
      setVotingSectionSelectArray(
        votingSectionArray.length > 1 ? votingSectionArray : [],
      );
      setINESelectArray(INEArray.length > 1 ? INEArray : []);
    }
  }, [voter]);

  return (
    <div className={!active ? 'auditor-list' : 'auditor-list is-open'}>
      <div className="close-button" onClick={setOpen}>
        <RiCloseLine />
      </div>
      <div className="body-logs">
        <div className="title">
          <div className="mean-duplicates">
            <h1 className="title-voter-slide">
              {t('territorial.auditory.list.auditVoter')}
            </h1>
            {voterData.duplicates?.length > 0 && (
              <div className="duplicates-circle duplicates-circle-color">
                {voterData.duplicates.length + 1}
              </div>
            )}
          </div>
          <div>
            <Button
              variant="light"
              className="box-checkout mr-3 width-ranger"
              onClick={() => {
                handleDenyVoter();
              }}
              disabled={false}
            >
              {t('territorial.auditory.btn.deny')}
            </Button>
            <Button
              variant="primary"
              className="width-ranger"
              onClick={() => {
                handleConfirmVoter();
              }}
              disabled={false}
            >
              {t('territorial.auditory.btn.confirm')}
            </Button>
          </div>
        </div>
        <div className="list-nav">
          <Row className="information-content">
            {submitConfirmation && (
              <Col xs={12} md={12} className="col-item-message">
                <p className="">{submitMessage}</p>
              </Col>
            )}
            <Col xl={6} lg={6} md={6} sm={12} className="">
              <h3 className="title-voter-list">
                {' '}
                {t('territorial.auditory.table.name')}{' '}
              </h3>
              <div className="input-box">
                {voterData.duplicates?.length < 1 && (
                  <TagsOptionsTextFieldComponent
                    className={''}
                    horizontal={true}
                    fun={(firstName: string) => {
                      let newVoterValue = voterData;
                      newVoterValue.firstName = firstName;
                      setVoterData(newVoterValue);
                    }}
                    defaultValue={voterData.firstName}
                    length={40}
                    regExp={onlyLetters}
                    placeholder={t('general.modal.lbl.placeholder.name')}
                    refElement={inputNameRef}
                    focusAction={() => {
                      inputLastnameRef.current.focus();
                    }}
                  ></TagsOptionsTextFieldComponent>
                )}
                {voterData.duplicates?.length > 0 && (
                  <TagsOptionsTextFieldComponent
                    className={''}
                    horizontal={true}
                    fun={(firstName: string) => {
                      let newVoterValue = voterData;
                      newVoterValue.firstName = firstName;
                      setVoterData(newVoterValue);
                    }}
                    defaultValue={voterData.firstName}
                    length={40}
                    regExp={onlyLetters}
                    type={'default'}
                    options={nameSelectArray}
                    placeholder={t('general.modal.lbl.placeholder.name')}
                    refElement={inputNameRef}
                    focusAction={() => {
                      inputLastnameRef.current.focus();
                    }}
                  ></TagsOptionsTextFieldComponent>
                )}
              </div>
            </Col>
            <Col xl={6} lg={6} md={6} sm={12} className="">
              <h3 className="title-voter-list">
                {t('territorial.auditory.table.lastname')}
              </h3>
              <div className="input-box">
                {voterData.duplicates?.length < 1 && (
                  <TagsOptionsTextFieldComponent
                    className={''}
                    horizontal={true}
                    fun={(lastName: string) => {
                      let newVoterValue = voterData;
                      newVoterValue.lastName = lastName;
                      setVoterData(newVoterValue);
                    }}
                    defaultValue={voterData.lastName}
                    length={40}
                    regExp={onlyLetters}
                    placeholder={t('general.modal.lbl.placeholder.lastname')}
                    refElement={inputLastnameRef}
                    focusAction={() => {
                      inputStreetRef.current.focus();
                    }}
                  ></TagsOptionsTextFieldComponent>
                )}
                {voterData.duplicates?.length > 0 && (
                  <TagsOptionsTextFieldComponent
                    className={''}
                    horizontal={true}
                    fun={(lastName: string) => {
                      let newVoterValue = voterData;
                      newVoterValue.lastName = lastName;
                      setVoterData(newVoterValue);
                    }}
                    defaultValue={voterData.lastName}
                    length={40}
                    regExp={onlyLetters}
                    type={'default'}
                    options={lastNameSelectArray}
                    placeholder={t('general.modal.lbl.placeholder.lastname')}
                    refElement={inputLastnameRef}
                    focusAction={() => {
                      inputStreetRef.current.focus();
                    }}
                  ></TagsOptionsTextFieldComponent>
                )}
              </div>
            </Col>
          </Row>

          <Row className="information-content">
            <Col xl={6} lg={6} md={6} sm={12} className="">
              <h3 className="title-voter-list">
                {t('territorial.auditory.table.street')}
              </h3>
              <div className="input-box">
                {voterData.duplicates?.length < 1 && (
                  <TagsOptionsTextFieldComponent
                    className={''}
                    horizontal={true}
                    fun={(street: string) => {
                      let newVoterValue = voterData;
                      newVoterValue.address.street = street;
                      setVoterData(newVoterValue);
                    }}
                    defaultValue={voterData.homeAddress.street}
                    length={40}
                    placeholder={t('general.modal.lbl.placeholder.street')}
                    refElement={inputStreetRef}
                    focusAction={() => {
                      inputExteriorRef.current.focus();
                    }}
                  ></TagsOptionsTextFieldComponent>
                )}
                {voterData.duplicates?.length > 0 && (
                  <TagsOptionsTextFieldComponent
                    className={''}
                    horizontal={true}
                    fun={(street: string) => {
                      let newVoterValue = voterData;
                      newVoterValue.address.street = street;
                      setVoterData(newVoterValue);
                    }}
                    defaultValue={voterData.homeAddress.street}
                    length={40}
                    type={'default'}
                    options={streetSelectArray}
                    placeholder={t('general.modal.lbl.placeholder.street')}
                    refElement={inputStreetRef}
                    focusAction={() => {
                      inputExteriorRef.current.focus();
                    }}
                  ></TagsOptionsTextFieldComponent>
                )}
              </div>
            </Col>
            <Col xl={3} lg={3} md={3} sm={6} className="">
              <h3 className="title-voter-list">
                {t('territorial.auditory.table.exteriorNumber')}
              </h3>
              <div className="input-box">
                {voterData.duplicates?.length < 1 && (
                  <TagsOptionsTextFieldComponent
                    className={''}
                    horizontal={true}
                    fun={(exteriorNumber: string) => {
                      let newVoterValue = voterData;
                      newVoterValue.address.exteriorNumber = exteriorNumber;
                      setVoterData(newVoterValue);
                    }}
                    defaultValue={voterData.homeAddress.exteriorNumber}
                    length={10}
                    placeholder={t('general.modal.lbl.placeholder.exterior')}
                    refElement={inputExteriorRef}
                    focusAction={() => {
                      inputInteriorRef.current.focus();
                    }}
                  ></TagsOptionsTextFieldComponent>
                )}
                {voterData.duplicates?.length > 0 && (
                  <TagsOptionsTextFieldComponent
                    className={''}
                    horizontal={true}
                    fun={(exteriorNumber: string) => {
                      let newVoterValue = voterData;
                      newVoterValue.address.exteriorNumber = exteriorNumber;
                      setVoterData(newVoterValue);
                    }}
                    defaultValue={voterData.homeAddress.exteriorNumber}
                    length={10}
                    type={'default'}
                    options={extNumberSelectArray}
                    placeholder={t('general.modal.lbl.placeholder.exterior')}
                    refElement={inputExteriorRef}
                    focusAction={() => {
                      inputInteriorRef.current.focus();
                    }}
                  ></TagsOptionsTextFieldComponent>
                )}
              </div>
            </Col>
            <Col xl={3} lg={3} md={3} sm={6} className="">
              <h3 className="title-voter-list">
                {t('territorial.auditory.table.interiorNumber')}
              </h3>
              <div className="input-box">
                {voterData.duplicates?.length < 1 && (
                  <TagsOptionsTextFieldComponent
                    className={''}
                    horizontal={true}
                    fun={(interiorNumber: string) => {
                      let newVoterValue = voterData;
                      newVoterValue.address.interiorNumber = interiorNumber;
                      setVoterData(newVoterValue);
                    }}
                    defaultValue={voterData.homeAddress.interiorNumber}
                    length={10}
                    placeholder={t('general.modal.lbl.placeholder.interior')}
                    refElement={inputInteriorRef}
                    focusAction={() => {
                      inputSuburbRef.current.focus();
                    }}
                  ></TagsOptionsTextFieldComponent>
                )}
                {voterData.duplicates?.length > 0 && (
                  <TagsOptionsTextFieldComponent
                    className={''}
                    horizontal={true}
                    fun={(interiorNumber: string) => {
                      let newVoterValue = voterData;
                      newVoterValue.address.interiorNumber = interiorNumber;
                      setVoterData(newVoterValue);
                    }}
                    defaultValue={voterData.homeAddress.interiorNumber}
                    length={10}
                    type={'default'}
                    options={intNumberSelectArray}
                    placeholder={t('general.modal.lbl.placeholder.interior')}
                    refElement={inputInteriorRef}
                    focusAction={() => {
                      inputSuburbRef.current.focus();
                    }}
                  ></TagsOptionsTextFieldComponent>
                )}
              </div>
            </Col>
          </Row>

          <Row className="information-content">
            <Col xl={6} lg={6} md={6} sm={12} className="">
              <h3 className="title-voter-list">
                {t('territorial.auditory.table.suburb')}
              </h3>
              <div className="input-box">
                {voterData.duplicates?.length < 1 && (
                  <TagsOptionsTextFieldComponent
                    className={''}
                    horizontal={true}
                    fun={(suburb: string) => {
                      let newVoterValue = voterData;
                      newVoterValue.address.suburb = suburb;
                      setVoterData(newVoterValue);
                    }}
                    defaultValue={voterData.homeAddress.suburb}
                    length={40}
                    placeholder={t('general.modal.lbl.placeholder.suburb')}
                    refElement={inputSuburbRef}
                    focusAction={() => {
                      inputCellphoneRef.current.focus();
                    }}
                  ></TagsOptionsTextFieldComponent>
                )}
                {voterData.duplicates?.length > 0 && (
                  <TagsOptionsTextFieldComponent
                    className={''}
                    horizontal={true}
                    fun={(suburb: string) => {
                      let newVoterValue = voterData;
                      newVoterValue.address.suburb = suburb;
                      setVoterData(newVoterValue);
                    }}
                    defaultValue={voterData.homeAddress.suburb}
                    length={40}
                    type={'default'}
                    options={suburbSelectArray}
                    placeholder={t('general.modal.lbl.placeholder.suburb')}
                    refElement={inputSuburbRef}
                    focusAction={() => {
                      inputCellphoneRef.current.focus();
                    }}
                  ></TagsOptionsTextFieldComponent>
                )}
              </div>
            </Col>
            <Col xl={6} lg={6} md={6} sm={12} className="">
              <h3 className="title-voter-list">
                {t('territorial.auditory.table.cellphone')}
              </h3>
              <div className="input-box">
                {voterData.duplicates?.length < 1 && (
                  <TagsOptionsTextFieldComponent
                    className={''}
                    horizontal={true}
                    fun={(cellphone: string) => {
                      let newVoterValue = voterData;
                      newVoterValue.cellphone = formatPhoneNumber(cellphone);
                      setVoterData(newVoterValue);
                    }}
                    defaultValue={formatPhoneNumber(voterData.cellphone)}
                    length={20}
                    regExp={onlyDigits}
                    placeholder={t('general.modal.lbl.cellphone')}
                    refElement={inputCellphoneRef}
                    focusAction={() => {
                      inputHomeDistrictRef.current.focus();
                    }}
                  ></TagsOptionsTextFieldComponent>
                )}
                {voterData.duplicates?.length > 0 && (
                  <TagsOptionsTextFieldComponent
                    className={''}
                    horizontal={true}
                    fun={(cellphone: string) => {
                      let newVoterValue = voterData;
                      newVoterValue.cellphone = formatPhoneNumber(cellphone);
                      setVoterData(newVoterValue);
                    }}
                    defaultValue={formatPhoneNumber(voterData.cellphone)}
                    length={20}
                    regExp={onlyDigits}
                    type={'default'}
                    options={phoneSelectArray}
                    placeholder={t('general.modal.lbl.cellphone')}
                    refElement={inputCellphoneRef}
                    focusAction={() => {
                      inputHomeDistrictRef.current.focus();
                    }}
                  ></TagsOptionsTextFieldComponent>
                )}
              </div>
            </Col>
          </Row>

          <Row className="information-content">
            <Col xl={6} lg={6} md={6} sm={12} className="">
              <h3 className="title-voter-list">
                {t('territorial.voters.lbl.homeDistrict')}
              </h3>
              <div className="input-box">
                {voterData.duplicates?.length < 1 && (
                  <TagsOptionsTextFieldComponent
                    className={''}
                    horizontal={true}
                    fun={(districtHome: string) => {
                      let newVoterValue = voterData;
                      newVoterValue.districtHome = districtHome;
                      setVoterData(newVoterValue);
                    }}
                    defaultValue={voterData.districtHome}
                    length={40}
                    regExp={undefined}
                    placeholder={t('territorial.voters.lbl.homeDistrict')}
                    refElement={inputHomeDistrictRef}
                    focusAction={() => {
                      inputHomeSectionRef.current.focus();
                    }}
                  ></TagsOptionsTextFieldComponent>
                )}
                {voterData.duplicates?.length > 0 && (
                  <TagsOptionsTextFieldComponent
                    className={''}
                    horizontal={true}
                    fun={(districtHome: string) => {
                      let newVoterValue = voterData;
                      newVoterValue.districtHome = districtHome;
                      setVoterData(newVoterValue);
                    }}
                    defaultValue={voterData.districtHome}
                    length={40}
                    regExp={undefined}
                    type={'default'}
                    options={homeDistrictSelectArray}
                    placeholder={t('territorial.voters.lbl.homeDistrict')}
                    refElement={inputHomeDistrictRef}
                    focusAction={() => {
                      inputHomeSectionRef.current.focus();
                    }}
                  ></TagsOptionsTextFieldComponent>
                )}
              </div>
            </Col>
            <Col xl={6} lg={6} md={6} sm={12} className="">
              <h3 className="title-voter-list">
                {t('territorial.voters.lbl.homeSection')}
              </h3>
              <div className="input-box">
                {voterData.duplicates?.length < 1 && (
                  <TagsOptionsTextFieldComponent
                    className={''}
                    horizontal={true}
                    fun={(sectionHome: string) => {
                      let newVoterValue = voterData;
                      newVoterValue.sectionHome = sectionHome;
                      setVoterData(newVoterValue);
                    }}
                    defaultValue={voterData.sectionHome}
                    length={40}
                    regExp={undefined}
                    placeholder={t('territorial.voters.lbl.homeSection')}
                    refElement={inputHomeSectionRef}
                    focusAction={() => {
                      inputVotingDistrictRef.current.focus();
                    }}
                  ></TagsOptionsTextFieldComponent>
                )}
                {voterData.duplicates?.length > 0 && (
                  <TagsOptionsTextFieldComponent
                    className={''}
                    horizontal={true}
                    fun={(sectionHome: string) => {
                      let newVoterValue = voterData;
                      newVoterValue.sectionHome = sectionHome;
                      setVoterData(newVoterValue);
                    }}
                    defaultValue={voterData.sectionHome}
                    length={40}
                    regExp={undefined}
                    type={'default'}
                    options={homeSectionSelectArray}
                    placeholder={t('territorial.voters.lbl.homeSection')}
                    refElement={inputHomeSectionRef}
                    focusAction={() => {
                      inputVotingDistrictRef.current.focus();
                    }}
                  ></TagsOptionsTextFieldComponent>
                )}
              </div>
            </Col>
          </Row>

          <Row className="information-content">
            <Col xl={6} lg={6} md={6} sm={12} className="">
              <h3 className="title-voter-list">
                {t('territorial.voters.lbl.voteDistrict')}
              </h3>
              <div className="input-box">
                {voterData.duplicates?.length < 1 && (
                  <TagsOptionsTextFieldComponent
                    className={''}
                    horizontal={true}
                    fun={(districtVote: string) => {
                      let newVoterValue = voterData;
                      newVoterValue.districtVote = districtVote;
                      setVoterData(newVoterValue);
                    }}
                    defaultValue={voterData.districtVote}
                    length={40}
                    regExp={undefined}
                    placeholder={t('territorial.voters.lbl.voteDistrict')}
                    refElement={inputVotingDistrictRef}
                    focusAction={() => {
                      inputVotingSectionRef.current.focus();
                    }}
                  ></TagsOptionsTextFieldComponent>
                )}
                {voterData.duplicates?.length > 0 && (
                  <TagsOptionsTextFieldComponent
                    className={''}
                    horizontal={true}
                    fun={(districtVote: string) => {
                      let newVoterValue = voterData;
                      newVoterValue.districtVote = districtVote;
                      setVoterData(newVoterValue);
                    }}
                    defaultValue={voterData.districtVote}
                    length={40}
                    regExp={undefined}
                    type={'default'}
                    options={votingDistrictSelectArray}
                    placeholder={t('territorial.voters.lbl.voteDistrict')}
                    refElement={inputVotingDistrictRef}
                    focusAction={() => {
                      inputVotingSectionRef.current.focus();
                    }}
                  ></TagsOptionsTextFieldComponent>
                )}
              </div>
            </Col>
            <Col xl={6} lg={6} md={6} sm={12} className="">
              <h3 className="title-voter-list">
                {t('territorial.voters.lbl.voteSection')}
              </h3>
              <div className="input-box">
                {voterData.duplicates?.length < 1 && (
                  <TagsOptionsTextFieldComponent
                    className={''}
                    horizontal={true}
                    fun={(sectionVote: string) => {
                      let newVoterValue = voterData;
                      newVoterValue.sectionVote = sectionVote;
                      setVoterData(newVoterValue);
                    }}
                    defaultValue={voterData.sectionVote}
                    length={40}
                    regExp={undefined}
                    placeholder={t('territorial.voters.lbl.voteSection')}
                    refElement={inputVotingSectionRef}
                    focusAction={() => {
                      inputCellphoneRef.current.focus();
                    }}
                  ></TagsOptionsTextFieldComponent>
                )}
                {voterData.duplicates?.length > 0 && (
                  <TagsOptionsTextFieldComponent
                    className={''}
                    horizontal={true}
                    fun={(sectionVote: string) => {
                      let newVoterValue = voterData;
                      newVoterValue.sectionVote = sectionVote;
                      setVoterData(newVoterValue);
                    }}
                    defaultValue={voterData.sectionVote}
                    length={40}
                    regExp={undefined}
                    type={'default'}
                    options={votingSectionSelectArray}
                    placeholder={t('territorial.voters.lbl.voteSection')}
                    refElement={inputVotingSectionRef}
                    focusAction={() => {
                      handleConfirmVoter();
                    }}
                  ></TagsOptionsTextFieldComponent>
                )}
              </div>
            </Col>
          </Row>

          <Row className="information-content">
            <Col xl={7} lg={7} md={7} sm={12} className="">
              <h3 className="title-voter-list">
                {' '}
                {t('territorial.auditory.table.sex')}{' '}
              </h3>
              <div className="input-box">
                <TagsOptionsTextFieldComponent
                  className={''}
                  horizontal={true}
                  fun={(sexValue: string) => {
                    let newVoterValue = voterData;
                    newVoterValue.sex = sexValue;
                    setVoterData(newVoterValue);
                  }}
                  defaultValue={voterData.sex}
                  optionSelected={voterData.sex}
                  type={'sex'}
                ></TagsOptionsTextFieldComponent>
              </div>
            </Col>
            <Col xl={5} lg={5} md={5} sm={12} className="">
              <h3 className="title-voter-list">
                {t('territorial.auditory.table.IDphoto')}
              </h3>
              {voterData.duplicates?.length < 1 &&
                voterData.ineExpirationDate !== '' && (
                  <Image
                    className="photo-id-fluid"
                    src={photoIdPlaceHolderFront}
                    fluid
                    onClick={() => {}}
                  />
                )}
              {voterData.duplicates?.length < 1 &&
                voterData.ineExpirationDate === '' && (
                  <Image
                    className="photo-id-fluid"
                    src={photoIdPlaceHolderFrontNoINE}
                    fluid
                    onClick={() => {}}
                  />
                )}
              {voterData.duplicates?.length > 0 && INESelectArray?.length > 1 && (
                <div className="input-box">
                  <TagsOptionsTextFieldComponent
                    className={''}
                    classNameInput={'textarea-input-duplicate'}
                    horizontal={true}
                    fun={(ineInformation: string) => {
                      setIneSelectionMode(true);
                      let newVoterValue = voterData;

                      let defaultIneValue = ineInformation + '';
                      const split = defaultIneValue.split('&');

                      newVoterValue.ineExpirationDate = split[0]?.trim() || '';
                      newVoterValue.ineFrontPath = split[1]?.trim() || '';
                      newVoterValue.ineBackPath = split[2]?.trim() || '';
                      setVoterData(newVoterValue);
                    }}
                    defaultValue={'&'}
                    type={'photo'}
                    options={INESelectArray}
                    rows={2}
                    as={'textarea'}
                  ></TagsOptionsTextFieldComponent>
                </div>
              )}
            </Col>
          </Row>

          <Row className="information-content">
            <Col xl={12} lg={12} md={12} sm={12} className="">
              <h3 className="title-voter-list">
                {t('territorial.auditory.table.volunteer')}
              </h3>
              <p className="content-voter-list">
                {voterData.capturer ? voterData.capturer : '-'}
              </p>
            </Col>
          </Row>
        </div>
      </div>
      {modalShowAdd && (
        <ModalDenyVoter
          modalShow={modalShowAdd}
          setModalShow={setModalShowAdd}
          setOpen={setOpen}
        ></ModalDenyVoter>
      )}
    </div>
  );
};
export default VoterListComponent;
