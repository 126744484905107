import {
  Bingo,
  BingoDispatchTypes,
  PollingStation,
  SET_BINGO,
  SET_DISTRICTS,
  SET_DISTRICTS_SELECTED,
  SET_POLLING_STATIONS,
  SET_POLLING_STATIONS_SELECTED,
  SET_SECTIONS,
  SET_SECTIONS_SELECTED,
  STATUS_BINGO_VOTERS,
  UPDATE_BINGO,
  UPDATE_STATS,
} from './bingoTypes';
import store from '../../redux/Store';

export interface CampaignState {
  loading: boolean;
  pollingStation?: PollingStation;
  stats: {
    forWin: number;
    toWin: number;
    available: number;
    ourVotes: number;
    totalMarked: number;
    total: number;
  };
  districts: { _id: string; name: string }[];
  sections: { _id: string; name: string }[];
  pollingStations: { _id: string; name: string }[];
  districtSelected?: { _id: string; name: string };
  sectionSelected?: { _id: string; name: string };
  pollingStationSelected?: { _id: string; name: string };
}

export const defaultState: CampaignState = {
  loading: false,
  pollingStation: undefined,
  stats: {
    forWin: 0,
    toWin: 0,
    available: 0,
    ourVotes: 0,
    totalMarked: 0,
    total: 0,
  },
  districts: [],
  sections: [],
  pollingStations: [],
  districtSelected: undefined,
  sectionSelected: undefined,
  pollingStationSelected: undefined,
};

const getStats = (pollingStation: PollingStation) => {
  const stats = {
    forWin: 0,
    toWin: 0,
    available: 0,
    ourVotes: 0,
    totalMarked: 0,
    total: 0,
  };
  if (pollingStation) {
    stats.toWin = pollingStation.winningNumber || 0;
    for (const voter of pollingStation.bingo.voters) {
      stats.total = stats.total + 1;
      if (voter.inDatabase) stats.available = stats.available + 1;
      if (voter.status === STATUS_BINGO_VOTERS.MARKED) {
        stats.totalMarked = stats.totalMarked + 1;
        if (voter.inDatabase) stats.ourVotes = stats.ourVotes + 1;
      }
    }
    stats.forWin = stats.toWin - stats.ourVotes;
  }
  return stats;
};

export const bingoReducer = (
  state: CampaignState = defaultState,
  action: BingoDispatchTypes,
): CampaignState => {
  switch (action.type) {
    case SET_BINGO:
      return {
        ...state,
        pollingStation: action.data,
        stats: getStats(action.data),
      };
    case UPDATE_BINGO:
      const voters = [];
      console.log('action', action.data);
      if (state.pollingStation?.bingo) {
        voters.push(...state.pollingStation.bingo.voters);
        for (let i = 0; i < voters.length; i++)
          if (voters[i].number === action.data?.number) {
            voters[i] = action.data;
            break;
          }
        const pollingStation = {
          ...state.pollingStation,
          bingo: {
            status: state.pollingStation.bingo.status,
            voters,
          },
        };

        return {
          ...state,
          pollingStation,
          stats: getStats(pollingStation),
        };
      }
    case UPDATE_STATS:
      return { ...state };
    case SET_DISTRICTS:
      return { ...state, districts: action.data };
    case SET_SECTIONS:
      return { ...state, sections: action.data };
    case SET_POLLING_STATIONS:
      return { ...state, pollingStations: action.data };
      
    case SET_DISTRICTS_SELECTED:
      return { ...state, districtSelected: action.data };
    case SET_SECTIONS_SELECTED:
      return { ...state, sectionSelected: action.data };
    case SET_POLLING_STATIONS_SELECTED:
      return { ...state, pollingStationSelected: action.data };
    default:
      return state;
  }
};
