import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootStore } from '../../../redux/Store';
import * as netWorking from '../../../redux/netWorking';

import {
  getVotersBoxByCoordinates,
  getVotersBoxTotalByCoordinates,
} from '../../../redux/votersBox/VotersBoxActions';
import { XLS_TYPE } from '../../../utils/utils';

import LayerControlComponent from '../../../components/sub-component/layerControl';
import { DownloadIcon } from '../../../assets/svg/index';
import VotersBoxMapIcon from '../../../assets/svg/icon_casilla.svg';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import { BsThreeDots } from 'react-icons/bs';
import ModalAddVotersBox from './addVotersbox';
import ModalDelete from './modalDelete';
import ModalAddXLS from '../../../dialogs/modalXLS';

import MarkerClusterGroup from 'react-leaflet-markercluster';
import { Map, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { createClusterCustomIcon } from '../../../components/sub-component/markers';

const Maps: React.FunctionComponent = () => {
  const citySelected: any = useSelector((globalState: RootStore) => {
    return globalState.core.globalMunicipalitySelected;
  });

  const voterBoxsMap = useSelector(
    (globalState: RootStore) => globalState.votersBox.voterBoxsMap,
  );
  const totalVoterBoxsMap: number = useSelector(
    (globalState: RootStore) => globalState.votersBox.totalVoterBoxsMap,
  );
  const search = useSelector(
    (globalState: RootStore) => globalState.votersBox.searchMap,
  );

  let defaultVoterBox: any;
  let defaultCoordinate: any;

  const [radius, setRadius] = useState(144447.6442);
  const [coordinates, setCoordinates] = useState(defaultCoordinate);
  const [modalShowAdd, setModalShowAdd] = useState(false);
  const [modalDeleteShow, setModalDeleteShow] = useState(false);
  const [modalShowXls, setModalShowXls] = useState(false);
  const [edit, setEdit] = useState(Object);
  const [toDelete, setToDelete] = useState(Array);

  const defaultPosition: any = {
    lat: 32.7157431,
    lng: -117.1888535,
    zoom: 11,
  };

  const [center, setCenter] = React.useState(defaultCoordinate);
  const [zoom, setZoom] = React.useState(defaultCoordinate);

  useEffect(() => {
    if (citySelected) {
      setCenter(
        citySelected?.defaultCenter !== undefined
          ? citySelected?.defaultCenter
          : [defaultPosition.lat, defaultPosition.lng],
      );
      setZoom(
        citySelected?.defaultZoom !== undefined
          ? citySelected?.defaultZoom
          : defaultPosition.zoom,
      );
      setCoordinates(
        citySelected?.defaultCenter !== undefined
          ? [citySelected?.defaultCenter.lng, citySelected?.defaultCenter.lat]
          : [defaultPosition.lng, defaultPosition.lat],
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [citySelected]);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const redMarker = L.icon({
    iconUrl: VotersBoxMapIcon,
    iconSize: [40, 40],
    iconAnchor: [20, 40],
  });

  const dropdown = (ref: any, id: string) => {
    return (
      <Dropdown
        as={ButtonGroup}
        id={`dropdown-button-drop-down`}
        className="dropdown-options"
      >
        <Dropdown.Toggle id="dropdown-custom-marker">
          <Row className="h100 w100" noGutters>
            <BsThreeDots />
          </Row>
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-body">
          <Dropdown.Item
            eventKey="1"
            onClick={() => {
              closePopusOnClick(ref);
              openDelete([id]);
            }}
          >
            {t('settings.polling.dropdown.delete')}
          </Dropdown.Item>
          <Dropdown.Item
            eventKey="1"
            onClick={() => {
              closePopusOnClick(ref);
              openEdit(id);
            }}
          >
            {t('settings.polling.dropdown.edit')}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const closePopusOnClick = (popup: any) => {
    popup.current.leafletElement.options.leaflet.map.closePopup();
  };

  const getAddress = (address: any) => {
    let result = '';
    const newAddress = address;
    if (newAddress) {
      if (newAddress.street && newAddress.street !== '')
        result = newAddress.street;
      if (newAddress.exteriorNumber && newAddress.exteriorNumber !== '')
        result = result + `, ${newAddress.exteriorNumber}`;
      if (newAddress.interiorNumber && newAddress.interiorNumber !== '')
        result = result + `, ${newAddress.interiorNumber}`;
      if (newAddress.postalCode && newAddress.postalCode !== '')
        result = result + `, ${newAddress.postalCode}`;
      if (newAddress.suburb && newAddress.suburb !== '')
        result = result + `, ${newAddress.suburb}`;
    }
    return result;
  };

  const getUbication = (ubication: any) => {
    let result = '';
    let aux_count = 0;
    const newUbication = ubication;
    newUbication.forEach((ub: any) => {
      if (aux_count === 0) {
        result = ub;
      } else {
        result = result + ', ' + ub;
      }
      aux_count++;
    });
    return result;
  };

  const getMaxDistance = (zoom: number) => {
    let km = 144447.6442;
    if (zoom === 20) {
      km = 1128.49722;
    } else if (zoom === 19) {
      km = 2256.99444;
    } else if (zoom === 18) {
      km = 4513.98888;
    } else if (zoom === 17) {
      km = 9027.977761;
    } else if (zoom === 16) {
      km = 18055.95552;
    } else if (zoom === 15) {
      km = 36111.91104;
    } else if (zoom === 14) {
      km = 72223.82209;
    } else if (zoom === 13) {
      km = 144447.6442;
    } else if (zoom === 12) {
      km = 288895.2884;
    } else if (zoom === 11) {
      km = 577790.5767;
    } else if (zoom === 10) {
      km = 1155581.153;
    } else if (zoom === 9) {
      km = 2311162.307;
    } else if (zoom === 8) {
      km = 4622324.614;
    } else if (zoom === 7) {
      km = 9244649.227;
    } else if (zoom === 6) {
      km = 18489298.45;
    } else if (zoom === 5) {
      km = 36978596.91;
    } else if (zoom === 4) {
      km = 73957193.82;
    } else if (zoom === 3) {
      km = 147914387.6;
    } else if (zoom === 2) {
      km = 295828775.3;
    } else if (zoom === 1) {
      km = 591657550.5;
    }
    return km;
  };

  const handleAddVBClick = () => {
    setEdit(defaultVoterBox);
    setModalShowAdd(true);
  };

  const openEdit = (_id: any) => {
    const vb = voterBoxsMap?.find((value: any) => value._id === _id);
    if (vb) {
      setEdit(vb);
      setModalShowAdd(true);
    }
  };

  const openDelete = (deleteArray: any) => {
    setToDelete(deleteArray);
    setModalDeleteShow(true);
  };

  const searchByValue = () => {
    const params = buildParamSearchVoterBoxDataMap(search);
    dispatch(getVotersBoxByCoordinates(params));
    dispatch(getVotersBoxTotalByCoordinates(params));
  };

  const buildParamSearchVoterBoxDataMap = (search: string) => {
    let requestObject;
    requestObject = {
      cityLicenseId: citySelected?._id,
      municipalityId: citySelected?.municipalityId,
      districtId: '',
      sectionId: '',
      search: search.length >= 3 ? search : '',
      longitude: coordinates[0],
      latitude: coordinates[1],
      radius,
    };
    return requestObject;
  };

  useEffect(() => {
    if (citySelected && coordinates) {
      searchByValue();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [citySelected]);

  useEffect(() => {
    if (radius && coordinates) {
      searchByValue();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [radius]);

  useEffect(() => {
    if (radius && coordinates) {
      searchByValue();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coordinates]);

  useEffect(() => {
    if (radius && coordinates && (search.length >= 3 || search.length <= 0)) {
      searchByValue();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <div className="box-settings-list">
      <div className="box-settings-container-second">
        <div className="header-main-title">
          <h1>
            {t('electoral.rcrg.table.rgs.polling') +
              ' [' +
              totalVoterBoxsMap +
              ']'}
          </h1>
        </div>
        <div className="search-action-box">
          <div className="row-buttons">
            <Button
              className="template-button"
              variant="outline-dark"
              onClick={() => {
                netWorking.downloadXls(
                  XLS_TYPE.VOTERS_BOX_XLS_TYPE,
                  citySelected?.name || '',
                );
              }}
            >
              <DownloadIcon></DownloadIcon>
              {t('general.btn.template')}
            </Button>
          </div>
          <Button
            variant="primary"
            onClick={() => {
              setModalShowXls(true);
            }}
            className="upload-xls-button"
          >
            {t('general.btn.uploadXls')}
          </Button>
          <Button
            variant="primary"
            onClick={handleAddVBClick}
            className="search-add-button"
          >
            {t('settings.trainings.form.add')}
          </Button>
        </div>
      </div>
      <div className="map-box-box">
        <Map
          center={center}
          zoom={zoom}
          ondragend={(map: any) => {
            setCoordinates([
              map.target.getCenter().lng,
              map.target.getCenter().lat,
            ]);
            let zoom = map.target.getZoom();
            setRadius(getMaxDistance(zoom));
          }}
          onzoomend={(map: any) => {
            setCoordinates([
              map.target.getCenter().lng,
              map.target.getCenter().lat,
            ]);
            let zoom = map.target.getZoom();
            setRadius(getMaxDistance(zoom));
          }}
        >
          <LayerControlComponent></LayerControlComponent>
          <MarkerClusterGroup
            showCoverageOnHover={false}
            spiderfyDistanceMultiplier={2}
            iconCreateFunction={createClusterCustomIcon}
          >
            {voterBoxsMap.map((voterBoxes: any) => {
              const popup = React.createRef<Popup>();

              return (
                <Marker
                  key={voterBoxes._id}
                  position={[
                    voterBoxes.address?.geoLocation.coordinates[1],
                    voterBoxes.address?.geoLocation.coordinates[0],
                  ]}
                  icon={redMarker}
                >
                  <Popup ref={popup}>
                    <div
                    /*onClick={() => {
                        closePopusOnClick(popup);
                      }}*/
                    >
                      <div className="marker-actions">
                        <h2>
                          {t('electoral.rcrg.modal.lbl.polling')}{' '}
                          {voterBoxes.number}
                        </h2>
                        {dropdown(popup, voterBoxes._id)}
                      </div>
                      <p>
                        <strong>{t('settings.polling.table.address')}:</strong>
                        {getAddress(voterBoxes.address)}
                      </p>
                      <p>
                        <strong>{t('settings.polling.table.city')}:</strong>
                        {voterBoxes.address?.municipality}
                      </p>
                      <p>
                        <strong>{t('settings.polling.table.location')}:</strong>
                        {voterBoxes.address?.geoLocation?.coordinates
                          ? getUbication(
                              voterBoxes.address.geoLocation.coordinates,
                            )
                          : getUbication([0, 0])}
                      </p>
                      <p>
                        <strong>{t('settings.polling.table.district')}:</strong>
                        {voterBoxes.district}
                        <strong>{t('settings.polling.table.section')}:</strong>
                        {voterBoxes.section}
                      </p>
                    </div>
                  </Popup>
                </Marker>
              );
            })}
          </MarkerClusterGroup>
        </Map>
      </div>
      {modalShowXls && (
        <ModalAddXLS
          modalShow={modalShowXls}
          setModalShow={setModalShowXls}
          type={XLS_TYPE.VOTERS_BOX_XLS_TYPE}
          municipalityId={citySelected?._id}
          successFun={(res: any) => {
            searchByValue();
            setToDelete([]);
          }}
        ></ModalAddXLS>
      )}
      {modalShowAdd && (
        <ModalAddVotersBox
          modalShow={modalShowAdd}
          successFun={() => {
            searchByValue();
          }}
          setModalShow={setModalShowAdd}
          edit={edit}
        ></ModalAddVotersBox>
      )}
      {modalDeleteShow && (
        <ModalDelete
          modalShow={modalDeleteShow}
          successFun={() => {
            searchByValue();
            setToDelete([]);
          }}
          toDelete={toDelete}
          setModalShow={setModalDeleteShow}
          votersBox={voterBoxsMap}
          deleteMessage={t('electoral.rcrg.modal.lbl.polling')}
          t={t}
        ></ModalDelete>
      )}
    </div>
  );
};

export default Maps;
