import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../redux/Store';
import { useTranslation } from 'react-i18next';

import {
  validEmailRegex,
  onlyLetters,
  onlyDigits,
  validPhoneRegex,
  ROLE,
} from '../../../utils/utils';
import { checkInUse } from '../../../redux/User/userActions';
import {
  putUpdateUserTeam,
  postCreateUserTeam,
} from '../../../redux/lawyers/lawyersAction';

import InputComponent from '../../../components/sub-component/input';
import StaticModal from '../../../dialogs/StaticModal';
import Container from 'react-bootstrap/Container';
import Switch from 'react-switch';

interface AddLawyerProps {
  modalShow: any;
  setModalShow: any;
  successFun: any;
  edit?: any;
  type: any;
  mainTitle: string;
}

const AddLawyer: React.FunctionComponent<AddLawyerProps> = ({
  modalShow,
  setModalShow,
  successFun,
  edit,
  type,
  mainTitle = '',
}: any) => {
  const citySelected = useSelector((globalState: RootStore) => {
    return globalState.core.globalMunicipalitySelected;
  });

  const stateSelected = useSelector((globalState: RootStore) => {
    return globalState.core.globalStateSelected;
  });

  const defaultEdit = {
    _id: '',
    firstName: '',
    lastName: '',
    email: '',
    cellphone: '',
    role: type,
    blocked: false,
    deleted: false,
    blockedReason: '',
    name: '',
    municipalityId: '',
    campaignId: localStorage.campaignId,
  };
  const [toSave, setToSave] = useState(defaultEdit);
  const [toCompare, setToCompare] = useState(defaultEdit);

  const [submitMessage, setSubmitMessage] = useState(String);
  const [submitConfirmation, setSubmitConfirmation] = useState(false);

  const [loadData, setLoadData] = useState(false);

  const inputFirstnameRef: any = useRef(null);
  const inputLastnameRef: any = useRef(null);
  const inputEmailRef: any = useRef(null);
  const inputCellphoneRef: any = useRef(null);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const getMunicipality = () => {
    setToSave((prevState) => ({
      ...prevState,
      municipalityId: citySelected?._id || '',
      states: [stateSelected?._id],
      cities: [citySelected?._id],
    }));
  };

  const submitFun = (validSubmit: any) => {
    if (validSubmit) {
      setSubmitMessage(validSubmit);
      setSubmitConfirmation(true);
    } else {
      if (toSave._id !== '') {
        setModalShow(false);
        dispatch(
          putUpdateUserTeam(
            {
              ...toSave,
            },
            () => {
              successFun();
            },
          ),
        );
      } else {
        setModalShow(false);
        dispatch(
          postCreateUserTeam(
            {
              ...toSave,
            },
            (res: any) => {
              successFun();
            },
          ),
        );
      }
    }
  };

  const verifyCellphoneInUse = () => {
    let checkInUseAux: any = {
      cellphone: toSave.cellphone,
    };

    if (toSave.cellphone === '' || toSave.cellphone === undefined) {
      validSubmit(1);
    } else {
      dispatch(
        checkInUse(checkInUseAux, (data: any) => {
          validSubmit(data.error);
        }),
      );
    }
  };

  const validSubmit = (data: any) => {
    let newStringValue = verifyFields(data);
    submitFun(newStringValue);
  };

  const verifyFields = (data: any) => {
    if (toSave.firstName === '') {
      return t('territorial.volunteer.modal.error.name');
    } else if (toSave.lastName === '') {
      return t('territorial.volunteer.modal.error.lastname');
    } else if (toSave.email !== '' && !validEmailRegex.test(toSave.email)) {
      return t('territorial.volunteer.modal.error.email');
    } else if (
      toSave.cellphone === '' ||
      !validPhoneRegex.test(toSave.cellphone) ||
      data === 0
    ) {
      if (data === 0 && toSave.cellphone === toCompare.cellphone) {
        return '';
      } else if (data === 0 && toSave.cellphone !== toCompare.cellphone) {
        return t('territorial.volunteer.modal.error.cellphoneExist');
      } else if (toSave.cellphone === '') {
        return t('territorial.volunteer.modal.error.cellphoneComplete');
      } else if (!validPhoneRegex.test(toSave.cellphone)) {
        return t('territorial.volunteer.modal.error.cellphone');
      } else if (toSave.cellphone.length < 5) {
        return t('territorial.volunteer.modal.error.cellphone');
      }
    } else {
      return '';
    }
  };

  useEffect(() => {
    if (edit !== undefined) {
      setToSave((prevState) => ({
        ...prevState,
        ...edit,
        cellphone: edit.cellphone.replace(/\D/g, ''),
        name: edit.firstName + ', ' + edit.lastName,
      }));
      setToCompare((prevState) => ({
        ...prevState,
        ...edit,
        cellphone: edit.cellphone.replace(/\D/g, ''),
        name: edit.firstName + ', ' + edit.lastName,
      }));
    }
    setLoadData(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edit]);

  useEffect(() => {
    if (loadData) {
      getMunicipality();
      inputFirstnameRef.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadData]);

  return (
    <StaticModal
      show={modalShow}
      title={mainTitle}
      closeText={t('submit.cancel')}
      confirmText={t('general.modal.btn.confirm')}
      onHide={() => {
        setModalShow(false);
      }}
      submitFun={verifyCellphoneInUse}
      validBtn={false}
      size="lg"
      sizeClass="md modal-rc"
    >
      <>
        <Container className="dialog-add-teams">
          {submitConfirmation && (
            <div className="col-item-message">
              <p>{submitMessage}</p>
            </div>
          )}
          <div className="row-item-modal first-row">
            <div className="col-item-modal">
              <InputComponent
                placeholder={t('general.modal.lbl.placeholder.name')}
                length={50}
                onChange={(value: string) => {
                  setToSave((prevState) => ({
                    ...prevState,
                    firstName: value,
                  }));
                }}
                title={t('general.modal.lbl.name')}
                value={toSave.firstName}
                regExp={onlyLetters}
                horizontal={false}
                refElement={inputFirstnameRef}
                focusAction={() => {
                  inputLastnameRef.current.focus();
                }}
              ></InputComponent>
            </div>
            <div className="col-item-modal">
              <InputComponent
                placeholder={t('general.modal.lbl.placeholder.lastname')}
                length={50}
                onChange={(value: string) => {
                  setToSave((prevState) => ({
                    ...prevState,
                    lastName: value,
                  }));
                }}
                title={t('general.modal.lbl.lastname')}
                value={toSave.lastName}
                regExp={onlyLetters}
                horizontal={false}
                refElement={inputLastnameRef}
                focusAction={() => {
                  inputCellphoneRef.current.focus();
                }}
              ></InputComponent>
            </div>
          </div>
          <div className="row-item-modal second-row">
            <div className="col-item-modal cellphone-item">
              <InputComponent
                length={20}
                placeholder={t('general.modal.lbl.cellphone')}
                onChange={(value: string) => {
                  setToSave((prevState) => ({
                    ...prevState,
                    cellphone: value.trim(),
                  }));
                }}
                title={t('general.modal.lbl.cellphone')}
                regExp={onlyDigits}
                value={toSave.cellphone}
                horizontal={false}
                refElement={inputCellphoneRef}
                focusAction={() => {
                  inputEmailRef.current.focus();
                }}
              ></InputComponent>
            </div>
            <div className="col-item-modal email-item">
              <InputComponent
                placeholder={t('general.modal.lbl.email')}
                length={50}
                onChange={(value: string) => {
                  setToSave((prevState) => ({
                    ...prevState,
                    email: value.trim(),
                  }));
                }}
                title={t('general.modal.lbl.emailOptional')}
                value={toSave.email}
                horizontal={false}
                refElement={inputEmailRef}
                focusAction={() => {
                  verifyCellphoneInUse();
                }}
              ></InputComponent>
            </div>
          </div>
          <div className="row-item-modal third-row">
            <div className="col-item-modal">
              <h2>{t('electoral.lawyers.lbl.lawyerLeader')}</h2>
              <div className="switcher-container">
                <Switch
                  onChange={() => {
                    let state_role =
                      toSave.role === ROLE.LAWYER
                        ? ROLE.LAWYER_BOSS
                        : ROLE.LAWYER;
                    setToSave((prevState) => ({
                      ...prevState,
                      role: state_role,
                    }));
                  }}
                  checked={toSave.role === ROLE.LAWYER ? false : true}
                  height={25}
                  width={50}
                  onColor="#2FAD74"
                  // onHandleColor="#2693E6"
                  handleDiameter={25}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 5px rgba(0, 0, 0, 0.2)"
                  className="react-switch"
                  id="material-switch"
                ></Switch>
              </div>
            </div>
          </div>
        </Container>
      </>
    </StaticModal>
  );
};

export default AddLawyer;
