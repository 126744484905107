import React, { FunctionComponent } from 'react';
import '../style.scss';
import { DeleteBlackIcon, EditIcon } from '../../../../../assets/svg';
import Image from 'react-bootstrap/Image';
import { findFlagUrlByCountryName } from 'country-flags-svg';
import DeletePartyModalComponent from './deleteParty';

interface TopSelectProps {
  party: any;
  onEdit: any;
  onDelete: any;
}

const PartyItem: FunctionComponent<TopSelectProps> = ({
  party,
  onEdit,
  onDelete,
}: any) => {
  // const { t } = useTranslation();
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  return (
    <>
      <div className="party-item" key={party._id}>
        <div className="party-header">
          <Image src={party.logoPath} />
          <div className="names-box">
            <h3>{party.name}</h3>
            <p>{party.abbreviation?.toUpperCase() || ''}</p>
          </div>
        </div>
        <div className="party-country">
          <Image src={findFlagUrlByCountryName(party.countryName)} />
          <h3>{party.countryName}</h3>
          <div className="actions-license-box">
            <DeleteBlackIcon
              onClick={() => {
                setShowDeleteModal(true);
                // onDelete(data);
              }}
            ></DeleteBlackIcon>
            <EditIcon
              onClick={() => {
                onEdit(party);
              }}
            ></EditIcon>
            <div className="empty-box"></div>
          </div>
        </div>
      </div>
      <DeletePartyModalComponent
        setModalShow={setShowDeleteModal}
        modalShow={showDeleteModal}
        toDelete={party}
        successFun={() => {
          onDelete();
        }}
      ></DeletePartyModalComponent>
    </>
  );
};

export default PartyItem;
