import React, { useEffect } from 'react';
//import { useTranslation } from 'react-i18next';
import '../style.scss';
import { useSelector } from 'react-redux';
import LayoutBodyComponent from '../../../../../components/LayoutBody';
import { RiArrowLeftLine } from 'react-icons/ri';
import { SUPER_SHARED_COUNTRIES } from '../../../../../utils/paths';
import { useHistory } from 'react-router-dom';
import { RootStore } from '../../../../../redux/Store';
//import StateItem from './stateItem';
import FeatureEditItem from './featureEditItem';
import { MODE_TYPE } from '../../../../../redux/coreLicense/coreLicenseTypes';

const SelectedList: React.FunctionComponent = () => {
  /*const { t } = useTranslation();

  const defaultPosition = {
    lat: 32.7157431,
    lng: -117.1888535,
    zoom: 11,
  };
  const dispatch = useDispatch();*/
  const redirect = useHistory();

  const countrySelected = useSelector((globalState: RootStore) => {
    return globalState.campaignsManager.selectedCountry;
  });

  const MODE = useSelector((globalState: RootStore) => {
    return globalState.coreLicense.mode;
  });

  const features = useSelector((globalState: RootStore) => {
    return globalState.coreLicense.features;
  });
  useEffect(() => {
    if (features && features.length === 0) {
      redirect.push(SUPER_SHARED_COUNTRIES);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [features]);

  return (
    <>
      <LayoutBodyComponent>
        <div className="body-country license-view selection-view selected-view selected-list">
          <div className="top-divider">
            <div className="column-divider">
              <div className="city-divider">
                <h3>{countrySelected?.country.name + ' >'}</h3>
              </div>
              <div className="license-divider">
                <RiArrowLeftLine
                  onClick={() => {
                    // dispatch({
                    //   type: SET_SELECTED_LICENSE_CITY,
                    //   licenseSelected: undefined,
                    //   cityLicense: undefined,
                    // });
                    redirect.push(SUPER_SHARED_COUNTRIES);
                  }}
                ></RiArrowLeftLine>
                <div className="name-and-party">
                  <h1>
                    {MODE === MODE_TYPE.CITIES ? 'New Cities' : 'New States'}
                  </h1>
                </div>
              </div>
            </div>
            <div className="box-to-next-step"></div>
          </div>
          <div className="list">
            {features?.map((e: any, i: number) => {
              return (
                <FeatureEditItem
                  key={e._id}
                  feature={e}
                  mode={MODE}
                ></FeatureEditItem>
              );
            })}
          </div>
        </div>
      </LayoutBodyComponent>
    </>
  );
};

export default SelectedList;
