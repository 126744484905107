import React, {
  createRef,
  FunctionComponent,
  useEffect,
} from 'react';
import { RouteProps } from 'react-router-dom';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './style.scss';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Dropdown from 'react-bootstrap/Dropdown';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

interface ItemDateProps extends RouteProps {
  text: string;
  activeDates?: any;
  onChange: any;
  value: any;
}

const InputDateComponent: FunctionComponent<ItemDateProps> = ({
  text,
  activeDates,
  onChange,
  value,
}: any) => {
  const [visible, showCalendar] = React.useState(false);
  const { t } = useTranslation();
  // const nextDay = () => {
  //   var date = moment(value).toDate();
  //   date.setDate(date.getDate() + 1);
  //   onChange(date);
  // };

  // const lastDay = () => {
  //   var date = moment(value).toDate();
  //   date.setDate(date.getDate() - 1);
  //   onChange(date);
  // };

  const ref = createRef<HTMLDivElement>();
  const refDropdown = createRef<Dropdown<any>>();

  const [, updateStyle] = React.useState(`
    .dropwwwwwww {
      background-color: #fff;
      border-bottom: 1px solid #eee;
      transform: translate(${0}px, ${ref.current?.clientHeight}px) !important
    }
  `);

  useEffect(() => {
    updateStyle(`
      .dropwwwwwww {
        background-color: #fff;
        border-bottom: 1px solid #eee;
        transform: translate(${0}px, ${ref.current?.clientHeight}px) !important
      }
    `);
  }, [ref]);

  return (
    <div className="item-date-input">
      <div className="date-picker-box">
        <h2>{text}</h2>
        <div ref={ref} className="date-picker">
          <Dropdown ref={refDropdown} as={ButtonGroup}>
            <Dropdown.Toggle split variant="success" id="dropdown-split-basic">
              <div
                className="arrow-box calendar-icon-box"
                onClick={() => {
                  showCalendar(!visible);
                }}
              >
                <p>
                  {moment(value).format('DD-MM-YYYY')}
                </p>
              </div>
            </Dropdown.Toggle>

            <Dropdown.Menu
              className="dropwwwwwww"
              style={{
                transform: `translate(${0}px, ${
                  ref.current?.clientHeight
                }px) !important`,
              }}
            >
              <Calendar
                onChange={(e: any) => {
                  onChange(e);
                  showCalendar(false);
                }}
                locale={t('language')}
                value={value}
                tileClassName={({ date, view }) => {
                  if (
                    activeDates !== undefined &&
                    activeDates.find(
                      (x: any) =>
                        moment(x).utc(false).format('DD-MM-YYYY') ===
                        moment(date).utc(false).format('DD-MM-YYYY'),
                    )
                  ) {
                    return 'highlight';
                  } else return '';
                }}
              />
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default InputDateComponent;
