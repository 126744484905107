import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  validEmailRegex,
  onlyDigits,
  validPhoneRegex,
} from '../../../../utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../../redux/Store';
import StaticModal from '../../../../dialogs/StaticModal';
import InputComponent from '../../../../components/sub-component/input';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SelectorFormsComponent from '../../../../components/sub-component/selectorForms';
import SelectorMultipleComponent from '../../../components/SelectorMultiple';
import { getMunicipality, getStates } from '../../../../redux/core/CoreActions';
import InputDateComponent from '../../../../components/sub-component/inputDate';
import { Municipality, State } from '../../../../redux/core/CoreTypes';
import {
  createLicense,
  updateLicense,
} from '../../../../redux/campaigns/campaignsActions';
import { checkInUse } from '../../../../redux/User/userActions';
import { Party } from '../../../../redux/parties/partiesTypes';
import Modal from 'react-bootstrap/Modal';
import Switch from 'react-switch';
import { MODULES } from '../../../../redux/coreLicense/coreLicenseTypes';
import { capturistUsersList } from '../../../../redux/campaigns/campaignsTypes';
import { Problem } from '../../shared/general/components/problemsView';
import moment from 'moment';

interface AddModalProps {
  modalShow: any;
  setModalShow: any;
  successFun: any;
  toEdit?: any;
  parties: Party[];
}

// tslint:disable-next-line: prefer-const
let defaultArrayState: State[] = [];
// tslint:disable-next-line: prefer-const
let defaultArrayMunicipality: Municipality[] = [];

export const AddCampaignModalComponent: React.FunctionComponent<AddModalProps> = ({
  modalShow,
  setModalShow,
  successFun,
  toEdit,
  parties,
}: AddModalProps) => {
  // const citiesType = {
  //   admins: [],
  //   stateId: '',
  //   defaultZoom: 16,
  //   defaultCenter: [],
  //   modules: [],
  //   name: '',
  //   active: true,
  //   geoLimits: null,
  // };

  const defaultEdit = {
    _id: '',
    partyId: '',
    candidateName: '',
    campaignType: 'GOVERNOR',
    states: defaultArrayState,
    cities: defaultArrayMunicipality,
    name: '',
    endDate: new Date(),
    bossName: '',
    contact: '',
    email: '',
    deleted: false,
    active: true,
    countryId: '',
  };

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [toSave, setToSave] = useState(defaultEdit);
  const [load, setLoad] = useState(false);
  const [toCompare, setToCompare] = useState(defaultEdit);
  const [municipalities, setMunicipalities] = React.useState(
    defaultArrayMunicipality,
  );
  const [submitConfirmation, setSubmitConfirmation] = useState(false);
  const [submitMessage, setSubmitMessage] = useState(String);
  const states = useSelector((globalState: RootStore) => {
    return globalState.core.stateD;
  });
  const municipality = useSelector((globalState: RootStore) => {
    return globalState.core.municipality;
  });
  const countrySelected = useSelector((globalState: RootStore) => {
    return globalState.campaignsManager.selectedCountry;
  });

  const problemList: Problem[] = useSelector((globalState: RootStore) => {
    return globalState.campaignsManager.problemList;
  });

  useEffect(() => {
    if (!load && modalShow) {
      if (toEdit !== undefined) {
        getMuni(toEdit.states[0]._id);
        setToSave({
          ...toEdit,
        });
        setToCompare({
          ...toEdit,
        });
        setMunicipalities(
          toEdit.cities.map((e: any) => {
            return {
              ...e,
              _id: e.municipalityId,
              provisionalId: e._id,
            };
          }),
        );
      } else {
        setToSave({
          ...defaultEdit,
          partyId: parties && parties.length > 0 ? parties[0]._id : '',
        });
        setToCompare({
          ...defaultEdit,
          partyId: parties && parties.length > 0 ? parties[0]._id : '',
        });
      }
      setLoad(true);
    }
    if (!modalShow) {
      setLoad(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [load, modalShow]);

  useEffect(() => {
    if (countrySelected !== undefined) {
      getState(countrySelected.country._id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countrySelected]);

  useEffect(() => {
    if (states !== undefined)
      setToSave((prevState: any) => ({
        ...prevState,
        states: [states[0]],
      }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [states]);

  const getState = (id: string) => {
    dispatch(getStates(id, getMuni));
  };

  const getMuni = (id: string) => {
    dispatch(getMunicipality(id, () => {}));
  };

  const validSubmit = (data: any) => {
    if (toSave.candidateName === '') {
      return (
        (toSave.campaignType === 'GOVERNOR' ? 'Governor' : 'Mayor') +
        ' name is empty'
      );
    } else if (municipalities.length === 0) {
      return 'Cities cannot be empty';
    } else if (toSave.bossName === '') {
      return 'Boss name empty';
    }
    if (
      toSave.contact === '' ||
      !validPhoneRegex.test(toSave.contact) ||
      data === 0
    ) {
      if (data === 0 && toSave.contact === toCompare.contact) {
        return '';
      } else if (data === 0 && toSave.contact !== toCompare.contact) {
        return t('territorial.volunteer.modal.error.cellphoneExist');
      } else if (toSave.contact === '') {
        return t('territorial.volunteer.modal.error.cellphoneComplete');
      } else if (!validPhoneRegex.test(toSave.contact)) {
        return t('territorial.volunteer.modal.error.cellphone');
      } else return '';
    } else if (toSave.email === '' || !validEmailRegex.test(toSave.email)) {
      return t('territorial.volunteer.modal.error.email');
    } else {
      return '';
    }
  };

  const submitFun = () => {
    if (validSubmit(1) !== '') {
      setSubmitMessage(validSubmit(1));
      setSubmitConfirmation(true);
    } else {
      const checkInUseAux: any = {
        cellphone: toSave.contact.toLowerCase().trim(),
      };
      dispatch(
        checkInUse(checkInUseAux, (data: any) => {
          if (validSubmit(data.error) === '') {
            if (toEdit) update();
            else create();
          } else {
            setSubmitMessage(validSubmit(data.error));
            setSubmitConfirmation(true);
          }
        }),
      );
    }
  };

  const create = () => {
    const array: any[] = [];
    let stateId = '';
    let state = null;
    municipalities.forEach((d) => {
      stateId = d.stateId;
      if (toEdit) {
        const found = toEdit.cities.find(
          (e: any) => e.municipalityId === d._id,
        );
        if (!found) {
          array.push({
            admins: [],
            stateId: d.stateId,
            defaultZoom: 15,
            defaultCenter: undefined,
            modules: [
              MODULES.ANALYSIS,
              MODULES.TERRITORIAL,
              MODULES.ELECTORAL,
              MODULES.D_DAY,
            ],
            capturistUserLevels: capturistUsersList,
            problemList,
            active: true,
            name: d.name,
            municipalityId: d._id,
            geoLimits: d.geoCoordinates,
          });
        } else array.push(found);
      } else
        array.push({
          admins: [],
          stateId: d.stateId,
          defaultZoom: 15,
          defaultCenter: undefined,
          modules: [
            MODULES.ANALYSIS,
            MODULES.TERRITORIAL,
            MODULES.ELECTORAL,
            MODULES.D_DAY,
          ],
          capturistUserLevels: capturistUsersList,
          problemList,
          active: true,
          name: d.name,
          municipalityId: d._id,
          geoLimits: d.geoCoordinates,
        });
    });
    state = states?.find((e) => e._id === stateId);
    dispatch(
      createLicense(
        {
          partyId: toSave.partyId,
          candidateName: toSave.candidateName.trim(),
          campaignType: toSave.campaignType,
          states: [state],
          cities: array,
          name: state?.name || '',
          endDate: toSave.endDate,
          bossName: toSave.bossName.trim(),
          contact: toSave.contact.trim(),
          email: toSave.email.toLowerCase().trim(),
          deleted: false,
          active: toSave.active,
          countryId: countrySelected.country._id,
        },
        () => {
          setToSave(defaultEdit);
          successFun();
        },
        error,
      ),
    );
  };

  const update = () => {
    const array: any[] = [];
    let stateId = '';
    let state = null;
    municipalities.forEach((d) => {
      stateId = d.stateId;
      if (toEdit) {
        const found = toEdit.cities.find(
          (e: any) => e.municipalityId === d._id,
        );
        if (!found) {
          array.push({
            admins: [],
            stateId: d.stateId,
            defaultZoom: 15,
            defaultCenter: undefined,
            modules: [
              MODULES.ANALYSIS,
              MODULES.TERRITORIAL,
              MODULES.ELECTORAL,
              MODULES.D_DAY,
            ],
            capturistUserLevels: capturistUsersList,
            problemList,
            active: true,
            name: d.name,
            municipalityId: d._id,
            geoLimits: d.geoCoordinates,
          });
        } else array.push(found);
      } else
        array.push({
          admins: [],
          stateId: d.stateId,
          defaultZoom: 15,
          defaultCenter: undefined,
          modules: [
            MODULES.ANALYSIS,
            MODULES.TERRITORIAL,
            MODULES.ELECTORAL,
            MODULES.D_DAY,
          ],
          capturistUserLevels: capturistUsersList,
          problemList,
          active: true,
          name: d.name,
          municipalityId: d._id,
          geoLimits: d.geoCoordinates,
        });
    });
    state = states?.find((e) => e._id === stateId);
    dispatch(
      updateLicense(
        {
          _id: toEdit._id,
          partyId: toSave.partyId,
          candidateName: toSave.candidateName.trim(),
          campaignType: toSave.campaignType,
          states: [state],
          cities: array,
          name: state?.name || '',
          endDate: toSave.endDate,
          bossName: toSave.bossName.trim(),
          contact: toSave.contact.trim(),
          email: toSave.email.toLowerCase().trim(),
          deleted: false,
          active: toSave.active,
          countryId: countrySelected.country._id,
        },
        () => {
          setToSave(defaultEdit);
          successFun();
        },
        error,
      ),
    );
  };
  const error = () => {
    setSubmitMessage('An Error has ocurred');
    setSubmitConfirmation(true);
  };

  const cancelFun = () => {
    setModalShow(false);
  };

  const setMunicipalitiesSelected = (muni: any) => {
    let inArray = false;
    const newArray: any[] = [];
    municipalities.forEach((e: any) => {
      if (e._id !== muni._id) {
        newArray.push(e);
      } else {
        inArray = true;
      }
    });
    if (!inArray) {
      newArray.push(muni);
    }
    setMunicipalities(newArray);
  };

  return (
    <StaticModal
      show={modalShow}
      title={'Add Campaign'}
      closeText={'Cancel'}
      confirmText={'Save'}
      onHide={cancelFun}
      submitFun={submitFun}
      validBtn={false}
      size="md"
      sizeClass="add-user-modal add-campaing add-campaign-modal"
      customTitle={
        <div className="header-modal-add-admin">
          <Modal.Title className="title-border-bottom">
            <strong>Add Campaign</strong>
          </Modal.Title>
          <Switch
            onChange={() => {
              setToSave((prevState: any) => ({
                ...prevState,
                active: !prevState.active,
              }));
            }}
            checked={toSave.active}
            height={20}
            width={40}
            onColor="#2fad74"
            // onHandleColor="#2693e6"
            handleDiameter={20}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 5px rgba(0, 0, 0, 0.2)"
            className="react-switch"
            id="material-switch"
          ></Switch>
        </div>
      }
    >
      <Row noGutters className="addModal-content modal-text-row ">
        {submitConfirmation && (
          <Col xs={12} md={12} className="col-item-message">
            <p className="">{submitMessage}</p>
          </Col>
        )}
        <Col xs={6} md={6} className="col-item-modal col-padding-right">
          <SelectorFormsComponent
            options={parties}
            title={'Party'}
            defaultSelector={toSave.partyId}
            horizontal={false}
            fun={(data: any) => {
              setToSave((prevState: any) => ({
                ...prevState,
                partyId: data,
              }));
            }}
          ></SelectorFormsComponent>
        </Col>
        <Col
          xs={6}
          md={6}
          className="col-item-modal license-type-box-modal col-padding-left"
        >
          <h2>License Type</h2>
          <div className="options">
            <div
              className={
                toSave.campaignType === 'GOVERNOR'
                  ? 'item-type active'
                  : 'item-type'
              }
              onClick={() => {
                setToSave((prevState: any) => ({
                  ...prevState,
                  campaignType: 'GOVERNOR',
                }));
              }}
            >
              <p>Governor</p>
            </div>
            <div
              className={
                toSave.campaignType === 'MAYOR'
                  ? 'item-type active'
                  : 'item-type'
              }
              onClick={() => {
                setToSave((prevState: any) => ({
                  ...prevState,
                  campaignType: 'MAYOR',
                }));
              }}
            >
              <p>Mayor</p>
            </div>
          </div>
        </Col>
        <Col xs={6} md={6} className="col-item-modal col-padding-right">
          <SelectorFormsComponent
            options={states}
            title={'State'}
            defaultSelector={
              (toSave.states !== undefined &&
              toSave.states.length > 0 &&
              toSave.states[0]?._id !== undefined
                ? toSave.states[0]._id
                : '') || ''
            }
            horizontal={false}
            fun={(value: string) => {
              setMunicipalities([]);
              setToSave((prevState: any) => ({
                ...prevState,
                states: [
                  states?.find((e) => {
                    return e._id === value;
                  }),
                ],
              }));
              getMuni(value);
            }}
          ></SelectorFormsComponent>
        </Col>
        <Col xs={6} md={6} className="col-item-modal col-padding-left">
          <InputComponent
            placeholder={'Daniel Ibañez Perez'}
            length={50}
            onChange={(value: string) => {
              let candidateName = '';
              candidateName = value;
              setToSave((prevState: any) => ({
                ...prevState,
                candidateName,
              }));
            }}
            title={toSave.campaignType === 'GOVERNOR' ? 'Governor' : 'Mayor'}
            value={toSave.candidateName}
            horizontal={false}
          ></InputComponent>
        </Col>
        <Col xs={12} md={12} className="col-item-modal">
          <SelectorMultipleComponent
            title="Cities"
            horizontal={false}
            filters={municipality}
            filtersSelected={municipalities}
            fun={(value: any) => {
              // getVoBo(value);
              setMunicipalitiesSelected(value);
            }}
            t={t}
          ></SelectorMultipleComponent>
        </Col>
        <Col xs={6} md={6} className="col-item-modal col-padding-right">
          <InputComponent
            placeholder={'Simon Vargas Mireles'}
            length={50}
            onChange={(value: string) => {
              let bossName = '';
              bossName = value;

              setToSave((prevState: any) => ({
                ...prevState,
                bossName,
              }));
            }}
            title={'Campaign Manager'}
            value={toSave.bossName}
            horizontal={false}
          ></InputComponent>
        </Col>
        <Col xs={6} md={6} className="col-item-modal col-padding-left">
          <InputComponent
            placeholder={'test@gmail.com'}
            length={50}
            onChange={(value: string) => {
              let email = '';
              email = value;

              setToSave((prevState: any) => ({
                ...prevState,
                email,
              }));
            }}
            title={'Email'}
            value={toSave.email}
            horizontal={false}
          ></InputComponent>
        </Col>
        <Col xs={6} md={6} className="col-item-modal col-padding-right">
          <InputComponent
            placeholder={'8119927772'}
            length={10}
            onChange={(value: string) => {
              let contact = '';
              contact = value;

              setToSave((prevState: any) => ({
                ...prevState,
                contact,
              }));
            }}
            title={'Cellphone'}
            value={toSave.contact}
            regExp={onlyDigits}
            horizontal={false}
          ></InputComponent>
        </Col>
        <Col xs={6} md={6} className="col-item-modal col-padding-left">
          <InputDateComponent
            text={'Duration'}
            onChange={(endDate: Date) => {
              setToSave((prevState: any) => ({
                ...prevState,
                endDate,
              }));
            }}
            value={moment(toSave.endDate).toDate()}
          ></InputDateComponent>
        </Col>
      </Row>
    </StaticModal>
  );
};

export default AddCampaignModalComponent;
