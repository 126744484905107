import {
  RGRC_LOADING,
  RGRC_FAIL,
  RGRC_SUCCESS,
  RGRC_DATA_SUCCESS,
  RGRC_COUNT_SUCCESS,
  RGRC_LEFT_DATA_SUCCES,
  RGRC_RG_SUCCESS_ONE,
  DeleteRGRCTeam,
  SearchRCsTeamsByCity,
  SearchTeamData,
  SearchLeftData,
} from './RgRcTypes';
import * as netWorking from '../netWorking';

export const postCreateUserTeam = (data: any, successFun: any) => {
  return async (dispatch: any) => {
    dispatch({
      type: RGRC_LOADING,
    });
    return await netWorking.post(
      netWorking.createUsers,
      data,
      (res: any) => {
        successFun(res);
      },
      (error: any) => {
        return dispatch({
          type: RGRC_FAIL,
        });
      },
    );
  };
};

export const putUpdateUserTeam = (data: any, successFun: any) => {
  return async (dispatch: any) => {
    dispatch({
      type: RGRC_LOADING,
    });
    return await netWorking.put(
      netWorking.updateCreateUsers,
      data,
      (res: any) => {
        successFun();
      },
      (error: any) => {
        return dispatch({
          type: RGRC_FAIL,
        });
      },
    );
  };
};

export const postDeleteRGRCTeam = (
  data: DeleteRGRCTeam,
  successFun: any,
) => {
  return async (dispatch: any) => {
    dispatch({
      type: RGRC_LOADING,
    });
    return await netWorking.post(
      netWorking.deleteCapturistTeam,
      data,
      (res: any) => {
        successFun();
      },
      (error: any) => {
        return dispatch({
          type: RGRC_FAIL,
        });
      },
    );
  };
};

export const getCoordinatorViewByIdRGRC = (_id: string, followValue: any) => {
  return async (dispatch: any) => {
    dispatch({
      type: RGRC_LOADING,
    });
    return await netWorking.get(
      netWorking.getCoordinatorViewById + _id,
      (res: any) => {
        return dispatch({
          type: RGRC_RG_SUCCESS_ONE,
          payload: res.data.response,
          follow: followValue,
        });
      },
      (error: any) => {
        return dispatch({
          type: RGRC_FAIL,
        });
      },
    );
  };
};

export const postListTeamMembersLeftSide = (data: SearchLeftData) => {
  return async (dispatch: any) => {
    return await netWorking.post(
      netWorking.postListTeamMembersLeftSide,
      data,
      (res: any) => {
        return dispatch({
          type: RGRC_LEFT_DATA_SUCCES,
          payload: res.data.response,
        });
      },
      (error: any) => {
        return dispatch({
          type: RGRC_FAIL,
        });
      },
    );
  };
};


export const postListRCsTeamsByCity = (data: SearchRCsTeamsByCity) => {
  return async (dispatch: any) => {
    return await netWorking.post(
      netWorking.postByCityRCsTeams,
      data,
      (res: any) => {
        return dispatch({
          type: RGRC_SUCCESS,
          payload: res.data.response,
        });
      },
      (error: any) => {
        return dispatch({
          type: RGRC_FAIL,
        });
      },
    );
  };
};

export const postGetRCsTeamDataTotal = (data: SearchTeamData) => {
  return async (dispatch: any) => {
    return await netWorking.post(
      netWorking.getTotalRCsTeamData,
      data,
      (res: any) => {
        return dispatch({
          type: RGRC_COUNT_SUCCESS,
          payload: res.data.response,
        });
      },
      (error: any) => {
        return dispatch({
          type: RGRC_FAIL,
        });
      },
    );
  };
};

export const postRCsTeamDataByRG = (data: SearchTeamData) => {
  return async (dispatch: any) => {
    dispatch({
      type: RGRC_LOADING,
    });
    return await netWorking.post(
      netWorking.postByRGRCsTeamData,
      data,
      (res: any) => {
        return dispatch({
          type: RGRC_DATA_SUCCESS,
          payload: res.data.response,
        });
      },
      (error: any) => {
        return dispatch({
          type: RGRC_FAIL,
        });
      },
    );
  };
};
