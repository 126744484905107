import { UserDispatchTypes } from "../User/userTypes";
import { SET_ERRORS, LOADING_UI, CLEAR_ERRORS } from "../types";

export interface UIState {
  loading: boolean;
  errors?: null;
}

export const defaultState: UIState = {
  loading: false,
};

export const uiReducer = (
  state: UIState = defaultState,
  action: UserDispatchTypes
): UIState => {
  switch (action.type) {
    case SET_ERRORS:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        errors: null,
      };
    case LOADING_UI:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};
