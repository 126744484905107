import React, { useEffect, useState, useRef } from 'react';
import * as netWorking from '../../../redux/netWorking';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../redux/Store';
import { useTranslation } from 'react-i18next';

import {
  Municipality,
  District,
  Section,
  citiesLogin,
} from '../../../redux/core/CoreTypes';
import { onlyDigits, GoogleAPIKEY } from '../../../utils/utils';
import { Address, ADDRESS_TYPE } from '../../../redux/votersBox/VotersBoxTypes';
import { checkPollingInUse } from '../../../redux/votersBox/VotersBoxActions';
import { VotersBoxMapIcon } from '../../../assets/svg/index';
import GoogleMapReact from 'google-map-react';

import SelectorFormsComponent from '../../../components/sub-component/selectorForms';
import InputComponent from '../../../components/sub-component/input';
import StaticModal from '../../../dialogs/StaticModal';
import Container from 'react-bootstrap/Container';
import SelectorTextField from './selectorTextField';

interface ModalVotersProps {
  modalShow: any;
  setModalShow: any;
  successFun: any;
  edit?: any;
}

export const ModalAddVotersBox: React.FunctionComponent<ModalVotersProps> = ({
  modalShow,
  setModalShow,
  successFun,
  edit,
}: any) => {
  const citySelected: any = useSelector((globalState: RootStore) => {
    return globalState.core.globalMunicipalitySelected;
  });

  const stateSelected = useSelector((globalState: RootStore) => {
    return globalState.core.globalStateSelected;
  });

  const defaultAddress: Address = {
    _id: '',
    street: '',
    addressType: ADDRESS_TYPE.VOTERS_BOX,
    interiorNumber: '',
    exteriorNumber: '',
    postalCode: '',
    suburb: '',
    geoLocation: {
      type: 'Point',
      coordinates: [
        citySelected?.defaultCenter
          ? citySelected?.defaultCenter.lng
          : -117.1888535,
        citySelected?.defaultCenter
          ? citySelected?.defaultCenter.lat
          : 32.7157431,
      ],
    },
    sectionId: '',
    districtId: '',
    municipalityId: '',
  };

  const defaultEdit = {
    _id: '',
    number: '',
    address: defaultAddress,
    winningNumber: '',
    district: '',
    section: '',
    timezone: '',
  };

  const [toSave, setToSave] = useState(defaultEdit);
  const [toCompare, setToCompare] = useState(defaultEdit);

  const [submitMessage, setSubmitMessage] = useState(String);
  const [submitConfirmation, setSubmitConfirmation] = useState(false);

  const [loadData, setLoadData] = useState(false);

  const arrayDistrict: District[] = [];
  const arraySection: Section[] = [];
  const arrayTimeZone: any = [];

  const [district, setDistrict] = useState(arrayDistrict);
  const [section, setSection] = useState(arraySection);
  const [timezone, setTimezone] = useState(arrayTimeZone);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const inputPollingStationRef: any = useRef(null);
  const inputDistrictRef: any = useRef(null);
  const inputSectionRef: any = useRef(null);
  const inputStreetRef: any = useRef(null);
  const inputExtNumberRef: any = useRef(null);
  const inputGoalRef: any = useRef(null);

  console.log('toSave', toSave);

  const getMunicipality = (first?: boolean) => {
    if (citySelected?._id) {
      getDistrict(citySelected?._id, first);

      if ((first && edit === undefined) || !first)
        setToSave((prevState) => ({
          ...prevState,
          address: {
            ...prevState.address,
            municipalityId: citySelected?.municipalityId,
          },
        }));
    } else {
      setToSave((prevState) => ({
        ...prevState,
        section: '',
        district: '',
        address: {
          ...prevState.address,
          municipalityId: '',
        },
      }));
    }
  };

  const getDistrict = (id: string, first?: boolean) => {
    netWorking.get(
      netWorking.getDistrict + id,
      (res: any) => {
        setDistrict(res.data.response);
        setSection(arraySection);

        if (res.data.response.length > 0) {
          if (edit !== undefined) {
            let districtList = [...res.data.response];
            let searchDistrict = districtList?.find(
              (d: any) => d.name === edit.district,
            );
            getSection(searchDistrict ? searchDistrict._id : '', first);
          } else getSection(res.data.response[0]._id, first);

          if (first && edit === undefined)
            setToSave((prevState) => ({
              ...prevState,
              district: res.data.response[0].name,
            }));
        } else {
          setToSave((prevState) => ({
            ...prevState,
            section: '',
            district: '',
          }));
        }
      },
      (error: any) => {
        setDistrict(arrayDistrict);
        setSection(arraySection);
        setToSave((prevState) => ({
          ...prevState,
          section: '',
          district: '',
        }));
      },
    );
  };

  const getSection = (id: string, first?: boolean) => {
    netWorking.get(
      netWorking.getSection + id,
      (res: any) => {
        setSection(res.data.response);

        if (res.data.response.length > 0) {
          if ((first && edit === undefined) || (!first && loadData))
            setToSave((prevState) => ({
              ...prevState,
              section: res.data.response[0].name,
            }));
        } else {
          setToSave((prevState) => ({
            ...prevState,
            section: '',
          }));
        }
      },
      (error: any) => {
        setSection(arraySection);
        setToSave((prevState) => ({
          ...prevState,
          section: '',
        }));
      },
    );
  };

  const submitFun = (validSubmit: any) => {
    if (validSubmit) {
      setSubmitMessage(validSubmit);
      setSubmitConfirmation(true);
    } else {
      if (toSave._id !== '') {
        netWorking.put(
          netWorking.getVotersBoxByPage,
          { ...toSave, cityLicenseId: citySelected?._id },
          (res: any) => {
            successFun();
            setModalShow(false);
          },
          (err: any) => {},
        );
      } else {
        netWorking.post(
          netWorking.getVotersBoxByPage,
          { ...toSave, cityLicenseId: citySelected?._id },
          (res: any) => {
            successFun();
            setModalShow(false);
          },
          (err: any) => {},
        );
      }
    }
  };

  const verifyNumberInUse = () => {
    let checkInUseAux: any = {
      number: parseInt(toSave.number),
      section: toSave.section,
      municipalityId: toSave.address.municipalityId,
    };

    if (toSave.number === '' || toSave.number === undefined) {
      validSubmit(1);
    } else {
      dispatch(
        checkPollingInUse(checkInUseAux, (data: any) => {
          validSubmit(data.error);
        }),
      );
    }
  };

  const validSubmit = (data: any) => {
    let newStringValue = verifyFields(data);
    submitFun(newStringValue);
  };

  const verifyFields = (data: any) => {
    if (toSave.number === '' || data === 0) {
      if (data === 0 && toSave.number === toCompare.number) {
        return '';
      } else if (data === 0 && toSave.number !== toCompare.number) {
        return t('settings.polling.modal.error.numberExist');
      } else if (toSave.number === '') {
        return t('settings.polling.modal.error.number');
      }
    } else if (toSave.district === '') {
      return t('settings.polling.modal.error.district');
    } else if (toSave.section === '') {
      return t('settings.polling.modal.error.section');
    } else if (toSave.timezone === '') {
      return t('settings.polling.modal.error.timezone');
    } else if (toSave.address.street === '') {
      return t('settings.polling.modal.error.street');
    } else if (toSave.address.exteriorNumber === '') {
      return t('settings.polling.modal.error.exteriorNumber');
    } else if (toSave.winningNumber === '') {
      return t('settings.polling.modal.error.goal');
    } else {
      return '';
    }
  };

  useEffect(() => {
    if (!loadData) {
      if (edit !== undefined) {
        setToSave((prevState) => ({
          ...prevState,
          ...edit,
        }));
        setToCompare((prevState) => ({
          ...prevState,
          ...edit,
        }));
      }
      if (edit !== undefined) {
        getMunicipality(false);
      } else {
        getMunicipality(true);
      }
      setLoadData(true);
      inputPollingStationRef.current.focus();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadData]);

  useEffect(() => {
    if (citySelected) {
      let timeArray = [...timezone];
      citySelected?.timeZones.map((t: any) => {
        timeArray.push({
          _id: t._id,
          name: t._id,
        });
      });
      timeArray.unshift({ _id: '', name: t('settings.trainings.form.select') });
      setTimezone(timeArray);
    }
  }, [citySelected]);

  return (
    <StaticModal
      show={modalShow}
      title={
        toSave._id !== ''
          ? t('settings.polling.modal.title.edit')
          : t('settings.polling.modal.title.new')
      }
      closeText={t('submit.cancel')}
      confirmText={t('submit.save')}
      onHide={() => {
        setModalShow(false);
      }}
      submitFun={verifyNumberInUse}
      validBtn={false}
      size="xxl"
      sizeClass="md modal-rc"
    >
      <Container className="dialog-add-voterbox">
        {submitConfirmation && (
          <div className="col-item-message">
            <p>{submitMessage}</p>
          </div>
        )}
        <div className="row-item-modal first-row">
          <div className="col-item-less-modal">
            <InputComponent
              placeholder={t('settings.polling.label.pollingStation')}
              onChange={(value: string) => {
                setToSave((prevState) => ({
                  ...prevState,
                  number: value,
                }));
              }}
              title={t('settings.polling.label.pollingStation')}
              value={toSave.number}
              regExp={onlyDigits}
              length={6}
              refElement={inputPollingStationRef}
              focusAction={() => {
                inputDistrictRef.current.focus();
              }}
            ></InputComponent>
          </div>
          {/*<div className="col-item-modal">
            <SelectorFormsComponent
              title={t('general.form.district')}
              defaultSelector={toSave.address.districtId}
              options={district}
              horizontal={false}
              fun={(value: string) => {
                setToSave((prevState) => ({
                  ...prevState,
                  address: {
                    ...prevState.address,
                    districtId: value,
                  },
                }));
                getSection(value);
              }}
            ></SelectorFormsComponent>
          </div>*/}
          <div className="col-item-less-modal">
            <SelectorTextField
              title={t('general.form.district')}
              defaultValue={toSave.district}
              options={district.length > 1 ? district : []}
              fun={(value: string) => {
                setToSave((prevState) => ({
                  ...prevState,
                  district: value,
                }));
                let searchDistrict = district?.find(
                  (d: any) => d.name === value,
                );
                getSection(searchDistrict ? searchDistrict._id : '');
              }}
              regExp={onlyDigits}
              placeholder={t('general.form.district')}
              refElement={inputDistrictRef}
              focusAction={() => {
                inputSectionRef.current.focus();
              }}
            ></SelectorTextField>
          </div>
          <div className="col-item-less-modal">
            <SelectorTextField
              title={t('general.form.section')}
              defaultValue={toSave.section}
              options={section.length > 1 ? section : []}
              fun={(value: string) => {
                setToSave((prevState) => ({
                  ...prevState,
                  section: value,
                }));
              }}
              regExp={onlyDigits}
              placeholder={t('general.form.section')}
              refElement={inputSectionRef}
              focusAction={() => {
                inputStreetRef.current.focus();
              }}
            ></SelectorTextField>
          </div>
          <div className="col-item-less-modal">
            <SelectorFormsComponent
              title={t('general.form.timezone')}
              defaultSelector={toSave.timezone}
              options={timezone}
              horizontal={false}
              fun={(value: string) => {
                setToSave((prevState) => ({
                  ...prevState,
                  timezone: value,
                }));
              }}
            ></SelectorFormsComponent>
          </div>
        </div>
        <div className="row-item-modal second-row">
          <div className="col-item-modal">
            <InputComponent
              placeholder={t('general.form.street')}
              onChange={(value: string) => {
                setToSave((prevState) => ({
                  ...prevState,
                  address: {
                    ...prevState.address,
                    street: value,
                  },
                }));
              }}
              title={t('general.form.street')}
              length={150}
              value={toSave.address.street}
              refElement={inputStreetRef}
              focusAction={() => {
                inputExtNumberRef.current.focus();
              }}
            ></InputComponent>
          </div>
          <div className="col-item-less-modal">
            <InputComponent
              placeholder={t('general.form.exterior.n')}
              onChange={(value: string) => {
                setToSave((prevState) => ({
                  ...prevState,
                  address: {
                    ...prevState.address,
                    exteriorNumber: value,
                  },
                }));
              }}
              title={t('general.form.exterior.n')}
              length={10}
              regExp={onlyDigits}
              value={toSave.address.exteriorNumber}
              refElement={inputExtNumberRef}
              focusAction={() => {
                inputGoalRef.current.focus();
              }}
            ></InputComponent>
          </div>
          <div className="col-item-less-modal">
            <InputComponent
              placeholder={t('settings.polling.modal.form.num.toWin')}
              onChange={(value: string) => {
                setToSave((prevState) => ({
                  ...prevState,
                  winningNumber: value,
                }));
              }}
              title={t('settings.polling.modal.form.num.toWin')}
              value={toSave.winningNumber}
              regExp={onlyDigits}
              length={6}
              refElement={inputGoalRef}
              focusAction={() => {
                verifyNumberInUse();
              }}
            ></InputComponent>
          </div>
        </div>
        <div className="row-item-modal third-row">
          <div className="map-col">
            <h2>{t('settings.polling.modal.form.select.location')}</h2>
            <div id="map">
              <GoogleMapReact
                yesIWantToUseGoogleMapApiInternals
                shouldUnregisterMapOnUnmount={true}
                style={{
                  width: '100%',
                  height: '100%',
                }}
                bootstrapURLKeys={{
                  key: GoogleAPIKEY,
                }}
                defaultZoom={citySelected?.defaultZoom}
                center={{
                  lng: parseFloat(toSave.address.geoLocation.coordinates[0]),
                  lat: parseFloat(toSave.address.geoLocation.coordinates[1]),
                }}
                options={{ clickableIcons: false }}
                onDragEnd={(map) => {
                  setToSave((prevState) => ({
                    ...prevState,
                    address: {
                      ...prevState.address,
                      geoLocation: {
                        type: 'Point',
                        coordinates: [map.center.lng(), map.center.lat()],
                      },
                    },
                  }));
                }}
              ></GoogleMapReact>
            </div>
            <div className="marker-layer">
              <VotersBoxMapIcon></VotersBoxMapIcon>
            </div>
          </div>
        </div>
      </Container>
    </StaticModal>
  );
};

export default ModalAddVotersBox;
