import React, { useState, createRef, useEffect } from 'react';

import { IoMdArrowDropdown } from 'react-icons/io';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';

interface SelectProps {
  title: any;
  length?: number;
  defaultValue: string;
  options?: any;
  fun: any;
  regExp?: any;
  placeholder?: string;
  refElement?: any;
  focusAction?: any;
}

const SelectorTextFieldComponent: React.FunctionComponent<SelectProps> = ({
  title,
  length = 120,
  defaultValue = '',
  options = [],
  fun,
  regExp,
  placeholder = '',
  refElement,
  focusAction,
}: any) => {
  const [optionsList, setOptionsList] = useState(options);
  const [defaultValueOption, setDefaultValue] = useState();

  const onChangeOption = (e: any) => {
    setDefaultValue(e);
  };

  let refBody = createRef<HTMLDivElement>();
  const [style, updateStyle] = useState(`
        .dropwwwwwww {
            background-color: #fff;
            transform: translate(${40}px, ${
    refBody.current?.clientHeight
  }px) !important
        }
    `);

  useEffect(() => {
    updateStyle(`
        .dropwwwwwww {
            background-color: #fff;
            transform: translate(${40}px, ${
      refBody.current?.clientHeight
    }px) !important
        }
    `);
  }, [refBody]);

  useEffect(() => {
    setDefaultValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    setOptionsList(
      options?.filter((e: any) => {
        return e.name !== defaultValueOption;
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValueOption, options]);

  return (
    <div>
      {optionsList.length > 0 && (
        <>
          <h2>{title}</h2>

          <div className="tags-items-box-input">
            <Dropdown as={ButtonGroup}>
              <style>{style}</style>
              <div className="tags-items-box">
                <Form.Control
                  className="input-box"
                  value={defaultValueOption || ''}
                  onChange={(data: any) => {
                    if (
                      regExp === undefined ||
                      regExp.test(data.target.value.trim()) ||
                      data.target.value === ''
                    ) {
                      onChangeOption(data.target.value);
                      fun(data.target.value);
                    }
                  }}
                  type="text"
                  maxLength={length}
                  placeholder={placeholder}
                  ref={refElement}
                  onKeyPress={(data: any) => {
                    if (data.key === 'Enter') {
                      if (focusAction) {
                        focusAction();
                      }
                    }
                  }}
                ></Form.Control>
              </div>

              <Dropdown.Toggle
                split
                variant="success"
                id="dropdown-split-basic"
              >
                <IoMdArrowDropdown></IoMdArrowDropdown>
              </Dropdown.Toggle>

              <Dropdown.Menu
                className="dropwwwwwww dropdown-less-menu"
                style={{
                  transform: `translate(40px, ${refBody.current?.clientHeight}px) !important`,
                }}
              >
                {optionsList.map((e: any, index: any) => {
                  return (
                    <Dropdown.Item
                      key={e._id + '-' + index}
                      onClick={() => {
                        onChangeOption(e.name);
                        fun(e.name);
                      }}
                      className=""
                    >
                      <p> {e.name} </p>
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </>
      )}
      {optionsList.length < 1 && (
        <>
          <h2>{title}</h2>

          <div className="tags-items-box-input">
            <Form.Control
              className="input-box"
              value={defaultValueOption || ''}
              onChange={(data: any) => {
                if (
                  regExp === undefined ||
                  regExp.test(data.target.value.trim()) ||
                  data.target.value === ''
                ) {
                  onChangeOption(data.target.value);
                  fun(data.target.value);
                }
              }}
              type="text"
              maxLength={length}
              placeholder={placeholder}
              ref={refElement}
              onKeyPress={(data: any) => {
                if (data.key === 'Enter') {
                  if (focusAction) {
                    focusAction();
                  }
                }
              }}
            ></Form.Control>
          </div>
        </>
      )}
    </div>
  );
};

export default SelectorTextFieldComponent;
