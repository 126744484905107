import React from 'react';
import PorcentageComponent from './progressBar';
import { IoMdArrowDropdown } from 'react-icons/io';
import { useTranslation } from 'react-i18next';

interface AreaProgressProps {
  area: any;
  index: any;
  getAuditedFailCountByArea: any;
  getWithProblemCountByArea: any;
  getReviewCountByArea: any;
}

const AreaProgressComponent: React.FunctionComponent<AreaProgressProps> = ({
  area,
  index,
  getAuditedFailCountByArea,
  getWithProblemCountByArea,
  getReviewCountByArea,
}: any) => {
  const { t } = useTranslation();
  const [accordionState, setAccordionState] = React.useState(true);
  const [hoverState, setHoverState] = React.useState(String);

  const style = `.bar-${index} .progress-bar {
        background-color: ${area.colorBase} !important;
    }
      .box-${index}{
        background-color: ${area.colorBase} !important;
    }`;
//   const classN = `box-progress bar-${index}`;
  const classBox = `box-area-square box-${index}`;

  return (
    <div key={index} className="percentage-box">
      <style>{style}</style>
      <div className="data-box">
        <div
          className={accordionState ? 'general-boxes active' : 'general-boxes'}
        >
          <div className="principal-boxes">
            <div className="box-area">
              <div className="title-area">
                <div className={classBox}></div>
                <h2>
                  {t('territorial.tracker.lbl.area') + ' '}
                  {index + 1}
                </h2>
              </div>
              <div
                className="accordion-arrow"
                onClick={() => {
                  setAccordionState(!accordionState);
                }}
              >
                <IoMdArrowDropdown></IoMdArrowDropdown>
              </div>
            </div>
            <div className="box-percentage">
              {/*<div className="box-percentage-value">
                                {(e.dataOfArea[0].captured * 100) /
                                e.dataOfArea[0].total || 0}
                                %
                            </div>*/}
              <div className="box-labels-quantities">
                <p>
                  {t('territorial.tracker.slider.lbl.captured')}{' '}
                  <strong>
                    {area.dataOfArea ? area.dataOfArea[0].toAudit : '-'}
                  </strong>
                </p>
                <p>
                  {t('territorial.tracker.slider.lbl.assigned')}{' '}
                  <strong>
                    {area.dataOfArea ? area.dataOfArea[0].total : '-'}
                  </strong>
                </p>
              </div>
              <PorcentageComponent
                total={area.dataOfArea ? area.dataOfArea[0].total : 0}
                captured={area.dataOfArea ? area.dataOfArea[0].toAudit : 0}
                inReview={getAuditedFailCountByArea(area.area._id)}
                withProblems={getWithProblemCountByArea(area.area._id)}
                rejected={getReviewCountByArea(area.area._id)}
                hoverState={hoverState}
              ></PorcentageComponent>
            </div>
          </div>
        </div>
        <div
          className={accordionState ? 'totals-boxes active' : 'totals-boxes'}
        >
          <div
            className="total-box"
            onMouseOver={() => {
              setHoverState('revision');
            }}
            onMouseLeave={() => {
              setHoverState('');
            }}
          >
            <p className="title total-box-revision">
              {t('territorial.tracker.slider.lbl.revision')}
            </p>
            <p className="content">
              {getAuditedFailCountByArea(area.area._id)}
            </p>
          </div>
          <div
            className="total-box"
            onMouseOver={() => {
              setHoverState('failed');
            }}
            onMouseLeave={() => {
              setHoverState('');
            }}
          >
            <p className="title total-box-failed">
              {t('territorial.tracker.slider.lbl.failed')}
            </p>
            <p className="content">
              {getWithProblemCountByArea(area.area._id)}
            </p>
          </div>
          <div
            className="total-box"
            onMouseOver={() => {
              setHoverState('rejected');
            }}
            onMouseLeave={() => {
              setHoverState('');
            }}
          >
            <p className="title total-box-rejected">
              {t('territorial.tracker.slider.lbl.rejected')}
            </p>
            <p className="content">{getReviewCountByArea(area.area._id)}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AreaProgressComponent;
