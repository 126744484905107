import React, { FunctionComponent } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../style.scss';
import StaticModal from '../../../dialogs/StaticModal';

interface ModalConfirmFinalizeProps {
  modalShow: boolean;
  setModalShow: any;
  successFun: any;
  type: number;
}

export const ModalConfirmFinalize: FunctionComponent<ModalConfirmFinalizeProps> = ({
  modalShow,
  setModalShow,
  successFun,
  type,
}: any) => {
  const submitFun = () => {
    setModalShow(false);
    successFun();
  };

  return (
    <StaticModal
      show={modalShow}
      title={'Finalizar Revision'}
      closeText={'No'}
      confirmText={'Si'}
      onHide={() => setModalShow(false)}
      submitFun={submitFun}
      validBtn={false}
      size="lg"
      sizeClass="md modal-rc"
    >
      <>
        {type === 0 ? (
          <Container className="body-modal">
            <Row noGutters className="modal-text-row">
              <Col xs={12} className="description-col">
                Si continuas los votantes dentro de esta lista remplazaran a las
                votantes de base de datos
              </Col>
              <Col xs={12} className="question-col">
                <strong>¿Deseas Continuar?</strong>
              </Col>
            </Row>
          </Container>
        ) : (
          <Container className="body-modal">
            <Row noGutters className="modal-text-row">
              <Col xs={12} className="description-col">
                Si continuas los votantes dentro de esta lista sera ignorados
              </Col>
              <Col xs={12} className="question-col">
                <strong>¿Deseas Continuar?</strong>
              </Col>
            </Row>
          </Container>
        )}
      </>
    </StaticModal>
  );
};

export default ModalConfirmFinalize;
