import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../redux/Store';

import {
  postGetAreasByUsers,
  postSearchCoordinatesData,
  putListRoutes,
} from '../../../redux/routes/RoutesActions';
import { changeSelectedCoordinator } from '../../../redux/areas/areasActions';
import { onlyDigits, ROLE } from '../../../utils/utils';

import { HamburgerIcon } from '../../../assets/svg';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import SelectorFormsComponent from '../../../components/sub-component/selectorForms';
import ListFilterCustomComponent from '../../../components/ListFilterCustom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import Switch from 'react-switch';

interface AreaDataBoxProps {
  selected: any;
  setSelected: any;
  layersGeoJson: any;
  setLayersGeoJson: any;
  goUpList: any;
  goDownList: any;
  changeRouteState: any;
  getBounds: any;
  onHide: any;
  t: any;
}
// tslint:disable-next-line: prefer-const
let emptyArray: string[] = [];
// tslint:disable-next-line: prefer-const
let emptyArray2: string[] = [];

const AreaDataBox: React.FunctionComponent<AreaDataBoxProps> = ({
  selected,
  setSelected,
  layersGeoJson,
  setLayersGeoJson,
  goUpList,
  goDownList,
  changeRouteState,
  getBounds,
  onHide,
  t,
}: any) => {
  const citySelected: any = useSelector((globalState: RootStore) => {
    return globalState.core.globalMunicipalitySelected;
  });

  const [counter, setCounter] = React.useState(1);
  const [toggle, setToggle] = React.useState(false);

  //console.log('layersGeoJson', layersGeoJson);

  React.useEffect(() => {
    if (toggle) {
      if (counter > 0) setTimeout(() => setCounter(counter - 1), 1000);
      if (counter === 0) {
        setToggle(false);
        update();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter, toggle]);

  const handleFocus = (event: any) => {
    event.target.select();
  };

  const userData: any = useSelector(
    (globalState: RootStore) => globalState.core.userData,
  );

  const byId = userData._id;
  const titleLv2 =
    userData?.role === ROLE.ADMIN_BOSS || userData?.role === ROLE.SUPER_ADMIN
      ? citySelected.capturistUserLevels[0][t('language')]
      : citySelected.capturistUserLevels.find(
          (c: any) => c.level === parseInt(localStorage?.level) - 1,
        )[t('language')];

  const [dataArea, setDataArea] = React.useState({
    audited: 0,
    toAudit: 0,
    toCapture: 0,
    total: 0,
  });

  const [area, setToArea] = React.useState({
    _id: '',
    name: '',
    extraGoal: 0,
    limit: 1,
    limitType: 0,
    enable: true,
    createdBy: byId,
    datesWorked: emptyArray2,
    members: emptyArray,
    coordinates: selected?.feature.geometry.coordinates,
    originalCoordinates: selected?.feature.geometry.coordinates,
  });

  const [loading, setLoading] = React.useState(false);
  const [tempMembers, setTempMembers] = React.useState(emptyArray);

  const teams = useSelector(
    (globalState: RootStore) => globalState.areas.teams,
  );
  const selectedCoordinator = useSelector(
    (globalState: RootStore) => globalState.areas.selectedCoordinator,
  );

  const dispatch = useDispatch();

  // const validation = () => {
  //   if (
  //     area.members?.length > 0 &&
  //     selected?.feature.geometry.coordinates?.length > 0
  //   ) {
  //     return false;
  //   } else return true;
  // };

  const onDragEnd = (result: any) => {
    const id = result.draggableId;
    if (result.destination && result.source.index !== result.destination.index)
      if (result.source.index > result.destination.index) {
        goDownList(id, result.source.index - result.destination.index);
      } else {
        goUpList(id, result.destination.index - result.source.index);
      }
  };

  const transformData = (data: any[]) => {
    // 'name', 'email', 'phone', 'workToday', 'routess', 'progress'
    return data.map((d) => ({
      _id: d.bossId,
      name: d.name,
    }));
  };

  useEffect(() => {
    let listSelectedRows = [];
    if (teams.length > 0) {
      listSelectedRows.push(teams[0]._id);
    } else {
      listSelectedRows = [];
    }
    setTempMembers(listSelectedRows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teams]);

  useEffect(() => {
    if (selected) {
      if (selected.feature._id.length > 6)
        setToArea({
          ...selected.feature,
          extraGoal: selected.feature.extraGoal
            ? selected.feature.extraGoal
            : 0,
        });
      else
        setToArea({
          ...selected.feature,
          extraGoal: selected.feature.extraGoal
            ? selected.feature.extraGoal
            : 0,
          members: tempMembers,
        });
      setLoading(true);
      dispatch(
        postSearchCoordinatesData(
          {
            geometry: selected.feature.geometry,
            municipalityId: citySelected?._id,
          },
          (data: any) => {
            setDataArea(data[0]);
            setLoading(false);
          },
          (error: any) => {
            setDataArea({
              audited: 0,
              toAudit: 0,
              toCapture: 0,
              total: 0,
            });
            setLoading(false);
          },
        ),
      );
    } else {
      setDataArea({
        audited: 0,
        toAudit: 0,
        toCapture: 0,
        total: 0,
      });
      setToArea((prevState: any) => {
        return {
          ...prevState,
          extraGoal: 0,
        };
      });
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const update = () => {
    const list: any[] = [
      {
        _id: area._id,
        limit: area.limit,
        limitType: area.limitType,
        extraGoal: area.extraGoal,
      },
    ];
    dispatch(
      putListRoutes(
        list,
        (data: any) => {
          if (teams.length > 0)
            dispatch(
              postGetAreasByUsers(
                teams.reduce(
                  // eslint-disable-next-line
                  (a: any, o: any) => (o.bossId && a.push(o.bossId), a),
                  [],
                ),
              ),
            );
        },
        (error: any) => {},
      ),
    );
  };

  return (
    <>
      <div className="traking-electoral-selectors">
        <SelectorFormsComponent
          defaultSelector={
            selectedCoordinator && selectedCoordinator._id !== ''
              ? selectedCoordinator._id
              : ''
          }
          title={titleLv2}
          options={transformData(teams)}
          horizontal={true}
          fun={(e: any) => {
            let listSelectedRows: any[] = [];
            listSelectedRows = [e];
            if (area._id.length < 6) setTempMembers(listSelectedRows);
            setToArea((prevState) => ({
              ...prevState,
              members: listSelectedRows,
            }));
            getBounds(listSelectedRows);
            const coordinator: any = teams.find((c) => c.bossId === e);
            dispatch(
              changeSelectedCoordinator({
                _id: e,
                name: coordinator.name,
              }),
            );
            setSelected((prevState: any) => {
              prevState?.pm.disable();
              prevState?.unbindTooltip();
              prevState
                ?.bindTooltip(String(prevState.feature.index), {
                  permanent: true,
                  direction: 'center',
                  className: 'my-labels',
                })
                .openTooltip();
              return null;
            });
          }}
        ></SelectorFormsComponent>
      </div>
      <div className="traking-electoral-assign"></div>
      <ListFilterCustomComponent
        direction={'right'}
        className={'selected-area-data-box'}
        size={'lg-filter'}
        onHide={onHide}
      >
        {/* <TabComponent
          options={['territorial.goals.tab.1', 'territorial.goals.tab.2']}
        > */}
        <div className="header-box">
          {userData?.role === ROLE.CAPTURIST_BOSS && (
            <>
              <div className="duration-box">
                <div className="duration-title">
                  <h3>{t('territorial.goals.lbl.duration').toUpperCase()}</h3>
                </div>
                <div className="limit-box-data">
                  <div className="limit-box">
                    <Form.Control
                      placeholder="1"
                      value={area.limit}
                      maxLength={2}
                      onFocus={handleFocus}
                      onChange={(data: any) => {
                        const value = data.target.value;
                        if (onlyDigits.test(value)) {
                          setToArea((prevState) => ({
                            ...prevState,
                            limit: value,
                          }));
                          setCounter(1);
                          setToggle(true);
                        }
                      }}
                    />
                    <div className="selections-duration-box">
                      <Button
                        variant="secondary"
                        className={area.limitType === 0 ? 'active' : ''}
                        onClick={() => {
                          setToArea((prevState) => ({
                            ...prevState,
                            limitType: 0,
                          }));
                          setCounter(1);
                          setToggle(true);
                        }}
                      >
                        {t('territorial.goals.lbl.duration.days')}
                      </Button>
                      <Button
                        variant="secondary"
                        className={area.limitType === 1 ? 'active' : ''}
                        onClick={() => {
                          setToArea((prevState) => ({
                            ...prevState,
                            limitType: 1,
                          }));
                          setCounter(1);
                          setToggle(true);
                        }}
                      >
                        {t('territorial.goals.lbl.duration.weeks')}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          <div className="box-counts-data">
            <div className="counts-title">
              <h3>{t('territorial.goals.lbl.voters').toUpperCase()}</h3>
            </div>
            <div className="list-data-counts">
              <div className="item-counts">
                <div className="left-box">
                  <p>{t('territorial.goals.lbl.voters.total')}:</p>
                </div>
                <div className="right-box">
                  {loading ? (
                    <Spinner animation="border" variant="primary" />
                  ) : (
                    <p>
                      <strong>
                        {
                          // tslint:disable-next-line: radix
                          (parseInt(dataArea.total.toString()) || 0) +
                            // tslint:disable-next-line: radix
                            (parseInt(area.extraGoal.toString()) || 0)
                        }
                      </strong>
                    </p>
                  )}
                </div>
              </div>
              <div className="item-counts">
                <div className="left-box">
                  <p>{t('territorial.goals.lbl.voters.audited')}:</p>
                </div>
                <div className="right-box">
                  {loading ? (
                    <Spinner animation="border" variant="primary" />
                  ) : (
                    <p>
                      <strong>{dataArea.audited}</strong>
                    </p>
                  )}
                </div>
              </div>
              <div className="item-counts">
                <div className="left-box">
                  <p>{t('territorial.goals.lbl.voters.toAudit')}:</p>
                </div>
                <div className="right-box">
                  {loading ? (
                    <Spinner animation="border" variant="primary" />
                  ) : (
                    <p>
                      <strong>{dataArea.toAudit}</strong>
                    </p>
                  )}
                </div>
              </div>
              <div className="item-counts">
                <div className="left-box">
                  <p>{t('territorial.goals.lbl.voters.toCapture')}:</p>
                </div>
                <div className="right-box">
                  {loading ? (
                    <Spinner animation="border" variant="primary" />
                  ) : (
                    <p>
                      <strong>{dataArea.toCapture}</strong>
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="box-counts-data door-to-door-box">
            <div className="counts-title">
              <h3>{t('territorial.goals.lbl.doorToDoor').toUpperCase()}</h3>
            </div>
            <div className="list-data-counts">
              <div className="item-counts">
                <div className="right-box">
                  <Form.Control
                    placeholder="0"
                    value={area.extraGoal}
                    maxLength={6}
                    onFocus={handleFocus}
                    onChange={(data: any) => {
                      const value = data.target.value;
                      if (onlyDigits.test(value)) {
                        setToArea((prevState) => ({
                          ...prevState,
                          extraGoal: value,
                        }));
                        setCounter(2);
                        setToggle(true);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="box-counts-data-areas">
            <div className="counts-title">
              <h3>{t('territorial.goals.lbl.order').toUpperCase()}</h3>
            </div>
            <div className="list-areas">
              <div className="box-list">
                <div className="list-positions">
                  {layersGeoJson.map((item: any, index: any) => (
                    <div className="box-position" key={index}>
                      <div className="position-circle">{index + 1}</div>
                    </div>
                  ))}
                </div>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable
                    droppableId="droppable"
                    ignoreContainerClipping={false}
                  >
                    {(provided, snapshot) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className="list-droppable"
                      >
                        {layersGeoJson.map((item: any, index: any) => {
                          if (item)
                            return (
                              <div
                                className="list-droppable-element"
                                key={item._id + '-' + index}
                              >
                                <Draggable
                                  key={index}
                                  draggableId={item._id}
                                  index={index}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      className={'item-draggable-element'}
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <Switch
                                        onChange={() => {
                                          let enable =
                                            item.enable === undefined ||
                                            item.enable === true
                                              ? false
                                              : true;
                                          changeRouteState({
                                            _id: item._id,
                                            enable,
                                          });
                                        }}
                                        checked={item.enable}
                                        height={22}
                                        width={35}
                                        onColor="#2FAD74"
                                        handleDiameter={22}
                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                        activeBoxShadow="0px 0px 1px 5px rgba(0, 0, 0, 0.2)"
                                        className="react-switch"
                                        id="material-switch"
                                      ></Switch>
                                      <div className="item-draggable">
                                        <div className="first-box">
                                          <HamburgerIcon></HamburgerIcon>
                                          <p>
                                            {t('territorial.tracker.lbl.area') +
                                              ' ' +
                                              (index + 1)}
                                          </p>
                                        </div>
                                        <div
                                          className="square-area"
                                          // style={{
                                          //   backgroundColor: item.colorBase,
                                          // }}
                                        ></div>
                                      </div>
                                    </div>
                                  )}
                                </Draggable>
                              </div>
                            );
                        })}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </div>
          </div>
        </div>{' '}
        {/* </TabComponent> */}
      </ListFilterCustomComponent>
    </>
  );
};

export default AreaDataBox;
