import React from 'react';
import { RouteProps } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import './style.scss';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';

interface InputProps extends RouteProps {
  title: any;
  onChange: any;
  placeholder: string;
  validFilesTypes: any;
  horizontal?: boolean;
  readonly?: boolean;
}

const InputFileComponent: React.SFC<InputProps> = ({
  title,
  onChange,
  placeholder,
  horizontal,
  readonly,
  validFilesTypes,
}: any) => {
  const { t } = useTranslation();
  const onFileChange = (e: any) => {
    const files = e.target.files || e.dataTransfer.files;
    if (files.length > 0) {
      const weight = files[0].size / 1024;
      const limit = 10 * 1024;
      if (weight < limit) {
        let isValidExt = false;
        if (files[0].name.match(validFilesTypes)) {
          isValidExt = true;
        }

        if (!isValidExt) {
          return false;
        } else {
          onChange(files[0]);
        }
      } else {
        console.log('Máximo 10MB por archivo');
      }
    }
  };
  return (
    <div
      className={horizontal ? 'input-file-box-horizontal' : 'input-file-box'}
    >
      <h2>{title}</h2>
      <Form>
        <Form.File
          id="custom-file"
          label={placeholder}
          custom
          onChange={onFileChange}
        />
        <Button variant="primary">{t('general.form.file.action')}</Button>
      </Form>
    </div>
  );
};

export default InputFileComponent;
