import {
  VOTERS_FAIL,
  VOTERS_LOADING,
  VOTERS_SUCCESS,
  VOTERS_COUNT_SUCCESS,
  VOTERS_SUCCESS_ONE,
  VOTERS_PAGE_SUCCESS,
  VOTERS_SEARCH_SUCCESS,
  VOTERS_OPEN,
  VotersDispatchTypes,
} from './VotersTypes';

interface VotersState {
  loading: boolean;
  voters?: Array<any>;
  votersGlobal?: Array<any>;
  page?: number;
  records?: number;
  sort?: Array<any>;
  filters?: Object;
  totalPage: number;
  totalVoters: number;
  voter: Object;
  voterAudition: Object;
  voterDuplicate: Object;
  open: boolean;
  search: string;
}

const defaultState: VotersState = {
  loading: false,
  page: 1,
  records: 10,
  sort: ['firstName', 'asc'],
  filters: {
    all: false,
    confirmed: false,
    captured: false,
    registered: true,
    problems: true,
    expiredAll: true,
    expiredYes: false,
    expiredNo: false,
    expiredEmpty: false,
  },
  totalPage: 1,
  voters: [],
  totalVoters: 0,
  voter: {},
  voterAudition: {},
  voterDuplicate: {},
  open: false,
  search: '',
};

const votersReducer = (
  state: VotersState = defaultState,
  action: VotersDispatchTypes,
): VotersState => {
  switch (action.type) {
    case VOTERS_FAIL:
      return {
        ...state,
        loading: false,
      };
    case VOTERS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case VOTERS_SUCCESS:
      const list = [];
      const data: any = action.payload;
      list.push(...(data.voters || []));
      return {
        ...state,
        voters: list,
        page: data.page,
        records: data.records,
        sort: data.sort,
        filters: data.filters,
        loading: false,
        //search: data.search,
      };
    case VOTERS_COUNT_SUCCESS:
      const data1: any = action.payload;
      console.log(data1);
      return {
        ...state,
        totalPage: data1.pages,
        totalVoters: data1.totalVoters,
      };
    case VOTERS_SUCCESS_ONE:
      return {
        ...state,
        voter: action.payload,
        loading: false,
      };
    case VOTERS_PAGE_SUCCESS:
      const page = Number.parseInt(action.page, 0) - 1;
      const skip = page * 8;
      const list1 = [];
      list1.push(...(state.votersGlobal || []));
      const vBP = list1?.splice(skip, 8);
      return {
        ...state,
        loading: false,
        voters: vBP,
      };
    case VOTERS_OPEN:
      return {
        ...state,
        open: action.open,
        loading: false,
      };
    case VOTERS_SEARCH_SUCCESS:
      return {
        ...state,
        search: action.searchValue,
        loading: false,
      };
    default:
      return state;
  }
};

export default votersReducer;
