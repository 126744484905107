export const TEAMS_LOADING = 'TEAMS_LOADING';
export const TEAMS_FAIL = 'TEAMS_FAIL';
export const TEAMS_SUCCESS = 'TEAMS_SUCCESS';
export const TEAMS_DATA_SUCCESS = 'TEAMS_DATA_SUCCESS';
export const TEAMS_COUNT_SUCCESS = 'TEAMS_COUNT_SUCCESS';
export const TEAMS_LEFT_DATA_SUCCES = 'TEAMS_LEFT_DATA_SUCCES';
export const TEAMS_SEARCH_SUCCESS = 'TEAMS_SEARCH_SUCCESS';
export const TEAMS_SELECT_CAPTURIST_SUCCESS = 'TEAMS_SELECT_CAPTURIST_SUCCESS';
export const TEAMS_COORDINATOR_SUCCESS_ONE = 'TEAMS_COORDINATOR_SUCCESS_ONE';
export const TEAMS_TOTAL_CAMPAIGN_SUCCES = 'TEAMS_TOTAL_CAMPAIGN_SUCCES';
export const TEAMS_BREADCUM_SUCCES = 'TEAMS_BREADCUM_SUCCES';
export const COVERAGE_DATA = 'COVERAGE_DATA';
export const COVERAGE_LOADING = 'COVERAGE_LOADING';

export const TAB_CHANGE = 'TAB_CHANGE';

export type DeleteCapturistTeam = {
  toDelete: Array<string>;
  isTeam: boolean;
};

export type SearchCapturistTeamsByCityDuplicate = {
  municipalityId: string;
  teamLevel: number;
};

export type SearchTeamData = {
  coordinatorId: string;
  coordinatorRole: string;
  teamLevel: number;
  municipalityId: string;
  search: string;
  records: number;
  order: string;
  page: number;
};

export type SearchLeftData = {
  coordinatorId: string;
};

export interface TeamsLoading {
  type: typeof TEAMS_LOADING;
}
export interface TeamsFail {
  type: typeof TEAMS_FAIL;
}
export interface TeamsSuccess {
  type: typeof TEAMS_SUCCESS;
  payload: object;
}
export interface TeamsDataSuccess {
  type: typeof TEAMS_DATA_SUCCESS;
  payload: object;
}
export interface TeamsCountSuccess {
  type: typeof TEAMS_COUNT_SUCCESS;
  payload: object;
}
export interface TeamsLeftDataSuccess {
  type: typeof TEAMS_LEFT_DATA_SUCCES;
  payload: object;
}
export interface TeamsSearchSuccess {
  type: typeof TEAMS_SEARCH_SUCCESS;
  searchValue: string;
}
export interface TeamsSelectCapturistSuccess {
  type: typeof TEAMS_SELECT_CAPTURIST_SUCCESS;
  selectedCapturist: any;
}
export interface TeamCoordinatorSuccessOne {
  type: typeof TEAMS_COORDINATOR_SUCCESS_ONE;
  payload: object;
  follow: string;
  subLevel: boolean;
}
export interface TeamsTotalCampaignSuccess {
  type: typeof TEAMS_TOTAL_CAMPAIGN_SUCCES;
  payload: object;
}
export interface TeamsBreadcumSuccess {
  type: typeof TEAMS_BREADCUM_SUCCES;
  payload: object;
}

export interface CoverageData {
  type: typeof COVERAGE_DATA;
  coverageMap: any[];
}
export interface CoverageLoading {
  type: typeof COVERAGE_LOADING;
  loading: boolean;
}
export interface TabChange {
  type: typeof TAB_CHANGE;
  tabChange: any;
}

export type TeamsDispatchTypes =
  | TeamsLoading
  | TeamsFail
  | TeamsSuccess
  | TeamsDataSuccess
  | TeamsCountSuccess
  | TeamsLeftDataSuccess
  | TeamsSearchSuccess
  | TeamsSelectCapturistSuccess
  | TeamCoordinatorSuccessOne
  | TeamsTotalCampaignSuccess
  | TeamsBreadcumSuccess
  | CoverageData
  | CoverageLoading
  | TabChange;
