import React, { FunctionComponent } from 'react';
import * as netWorking from '../../../redux/netWorking';
import { useTranslation } from 'react-i18next';
import '../style.scss';
import StaticModal from '../../../dialogs/StaticModal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

interface ModalNoIncidenceProps {
  modalShow: any;
  setModalShow: any;
  successFun: any;
  data: any;
  tracking: any;
}

export const ModalNoIncidence: FunctionComponent<ModalNoIncidenceProps> = ({
  modalShow,
  setModalShow,
  successFun,
  data,
  tracking,
}: ModalNoIncidenceProps) => {
  const { t } = useTranslation();
  const submitFun = () => {
    netWorking.post(
      netWorking.postMarkPointIncidence,
      {
        _id: tracking._id,
        pointId: data._id,
        status: 0,
        issue: '',
      },
      () => {
        successFun();
        setModalShow(false);
      },
      () => {},
    );
  };

  return (
    <StaticModal
      show={modalShow}
      title={t('territorial.tracker.modal.lbl.removeProblem')}
      closeText={t('submit.cancel')}
      confirmText={t('submit.yes')}
      onHide={() => setModalShow(false)}
      submitFun={submitFun}
      validBtn={false}
      size="lg"
      sizeClass="md modal-rc"
    >
      <>
        <Container className="body-modal">
          <Row>
            <Col xl={12} lg={12} md={12} sm={12} className="mt-2">
              <p>{t('territorial.tracker.modal.lbl.removeProblemQuestion')}</p>
            </Col>
          </Row>
        </Container>
      </>
    </StaticModal>
  );
};

export default ModalNoIncidence;
