import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import List from './components/List';
import TopSeparator from '../../components/TopSeparator';
import './style.scss';

const VotersTerritorial: React.FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <TopSeparator></TopSeparator>
      <List t={t}></List>
    </Fragment>
  );
};

export default VotersTerritorial;
