import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  postDeleteLawyers
} from '../../../redux/lawyers/lawyersAction';

import StaticModal from '../../../dialogs/StaticModal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

interface ModalDeleteRcRgProps {
  modalShow: any;
  setModalShow: any;
  toDelete: any;
  successFun: any;
  lawyers: any;
}

export const ModalDeleteLawyer: React.SFC<ModalDeleteRcRgProps> = ({
  modalShow,
  setModalShow,
  toDelete,
  successFun,
  lawyers,
}: any) => {

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const buildParamsDeleteLawyers = (
    toDelete: Array<string>,
  ) => {
      let requestObject;
      requestObject = {
        toDelete: toDelete,
        isTeam: false,
      };
      return requestObject;
  };

  const submitFun = () => {
    setModalShow(false);
    const params = buildParamsDeleteLawyers(toDelete)
    dispatch(
      postDeleteLawyers(
        params,
        () => {
          successFun();
        }
      ),
    );
  };

  const getNames = () => {
    let text = '';
    if (lawyers !== undefined)
      for (const vB of lawyers) {
        if (toDelete.indexOf(vB._id) !== -1) {
          if (text === '') text = text + ' ' + vB.firstName + ' ' + vB.lastName;
          else text = text + ', ' + vB.firstName + ' ' + vB.lastName;
        }
      }
    return text;
  };

  return (
    <StaticModal
      show={modalShow}
      title={t('electoral.lawyers.lbl.deleteLawyer')}
      closeText={t('submit.cancel')}
      confirmText={t('submit.yes.delete')}
      onHide={() => setModalShow(false)}
      submitFun={submitFun}
      validBtn={false}
      size="md"
      sizeClass="md modal-rc"
    >
      <>
        <Container className="body-modal">
          <Row noGutters className="modal-text-row">
            <Col xs={12} className="description-col">
              <div className="text-description">
                {t("settings.users.modal.delete.body")}
              </div>
              <div className="text-description">{getNames()}</div>
            </Col>
          </Row>
        </Container>
      </>
    </StaticModal>
  );
};

export default ModalDeleteLawyer;