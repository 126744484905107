import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../style.scss";
import StaticModal from "../../../dialogs/StaticModal";
import { postTrainingDelete } from "../../../redux/trainings/TrainingsActions";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../redux/Store";

interface ModalDeleteProps {
  modalShow: any;
  setModalShow: any;
  toDelete: any;
  successFun: any;
  setIsPerformingRequest: any;
  t: any;
}

export const ModalDelete: React.SFC<ModalDeleteProps> = ({
  modalShow,
  setModalShow,
  toDelete,
  successFun,
  setIsPerformingRequest,
  t,
}: any) => {
  
  const search = useSelector((globalState: RootStore) => globalState.auditory.search);
  const dispatch = useDispatch();

  const submitFun = () => {
    if (toDelete.length > 0) {
      setModalShow(false);
      setIsPerformingRequest(true);
      dispatch(
        postTrainingDelete(
          search,
          toDelete,
          () => {
            successFun({ deleted: true });
            setIsPerformingRequest(false);
          },
          () => {
            successFun({ deleted: false });
            setIsPerformingRequest(false);
          }
        )
      );
    }
  };

  return (
    <StaticModal
      show={modalShow}
      title={t("settings.trainings.modal.delete.title")}
      closeText={t("submit.cancel")}
      confirmText={t("settings.trainings.modal.delete.confirmText")}
      onHide={() => setModalShow(false)}
      submitFun={submitFun}
      validBtn={false}
      size="sm"
    >
      <Row noGutters className="modal-text-row">
        <Col xs={12} className="description-col">
          {t("settings.trainings.modal.delete.body")}
        </Col>
        <Col xs={12} className="question-col">
          <strong>{t("settings.trainings.modal.wishContinue")}</strong>
        </Col>
      </Row>
    </StaticModal>
  );
};

export default ModalDelete;
