import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../redux/Store';
import { useTranslation } from 'react-i18next';
import * as netWorking from '../../../redux/netWorking';

import {
  validEmailRegex,
  onlyLetters,
  onlyDigits,
  validPhoneRegex,
  ROLE,
} from '../../../utils/utils';
import { checkInUse } from '../../../redux/User/userActions';
import {
  putUpdateUserTeam,
  postCreateUserTeam,
} from '../../../redux/RgRc/RgRcActions';
import { Team } from '../../../redux/RgRc/RgRcTypes';

import SelectorFormsComponent from '../../../components/sub-component/selectorForms';
import InputComponent from '../../../components/sub-component/input';
import StaticModal from '../../../dialogs/StaticModal';
import Container from 'react-bootstrap/Container';
import Switch from 'react-switch';

// tslint:disable-next-line: prefer-const
let defaultTeam: Team;

interface ModalAddRcProps {
  modalShow: any;
  setModalShow: any;
  successFun: any;
  edit?: any;
  type: any;
  isTeam: boolean;
  selectedTeam?: any;
  mainTitle: string;
  getPin: any;
}

export const ModalAddRc: React.FunctionComponent<ModalAddRcProps> = ({
  modalShow,
  setModalShow,
  successFun,
  edit,
  type,
  isTeam,
  selectedTeam,
  mainTitle = '',
  getPin,
}: any) => {
  const citySelected: any = useSelector((globalState: RootStore) => {
    return globalState.core.globalMunicipalitySelected;
  });

  const stateSelected = useSelector((globalState: RootStore) => {
    return globalState.core.globalStateSelected;
  });

  const arrayDefault: any = [];

  const defaultEdit = {
    _id: '',
    blocked: false,
    deleted: false,
    firstName: '',
    lastName: '',
    email: '',
    cellphone: '',
    states: arrayDefault,
    cities: arrayDefault,
    campaignId: localStorage.campaignId,
    municipalityId: '',
    role: type,
    team: defaultTeam,
    votersB: {
      _id: '',
      number: '',
      section: '',
      district: '',
      municipalityId: '',
    },
    votersBCompare: {
      _id: '',
      number: '',
      section: '',
      district: '',
      municipalityId: '',
    },
  };

  const [toSave, setToSave] = useState(defaultEdit);
  const [toCompare, setToCompare] = useState(defaultEdit);

  const [submitMessage, setSubmitMessage] = useState(String);
  const [submitConfirmation, setSubmitConfirmation] = useState(false);

  const [loadData, setLoadData] = useState(false);

  const inputFirstnameRef: any = useRef(null);
  const inputLastnameRef: any = useRef(null);
  const inputEmailRef: any = useRef(null);
  const inputCellphoneRef: any = useRef(null);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  console.log('toSave', toSave);

  const arrayDistrict: any = [
    { _id: '', value: t('settings.trainings.form.select') },
  ];
  const arraySection: any = [
    { _id: '', value: t('settings.trainings.form.select') },
  ];
  const arrayPollingStation: any = [
    { _id: '', value: t('settings.trainings.form.select') },
  ];
  const [district, setDistrict] = useState(arrayDistrict);
  const [section, setSection] = useState(arraySection);
  const [pollingStation, setPollingStation] = useState(arrayPollingStation);

  const getMunicipality = () => {
    if (toSave.votersB._id === '' || toSave.votersB._id === undefined) {
      setToSave((prevState) => ({
        ...prevState,
        states: [stateSelected?._id],
        cities: [citySelected?._id],
        municipalityId: citySelected?._id || '',
        votersB: {
          ...prevState.votersB,
          municipalityId: citySelected?._id || '',
        },
      }));
      getDistrict(citySelected?._id || '');
    } else {
      getDistrict(citySelected?._id || '');
    }
  };

  const getDistrict = (id: string) => {
    netWorking.get(
      netWorking.getDistrict + id,
      (res: any) => {
        if (res.data.response[0]) {
          let arrayData = res.data.response;
          arrayData.unshift({
            _id: '',
            value: t('settings.trainings.form.select'),
          });
          if (toSave.votersB._id === '' || toSave.votersB._id === undefined) {
            setToSave((prevState) => ({
              ...prevState,
              votersB: {
                ...prevState.votersB,
                district: arrayData[0]._id,
              },
            }));
            getSection(arrayData[0]._id, id);
          } else {
            let districtList = [...res.data.response];
            let searchDistrict = districtList?.find(
              (d: any) => d.name === toSave.votersB.district,
            );
            getSection(searchDistrict ? searchDistrict._id : '', id);
          }
          setDistrict(arrayData);
        } else {
          setDistrict(arrayDistrict);
          setSection(arraySection);
          setPollingStation(arrayPollingStation);
        }
      },
      (error: any) => {
        setDistrict(arrayDistrict);
        setSection(arraySection);
        setPollingStation(arrayPollingStation);
        setToSave((prevState) => ({
          ...prevState,
          votersB: {
            ...prevState.votersB,
            _id: '',
            section: '',
            district: '',
          },
        }));
      },
    );
  };

  const getSection = (id: string, citySelectedId: string) => {
    netWorking.get(
      netWorking.getSection + id,
      (res: any) => {
        if (res.data.response[0]) {
          let arrayData = res.data.response;
          arrayData.unshift({
            _id: '',
            value: t('settings.trainings.form.select'),
          });
          if (toSave.votersB._id === '' || toSave.votersB._id === undefined) {
            setToSave((prevState) => ({
              ...prevState,
              votersB: {
                ...prevState.votersB,
                section: arrayData[0]._id,
              },
            }));
            getPollingStation(arrayData[0]._id, id, citySelectedId);
          } else {
            let sectionList = [...res.data.response];
            let searchSection = sectionList?.find(
              (d: any) => d.name === toSave.votersB.section,
            );
            getPollingStation(
              searchSection ? searchSection._id : '',
              id,
              citySelectedId,
            );
          }
          setSection(arrayData);
        } else {
          setSection(arraySection);
          setPollingStation(arrayPollingStation);
        }
      },
      (error: any) => {
        setSection(arraySection);
        setPollingStation(arrayPollingStation);
        setToSave((prevState) => ({
          ...prevState,
          votersB: {
            ...prevState.votersB,
            _id: '',
            sectionId: '',
          },
        }));
      },
    );
  };

  const getPollingStation = (
    id: string,
    district: string,
    citySelectedId: string,
  ) => {
    netWorking.post(
      netWorking.postVotersBoxByRgSection,
      {
        sectionId: id,
        districtId: district,
        citySelectedId: citySelectedId,
      },
      (res: any) => {
        if (res.data.response[0]) {
          let arrayData = res.data.response;
          arrayData.unshift({
            _id: '',
            value: t('settings.trainings.form.select'),
          });
          if (toSave.votersB._id === '' || toSave.votersB._id === undefined) {
            setToSave((prevState) => ({
              ...prevState,
              votersB: {
                ...prevState.votersB,
                _id: arrayData[0]._id,
              },
            }));
          }
          setPollingStation(arrayData);
        } else {
          setPollingStation(arrayPollingStation);
        }
      },
      (error: any) => {
        setPollingStation(arrayPollingStation);
        setToSave((prevState) => ({
          ...prevState,
          votersB: {
            ...prevState.votersB,
            _id: '',
          },
        }));
      },
    );
  };

  const unassignRc = () => {
    if (
      toSave._id !== '' ||
      (toSave._id !== undefined && toSave.votersB._id !== '') ||
      toSave.votersB._id !== undefined
    ) {
      netWorking.post(
        netWorking.postAssignVoterBoxGeneral,
        {
          _id: edit.votersB._id,
          rgId: edit.votersB.rgId,
          rcId: edit.role === ROLE.RC_SUBSTITUTE ? edit.votersB.rcId : '',
          substituteId:
            edit.role === ROLE.RC_SUBSTITUTE ? '' : edit.votersB.substituteId,
        },
        () => {
          successFun();
          setModalShow(false);
        },
        () => {},
      );
    }
  };

  const submitFun = (validSubmit: any) => {
    if (validSubmit) {
      setSubmitMessage(validSubmit);
      setSubmitConfirmation(true);
    } else {
      if (toSave._id !== '') {
        setModalShow(false);
        dispatch(
          putUpdateUserTeam(
            {
              ...toSave,
              team: {
                _id: selectedTeam.teamId,
                bossId: selectedTeam.bossId,
                members: selectedTeam.members,
              },
            },
            () => {
              successFun();
            },
          ),
        );
      } else {
        if (selectedTeam) {
          setModalShow(false);
          dispatch(
            postCreateUserTeam(
              {
                ...toSave,
                team: {
                  _id: selectedTeam.teamId,
                  bossId: selectedTeam.bossId,
                  members: selectedTeam.members,
                },
              },
              (res: any) => {
                successFun();
                getPin(res.data.response.user._id);
              },
            ),
          );
        } else {
          setModalShow(false);
          dispatch(
            postCreateUserTeam(
              {
                ...toSave,
              },
              (res: any) => {
                successFun();
                getPin(res.data.response.user._id);
              },
            ),
          );
        }
      }
    }
  };

  const verifyCellphoneInUse = () => {
    let checkInUseAux: any = {
      cellphone: toSave.cellphone,
    };

    if (toSave.cellphone === '' || toSave.cellphone === undefined) {
      validSubmit(1);
    } else {
      dispatch(
        checkInUse(checkInUseAux, (data: any) => {
          validSubmit(data.error);
        }),
      );
    }
  };

  const validSubmit = (data: any) => {
    const newStringValue = verifyFields(data);
    submitFun(newStringValue);
  };

  const verifyFields = (data: any) => {
    if (toSave.firstName === '') {
      return t('territorial.volunteer.modal.error.name');
    } else if (toSave.lastName === '') {
      return t('territorial.volunteer.modal.error.lastname');
    } else if (toSave.email !== '' && !validEmailRegex.test(toSave.email)) {
      return t('territorial.volunteer.modal.error.email');
    } else if (
      toSave.cellphone === '' ||
      !validPhoneRegex.test(toSave.cellphone) ||
      data === 0
    ) {
      if (data === 0 && toSave.cellphone === toCompare.cellphone) {
        return '';
      } else if (data === 0 && toSave.cellphone !== toCompare.cellphone) {
        return t('territorial.volunteer.modal.error.cellphoneExist');
      } else if (toSave.cellphone === '') {
        return t('territorial.volunteer.modal.error.cellphoneComplete');
      } else if (!validPhoneRegex.test(toSave.cellphone)) {
        return t('territorial.volunteer.modal.error.cellphone');
      } else if (toSave.cellphone.length < 5) {
        return t('territorial.volunteer.modal.error.cellphone');
      }
    } else {
      return '';
    }
  };

  useEffect(() => {
    if (edit !== undefined) {
      let newRole: any;
      if (type === ROLE.RC) {
        newRole = edit.role;
      } else {
        newRole = ROLE.RG;
      }
      setToSave((prevState) => ({
        ...prevState,
        _id: edit._id,
        firstName: edit.firstName,
        lastName: edit.lastName,
        email: edit.email,
        cellphone: edit.cellphone.replace(/\D/g, ''),
        role: newRole,
        votersB: {
          ...prevState.votersB,
          _id: edit.votersB?._id || '',
          number: edit.votersB?._id || '',
          section: edit.votersB?.section || '',
          district: edit.votersB?.district || '',
        },
        votersBCompare: {
          ...prevState.votersB,
          _id: edit.votersB?._id || '',
          number: edit.votersB?._id || '',
          section: edit.votersB?.section || '',
          district: edit.votersB?.district || '',
        },
      }));
      setToCompare((prevState) => ({
        ...prevState,
        _id: edit._id,
        firstName: edit.firstName,
        lastName: edit.lastName,
        email: edit.email,
        cellphone: edit.cellphone.replace(/\D/g, ''),
        role: newRole,
        votersB: {
          ...prevState.votersB,
          _id: edit.votersB?._id || '',
          number: edit.votersB?._id || '',
          section: edit.votersB?.section || '',
          district: edit.votersB?.district || '',
        },
      }));
    }
    setLoadData(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edit]);

  useEffect(() => {
    if (loadData) {
      getMunicipality();
      inputFirstnameRef.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadData]);

  return (
    <StaticModal
      show={modalShow}
      title={mainTitle}
      closeText={t('submit.cancel')}
      confirmText={t('general.modal.btn.confirm')}
      unassignText={t('territorial.goals.btn.unassign')}
      onHide={() => {
        setModalShow(false);
      }}
      onUnassign={unassignRc}
      submitFun={verifyCellphoneInUse}
      validBtn={false}
      size="lg"
      sizeClass="md modal-rc"
      showCancelBtn={
        edit && type === ROLE.RC && edit.votersB._id ? false : true
      }
      showUnassignBtn={
        edit && type === ROLE.RC && edit.votersB._id ? true : false
      }
    >
      <>
        <Container className="dialog-add-teams">
          {submitConfirmation && (
            <div className="col-item-message">
              <p>{submitMessage}</p>
            </div>
          )}
          <div className="row-item-modal first-row">
            <div className="col-item-modal">
              <InputComponent
                placeholder={t('general.modal.lbl.placeholder.name')}
                length={50}
                onChange={(value: string) => {
                  setToSave((prevState) => ({
                    ...prevState,
                    firstName: value,
                  }));
                }}
                title={t('general.modal.lbl.name')}
                value={toSave.firstName}
                regExp={onlyLetters}
                horizontal={false}
                refElement={inputFirstnameRef}
                focusAction={() => {
                  inputLastnameRef.current.focus();
                }}
              ></InputComponent>
            </div>
            <div className="col-item-modal">
              <InputComponent
                placeholder={t('general.modal.lbl.placeholder.lastname')}
                length={50}
                onChange={(value: string) => {
                  setToSave((prevState) => ({
                    ...prevState,
                    lastName: value,
                  }));
                }}
                title={t('general.modal.lbl.lastname')}
                value={toSave.lastName}
                regExp={onlyLetters}
                horizontal={false}
                refElement={inputLastnameRef}
                focusAction={() => {
                  inputCellphoneRef.current.focus();
                }}
              ></InputComponent>
            </div>
          </div>
          <div className="row-item-modal second-row">
            <div className="col-item-modal cellphone-item">
              <InputComponent
                length={20}
                placeholder={t('general.modal.lbl.cellphone')}
                onChange={(value: string) => {
                  setToSave((prevState) => ({
                    ...prevState,
                    cellphone: value.trim(),
                  }));
                }}
                title={t('general.modal.lbl.cellphone')}
                regExp={onlyDigits}
                value={toSave.cellphone}
                horizontal={false}
                refElement={inputCellphoneRef}
                focusAction={() => {
                  inputEmailRef.current.focus();
                }}
              ></InputComponent>
            </div>
            <div className="col-item-modal email-item">
              <InputComponent
                placeholder={t('general.modal.lbl.email')}
                length={50}
                onChange={(value: string) => {
                  setToSave((prevState) => ({
                    ...prevState,
                    email: value.trim(),
                  }));
                }}
                title={t('general.modal.lbl.emailOptional')}
                value={toSave.email}
                horizontal={false}
                refElement={inputEmailRef}
                focusAction={() => {
                  verifyCellphoneInUse();
                }}
              ></InputComponent>
            </div>
          </div>
          {type === ROLE.RC && (
            <>
              <div className="row-item-modal third-row">
                <div className="col-item-modal">
                  <SelectorFormsComponent
                    key={1}
                    title={t('settings.polling.table.district')}
                    defaultSelector={
                      district?.find(
                        (d: any) => d.name === toSave.votersB.district,
                      )
                        ? district?.find(
                            (d: any) => d.name === toSave.votersB.district,
                          )._id
                        : ''
                    }
                    options={district}
                    horizontal={false}
                    fun={(value: string) => {
                      getSection(value, citySelected?._id);
                      let searchDistrict = district?.find(
                        (d: any) => d._id === value,
                      );
                      setToSave((prevState) => ({
                        ...prevState,
                        votersB: {
                          ...prevState.votersB,
                          district: searchDistrict.name,
                        },
                      }));
                    }}
                  ></SelectorFormsComponent>
                </div>
                <div className="col-item-modal">
                  <SelectorFormsComponent
                    key={2}
                    title={t('settings.polling.table.section')}
                    defaultSelector={
                      section?.find(
                        (d: any) => d.name === toSave.votersB.section,
                      )
                        ? section?.find(
                            (d: any) => d.name === toSave.votersB.section,
                          )._id
                        : ''
                    }
                    options={section}
                    horizontal={false}
                    fun={(value: string) => {
                      let searchDistrict = district?.find(
                        (d: any) => d.name === toSave.votersB.district,
                      );
                      getPollingStation(
                        value,
                        searchDistrict._id,
                        citySelected?._id,
                      );
                      let searchSection = section?.find(
                        (d: any) => d._id === value,
                      );
                      setToSave((prevState) => ({
                        ...prevState,
                        votersB: {
                          ...prevState.votersB,
                          section: searchSection.name,
                        },
                      }));
                    }}
                  ></SelectorFormsComponent>
                </div>
              </div>
              <div className="row-item-modal fourth-row">
                <div className="col-item-modal">
                  <SelectorFormsComponent
                    key={3}
                    title={t('settings.polling.table.polling')}
                    defaultSelector={toSave.votersB._id}
                    options={pollingStation}
                    horizontal={false}
                    fun={(value: string) => {
                      setToSave((prevState) => ({
                        ...prevState,
                        votersB: {
                          ...prevState.votersB,
                          _id: value,
                        },
                      }));
                    }}
                  ></SelectorFormsComponent>
                </div>
                <div className="col-item-modal">
                  <h2>{t('electoral.rcrg.modal.lbl.RC/RCS')}</h2>
                  <div className="switcher-container">
                    <Switch
                      onChange={() => {
                        let state_role =
                          toSave.role === ROLE.RC
                            ? ROLE.RC_SUBSTITUTE
                            : ROLE.RC;
                        setToSave((prevState) => ({
                          ...prevState,
                          role: state_role,
                        }));
                      }}
                      checked={toSave.role === ROLE.RC ? true : false}
                      height={25}
                      width={50}
                      onColor="#2FAD74"
                      handleDiameter={25}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      activeBoxShadow="0px 0px 1px 5px rgba(0, 0, 0, 0.2)"
                      className="react-switch"
                      id="material-switch"
                    ></Switch>
                  </div>
                </div>
              </div>
            </>
          )}
        </Container>
      </>
    </StaticModal>
  );
};

export default ModalAddRc;
