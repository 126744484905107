import {
  INCIDENCE_LOADING,
  INCIDENCE_FAIL,
  INCIDENCE_ALL,
  INCIDENCE_CREATE,
  INCIDENCE_UPDATE,
  INCIDENCE_SELECTED,
  IncidenceDispatchTypes,
} from './incidenceTypes';

interface IncidenceState {
  loading: boolean;
  selected: object;
  incidencesData: any[];
}

const defaultState: IncidenceState = {
  loading: false,
  selected: {},
  incidencesData: [],
};

const incidenceReducer = (
  state: IncidenceState = defaultState,
  action: IncidenceDispatchTypes,
): IncidenceState => {
  switch (action.type) {
    case INCIDENCE_LOADING:
      return {
        ...state,
        loading: false,
      };
    case INCIDENCE_FAIL:
      return {
        ...state,
        loading: false,
      };
    case INCIDENCE_ALL:
      const list_incidences = [];
      const dataIncidencesSuccess: any = action.payload;
      list_incidences.push(...(dataIncidencesSuccess.incidences || []));
      return {
        ...state,
        incidencesData: list_incidences,
      };
    case INCIDENCE_CREATE:
      const createSuccess = action.data;
      let allDataCreate = [...state.incidencesData];
      allDataCreate.push(createSuccess);

      return {
        ...state,
        incidencesData: allDataCreate,
      };
    case INCIDENCE_UPDATE:
      const updateSuccess = action.data;
      let allDataUpdate = [...state.incidencesData];
      let newAllData: any = [];

      allDataUpdate.map((e: any) => {
        if (e._id === updateSuccess._id) {
          let updatedElement = e;

          updatedElement.status = updateSuccess.status;
          updatedElement.lawyer = updateSuccess.lawyer;

          newAllData.push(updatedElement);
        } else {
          newAllData.push(e);
        }
      });

      return {
        ...state,
        incidencesData: newAllData,
      };
    case INCIDENCE_SELECTED:
      return {
        ...state,
        selected: action.payload,
      };
    default:
      return state;
  }
};

export default incidenceReducer;
