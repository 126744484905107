import {
  DASHBOARD_LOADING,
  DASHBOARD_FAIL,
  DASHBOARD_ALL,
  DASHBOARD_LINE,
  DASHBOARD_PIE,
} from './dashboardTypes';
import * as netWorking from '../netWorking';

export const postCallLineChartData = (data: any) => {
  return async (dispatch: any) => {
    dispatch({
      type: DASHBOARD_LOADING,
    });
    return await netWorking.post(
      netWorking.postGetLineChartData,
      data,
      (res: any) => {
        return dispatch({
          type: DASHBOARD_LINE,
          payload: res.data.response,
        });
      },
      (error: any) => {
        return dispatch({
          type: DASHBOARD_FAIL,
        });
      },
    );
  };
};

export const postCallPieChartData = (data: any) => {
  return async (dispatch: any) => {
    dispatch({
      type: DASHBOARD_LOADING,
    });
    return await netWorking.post(
      netWorking.postGetPieChartData,
      data,
      (res: any) => {
        return dispatch({
          type: DASHBOARD_PIE,
          payload: res.data.response,
        });
      },
      (error: any) => {
        return dispatch({
          type: DASHBOARD_FAIL,
        });
      },
    );
  };
};

export const getDistricts = (municipalityId: any, fun?: any) => {
  netWorking.get(
    netWorking.getDistrict + municipalityId,
    (res: any) => {
      if (fun) {
        fun(res.data.response);
      }
    },
    (error: any) => {},
  );
};

export const getComplete = (municipalityId: any, fun?: any) => {
  netWorking.get(
    netWorking.getComplete + municipalityId,
    (res: any) => {
      if (fun) {
        fun(res.data.response);
      }
    },
    (error: any) => {},
  );
};

export const assignNecessary = (data: any) => {
  return async (dispatch: any) => {
    dispatch({
      type: DASHBOARD_LOADING,
    });
    return await netWorking.post(
      netWorking.assignNecessary,
      data,
      (res: any) => {},
      (error: any) => {
        return dispatch({
          type: DASHBOARD_FAIL,
        });
      },
    );
  };
};
