import {
  PartiesDispatchTypes,
  Party,
  SET_LOADING,
  SET_PARTIES_DATA,
} from './partiesTypes';

export interface PartyState {
  loading: boolean;
  parties: Party[];
}

export const defaultState: PartyState = {
  loading: false,
  parties: [],
};

export const partyReducer = (
  state: PartyState = defaultState,
  action: PartiesDispatchTypes,
): PartyState => {
  switch (action.type) {
    case SET_PARTIES_DATA:
      return {
        ...state,
        parties: action.parties,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: action.value,
      };

    default:
      return state;
  }
};
