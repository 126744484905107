export const DASHBOARD_ALL = 'DASHBOARD_ALL';
export const DASHBOARD_CREATE = 'DASHBOARD_CREATE';
export const DASHBOARD_UPDATE = 'DASHBOARD_UPDATE';
export const TAB_CHANGE = 'TAB_CHANGE';
//======================
export const DASHBOARD_LOADING = 'DASHBOARD_LOADING';
export const DASHBOARD_FAIL = 'DASHBOARD_FAIL';
export const DASHBOARD_SELECTED = 'DASHBOARD_SELECTED';
export const DASHBOARD_SEARCH_SUCCESS = 'DASHBOARD_SEARCH_SUCCESS';
export const DASHBOARD_LINE = 'DASHBOARD_LINE';
export const DASHBOARD_PIE = 'DASHBOARD_PIE';
//======================
export const DASHBOARD_D_LOADING = 'DASHBOARD_D_LOADING';
export const DASHBOARD_D_FAIL = 'DASHBOARD_D_FAIL';
export const DASHBOARD_D_SELECTED = 'DASHBOARD_D_SELECTED';
export const DASHBOARD_D_SEARCH_SUCCESS = 'DASHBOARD_D_SEARCH_SUCCESS';

export interface DashboardAllSuccess {
  type: typeof DASHBOARD_ALL;
  data: any;
}
export interface DashboardCreateSuccess {
  type: typeof DASHBOARD_CREATE;
  data: any;
}
export interface DashboardUpdateSuccess {
  type: typeof DASHBOARD_UPDATE;
  data: any;
}
export interface TabChange {
  type: typeof TAB_CHANGE;
  tabChange: any;
}
//======================
export interface DashboardLoading {
  type: typeof DASHBOARD_LOADING;
}
export interface DashboardFail {
  type: typeof DASHBOARD_FAIL;
}
export interface DashboardSelectedSuccess {
  type: typeof DASHBOARD_SELECTED;
  payload: object;
}
export interface DashboardSearchSuccess {
  type: typeof DASHBOARD_SEARCH_SUCCESS;
  searchValue: string;
}
export interface DashboardLineSuccess {
  type: typeof DASHBOARD_LINE;
  payload: object;
}
export interface DashboardPieSuccess {
  type: typeof DASHBOARD_PIE;
  payload: object;
}
//======================
export interface DashboardDLoading {
  type: typeof DASHBOARD_D_LOADING;
}
export interface DashboardDFail {
  type: typeof DASHBOARD_D_FAIL;
}
export interface DashboardDSelectedSuccess {
  type: typeof DASHBOARD_D_SELECTED;
  payload: object;
}
export interface DashboardDSearchSuccess {
  type: typeof DASHBOARD_D_SEARCH_SUCCESS;
  searchValue: string;
}

export type DashboardDispatchTypes =
  | DashboardAllSuccess
  | DashboardCreateSuccess
  | DashboardUpdateSuccess
  | TabChange
  //======================
  | DashboardLoading
  | DashboardFail
  | DashboardSelectedSuccess
  | DashboardSearchSuccess
  | DashboardLineSuccess
  | DashboardPieSuccess
  //======================
  | DashboardDLoading
  | DashboardDFail
  | DashboardDSelectedSuccess
  | DashboardDSearchSuccess;
