import React, { FunctionComponent, useState } from 'react';
import { Usa, Italia, Mexico, Brazil, CheckIcon } from '../../assets/svg/index';
import { useTranslation } from 'react-i18next';

interface LanguageProps {
  value: any;
}

const LanguageItem: FunctionComponent<LanguageProps> = ({ value }: any) => {
  const { t, i18n } = useTranslation();

  const getFlag = () => {
    if (value === 'es') return <Mexico></Mexico>;
    if (value === 'en') return <Usa></Usa>;
    if (value === 'it') return <Italia></Italia>;
    if (value === 'pt') return <Brazil></Brazil>;
  };
  const getText = () => {
    if (value === 'es') return t('language.es');
    if (value === 'en') return t('language.en');
    if (value === 'it') return t('language.es');
    else return 'language.es';
  };
  const checked = () => {
    if (t('language') === value) return <CheckIcon></CheckIcon>;
  };

  const changeLocalLanguage = (e: string) => {
    localStorage.setItem('language', e);
  };

  const [visible, setVisible] = useState(false);

  return (
    <div
      className="language-dropdown-box"
      onClick={() => {
        setVisible(!visible);
        i18n.changeLanguage(value);
        changeLocalLanguage(value);
      }}
    >
      <div className="name-language">
        <div className="text-icon">
          <div className="icon-language">{getFlag()}</div>
          {getText()}
        </div>

        {checked()}
      </div>
    </div>
  );
};

export default LanguageItem;
