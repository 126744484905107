import React from 'react';
import { useDispatch } from 'react-redux';
import StaticModal from '../../../../dialogs/StaticModal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { deleteLicenseUser } from '../../../../redux/campaigns/campaignsActions';
import { Campaign, City } from '../../../../redux/campaigns/campaignsTypes';
//import { useHistory } from 'react-router-dom';

interface AddModalProps {
  modalShow: any;
  setModalShow: any;
  successFun: any;
  licenseSelected?: Campaign;
  cityLicense?: City;
  toDelete: any;
  users: any;
}

export const DeleteAdminModalComponent: React.FunctionComponent<AddModalProps> = ({
  modalShow,
  setModalShow,
  successFun,
  licenseSelected,
  cityLicense,
  toDelete,
  users,
}: AddModalProps) => {
  // const { t } = useTranslation();
  const dispatch = useDispatch();
  // const redirect = useHistory();

  const cancelFun = () => {
    setModalShow(false);
  };

  const submitFun = () => {
    successFun();
    setModalShow(false);
  };

  const getNames = () => {
    let value = ''; // eslint-disable-next-line
    const u = users.map((e: any) => {
      if (toDelete.indexOf(e._id) !== -1) {
        return e;
      }
    });
    u.forEach((d: any) => {
      if (d !== undefined)
        if (value === '') {
          value = d.firstName;
        } else {
          value = value + ', ' + d.firstName;
        }
    });
    return value;
  };

  const buildParamDelete = (toDelete: Array<any>) => {
    let requestObject;
    requestObject = {
      toDelete: toDelete,
    };
    return requestObject;
  };

  const submitDelete = () => {
    const params = buildParamDelete(toDelete);
    dispatch(deleteLicenseUser(params, submitFun));
  };

  return (
    <StaticModal
      show={modalShow}
      title={'Delete City License'}
      closeText={'Cancel'}
      confirmText={'Delete'}
      onHide={cancelFun}
      submitFun={submitDelete}
      validBtn={false}
      size="sm"
      sizeClass="delete-license-modal"
    >
      <Row noGutters className="modal-text-row">
        <Col xs={12} className="description-col">
          <div className="text-description">
            Are you sure you want to delete <strong>{getNames()}</strong> from
            this city?
          </div>
        </Col>
      </Row>
    </StaticModal>
  );
};

export default DeleteAdminModalComponent;
