import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { Router, Switch, Redirect } from 'react-router-dom';
import GuestRoute from './GuestRoute';
import PrivateRoute from './PrivateRoute';

// Router stuff
import {
  GUEST_LOGIN,
  GUEST_FORGOT,
  GUEST_ACTION,
  GUEST_PASSWORD_CONFIRM,
  PRIVATE_DASHBOARD,
  PRIVATE_ROUTES,
  PRIVATE_VOTERS,
  PRIVATE_TRACKING,
  PRIVATE_TEAMS,
  PRIVATE_AUDITING,
  PRIVATE_POLLWATCHERS,
  PRIVATE_LAWYERS,
  PRIVATE_POLLINGSTATIONS,
  PRIVATE_POLLINGSTATIONS_ACTIVE,
  PRIVATE_BINGO,
  PRIVATE_PROBLEMS,
  PRIVATE_HISTORY,
  PRIVATE_POLLS,
  PRIVATE_MAP,
  PRIVATE_MOBILIZATION,
  PRIVATE_TRAINING_LIST,
  PRIVATE_POLLINGSTATIONS_SETTINGS,
  PRIVATE_VOTERS_LIST,
  PRIVATE_VOTERS_VIEW,
  SUPER_CAMPAIGNS,
  SUPER_CAMPAIGNS_LICENSE_CITY,
  SUPER_SHARED_PARTIES,
  SUPER_SHARED_COUNTRIES,
  SUPER_SHARED_COUNTRIES_CREATION,
  SUPER_SHARED_COUNTRIES_CREATION_SELECTED,
  SUPER_SHARED_GENERAL,
  PRIVATE_TRAINING_EDIT,
  PRIVATE_TRAINING_CREATE,
  PRIVATE_DASHBOARD_DIAD,
} from './paths';
import Index from '../components/Index';
import LoginView from '../pages/login/Login';
import { connect } from 'react-redux';
import ForgotView from '../pages/login/Forgot';
import Action from '../pages/login/Action';
import PasswordConfirmationView from '../pages/login/PasswordConfirmation';
import LayoutComponent from '../components/LayoutComponent';
import history from './history';
import Dashboard from '../pages/dashboard';
import AuditoryTerritorial from '../pages/auditoryTerritorial';
import VotersTerritorial from '../pages/votersTerritorial';
import VotersTerritorialView from '../pages/votersTerritorial/components/VoterView';
import TeamTerritorial from '../pages/teamTerritorial';
import VotersBoxDDay from '../pages/voterBoxDDay';
import VotersBoxSettings from '../pages/voterBoxSettings';
import VotersBoxElectoral from '../pages/voterBoxElectoral';
import LawyersElectoral from '../pages/lawyersElectoral';
import RcRgElectoral from '../pages/RcRgElectoral';
import VotersListSettings from '../pages/votersListSettings';
import RoutesTerritorial from '../pages/routesTerritorial';
import TrackingTerritorial from '../pages/trakingTerritorial';
import ProblemsDDay from '../pages/problemsDDay';
import TrainingsListSettings from '../pages/trainingsSettings/list';
import LayoutSComponent from '../superAdmin/components/LayoutSComponent';
import SuperRoute from './SuperRoute';
import Campaigns from '../superAdmin/pages/campaings';
import LicenseView from '../superAdmin/pages/campaings/components/licenseView';
import Parties from '../superAdmin/pages/shared/parties';
import Countries from '../superAdmin/pages/shared/countries';
import SelectionMap from '../superAdmin/pages/shared/countries/components/selectionMap';
import SelectedList from '../superAdmin/pages/shared/countries/components/selectedList';
import GeneralSettings from '../superAdmin/pages/shared/general';
import TrainingSettings from '../pages/trainingsSettings';
import Bingo from '../pages/bingoDiaD';
//import DashboardDiaD from '../pages/dashboardDiaD';

const SwitchRoute: React.FunctionComponent = ({
  authenticated,
  superAdmin,
}: any) => (
  <>
    <Router history={history}>
      {/* SUPER_ADMIN VIEW */}
      {superAdmin && authenticated && (
        <LayoutSComponent role={1} history={history}>
          <Switch>
            <SuperRoute
              exact
              path={SUPER_SHARED_COUNTRIES}
              authenticated={authenticated}
              component={Countries}
              allowByRole={true}
            />
            <SuperRoute
              exact
              path={SUPER_SHARED_COUNTRIES_CREATION}
              authenticated={authenticated}
              component={SelectionMap}
              allowByRole={true}
            />
            <SuperRoute
              exact
              path={SUPER_SHARED_COUNTRIES_CREATION_SELECTED}
              authenticated={authenticated}
              component={SelectedList}
              allowByRole={true}
            />
            <SuperRoute
              exact
              path={SUPER_SHARED_PARTIES}
              authenticated={authenticated}
              component={Parties}
              allowByRole={true}
            />{' '}
            <SuperRoute
              exact
              path={SUPER_SHARED_GENERAL}
              authenticated={authenticated}
              component={GeneralSettings}
              allowByRole={true}
            />
            <SuperRoute
              exact
              path={SUPER_CAMPAIGNS}
              authenticated={authenticated}
              component={Campaigns}
              allowByRole={true}
            />
            <SuperRoute
              exact
              path={SUPER_CAMPAIGNS_LICENSE_CITY}
              authenticated={authenticated}
              component={LicenseView}
              allowByRole={true}
            />
            <Redirect to={SUPER_CAMPAIGNS} />
          </Switch>
        </LayoutSComponent>
      )}
      {/* NORMAL USERS */}
      {!superAdmin && authenticated && (
        <LayoutComponent role={1} history={history}>
          <Switch>
            <PrivateRoute
              exact
              path={PRIVATE_DASHBOARD}
              component={Dashboard}
              allowByRole={true}
            />
            <PrivateRoute
              exact
              path={PRIVATE_VOTERS}
              component={VotersTerritorial}
              allowByRole={true}
            />
            <PrivateRoute
              exact
              path={PRIVATE_VOTERS_VIEW}
              component={VotersTerritorialView}
              allowByRole={true}
            />
            <PrivateRoute
              exact
              path={PRIVATE_ROUTES}
              component={RoutesTerritorial}
              allowByRole={true}
            />
            <PrivateRoute
              exact
              path={PRIVATE_TRACKING}
              component={TrackingTerritorial}
              allowByRole={true}
            />
            <PrivateRoute
              exact
              path={PRIVATE_TEAMS}
              component={TeamTerritorial}
              allowByRole={true}
            />
            <PrivateRoute
              exact
              path={PRIVATE_AUDITING}
              component={AuditoryTerritorial}
              allowByRole={true}
            />
            <PrivateRoute
              exact
              path={PRIVATE_POLLWATCHERS}
              component={RcRgElectoral}
              allowByRole={true}
            />
            <PrivateRoute
              exact
              path={PRIVATE_LAWYERS}
              component={LawyersElectoral}
              allowByRole={true}
            />
            <PrivateRoute
              exact
              path={PRIVATE_POLLINGSTATIONS}
              component={VotersBoxElectoral}
              allowByRole={true}
            />{' '}
            {/* <PrivateRoute
              exact
              path={PRIVATE_DASHBOARD_DIAD}
              component={DashboardDiaD}
              allowByRole={true}
            /> */}
            <PrivateRoute
              exact
              path={PRIVATE_POLLINGSTATIONS_ACTIVE}
              component={VotersBoxDDay}
              allowByRole={true}
            />
            <PrivateRoute
              exact
              path={PRIVATE_BINGO}
              component={Bingo}
              allowByRole={true}
            />
            <PrivateRoute
              exact
              path={PRIVATE_PROBLEMS}
              component={ProblemsDDay}
              allowByRole={true}
            />
            {/*<PrivateRoute
              exact
              path={PRIVATE_HISTORY}
              component={Index}
              allowByRole={true}
            />
            <PrivateRoute
              exact
              path={PRIVATE_POLLS}
              component={Index}
              allowByRole={true}
            />
            <PrivateRoute
              exact
              path={PRIVATE_MAP}
              component={Index}
              allowByRole={true}
            />
            <PrivateRoute
              exact
              path={PRIVATE_MOBILIZATION}
              component={Index}
              allowByRole={true}
            />*/}
            <PrivateRoute
              exact
              path={PRIVATE_POLLINGSTATIONS_SETTINGS}
              component={VotersBoxSettings}
              allowByRole={true}
            />
            {/*<PrivateRoute
              exact
              path={PRIVATE_COALITIONS}
              component={Index}
              allowByRole={true}
            />*/}
            <PrivateRoute
              exact
              path={PRIVATE_VOTERS_LIST}
              component={VotersListSettings}
              allowByRole={true}
            />
            <PrivateRoute
              exact
              path={PRIVATE_TRAINING_LIST}
              component={TrainingsListSettings}
              allowByRole={true}
            />
            <PrivateRoute
              exact
              path={PRIVATE_TRAINING_CREATE}
              component={TrainingSettings}
              allowByRole={true}
            />
            <PrivateRoute
              exact
              path={PRIVATE_TRAINING_EDIT}
              component={TrainingSettings}
              allowByRole={true}
            />
            {/*<PrivateRoute
              exact
              path={PRIVATE_USERS}
              component={UsersSettings}
              allowByRole={true}
            />
            <PrivateRoute
              exact
              path={PRIVATE_ROLES}
              component={RolesSettings}
              allowByRole={true}
            />*/}
            <PrivateRoute
              exact
              path={GUEST_ACTION}
              component={Action}
              allowByRole={true}
            />
            <Redirect to={PRIVATE_DASHBOARD} />
          </Switch>
        </LayoutComponent>
      )}

      {/* LOGINS NO AUTH */}
      {!authenticated && !superAdmin && (
        <Switch>
          <GuestRoute exact path={GUEST_LOGIN} component={LoginView} />
          <GuestRoute exact path={GUEST_FORGOT} component={ForgotView} />
          <GuestRoute exact path={GUEST_ACTION} component={Action} />
          <GuestRoute
            exact
            path={GUEST_PASSWORD_CONFIRM}
            component={PasswordConfirmationView}
          />
          <Redirect to={GUEST_LOGIN} />
        </Switch>
      )}
    </Router>
  </>
);

const mapStateToProps = (state: any) => ({
  authenticated: state.user.authenticated,
  superAdmin: state.user.superAdmin,
});

export default connect(mapStateToProps)(SwitchRoute);
