import React, { useEffect } from 'react';
import './style.scss';
import SelectorComponent from './sub-component/selector';
import { useSelector, useDispatch } from 'react-redux';
import { RootStore } from '../redux/Store';
import {
  getDistrict,
  getSection,
} from '../redux/core/CoreActions';
import Button from 'react-bootstrap/Button';

interface TopBarProps {
  title: string;
  setSelected: any;
  getData: any;
  showSelects: boolean;
  showCityTitle?: boolean;
  selectsToShow: number;
  customTitle?: any;
  children?: any;
  showBtn?: any;
  textButton?: string;
  buttonFun?: any;
  defaultSelector?: any;
  showBack?: boolean;
  setshowBack?: any;
}

const TopBarSelectors: React.FunctionComponent<TopBarProps> = ({
  title,
  textButton,
  buttonFun,
  showSelects,
  selectsToShow,
  children,
  showCityTitle = true,
  setSelected,
  getData,
  showBtn,
  customTitle,
  defaultSelector,
  showBack = false,
  setshowBack,
}: any) => {
  const district = useSelector((globalState: RootStore) => {
    return globalState.core.district;
  });
  const section = useSelector((globalState: RootStore) => {
    return globalState.core.section;
  });
  const dispatch = useDispatch();
  const [loadData, setLoadData] = React.useState(false);

  const citySelected = useSelector((globalState: RootStore) => {
    return globalState.core.globalMunicipalitySelected;
  });

  useEffect(() => {
    if (!loadData && showSelects) {
      getDist(citySelected?._id || '');
      setLoadData(true);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadData]);

  useEffect(() => {
    if (showSelects) {
      getDist(citySelected?._id || '');
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [citySelected]);

  // const getMuni = (id: string) => {
  //   dispatch(getMunicipality(id, getDist));
  // };
  const getDist = (id: string) => {
    setSelected(id, 'municipality');
    if (selectsToShow > 1) dispatch(getDistrict(id, getSect));
    else getData(id);
  };
  const getSect = (id: string) => {
    setSelected(id, 'district');
    if (selectsToShow > 2) dispatch(getSection(id, getVoBo));
    else getData(id);
  };
  const getVoBo = (id: string) => {
    setSelected(id, 'section');
    getData(id);
  };
  return (
    <div className="top-bar-container">
      {showSelects ? (
        <div className="top-bar-selects">
          {selectsToShow > 1 && (
            <SelectorComponent
              title="Distrito"
              defaultSelector={''}
              options={district}
              horizontal={true}
              fun={(value: string) => {
                getSect(value);
              }}
              additionalClass="selector-padding"
            ></SelectorComponent>
          )}
          {selectsToShow > 2 && (
            <SelectorComponent
              title="Seccion"
              defaultSelector={''}
              options={section}
              horizontal={true}
              fun={(value: string) => {
                getVoBo(value);
              }}
            ></SelectorComponent>
          )}
          {children}
        </div>
      ) : customTitle ? (
        customTitle
      ) : (
        <div className="col-title">
          <h2>{title}</h2>
        </div>
      )}
      {showBtn && (
        <Button variant="primary" onClick={buttonFun}>
          {textButton}
        </Button>
      )}
    </div>
  );
};

export default TopBarSelectors;
