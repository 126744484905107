import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { RootStore } from '../../../redux/Store';
import * as netWorking from '../../../redux/netWorking';

import {
  postListCapturistTeamsByCity,
  postGetCapturistTeamDataTotal,
  postCapturistTeamDataByCoordinator,
  getCoordinatorViewByIdTeam,
  postGetCampaignDataTotal,
  postBreadcumByCoordinator,
} from '../../../redux/teams/teamsActions';
import { TEAMS_COORDINATOR_SUCCESS_ONE } from '../../../redux/teams/teamsTypes';
import { ROLE, XLS_TYPE } from '../../../utils/utils';

import TableComponent, {
  HEADER,
  HEADER_TYPE,
  FIELD_TYPE,
} from '../../../components/Table';
import PaginationRecords from '../../../components/PaginationRecords';
import ModalAddCapturist from './addCoordinatorCapturist';
import ModalDeleteCapturist from './deleteCapturist';
import ModalAddXLS from './modalXLS';
import ModalAdministrer from './administrerModal';
import VolunteerSectionsComponent from './volunteerSections';
import LoadingModal from '../../../dialogs/loadingModal';
import CodeGenerationDialog from './codeGenerationDialog';
import ModalTipUpload from './tipUploadModal';

const List: React.FunctionComponent = () => {
  const citySelected: any = useSelector((globalState: RootStore) => {
    return globalState.core.globalMunicipalitySelected;
  });

  const stateSelected = useSelector((globalState: RootStore) => {
    return globalState.core.globalStateSelected;
  });

  const loading = useSelector(
    (globalState: RootStore) => globalState.teams.loading,
  );
  const coordinator: any = useSelector(
    (globalState: RootStore) => globalState.teams.coordinator,
  );
  const navigationPath: any = useSelector(
    (globalState: RootStore) => globalState.teams.navigationPath,
  );
  const breadcumCall: any = useSelector(
    (globalState: RootStore) => globalState.teams.breadcumCall,
  );
  const teamData: Array<any> = useSelector(
    (globalState: RootStore) => globalState.teams.teamData,
  );
  const totalTeamData: number = useSelector(
    (globalState: RootStore) => globalState.teams.totalTeamData,
  );

  const search = useSelector(
    (globalState: RootStore) => globalState.teams.search,
  );
  const sort: any = useSelector(
    (globalState: RootStore) => globalState.teams.sort,
  );
  const records: any = useSelector(
    (globalState: RootStore) => globalState.teams.records,
  );
  const page: any = useSelector(
    (globalState: RootStore) => globalState.teams.page,
  );
  //======================================================================================
  const totalPage = useSelector(
    (globalState: RootStore) => globalState.teams.totalPage,
  );

  const [modalShowAdd, setModalShowAdd] = useState(false);
  const [modalDeleteShow, setModalDeleteShow] = useState(false);

  const [tableData, setTableData] = useState(Array);
  const [fieldOrder, setFieldOrder] = useState(sort[1] ? sort[1] : 'asc');
  const [municipalityId, setMunicipalityId] = useState(String);

  const [modalShowAdministrer, setModalShowAdministrer] = useState(false);
  const [modalShowXls, setModalShowXls] = useState(false);
  const [modalShowCodeGeneration, setModalShowCodeGeneration] = useState(false);
  const [modalShowTipUpload, setModalShowTipUpload] = useState(false);
  const [edit, setEdit] = useState(Object);
  const [toDelete, setToDelete] = useState(Array);
  const [toResetPin, setToResetPin] = useState('');

  let defaultValue: any;

  const [titleAdd, setTitleAdd] = useState(defaultValue);
  const [addRole, setAddRole] = useState(defaultValue);
  const [addLvl, setAddLvl] = useState(defaultValue);
  const [selectedId, setSelectedId] = useState(defaultValue);
  const [selectedRole, setSelectedRole] = useState(defaultValue);
  const [selectedLevel, setSelectedLevel] = useState(defaultValue);
  const [selectedTeam, setSelectedTeam] = useState(defaultValue);
  const [isTeam, setIsTeam] = useState(defaultValue);
  const [downloadXls, setDownloadXls] = useState(defaultValue);
  const [levelList, setLevelList] = useState(defaultValue);
  const [subordinateLevel, setSubordinateLevel] = useState(defaultValue);
  const [showOptionsPerLevel, setShowOptionsPerLevel] = useState(false);
  const [isSubLevel, setIsSubLevel] = useState(false);
  const [topLevel, setTopLevel] = useState(Object);
  const [topTitle, setTopTitle] = useState('');

  console.log('selectedRole', selectedRole);

  let defaultUser: any;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  let teamLocalStorage = JSON.parse(localStorage.team);

  const getLevelValues = (reset = false) => {
    let selectedId =
      navigationPath.length < 1 ? localStorage?.id : coordinator._id;
    let selectedRole =
      navigationPath.length < 1 ? ROLE.ADMIN_BOSS : coordinator.role;
    let selectedLevel =
      navigationPath.length < 1 ? localStorage?.level : coordinator.level;
    let selectedTeam =
      navigationPath.length < 1 ? teamLocalStorage?._id : coordinator.teamId;
    let lvlLength = citySelected.capturistUserLevels.length;
    let usedLvl;
    let qtySlice;
    let levelArray;
    let highLevel;

    if (selectedRole === ROLE.SUPER_ADMIN || selectedRole === ROLE.ADMIN_BOSS) {
      usedLvl = citySelected.capturistUserLevels[0];
      setDownloadXls(XLS_TYPE.CAPTURIST_BOSS_XLS_TYPE);
      setIsTeam(false);
      setLevelList(citySelected.capturistUserLevels);
    } else if (selectedRole === ROLE.CAPTURIST_BOSS) {
      let lvlIndex = parseInt(lvlLength) - 1;
      usedLvl = citySelected.capturistUserLevels[lvlIndex];
      highLevel = citySelected.capturistUserLevels[lvlIndex - 1];
      setDownloadXls(XLS_TYPE.CAPTURIST_XLS_TYPE);
      setIsTeam(true);
      setLevelList([]);
    } else {
      usedLvl = citySelected.capturistUserLevels.find(
        (c: any) => c.level === parseInt(selectedLevel) - 1,
      );
      highLevel = citySelected.capturistUserLevels.find(
        (c: any) => c.level === parseInt(selectedLevel),
      );
      setDownloadXls(XLS_TYPE.CAPTURIST_BOSS_XLS_TYPE);
      setIsTeam(true);

      qtySlice = parseInt(lvlLength) - parseInt(selectedLevel) + 1;
      levelArray = citySelected.capturistUserLevels.slice(qtySlice);
      setLevelList(levelArray);
    }

    if (
      localStorage?.role === ROLE.ADMIN_BOSS ||
      localStorage?.role === ROLE.SUPER_ADMIN
    ) {
      setShowOptionsPerLevel(true);
      setIsSubLevel(true);
    } else {
      if (coordinator._id) {
        if (parseInt(localStorage?.level) < coordinator.level) {
          setShowOptionsPerLevel(false);
          setIsSubLevel(false);
        } else if (parseInt(localStorage?.level) === coordinator.level) {
          if (localStorage?.id === coordinator._id) {
            setShowOptionsPerLevel(true);
            setIsSubLevel(true);
          } else {
            setShowOptionsPerLevel(false);
            setIsSubLevel(false);
          }
        } else {
          if (isSubLevel === true) {
            setShowOptionsPerLevel(true);
          } else {
            setShowOptionsPerLevel(false);
          }
        }
      } else {
        setShowOptionsPerLevel(false);
        setIsSubLevel(false);
      }
    }

    setTitleAdd(usedLvl[i18next.language]);
    setAddRole(usedLvl.role);
    setAddLvl(usedLvl.level);
    setSubordinateLevel(usedLvl);

    setSelectedId(selectedId);
    setSelectedRole(selectedRole);
    setSelectedLevel(selectedLevel);
    setSelectedTeam(selectedTeam);
    setTopTitle(highLevel ? highLevel[i18next.language] : '');
    setTopLevel(highLevel);

    const params = buildParamSearchTeamDataByCoordinator(
      selectedId,
      selectedRole,
      usedLvl.level,
      !reset ? search : '',
      !reset ? records : 10,
      fieldOrder,
      !reset ? page : 1,
    );
    const params2 = buildParamsCapturistTeamsByCity(selectedLevel);

    if (
      localStorage?.role === ROLE.ADMIN_BOSS ||
      localStorage?.role === ROLE.SUPER_ADMIN
    ) {
      callAllData(params, params2);
    } else {
      if (breadcumCall) {
        callAllData(params, params2);
      }
    }
  };

  const headerTerritorialTeam: any = {
    totalTeamData: totalTeamData || 0,
    labelPlural: titleAdd,

    uploadButtonAppear: showOptionsPerLevel,
    isUploadButtonDisabled: false,
    uploadFun: () => {
      setModalShowXls(true);
    },

    downloadButtonAppear: showOptionsPerLevel,
    downloadFun: () => {
      netWorking.downloadXls(downloadXls, citySelected?.name || '');
      setModalShowTipUpload(true);
    },

    tipButtonAppear:
      showOptionsPerLevel && selectedRole !== ROLE.CAPTURIST_BOSS
        ? true
        : false,
    isTipButtonDisabled: false,
    tipFun: () => {
      setModalShowTipUpload(true);
    },

    firstButtonAppear:
      navigationPath.length > 0 && showOptionsPerLevel ? true : false,
    isFirstButtonDisabled: false,
    funTextAdmin: t('territorial.volunteer.table.transfer'),
    funAdmin: () => {
      setModalShowAdministrer(true);
    },

    secondButtonAppear: showOptionsPerLevel,
    isSecondButtonDisabled: false,
    fun: () => {
      setEdit(defaultUser);
      setModalShowAdd(() => !modalShowAdd);
    },
  };

  const openEdit = (_id: any) => {
    const user = teamData?.find((value: any) => value._id === _id);
    if (user) {
      setEdit(user);
      setModalShowAdd(true);
    }
  };

  const openDelete = (deleteArray: any) => {
    setToDelete(deleteArray);
    setModalDeleteShow(true);
  };

  const callAllData = (param1: any, param2: any) => {
    dispatch(postCapturistTeamDataByCoordinator(param1));
    dispatch(postGetCapturistTeamDataTotal(param1));

    dispatch(postListCapturistTeamsByCity(param2));
    dispatch(postGetCampaignDataTotal());
  };

  const transformData = () => {
    let newCapturists: any = [];
    let dataArray: any = teamData;
    dataArray.forEach((capturist: any) => {
      let totalCatches = 0;
      let totalVoters = 0;
      let totalGoal = 0;
      capturist.areas?.forEach((data: any) => {
        if (data.area && data.dataOfArea && data.dataOfArea.length > 0) {
          const captured =
            data.dataOfArea[0].toAudit + data.dataOfArea[0].audited;
          totalCatches += captured;

          const extraGoal = data.area.extraGoal ? data.area.extraGoal : 0;
          const total = extraGoal + data.dataOfArea[0].total;
          totalVoters += total;

          totalGoal += extraGoal;
        }
      });
      let newCapturist = {
        _id: capturist._id,
        role: capturist.role,
        level: capturist.level,
        nameMap: {
          name: `${capturist.firstName} ${capturist.lastName}`,
        },
        email: capturist.email,
        phone: capturist.cellphone,
        goal: totalGoal,
        routes: totalVoters,
        members: capturist.members.length,
        progress: ((totalCatches * 100) / totalVoters || 0).toFixed(0),
        lastSync: capturist.lastSync ? capturist.lastSync : '',
        permission:
          isSubLevel || capturist._id === localStorage.id ? true : false,
      };
      newCapturists.push(newCapturist);
    });
    setTableData(newCapturists);
  };

  const goToView = (_id: string, level: string) => {
    let subLevelValue = false;

    if (
      localStorage?.role === ROLE.ADMIN_BOSS ||
      localStorage?.role === ROLE.SUPER_ADMIN
    ) {
      subLevelValue = true;
    } else {
      if (parseInt(localStorage?.level) < parseInt(level)) {
        subLevelValue = false;
      } else if (parseInt(localStorage?.level) === parseInt(level)) {
        if (localStorage?.id === _id) {
          subLevelValue = true;
        } else {
          subLevelValue = false;
        }
      } else if (parseInt(localStorage?.level) > parseInt(level)) {
        if (isSubLevel === true) {
          subLevelValue = true;
        } else {
          subLevelValue = false;
        }
      }
    }

    dispatch(getCoordinatorViewByIdTeam(_id, '-1', subLevelValue));
  };

  const getPin = (_id: string) => {
    setToResetPin(_id);
    setModalShowCodeGeneration(true);
  };

  const getMainTitle = () => {
    if (edit === undefined) {
      return t('settings.trainings.form.add') + ' ' + titleAdd;
    } else {
      return t('settings.trainings.form.edit') + ' ' + titleAdd;
    }
  };

  const searchByValue = (reset = false) => {
    const params = buildParamSearchTeamDataByCoordinator(
      selectedId,
      selectedRole,
      addLvl,
      !reset ? search : '',
      !reset ? records : 10,
      fieldOrder,
      !reset ? page : 1,
    );
    dispatch(postCapturistTeamDataByCoordinator(params));
    dispatch(postGetCapturistTeamDataTotal(params));
    dispatch(postGetCampaignDataTotal());
  };

  const buildParamSearchTeamDataByCoordinator = (
    coordinatorId: string,
    coordinatorRole: string,
    teamLevel: number,
    search: string,
    recordsNum: number,
    order: string,
    pageNum: number,
  ) => {
    let requestObject;
    requestObject = {
      coordinatorId: coordinatorId,
      coordinatorRole: coordinatorRole,
      teamLevel: teamLevel,
      municipalityId: citySelected._id,
      search: search.length >= 3 ? search : '',
      records: recordsNum,
      order: order,
      page: pageNum,
    };
    return requestObject;
  };

  const buildParamsCapturistTeamsByCity = (teamLevel: number) => {
    let requestObject;
    requestObject = {
      municipalityId: citySelected._id,
      teamLevel: teamLevel,
    };
    return requestObject;
  };

  useEffect(() => {
    if (municipalityId) {
      if (municipalityId !== citySelected._id) {
        dispatch({
          type: TEAMS_COORDINATOR_SUCCESS_ONE,
          payload: {},
          follow: '0',
        });
      }
      getLevelValues(true);
    } else {
      if (
        localStorage?.role !== ROLE.ADMIN_BOSS &&
        localStorage?.role !== ROLE.SUPER_ADMIN &&
        !breadcumCall
      ) {
        let lvlQty = citySelected.capturistUserLevels
          ? citySelected.capturistUserLevels.length -
            parseInt(localStorage?.level)
          : 0;
        dispatch(postBreadcumByCoordinator(lvlQty, getLevelValues(false)));
      } else {
        getLevelValues(false);
      }
    }

    if (citySelected) {
      setMunicipalityId(citySelected._id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [citySelected]);

  useEffect(() => {
    if (municipalityId) {
      setToDelete([]);
      if (
        localStorage?.role === ROLE.ADMIN_BOSS ||
        localStorage?.role === ROLE.SUPER_ADMIN
      ) {
        getLevelValues(true);
      } else {
        if (breadcumCall) {
          getLevelValues(true);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigationPath]);

  useEffect(() => {
    if (teamData) {
      transformData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamData]);

  useEffect(() => {
    if (municipalityId && (search.length >= 3 || search.length <= 0)) {
      if (
        localStorage?.role === ROLE.ADMIN_BOSS ||
        localStorage?.role === ROLE.SUPER_ADMIN
      ) {
        searchByValue();
      } else {
        if (breadcumCall) {
          searchByValue();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    if (municipalityId) {
      if (
        localStorage?.role === ROLE.ADMIN_BOSS ||
        localStorage?.role === ROLE.SUPER_ADMIN
      ) {
        searchByValue();
      } else {
        if (breadcumCall) {
          searchByValue();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldOrder]);

  useEffect(() => {
    if (subordinateLevel) {
      setTitleAdd(subordinateLevel[i18next.language]);
      setTopTitle(topLevel ? topLevel[i18next.language] : '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18next.language]);

  return (
    <Fragment>
      {/* <TopSeparator></TopSeparator> */}
      <div className="team-territorial-list">
        <div className="body-container body-container-without">
          <div className="team-container">
            <TableComponent
              showActions={showOptionsPerLevel}
              selectedRows={toDelete}
              selectRows={setToDelete}
              selectMessage={true}
              header={headerTerritorialTeam}
              headerType={HEADER_TYPE.TERRITORIAL_TEAM_NEW}
              headers={[
                {
                  type: HEADER.FILTER,
                  name: t('territorial.volunteer.table.name'),
                  fun: () => {
                    let newOrder = fieldOrder === 'asc' ? 'desc' : 'asc';
                    setFieldOrder(newOrder);
                  },
                },
                {
                  type: HEADER.TEXT,
                  name: t('territorial.volunteer.table.cellphone'),
                },
                {
                  type: HEADER.TEXT,
                  name: t('territorial.volunteer.table.goal'),
                },
                {
                  type: HEADER.TEXT,
                  name: t('territorial.volunteer.table.people'),
                },
                {
                  type: HEADER.TEXT,
                  name: t('territorial.volunteer.table.progress'),
                },
                {
                  type: HEADER.TEXT,
                  name: t('territorial.volunteer.table.list'),
                },
                {
                  type: HEADER.TEXT,
                  name: t('territorial.volunteer.table.lastSync'),
                },
                {
                  type: HEADER.TEXT,
                  name: t('territorial.volunteer.table.pin'),
                },
              ]}
              fieldTypes={[
                { type: FIELD_TYPE.IMAGE_TEXT_TWO_INITIALS_CAPTURISTS },
                { type: FIELD_TYPE.TEXT },
                { type: FIELD_TYPE.TEXT_LINK_TEAMS },
                { type: FIELD_TYPE.TEXT_LINK_TEAMS },
                { type: FIELD_TYPE.PERCENTAJE_PROGRESS },
                {
                  type: FIELD_TYPE.TEXT_LINK,
                  fun: (value: any, level: any) => {
                    goToView(value, level);
                  },
                },
                { type: FIELD_TYPE.TEXT_SYNC },
                {
                  type: FIELD_TYPE.BUTTON_PIN,
                  fun: (value: any) => {
                    getPin(value);
                  },
                },
              ]}
              toEdit={(value: any) => {
                openEdit(value);
              }}
              toDelete={(value: any) => {
                openDelete(value);
              }}
              search={() => {}}
              regExp={undefined}
              data={tableData}
              nameOfData={[
                'nameMap',
                'phone',
                'goal',
                'routes',
                'progress',
                'email',
                'lastSync',
                'role',
              ]}
              classBody={[
                'text-align-left selector-cell full-name-container',
                'text-align-left cellphone-container',
                'text-align-center home-container',
                'text-align-center people-container',
                'text-align-left progress-container',
                selectedRole === ROLE.CAPTURIST_BOSS
                  ? 'text-align-center list-container-hidden'
                  : 'text-align-center list-container',
                selectedRole === ROLE.CAPTURIST_BOSS
                  ? 'text-align-center last-sync'
                  : 'text-align-center last-sync-hidden',
                showOptionsPerLevel
                  ? 'text-align-center pin-container'
                  : 'text-align-center pin-container-hidden',
              ]}
            ></TableComponent>
            <PaginationRecords
              totalPages={totalPage}
              value={page}
              records={records}
              onChange={(pagina: string, record: string) => {
                let rec = record === '' ? '10' : record;
                let pag;
                if (records !== record) {
                  pag = '1';
                } else {
                  pag = pagina === '' || pagina === '0' ? '1' : pagina;
                }
                const params = buildParamSearchTeamDataByCoordinator(
                  selectedId,
                  selectedRole,
                  addLvl,
                  search,
                  +rec,
                  fieldOrder,
                  +pag,
                );
                dispatch(postCapturistTeamDataByCoordinator(params));
                dispatch(postGetCapturistTeamDataTotal(params));
              }}
            ></PaginationRecords>
          </div>
          <VolunteerSectionsComponent
            selectedRows={toDelete}
            teamData={teamData}
          ></VolunteerSectionsComponent>
        </div>
      </div>
      {modalShowXls && (
        <ModalAddXLS
          modalShow={modalShowXls}
          setModalShow={setModalShowXls}
          type={downloadXls}
          teamId={selectedTeam}
          states={[stateSelected?._id]}
          cities={[citySelected?._id]}
          cityLicenseId={citySelected?._id}
          role={addRole}
          levelList={levelList}
          showConfirm={false}
          showModalFooterContinue={true}
          successFun={(res: any) => {
            searchByValue();
            setToDelete([]);
          }}
        ></ModalAddXLS>
      )}
      {modalShowAdd && (
        <ModalAddCapturist
          mainTitle={getMainTitle()}
          modalShow={modalShowAdd}
          successFun={() => {
            searchByValue();
          }}
          setModalShow={setModalShowAdd}
          edit={edit}
          type={addRole}
          level={addLvl}
          isTeam={isTeam}
          selectedTeam={selectedTeam}
          getPin={getPin}
        ></ModalAddCapturist>
      )}
      {modalDeleteShow && (
        <ModalDeleteCapturist
          modalShow={modalDeleteShow}
          successFun={() => {
            searchByValue();
            setToDelete([]);
          }}
          toDelete={toDelete}
          rcrg={teamData}
          isTeam={isTeam}
          setModalShow={setModalDeleteShow}
          deleteMessage={titleAdd}
        ></ModalDeleteCapturist>
      )}
      {modalShowAdministrer && (
        <ModalAdministrer
          modalShow={modalShowAdministrer}
          selectedTeam={selectedTeam}
          selectedMunicipality={municipalityId}
          teamType={selectedLevel}
          userRole={addLvl}
          topTitle={topTitle}
          successFun={() => {}}
          setModalShow={setModalShowAdministrer}
        ></ModalAdministrer>
      )}
      {modalShowTipUpload && (
        <ModalTipUpload
          modalShow={modalShowTipUpload}
          setModalShow={setModalShowTipUpload}
          levelList={levelList}
        ></ModalTipUpload>
      )}

      {loading && (
        <LoadingModal show={loading} onHide={() => {}}></LoadingModal>
      )}
      {modalShowCodeGeneration && (
        <CodeGenerationDialog
          setModalShow={setModalShowCodeGeneration}
          modalShow={modalShowCodeGeneration}
          userId={toResetPin}
        ></CodeGenerationDialog>
      )}
    </Fragment>
  );
};

export default List;
