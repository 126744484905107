import React, { useState, createRef, useEffect } from 'react';
import { RouteProps } from 'react-router-dom';
import '../style.scss';
import { CheckGreenIcon } from '../../../assets/svg/index';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { IoMdArrowDropdown } from 'react-icons/io';
import Dropdown from 'react-bootstrap/Dropdown';

interface SelectProps extends RouteProps {
  title: string;
  horizontal: boolean;
  filters: any;
  fun: any;
  t: any;
}

const SelectorStatusComponent: React.FunctionComponent<SelectProps> = ({
  title,
  horizontal,
  filters,
  fun,
  t,
}: any) => {
  const ref = createRef<HTMLDivElement>();

  const [style, updateStyle] = useState(`
    .dropwwwwwww {
      background-color: #fff;
      border-bottom: 1px solid #eee;
      transform: translate(${0}px, ${ref.current?.clientHeight}px) !important
    }
  `);

  useEffect(() => {
    updateStyle(`
    .dropwwwwwww {
      background-color: #fff;
      border-bottom: 1px solid #eee;
      transform: translate(${0}px, ${ref.current?.clientHeight}px) !important
    }
  `);
  }, [ref]);

  const getFiltersSelected = () => {
    let result = '';
    if (filters.all || (filters.polling && filters.rcs)) {
      result = t('territorial.auditory.lbl.all');
    } else {
      if (filters.polling) {
        result = t('electoral.polling.modal.lbl.polling');
      }
      if (filters.rcs) {
        if (result !== '') {
          result = result + ', ' + t('electoral.polling.table.rc');
        } else result = t('electoral.polling.table.rc');
      }
    }
    return result;
  };

  return (
    <div className={horizontal ? 'tags-box-horizontal' : 'tags-box'}>
      <h2>{title}</h2>
      <div className="dropdown-tags dropdown-filters">
        <div ref={ref} className="tag-box">
          <Dropdown as={ButtonGroup}>
            <style>{style}</style>
            <p className="tags-items-box-2">{getFiltersSelected()}</p>

            <Dropdown.Toggle split variant="success" id="dropdown-split-basic">
              <IoMdArrowDropdown></IoMdArrowDropdown>
            </Dropdown.Toggle>

            <Dropdown.Menu
              className="dropwwwwwww"
              style={{
                transform: `translate(${0}px, ${
                  ref.current?.clientHeight
                }px) !important`,
              }}
            >
              <div
                key={2}
                onClick={() => {
                  let newfilters = { ...filters };
                  const isLast =
                    newfilters.polling === false && !newfilters.rcs === false
                      ? true
                      : false;
                  newfilters = {
                    ...newfilters,
                    ...{ rcs: !newfilters.rcs, all: isLast },
                  };
                  fun(newfilters);
                }}
                className={
                  filters.rcs ? 'item-active dropdown-item' : 'dropdown-item'
                }
              >
                <p> {t('electoral.polling.table.rc')}</p>
                <div className="icon">
                  <CheckGreenIcon></CheckGreenIcon>
                </div>
              </div>
              <div
                key={1}
                onClick={() => {
                  let newfilters = { ...filters };
                  const isLast =
                    !newfilters.polling === false && newfilters.rcs === false
                      ? true
                      : false;
                  newfilters = {
                    ...newfilters,
                    ...{ polling: !newfilters.polling, all: isLast },
                  };
                  fun(newfilters);
                }}
                className={
                  filters.polling
                    ? 'item-active dropdown-item'
                    : 'dropdown-item'
                }
              >
                <p> {t('electoral.polling.modal.lbl.polling')}</p>
                <div className="icon">
                  <CheckGreenIcon></CheckGreenIcon>
                </div>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default SelectorStatusComponent;
