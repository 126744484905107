import React, { useState, FunctionComponent } from 'react';
import { Arrow, NotificationIcon } from '../assets/svg/index';
import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import {
  PRIVATE_DASHBOARD,
  PRIVATE_ROUTES,
  PRIVATE_VOTERS,
  PRIVATE_TRACKING,
  PRIVATE_TEAMS,
  PRIVATE_AUDITING,
  PRIVATE_POLLWATCHERS,
  PRIVATE_LAWYERS,
  PRIVATE_POLLINGSTATIONS,
  PRIVATE_PROBLEMS,
  PRIVATE_HISTORY,
  PRIVATE_POLLS,
  PRIVATE_MAP,
  PRIVATE_POLLINGSTATIONS_ACTIVE,
  PRIVATE_BINGO,
  PRIVATE_MOBILIZATION,
  PRIVATE_POLLINGSTATIONS_SETTINGS,
  PRIVATE_COALITIONS,
  PRIVATE_VOTERS_LIST,
  PRIVATE_TRAINING_LIST,
  PRIVATE_USERS,
  PRIVATE_ROLES,
  PRIVATE_TRAINING_CREATE,
  PRIVATE_DASHBOARD_DIAD,
} from '../utils/paths';
import { Usa, Italia, Mexico, Brazil, CheckIcon } from '../assets/svg/index';

import { useLocation } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Dropdown from 'react-bootstrap/Dropdown';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import LanguageItem from './sub-component/languageItem';
import { connect, useDispatch, useSelector } from 'react-redux';
import { SET_SUPER_ADMIN, SET_UNAUTHENTICATED } from '../redux/User/userTypes';
import TopTitle from './TopTitle';
import { SET_GLOBAL_SELECTED } from '../redux/core/CoreTypes';
import { RootStore } from '../redux/Store';

import SearchFormComponent from './sub-component/searchForm';
import { VOTERS_SEARCH_SUCCESS } from '../redux/voters/VotersTypes';
import { TEAMS_SEARCH_SUCCESS } from '../redux/teams/teamsTypes';
import { AUDITORY_SEARCH_SUCCESS } from '../redux/auditory/AuditoryTypes';
import { RGRC_SEARCH_SUCCESS } from '../redux/RgRc/RgRcTypes';
import {
  VOTERSBOX_SEARCH_SUCCESS,
  VOTERSBOX_MAP_SEARCH_SUCCESS,
  VOTERSBOX_ELECTORAL_SEARCH_SUCCESS,
  VOTERSBOX_ELECTORAL_MAP_SEARCH_SUCCESS,
} from '../redux/votersBox/VotersBoxTypes';
import { TRAININGS_SEARCH_SUCCESS } from '../redux/trainings/TrainingsTypes';
import { SEARCH_DATA_SUCCESS } from '../redux/updata/UpDataTypes';
import { logoutUser } from '../redux/User/userActions';

import { onlyLettersNumbersSpaces } from '../utils/utils';

interface TopProps {
  role: any;
  showMenu: boolean;
  setMenu: any;
}

const Top: FunctionComponent<TopProps> = ({
  role,
  showMenu,
  setMenu,
}: TopProps) => {
  const searchVoters = useSelector(
    (globalState: RootStore) => globalState.voters.search,
  );
  const searchTeams = useSelector(
    (globalState: RootStore) => globalState.teams.search,
  );
  const searchAuditory = useSelector(
    (globalState: RootStore) => globalState.auditory.search,
  );
  const searchRgRc = useSelector(
    (globalState: RootStore) => globalState.rgRc.search,
  );
  const searchVB = useSelector(
    (globalState: RootStore) => globalState.votersBox.search,
  );
  const searchVBMap = useSelector(
    (globalState: RootStore) => globalState.votersBox.searchMap,
  );
  const searchVBElectoral = useSelector(
    (globalState: RootStore) => globalState.votersBox.searchElectoral,
  );
  const searchVBElectoralMap = useSelector(
    (globalState: RootStore) => globalState.votersBox.searchElectoralMap,
  );
  const searchUpdata = useSelector(
    (globalState: RootStore) => globalState.upData.search,
  );
  const searchTrainings = useSelector(
    (globalState: RootStore) => globalState.trainings.search,
  );

  const selectedList = useSelector(
    (globalState: RootStore) => globalState.upData.selectedList,
  );
  const tabTeam = useSelector(
    (globalState: RootStore) => globalState.teams.tabChange,
  );
  const tabVB = useSelector(
    (globalState: RootStore) => globalState.votersBox.tabChange,
  );
  const tabVBElectoral = useSelector(
    (globalState: RootStore) => globalState.votersBox.tabElectoralChange,
  );

  const stateSelected = useSelector((globalState: RootStore) => {
    return globalState.core.globalStateSelected;
  });
  const citySelected = useSelector((globalState: RootStore) => {
    return globalState.core.globalMunicipalitySelected;
  });

  const states = useSelector((globalState: RootStore) => {
    return globalState.core.globalStates;
  });
  const cities = useSelector((globalState: RootStore) => {
    return globalState.core.globalMunicipalities;
  });

  const userData = useSelector((globalState: RootStore) => {
    return globalState.core.userData;
  });

  const { t } = useTranslation();
  // const [visible, setVisible] = useState(0);
  const location = useLocation();
  const dispatch = useDispatch();

  const [counter, setCounter] = React.useState(2);
  const [toggle, setToggle] = React.useState(false);
  const [data, setData] = React.useState('');

  const [enterOn, setEnterOn] = useState(false);

  React.useEffect(() => {
    if (counter > 0) setTimeout(() => setCounter(counter - 1), 1000);
    if (counter <= 0) {
      if (toggle) {
        setToggle(false);
        onChange(data);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter, toggle]);

  React.useEffect(() => {
    if (enterOn) {
      setToggle(false);
      setEnterOn(false);
      onChange(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enterOn]);

  const onChange = (data: any) => {
    if (location.pathname === '/voters') {
      dispatch({ type: VOTERS_SEARCH_SUCCESS, searchValue: data });
    } else if (location.pathname === '/teams') {
      dispatch({ type: TEAMS_SEARCH_SUCCESS, searchValue: data });
    } else if (location.pathname === '/auditing') {
      dispatch({ type: AUDITORY_SEARCH_SUCCESS, searchValue: data });
    } else if (location.pathname === '/pollwatchers') {
      dispatch({ type: RGRC_SEARCH_SUCCESS, searchValue: data });
    } else if (
      location.pathname === '/boxesSettings' &&
      tabVB === 'settings.polling.tab.1'
    ) {
      dispatch({ type: VOTERSBOX_SEARCH_SUCCESS, searchValue: data });
    } else if (
      location.pathname === '/boxesSettings' &&
      tabVB === 'settings.polling.tab.2'
    ) {
      dispatch({ type: VOTERSBOX_MAP_SEARCH_SUCCESS, searchValue: data });
    } else if (
      location.pathname === '/pollingstations' &&
      tabVBElectoral === 'electoral.polling.tab.1'
    ) {
      dispatch({ type: VOTERSBOX_ELECTORAL_SEARCH_SUCCESS, searchValue: data });
    } else if (
      location.pathname === '/pollingstations' &&
      tabVBElectoral === 'electoral.polling.tab.2'
    ) {
      dispatch({
        type: VOTERSBOX_ELECTORAL_MAP_SEARCH_SUCCESS,
        searchValue: data,
      });
    } else if (location.pathname === '/trainings') {
      dispatch({ type: TRAININGS_SEARCH_SUCCESS, searchValue: data });
    } else if (location.pathname === '/votersList' && selectedList) {
      dispatch({ type: SEARCH_DATA_SUCCESS, searchValue: data });
    }
  };

  const getTitle = (routeNane: string) => {
    switch (routeNane) {
      case PRIVATE_DASHBOARD:
        return t('menu.dashboard');
      case PRIVATE_ROUTES:
        return t('menu.areas');
      case PRIVATE_VOTERS:
        return t('menu.voters');
      case PRIVATE_TRACKING:
        return t('menu.tracking');
      case PRIVATE_TEAMS:
        return t('menu.teams');
      case PRIVATE_AUDITING:
        return t('menu.auditing');
      case PRIVATE_POLLWATCHERS:
        return t('menu.pollwatchers');
      case PRIVATE_LAWYERS:
        return t('menu.lawyers');
      case PRIVATE_POLLINGSTATIONS:
        return t('menu.pollingstations');
      case PRIVATE_PROBLEMS:
        return t('menu.problems');
      case PRIVATE_HISTORY:
        return t('menu.analysis.historical');
      case PRIVATE_POLLS:
        return t('menu.analysis.polls');
      case PRIVATE_DASHBOARD_DIAD:
        return t('menu.dashboard.d');
      case PRIVATE_MAP:
        return t('menu.map');
      case PRIVATE_POLLINGSTATIONS_ACTIVE:
        return t('menu.pollingstations');
      case PRIVATE_BINGO:
        return t('menu.bingo');
      case PRIVATE_MOBILIZATION:
        return t('menu.mobilization');
      case PRIVATE_POLLINGSTATIONS_SETTINGS:
        return t('menu.pollingstations');
      case PRIVATE_COALITIONS:
        return t('menu.coalitions');
      case PRIVATE_VOTERS_LIST:
        return t('menu.votersList');
      case PRIVATE_TRAINING_LIST:
        return t('menu.training');
      case PRIVATE_USERS:
        return t('menu.users');
      case PRIVATE_ROLES:
        return t('menu.roles');
      case PRIVATE_TRAINING_CREATE:
        return t('menu.training');
      default:
        return t('');
    }
  };

  const getFlag = () => {
    const value = localStorage.language;
    if (value === 'es') return <Mexico></Mexico>;
    if (value === 'en') return <Usa></Usa>;
    if (value === 'it') return <Italia></Italia>;
    if (value === 'pt') return <Brazil></Brazil>;
  };

  const getText = () => {
    const value = localStorage.language;
    if (value === 'es') return t('language.es');
    if (value === 'en') return t('language.en');
    if (value === 'it') return t('language.es');
    else return t('language.es');
  };

  const checked = () => {
    return <CheckIcon></CheckIcon>;
  };

  const changeCity = (state: any, city: any) => {
    // localStorage.setItem('stateSelected', JSON.stringify(state));
    // localStorage.setItem('citySelected', JSON.stringify(city));
    // const geoCities = JSON.parse(localStorage.geoCities);
    // const geo = geoCities.find((e: any) => e._id === city.geoCoordenatesId);
    // localStorage.setItem('geoSelected', JSON.stringify(geo));
    dispatch({
      type: SET_GLOBAL_SELECTED,
      state,
      municipality: city,
    });
  };

  const getInitials = (value?: string) => {
    let result = '';
    if (value) {
      const array = value.split(' ');
      array.forEach((e) => {
        result = result + e.slice(0, 1);
      });
    }
    return result;
  };

  const language = () => {
    return (
      <Dropdown as={ButtonGroup} drop="left">
        <Dropdown.Toggle id="dropdown-custom-1">
          <div className="custom-dropdown-with-title">
            <div className="title">{t('general.profile.languages')}</div>
            <div className="language-dropdown-box-selected">
              <div className="name-language">
                <div className="text-icon">
                  <div className="icon-language">{getFlag()}</div>
                  {getText()}
                </div>
              </div>
            </div>
            <Arrow className="arrow-dropdown"></Arrow>
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu className="super-colors">
          <LanguageItem value="es"></LanguageItem>
          <LanguageItem value="en"></LanguageItem>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const dropDownLicenses = () => {
    if ((states?.length || 0) >= 1 && (cities?.length || 0) > 1)
      return (
        <Dropdown as={ButtonGroup} drop="left">
          <Dropdown.Toggle id="dropdown-custom-1">
            {firstItem(
              t('general.profile.licenses'),
              <p>
                {stateSelected?.name} <strong>({citySelected?.name})</strong>
              </p>,
              true,
              false,
            )}
          </Dropdown.Toggle>
          <Dropdown.Menu className="super-colors">
            {states?.map((e: any, index: number) => {
              const availableCities = cities?.filter(
                (j: any) => j.stateId === e._id,
              );
              return dropDownStates(
                e,
                availableCities,
                index === 0,
                states?.length,
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
      );
    else {
      return aloneItem(
        t('general.profile.license'),
        <p>
          {stateSelected?.name} <strong>({citySelected?.name})</strong>
        </p>,
        true,
        false,
      );
    }
  };

  const dropDownStates = (
    state: any,
    cities: any,
    first: boolean,
    length: any,
  ) => {
    return (
      <Dropdown key={state._id} as={ButtonGroup} drop="left">
        {first ? (
          <Dropdown.Toggle id="dropdown-custom-1">
            {firstItem(
              length === 1
                ? t('general.profile.state')
                : t('general.profile.states'),
              <p>{state.name}</p>,
              false,
              stateSelected?._id === state._id,
            )}
          </Dropdown.Toggle>
        ) : (
          <Dropdown.Toggle id="dropdown-item-father">
            {othersItemDropDown(
              <p>{state.name}</p>,
              false,
              stateSelected?._id === state._id,
            )}
          </Dropdown.Toggle>
        )}
        <Dropdown.Menu className="super-colors">
          {cities.map((f: any, i: number) => {
            if (i === 0)
              return (
                <button
                  key={f._id}
                  className="dropdown-toggle btn btn-primary"
                  onClick={() => {
                    changeCity(state, f);
                  }}
                >
                  {firstItem(
                    cities.length === 1
                      ? t('general.profile.city')
                      : t('general.profile.cities'),
                    <p>{f.name}</p>,
                    false,
                    citySelected?._id === f._id,
                  )}
                </button>
              );
            else {
              return (
                <button
                  key={f._id}
                  className="dropdown-toggle btn btn-primary city-box-i"
                  onClick={() => {
                    changeCity(state, f);
                  }}
                >
                  {othersItem(
                    <p>{f.name}</p>,
                    false,
                    citySelected?._id === f._id,
                  )}
                </button>
              );
            }
          })}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const aloneItem = (
    title: string,
    text: any,
    dropdown: boolean,
    selected: boolean,
  ) => {
    return (
      <div className="custom-dropdown-with-title alone-item">
        <div className="title">{title}</div>
        <div className="language-dropdown-box-selected">
          <div className="name-language">
            <div className="text-icon">{text}</div>
            {selected && checked()}
          </div>
        </div>
        {dropdown && <Arrow className="arrow-dropdown"></Arrow>}
      </div>
    );
  };

  const othersItemDropDown = (
    text: any,
    dropdown: boolean,
    selected: boolean,
  ) => {
    return (
      <div className="city-dropdown-box">
        <div className="name-language">
          <div className="text-icon">{text}</div>
          {selected && checked()}
        </div>
        {dropdown && <Arrow className="arrow-dropdown"></Arrow>}
      </div>
    );
  };

  const firstItem = (
    title: string,
    text: any,
    dropdown: boolean,
    selected: boolean,
  ) => {
    return (
      <div className="custom-dropdown-with-title">
        <div className="title">{title}</div>
        <div className="language-dropdown-box-selected">
          <div className="name-language">
            <div className="text-icon">{text}</div>
            {selected && checked()}
          </div>
        </div>
        {dropdown && <Arrow className="arrow-dropdown"></Arrow>}
      </div>
    );
  };

  const othersItem = (text: any, dropdown: boolean, selected: boolean) => {
    return (
      <div className="city-dropdown-box">
        <div className="name-language">
          <div className="text-icon">{text}</div>
          {selected && checked()}
        </div>
        {dropdown && <Arrow className="arrow-dropdown"></Arrow>}
      </div>
    );
  };

  const searchBoxItem = () => {
    let searchContainer;
    let showSearchComponent = false;
    if (location.pathname === '/voters') {
      searchContainer = searchVoters;
      showSearchComponent = true;
    } else if (location.pathname === '/teams') {
      searchContainer = searchTeams;
      showSearchComponent =
        tabTeam === 'territorial.teams.tab.1' ? true : false;
    } else if (location.pathname === '/auditing') {
      searchContainer = searchAuditory;
      showSearchComponent = true;
    } else if (location.pathname === '/pollwatchers') {
      searchContainer = searchRgRc;
      showSearchComponent = true;
    } else if (location.pathname === '/boxesSettings') {
      searchContainer =
        tabVB === 'settings.polling.tab.1' ? searchVB : searchVBMap;
      showSearchComponent = true;
    } else if (location.pathname === '/pollingstations') {
      searchContainer =
        tabVBElectoral === 'electoral.polling.tab.1'
          ? searchVBElectoral
          : searchVBElectoralMap;
      showSearchComponent = true;
    } else if (location.pathname === '/votersList') {
      searchContainer = searchUpdata;
      showSearchComponent = selectedList ? true : false;
    } else if (location.pathname === '/trainings') {
      searchContainer = searchTrainings;
      showSearchComponent = true;
    }
    return (
      showSearchComponent && (
        <SearchFormComponent
          search={searchContainer}
          funOnInit={(data: any) => {
            setCounter(2);
            setToggle(true);
            setData(data);
          }}
          regExp={onlyLettersNumbersSpaces}
          additionalClass={''}
          setEnterOn={setEnterOn}
        ></SearchFormComponent>
      )
    );
  };

  return (
    <Container className="top-layout" fluid>
      <Row className="top-layout-row">
        <div className="h100 title-header-box">
          <TopTitle location={location} getTitle={getTitle}></TopTitle>
        </div>
        <div className="h100 top-profile-box">
          {searchBoxItem()}
          <Dropdown as={ButtonGroup} alignRight className="profile-dropdown">
            <Dropdown.Toggle id="dropdown-profile">
              <div className="box-profile">
                <div className="current-city-box">
                  {citySelected?.name}
                  {', '}
                  {getInitials(stateSelected?.name)}
                </div>
                <div className="user-box-profile">
                  <div className="profile-data">
                    <div className="h100">
                      <div className="profile-name">
                        {(userData?.firstName || '') +
                          ' ' +
                          (userData?.lastName || '')}
                      </div>
                      <div className="profile-role">
                        {userData?.candidate !== undefined &&
                        userData.candidate !== ''
                          ? userData?.candidate
                          : 'Sin Candidato'}
                      </div>
                    </div>
                  </div>

                  <div className="user-icon">
                    <div>
                      {(userData?.firstName?.slice(0, 1) || '') +
                        (userData?.lastName?.slice(0, 1) || '')}
                    </div>
                  </div>
                </div>
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu className="profile-dropdown-body">
              {dropDownLicenses()}
              {language()}
              {/* <Dropdown.Divider /> */}
              <Dropdown.Header
                className="dropdown-item-bold"
                onClick={() => {
                  localStorage.setItem('logoutEvent', 'logout' + Math.random());
                  logoutUser();
                  dispatch({
                    type: SET_UNAUTHENTICATED,
                  });
                  dispatch({
                    type: SET_SUPER_ADMIN,
                    payload: false,
                  });
                }}
              >
                {t('general.profile.logout')}
              </Dropdown.Header>
            </Dropdown.Menu>
          </Dropdown>
          <div className="notification-icon-box">
            <NotificationIcon></NotificationIcon>
          </div>
        </div>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state: any) => ({
  authenticated: state.user.authenticated,
});
export default connect(mapStateToProps)(Top);
