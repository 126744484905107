export const AREAS_LOADING = 'AREAS_LOADING';
export const AREAS_FAIL = 'AREAS_FAIL';
export const AREAS_SUCCESS = 'AREAS_SUCCESS';
export const AREAS_TEAM_SUCCESS = 'AREAS_TEAM_SUCCESS';
export const AREAS_COORDINATOR_CHANGE = 'AREAS_COORDINATOR_CHANGE';
export const AREAS_CAPTURIST_CHANGE = 'AREAS_CAPTURIST_CHANGE';
export const AREAS_HEAT_MAP = 'AREAS_HEAT_MAP';


export type SearchCapturistTeamsByCity = {
  municipalityId: string;
  isFirst: boolean;
  teamLevel: number;
};

export interface AreasLoading {
  type: typeof AREAS_LOADING;
}
export interface AreasFail {
  type: typeof AREAS_FAIL;
}
export interface AreasSuccess {
  type: typeof AREAS_SUCCESS;
  payload: object;
}
export interface AreasTeamSuccess {
  type: typeof AREAS_TEAM_SUCCESS;
  payload: object;
}
export interface AreasCoordinatorChange {
  type: typeof AREAS_COORDINATOR_CHANGE;
  payload: any;
}
export interface AreasCapturistChange {
  type: typeof AREAS_CAPTURIST_CHANGE;
  payload: any;
}

export interface AreasHeatMap {
  type: typeof AREAS_HEAT_MAP;
  payload: any;
}



export type AreasDispatchTypes =
  | AreasLoading
  | AreasFail
  | AreasSuccess
  | AreasTeamSuccess
  | AreasCoordinatorChange
  | AreasCapturistChange
  | AreasHeatMap;
