import {
  Campaign,
  CampaignDispatchTypes,
  City,
  SELECT_COUNTRY,
  SET_COUNTRIES_DATA,
  SET_LIST_LICENSES,
  SET_PROBLEMS,
  SET_SELECTED_LICENSE_CITY,
  STATE_LOADING,
  USERS_CAMPAIGN_LOADING,
  USERS_CAMPAIGN_SUCCESS,
} from './campaignsTypes';

export interface CampaignState {
  loading: boolean;
  countries: any[];
  licenses: Campaign[];
  users: any[];
  licenseSelected?: Campaign;
  cityLicense?: City;
  selectedCountry?: any;
  problemList: any[];
}

export const defaultState: CampaignState = {
  loading: false,
  countries: [],
  licenses: [],
  users: [],
  problemList: [],
};

export const campaignReducer = (
  state: CampaignState = defaultState,
  action: CampaignDispatchTypes,
): CampaignState => {
  switch (action.type) {
    case SET_COUNTRIES_DATA:
      return {
        ...state,
        countries: action.countries,
        loading: false,
        selectedCountry:
          state.selectedCountry !== undefined
            ? state.selectedCountry
            : action.countries[0],
      };
    case SELECT_COUNTRY:
      const found = state.countries.find((e) => {
        return e.country._id === action._id;
      });
      console.log(found);
      return {
        ...state,
        selectedCountry: found,
      };
    case SET_LIST_LICENSES:
      return {
        ...state,
        licenses: [...action.listLicenses],
      };
    case SET_SELECTED_LICENSE_CITY:
      return {
        ...state,
        licenseSelected: action.licenseSelected,
        cityLicense: action.cityLicense,
      };
    case STATE_LOADING:
      return {
        ...state,
        loading: action.value,
      };
    case USERS_CAMPAIGN_LOADING:
      return {
        ...state,
        loading: action.value,
      };
    case USERS_CAMPAIGN_SUCCESS:
      return {
        ...state,
        users: [...action.users],
      };
    case SET_PROBLEMS:
      return {
        ...state,
        problemList: [...action.problems],
      };
    default:
      return state;
  }
};
