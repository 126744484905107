import React, {
  Fragment,
  useEffect,
  useState,
  useContext,
  createRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../redux/Store';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import moment from 'moment';

import {
  DASHBOARD_ALL,
  DASHBOARD_CREATE,
  DASHBOARD_UPDATE,
  DASHBOARD_SELECTED,
  DASHBOARD_SEARCH_SUCCESS,
} from '../../../redux/dashboard/dashboardTypes';
import {
  postCallLineChartData,
  postCallPieChartData,
  getDistricts,
  getComplete,
} from '../../../redux/dashboard/dashboardAction';
import { onlyLettersNumbersSpaces } from '../../../utils/utils';

import {
  CapturaStatusIcon,
  AuditoriaStatusIcon,
  XLSStatusIcon,
} from '../../../assets/svg/index';
import SearchFormComponent from '../../../components/sub-component/searchForm';
import SelectorComponent from '../../../components/sub-component/selector';
import ModalAssignBox from './assignNecessary';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import Popover from 'react-bootstrap/Popover';
import Row from 'react-bootstrap/Row';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { ResponsiveLine } from '@nivo/line';
import { ResponsivePie } from '@nivo/pie';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';

import { ContextWs, WS_STATES } from '../../../redux/socket/wsContext';

const List: React.FunctionComponent = () => {
  const citySelected: any = useSelector((globalState: RootStore) => {
    return globalState.core.globalMunicipalitySelected;
  });
  const userData: any = useSelector((globalState: RootStore) => {
    return globalState.core.userData;
  });

  const dashboard = useSelector((globalState: RootStore) => {
    return globalState.dashboard.dashboardData;
  });
  const tabChange = useSelector((globalState: RootStore) => {
    return globalState.dashboard.tabChange;
  });
  const districtSelected: any = useSelector((globalState: RootStore) => {
    return globalState.dashboard.selected;
  });
  const districtSelectedD: any = useSelector((globalState: RootStore) => {
    return globalState.dashboard.selectedD;
  });
  const lineDataDetail: any = useSelector((globalState: RootStore) => {
    return globalState.dashboard.lineData;
  });
  const pieDataDetail: any = useSelector((globalState: RootStore) => {
    return globalState.dashboard.pieData;
  });
  const search: any = useSelector((globalState: RootStore) => {
    return globalState.dashboard.search;
  });
  const filter: any = useSelector((globalState: RootStore) => {
    return globalState.dashboard.filter;
  });

  let defaultData: any = [];

  const ref = createRef<HTMLDivElement>();
  const [style, updateStyle] = useState(`
    .main-main-main {
      height: 100%;
      width: calc(100% - ${ref.current?.clientWidth}px);
    }
  `);

  useEffect(() => {
    updateStyle(`
    .main-main-main {
      height: 100%;
      width: calc(100% - ${ref.current?.clientWidth}px);
    }
  `);
  }, [ref]);

  const [counter, setCounter] = React.useState(2);
  const [toggle, setToggle] = React.useState(false);
  const [data, setData] = React.useState('');
  const [enterOn, setEnterOn] = useState(false);

  const [filters, setFilters] = useState(
    filter
      ? filter
      : {
          progress: 'REGISTERED',
          showBy: 'MONTH',
          complaints: 'ALL',
          district: 'ALL',
          section: 'ALL',
        },
  );
  const [districtArray, setDistrictArray] = useState(defaultData);
  const [assign, setAssign] = useState(Object);
  const [modalShowAssign, setModalShowAssign] = useState(false);

  const [lineData, setLineData] = useState(defaultData);
  const [pieData, setPieData] = useState(defaultData);
  const [colorsArray, setColorsArray] = useState(defaultData);

  const [tableData, setTableData] = useState(Array);
  const [daysLeft, setDaysLeft] = useState(0);
  const [neddedValue, setNeddedValue] = useState(0);
  const [missingValue, setMissingValue] = useState(0);
  const [doorValue, setDoorValue] = useState(0);
  const [confirmedValue, setConfirmedValue] = useState(0);
  const [listValue, setListValue] = useState(0);
  const [doorFakeValue, setDoorFakeValue] = useState(0);
  const [confirmedFakeValue, setConfirmedFakeValue] = useState(0);
  const [listFakeValue, setListFakeValue] = useState(0);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { socket, wsState, rooms, join, disconnect, off } =
    useContext(ContextWs);

  const generateHistoryElements = (issuesArray: any) => {
    let pieValuesArray: any = [];
    let colorsArray: any = [];

    if (issuesArray.length > 0) {
      for (let index = 0; index < issuesArray.length; index++) {
        const r1 = (Math.random() * 255).toFixed(0);
        const r2 = (Math.random() * 255).toFixed(0);
        const r3 = (Math.random() * 255).toFixed(0);
        const color = `rgba(${r1},${r2},${r3})`;

        pieValuesArray.push({
          id: issuesArray[index][i18next.language],
          label: issuesArray[index][i18next.language],
          value: issuesArray[index]['count'],
          color: generateHSLfromRGBA(r1, r2, r3),
          colorBase: color,
        });

        colorsArray.push(generateHSLfromRGBA(r1, r2, r3));
      }

      setPieData(pieValuesArray);
      setColorsArray(colorsArray);
    } else {
      setPieData([
        {
          id: t('select.lbl.none'),
          label: t('select.lbl.none'),
          value: 1,
          color: 'hsl(0, 0%, 90%)',
          colorBase: 'rgba(230, 230, 230)',
        },
      ]);
      setColorsArray(['hsl(0,0%,90%)']);
    }
  };

  const generateHSLfromRGBA = (r: any, g: any, b: any) => {
    r /= 255;
    g /= 255;
    b /= 255;

    let cmin = Math.min(r, g, b),
      cmax = Math.max(r, g, b),
      delta = cmax - cmin,
      h = 0,
      s = 0,
      l = 0;

    if (delta == 0) h = 0;
    else if (cmax == r) h = ((g - b) / delta) % 6;
    else if (cmax == g) h = (b - r) / delta + 2;
    else h = (r - g) / delta + 4;

    h = Math.round(h * 60);
    if (h < 0) h += 360;
    l = (cmax + cmin) / 2;
    s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
    s = +(s * 100).toFixed(1);
    l = +(l * 100).toFixed(1);

    return 'hsl(' + h + ',' + s + '%,' + l + '%)';
  };

  const getRegisteredOverlay = (title: any) => {
    return (
      <Popover
        id={'exclamation-popover'}
        placement={'bottom'}
        className="pop-over-exclamation"
        key={'popover-' + title}
      >
        <Popover.Content bsPrefix="content">
          <Fragment>
            <span>{t('general.table.label.multipleSelect')}</span>
          </Fragment>
        </Popover.Content>
      </Popover>
    );
  };

  const getIcon = (value: any) => {
    let content;

    if (value === 'door') {
      content = <CapturaStatusIcon></CapturaStatusIcon>;
    } else if (value === 'confirm') {
      content = <AuditoriaStatusIcon></AuditoriaStatusIcon>;
    } else if (value === 'list') {
      content = <XLSStatusIcon></XLSStatusIcon>;
    }

    return content;
  };

  const getValueCard = (
    title: string,
    qty: string,
    showBottom: boolean,
    isToWin: boolean,
    icon = '',
    bottomValue = '',
  ) => {
    return (
      <div
        className={
          showBottom && !bottomValue
            ? 'card-value card-value-secondary'
            : 'card-value'
        }
        key={'card-' + title}
      >
        <div className="card-top">
          <h2>{title}</h2>
          <OverlayTrigger
            key={'exclamation-icon'}
            placement={'top-start'}
            trigger="click"
            overlay={getRegisteredOverlay(title)}
          >
            <div className="circle-exclamation">{'!'}</div>
          </OverlayTrigger>
        </div>
        <div
          className={showBottom ? 'card-main card-main-secondary' : 'card-main'}
          style={{
            background: isToWin
              ? getColor(neddedValue, missingValue)
              : 'transparent',
          }}
        >
          {showBottom && getIcon(icon)}
          {qty}
        </div>
        <div className="card-bottom">
          {showBottom &&
            bottomValue !== '0' &&
            '*' + bottomValue + ' ' + t('dashboard.lbl.fake')}
        </div>
      </div>
    );
  };

  const getListElement = (value: any) => {
    let porcentageValue = 0;
    porcentageValue = (value.confirmed * 100) / value.forWin;

    return (
      <div className="list-element" key={'list-element' + value._id}>
        <div className="title-element">
          <p
            onClick={() => {
              if (!districtSelected._id) {
                goToView(value._id);
              }
            }}
            className='relative'
          >
            {districtSelected._id
              ? t('dashboard.lbl.section') + ' ' + value.name
              : t('dashboard.lbl.district') + ' ' + value.name}
          </p>
          {getActionDropdown(value._id)}
        </div>

        <div className="progress-circle">
          <CircularProgressbar
            value={porcentageValue ? Math.round(porcentageValue) : 0}
            strokeWidth={15}
            text={porcentageValue ? Math.round(porcentageValue) + '%' : '0%'}
            styles={buildStyles({
              pathTransitionDuration: 0.15,
              strokeLinecap: 'butt',
            })}
          />
        </div>
        <p>
          {value.confirmed +
            ' ' +
            t('dashboard.lbl.of') +
            ' ' +
            (value.forWin || 0)}
        </p>
      </div>
    );
  };

  const getActionDropdown = (id: string) => {
    return (
      <Dropdown
        as={ButtonGroup}
        id={`dropdown-button-drop-down`}
        className="dropdown-options"
        key={'dropdown-' + id}
      >
        <Dropdown.Toggle id="dropdown-custom-marker">
          <Row className="h100 w100" noGutters>
            <BsThreeDotsVertical />
          </Row>
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-body">
          <Dropdown.Item
            eventKey="1"
            onClick={() => {
              const elementInfo = tableData?.find(
                (value: any) => value._id === id,
              );
              console.log('elementInfo', elementInfo);
              setAssign(elementInfo);
              setModalShowAssign(true);
            }}
          >
            {t('dashboard.dropdown.assign')}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const getResponsiveLine = (data: any) => {
    let maxValue: any = 'auto';

    if (data.length > 0) {
      let yValueArray = data[0].data.map((d: any) => {
        return d.y;
      });

      let yMaxValue = Math.max(...yValueArray).toString();

      if (yMaxValue === '0') {
        maxValue = 10;
      } else {
        let yLenght =
          yMaxValue[0] !== '9' ? yMaxValue.length - 1 : yMaxValue.length;
        let yFirst =
          yMaxValue[0] !== '9' ? (parseInt(yMaxValue[0]) + 1).toString() : '1';

        let newMaxValue = yFirst;

        for (let index = 0; index < yLenght; index++) {
          newMaxValue = newMaxValue + '0';
        }

        maxValue = parseInt(newMaxValue);
      }
    }

    return (
      <ResponsiveLine
        data={data}
        margin={{ top: 20, right: 20, bottom: 30, left: 35 }}
        xScale={{ type: 'point' }}
        yScale={{
          type: 'linear',
          min: 0,
          max: maxValue,
          stacked: true,
          reverse: false,
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          orient: 'bottom',
          tickSize: 0,
          tickPadding: 5,
          tickRotation: 0,
          legend: '',
          legendOffset: 36,
          legendPosition: 'middle',
        }}
        axisLeft={{
          orient: 'left',
          tickSize: 0,
          tickPadding: 5,
          tickRotation: 0,
          legend: '',
          legendOffset: -40,
          legendPosition: 'middle',
        }}
        colors={{ scheme: 'category10' }}
        pointSize={10}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor', modifiers: [] }}
        pointLabelYOffset={-12}
        areaOpacity={0}
        enableCrosshair={false}
        useMesh={true}
        legends={[]}
        animate={false}
      />
    );
  };

  const getResponsivePie = (data: any) => {
    return (
      <ResponsivePie
        data={data}
        margin={{ top: 0, right: 20, bottom: 0, left: 0 }}
        innerRadius={0.6}
        padAngle={1}
        fit={false}
        enableSliceLabels={false}
        enableRadialLabels={false}
        colors={colorsArray}
        borderColor={{ theme: 'background' }}
        isInteractive={false}
        legends={[]}
      />
    );
  };

  const getNumberWithComma = (value: any) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const goToView = (_id: string) => {
    const district = dashboard?.find((value: any) => value._id === _id);

    dispatch({
      type: DASHBOARD_SELECTED,
      payload: district,
    });
  };

  const transformData = (search: string) => {
    let newArray: any = [];
    let nedded = 0;
    let door = 0;
    let confirmed = 0;
    let list = 0;
    let doorFake = 0;
    let confirmedFake = 0;
    let listFake = 0;

    dashboard?.forEach((dash) => {
      if (dash.name.toLowerCase().includes(search.toLowerCase())) {
        nedded += dash.necessary;
        door += dash.doorToDoor;
        confirmed += dash.confirmed;
        list += dash.xls;
        doorFake += dash.doorToDoorFake;
        //confirmedFake += dash.necessary
        listFake += dash.xlsFake;

        let newElement = {
          _id: dash._id,
          name: dash.name,
          confirmed: dash.confirmed,
          forWin: dash.forWin,
          necessary: dash.necessary,
        };
        newArray.push(newElement);
      }
    });

    setTableData(newArray);
    setNeddedValue(nedded);
    setMissingValue(nedded - confirmed);
    setDoorValue(door);
    setConfirmedValue(confirmed);
    setListValue(list);
    setDoorFakeValue(doorFake);
    setConfirmedFakeValue(confirmedFake);
    setListFakeValue(listFake);
  };

  const searchLineChart = () => {
    const paramsLine = buildParamLine(
      moment().format('YYYY'),
      moment().format('MM'),
    );
    dispatch(postCallLineChartData(paramsLine));
  };

  const searchPieChart = () => {
    const paramsPie = buildParamPie();
    dispatch(postCallPieChartData(paramsPie));
  };

  const buildParamLine = (year: string, month: string) => {
    let requestObject;
    requestObject = {
      cityLicenseId: citySelected?._id || '',
      year: year,
      month: month,
      progress: filters.progress,
      showBy: filters.showBy,
    };
    return requestObject;
  };

  const buildParamPie = () => {
    let requestObject;
    requestObject = {
      cityLicenseId: citySelected?._id || '',
      problemList: citySelected?.problemList || [],
      complaints: filters.complaints,
      district: filters.district,
      section: filters.section,
    };
    return requestObject;
  };

  const getDistrictList = () => {
    getDistricts(citySelected?._id, (value: any) => {
      let districtArray: any = [];
      if (value.length > 0) {
        value.map((dist: any) => {
          districtArray.push({
            _id: dist.name,
            name: dist.name,
          });
        });
        districtArray.unshift({
          _id: 'ALL',
          name: t('settings.trainings.form.all'),
        });
      }
      setDistrictArray(districtArray);
    });
    getComplete(citySelected?._id, (value: any) => {
      dispatch({
        type: DASHBOARD_ALL,
        data: value
      })
    })
  };

  const onChange = (data: any) => {
    dispatch({ type: DASHBOARD_SEARCH_SUCCESS, searchValue: data });
  };

  const getColor = (value: number, total: number) => {
    let porcentage = Math.round((value * 100) / total);
    if (isNaN(porcentage)) {
      porcentage = 0;
    }
    if (porcentage < 80) {
      return '#e7495d';
    } else if (porcentage >= 81 && porcentage <= 94) {
      return '#f29100';
    } else if (porcentage >= 95 && porcentage <= 99) {
      return '#2fad74';
    } else if (porcentage >= 100) {
      return '#ffd700';
    }
  };

  useEffect(() => {
    if (citySelected)
      if (wsState === WS_STATES.OPEN && socket) {
        const dashboardRoom = 'dashboard' + citySelected._id;
        socket.once('RoomsJoined', () => {
          socket.emit('getDashboardAll', {
            cityLicenseId: citySelected._id,
            districtId:
              tabChange === 'dashboard.tab.general'
                ? districtSelected._id
                  ? districtSelected._id
                  : ''
                : districtSelectedD._id
                ? districtSelectedD._id
                : '',
          });
        });
        if (off) {
          off(dashboardRoom);
        }
        if (join)
          join(
            dashboardRoom,
            DASHBOARD_UPDATE,
            DASHBOARD_CREATE,
            '',
            DASHBOARD_ALL,
            (response: any) => {},
          );
      }
  }, [wsState, citySelected, districtSelected, districtSelectedD, tabChange]);

  useEffect(() => {
    if (citySelected) {
      searchLineChart();
      searchPieChart();
      getDistrictList();
    }
  }, [citySelected]);

  useEffect(() => {
      searchLineChart();
      searchPieChart();
      getDistrictList();
  }, []);

  useEffect(() => {
    if (citySelected) {
      searchLineChart();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.progress, filters.showBy]);

  useEffect(() => {
    if (citySelected) {
      searchPieChart();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.complaints, filters.district, filters.section]);

  useEffect(() => {
    if (userData) {
      let now = moment(new Date());
      let end = moment(userData.endDate);
      let duration = moment.duration(end.diff(now));
      let days = duration.asDays();

      setDaysLeft(Math.floor(days));
    }
  }, [userData]);

  useEffect(() => {
    if (dashboard) {
      transformData(search);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboard, search]);

  useEffect(() => {
    setLineData(lineDataDetail);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lineDataDetail]);

  useEffect(() => {
    generateHistoryElements(pieDataDetail);
    getDistrictList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pieDataDetail, i18next.language]);

  React.useEffect(() => {
    if (counter > 0) setTimeout(() => setCounter(counter - 1), 1000);
    if (counter <= 0) {
      if (toggle) {
        setToggle(false);
        onChange(data);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter, toggle]);

  React.useEffect(() => {
    if (enterOn) {
      setToggle(false);
      setEnterOn(false);
      onChange(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enterOn]);

  return (
    <Fragment>
      <div className="dashboard-main">
        <div className="dashboard-list-container">
          <div className="list-title">
            <div className="list-breadcum">
              <h2
                className={districtSelected?._id ? 'back-link' : ''}
                onClick={() => {
                  if (districtSelected?._id) {
                    dispatch({
                      type: DASHBOARD_SELECTED,
                      payload: {},
                    });
                  }
                }}
              >
                {districtSelected?._id
                  ? t('dashboard.lbl.capturesPerDistrict')
                  : t('dashboard.lbl.capturesPerDistrict') +
                    ' [' +
                    tableData.length +
                    ']'}
              </h2>
              {districtSelected?._id && (
                <>
                  <p className="show-link-separator">&nbsp;/&nbsp;</p>
                  <h2>
                    {t('dashboard.lbl.district') +
                      districtSelected.name +
                      ' [' +
                      tableData.length +
                      ']'}
                  </h2>
                </>
              )}
            </div>
            <div className="title-days-left">
              {t('dashboard.lbl.daysLeft', {
                data: daysLeft,
              })}
            </div>
          </div>
          <div className="list-values">
            {getValueCard(
              t('dashboard.lbl.necesary'),
              getNumberWithComma(neddedValue),
              false,
              false,
            )}
            {getValueCard(
              t('dashboard.lbl.missing'),
              getNumberWithComma(missingValue),
              false,
              true,
            )}
            <div className="separator"></div>
            {getValueCard(
              t('dashboard.lbl.doorToDoor'),
              getNumberWithComma(doorValue),
              true,
              false,
              'door',
              getNumberWithComma(doorFakeValue),
            )}
            {getValueCard(
              t('dashboard.lbl.confirmed'),
              getNumberWithComma(confirmedValue),
              true,
              false,
              'confirm',
              getNumberWithComma(confirmedFakeValue),
            )}
            {getValueCard(
              t('dashboard.lbl.inList'),
              getNumberWithComma(listValue),
              true,
              false,
              'list',
              getNumberWithComma(listFakeValue),
            )}
          </div>
          <div className="list-search">
            <div className="list-breadcum"></div>
            <SearchFormComponent
              search={search}
              funOnInit={(data: any) => {
                setCounter(2);
                setToggle(true);
                setData(data);
              }}
              regExp={onlyLettersNumbersSpaces}
              additionalClass={''}
              setEnterOn={setEnterOn}
            ></SearchFormComponent>
          </div>
          <div className="list-rows">
            {tableData.map((e: any) => {
              return getListElement(e);
            })}
          </div>
        </div>
        <div className="dashboard-chart-container">
          <div className="line-chart">
            <div className="chart-top-filter">
              <h2>{t('dashboard.lbl.progress')}</h2>
              <SelectorComponent
                title={''}
                defaultSelector={filters.progress}
                options={[
                  {
                    _id: 'REGISTERED',
                    value: t('dashboard.select.votersCaptured'),
                  },
                  {
                    _id: 'CAPTURED',
                    value: t('dashboard.select.votersRegistered'),
                  },
                ]}
                horizontal={true}
                fun={(value: any) => {
                  setFilters((prevstate: any) => ({
                    ...prevstate,
                    progress: value,
                  }));
                }}
              ></SelectorComponent>
            </div>
            <div
              className="chart-line-graph"
              style={{
                height: '100%',
              }}
            >
              {getResponsiveLine(lineData)}
            </div>
            <div className="chart-bot-filter">
              <p>{t('dashboard.lbl.showBy')}</p>
              <SelectorComponent
                title={''}
                defaultSelector={filters.showBy}
                options={[
                  {
                    _id: 'MONTH',
                    value: t('dashboard.select.month'),
                  },
                  {
                    _id: 'DAY',
                    value: t('dashboard.select.day'),
                  },
                ]}
                horizontal={true}
                fun={(value: any) => {
                  setFilters((prevstate: any) => ({
                    ...prevstate,
                    showBy: value,
                  }));
                }}
              ></SelectorComponent>
            </div>
          </div>
          <div className="pie-chart">
            <div className="chart-top-filter">
              <h2>{t('dashboard.lbl.votersProblems')}</h2>
              <SelectorComponent
                title={''}
                defaultSelector={filters.district}
                options={districtArray}
                horizontal={true}
                fun={(value: any) => {
                  setFilters((prevstate: any) => ({
                    ...prevstate,
                    district: value,
                  }));
                }}
              ></SelectorComponent>
            </div>
            <div className="chart-main-content">
              <div className="chart-figure main-main-main">
                <style>{style}</style>
                {getResponsivePie(pieData)}
              </div>
              <div className="chart-history" ref={ref}>
                <h2>{'#' + t('dashboard.lbl.votersAndProblems')}</h2>
                {pieData.map((e: any, i: any) => {
                  return (
                    <div
                      className="history-element"
                      key={'history-element-' + i}
                    >
                      <div
                        className="history-square"
                        style={{
                          backgroundColor: e.colorBase,
                        }}
                      ></div>
                      {e.label !== t('select.lbl.none') && (
                        <div className="history-qty">{e.value}</div>
                      )}
                      <div className="history-label">{e.label}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      {modalShowAssign && (
        <ModalAssignBox
          modalShow={modalShowAssign}
          setModalShow={setModalShowAssign}
          successFun={() => {}}
          assign={assign}
          alreadyAssigned={tableData.reduce(
            (a: any, b: any) => a + b.necessary,
            0,
          )}
        ></ModalAssignBox>
      )}
    </Fragment>
  );
};

export default List;
