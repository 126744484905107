import React from 'react';
import { useSelector } from 'react-redux';
import { RootStore } from '../../../redux/Store';
import { useTranslation } from 'react-i18next';
import { BiChevronRight } from 'react-icons/bi';

import { ROLE, XLS_TYPE } from '../../../utils/utils';

import StaticModal from '../../../dialogs/StaticModal';
import Container from 'react-bootstrap/Container';

interface ModalTipProps {
  modalShow: any;
  setModalShow: any;
  levelList: any;
}

export const ModalTip: React.FunctionComponent<ModalTipProps> = ({
  modalShow,
  setModalShow,
  levelList,
}) => {
  const navigationPath: any = useSelector(
    (globalState: RootStore) => globalState.teams.navigationPath,
  );
  const coordinator: any = useSelector(
    (globalState: RootStore) => globalState.teams.coordinator,
  );

  const { t } = useTranslation();

  return (
    <StaticModal
      show={modalShow}
      title={t('territorial.volunteer.lbl.tipTitle')}
      closeText={t('submit.cancel')}
      confirmText={t('general.modal.btn.confirm')}
      onHide={() => {
        setModalShow(false);
      }}
      submitFun={() => {}}
      validBtn={false}
      showConfirmBtn={false}
      showCancelBtn={false}
      showCloseIcon={true}
      showModalFooter={false}
      size="sm"
      sizeClass="md modal-rc modal-tips"
    >
      <Container className="dialog-tip-teams">
        <p className="sub-title">{t('territorial.volunteer.lbl.levelByRol')}</p>
        <div className="box-of-levels">
          {levelList.map((e: any, index: any) => {
            return (
              <div
                className="tip-row-element"
                style={{ paddingLeft: `${(index + 1) * 25}px` }}
              >
                <p>{e['level'] + '° ' + e[t('language')]}</p>
              </div>
            );
          })}
        </div>
      </Container>
    </StaticModal>
  );
};

export default ModalTip;
