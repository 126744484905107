export const TRACKING_LOADING = 'TRACKING_LOADING';
export const TRACKING_FAIL = 'TRACKING_FAIL';
export const TRACKING_SUCCESS = 'TRACKING_SUCCESS';
export const TRACKING_DATA_SUCCESS = 'TRACKING_DATA_SUCCESS';
export const TRACKING_DATA_FAIL = 'TRACKING_DATA_FAIL';
export const TRACKING_TEAM_SUCCESS = 'TRACKING_TEAM_SUCCESS';
export const TRACKING_COORDINATOR_CHANGE = 'TRACKING_COORDINATOR_CHANGE';

export type Tracking = {
  _id: string;
  userId: string;
  created: Date;
  route: Array<any>;
  deleted: boolean;
  type: number;
  steps: number;
  routes: Array<any>;
};

export type SearchCapturistTeamsByCity = {
  municipalityId: string;
  isFirst: boolean;
  teamLevel: number;
};

export type Training = {
  _id: string;
  coordinates: Array<any>;
};
export interface TrackingLoading {
  type: typeof TRACKING_LOADING;
}
export interface TrackingFail {
  type: typeof TRACKING_FAIL;
}
export interface TrackingSuccess {
  type: typeof TRACKING_SUCCESS;
  payload: Tracking[];
  payload2: any;
}
export interface TrackingDataSuccess {
  type: typeof TRACKING_DATA_SUCCESS;
  payload: object;
}
export interface TrackingTeamSuccess {
  type: typeof TRACKING_TEAM_SUCCESS;
  payload: object;
}
export interface TrackingCoordinatorChange {
  type: typeof TRACKING_COORDINATOR_CHANGE;
  payload: any;
}
export interface TrackingDataFail {
  type: typeof TRACKING_DATA_FAIL;
}

export type TrackingDispatchTypes =
  | TrackingLoading
  | TrackingFail
  | TrackingSuccess
  | TrackingDataFail
  | TrackingDataSuccess
  | TrackingCoordinatorChange
  | TrackingTeamSuccess;
