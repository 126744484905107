import React, { useEffect, FunctionComponent } from 'react';
import * as netWorking from '../redux/netWorking';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootStore } from '../redux/Store';
import './style.scss';

import { District, Section } from '../redux/core/CoreTypes';

import SelectorComponent from './sub-component/selector';
import Button from 'react-bootstrap/Button';
import { DownloadIcon, Trash } from '../assets/svg/index';
import { RiArrowGoBackLine } from 'react-icons/ri';

interface TopXlsProps {
  title: string;
  downloadFun?: any;
  uploadFun?: any;
  buttonFun: any;
  setSelected: any;
  selected: any;
  showSelects: boolean;
  selectsToShow: number;
  children?: any;
  children2?: any;
  showBack?: boolean;
  showButton?: boolean;
  showXls?: boolean;
  setshowBack?: any;
  showUpload?: boolean;
  showDownload?: boolean;
  total?: any;
  deleteFun?: any;
  toDelete?: any;
}

const TopXlsBar: FunctionComponent<TopXlsProps> = ({
  title,
  downloadFun,
  uploadFun,
  buttonFun,
  setSelected,
  selected,
  showButton = true,
  showSelects,
  selectsToShow,
  children,
  showBack,
  setshowBack,
  showXls = true,
  children2,
  showDownload = true,
  showUpload = true,
  total,
  deleteFun,
  toDelete = [],
}: any) => {
  const { t } = useTranslation();

  const citySelected: any = useSelector((globalState: RootStore) => {
    return globalState.core.globalMunicipalitySelected;
  });

  const arrayDistrict: District[] = [];
  const arraySection: Section[] = [];

  const [loadData, setLoadData] = React.useState(false);
  const [district, setDistrict] = React.useState(arrayDistrict);
  const [section, setSection] = React.useState(arraySection);

  const getDist = (id: string) => {
    if (selectsToShow > 1) {
      netWorking.get(
        netWorking.getDistrict + id,
        (res: any) => {
          if (res.data.response.length > 0) {
            setDistrict(res.data.response);
            getSect(res.data.response[0]._id);
          } else {
            setDistrict(arrayDistrict);
            setSection(arraySection);
            getVoBo('', '');
          }
        },
        (error: any) => {
          setDistrict(arrayDistrict);
          setSection(arraySection);
          getVoBo('', '');
        },
      );
    }
  };
  const getSect = (id: string) => {
    if (selectsToShow > 2) {
      netWorking.get(
        netWorking.getSection + id,
        (res: any) => {
          if (res.data.response.length > 0) {
            setSection(res.data.response);
            getVoBo(res.data.response[0]._id, id);
          } else {
            setSection(arraySection);
            getVoBo('', '');
          }
        },
        (error: any) => {
          setSection(arraySection);
          getVoBo('', '');
        },
      );
    }
  };
  const getVoBo = (section: string, district: string) => {
    setSelected({
      section: section,
      district: district,
      municipality: citySelected?._id,
    });
  };

  useEffect(() => {
    if (!loadData && showSelects) {
      getDist(citySelected?._id || '');
      setLoadData(true);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadData]);

  useEffect(() => {
    if (showSelects) {
      getDist(citySelected?._id || '');
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [citySelected]);

  return (
    <div className="top-bar-xls">
      <div className="row-xls">
        {showSelects ? (
          <div className="row-selectors">
            <h1 className="count">
              {t('electoral.rcrg.table.rgs.polling') + ' [' + total + ']'}
            </h1>
            {showBack && (
              <div className="col-back-button">
                <div
                  className={'circle-hamburger active'}
                  onClick={() => setshowBack()}
                >
                  <RiArrowGoBackLine></RiArrowGoBackLine>
                  {/* <Menu></Menu> */}
                </div>{' '}
              </div>
            )}
            {!showBack && selectsToShow > 1 && (
              <div className="col-selector col-padding">
                <SelectorComponent
                  title={t('general.form.district')}
                  defaultSelector={''}
                  options={district}
                  horizontal={true}
                  fun={(value: string) => {
                    getSect(value);
                  }}
                ></SelectorComponent>
              </div>
            )}
            {!showBack && selectsToShow > 2 && (
              <div className="col-selector">
                <SelectorComponent
                  title={t('general.form.section')}
                  defaultSelector={''}
                  options={section}
                  horizontal={true}
                  fun={(value: string) => {
                    getVoBo(value, selected.district);
                  }}
                ></SelectorComponent>
              </div>
            )}
            {children}
          </div>
        ) : (
          <>
            <div className="col-title">
              <h2>{title}</h2>
            </div>
            {children2}
          </>
        )}
        <div className="row-actions">
          {toDelete.length > 0 ? (
            <div className="delete-btn" onClick={deleteFun}>
              <Button
                variant="outline-dark"
                className="delete-item-territorial-team"
              >
                <Trash></Trash>
              </Button>
            </div>
          ) : (
            <div className="delete-btn"></div>
          )}
          <div className="row-buttons">
            {showXls && showDownload && (
              <Button
                className="template-button"
                variant="outline-dark"
                onClick={downloadFun}
              >
                <DownloadIcon></DownloadIcon>
                {t('general.btn.template')}
              </Button>
            )}
          </div>
          {showUpload && (
            <Button
              variant="primary"
              onClick={uploadFun}
              className="upload-xls-button"
            >
              {t('general.btn.uploadXls')}
            </Button>
          )}
          {showButton && (
            <Button
              variant="primary"
              onClick={buttonFun}
              className="search-add-button"
            >
              {t('settings.trainings.form.add')}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default TopXlsBar;
