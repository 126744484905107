import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  validEmailRegex,
  onlyDigits,
  ROLE,
  validPhoneRegex,
} from '../../../../utils/utils';
import { useDispatch } from 'react-redux';
import StaticModal from '../../../../dialogs/StaticModal';
import InputComponent from '../../../../components/sub-component/input';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {
  createLicenseUser,
  updateLicenseUser,
} from '../../../../redux/campaigns/campaignsActions';
import { checkInUse } from '../../../../redux/User/userActions';
import { Campaign, City } from '../../../../redux/campaigns/campaignsTypes';
//import { useHistory } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Switch from 'react-switch';

interface AddModalProps {
  modalShow: any;
  setModalShow: any;
  successFun: any;
  licenseSelected?: Campaign;
  cityLicense?: City;
  toEdit?: any;
}

export const AddAdminModalComponent: React.FunctionComponent<AddModalProps> = ({
  modalShow,
  setModalShow,
  successFun,
  licenseSelected,
  cityLicense,
  toEdit,
}: AddModalProps) => {
  const defaultEdit = {
    _id: '',
    firstName: '',
    lastName: '',
    email: '',
    cellphone: '',
    deleted: false,
    blocked: false,
  };

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [submitConfirmation, setSubmitConfirmation] = useState(false);
  const [submitMessage, setSubmitMessage] = useState(String);
  const [toSave, setToSave] = useState(defaultEdit);
  const [load, setLoad] = useState(false);
  const [toCompare, setToCompare] = useState(defaultEdit);

  useEffect(() => {
    if (!load && modalShow) {
      if (toEdit !== undefined) {
        setToSave({
          _id: toEdit._id,
          firstName: toEdit.firstName,
          lastName: toEdit.lastName,
          email: toEdit.email,
          cellphone: toEdit.cellphone,
          deleted: false,
          blocked: toEdit.blocked,
        });
        setToCompare({
          _id: toEdit._id,
          firstName: toEdit.firstName,
          lastName: toEdit.lastName,
          email: toEdit.email,
          cellphone: toEdit.cellphone,
          deleted: false,
          blocked: toEdit.blocked,
        });
      } else {
        setToSave(defaultEdit);
        setToCompare(defaultEdit);
      }
      setLoad(true);
    }
    if (!modalShow) {
      setLoad(false);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [load, modalShow]);

  const cancelFun = () => {
    setModalShow(false);
  };

  const submitFun = () => {
    console.log('toSave', toSave);
    if (validSubmit(1)) {
      setSubmitMessage(validSubmit(1));
      setSubmitConfirmation(true);
    } else {
      const checkInUseAux: any = {
        cellphone: toSave.cellphone,
      };
      dispatch(
        checkInUse(checkInUseAux, (data: any) => {
          if (validSubmit(data.error) === '') {
            if (toSave._id === '') onSave();
            else onEdit();
          } else {
            setSubmitMessage(validSubmit(data.error));
            setSubmitConfirmation(true);
          }
        }),
      );
    }
  };

  const validSubmit = (data: any) => {
    if (toSave.firstName === '') {
      return 'Name is empty';
    } else if (toSave.lastName === undefined || toSave.lastName === '') {
      return 'Last name is empty';
    } else if (
      toSave.cellphone === '' ||
      !validPhoneRegex.test(toSave.cellphone) ||
      data === 0
    ) {
      if (data === 0 && toSave.cellphone === toCompare.cellphone) {
        return '';
      } else if (data === 0 && toSave.cellphone !== toCompare.cellphone) {
        return t('territorial.volunteer.modal.error.cellphoneExist');
      } else if (toSave.cellphone === '') {
        return t('territorial.volunteer.modal.error.cellphoneComplete');
      } else if (!validPhoneRegex.test(toSave.cellphone)) {
        return t('territorial.volunteer.modal.error.cellphone');
      } else return '';
    } else if (toSave.email === '' || !validEmailRegex.test(toSave.email)) {
      return t('territorial.volunteer.modal.error.email');
    } else {
      return '';
    }
  };

  const onEdit = () => {
    if (licenseSelected && cityLicense) {
      //const license = licenseSelected;
      //const city = cityLicense;

      dispatch(
        updateLicenseUser(
          {
            _id: toSave._id,
            firstName: toSave.firstName.trim(),
            lastName: toSave.lastName.trim(),
            email: toSave.email,
            cellphone: toSave.cellphone,
            deleted: false,
            blocked: toSave.blocked,
            role: ROLE.ADMIN_BOSS,
          },
          (data: any) => {
            setModalShow(false);
            successFun();
          },
          (err: any) => {
            console.log(err);
          },
        ),
      );
    }
  };

  const onSave = () => {
    if (licenseSelected && cityLicense) {
      const license = licenseSelected;
      const city = cityLicense;

      dispatch(
        createLicenseUser(
          {
            _id: toSave._id,
            firstName: toSave.firstName.trim(),
            lastName: toSave.lastName.trim(),
            email: toSave.email,
            cellphone: toSave.cellphone,
            deleted: false,
            blocked: toSave.blocked,
            campaignId: license._id,
            states: [license.states[0]._id],
            cities: [city._id],
            role: ROLE.ADMIN_BOSS,
          },
          (data: any) => {
            setModalShow(false);
            successFun();
          },
          (err: any) => {
            console.log(err);
          },
        ),
      );
    }
  };

  return (
    <StaticModal
      show={modalShow}
      title={toEdit ? 'Edit Admin' : 'Add Admin'}
      closeText={'Cancel'}
      confirmText={toEdit ? 'Edit' : 'Save'}
      onHide={cancelFun}
      submitFun={submitFun}
      validBtn={false}
      customTitle={
        <div className="header-modal-add-admin">
          <Modal.Title className="title-border-bottom">
            <strong>Add Administrator</strong>
          </Modal.Title>
          <Switch
            onChange={() => {
              setToSave((prevState: any) => ({
                ...prevState,
                blocked: !prevState.blocked,
              }));
            }}
            checked={toSave.blocked === false}
            height={20}
            width={40}
            onColor="#2fad74"
            // onHandleColor="#2693e6"
            handleDiameter={20}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 5px rgba(0, 0, 0, 0.2)"
            className="react-switch"
            id="material-switch"
          ></Switch>
        </div>
      }
      size="sm"
      sizeClass="add-admin-modal"
    >
      <Row
        noGutters
        className="addModal-content modal-text-row add-campaign-modal"
      >
        {submitConfirmation && (
          <Col xs={12} md={12} className="col-item-message">
            <p className="">{submitMessage}</p>
          </Col>
        )}{' '}
        <Col xs={6} md={6} className="col-item-modal">
          <InputComponent
            placeholder={'Jose Ramon'}
            length={50}
            onChange={(value: string) => {
              let firstName = '';
              firstName = value;

              setToSave((prevState: any) => ({
                ...prevState,
                firstName,
              }));
            }}
            title={'Name'}
            value={toSave.firstName}
            horizontal={false}
          ></InputComponent>
        </Col>
        <Col xs={6} md={6} className="col-item-modal">
          <InputComponent
            placeholder={'Garcia Velazquez'}
            length={50}
            onChange={(value: string) => {
              let lastName = '';
              lastName = value;

              setToSave((prevState: any) => ({
                ...prevState,
                lastName,
              }));
            }}
            title={'Last Name'}
            value={toSave.lastName}
            horizontal={false}
          ></InputComponent>
        </Col>
        <Col xs={12} md={12} className="col-item-modal">
          <InputComponent
            placeholder={'example@gmail.com'}
            length={100}
            onChange={(value: string) => {
              let email = '';
              email = value.trim();

              setToSave((prevState: any) => ({
                ...prevState,
                email,
              }));
            }}
            title={'Email'}
            value={toSave.email}
            horizontal={false}
          ></InputComponent>
        </Col>
        <Col xs={12} md={12} className="col-item-modal">
          <InputComponent
            placeholder={'8119927772'}
            length={10}
            onChange={(value: string) => {
              let cellphone = '';
              cellphone = value.trim();

              setToSave((prevState: any) => ({
                ...prevState,
                cellphone,
              }));
            }}
            title={'Cellphone'}
            value={toSave.cellphone}
            regExp={onlyDigits}
            horizontal={false}
          ></InputComponent>
        </Col>
      </Row>
    </StaticModal>
  );
};

export default AddAdminModalComponent;
