import React, { useEffect, useState, Fragment } from "react";
import Form from "react-bootstrap/Form";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import { useHistory } from "react-router-dom";

interface DetailBoxProps {
    saveDetailData: any;
    saveForm: any;
    t: any;
    isEdit: any;
    training?: any;
    viewMode: any;
    setViewMode: any;
}

export const DetailMainData: React.FunctionComponent<DetailBoxProps> = ({
    saveDetailData,
    saveForm,
    t,
    isEdit,
    training,
    viewMode,
    setViewMode,
}: any) => {
    const defaultData = {
        _id: "",
        name: "",
        showTo: 0,
    };

    const redirect = useHistory();
    const [editName, setEditName] = useState(isEdit?false:true)
    const [showToOption, setShowToOption] = useState(defaultData.showTo)
    const [nameOption, setNameOption] = useState(defaultData.name)
    const [detailData, setDetailData] = useState(defaultData);

    const updateField = (targetProperty: string, value: any) => {
        setDetailData({
        ...detailData,
        [targetProperty]: value,
        });
    };

    useEffect(() => {
        saveDetailData(detailData);
    }, [detailData]);

    useEffect(() => {
        if (isEdit) {
        const { _id, name, showTo } = training;
        setDetailData({ _id, name, showTo });
        } else {
        setDetailData(defaultData);
        }
    }, [isEdit]);

    useEffect(() => {
        updateField("showTo", showToOption);
    }, [showToOption]);

    useEffect(() => {
        updateField("name", nameOption);
    }, [nameOption]); 

    return (

        <Fragment>
            <div className="training-information">
                <div className="box-row">
                    <div className="main-title-information">
                        <div className="title-information">
                            <h1>{t('settings.trainings.lbl.name')}:</h1>
                            {/* !editName && (
                                <p>{detailData.name}</p>
                            )*/}
                            {/*{ editName && (*/}
                                <Form.Control
                                    className="input-box"
                                    defaultValue={detailData.name}
                                    onChange={(data: any) => {
                                        setNameOption(data.target.value)
                                    }}
                                    type="text"
                                    placeholder={t("settings.trainings.lbl.name")}
                                ></Form.Control>
                            {/*})}*/}
                        </div>
                        <div className="svg-information">
                            {/*<EditIcon
                            onClick={() => {
                                let editValue = editName?false:true;
                                setEditName(editValue)
                            }}
                            ></EditIcon>*/}
                            <Button
                            variant="outline-dark"
                            className={'delete-item-territorial-team'}
                            onClick={() => {
                                redirect.push("/trainings");
                            }}
                            >
                            {t('settings.trainings.lbl.cancel')}
                            </Button>
                            <Button
                            variant="primary"
                            className={''}
                            onClick={() => {
                                saveForm()
                            }}
                            >
                            {t('settings.trainings.lbl.save')}
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="box-row">
                    <h1>{t('settings.trainings.lbl.showTo')}:</h1>
                    <ButtonGroup
                        aria-label="ine-status-to-label"
                        className="to-show-group"
                    >
                        <Button
                        variant="primary"
                        className={detailData.showTo === 1 || detailData.showTo === 3? 'button-rgs active' : 'button-rgs'}
                        onClick={() => {
                            let showOptionTemp = 0;
                            if(showToOption === 0) showOptionTemp = 1
                            if(showToOption === 1) showOptionTemp = 0
                            if(showToOption === 2) showOptionTemp = 3
                            if(showToOption === 3) showOptionTemp = 2
                            setShowToOption(showOptionTemp)
                        }}
                        >
                        {t('settings.trainings.lbl.rgs')}
                        </Button>
                        <Button
                        variant="primary"
                        className={detailData.showTo === 2 || detailData.showTo === 3? 'button-rcs active' : 'button-rcs'}
                        onClick={() => {
                            let showOptionTemp = 0;
                            if(showToOption === 0) showOptionTemp = 2
                            if(showToOption === 1) showOptionTemp = 3
                            if(showToOption === 2) showOptionTemp = 0
                            if(showToOption === 3) showOptionTemp = 1
                            setShowToOption(showOptionTemp)
                        }}
                        >
                        {t('settings.trainings.lbl.rcs')}
                        </Button>
                    </ButtonGroup>
                </div>
                <div className="box-actions-final box-row">
                </div>
            </div>
        </Fragment>
    );
};

export default DetailMainData;
