import React, { useEffect, useState } from 'react';
import { Button, Form, Input } from 'antd';
import * as F from 'react-bootstrap/Form';
import { Eye, ClosedEye } from '../../assets/svg/index';
import SimpleModal from '../../utils/dialogs/simple-dialog.js';
import LoadingDialog from '../../dialogs/loading-dialog';
import BDDialog from '../../dialogs/bdDialog';
import axios from 'axios';
import * as auth from '../../utils/auth';
import * as netWorking from '../../redux/netWorking';
import {
  passwordValidation,
  ROLE,
  validEmailRegex,
  validPhoneRegex,
} from '../../utils/utils';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import './style.scss';
import { CheckAuthentication } from '../../utils/CheckAuthentication';
import LanguageSelector from '../../components/LanguageSelector';
import Image from 'react-bootstrap/Image';
import GioobyLogo from '../../assets/logo/logo_giooby.png';
import jwtDecode from 'jwt-decode';
import { DecodedToken } from '../../redux/core/CoreTypes';

const FormItem = Form.Item;

const LoginView = (props: any) => {
  const { t } = useTranslation();
  const myRef = React.createRef<Input>();
  const [state, setValues] = useState({
    eyeActive: false,
    password: '',
    cellphone: '',
    terms: false,
    errorTerms: false,
    isLoading: false,
    blockedUserDialog: false,
    deletedUserDialog: false,
    failUserMessage: null,
    failLoginMessage: null,
    checked: false,
  });

  const [emailValidation, setEmailValidation] = useState(0);
  const [passValidation, setPassValidation] = useState(0);

  useEffect(() => {
    // Verify the password reset code is valid.
    if (!state.checked) {
      myRef.current?.focus();
      setValues((prevState) => ({
        ...prevState,
        checked: true,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.checked, myRef]);

  const doLogin = (event: any) => {
    validateForm('cellphone', state.cellphone);
    validateForm('password', state.password);
    if (!validation()) {
      const goHome = () => {
        // props.history.push('/');
      };
      const license = () => {
        wrongLicense();
      };

      const wrong = () => {
        wrongLogin();
      };
      // tslint:disable-next-line: no-shadowed-variable
      const wrongTerms = () => {
        wrongTermsFun();
      };
      if (
        !validPhoneRegex.test(state.cellphone.trim().toLowerCase()) ||
        isEmpty(state.password.trim())
      ) {
        wrong();
        return;
      }
      if (!state.terms) {
        wrongTerms();
        return;
      } else {
        axios
          .post(
            netWorking.URL + 'auth/login',
            {
              cellphone: state.cellphone.trim().toLowerCase(),
              password: state.password.trim(),
            },
            {
              headers: {
                'Access-Control-Allow-Origin': '*',
                crossdomain: true,
              },
            },
          )
          .then((value) => {
            if (value.data.response !== undefined) {
              const result = value.data.response;
              if (result.success) {
                const decodedToken: DecodedToken = jwtDecode(
                  result.accessToken,
                );
                if (decodedToken.role !== ROLE.CAPTURIST) {
                  const authResult = {
                    accessToken: result.accessToken,
                    refreshToken: result.refreshToken,
                    expiresIn: result.expiresIn,
                    team: result.response.team,
                  };
                  auth.setSession(authResult, goHome);
                  CheckAuthentication();
                } else {
                  wrong();
                  return;
                }
              } else {
                wrong();
                return;
              }
            } else {
              wrong();
              return;
            }
          })
          .catch((error) => {
            console.log(error);
            // take everything but 'request'
            if (error.response)
              if (error.response.request.status === 400) {
                if (error.response.data.errorMessage === 'License not Active') {
                  license();
                } else wrong();
              } else wrong();
            else wrong();
          });
      }
    }
  };

  const showBlockedDialog = () => {
    setValues((prevState) => ({
      ...prevState,
      blockedUserDialog: !state.blockedUserDialog,
      isLoading: false,
    }));
  };

  const showDeletedDialog = () => {
    setValues((prevState) => ({
      ...prevState,
      deletedUserDialog: !state.deletedUserDialog,
      isLoading: false,
    }));
  };

  const wrongTermsFun = () => {
    setValues((prevState) => ({
      ...prevState,
      errorTerms: true,
      failLoginMessage: t('error.message.no.terms'),
      loginStatus: 'error',
      isLoading: false,
    }));
  };

  const wrongLicense = () => {
    setValues((prevState) => ({
      ...prevState,
      failLoginMessage: t('error.message.incorrect.license'),
      loginStatus: 'error',
      isLoading: false,
    }));
  };

  const wrongLogin = () => {
    setValues((prevState) => ({
      ...prevState,
      failLoginMessage: t('error.message.incorrect.password'),
      loginStatus: 'error',
      isLoading: false,
    }));
  };

  /*const wrongTerms = () => {
    setValues((prevState) => ({
      ...prevState,
      failLoginMessage: t('error.message.incorrect.password'),
      loginStatus: 'error',
      isLoading: false,
    }));
  };*/

  /*const wrongLoginAccount = () => {
    setValues((prevState) => ({
      ...prevState,
      failLoginMessage: t('error.message.incorrect.account'),
      validUser: 'error',
    }));
  };*/

  const handleLoginForm = (name: string, event: any) => {
    const value = event.target.value;
    event.preventDefault();

    validateForm(name, value);

    setValues((prevState) => ({
      ...prevState,
      [name]: value,
      loginStatus: '',
      validUser: '',
      failLoginMessage: null,
      failUserMessage: null,
      errorTerms: false,
    }));
  };

  const validateForm = (name: string, value: any) => {
    console.log('validcell', validPhoneRegex.test(value));
    if (name === 'cellphone') {
      if (value === '') {
        setEmailValidation(1);
        return false;
      } else if (!validPhoneRegex.test(value)) {
        setEmailValidation(2);
        return false;
      } else {
        setEmailValidation(0);
        return true;
      }
    }
    if (name === 'password') {
      if (value === '') {
        setPassValidation(1);
        return false;
      } else if (!passwordValidation.test(value)) {
        setPassValidation(2);
        return false;
      } else {
        setPassValidation(0);
        return true;
      }
    }
  };

  const getErrorEmailText = () => {
    switch (emailValidation) {
      case 0:
        return '';
      case 1:
        return '* ' + t('login.wrong.email.1');
      case 2:
        return '* ' + t('login.wrong.email.2');
      default:
        return '';
    }
  };

  const getErrorPassText = () => {
    switch (passValidation) {
      case 0:
        return '';
      case 1:
        return '* ' + t('login.wrong.pass.1');
      case 2:
        return '* ' + t('login.wrong.pass.2');
      default:
        return '';
    }
  };

  const validation = () => {
    if (
      state.cellphone !== '' &&
      state.password !== '' &&
      passwordValidation.test(state.password.trim()) &&
      state.password.length >= 8
    ) {
      return false;
    } else return true;
  };

  return (
    <div className="login-background">
      <div className="login">
        <LanguageSelector></LanguageSelector>
        <Form className="login-box">
          <div className="login-logo">
            <Image src={GioobyLogo} fluid />
          </div>
          <div className="data-box">
            <div className="data-box-title">
              <div className="title">{t('login.welcome')}</div>
              <div className="sub-title">{t('login.start.session')}</div>
            </div>
            <div className="inputs-box">
              <div className="email-box">
                <div>{t('form.cellphone')}</div>
                <FormItem hasFeedback help={state.failUserMessage}>
                  {/* <Input
                    ref={myRef}
                    placeholder=""
                    onChange={(event: any) => handleLoginForm('email', event)}
                    className={
                      emailValidation !== 0
                        ? 'email-login error-input'
                        : 'email-login'
                    }
                  /> */}
                  {/* <InputComponent
                    placeholder={''}
                    className={
                      emailValidation !== 0
                        ? 'email-login error-input'
                        : 'email-login'
                    }
                    length={50}
                    onChange={(event: any) => handleLoginForm('email', event)}
                    title={''}
                    value={state.email}
                    horizontal={false}
                  ></InputComponent> */}
                  <F.default.Control
                    placeholder={''}
                    value={state.cellphone}
                    maxLength={150}
                    onChange={(event: any) =>
                      handleLoginForm('cellphone', event)
                    }
                  />
                  <span className="error-span">
                    {emailValidation !== 0 && getErrorEmailText()}
                  </span>
                </FormItem>
              </div>
              <div className="password-box">
                <div>
                  <div>{t('form.password')}</div>
                  <FormItem hasFeedback help={state.failLoginMessage}>
                    {/* <Input
                      suffix={}
                      placeholder=""
                      className={
                        passValidation !== 0
                          ? 'password-login error-input'
                          : 'password-login'
                      }
                      onChange={(event: any) =>
                        handleLoginForm('password', event)
                      }
                      type={state.eyeActive ? '' : 'password'}
                    /> */}
                    <F.default.Control
                      placeholder={''}
                      value={state.password}
                      maxLength={50}
                      type={state.eyeActive ? '' : 'password'}
                      onChange={(event: any) =>
                        handleLoginForm('password', event)
                      }
                    />
                    <div className="password-eye-box">
                      {state.eyeActive ? (
                        <Eye
                          className="eye-deactive password-eye"
                          onClick={() => {
                            setValues((prevState) => ({
                              ...prevState,
                              eyeActive: !state.eyeActive,
                            }));
                          }}
                        ></Eye>
                      ) : (
                        <ClosedEye
                          className="eye-deactive password-eye-closed"
                          onClick={() => {
                            setValues((prevState) => ({
                              ...prevState,
                              eyeActive: !state.eyeActive,
                            }));
                          }}
                        ></ClosedEye>
                      )}
                    </div>
                    <span className="error-span">
                      {passValidation !== 0 && getErrorPassText()}
                    </span>
                  </FormItem>
                </div>
              </div>
            </div>
            <div
              className={
                state.errorTerms
                  ? 'agreements-box checkbox-error'
                  : 'agreements-box'
              }
            >
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="customCheck1"
                  onChange={(event) => {
                    setValues((prevState) => ({
                      ...prevState,
                      terms: !state.terms,
                      errorTerms: false,
                    }));
                  }}
                ></input>
                <label
                  className="custom-control-label"
                  htmlFor="customCheck1"
                ></label>
                {t('terms.agree') + ' '}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={
                    'https://agreements.giooby.com/' +
                    t('language') +
                    '/terms_and_conditions'
                  }
                >
                  {t('terms')}
                </a>
              </div>
            </div>

            <div className="btn-box">
              <div className="forgot-box">
                <div>
                  <span
                    onClick={() => {
                      props.history.push('/forgot');
                    }}
                  >
                    {t('did.forgot.password')}{' '}
                  </span>
                </div>
              </div>
              <FormItem>
                <Button
                  type="primary"
                  htmlType="submit"
                  className={'btn-done'}
                  // disabled={validation()}
                  onClick={(event: any) => {
                    doLogin(event);
                  }}
                  // loading={props.isLoading}
                >
                  {t('name.sign.in')}
                </Button>
              </FormItem>
            </div>
          </div>
          <div className="copyright">
            &copy; {t('copyright.footer')}{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://sdsharp.com/"
            >
              Giooby.com
            </a>
          </div>
          {state.isLoading && (
            <SimpleModal>
              <LoadingDialog></LoadingDialog>
            </SimpleModal>
          )}
          {state.blockedUserDialog && (
            <SimpleModal>
              <BDDialog
                Title={t('name.blocked.account')}
                Description={t('notification.blocked.account')}
                onCloseRequest={showBlockedDialog}
              ></BDDialog>
            </SimpleModal>
          )}
          {state.deletedUserDialog && (
            <SimpleModal>
              <BDDialog
                Title={t('name.deleted.account')}
                Description={t('notification.deleted.account')}
                onCloseRequest={showDeletedDialog}
              ></BDDialog>
            </SimpleModal>
          )}
        </Form>
      </div>
    </div>
  );
};

export default LoginView;
