import React from 'react';
import { RouteProps } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import './style.scss';
interface SelectProps extends RouteProps {
  options: any;
  title: string;
  defaultSelector: string;
  horizontal: boolean;
  fun: any;
  dataValue?: string;
}

const SelectorFormsComponent: React.FunctionComponent<SelectProps> = ({
  title,
  defaultSelector,
  options,
  horizontal,
  fun,
  dataValue,
}: any) => {
  return (
    <div className={horizontal ? 'selector-box-horizontal' : 'selector-box'}>
      <h2>{title}</h2>
      <Form.Control
        size="sm"
        as="select"
        value={defaultSelector}
        onChange={(data: any) => {
          fun(data.target.value);
        }}
      >
        {options.map((e: any, index: any) => {
          return (
            <option
              // selected={e._id === defaultSelector}
              key={e._id}
              value={e._id}
            >
              {dataValue ? e[dataValue] : (e.name) ? e.name : e.value}
            </option>
          );
        })}
      </Form.Control>
    </div>
  );
};

export default SelectorFormsComponent;
