import React, { FunctionComponent } from 'react';
import { RouteProps } from 'react-router-dom';

interface ItemProps extends RouteProps {
  total: number;
  captured: number;
  inReview: number;
  withProblems: number;
  rejected: number;
  hoverState: string;
}

const PorcentageComponent: FunctionComponent<ItemProps> = ({
  total,
  captured,
  inReview,
  withProblems,
  rejected,
  hoverState,
}: any) => {
  const getColor = (index: string) => {
    switch (index) {
      case 'captured':
        return '#141c21';
      case 'inReview':
        return `#f2ee00`;
      case 'withProblems':
        return `#f29100`;
      case 'rejected':
        return `#e7495d`;
      default:
        return '#141c21';
    }
  };
  
  return (
    <div className={'porcentage-box'}>
      <div className="top-lines">
        <div
          className="porcentage-line"
          style={{
            width: `${(captured === 0)?0:(captured * 100) / total}%`,
            backgroundColor: `${getColor('captured')}`,
          }}
        ></div>
        <div
          className={(hoverState === 'revision' || hoverState === '')?"porcentage-line":"porcentage-line porcentage-display-none"}
          style={{
            width: `${(inReview === 0)?0:(inReview * 100) / total}%`,
            backgroundColor: `${getColor('inReview')}`,
          }}
        ></div>{' '}
        <div
          className={(hoverState === 'failed' || hoverState === '')?"porcentage-line":"porcentage-line porcentage-display-none"}
          style={{
            width: `${(withProblems === 0)?0:(withProblems * 100) / total}%`,
            backgroundColor: `${getColor('withProblems')}`,
          }}
        ></div>{' '}
        <div
          className={(hoverState === 'rejected' || hoverState === '')?"porcentage-line":"porcentage-line porcentage-display-none"}
          style={{
            width: `${(rejected === 0)?0:(rejected * 100) / total}%`,
            backgroundColor: `${getColor('rejected')}`,
          }}
        ></div>
        
      </div>
    </div>
  );
};

export default PorcentageComponent;
