import React, { useState } from 'react';
import { Button, Form } from 'antd';
// import Logo from "../../../assets/img/logo.png";
//import * as auth from '../../utils/auth';
import * as netWorking from '../../redux/netWorking';
// import SimpleModal from "../../../utils/dialogs/simple-dialog.js";
import NotificationDialog from '../../dialogs/Notification-dialog';
import { useTranslation } from 'react-i18next';
import Image from 'react-bootstrap/Image';
import GioobyLogo from '../../assets/logo/logo_giooby.png';
import { validEmailRegex, validPhoneRegex } from '../../utils/utils';
import * as F from 'react-bootstrap/Form';
import { useHistory } from 'react-router-dom';
import { GUEST_PASSWORD_CONFIRM } from '../../utils/paths';

const FormItem = Form.Item;

const ForgotView = (props: any) => {
  const { t } = useTranslation();
  const redirect = useHistory();
  const [state, setValues] = useState({
    eyeActive: false,
    cellphone: '',
    isLoading: false,
    isSent: false,
    isError: false,
    successMessage: t('password.success.message'),
  });

  const [emailValidation, setEmailValidation] = useState(0);

  const sendPasswordReset = (event: any) => {
    event.preventDefault();
    validateForm('cellphone', state.cellphone);
    if (!validation()) {
      netWorking.post(
        netWorking.checkCellphone,
        {
          cellphone: state.cellphone.trim().toLowerCase(),
          language: t('language'),
        },
        (result: any) => {
          if (result.data.response.error === 0) {
            localStorage.setItem('tempUserId', result.data.response._id);
            setValues((prevState) => ({
              ...prevState,
              isLoading: false,
              isSent: true,
              cellphone: '',
            }));
            redirect.push(GUEST_PASSWORD_CONFIRM);
          } else {
            setValues((prevState) => ({
              ...prevState,
              isLoading: false,
              isError: true,
            }));
          }
        },
        (error: any) => {
          setValues((prevState) => ({
            ...prevState,
            isLoading: false,
            isError: true,
          }));
        },
      );
    }
  };

  const clearMessage = () => {
    setValues((prevState) => ({
      ...prevState,
      isSent: false,
    }));
  };

  const clearError = () => {
    setValues((prevState) => ({
      ...prevState,
      isError: false,
    }));
  };

  const handleLoginForm = (name: string, event: any) => {
    const value = event.target.value;
    event.preventDefault();
    validateForm('cellphone', value);
    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validation = () => {
    if (
      state.cellphone !== '' &&
      validPhoneRegex.test(state.cellphone.trim().toLowerCase())
    ) {
      return false;
    } else return true;
  };

  const validateForm = (name: string, value: any) => {
    if (name === 'cellphone') {
      if (value === '') {
        setEmailValidation(1);
        return false;
      } else if (!validPhoneRegex.test(value)) {
        setEmailValidation(2);
        return false;
      } else {
        setEmailValidation(0);
        return true;
      }
    }
  };

  const getErrorEmailText = () => {
    switch (emailValidation) {
      case 0:
        return '';
      case 1:
        return '* ' + t('login.wrong.email.1');
      case 2:
        return '* ' + t('login.wrong.email.2');
      default:
        return '';
    }
  };

  return (
    <div className="login-background">
      <div className="login">
        <Form className="login-box forgot-password">
          <div className="login-logo">
            <Image src={GioobyLogo} fluid />
          </div>
          <div className="data-box">
            <div className="data-box-title">
              <div className="title">{t('name.redo.password')}</div>
              <div className="sub-title">
                {' '}
                {t('name.redo.password.description')}
              </div>
            </div>
            <div className="inputs-box">
              <div className="email-box">
                <div>{t('form.cellphone')}</div>
                <FormItem>
                  {' '}
                  <F.default.Control
                    placeholder={''}
                    value={state.cellphone}
                    maxLength={150}
                    onChange={(event: any) =>
                      handleLoginForm('cellphone', event)
                    }
                  />
                  {/* <Input
                    value={state.email}
                    placeholder=""
                    onChange={(event: any) => handleLoginForm('email', event)}
                    className="email-login"
                  /> */}
                  <span className="error-span">
                    {emailValidation !== 0 && getErrorEmailText()}
                  </span>
                </FormItem>
              </div>
            </div>
            <div className="btn-box">
              <span
                className="click-forgot"
                onClick={() => props.history.push('/')}
              >
                {t('name.sign.in')}
              </span>
              <FormItem>
                <Button
                  type="primary"
                  htmlType="submit"
                  className={'btn-done'}
                  onClick={(event: any) => {
                    sendPasswordReset(event);
                  }}
                  // loading={state.isLoading}
                >
                  {t('submit.send')}
                </Button>
              </FormItem>
            </div>
          </div>
          <div className="copyright">
            &copy; {t('copyright.footer')}{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://sdsharp.com/"
            >
              Giooby.com
            </a>
          </div>
        </Form>
        {state.isSent && (
          <NotificationDialog
            type={1}
            successMessage={state.successMessage}
            clearMessage={clearMessage}
          ></NotificationDialog>
        )}
        {state.isError && (
          <NotificationDialog
            type={2}
            errorMessage={t('name.cellphone.no.register')}
            clearMessage={clearError}
          ></NotificationDialog>
        )}
      </div>
    </div>
  );
};

export default ForgotView;
