import React, { useEffect, useState, Fragment } from 'react';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import i18next from 'i18next';

interface UploadBoxProps {
  saveUploadData: any;
  t: any;
  isEdit: any;
  training?: any;
  viewMode: any;
  filesArray: any;
  setFilesArray: any;
}

export const UploadImageBox: React.FunctionComponent<UploadBoxProps> = ({
  saveUploadData,
  t,
  isEdit,
  training,
  viewMode,
  filesArray,
  setFilesArray,
}: any) => {
  const defaultData = '';
  const [showPicture, setShowPicture] = useState(defaultData);
  const [showUploadBtn] = useState(false);

  const onFileChange = (e: any) => {
    let files = e.target.files || e.dataTransfer.files;

    const weight = files[0].size / 1024;
    const limit = 10 * 1024;
    if (weight < limit) {
      if (!files.length) return;
      createFile(files[0]);
    } else {
      console.log('Máximo 10MB por archivo');
    }
  };

  const createFile = (file: any) => {
    let stepString = 'image';
    filesArray.append(stepString, file);

    let fileFormat = {
      name: file.name.replace(/[^a-zA-Z0-9]/g, ''),
      type: `.${file.type.split('/')[1]}`,
      humanType: file.type.split('/')[1],
      dataFull: '',
    };

    let isValidExt = false;

    ['.jpg', '.jpeg', '.png'].forEach((ext) => {
      if (ext === fileFormat.type) {
        isValidExt = true;
      }
    });

    if (!isValidExt) {
      return false;
    }

    let reader = new FileReader();
    reader.onload = (e: any) => {
      if (isEdit) {
        Object.assign(fileFormat, {
          oldFile: training.urlPicture.replace(
            'https://giooby-elections.s3-us-west-1.amazonaws.com/',
            '',
          ),
        });
      }
      setShowPicture(e.target.result);
    };
    reader.readAsDataURL(file);

    saveUploadData(fileFormat);
    setFilesArray(filesArray);
  };

  useEffect(() => {
    if (isEdit) {
      setShowPicture(training.urlPicture);
      saveUploadData({ name: training.urlPicture });
    } else {
      setShowPicture(defaultData);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

  return (
    <Fragment>
      <div className="training-image">
        <div className="box-row">
          <h1 className="main-title">{t('settings.trainings.lbl.image')}</h1>
          {!showUploadBtn && (
            <Form.File
              className={
                i18next.language === 'es'
                  ? 'btn-upload'
                  : 'btn-upload en-upload-style'
              }
              id="file-upload-box-input"
              label=""
              data-browse={t('settings.trainings.lbl.upload')}
              custom
              onChange={(event: any) => {
                onFileChange(event);
              }}
            />
          )}
        </div>
        <div className="box-image">
          <div className="upload-picture-container">
            {showPicture ? (
              <Card.Img
                variant="top"
                className="upload-picture"
                src={showPicture}
                alt={t('settings.trainings.lbl.image')}
              />
            ) : null}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default UploadImageBox;
