import {
  TEAMS_LOADING,
  TEAMS_FAIL,
  TEAMS_SUCCESS,
  TEAMS_DATA_SUCCESS,
  TEAMS_COUNT_SUCCESS,
  TEAMS_LEFT_DATA_SUCCES,
  TEAMS_SEARCH_SUCCESS,
  TEAMS_SELECT_CAPTURIST_SUCCESS,
  TEAMS_COORDINATOR_SUCCESS_ONE,
  TEAMS_TOTAL_CAMPAIGN_SUCCES,
  TEAMS_BREADCUM_SUCCES,
  TeamsDispatchTypes,
  COVERAGE_DATA,
  COVERAGE_LOADING,
  TAB_CHANGE,
} from './teamsTypes';
import { Team } from '../RgRc/RgRcTypes';

export interface CoverageLevel {
  _id: {
    level: number;
  };
  users: { _id: string; name: string; coordinates: [number, number] }[];
}
interface TeamsState {
  loading: boolean;
  page?: number;
  records?: number;
  sort?: any[];
  search: string;
  totalPage: number;
  totalTeamData: number;
  totalCampaign: number;
  teams: Team[];
  teamData: any[];
  teamLeftSide: any[];
  selectedCapturist?: any;
  coordinator: object;
  navigationPath: any[];
  breadcumCall: boolean;
  coverageMap: CoverageLevel[];
  coverageLoading: boolean;
  tabChange: string;
}

const defaultState: TeamsState = {
  loading: false,
  page: 1,
  records: 10,
  sort: ['firstName', 'asc'],
  search: '',
  totalPage: 1,
  totalTeamData: 0,
  totalCampaign: 0,
  teams: [],
  teamData: [],
  teamLeftSide: [],
  selectedCapturist: [],
  coordinator: {},
  navigationPath: [],
  breadcumCall: false,
  coverageMap: [],
  coverageLoading: false,
  tabChange: 'territorial.teams.tab.1',
};

const teamsReducer = (
  state: TeamsState = defaultState,
  action: TeamsDispatchTypes,
): TeamsState => {
  switch (action.type) {
    case TEAMS_FAIL:
      return {
        ...state,
        loading: false,
      };
    case TEAMS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case TEAMS_SUCCESS:
      const teams = [];
      const dataTeamsSuccess: any = action.payload;
      teams.push(...(dataTeamsSuccess.teams || []));
      return {
        ...state,
        teams: [...teams],
      };
    case TEAMS_COUNT_SUCCESS:
      const countTeamDataSuccess: any = action.payload;
      return {
        ...state,
        totalPage: countTeamDataSuccess.pages,
        totalTeamData: countTeamDataSuccess.totalCapturist,
      };
    case TEAMS_DATA_SUCCESS:
      const list_teamsCapturist = [];
      const dataTeamDataSuccess: any = action.payload;
      list_teamsCapturist.push(...(dataTeamDataSuccess.teamData || []));
      return {
        ...state,
        teamData: list_teamsCapturist,
        records: dataTeamDataSuccess.records,
        sort: ['firstName', dataTeamDataSuccess.order],
        page: dataTeamDataSuccess.page,
        loading: false,
      };
    case TEAMS_SEARCH_SUCCESS:
      return {
        ...state,
        search: action.searchValue,
      };
    case TEAMS_LEFT_DATA_SUCCES:
      const leftSide_teamsCapturist = [];
      const leftDataSuccess: any = action.payload;
      leftSide_teamsCapturist.push(...(leftDataSuccess.teamData || []));
      return {
        ...state,
        teamLeftSide: leftSide_teamsCapturist,
      };
    case TEAMS_COORDINATOR_SUCCESS_ONE:
      const coordinatorDataSuccess: any = action.payload;
      const followDataSuccess: any = action.follow;
      const subLevelDataSuccess: any = action.subLevel;
      let newPath = [...state.navigationPath];

      if (followDataSuccess !== '-1') {
        let toDelete = newPath.length - parseInt(followDataSuccess);
        newPath.splice(followDataSuccess, toDelete);
      } else {
        newPath.push(
          ...[
            {
              id: coordinatorDataSuccess._id,
              name:
                coordinatorDataSuccess.firstName +
                ' ' +
                coordinatorDataSuccess.lastName,
              role: coordinatorDataSuccess.role,
              teamName: coordinatorDataSuccess.teamName,
              teamId: coordinatorDataSuccess.teamId,
              subLevel: subLevelDataSuccess,
            },
          ],
        );
      }
      return {
        ...state,
        coordinator: coordinatorDataSuccess,
        navigationPath: newPath,
        loading: false,
      };
    case TEAMS_TOTAL_CAMPAIGN_SUCCES:
      const countTeamCampaignDataSuccess: any = action.payload;
      return {
        ...state,
        totalCampaign: countTeamCampaignDataSuccess.totalCampaign,
      };
    case TEAMS_BREADCUM_SUCCES:
      const breadcumSuccess: any = action.payload;
      return {
        ...state,
        navigationPath: breadcumSuccess.breadcum.reverse(),
        coordinator: breadcumSuccess.coordinator,
        breadcumCall: true,
      };
    case TEAMS_SELECT_CAPTURIST_SUCCESS:
      return {
        ...state,
        selectedCapturist: action.selectedCapturist,
      };

    case COVERAGE_DATA:
      return {
        ...state,
        coverageMap: [...action.coverageMap],
      };
    case COVERAGE_LOADING:
      return {
        ...state,
        coverageLoading: action.loading,
      };
    case TAB_CHANGE:
      return {
        ...state,
        tabChange: action.tabChange,
      };
    default:
      return state;
  }
};

export default teamsReducer;
