import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { GUEST_LOGIN } from './paths';

interface MyRouteProps extends RouteProps {
  component: any;
  authenticated: boolean;
  allowByRole: boolean;
  rest?: any;
}

const SuperRoute: React.FunctionComponent<MyRouteProps> = ({
  component: Component,
  authenticated,
  allowByRole,
  ...rest
}: any) => (
  <Route
    {...rest}
    render={(props) => {
      if (authenticated) {
        if (allowByRole) {
          return <Component {...props} />;
        } else {
          return <></>;
        }
      } else {
        return <Redirect to={GUEST_LOGIN} />;
      }
    }}
  />
);

export default SuperRoute;
