export const ROUTES_LOADING = 'ROUTES_LOADING';
export const ROUTES_FAIL = 'ROUTES_FAIL';
export const ROUTES_SUCCESS = 'ROUTES_SUCCESS';
export const ROUTES_SINGLE_SUCCESS = 'ROUTES_SINGLE_SUCCESS';
export const ROUTES_LOAD_SUCCESS = 'ROUTES_LOAD_SUCCESS';
export const ROUTES_GENERAL_LOAD_SUCCESS = 'ROUTES_GENERAL_LOAD_SUCCESS';
export const ROUTES_LOAD_LOADING = 'ROUTES_LOAD_LOADING';
export const ROUTES_LOAD_FAIL = 'ROUTES_LOAD_FAIL';
export const ROUTES_SELECT_AREA = 'ROUTES_SELECT_AREA';
export const UPDATE_OF_ENABLE = 'UPDATE_OF_ENABLE';

export type Routes = {
  _id: string;
  name: string;
  limit: number;
  limitType: number;
  enable: boolean;
  createdBy: string;
  datesWorked: Array<any>;
  members: Array<any>;
  coordinates: Array<any>;
  originalCoordinates: any;
  type?: string;
  geometry?: any;
};

export interface IRoutes {
  _id: string;
  coordinates: Array<any>;
}

export type Training = {
  _id: string;
  coordinates: Array<any>;
};

export interface RoutesLoadLoading {
  type: typeof ROUTES_LOAD_LOADING;
}
export interface RoutesLoadFail {
  type: typeof ROUTES_LOAD_FAIL;
}

export interface RoutesLoadSuccess {
  type: typeof ROUTES_LOAD_SUCCESS;
  createdAreas: Routes[];
}
export interface RoutesGeneralLoadSuccess {
  type: typeof ROUTES_GENERAL_LOAD_SUCCESS;
  generalAreas: Routes[];
}

export interface RoutesLoading {
  type: typeof ROUTES_LOADING;
}
export interface RoutesFail {
  type: typeof ROUTES_FAIL;
}

export interface RoutesSuccess {
  type: typeof ROUTES_SUCCESS;
  payload: Routes[];
}

export interface RoutesSingleSuccess {
  type: typeof ROUTES_SINGLE_SUCCESS;
  payload: Training[];
}

export interface RoutesSelectArea {
  type: typeof ROUTES_SELECT_AREA;
  selectedArea: any;
}

export interface UpdateOfEnable {
  type: typeof UPDATE_OF_ENABLE;
  payload: any;
}

export type RoutesDispatchTypes =
  | RoutesLoading
  | RoutesFail
  | RoutesSuccess
  | RoutesSingleSuccess
  | RoutesLoadLoading
  | RoutesLoadFail
  | RoutesLoadSuccess
  | RoutesSelectArea
  | RoutesGeneralLoadSuccess |UpdateOfEnable;
