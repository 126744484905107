//import axios from 'axios';
import { Dispatch } from 'react';
import * as NetWorking from '../netWorking';
import {
  PartiesDispatchTypes,
  SET_LOADING,
  SET_PARTIES_DATA,
} from './partiesTypes';

export const getParties = (_id: string, fun?: any) => async (
  dispatch: Dispatch<PartiesDispatchTypes>,
) => {
  dispatch({
    type: SET_LOADING,
    value: true,
  });
  NetWorking.get(
    NetWorking.getParty + _id,
    (res: any) => {
      dispatch({
        type: SET_PARTIES_DATA,
        parties: res.data.response,
      });
    },
    (error: any) => {
      dispatch({
        type: SET_LOADING,
        value: false,
      });
    },
  );
};

export const uploadParties = (data: any, fun?: any) => async (
  dispatch: Dispatch<PartiesDispatchTypes>,
) => {
  dispatch({
    type: SET_LOADING,
    value: true,
  });
  NetWorking.post(
    NetWorking.uploadParty,
    data,
    (res: any) => {
      console.log(res);
      fun(res.data.response);
    },
    (error: any) => {
      dispatch({
        type: SET_LOADING,
        value: false,
      });
    },
  );
};

export const createParties = (data: any, fun?: any, err?: any) => async (
  dispatch: Dispatch<PartiesDispatchTypes>,
) => {
  dispatch({
    type: SET_LOADING,
    value: true,
  });
  NetWorking.post(
    NetWorking.postParty,
    data,
    (res: any) => {
      console.log(res);
      fun();
    },
    (error: any) => {
      err(error);
      dispatch({
        type: SET_LOADING,
        value: false,
      });
    },
  );
};

export const updateParties = (data: any, fun?: any, err?: any) => async (
  dispatch: Dispatch<PartiesDispatchTypes>,
) => {
  dispatch({
    type: SET_LOADING,
    value: true,
  });
  NetWorking.put(
    NetWorking.postParty,
    data,
    (res: any) => {
      console.log(res);
      fun();
    },
    (error: any) => {
      err(error);
      dispatch({
        type: SET_LOADING,
        value: false,
      });
    },
  );
};

export const deleteParties = (_id: any, fun?: any) => async (
  dispatch: Dispatch<PartiesDispatchTypes>,
) => {
  dispatch({
    type: SET_LOADING,
    value: true,
  });
  NetWorking.deleteRequest(
    NetWorking.postParty + _id,
    (res: any) => {
      console.log(res);
      fun();
    },
    (error: any) => {
      dispatch({
        type: SET_LOADING,
        value: false,
      });
    },
  );
};
