import React from 'react';
import './style.scss';
import { useDropzone } from 'react-dropzone';
import Button from 'react-bootstrap/Button';
import * as netWorking from '../../redux/netWorking';
import { useTranslation } from 'react-i18next';
import {
  UploadIcon,
  UploadLoading,
  UploadSuccess,
} from '../../assets/svg/index';

interface DropzoneProps {
  setRouteFile: any;
  type: string;
  municipalityId: any;
  cityLicenseId: any;
}

const Dropzone: React.FunctionComponent<DropzoneProps> = ({
  setRouteFile,
  type,
  municipalityId,
  cityLicenseId,
}: any) => {
  const [uploadProgress, setUploadProgress] = React.useState(0);
  const [uploadStatus, setUploadStatus] = React.useState('NONE');
  const [uploadProblem, setUploadProblem] = React.useState('');

  const { t } = useTranslation();

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    accept:
      '.xls, .xlsx, application/excel, application/vnd.ms-excel, application/x-excel, application/x-msexcel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    onDrop: (acceptedFile: any) => {
      if (acceptedFile.length > 0) {
        let formData = new FormData();
        formData.append('file', acceptedFile[0]);
        formData.append('type', type);
        setUploadStatus('LOADING');
        netWorking.sendRequest(
          netWorking.UploadVoterBox +
            '/' +
            t('language') +
            '/' +
            type +
            '/' +
            municipalityId +
            '/' +
            cityLicenseId +
            '/' +
            localStorage.campaignId,
          formData,
          setUploadProgress,
          setUploadStatus,
          setRouteFile,
          false,
          setUploadProblem,
          (err: any) => {
            console.log('err XLS', err.response.data);
            setUploadProblem(err.response.data.errorMessage);
          },
        );
      }
    },
  });

  //   const files = acceptedFiles.map((file) => (
  //     <li key={file.name}>
  //       {file.name} - {file.size} bytes
  //     </li>
  //   ));

  return (
    <div className="container dropzone-container">
      {uploadStatus === 'NONE' && (
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          <UploadIcon></UploadIcon>
          <h2>{t('XLS.modal.lbl.dropFilesHere')}</h2>
          <Button type="button" onClick={open} variant="primary">
            {t('XLS.modal.lbl.searchFile')}
          </Button>
          {uploadProblem && (
            <p className="XLS-error-message">{uploadProblem}</p>
          )}
        </div>
      )}
      {uploadStatus === 'LOADING' && (
        <div className="loading-file-box">
          <UploadLoading></UploadLoading>
          <h2>{t('XLS.modal.lbl.uploading')}</h2>
          <div className="loading-bar">
            <div className="bar" style={{ width: `${uploadProgress}%` }}></div>
          </div>
          {/* <p>Loading {uploadProgress}</p> */}
        </div>
      )}
      {uploadStatus === 'DONE' && (
        <div {...getRootProps({ className: 'dropzone done-file-box' })}>
          <input {...getInputProps()} />
          <UploadSuccess></UploadSuccess>
          <h2>{t('XLS.modal.lbl.fileUploaded')}</h2>
          <p>{acceptedFiles[0]?.name}</p>
          {/* <Button type="button" onClick={open} variant="primary">
            {t('XLS.modal.lbl.replace')}
          </Button> */}
        </div>
      )}
      {/* <aside>
        <h4>Files</h4>
        <ul>{files}</ul>
      </aside> */}
    </div>
  );
};

export default Dropzone;
