import React from 'react';
import { useDispatch } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import StaticModal from '../../../../../dialogs/StaticModal';
import { deleteParties } from '../../../../../redux/parties/partiesActions';

interface AddModalProps {
  modalShow: any;
  setModalShow: any;
  successFun: any;
  toDelete: any;
}

export const DeletePartyModalComponent: React.FunctionComponent<AddModalProps> = ({
  modalShow,
  setModalShow,
  successFun,
  toDelete,
}: AddModalProps) => {
  const dispatch = useDispatch();
  const cancelFun = () => {
    setModalShow(false);
  };

  const submitFun = () => {
    successFun();
    setModalShow(false);
  };

  const submitDelete = () => {
    dispatch(deleteParties(toDelete._id, submitFun));
  };

  return (
    <StaticModal
      show={modalShow}
      title={'Delete Party'}
      closeText={'Cancel'}
      confirmText={'Delete'}
      onHide={cancelFun}
      submitFun={submitDelete}
      validBtn={false}
      size="sm"
      sizeClass="delete-license-modal"
    >
      <Row noGutters className="modal-text-row">
        <Col xs={12} className="description-col">
          <div className="text-description">
            Are you sure you want to delete <strong>{toDelete.name}</strong> ?
          </div>
        </Col>
      </Row>
    </StaticModal>
  );
};

export default DeletePartyModalComponent;
