import React, { FunctionComponent } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../style.scss';
import StaticModal from '../../../dialogs/StaticModal';
import { useTranslation } from 'react-i18next';

interface ModalApproveFileProps {
  modalShow: boolean;
  setModalShow: any;
  successFun: any;
}

export const ModalApproveFile: FunctionComponent<ModalApproveFileProps> = ({
  modalShow,
  setModalShow,
  successFun,
}: any) => {
  const { t } = useTranslation();

  //   useEffect(() => {
  //     if (!loadData) {
  //       if (edit !== undefined) {
  //         console.log("entro");
  //         setToSave(edit);
  //       }
  //       getMunicipality(true);
  //       setLoadData(true);
  //     }
  //   }, [loadData]);

  const submitFun = () => {};

  return (
    <StaticModal
      show={modalShow}
      title={t('settings.votersList.modal.approve.title')}
      closeText={t('submit.cancel')}
      confirmText={t('submit.save')}
      onHide={() => setModalShow(false)}
      submitFun={submitFun}
      validBtn={false}
      size="lg"
      sizeClass="md modal-rc"
    >
      <>
        <Container className="body-modal">
          <Row noGutters className="modal-text-row">
            <Col xs={12} className="description-col">
              {t('settings.votersList.modal.approve.description')}
            </Col>
            <Col xs={12} className="question-col">
              <strong>{t('settings.votersList.modal.approve.question')}</strong>
            </Col>
          </Row>
        </Container>
      </>
    </StaticModal>
  );
};

export default ModalApproveFile;
