import React, { useState, useEffect } from 'react';
// import Logo from "../../../assets/img/logo.png";
import * as netWorking from '../../redux/netWorking';
import { Button, Form, Input } from 'antd';
import { Eye, ClosedEye } from '../../assets/svg/index';
import NotificationDialog from '../../dialogs/Notification-dialog';
import { useTranslation } from 'react-i18next';
import Image from 'react-bootstrap/Image';
import GioobyLogo from '../../assets/logo/logo_giooby.png';
import { passwordValidation } from '../../utils/utils';
import * as F from 'react-bootstrap/Form';
import PinField from 'react-pin-field';

const FormItem = Form.Item;

const PasswordConfirmationView = (props: any) => {
  const { t } = useTranslation();
  const [state, setValues] = useState({
    eyeActive: false,
    password: '',
    newPassword: '',
    code: '',
    eyeActive2: false,
    isLoading: false,
    isSent: false,
    isError: false,
    errorMessage: '',
    successMessage: '',
    failPasswordMessage: null,
    time: 0,
    isOn: false,
    start: 0,
    checked: false,
  });
  const myRef = React.createRef<Input>();

  const [passValidation, setPassValidation] = useState(0);
  const [pass2Validation, setPass2Validation] = useState(0);
  const [codeValidation, setCodeValidation] = useState(0);

  useEffect(() => {
    // Verify the password reset code is valid.
    if (!state.checked) {
      myRef.current?.focus();
      setValues((prevState) => ({
        ...prevState,
        checked: true,
        password: '',
        newPassword: '',
        code: '',
      }));

      // netWorking.post(
      //   netWorking.CheckCode,
      //   { actionCode: props.actionCode },
      //   (result: any) => {
      //     if (result.data.response.error === 0) {
      //       setValues((prevState) => ({
      //         ...prevState,
      //         validCode: true,
      //         verifiedCode: true,
      //       }));
      //     } else {
      //       // setState({ email, validCode: true, verifiedCode: true });
      //       // Invalid or expired action code. Ask user to try to reset the password
      //       // again.
      //       setValues((prevState) => ({
      //         ...prevState,
      //         error: t('error.message.outdated.link'),
      //         validCode: false,
      //         verifiedCode: true,
      //         isError: true,
      //         errorMessage: t('error.message.outdated.link'),
      //       }));
      //       setTimeout(() => {
      //         props.history.push('/');
      //       }, 1000);
      //     }
      //   },
      //   (error: any) => {
      //     console.log(error);
      //   },
      // );
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.checked]);

  const handleResetPassword = (event: any) => {
    event.preventDefault();
    // const newPassword = state.password;
    // Save the new password.
    validateForm('password', state.password);
    validateForm('newPassword', state.newPassword);
    validateForm('code', state.code);
    if (!validation()) {
      if (state.password !== state.newPassword) {
        setValues((prevState) => ({
          ...prevState,
          failPasswordMessage: t('error.message.passwords.dont.match'),
        }));
      } else {
        netWorking.post(
          netWorking.resetPwd,
          {
            _id: localStorage.tempUserId,
            pin: state.code,
            password: state.newPassword.trim(),
          },
          (result: any) => {
            // Password reset has been confirmed and new password updated.
            if (result.data.response.error === 0) {
              setValues((prevState) => ({
                ...prevState,
                success: true,
                isSent: true,
                password: '',
                newPassword: '',
                successMessage: t('success.message.password.change'),
              }));
              setTimeout(() => {
                localStorage.removeItem('tempUserId');
                props.history.push('/');
              }, 1000);
            } else {
              // Error occurred during confirmation. The code might have expired or the
              // password is too weak.
              setValues((prevState) => ({
                ...prevState,
                error: t('error.message.while.changing'),
                isSent: false,
                isError: true,
                errorMessage: t('error.message.while.changing'),
                password: '',
                newPassword: '',
              }));
            }
          },
          (error: any) => {
            console.log('error', error);
            if (error.response)
              if (error.response.request.status === 400) {
                if (
                  error.response.data.errorCode === 3004 ||
                  error.response.data.errorCode === 3001
                ) {
                  setCodeValidation(3);
                }
              }
          },
        );
      }
    }
  };

  const clearMessage = () => {
    setValues((prevState) => ({
      ...prevState,
      isSent: false,
    }));
  };

  const clearError = () => {
    setValues((prevState) => ({
      ...prevState,
      isError: false,
    }));
  };

  const handlePasswordForm = (name: string, event: any) => {
    const value = event.target.value;
    event.preventDefault();
    validateForm(name, value);
    if (value.length <= 40)
      setValues((prevState) => ({
        ...prevState,
        [name]: value,
        loginStatus: '',
        validUser: '',
      }));
  };

  const validateForm = (name: string, value: any) => {
    if (name === 'code') {
      if (value === '') {
        setCodeValidation(1);
        return false;
      } else if (value.length < 4) {
        setCodeValidation(2);
        return false;
      } else {
        setCodeValidation(0);
        return true;
      }
    }
    if (name === 'password') {
      if (value === '') {
        setPassValidation(1);
        return false;
      } else if (!passwordValidation.test(value)) {
        setPassValidation(2);
        return false;
      } else {
        setPassValidation(0);
        return true;
      }
    }
    if (name === 'newPassword') {
      if (value === '') {
        setPass2Validation(1);
        return false;
      } else if (!passwordValidation.test(value)) {
        setPass2Validation(2);
        return false;
      } else if (value !== state.password) {
        setPass2Validation(3);
        return false;
      } else {
        setPass2Validation(0);
        return true;
      }
    }
  };

  const getErrorCodeText = () => {
    switch (codeValidation) {
      case 0:
        return '';
      case 1:
        return '* ' + t('login.wrong.code.1');
      case 2:
        return '* ' + t('login.wrong.code.1');
      case 3:
        return '* ' + t('login.wrong.code.3');
      default:
        return '';
    }
  };

  const getErrorPassText = () => {
    switch (passValidation) {
      case 0:
        return '';
      case 1:
        return '* ' + t('login.wrong.pass.1');
      case 2:
        return '* ' + t('login.wrong.pass.2');
      default:
        return '';
    }
  };

  const getErrorPass2Text = () => {
    switch (pass2Validation) {
      case 0:
        return '';
      case 1:
        return '* ' + t('login.wrong.pass.1');
      case 2:
        return '* ' + t('login.wrong.pass.2');
      case 3:
        return '* ' + t('login.wrong.pass.3');
      default:
        return '';
    }
  };

  const validation = () => {
    if (
      state.password !== '' &&
      state.newPassword !== '' &&
      passwordValidation.test(state.password) &&
      passwordValidation.test(state.newPassword) &&
      state.code !== '' &&
      state.code.length === 4
    ) {
      return false;
    } else return true;
  };

  return (
    <div className="login-background">
      <div className="login">
        <Form className="login-box password-confirmation">
          <div className="login-logo">
            <Image src={GioobyLogo} fluid />
          </div>
          <div className="data-box">
            <div className="data-box-title">
              <div className="title">{t('login.new.password')}</div>
              <div className="sub-title">
                {t('login.introduce.new.password')}
              </div>
            </div>
            <div className="inputs-box">
              <div className="pin-box">
                <div>{t('login.new.code')}</div>
                <div className="inputs">
                  <PinField
                    onChange={(event: any) => {
                      validateForm('code', event);
                      setValues((prevState) => ({
                        ...prevState,
                        code: event,
                        loginStatus: '',
                        validUser: '',
                      }));
                    }}
                    length={4}
                  />
                </div>
                <span className="error-span">
                  {codeValidation !== 0 && getErrorCodeText()}
                </span>
              </div>
              <div className="password-box">
                <div>{t('login.new.password')}</div>
                <FormItem>
                  <F.default.Control
                    placeholder={''}
                    value={state.password}
                    maxLength={50}
                    type={state.eyeActive ? '' : 'password'}
                    onChange={(event: any) =>
                      handlePasswordForm('password', event)
                    }
                  />
                  <div className="password-eye-box">
                    {state.eyeActive ? (
                      <Eye
                        className="eye-deactive password-eye"
                        onClick={() => {
                          setValues((prevState) => ({
                            ...prevState,
                            eyeActive: !state.eyeActive,
                          }));
                        }}
                      ></Eye>
                    ) : (
                      <ClosedEye
                        className="eye-deactive password-eye-closed"
                        onClick={() => {
                          setValues((prevState) => ({
                            ...prevState,
                            eyeActive: !state.eyeActive,
                          }));
                        }}
                      ></ClosedEye>
                    )}
                  </div>
                  {/* <Input
                    ref={myRef}
                    suffix={
                      state.eyeActive ? (
                        <Eye
                          className="eye-deactive password-eye"
                          onClick={() => {
                            setValues((prevState) => ({
                              ...prevState,
                              eyeActive: !state.eyeActive,
                            }));
                          }}
                        ></Eye>
                      ) : (
                        <ClosedEye
                          className="eye-deactive password-eye-closed"
                          onClick={() => {
                            setValues((prevState) => ({
                              ...prevState,
                              eyeActive: !state.eyeActive,
                            }));
                          }}
                        ></ClosedEye>
                      )
                    }
                    value={state.password}
                    placeholder=""
                    autoFocus
                    className="password-login"
                    onChange={(event) => handlePasswordForm('password', event)}
                    type={state.eyeActive ? '' : 'password'}
                  /> */}
                  <span className="error-span">
                    {passValidation !== 0 && getErrorPassText()}
                  </span>
                </FormItem>
              </div>
              <div className="password-box">
                <div>{t('login.new.password.confirm')}</div>
                <FormItem hasFeedback help={state.failPasswordMessage}>
                  <F.default.Control
                    placeholder={''}
                    value={state.newPassword}
                    maxLength={50}
                    type={state.eyeActive2 ? '' : 'password'}
                    onChange={(event: any) =>
                      handlePasswordForm('newPassword', event)
                    }
                  />
                  <div className="password-eye-box">
                    {state.eyeActive2 ? (
                      <Eye
                        className="eye-deactive password-eye"
                        onClick={() => {
                          setValues((prevState) => ({
                            ...prevState,
                            eyeActive2: !state.eyeActive2,
                          }));
                        }}
                      ></Eye>
                    ) : (
                      <ClosedEye
                        className="eye-deactive password-eye-closed"
                        onClick={() => {
                          setValues((prevState) => ({
                            ...prevState,
                            eyeActive2: !state.eyeActive2,
                          }));
                        }}
                      ></ClosedEye>
                    )}
                  </div>
                  {/* <Input
                    suffix={
                      state.eyeActive2 ? (
                        <Eye
                          className="eye-deactive password-eye"
                          onClick={() => {
                            setValues((prevState) => ({
                              ...prevState,
                              eyeActive2: !state.eyeActive2,
                            }));
                          }}
                        ></Eye>
                      ) : (
                        <ClosedEye
                          className="eye-deactive password-eye-closed"
                          onClick={() => {
                            setValues((prevState) => ({
                              ...prevState,
                              eyeActive2: !state.eyeActive2,
                            }));
                          }}
                        ></ClosedEye>
                      )
                    }
                    value={state.newPassword}
                    placeholder=""
                    className="password-login"
                    onChange={(event) =>
                      handlePasswordForm('newPassword', event)
                    }
                    type={state.eyeActive2 ? '' : 'password'}
                  />{' '} */}
                  <span className="error-span">
                    {pass2Validation !== 0 && getErrorPass2Text()}
                  </span>
                </FormItem>
              </div>
            </div>
            <div className="btn-box">
              <span
                className="click-forgot"
                onClick={() => props.history.push('/')}
              >
                {t('name.sign.in')}
              </span>
              <FormItem>
                <Button
                  type="primary"
                  htmlType="submit"
                  className={'btn-done'}
                  onClick={(event) => handleResetPassword(event)}
                  // loading={state.isLoading}
                >
                  {t('submit.confirm')}
                </Button>
              </FormItem>
            </div>
          </div>
          <div className="copyright">
            &copy; {t('copyright.footer')}{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://sdsharp.com/"
            >
              Giooby.com
            </a>
          </div>
        </Form>

        {state.isSent && (
          <NotificationDialog
            type={1}
            successMessage={state.successMessage}
            clearMessage={clearMessage}
          ></NotificationDialog>
        )}
        {state.isError && (
          <NotificationDialog
            type={2}
            clearMessage={clearError}
            errorMessage={state.errorMessage}
          ></NotificationDialog>
        )}
      </div>{' '}
    </div>
  );
};

export default PasswordConfirmationView;
