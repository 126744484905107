import React, { Fragment, useEffect, useState, useContext } from 'react';
import * as netWorking from '../../../redux/netWorking';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../redux/Store';
import { useTranslation } from 'react-i18next';

import { INCIDENCE_TYPE } from '../../../utils/utils';

import SelectorFormsComponent from '../../../components/sub-component/selectorForms';
import StaticModal from '../../../dialogs/StaticModal';
import Container from 'react-bootstrap/Container';

interface ModalAssignLawyerProps {
  modalShow: any;
  setModalShow: any;
  successFun: any;
  edit?: any;
}

export const ModalAssignLawyer: React.FunctionComponent<ModalAssignLawyerProps> = ({
  modalShow,
  setModalShow,
  successFun,
  edit,
}: any) => {
  const citySelected = useSelector((globalState: RootStore) => {
    return globalState.core.globalMunicipalitySelected;
  });

  const { t } = useTranslation();

  const defaultEdit = {
    _id: '',
    lawyerId: '',
    status: '',
  };

  const arrayLawyers: any = [
    { _id: '', name: t('settings.trainings.form.select') },
  ];

  const [toSave, setToSave] = useState(defaultEdit);
  const [loadData, setLoadData] = useState(false);

  const [submitMessage, setSubmitMessage] = useState(String);
  const [submitConfirmation, setSubmitConfirmation] = useState(false);
  const [lawyerArray, setLawyersArray] = useState(arrayLawyers);

  const getLawyers = () => {
    netWorking.post(
      netWorking.postListAllLawyersByCampaign,
      {},
      (res: any) => {
        if (res.data.response[0]) {
          const arrayData = res.data.response;
          arrayData.unshift({
            _id: '',
            name: t('settings.trainings.form.select'),
          });

          if (toSave.lawyerId === undefined || toSave.lawyerId === '') {
            setToSave((prevState) => ({
              ...prevState,
              lawyerId: arrayData[0]._id,
            }));
          }

          setLawyersArray(arrayData);
        } else {
          setLawyersArray(arrayLawyers);
          setToSave((prevState) => ({
            ...prevState,
            lawyerId: '',
          }));
        }
      },
      (error: any) => {
        setLawyersArray(arrayLawyers);
        setToSave((prevState) => ({
          ...prevState,
          lawyerId: '',
        }));
      },
    );
  };

  const submitFun = (validSubmit: any) => {
    if (validSubmit) {
      setSubmitMessage(validSubmit);
      setSubmitConfirmation(true);
    } else {
      netWorking.post(
        netWorking.postAssignProblemGeneral,
        {
          _id: toSave._id,
          lawyerId: toSave.lawyerId,
          status: toSave.status,
        },
        (res: any) => {
          successFun();
          setModalShow(false);
        },
        (err: any) => {},
      );
    }
  };

  const validSubmit = () => {
    const newStringValue = verifyFields();
    submitFun(newStringValue);
  };

  const verifyFields = () => {
    if (toSave.lawyerId === '') {
      return t('dday.polling.modal.error.lawyer');
    } else {
      return '';
    }
  };

  useEffect(() => {
    if (edit !== undefined) {
      setToSave((prevState) => ({
        ...prevState,
        _id: edit._id,
        status: INCIDENCE_TYPE.ASSIGNED,
      }));
    }
    setLoadData(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edit]);

  useEffect(() => {
    if (loadData) {
      getLawyers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadData]);

  return (
    <StaticModal
      show={modalShow}
      title={t('dday.polling.modal.title')}
      closeText={t('submit.cancel')}
      confirmText={t('general.modal.btn.confirm')}
      onHide={() => {
        setModalShow(false);
      }}
      submitFun={validSubmit}
      validBtn={false}
      size="sm"
      sizeClass="md modal-rc"
    >
      <>
        <Container className="dialog-assign-lawyer">
          {submitConfirmation && (
            <div className="col-item-message">
              <p>{submitMessage}</p>
            </div>
          )}
          <div className="row-item-modal first-row">
            <div className="col-item-modal">
              <SelectorFormsComponent
                key={3}
                title={t('electoral.lawyers.lbl.singularLawyer')}
                defaultSelector={toSave.lawyerId}
                options={lawyerArray}
                horizontal={false}
                fun={(value: string) => {
                  setToSave((prevState) => ({
                    ...prevState,
                    lawyerId: value,
                  }));
                }}
              ></SelectorFormsComponent>
            </div>
          </div>
        </Container>
      </>
    </StaticModal>
  );
};

export default ModalAssignLawyer;
