export const AUDITORY_LOADING = 'AUDITORY_LOADING';
export const AUDITORY_FAIL = 'AUDITORY_FAIL';
export const AUDITORY_SUCCESS = 'AUDITORY_SUCCESS';
export const AUDITORY_COUNT_SUCCESS = 'AUDITORY_COUNT_SUCCESS';
export const AUDITORY_SUCCESS_ONE = 'AUDITORY_SUCCESS_ONE';
export const AUDITORY_PAGE_SUCCESS = 'AUDITORY_PAGE_SUCCESS';
export const AUDITORY_SEARCH_SUCCESS = 'AUDITORY_SEARCH_SUCCESS';
export const AUDITORY_OPEN = 'AUDITORY_OPEN';
export const AUDITORY_PAGE_RESET = 'AUDITORY_PAGE_RESET';

export type Voters = {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  cellphone: string;
  countryCode: string;
  supportInNetworks: string;
  mostUsedNetwork: string;
  address: string;
};

export type SearchVotersAuditory = {
  cityLicenseId: string;
  page: number;
  records?: number;
  field: string;
  order: string;
  search: string;
  filters: object;
  nextElectionsYear: number;
};

export type ConfirmUnauditedVoter = {
  voterArray: Array<any>;
}

export type DenyUnauditedVoter = {
  voterArray: Array<any>;
}

export interface AuditoryLoading {
  type: typeof AUDITORY_LOADING;
}
export interface AuditoryFail {
  type: typeof AUDITORY_FAIL;
}

export interface AuditorySuccess {
  type: typeof AUDITORY_SUCCESS;
  payload: Voters[];
}

export interface AuditoryCountSuccess {
  type: typeof AUDITORY_COUNT_SUCCESS;
  payload: object;
}

export interface AuditorySuccessOne {
  type: typeof AUDITORY_SUCCESS_ONE;
  payload: object;
}

export interface AuditoryPageSuccess {
  type: typeof AUDITORY_PAGE_SUCCESS;
  page: string;
}

export interface AuditorySearchSuccess {
  type: typeof AUDITORY_SEARCH_SUCCESS;
  searchValue: string;
}

export interface AuditoryOpen {
  type: typeof AUDITORY_OPEN;
  open: boolean;
}

export interface AuditoryPageReset {
  type: typeof AUDITORY_PAGE_RESET;
  page: number;
}

export type AuditoryDispatchTypes =
  | AuditoryLoading
  | AuditoryFail
  | AuditorySuccess
  | AuditoryCountSuccess
  | AuditoryPageSuccess
  | AuditorySuccessOne
  | AuditorySearchSuccess
  | AuditoryOpen
  | AuditoryPageReset;
