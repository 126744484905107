import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import StaticModal from '../../../../../dialogs/StaticModal';
import { countries } from 'country-flags-svg';
import { createCountry } from '../../../../../redux/coreLicense/coreLicenseActions';
import SelectorCustomComponent from '../../../../components/SelectorCustom';

interface AddModalProps {
  modalShow: any;
  setModalShow: any;
  successFun: any;
}

// tslint:disable-next-line: prefer-const
let defaultFile: any;

export const AddCountryModalComponent: React.FunctionComponent<AddModalProps> = ({
  modalShow,
  setModalShow,
  successFun,
}: AddModalProps) => {
  const defaultEdit = {
    _id: '',
    name: '',
    countryCode: '',
  };

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [submitConfirmation, setSubmitConfirmation] = useState(false);
  const [submitMessage, setSubmitMessage] = useState(String);
  const [toSave, setToSave] = useState(defaultEdit);
  const [load, setLoad] = useState(false);
  const [, setToCompare] = useState(defaultEdit);
  const [countryS, setCountry] = useState(defaultFile);
  const [, setImage] = useState('');
  const [, setFile] = useState(defaultFile);

  useEffect(() => {
    if (!load && modalShow) {
      setToSave(defaultEdit);
      setToCompare(defaultEdit);
      setImage('');
      setFile(defaultFile);
      setSubmitConfirmation(false);
      setSubmitMessage('');
      setLoad(true);
    }
    if (!modalShow) {
      setLoad(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [load, modalShow]);

  const cancelFun = () => {
    setModalShow(false);
  };

  const submitFun = () => {
    if (validSubmit(1)) {
      setSubmitMessage(validSubmit(1));
      setSubmitConfirmation(true);
    } else {
      if (toSave._id === '') onSave();
    }
  };

  const validSubmit = (data: any) => {
    if (countryS === undefined) {
      return 'Country not selected';
    } else {
      return '';
    }
  };

  const onSave = () => {
    const save = { name: countryS.name, countryCode: countryS.iso3 };
    dispatch(
      createCountry(
        save,
        (res: any) => {
          setModalShow(false);
          successFun();
        },
        (error: any) => {
          if (error.response.request.status === 400) {
            if (error.response.data.errorMessage === 'Duplicated Data') {
              setSubmitMessage('Country Already registered');
              setSubmitConfirmation(true);
            }
          }
        },
      ),
    );
  };

  return (
    <StaticModal
      show={modalShow}
      title={'Add Country'}
      closeText={'Cancel'}
      confirmText={'Save'}
      onHide={cancelFun}
      submitFun={submitFun}
      validBtn={false}
      customTitle={
        <div className="header-modal-add-admin">
          <Modal.Title className="title-border-bottom">
            <strong>Add Country</strong>
          </Modal.Title>
        </div>
      }
      size="sm"
      sizeClass="add-admin-modal add-party-modal add-country-modal"
    >
      <Row
        noGutters
        className="addModal-content modal-text-row add-campaign-modal"
      >
        {submitConfirmation && (
          <Col xs={12} md={12} className="col-item-message">
            <p className="">{submitMessage}</p>
          </Col>
        )}{' '}
        <Col xs={12} md={12} className="col-item-modal">
          <SelectorCustomComponent
            title="Cities"
            horizontal={false}
            filters={countries}
            filtersSelected={countryS}
            fun={(value: any) => {
              setCountry(value);
            }}
            t={t}
          ></SelectorCustomComponent>
        </Col>
      </Row>
    </StaticModal>
  );
};

export default AddCountryModalComponent;
