import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './style.scss';
import LayoutBodyComponent from '../../../components/LayoutBody';
import { BsSearch } from 'react-icons/bs';
import ListCountries from './components/listCountries';
import TopBarS from '../../components/TopBarSComponent';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import LicenseItem from './components/licenseItem';
import SelectorMultipleComponent from '../../components/SelectorMultiple';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../redux/Store';
import {
  getCountries,
  getLicenses,
  getProblems,
} from '../../../redux/campaigns/campaignsActions';
import AddCampaignModalComponent from './components/addCampaign';
import { Campaign } from '../../../redux/campaigns/campaignsTypes';
import { DeleteLicenseModalComponent } from './components/deleteLicense';
import { getParties } from '../../../redux/parties/partiesActions';
import AccessLicenseModalComponent from './components/accessLicense';

// tslint:disable-next-line: prefer-const
let emptyArray: any[] = [];

const Campaigns: React.FunctionComponent = () => {
  const { t, i18n } = useTranslation();

  /*const states = useSelector((globalState: RootStore) => {
    return globalState.core.stateD;
  });
  const municipality = useSelector((globalState: RootStore) => {
    return globalState.core.municipality;
  });*/

  const countries = useSelector((globalState: RootStore) => {
    return globalState.campaignsManager.countries;
  });

  const licenses = useSelector((globalState: RootStore) => {
    return globalState.campaignsManager.licenses;
  });

  const countrySelected = useSelector((globalState: RootStore) => {
    return globalState.campaignsManager.selectedCountry;
  });

  const parties = useSelector((globalState: RootStore) => {
    return globalState.party.parties;
  });

  const dispatch = useDispatch();
  const [loadData, setLoadData] = React.useState(false);
  const [firstCall, setFirstCall] = React.useState(false);
  const [showAddModal, setShowAddModal] = React.useState(false);
  const [showAccessModal, setShowAccessModal] = React.useState(false);
  const [toAccess, setToAccess] = React.useState(Object);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [toDeleteLicense, setToDeleteLicense] = React.useState(Object);
  const [toEditLicense, setToEditLicense] = React.useState(Object);
  const [partiesSelected, setPartiesSelected] = React.useState(emptyArray);

  const [actives, setActives] = React.useState(false);
  const [deActives, setDeActives] = React.useState(false);
  const [search, setSearch] = React.useState('');

  useEffect(() => {
    if (!loadData) {
      i18n.changeLanguage('en');
      localStorage.setItem('language', 'en');
      dispatch(getCountries(getState));
      setLoadData(true);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadData]);

  useEffect(() => {
    if (parties) setPartiesSelected(parties); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parties]);

  useEffect(() => {
    setFirstCall(true);
    if (firstCall) {
      getState(countrySelected.country._id);
      dispatch(getParties(countrySelected.country._id));
      dispatch(getProblems());
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countrySelected]);

  useEffect(() => {
    if (countrySelected !== undefined)
      dispatch(getLicenses(countrySelected.country._id)); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countrySelected]);

  const getState = (id: string) => {
    console.log(id);
    // dispatch(getStates(id, getMuni));
  };

  // const getMuni = (id: string) => {
  //   dispatch(getMunicipality(id, () => {}));
  // };

  const submitLicense = () => {
    if (countrySelected !== undefined)
      dispatch(getLicenses(countrySelected.country._id));
    dispatch(getCountries(getState));
    setShowAddModal(false);
  };

  const searchFun = (data: Campaign) => {
    if (
      data.bossName.toLowerCase().includes(search.toLowerCase()) ||
      data.contact.toLowerCase().includes(search.toLowerCase()) ||
      data.email.toLowerCase().includes(search.toLowerCase()) ||
      data.name.toLowerCase().includes(search.toLowerCase()) ||
      data.candidateName.toLowerCase().includes(search.toLowerCase())
    )
      return true;
    else return false;
  };

  const partiesSelectedFun = (party: any) => {
    let inArray = false;
    const newArray: any = [];
    partiesSelected.forEach((e: any) => {
      if (e._id !== party._id) {
        newArray.push(e);
      } else {
        inArray = true;
      }
    });
    if (!inArray) {
      newArray.push(party);
    }
    setPartiesSelected(newArray);
  };

  return (
    <>
      <LayoutBodyComponent>
        <ListCountries
          countries={countries}
          countrySelected={countrySelected}
        ></ListCountries>
        <div className="body-country">
          <TopBarS
            countrySelected={countrySelected}
            submit={() => {
              setToEditLicense(undefined);
              setShowAddModal(true);
            }}
          ></TopBarS>
          <div className="body-list-campaings">
            <div className="top">
              <div className="filters-campaigns">
                <div
                  className={actives ? 'item-filter-c active' : 'item-filter-c'}
                  onClick={() => {
                    setActives((prevStates) => !prevStates);
                  }}
                >
                  <div className="active-circle"></div>
                  <p>
                    {
                      // eslint-disable-next-line
                      licenses.filter((e: Campaign) => {
                        if (e.active) return e;
                      }).length
                    }{' '}
                    Active
                  </p>
                </div>
                <div
                  className={
                    deActives ? 'item-filter-c active' : 'item-filter-c'
                  }
                  onClick={() => {
                    setDeActives((prevStates) => !prevStates);
                  }}
                >
                  <div className="de-active-circle"></div>
                  <p>
                    {
                      // eslint-disable-next-line
                      licenses.filter((e: Campaign) => {
                        if (!e.active) return e;
                      }).length
                    }{' '}
                    De-Active
                  </p>
                </div>
              </div>
              <div className="filters-search-box">
                <SelectorMultipleComponent
                  title="Parties"
                  horizontal={true}
                  filters={parties}
                  filtersSelected={partiesSelected}
                  fun={(value: any) => {
                    // getVoBo(value);
                    partiesSelectedFun(value);
                  }}
                  t={t}
                ></SelectorMultipleComponent>
                <Form inline className={'tab-search-field-normal'}>
                  <FormControl
                    defaultValue={''}
                    type="text"
                    placeholder={'Search'}
                    className="mr-sm-2"
                    onChange={(data) => {
                      // funOnInit(data.target.value);
                      setSearch(data.target.value);
                    }}
                  />
                  <div className="icon">
                    <BsSearch />
                  </div>
                </Form>
              </div>
            </div>
            <div className="list">
              {licenses // eslint-disable-next-line
                .filter((e: Campaign) => {
                  if (
                    e.active &&
                    actives &&
                    searchFun(e) &&
                    partiesSelected.find((d) => d._id === e.partyId) !==
                      undefined
                  )
                    return e;
                  else if (
                    !e.active &&
                    deActives &&
                    searchFun(e) &&
                    partiesSelected.find((d) => d._id === e.partyId) !==
                      undefined
                  )
                    return e;
                  else if (
                    !deActives &&
                    !actives &&
                    searchFun(e) &&
                    partiesSelected.find((d) => d._id === e.partyId) !==
                      undefined
                  )
                    return e;
                })
                .sort((a, b) => {
                  return a.active === b.active ? 0 : a.active ? -1 : 1;
                })
                .map((e, i) => {
                  return (
                    <LicenseItem
                      key={e._id}
                      data={e}
                      active={true}
                      parties={parties}
                      onDelete={(value: Campaign) => {
                        setToDeleteLicense(value);
                        setShowDeleteModal(true);
                      }}
                      onEdit={(value: Campaign) => {
                        setToEditLicense(value);
                        setShowAddModal(true);
                      }}
                      onAccess={(value: Campaign) => {
                        setToAccess(value);
                        setShowAccessModal(true);
                      }}
                    ></LicenseItem>
                  );
                })}
            </div>
          </div>
        </div>
      </LayoutBodyComponent>
      <AddCampaignModalComponent
        modalShow={showAddModal}
        setModalShow={setShowAddModal}
        successFun={submitLicense}
        parties={parties}
        toEdit={toEditLicense}
      ></AddCampaignModalComponent>
      <DeleteLicenseModalComponent
        modalShow={showDeleteModal}
        setModalShow={setShowDeleteModal}
        successFun={submitLicense}
        licenseSelected={toDeleteLicense}
      ></DeleteLicenseModalComponent>
      <AccessLicenseModalComponent
        modalShow={showAccessModal}
        setModalShow={setShowAccessModal}
        successFun={submitLicense}
        licenseSelected={toAccess}
      ></AccessLicenseModalComponent>
    </>
  );
};

export default Campaigns;
