import {
  ROUTES_LOAD_LOADING,
  ROUTES_LOAD_FAIL,
  ROUTES_LOAD_SUCCESS,
  ROUTES_GENERAL_LOAD_SUCCESS,
} from './RoutesTypes';
import * as netWorking from '../netWorking';

export const invertCoordinates = (coordinates: any) => {
  const array1 = [];
  for (const value of coordinates) {
    const array2 = [];
    for (const value2 of value) {
      array2.push([value2[1], value2[0]]);
    }
    array1.push(array2);
  }
  return array1;
};

export const getAreasByUser = (
  userId: any,
  municipalityId: any,
  successFun: any,
  errorFunc: any,
) => {
  return async (dispatch: any) => {
    netWorking.get(
      netWorking.getAreasByUser + userId + '/' + municipalityId,
      (res: any) => {
        successFun(res.data.response);
      },
      (error: any) => {
        console.log(error);
        errorFunc(error);
      },
    );
  };
};

export const postGetAreasByUsers = (members: any) => {
  return async (dispatch: any) => {
    dispatch({
      type: ROUTES_LOAD_LOADING,
    });
    netWorking.post(
      netWorking.getAreasByUsers,
      { members },
      (res: any) => {
        dispatch({
          type: ROUTES_LOAD_SUCCESS,
          generalAreas: res.data.response.areas,
          createdAreas: res.data.response.createdAreas,
        });
      },
      (error: any) => {
        console.log(error);
        dispatch({
          type: ROUTES_LOAD_FAIL,
        });
      },
    );
  };
};

export const getAreasByCoordinator = (userId: any) => {
  return async (dispatch: any) => {
    dispatch({
      type: ROUTES_LOAD_LOADING,
    });
    netWorking.get(
      netWorking.getByCoordinator + userId,
      (res: any) => {
        dispatch({
          type: ROUTES_GENERAL_LOAD_SUCCESS,
          generalAreas: res.data.response.areas,
        });
      },
      (error: any) => {
        console.log(error);
        dispatch({
          type: ROUTES_LOAD_FAIL,
        });
      },
    );
  };
};

export const postSearchCoordinatesData = (
  data: any,
  successFun: any,
  errorFunc: any,
) => {
  return async (dispatch: any) => {
    if (data.geometry.coordinates.length > 0) {
      const array1 = data.geometry.coordinates;
      netWorking.post(
        netWorking.postSearchCoordinatesData,
        { coordinates: array1, municipalityId: data.municipalityId },
        (res: any) => {
          successFun(res.data.response);
        },
        (error: any) => {
          errorFunc(error);
        },
      );
    } else {
      errorFunc();
    }
  };
};

export const postCreateArea = (data: any, successFun: any, errorFunc: any) => {
  return async (dispatch: any) => {
    netWorking.post(
      netWorking.postCreateRoutes,
      {
        ...data,
        _id: data._id.length > 6 ? data._id : undefined,
        createdBy: data.createdBy,
        datesWorked: data.datesWorked,
        limit: data.limit,
        limitType: data.limitType,
        members: data.members,
        enable: data.enable,
        coordinates: data.geometry.coordinates,
        originalCoordinates: data.originalCoordinates,
        indexValue: data.indexValue,
      },
      (res: any) => {
        successFun(res.data.response);
      },
      (error: any) => {
        errorFunc(error);
      },
    );
  };
};

export const putListRoutes = (data: any, successFun: any, errorFunc: any) => {
  return async (dispatch: any) => {
    netWorking.post(
      netWorking.putListRoutes,
      {
        list: data,
      },
      (res: any) => {
        successFun(res.data.response);
      },
      (error: any) => {
        errorFunc(error);
      },
    );
  };
};

export const postUpdateRouteState = (
  data: any,
  successFun: any,
  errorFunc: any,
) => {
  return async (dispatch: any) => {
    netWorking.post(
      netWorking.postUpdateRouteState,
      data,
      (res: any) => {
        successFun(res.data.response);
      },
      (error: any) => {
        errorFunc(error);
      },
    );
  };
};

export const deleteRoute = (_id: any, successFun: any, errorFunc: any) => {
  return async (dispatch: any) => {
    netWorking.deleteRequest(
      netWorking.deleteRoute + _id,
      (res: any) => {
        successFun(res.data.response);
      },
      (error: any) => {
        errorFunc(error);
      },
    );
  };
};
