import React, { Fragment, useState, useEffect } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import '../globalStatusStyle.scss';
import '../timelineStyle.scss';
import {
  XLSStatusIcon,
  AuditoriaStatusIcon,
  CapturaStatusIcon,
} from '../../../assets/svg/index';

interface GlobalStatusProps {
  voter: any;
  t: any;
}

const GlobalStatus: React.FunctionComponent<GlobalStatusProps> = ({
  voter,
  t,
}: any) => {
  const wrongColor = '#BF192D';
  const okColor = '#629A2F';
  const [popoverFirst, setPopoverFirst] = useState(false);
  const [popoverSecond, setPopoverSecond] = useState(false);
  const [popoverThird, setPopoverThird] = useState(false);

  const getRegisteredOverlay = (voter: any) => {
    let content;

    content = (
      <Popover
        id={voter._id}
        placement={'bottom'}
        className="pop-over-timeline"
      >
        <Popover.Title as="h3" bsPrefix="title">
          {voter.registered === 0
            ? t('territorial.voters.lbl.notRegistered')
            : voter.registered === 1
            ? t('territorial.voters.lbl.registeredVoter')
            : t('territorial.voters.lbl.problemRegistration')}
        </Popover.Title>
        <Popover.Content bsPrefix="content">
          {voter.registered === 0 ? (
            <Fragment>
              <span>
                {t('territorial.voters.lbl.XLSNotDatabase')}{' '}
                {t('territorial.voters.lbl.XLSAPP')}
              </span>
            </Fragment>
          ) : voter.registered === 1 ? (
            <Fragment>
              <span>{t('territorial.voters.lbl.XLSXLS')}</span>
            </Fragment>
          ) : (
            <Fragment>
              <span>{t('territorial.voters.lbl.XLSDeny')}</span>
            </Fragment>
          )}
        </Popover.Content>
      </Popover>
    );

    return content;
  };

  const getCapturedOverlay = (voter: any) => {
    let content;

    content = (
      <Popover
        id={voter._id}
        placement={'bottom'}
        className="pop-over-timeline"
      >
        <Popover.Title as="h3" bsPrefix="title">
          {voter.captured === 0
            ? t('territorial.voters.lbl.notCaptured')
            : voter.captured === 1
            ? t('territorial.voters.lbl.capturedVoter')
            : t('territorial.voters.lbl.problemCapture')}
        </Popover.Title>
        <Popover.Content bsPrefix="content">
          {voter.captured === 0 ? (
            <Fragment>
              <span>
                {t('territorial.voters.lbl.captureNotDatabase')}{' '}
                {t('territorial.voters.lbl.captureXLS')}
              </span>
            </Fragment>
          ) : voter.captured === 1 ? (
            <Fragment>
              <span>{t('territorial.voters.lbl.captureApp')}</span>
            </Fragment>
          ) : (
            <Fragment>
              <span>{t('territorial.voters.lbl.captureDeny')}</span>
            </Fragment>
          )}
        </Popover.Content>
      </Popover>
    );

    return content;
  };

  const getAuditedOverlay = (voter: any) => {
    let content;

    content = (
      <Popover
        id={voter._id}
        placement={'bottom'}
        className="pop-over-timeline"
      >
        <Popover.Title as="h3" bsPrefix="title">
          {voter.audited === 0
            ? t('territorial.voters.lbl.notConfirmed')
            : voter.audited === 1
            ? t('territorial.voters.lbl.confirmedVoter')
            : t('territorial.voters.lbl.problemAudition')}
        </Popover.Title>
        <Popover.Content bsPrefix="content">
          {voter.audited === 0 ? (
            <Fragment>
              <span>{t('territorial.voters.lbl.auditWait')}</span>{' '}
            </Fragment>
          ) : voter.audited === 1 ? (
            <Fragment>
              <span>{t('territorial.voters.lbl.auditConfirm')}</span>{' '}
            </Fragment>
          ) : (
            <Fragment>
              <span>{t('territorial.voters.lbl.auditDeny')}</span>{' '}
            </Fragment>
          )}
        </Popover.Content>
      </Popover>
    );

    return content;
  };

  const getRegisteredContent = (voter: any) => {
    let fillerProps = { backgroundColor: '#838383' };
    let statusBox = 'icon-status-box';
    if (voter.registered === 2) {
      fillerProps = { backgroundColor: wrongColor };
      statusBox = statusBox + ' wrong-icon';
    }
    if (voter.registered === 1) {
      fillerProps = { backgroundColor: okColor };
      statusBox = statusBox + ' ok-icon';
    }
    if (statusBox === 'icon-status-box') {
      statusBox = statusBox + ' default-icon';
    }
    return (
      <div className={statusBox}>
        <XLSStatusIcon></XLSStatusIcon>
        <div className="dot" style={fillerProps}></div>
        <div className="title">
          <p>{t('territorial.voters.lbl.XLS')}</p>
        </div>
      </div>
    );
  };

  const getCapturedContent = (voter: any) => {
    let fillerProps = { backgroundColor: '#838383' };
    let statusBox = 'icon-status-box';
    if (voter.captured === 2) {
      fillerProps = { backgroundColor: wrongColor };
      statusBox = statusBox + ' wrong-icon';
    }
    if (voter.captured === 1) {
      fillerProps = { backgroundColor: okColor };
      statusBox = statusBox + ' ok-icon';
    }
    if (statusBox === 'icon-status-box') {
      statusBox = statusBox + ' default-icon';
    }
    return (
      <div className={statusBox}>
        <CapturaStatusIcon></CapturaStatusIcon>
        <div className="dot" style={fillerProps}></div>
        <div className="title">
          <p>{t('territorial.voters.lbl.capture')}</p>
        </div>
      </div>
    );
  };

  const getAuditedContent = (voter: any) => {
    let fillerProps = { backgroundColor: '#838383' };
    let statusBox = 'icon-status-box';
    if (voter.audited === 2) {
      fillerProps = { backgroundColor: wrongColor };
      statusBox = statusBox + ' wrong-icon';
    }
    if (voter.audited === 1) {
      fillerProps = { backgroundColor: okColor };
      statusBox = statusBox + ' ok-icon';
    }
    if (statusBox === 'icon-status-box') {
      statusBox = statusBox + ' default-icon';
    }
    return (
      <div className={statusBox}>
        <AuditoriaStatusIcon></AuditoriaStatusIcon>
        <div className="dot" style={fillerProps}></div>
        <div className="title">
          <p>{t('territorial.voters.lbl.audit')}</p>
        </div>
      </div>
    );
  };

  const getMainClass = (voter: any, defaultClass: string, field: string) => {
    let selectedClass = defaultClass;

    if (voter.audited === 1) {
      selectedClass = defaultClass + ' is-ok';
    }
    if (voter.audited !== 2 && voter[field] === 1) {
      selectedClass = defaultClass + ' is-ok';
    }
    if (field === 'registered' && voter.captured === 2) {
      selectedClass = defaultClass + ' is-wrong';
    }
    if (voter.audited === 2 || voter[field] === 2) {
      selectedClass = defaultClass + ' is-wrong';
    }

    return selectedClass;
  };

  const getFillerProps = (voter: any) => {
    let fillerProps = { backgroundColor: '#838383' };

    if (voter.audited === 1) {
      fillerProps = { backgroundColor: okColor };
    }

    if (voter.audited === 2) {
      fillerProps = { backgroundColor: wrongColor };
    }

    if (voter.captured === 2) {
      fillerProps = { backgroundColor: wrongColor };
    }

    return fillerProps;
  };

  useEffect(() => {
    document.addEventListener(
      'mousewheel',
      (e) => {
        setPopoverFirst(false);
        setPopoverSecond(false);
        setPopoverThird(false);
      },
      { passive: true },
    );
  }, []);

  return (
    <div className="global-status-main-container">
      <div className="overlay-status">
        <div
          className={getMainClass(voter, 'registered-item', 'registered')}
          onMouseOver={() => {
            setPopoverFirst(true);
          }}
          onMouseLeave={() => {
            setPopoverFirst(false);
          }}
        >
          <OverlayTrigger
            key={voter._id + 'registered-item'}
            placement={'bottom'}
            overlay={popoverFirst ? getRegisteredOverlay(voter) : <></>}
          >
            {getRegisteredContent(voter)}
          </OverlayTrigger>
        </div>
        <div
          className={getMainClass(voter, 'captured-item', 'captured')}
          onMouseOver={() => {
            setPopoverSecond(true);
          }}
          onMouseDown={() => {
            setPopoverSecond(false);
          }}
        >
          <OverlayTrigger
            key={voter._id + 'captured-item'}
            placement={'bottom'}
            overlay={popoverSecond ? getCapturedOverlay(voter) : <></>}
          >
            {getCapturedContent(voter)}
          </OverlayTrigger>
        </div>
        <div
          className={getMainClass(voter, 'audited-item', 'audited')}
          onMouseOver={() => {
            setPopoverThird(true);
          }}
          onMouseDown={() => {
            setPopoverThird(false);
          }}
        >
          <OverlayTrigger
            key={voter._id + 'audited-item'}
            placement={'bottom'}
            overlay={popoverThird ? getAuditedOverlay(voter) : <></>}
          >
            {getAuditedContent(voter)}
          </OverlayTrigger>
        </div>
      </div>
      <div className="overlay-status-filler-base">
        <div
          className="overlay-status-filler"
          style={getFillerProps(voter)}
        ></div>
        <div
          className="overlay-status-filler"
          style={getFillerProps(voter)}
        ></div>
      </div>
    </div>
  );
};

export default GlobalStatus;
