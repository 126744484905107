import React, {
  createRef,
  Fragment,
  FunctionComponent,
  useEffect,
  useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as netWorking from '../../redux/netWorking';
import { useTranslation } from 'react-i18next';
import { RootStore } from '../../redux/Store';
import './style.scss';
import { Map, FeatureGroup, Polyline, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { LatLng } from 'leaflet';
import moment from 'moment';
import {
  postGetTrackerByUserIdAndDate,
  postListCapturistTeamsByCity,
  changeSelectedCoordinator,
} from '../../redux/tracking/trackingActions';
import { getAreasByUser } from '../../redux/routes/RoutesActions';
import { TEAM_TYPE } from '../../redux/RgRc/RgRcTypes';
import { ROLE } from '../../utils/utils';
import {
  createClusterCustomIcon,
  selectedMarker,
  selectedRedMarker,
  unSelectedMarker,
  unSelectedRedMarker,
} from '../../components/sub-component/markers';
import ItemListFilterComponent from '../../components/sub-component/itemListFilter';
import LayerControlComponent from '../../components/sub-component/layerControl';
import ItemDateComponent from '../../components/sub-component/itemDateFilter';
import ListFilterCustomComponent from '../../components/ListFilterCustom';
import SelectorComponent from '../../components/sub-component/selector';
import StatisticsTabComponent from './components/statisticsTab';
import LayoutBodyComponent from '../../components/LayoutBody';
import TimeMarkerItemComponent from './components/timeMarker';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import MarkerPopupComponent from './components/markerPopup';
import CheckMarkerComponent from './components/checkMarker';
import TabComponent from '../../components/Tabs';

const varColor = '#5d646a';

const defaultArray2: any[] = [];
// tslint:disable-next-line: prefer-const
let defaultAny: any;
const defaultDate: Date = new Date();
const generalEmpty: any[] = [];

const TrackingTerritorial: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const ref = createRef<FeatureGroup>();
  const [, setStatistics] = React.useState(defaultArray2);
  const [dataSelected, setData] = React.useState(-1);
  const [pointToReview, setPointToReview] = React.useState(defaultAny);
  const [generalAreas, setGeneralAreas] = React.useState(generalEmpty);
  const [generalAreasData, setGeneralAreasData] = React.useState(generalEmpty);
  const [activeDates, setActiveDates] = React.useState(Array);
  const [date, onChange] = React.useState(defaultDate);

  const citySelected: any = useSelector((globalState: RootStore) => {
    return globalState.core.globalMunicipalitySelected;
  });

  const userData = useSelector((globalState: RootStore) => {
    return globalState.core.userData;
  });

  const teamData = useSelector((globalState: RootStore) => {
    return globalState.tracking.teamData;
  });

  const trackings = useSelector(
    (globalState: RootStore) => globalState.tracking.tracking,
  );

  const results = useSelector(
    (globalState: RootStore) => globalState.tracking.result,
  );

  const selectedCoordinator = useSelector(
    (globalState: RootStore) => globalState.tracking.selectedCoordinator,
  );

  const leafletMapRef = useRef<Map>(null);

  const teamLevel = localStorage?.level
    ? parseInt(localStorage?.level) - 1
    : citySelected.capturistUserLevels[0].level;

  const titleLv1 =
    userData?.role === ROLE.ADMIN_BOSS || userData?.role === ROLE.SUPER_ADMIN
      ? citySelected.capturistUserLevels[0][t('language')]
      : citySelected.capturistUserLevels.find(
          (c: any) => c.level === parseInt(localStorage?.level) - 1,
        )[t('language')];

  // const titleLv2 =
  //   userData?.role === ROLE.ADMIN_BOSS
  //     ? t('territorial.volunteer.modal.organizerVolunteerLv2.title')
  //     : userData?.role === ROLE.COORDINATOR_L1
  //     ? t('territorial.volunteer.modal.organizerVolunteerLv3.title')
  //     : userData?.role === ROLE.COORDINATOR_L2
  //     ? t('territorial.volunteer.modal.volunteer.title')
  //     : userData?.role === ROLE.CAPTURIST_BOSS
  //     ? t('territorial.volunteer.modal.volunteer.title')
  //     : '';

  const defaultPosition = {
    lat: 32.7157431,
    lng: -117.1888535,
    zoom: 11,
  };
  const position: [number, number] =
    citySelected?.defaultCenter !== undefined
      ? citySelected?.defaultCenter
      : [defaultPosition.lat, defaultPosition.lng];

  const [center, setCenter] = React.useState(position);
  const [zoom, setZoom] = React.useState(
    citySelected?.defaultZoom !== undefined
      ? citySelected?.defaultZoom
      : defaultPosition.zoom,
  );

  const buildParamsCapturistTeamsByCity = (
    municipalityId: string,
    isFirst: boolean,
  ) => {
    let requestObject;
    requestObject = {
      municipalityId: municipalityId,
      isFirst: isFirst,
      teamLevel: teamLevel,
    };
    return requestObject;
  };

  const setDateValue = (e: Date) => {
    onChange(e);
    fetchTrackers(selectedCoordinator._id, e);
  };

  const getActiveDates = (id: string) => {
    netWorking.get(
      netWorking.activeDates + id,
      (result: any) => {
        setActiveDates(result.data.response);
      },
      () => {},
    );
  };

  const fetchTrackers = (id: string, date?: Date) => {
    const listCapturers: any[] = [];
    teamData.forEach((e) => {
      listCapturers.push(e.bossId);
    });
    dispatch(
      postGetTrackerByUserIdAndDate({
        coordinatorId: id,
        otherCapturers: listCapturers,
        created: date
          ? moment(date).utc(false).startOf('day').toDate()
          : moment(Date.now()).utc(false).startOf('day').toDate(),
      }),
    );
  };

  const transformData = (data: any[]) => {
    // 'name', 'email', 'phone', 'workToday', 'routess', 'progress'
    return data.map((d) => ({
      _id: d.bossId,
      name: d.name,
    }));
  };

  const transformToPoly = () => {
    const array: any[] = [];
    if (
      trackings !== undefined &&
      trackings.length > 0 &&
      trackings[0].routes
    ) {
      trackings[0].routes.forEach((element: any) => {
        if (element)
          array.push(new LatLng(element.position.lat, element.position.lng));
      });
    }
    return array;
  };

  const getMarker = (status: number, inHouse: boolean, selected: boolean) => {
    if (status === 2) {
      if (selected) {
        return selectedRedMarker;
      } else {
        return unSelectedRedMarker;
      }
    } else {
      if (selected) {
        return selectedMarker;
      } else {
        return unSelectedMarker;
      }
    }
  };

  // const getPorcentaje = () => {
  //   let value = 0;
  //   statistics.forEach((e: any) => {
  //     value += e.capturist;
  //   });

  //   return (value * 100) / 400;
  // };

  // const getCount = (id: any) => {
  //   const value = statistics.find((e: any) => {
  //     return e._id === id;
  //   });
  //   return value?.capturist || 0;
  // };

  // const getTotalCount = () => {
  //   let value = 0;
  //   statistics.forEach((e: any) => {
  //     value += e.capturist;
  //   });
  //   return value;
  // };

  const getDataForGraph = (statistics: any) => {
    const data: any[] = [];
    const value = statistics.sort((v1: any, v2: any) => v2.count - v1.count);
    value.forEach((e: any, i: number) => {
      const capturist = teamData.find((d) => d.bossId === e._id);
      data.push({
        _id: e._id,
        position: i + 1 + '°',
        capturist: e.count,
        name: (capturist?.firstName || '') + ' ' + (capturist?.lastName || ''),
        color: e._id === selectedCoordinator._id ? '#0b87e7' : varColor,
      });
    });
    if (data.length === 0) {
      teamData.forEach((e, i) => {
        data.push({
          _id: e.bossId,
          position: i + 1 + '°',
          capturist: 0,
          name: e?.name,
          color: e.bossId === selectedCoordinator._id ? '#0b87e7' : varColor,
        });
      });
    }
    // return data;
    setStatistics(data);
  };

  // useEffect(() => {
  //   const mapElement = ref.current?.leafletElement;
  //   if (mapElement) {
  //     // mapElement.eachLayer((layer: any) => {
  //     //   if (layer._url === undefined && layer._container === undefined)
  //     //     layer.remove();
  //     //   count++;
  //     // });
  //     // generalAreas.forEach((e: any) => {
  //     //   L.geoJSON(
  //     //     {
  //     //       type: 'Feature',
  //     //       geometry: {
  //     //         type: 'Polygon',
  //     //         coordinates: e.area.coordinates,
  //     //       },
  //     //     } as any,
  //     //     {
  //     //       style: {
  //     //         weight: 2,
  //     //         opacity: 1,
  //     //         color: e.color,
  //     //         dashArray: '',
  //     //         fillOpacity: 1,
  //     //         fillColor: e.color,
  //     //       },
  //     //       onEachFeature: (feature, layer) => {
  //     //         layer.addTo(mapElement);
  //     //       },
  //     //     },
  //     //   );
  //     // });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [generalAreas]);

  useEffect(() => {
    if (citySelected?._id) {
      setCenter(
        citySelected?.defaultCenter !== undefined
          ? citySelected?.defaultCenter
          : [defaultPosition.lat, defaultPosition.lng],
      );
      setZoom(
        citySelected?.defaultZoom !== undefined
          ? citySelected?.defaultZoom
          : defaultPosition.zoom,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [citySelected?._id]);

  useEffect(() => {
    if (selectedCoordinator._id && citySelected?._id)
      dispatch(
        getAreasByUser(
          [selectedCoordinator._id],
          citySelected?._id,
          (data: any) => {
            const array: any[] = [];
            data.forEach((e: any) => {
              const r1 = (Math.random() * 255).toFixed(0);
              const r2 = (Math.random() * 255).toFixed(0);
              const r3 = (Math.random() * 255).toFixed(0);
              const color = `rgba(${r1},${r2},${r3},0.5)`;
              const colorBase = `rgba(${r1},${r2},${r3},1)`;
              array.push({ ...e, color, colorBase });
            });
            setGeneralAreas(array);
          },
          (error: any) => {},
        ),
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCoordinator._id, citySelected?._id]);

  useEffect(() => {
    if (generalAreas.length > 0) {
      netWorking.post(
        netWorking.getDataOfAreaOfTrackers,
        {
          areas: generalAreas.map((e) => {
            return e.area._id;
          }),
        },
        (result: any) => {
          setGeneralAreasData(result.data.response);
        },
        (error: any) => {},
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generalAreas]);

  useEffect(() => {
    if (results) getDataForGraph(results);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results]);

  useEffect(() => {
    if (selectedCoordinator._id) {
      getActiveDates(selectedCoordinator._id);
      fetchTrackers(selectedCoordinator._id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCoordinator._id]);

  useEffect(() => {
    if (citySelected?._id) {
      const params = buildParamsCapturistTeamsByCity(citySelected._id, true);
      dispatch(postListCapturistTeamsByCity(params));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [citySelected?._id]);

  return (
    <>
      <LayoutBodyComponent>
        <div className="traking-electoral-selectors">
          <SelectorComponent
            title={titleLv1}
            defaultSelector={
              selectedCoordinator._id ? selectedCoordinator._id : ''
            }
            options={transformData(teamData)}
            horizontal={true}
            fun={(value: string) => {
              fetchTrackers(value, date);
              dispatch(
                changeSelectedCoordinator({
                  _id: value,
                  name: '',
                  members: [],
                }),
              );
            }}
          ></SelectorComponent>
        </div>
        <div className="tracking-electoral-box-body">
          <div className="tracking-electoral-box">
            <TabComponent
              options={[
                'territorial.tracker.tab.1',
                'territorial.tracker.tab.2',
              ]}
              classAdditional={'tabs-search-container'}
            >
              <LayoutBodyComponent>
                <ListFilterCustomComponent
                  direction={'left'}
                  className={'tracker-filter-list'}
                  size={'lg-filter'}
                  onHide={() => {
                    setTimeout(function () {
                      window.dispatchEvent(new Event('resize'));
                    }, 301);
                  }}
                >
                  <>
                    <ItemDateComponent
                      activeDates={activeDates}
                      text={t('territorial.tracker.lbl.organizers')}
                      onChange={setDateValue}
                      value={date}
                    ></ItemDateComponent>
                    <ItemListFilterComponent className="list-tracker">
                      {trackings !== undefined &&
                        trackings.length > 0 &&
                        trackings[0].routes.map((data: any, i: number) => {
                          if (data && data.startDate !== data.endDate)
                            return (
                              <TimeMarkerItemComponent
                                key={i}
                                data={data}
                                length={
                                  trackings !== undefined &&
                                  trackings.length > 0
                                    ? trackings[0].routes.length - 1
                                    : 0
                                }
                                index={i}
                                dataSelected={dataSelected}
                                onSelected={(data: any) => {
                                  setData(data);
                                }}
                              ></TimeMarkerItemComponent>
                            );
                          else return <></>;
                        })}
                    </ItemListFilterComponent>
                  </>
                </ListFilterCustomComponent>
                <div className="map-box-box">
                  <Map
                    ref={leafletMapRef}
                    center={center}
                    zoomControl={false}
                    zoom={zoom}
                    // key="awdqweqmpfqop212"
                  >
                    <LayerControlComponent></LayerControlComponent>
                    <FeatureGroup ref={ref}>
                      <Fragment key={0}>
                        {/* {Zone routes} */}
                        <Polyline
                          color="purple"
                          positions={transformToPoly()}
                          dashArray="4"
                        ></Polyline>
                      </Fragment>
                    </FeatureGroup>
                    <MarkerClusterGroup
                      showCoverageOnHover={true}
                      spiderfyDistanceMultiplier={2}
                      iconCreateFunction={createClusterCustomIcon}
                    >
                      {trackings !== undefined &&
                        trackings.length > 0 &&
                        trackings[0] &&
                        trackings[0].routes.map((data: any, i: number) => {
                          if (data && data.startDate !== data.endDate)
                            return (
                              <Marker
                                key={i}
                                position={data?.position}
                                icon={getMarker(
                                  data.status,
                                  data.inHouse,
                                  dataSelected === i,
                                )}
                                onclick={() => {
                                  setData(i);
                                }}
                              >
                                <MarkerPopupComponent
                                  data={data}
                                  index={i}
                                  onReview={() => {
                                    setPointToReview(data);
                                  }}
                                ></MarkerPopupComponent>
                              </Marker>
                            );
                          else return <></>;
                        })}
                    </MarkerClusterGroup>
                  </Map>
                </div>{' '}
                {pointToReview && (
                  <CheckMarkerComponent
                    data={pointToReview}
                    tracking={
                      trackings !== undefined &&
                      trackings.length > 0 &&
                      trackings[0]
                        ? trackings[0]
                        : undefined
                    }
                    active={pointToReview ? true : false}
                    setActive={() => {
                      setPointToReview(defaultAny);
                    }}
                    submitFun={() => {
                      fetchTrackers(selectedCoordinator._id, date);
                    }}
                  ></CheckMarkerComponent>
                )}
              </LayoutBodyComponent>
              <StatisticsTabComponent
                transformData={transformData}
                fetchTrackers={fetchTrackers}
                date={date}
                generalAreas={generalAreas}
                generalAreasData={generalAreasData}
              ></StatisticsTabComponent>
            </TabComponent>
          </div>
        </div>
      </LayoutBodyComponent>
    </>
  );
};

export default TrackingTerritorial;
