import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootStore } from '../../../redux/Store';
import * as netWorking from '../../../redux/netWorking';

import {
  getVotersBoxElectoral,
  getVotersBoxElectoralTotal,
  setSelectedValuesElectoral,
} from '../../../redux/votersBox/VotersBoxActions';
import { XLS_TYPE } from '../../../utils/utils';

import TableComponent, {
  FIELD_TYPE,
  HEADER_TYPE,
  HEADER,
} from '../../../components/Table';
import PaginationRecords from '../../../components/PaginationRecords';
import TopBar from './TopBar';
import ModalAddXLS from '../../../dialogs/modalXLS';
import ModalAssignVoterBox from './assignVoterBox';
import LoadingModal from '../../../dialogs/loadingModal';

const List: React.FunctionComponent = () => {
  const citySelected: any = useSelector((globalState: RootStore) => {
    return globalState.core.globalMunicipalitySelected;
  });

  const loading = useSelector(
    (globalState: RootStore) => globalState.votersBox.loadingElectoral,
  );
  const voterBoxs = useSelector(
    (globalState: RootStore) => globalState.votersBox.voterBoxsElectoral,
  );
  const selected: any = useSelector(
    (globalState: RootStore) => globalState.votersBox.sectionSelectedElectoral,
  );
  const totalVoterBoxs: number = useSelector(
    (globalState: RootStore) => globalState.votersBox.totalVoterBoxsElectoral,
  );

  const search = useSelector(
    (globalState: RootStore) => globalState.votersBox.searchElectoral,
  );
  const sort: any = useSelector(
    (globalState: RootStore) => globalState.votersBox.sortElectoral,
  );
  const records: any = useSelector(
    (globalState: RootStore) => globalState.votersBox.recordsElectoral,
  );
  const page: any = useSelector(
    (globalState: RootStore) => globalState.votersBox.pageElectoral,
  );
  const filters: any = useSelector(
    (globalState: RootStore) => globalState.votersBox.filtersElectoral,
  );
  //======================================================================================
  const totalPage = useSelector(
    (globalState: RootStore) => globalState.votersBox.totalPageElectoral,
  );

  const [tableData, setTableData] = useState(Array);
  const [fieldOrder, setFieldOrder] = useState(sort[1] ? sort[1] : 'asc');
  const [filter, setFilter] = useState(
    filters
      ? filters
      : {
          polling: false,
          rcs: false,
        },
  );
  const [modalShowXls, setModalShowXls] = useState(false);
  const [modalAssignVB, setModalAssignVB] = useState(false);
  const [edit, setEdit] = useState(Object);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const headerVotersBox: any = {};

  const setSelected = (data: any, value: string) => {
    dispatch(setSelectedValuesElectoral(data));
  };

  const openEdit = (_id: any) => {
    const vb = voterBoxs?.find((value: any) => value._id === _id);
    if (vb) {
      setEdit(vb);
      setModalAssignVB(true);
    }
  };

  const getAddress = (address: any) => {
    let result = '';
    const newAddress = address;
    if (newAddress) {
      if (newAddress.street && newAddress.street !== '')
        result = newAddress.street;
      if (newAddress.exteriorNumber && newAddress.exteriorNumber !== '')
        result = result + `, ${newAddress.exteriorNumber}`;
      if (newAddress.interiorNumber && newAddress.interiorNumber !== '')
        result = result + `, ${newAddress.interiorNumber}`;
      if (newAddress.postalCode && newAddress.postalCode !== '')
        result = result + `, ${newAddress.postalCode}`;
      if (newAddress.suburb && newAddress.suburb !== '')
        result = result + `, ${newAddress.suburb}`;
    }
    return result;
  };

  const getUbication = (ubication: any) => {
    let result = '';
    let aux_count = 0;
    const newUbication = ubication;
    newUbication.forEach((ub: any) => {
      if (aux_count === 0) {
        result = ub;
      } else {
        result = result + ', ' + ub;
      }
      aux_count++;
    });
    return result;
  };

  const transformData = () => {
    const newVBs: any = [];
    const dataArray: any = voterBoxs;
    dataArray.forEach((vb: any) => {
      const newVoterBox = {
        _id: vb._id,
        number: vb.number,
        winningNumber: vb.winningNumber,
        address: getAddress(vb.address),
        city: vb.address?.municipality ? vb.address.municipality : '',
        ubication: vb.address?.geoLocation?.coordinates
          ? getUbication(vb.address.geoLocation.coordinates)
          : getUbication([0, 0]),
        district: vb?.district ? vb.district : '',
        section: vb?.section ? vb.section : '',
        rg: {
          _id: vb.rg?._id || '',
          name: vb.rg?.firstName + ' ' + vb.rg?.lastName || '',
          rgId: vb.rgId ? true : false,
        },
        rc: {
          _id: vb.rc?._id || '',
          name: vb.rc?.firstName + ' ' + vb.rc?.lastName || '',
          rgId: vb.rgId ? true : false,
        },
        substitute: {
          _id: vb.substitute?._id || '',
          name: vb.substitute?.firstName + ' ' + vb.substitute?.lastName || '',
          rgId: vb.rgId ? true : false,
        },
      };
      newVBs.push(newVoterBox);
    });
    setTableData(newVBs);
  };

  const searchByValue = (reset = false) => {
    const params = buildParamSearchVoterBoxData(
      search,
      records ? records : 10,
      fieldOrder,
      !reset ? page : 1,
    );
    dispatch(getVotersBoxElectoral(params));
    dispatch(getVotersBoxElectoralTotal(params));
  };

  const buildParamSearchVoterBoxData = (
    search: string,
    recordsNum: number,
    order: string,
    pageNum: number,
  ) => {
    let requestObject;
    requestObject = {
      cityLicenseId: citySelected?._id,
      municipalityId: citySelected?.municipalityId,
      districtId: selected.district,
      sectionId: selected.section,
      search: search.length >= 3 ? search : '',
      records: recordsNum,
      order: order,
      filters: filter,
      page: pageNum,
    };
    return requestObject;
  };

  useEffect(() => {
    if (voterBoxs) {
      transformData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [voterBoxs]);

  useEffect(() => {
    if (selected) {
      searchByValue();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldOrder]);

  useEffect(() => {
    if (selected && (search.length >= 3 || search.length <= 0)) {
      searchByValue(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    if (selected) {
      searchByValue(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  useEffect(() => {
    //if (selected) {
    searchByValue(true);
    //}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  return (
    <div className="box-electoral-list">
      <TopBar
        setSelected={setSelected}
        selected={selected}
        selectsToShow={3}
        total={totalVoterBoxs}
        filter={filter}
        setFilter={setFilter}
        downloadFun={() =>
          netWorking.downloadXls(XLS_TYPE.VB_ASSIGN_RGRC_XLS_TYPE, '')
        }
        uploadFun={() => setModalShowXls(true)}
      ></TopBar>
      <div className="box-electoral-container">
        <TableComponent
          loading={false}
          showActions={false}
          showAssign={true}
          selectedRows={[]}
          selectRows={() => {}}
          selectMessage={false}
          header={headerVotersBox}
          headerType={HEADER_TYPE.HIDDEN}
          headers={[
            {
              type: HEADER.FILTER,
              name: t('settings.polling.table.polling'),
              fun: () => {
                const newOrder = fieldOrder === 'asc' ? 'desc' : 'asc';
                setFieldOrder(newOrder);
              },
            },
            {
              type: HEADER.TEXT,
              name: t('settings.polling.table.district'),
            },
            {
              type: HEADER.TEXT,
              name: t('settings.polling.table.section'),
            },
            {
              type: HEADER.TEXT,
              name: t('settings.polling.table.address'),
            },
            {
              type: HEADER.TEXT,
              name: t('electoral.polling.table.rg'),
            },
            {
              type: HEADER.TEXT,
              name: t('electoral.polling.table.rc'),
            },
            {
              type: HEADER.TEXT,
              name: t('electoral.polling.table.rcSubstitute'),
            },
          ]}
          fieldTypes={[
            { type: FIELD_TYPE.TEXT },
            { type: FIELD_TYPE.TEXT },
            { type: FIELD_TYPE.TEXT },
            { type: FIELD_TYPE.TEXT },
            { type: FIELD_TYPE.VOTER_BOX },
            { type: FIELD_TYPE.VOTER_BOX },
            { type: FIELD_TYPE.VOTER_BOX },
          ]}
          toEdit={() => {}}
          toAssign={(value: any) => {
            openEdit(value);
          }}
          toDelete={() => {}}
          search={() => {}}
          regExp={undefined}
          data={tableData}
          nameOfData={[
            'number',
            'district',
            'section',
            'address',
            'rg',
            'rc',
            'substitute',
          ]}
          classBody={[
            'text-align-center selector-cell-none polling-container',
            'text-align-center district-container',
            'text-align-center section-container',
            'text-align-left address-container',
            'text-align-left rg-container',
            'text-align-left rc-container',
            'text-align-left substitute-container',
          ]}
        ></TableComponent>
        <PaginationRecords
          totalPages={totalPage}
          value={page}
          records={records}
          onChange={(pagina: string, record: string) => {
            const rec = record === '' ? '10' : record;
            let pag;
            if (records !== record) {
              pag = '1';
            } else {
              pag = pagina === '' || pagina === '0' ? '1' : pagina;
            }
            const params = buildParamSearchVoterBoxData(
              search,
              +rec,
              fieldOrder,
              +pag,
            );
            dispatch(getVotersBoxElectoral(params));
            dispatch(getVotersBoxElectoralTotal(params));
          }}
        ></PaginationRecords>
      </div>
      {modalShowXls && (
        <ModalAddXLS
          modalShow={modalShowXls}
          setModalShow={setModalShowXls}
          type={XLS_TYPE.VB_ASSIGN_RGRC_XLS_TYPE}
          municipalityId={citySelected?._id}
          successFun={(res: any) => {
            searchByValue();
          }}
        ></ModalAddXLS>
      )}
      {modalAssignVB && (
        <ModalAssignVoterBox
          modalShow={modalAssignVB}
          setModalShow={setModalAssignVB}
          edit={edit}
          successFun={(res: any) => {
            searchByValue();
          }}
          t={t}
        ></ModalAssignVoterBox>
      )}

      {loading && (
        <LoadingModal show={loading} onHide={() => {}}></LoadingModal>
      )}
    </div>
  );
};

export default List;
