import { join } from 'lodash';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import io from 'socket.io-client';

export const WEB = process.env.REACT_APP_WEB;

export const URLWebSocket = process.env.REACT_APP_URL_WEB_SOCKET;
export enum WS_DATA_TYPE {
  UPDATE = 'update',
  CREATE = 'create',
  DELETE = 'delete',
  ALL = 'all',
}
export enum WS_STATES {
  CONNECTING = 'CONNECTING',
  OPEN = 'OPEN',
  DISCONNECTED = 'DISCONNECTED',
  BAD_AUTH = 'BAD_AUTH',
}

export interface WSContextInterface {
  socket?: SocketIOClient.Socket;
  wsState: WS_STATES;
  rooms: Room[];
  join?(
    room: string,
    updatedEvent: string,
    createdEvent: string,
    deletedEvent: string,
    allEvent: string,
    fun?: any,
  ): any;
  disconnect?(): any;
  off?(room: string): any;
}

const sampleAppContext: WSContextInterface = {
  socket: undefined,
  wsState: WS_STATES.DISCONNECTED,
  rooms: [],
  join: undefined,
  disconnect: undefined,
  off: undefined,
};

export interface Room {
  name: string;
  updatedEvent: string;
  createdEvent: string;
  deletedEvent: string;
  allEvent: string;
  fun: any;
  active: boolean;
}
export const ContextWs = React.createContext<WSContextInterface>(
  sampleAppContext,
);

export function WSProvider(props: any) {
  const [socket, setSocket] = React.useState<SocketIOClient.Socket | undefined>(
    undefined,
  );
  const [wsState, setWsState] = React.useState<WS_STATES>(
    WS_STATES.DISCONNECTED,
  );
  const [rooms, setRooms] = React.useState<Room[]>([]);

  const dispatch = useDispatch();

  const disconnect = (socketActual?: SocketIOClient.Socket) => {
    setWsState(WS_STATES.DISCONNECTED);
    if (socketActual) {
      console.log('socketActual', socketActual);
      if (socketActual) {
        for (const room of rooms) {
          socketActual.off(room.name);
        }
        socketActual.disconnect();
        socketActual.close();
      }
      setRooms([]);
      setSocket(undefined);
    } else {
      console.log('socket', socket);
      if (socket) {
        for (const room of rooms) {
          socket.off(room.name);
        }
        socket.disconnect();
        socket.close();
      }
      setRooms([]);
    }
  };

  const joinRooms = () => {
    if (socket) {
      let updated = false;
      for (const room of rooms) {
        if (!room.active) {
          updated = true;
          socket.emit('join', [
            {
              token: localStorage.access_token,
              room: room.name,
            },
          ]);
          socket.on(room.name, (response: any) => {
            //console.log('response', response);
            if (response.typeOfData === WS_DATA_TYPE.UPDATE)
              dispatch({
                type: room.updatedEvent,
                data: response.data,
              });
            else if (response.typeOfData === WS_DATA_TYPE.CREATE)
              dispatch({
                type: room.createdEvent,
                data: response.data,
              });
            else if (response.typeOfData === WS_DATA_TYPE.DELETE)
              dispatch({
                type: room.deletedEvent,
                data: response.data,
              });
            else if (response.typeOfData === WS_DATA_TYPE.ALL)
              dispatch({
                type: room.allEvent,
                data: response.data,
              });
            else room.fun(response);
          });
          socket.on('RoomConnectionFailed', (response: any) => {});
        }
      }
      if (updated) {
        setRooms(
          rooms.map((e) => {
            return { ...e, active: true };
          }),
        );
      }
    }
  };

  React.useEffect(() => {
    if (localStorage.access_token !== undefined) {
      if (socket === undefined) {
        setWsState(WS_STATES.CONNECTING);
        const socketConnect: SocketIOClient.Socket = io(
          URLWebSocket || 'https://vote.api.qa.giooby.com',
          {
            query: {
              token: localStorage.access_token,
            },
            reconnection: true,
            reconnectionAttempts:10,
            autoConnect:true,
            upgrade:true,
            transports: ['websocket'],
          },
        );
        socketConnect.on('onSocket', (msg: any) => {
          setWsState(WS_STATES.OPEN);
        });
        socketConnect.on('UnAuthenticated', (response: any) => {
          setWsState(WS_STATES.BAD_AUTH);
        });
        socketConnect.on('disconnect', (reason: any) => {
          disconnect(socketConnect);
        });
        setSocket(socketConnect);
      }
    }
  }, [socket, localStorage.access_token]);

  const joinFun = (
    room: string,
    updatedEvent: string,
    createdEvent: string,
    deletedEvent: string,
    allEvent: string,
    fun: any,
  ) => {
    if (socket) {
      let arrayRooms = [...rooms];
      const found = arrayRooms.findIndex((e) => {
        return e.name === room;
      });
      if (found !== -1) {
        socket.emit('leave', [
          {
            token: localStorage.access_token,
            room,
          },
        ]);
        let array: Room[] = [];
        rooms.forEach((e) => {
          if (e.name !== room) array.push({ ...e, active: true });
        });
        arrayRooms = [...array];
        arrayRooms.splice(found, 1);
      }
      arrayRooms.push({
        name: room,
        updatedEvent,
        createdEvent,
        deletedEvent,
        allEvent,
        fun,
        active: false,
      });
      setRooms(arrayRooms);
    }
  };

  React.useEffect(() => {
    if (localStorage.access_token !== undefined) {
      console.log('excecuted!!!');
      console.log('rooms', rooms);
      joinRooms();
    }
  }, [rooms]);

  const off = (room: string) => {
    if (socket) {
      socket.off(room);
      socket.emit('leave', [
        {
          token: localStorage.access_token,
          room,
        },
      ]);
      let array: Room[] = [];
      rooms.forEach((e) => {
        if (e.name !== room) array.push({ ...e, active: true });
      });
      setRooms(array);
    }
  };

  return (
    <ContextWs.Provider
      value={{
        socket,
        wsState,
        rooms,
        join: joinFun,
        disconnect,
        off,
      }}
    >
      {props.children}
    </ContextWs.Provider>
  );
}
