import React, { FunctionComponent } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../style.scss';
import StaticModal from '../../../dialogs/StaticModal';
import * as netWorking from '../../../redux/netWorking';
import { useTranslation } from 'react-i18next';
import { Container } from 'react-bootstrap';
import { UpData } from '../../../redux/updata/UpDataTypes';

interface ModalDeleteProps {
  modalShow: any;
  setModalShow: any;
  toDelete: UpData;
  successFun: any;
}

export const ModalDeleteItem: FunctionComponent<ModalDeleteProps> = ({
  modalShow,
  setModalShow,
  toDelete,
  successFun,
}: any) => {
  const { t } = useTranslation();
  const submitFun = () => {
    if (toDelete)
      netWorking.post(
        netWorking.deleteUpData + toDelete._id,
        {},
        (data: any) => {
          console.log(data);
          successFun();
          setModalShow(false);
        },
        () => {},
      );

    // successFun();
    // setModalShow(false);
  };

  return (
    <StaticModal
      show={modalShow}
      title={'Eliminar Lista'}
      closeText={t('submit.cancel')}
      confirmText={t('submit.yes.delete')}
      onHide={() => setModalShow(false)}
      submitFun={submitFun}
      validBtn={false}
      size="lg"
      sizeClass="md modal-rc"
    >
      <>
        <Container className="body-modal">
          <Row noGutters className="modal-text-row">
            {/* <Dropzone type={type} setRouteFile={setRouteFile}></Dropzone> */}
            <Col xs={12} className="description-col">
              Estas por eliminar una lista de votantes{', '}
              {'¿Estas seguro de eliminar '}
              <strong>{toDelete?.name}</strong>?
            </Col>
            <Col xs={12} className="question-col"></Col>
          </Row>
        </Container>
      </>
    </StaticModal>
  );
};

export default ModalDeleteItem;
