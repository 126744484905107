//import axios from 'axios';
import { Dispatch } from 'react';
import * as NetWorking from '../netWorking';
import { DeleteUser, SearchUsers } from '../users/UsersTypes';
import {
  CampaignDispatchTypes,
  SET_COUNTRIES_DATA,
  SET_LIST_LICENSES,
  SET_PROBLEMS,
  STATE_LOADING,
  USERS_CAMPAIGN_LOADING,
  USERS_CAMPAIGN_SUCCESS,
} from './campaignsTypes';

export const getProblems = (fun?: any) => async (
  dispatch: Dispatch<CampaignDispatchTypes>,
) => {
  NetWorking.get(
    NetWorking.problems,
    (res: any) => {
      dispatch({
        type: SET_PROBLEMS,
        problems: res.data.response,
      });
      if (fun) {
        fun(res.data.response[0]?.country._id);
      }
    },
    (error: any) => {
      dispatch({
        type: SET_PROBLEMS,
        problems: [],
      });
    },
  );
};

export const createProblems = (data: any, fun?: any) => async (
  dispatch: Dispatch<CampaignDispatchTypes>,
) => {
  NetWorking.post(
    NetWorking.problems,
    data,
    (res: any) => {
      if (fun) {
        fun();
      }
    },
    (error: any) => {
      if (fun) {
        fun();
      }
    },
  );
};

export const updateProblems = (data: any, fun?: any) => async (
  dispatch: Dispatch<CampaignDispatchTypes>,
) => {
  NetWorking.put(
    NetWorking.problems,
    data,
    (res: any) => {
      if (fun) {
        fun();
      }
    },
    (error: any) => {
      if (fun) {
        fun();
      }
    },
  );
};

export const deleteProblems = (_id: string, fun?: any) => async (
  dispatch: Dispatch<CampaignDispatchTypes>,
) => {
  NetWorking.deleteRequest(
    NetWorking.problems + `/${_id}`,
    (res: any) => {
      if (fun) {
        fun();
      }
    },
    (error: any) => {
      if (fun) {
        fun();
      }
    },
  );
};

export const getCountries = (fun?: any) => async (
  dispatch: Dispatch<CampaignDispatchTypes>,
) => {
  dispatch({
    type: STATE_LOADING,
    value: true,
  });
  NetWorking.get(
    NetWorking.getCountriesWithCampaignsCount,
    (res: any) => {
      dispatch({
        type: SET_COUNTRIES_DATA,
        countries: res.data.response,
      });
      if (fun) {
        fun(res.data.response[0]?.country._id);
      }
    },
    (error: any) => {
      dispatch({
        type: STATE_LOADING,
        value: false,
      });
    },
  );
};

export const blockUser = (data: any, fun?: any) => async (
  dispatch: Dispatch<CampaignDispatchTypes>,
) => {
  NetWorking.post(
    NetWorking.blockUser,
    data,
    (res: any) => {
      fun();
    },
    (error: any) => {},
  );
};

export const createLicense = (data: any, fun: any, error: any) => async (
  dispatch: Dispatch<CampaignDispatchTypes>,
) => {
  dispatch({
    type: STATE_LOADING,
    value: true,
  });
  NetWorking.post(
    NetWorking.postLicense,
    data,
    (res: any) => {
      fun();
    },
    (err: any) => {
      error();
      dispatch({
        type: STATE_LOADING,
        value: false,
      });
    },
  );
};

export const createLicenseUser = (data: any, fun: any, error: any) => async (
  dispatch: Dispatch<CampaignDispatchTypes>,
) => {
  dispatch({
    type: STATE_LOADING,
    value: true,
  });
  NetWorking.post(
    NetWorking.createUsers,
    data,
    (res: any) => {
      fun();
    },
    (err: any) => {
      error();
      dispatch({
        type: STATE_LOADING,
        value: false,
      });
    },
  );
};

export const updateLicenseUser = (data: any, fun: any, error: any) => async (
  dispatch: Dispatch<CampaignDispatchTypes>,
) => {
  dispatch({
    type: STATE_LOADING,
    value: true,
  });
  NetWorking.put(
    NetWorking.updateCreateUsers,
    data,
    (res: any) => {
      fun();
    },
    (err: any) => {
      error();
      dispatch({
        type: STATE_LOADING,
        value: false,
      });
    },
  );
};

export const deleteLicenseUser = (data: DeleteUser, successFun: any) => {
  return async (dispatch: any) => {
    dispatch({
      type: STATE_LOADING,
      value: true,
    });
    return await NetWorking.post(
      NetWorking.deleteUser,
      data,
      (res: any) => {
        console.log(res);
        successFun();
      },
      (error: any) => {
        error();
        dispatch({
          type: STATE_LOADING,
          value: false,
        });
      },
    );
  };
};

export const postListByStateCityUsers = (data: SearchUsers) => {
  return async (dispatch: any) => {
    dispatch({
      type: USERS_CAMPAIGN_LOADING,
      value: true,
    });
    return await NetWorking.post(
      NetWorking.postByStateCityUsers,
      data,
      (res: any) => {
        console.log(data);
        console.log(res);
        return dispatch({
          type: USERS_CAMPAIGN_SUCCESS,
          users: res.data.response.users,
        });
      },
      (error: any) => {
        return dispatch({
          type: USERS_CAMPAIGN_LOADING,
          value: false,
        });
      },
    );
  };
};

export const accessLicense = (data: any, fun: any, error: any) => async (
  dispatch: Dispatch<CampaignDispatchTypes>,
) => {
  NetWorking.post(
    NetWorking.specialToken,
    data,
    (res: any) => {
      fun(res.data.response);
    },
    (err: any) => {
      error(err);
    },
  );
};

export const updateLicense = (data: any, fun: any, error: any) => async (
  dispatch: Dispatch<CampaignDispatchTypes>,
) => {
  dispatch({
    type: STATE_LOADING,
    value: true,
  });
  NetWorking.put(
    NetWorking.postLicense,
    data,
    (res: any) => {
      fun(res.data.response);
    },
    (err: any) => {
      error(err);
      dispatch({
        type: STATE_LOADING,
        value: false,
      });
    },
  );
};

export const getLicenses = (countryId: string) => async (
  dispatch: Dispatch<CampaignDispatchTypes>,
) => {
  dispatch({
    type: STATE_LOADING,
    value: true,
  });
  NetWorking.get(
    NetWorking.getLicenses + countryId,
    (res: any) => {
      dispatch({
        type: SET_LIST_LICENSES,
        listLicenses: res.data.response,
      });
    },
    (error: any) => {
      dispatch({
        type: STATE_LOADING,
        value: false,
      });
    },
  );
};
