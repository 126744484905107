import moment from 'moment';
import { SEX } from '../redux/RgRc/RgRcTypes';

export const validEmailRegex = RegExp(
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
);
// MIN 10 digits
// export const validPhoneRegex = RegExp(
//   /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
// );
// MIN 5 digits
export const validPhoneRegex = RegExp(/^[0-9]{5,}$/);

export const yearRegex = RegExp(/^(19|20)\d{2}$/i);

export const onlyDigits = RegExp(/^[0-9\s]*$/);

export const onlyLetters = RegExp(/^[a-zA-ZñÑ\s]*$/);

export const onlyLettersNumbersSpaces = RegExp(
  /^[A-Za-z0-9ñÑ ]*[A-Za-z0-9ñÑ][A-Za-z0-9ñÑ ]*$/,
);

export const passwordValidation = RegExp(
  /^(?=.*\d)(?=.*[\u0021-\u002b\u003c-\u0040])(?=.*[A-Z])(?=.*[a-z])\S{6,20}$/,
);

export const stepsToKm = (steps: number) => {
  return steps ? (steps * 0.0008).toFixed(2) : 0;
};

export const GOOGLE_API_KEY = () => {
  //todo: meanwhile
  return (
    process.env.REACT_APP_GOOGLEAPIKEY ||
    'AIzaSyCvjW-TQJYgn7BgaDpsz7_KK1a1EdwDJk4'
  );
};

export const GoogleAPIKEY = 'AIzaSyCvjW-TQJYgn7BgaDpsz7_KK1a1EdwDJk4';

export enum INCIDENCE_TYPE {
  OPEN = 'OPEN',
  ASSIGNED = 'ASSIGNED',
  COMPLETED = 'COMPLETED',
}

export enum XLS_TYPE {
  CORE_XLS_TYPE,
  VOTERS_BOX_XLS_TYPE,
  RG_RC_XLS_TYPE,
  VOTERS_TYPE,
  CAPTURIST_XLS_TYPE,
  VB_ASSIGN_RGRC_XLS_TYPE,
  CAPTURIST_BOSS_XLS_TYPE,
  CAPTURIST_LV1_XLS_TYPE,
  BINGO_XLS_TYPE,
}

export enum ROLE {
  SUPER_ADMIN = 'SUPER_ADMIN',
  CALL_CENTER = 'CALL_CENTER',
  ADMIN_BOSS = 'ADMIN_BOOS',
  ADMIN = 'ADMIN',
  RC = 'RC',
  RG = 'RG',
  RC_SUBSTITUTE = 'RC_SUBSTITUTE', // TODO: delete role
  LAWYER = 'LAWYER',
  LAWYER_BOSS = 'LAWYER_BOSS',
  CAPTURISTA = 'CAPTURIST', // TODO: delete role
  MOVILIZATOR = 'MOVILIZATOR', // TODO: delete role
  CAP_MOVI = 'CAP_MOVI', // TODO: delete role
  MOBILIZER_BOSS = 'MOBILIZER_BOSS',
  MOBILIZER = 'MOBILIZER',
  MOBILIZER_HELPERS = 'MOBILIZER_HELPERS',
  COORDINATOR_L1 = 'COORDINATOR_L1',
  COORDINATOR_L2 = 'COORDINATOR_L2',
  CAPTURIST_BOSS = 'CAPTURIST_BOSS',
  CAPTURIST = 'CAPTURIST',
  CAPTURIST_HELPERS = 'CAPTURIST_HELPERS',
  CAP_MOBI = 'CAP_MOBI',
  CUSTOM = 'CUSTOM',
}

export function ID() {
  // Math.random should be unique because of its seeding algorithm.
  // Convert it to base 36 (numbers + letters), and grab the first 9 characters
  // after the decimal.
  return '_' + Math.random().toString(36).substr(2, 9);
}

export function numberWithCommas(x: number) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function capitalizeWords(str: string) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export function array_move(arr: any[], old_index: number, new_index: number) {
  if (new_index >= arr.length) {
    var k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr; // for testing
}

export const format = 'D MMM YYYY';

export const formatPhoneNumber = (str: string) => {
  //Filter only numbers from the input
  let cleaned = ('' + str).replace(/\D/g, '');
  //Check if the input is of correct length
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{3})(\d{3})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3] + '-' + match[4];
  }
  return str;
};

export const getSex = (sex: any): string => {
  switch (sex) {
    case SEX.MAN:
      return 'Hombre';
    case SEX.WOMAN:
      return 'Mujer';
    case SEX.APACHE_HELICOPTER:
      return 'Otro';
    default:
      return 'Hombre';
  }
};

export const getMonth = (month: any): string => {
  switch (month) {
    case 1:
      return 'Jan';
    case 2:
      return 'Feb';
    case 3:
      return 'Mar';
    case 4:
      return 'Apr';
    case 5:
      return 'May';
    case 6:
      return 'Jun';
    case 7:
      return 'Jul';
    case 8:
      return 'Aug';
    case 9:
      return 'Sep';
    case 10:
      return 'Oct';
    case 11:
      return 'Nov';
    case 12:
      return 'Dic';
    default:
      return '';
  }
};

export const leapYear = (year: number): boolean => {
  return (year % 4 == 0 && year % 100 != 0) || year % 400 == 0;
};

export const getDaysPerMonth = (month: any): number => {
  let year = parseInt(moment().format('YYYY'));
  switch (month) {
    case 1:
      return 31;
    case 2:
      return leapYear(year) ? 29 : 28;
    case 3:
      return 31;
    case 4:
      return 30;
    case 5:
      return 31;
    case 6:
      return 30;
    case 7:
      return 31;
    case 8:
      return 31;
    case 9:
      return 30;
    case 10:
      return 31;
    case 11:
      return 30;
    case 12:
      return 31;
    default:
      return 0;
  }
};

export enum INCOME {
  LOW,
  INTER_MEDIUM,
  HIGH,
  VERY_HIGH,
}

export const getIncome = (income: any): string => {
  switch (income) {
    case INCOME.LOW:
      return 'Bajo';
    case INCOME.INTER_MEDIUM:
      return 'intermedio';
    case INCOME.HIGH:
      return 'Alto';
    case INCOME.VERY_HIGH:
      return 'Muy Alto';
    default:
      return 'Bajo';
  }
};

export const getTime = (t: any, startDate: any, endDate: any) => {
  var duration = moment.duration(moment(endDate).diff(moment(startDate)));
  var time = duration.seconds();
  // console.log(time);
  // var year = Math.abs(Math.round(time / (60 * 60 * 24) / 365.25));
  var month = Math.abs(Math.round(time / (60 * 60 * 24 * 7 * 4)));
  var days = Math.abs(Math.round(time / (3600 * 24)));
  var minutes = Math.abs(Math.round(time / 60));
  if (month !== 0) {
    if (month >= 1) return month + ' ' + t('time.months');
    else return month + ' ' + t('time.month');
  } else if (days !== 0) {
    if (days >= 1) return days + ' ' + t('time.days');
    else return days + ' ' + t('time.day');
  } else {
    var hour = Math.abs(minutes / 60);
    if (hour >= 1) {
      if (hour >= 1) return Math.round(hour) + t('time.hours');
      else return Math.round(hour) + ' ' + t('time.hour');
    } else {
      if (minutes >= 1) return minutes + ' ' + t('time.minutes');
      else return minutes + ' ' + t('time.minute');
    }
  }
};

export const getAddress = (address: any) => {
  let result = '';
  const newAddress = address;
  if (newAddress) {
    if (newAddress.street && newAddress.street !== '')
      result = newAddress.street;
    if (newAddress.exteriorNumber && newAddress.exteriorNumber !== '')
      result = result + `, ${newAddress.exteriorNumber}`;
    if (newAddress.interiorNumber && newAddress.interiorNumber !== '')
      result = result + `, ${newAddress.interiorNumber}`;
    if (newAddress.postalCode && newAddress.postalCode !== '')
      result = result + `, ${newAddress.postalCode}`;
    if (newAddress.suburb && newAddress.suburb !== '')
      result = result + `, ${newAddress.suburb}`;
  }
  return result;
};

export const getMaxDistance = (zoom: number) => {
  let km = 144447.6442;
  if (zoom === 20) {
    km = 1128.49722;
  } else if (zoom === 19) {
    km = 2256.99444;
  } else if (zoom === 18) {
    km = 4513.98888;
  } else if (zoom === 17) {
    km = 9027.977761;
  } else if (zoom === 16) {
    km = 18055.95552;
  } else if (zoom === 15) {
    km = 36111.91104;
  } else if (zoom === 14) {
    km = 72223.82209;
  } else if (zoom === 13) {
    km = 144447.6442;
  } else if (zoom === 12) {
    km = 288895.2884;
  } else if (zoom === 11) {
    km = 577790.5767;
  } else if (zoom === 10) {
    km = 1155581.153;
  } else if (zoom === 9) {
    km = 2311162.307;
  } else if (zoom === 8) {
    km = 4622324.614;
  } else if (zoom === 7) {
    km = 9244649.227;
  } else if (zoom === 6) {
    km = 18489298.45;
  } else if (zoom === 5) {
    km = 36978596.91;
  } else if (zoom === 4) {
    km = 73957193.82;
  } else if (zoom === 3) {
    km = 147914387.6;
  } else if (zoom === 2) {
    km = 295828775.3;
  } else if (zoom === 1) {
    km = 591657550.5;
  }
  return km;
};

export const getMaxZoom = (km: number) => {
  let zoom = 20;
  if (km <= 1128.49722) {
    zoom = 20;
  } else if (km <= 2256.99444) {
    zoom = 19;
  } else if (km <= 4513.98888) {
    zoom = 18;
  } else if (km <= 9027.977761) {
    zoom = 17;
  } else if (km <= 18055.95552) {
    zoom = 16;
  } else if (km <= 36111.91104) {
    zoom = 15;
  } else if (km <= 72223.82209) {
    zoom = 14;
  } else if (km <= 144447.6442) {
    zoom = 13;
  } else if (km <= 288895.2884) {
    zoom = 12;
  } else if (km <= 577790.5767) {
    zoom = 11;
  } else if (km <= 1155581.153) {
    zoom = 10;
  } else if (km <= 2311162.307) {
    zoom = 9;
  } else if (km <= 4622324.614) {
    zoom = 8;
  } else if (km <= 9244649.227) {
    zoom = 7;
  } else if (km <= 18489298.45) {
    zoom = 6;
  } else if (km <= 36978596.91) {
    zoom = 5;
  } else if (km <= 73957193.82) {
    zoom = 4;
  } else if (km <= 147914387.6) {
    zoom = 3;
  } else if (km <= 295828775.3) {
    zoom = 2;
  } else {
    zoom = 1;
  }
  return zoom;
};
