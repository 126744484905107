// Without AUTHENTICATION
export const GUEST_LOGIN = '/login';
export const GUEST_FORGOT = '/forgot';
export const GUEST_ACTION = '/action';
export const GUEST_PASSWORD_CONFIRM = '/passwordConfirmation';

// With Auth and super Admin
export const SUPER_CAMPAIGNS = '/campaigns';
export const SUPER_CAMPAIGNS_LICENSE_CITY = '/campaigns/:licenseId/:cityId';
export const SUPER_SHARED = '/shared';
export const SUPER_SHARED_PARTIES = '/shared/parties';
export const SUPER_SHARED_COUNTRIES = '/shared/countries';
export const SUPER_SHARED_COUNTRIES_CREATION = '/shared/countries/creation';
export const SUPER_SHARED_COUNTRIES_CREATION_SELECTED =
  '/shared/countries/creation/selected';
export const SUPER_SHARED_GENERAL = '/shared/general';
export const SUPER_SHARED_USERS = '/shared/users';
export const SUPER_USERS = '/users';

// With AUTHENTICATION
export const PRIVATE_DASHBOARD = '/dashboard';
export const PRIVATE_VOTERS = '/voters';
export const PRIVATE_VOTERS_VIEW = '/voters/:_id';
export const PRIVATE_ROUTES = '/assignments';
export const PRIVATE_TRACKING = '/progress';
export const PRIVATE_TEAMS = '/teams';
export const PRIVATE_AUDITING = '/auditing';
export const PRIVATE_POLLWATCHERS = '/pollwatchers';
export const PRIVATE_LAWYERS = '/lawyers';
export const PRIVATE_POLLINGSTATIONS = '/pollingstations';
export const PRIVATE_PROBLEMS = '/problems';
export const PRIVATE_HISTORY = '/historical';
export const PRIVATE_POLLS = '/polls';
export const PRIVATE_DASHBOARD_DIAD = '/dashboardD';
export const PRIVATE_MAP = '/map';
export const PRIVATE_POLLINGSTATIONS_ACTIVE = '/pollingstations_active';
export const PRIVATE_BINGO = '/bingo';
export const PRIVATE_MOBILIZATION = '/mobilization';
export const PRIVATE_POLLINGSTATIONS_SETTINGS = '/boxesSettings';
export const PRIVATE_COALITIONS = '/coalitions';
export const PRIVATE_VOTERS_LIST = '/votersList';
export const PRIVATE_TRAINING_LIST = '/trainings';
export const PRIVATE_TRAINING_CREATE = '/trainings/create';
export const PRIVATE_TRAINING_EDIT = '/trainings/edit/:_id';
export const PRIVATE_USERS = '/users';
export const PRIVATE_ROLES = '/roles';
