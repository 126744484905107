import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../redux/Store';
import { useTranslation } from 'react-i18next';

import {
  validEmailRegex,
  onlyLetters,
  onlyDigits,
  validPhoneRegex,
  ROLE,
  GoogleAPIKEY,
} from '../../../utils/utils';
import { checkInUse } from '../../../redux/User/userActions';
import {
  putUpdateUserTeam,
  postCreateUserTeam,
} from '../../../redux/teams/teamsActions';
import { Team } from '../../../redux/RgRc/RgRcTypes';

import GoogleMapReact from 'google-map-react';
import { VotersBoxMapIcon } from '../../../assets/svg/index';
import InputComponent from '../../../components/sub-component/input';
import StaticModal from '../../../dialogs/StaticModal';
import Container from 'react-bootstrap/Container';

// tslint:disable-next-line: prefer-const
let defaultTeam: Team;

interface ModalAddVolunteerProps {
  modalShow: any;
  setModalShow: any;
  successFun: any;
  edit?: any;
  type: any;
  level: any;
  isTeam: boolean;
  selectedTeam?: any;
  mainTitle: string;
  getPin: any;
}

export const ModalAddVolunteer: React.FunctionComponent<ModalAddVolunteerProps> = ({
  modalShow,
  setModalShow,
  successFun,
  edit,
  type,
  level,
  isTeam,
  selectedTeam,
  mainTitle = '',
  getPin,
}: any) => {
  const citySelected = useSelector((globalState: RootStore) => {
    return globalState.core.globalMunicipalitySelected;
  });

  const stateSelected = useSelector((globalState: RootStore) => {
    return globalState.core.globalStateSelected;
  });

  const defaultEdit = {
    _id: '',
    firstName: '',
    lastName: '',
    email: '',
    cellphone: '',
    teamName: '',
    focus: '',
    level: level,
    role: type,
    geoLocation: {
      type: 'Point',
      coordinates: [
        citySelected?.defaultCenter
          ? citySelected?.defaultCenter.lng
          : -117.1888535,
        citySelected?.defaultCenter
          ? citySelected?.defaultCenter.lat
          : 32.7157431,
      ],
    },
    blocked: false,
    deleted: false,
    blockedReason: '',
    name: '',
    municipalityId: '',
    team: defaultTeam,
    campaignId: localStorage.campaignId,
  };
  const [toSave, setToSave] = useState(defaultEdit);
  const [toCompare, setToCompare] = useState(defaultEdit);

  const [submitMessage, setSubmitMessage] = useState(String);
  const [submitConfirmation, setSubmitConfirmation] = useState(false);

  const [loadData, setLoadData] = useState(false);

  const inputFirstnameRef: any = useRef(null);
  const inputLastnameRef: any = useRef(null);
  const inputEmailRef: any = useRef(null);
  const inputCellphoneRef: any = useRef(null);
  const inputTeamNameRef: any = useRef(null);
  const inputFocusRef: any = useRef(null);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const getMunicipality = () => {
    setToSave((prevState) => ({
      ...prevState,
      municipalityId: citySelected?._id || '',
      states: [stateSelected?._id],
      cities: [citySelected?._id],
    }));
  };

  const submitFun = (validSubmit: any) => {
    if (validSubmit) {
      setSubmitMessage(validSubmit);
      setSubmitConfirmation(true);
    } else {
      if (toSave._id !== '') {
        setModalShow(false);
        dispatch(
          putUpdateUserTeam(
            {
              ...toSave,
            },
            () => {
              successFun();
            },
          ),
        );
      } else {
        if (!isTeam) {
          setModalShow(false);
          dispatch(
            postCreateUserTeam(
              {
                ...toSave,
              },
              (res: any) => {
                successFun();
                getPin(res.data.response.user._id);
              },
            ),
          );
        } else {
          setModalShow(false);
          dispatch(
            postCreateUserTeam(
              {
                ...toSave,
                team: {
                  _id: selectedTeam,
                },
              },
              (res: any) => {
                successFun();
                getPin(res.data.response.user._id);
              },
            ),
          );
        }
      }
    }
  };

  const verifyCellphoneInUse = () => {
    let checkInUseAux: any = {
      cellphone: toSave.cellphone,
    };

    if (toSave.cellphone === '' || toSave.cellphone === undefined) {
      validSubmit(1);
    } else {
      dispatch(
        checkInUse(checkInUseAux, (data: any) => {
          validSubmit(data.error);
        }),
      );
    }
  };

  const validSubmit = (data: any) => {
    let newStringValue = verifyFields(data);
    submitFun(newStringValue);
  };

  const verifyFields = (data: any) => {
    if (toSave.firstName === '') {
      return t('territorial.volunteer.modal.error.name');
    } else if (toSave.lastName === '') {
      return t('territorial.volunteer.modal.error.lastname');
    } else if (toSave.email !== '' && !validEmailRegex.test(toSave.email)) {
      return t('territorial.volunteer.modal.error.email');
    } else if (
      toSave.cellphone === '' ||
      !validPhoneRegex.test(toSave.cellphone) ||
      data === 0
    ) {
      if (data === 0 && toSave.cellphone === toCompare.cellphone) {
        return '';
      } else if (data === 0 && toSave.cellphone !== toCompare.cellphone) {
        return t('territorial.volunteer.modal.error.cellphoneExist');
      } else if (toSave.cellphone === '') {
        return t('territorial.volunteer.modal.error.cellphoneComplete');
      } else if (!validPhoneRegex.test(toSave.cellphone)) {
        return t('territorial.volunteer.modal.error.cellphone');
      } else if (toSave.cellphone.length < 5) {
        return t('territorial.volunteer.modal.error.cellphone');
      }
    } else if (type !== ROLE.CAPTURIST) {
      if (toSave.teamName === '') {
        return t('territorial.volunteer.modal.error.teamName');
      }
    } else {
      return '';
    }
  };

  useEffect(() => {
    if (edit !== undefined) {
      setToSave((prevState) => ({
        ...prevState,
        ...edit,
        cellphone: edit.cellphone.replace(/\D/g, ''),
        name: edit.firstName + ', ' + edit.lastName,
      }));
      setToCompare((prevState) => ({
        ...prevState,
        ...edit,
        cellphone: edit.cellphone.replace(/\D/g, ''),
        name: edit.firstName + ', ' + edit.lastName,
      }));
    }
    setLoadData(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edit]);

  useEffect(() => {
    if (loadData) {
      getMunicipality();
      inputFirstnameRef.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadData]);

  return (
    <StaticModal
      show={modalShow}
      title={mainTitle}
      closeText={t('submit.cancel')}
      confirmText={t('general.modal.btn.confirm')}
      onHide={() => {
        setModalShow(false);
      }}
      submitFun={verifyCellphoneInUse}
      validBtn={false}
      size="xxl"
      sizeClass="md modal-rc"
    >
      <>
        <Container className="dialog-add-teams">
          {submitConfirmation && (
            <div className="col-item-message">
              <p>{submitMessage}</p>
            </div>
          )}
          <div className="row-item-modal first-row">
            <div className="col-item-modal">
              <InputComponent
                placeholder={t('general.modal.lbl.placeholder.name')}
                length={50}
                onChange={(value: string) => {
                  setToSave((prevState) => ({
                    ...prevState,
                    firstName: value,
                  }));
                }}
                title={t('general.modal.lbl.name')}
                value={toSave.firstName}
                regExp={onlyLetters}
                horizontal={false}
                refElement={inputFirstnameRef}
                focusAction={() => {
                  inputLastnameRef.current.focus();
                }}
              ></InputComponent>
            </div>
            <div className="col-item-modal">
              <InputComponent
                placeholder={t('general.modal.lbl.placeholder.lastname')}
                length={50}
                onChange={(value: string) => {
                  setToSave((prevState) => ({
                    ...prevState,
                    lastName: value,
                  }));
                }}
                title={t('general.modal.lbl.lastname')}
                value={toSave.lastName}
                regExp={onlyLetters}
                horizontal={false}
                refElement={inputLastnameRef}
                focusAction={() => {
                  inputCellphoneRef.current.focus();
                }}
              ></InputComponent>
            </div>
          </div>
          <div className="row-item-modal second-row">
            <div className="col-item-modal cellphone-item">
              <InputComponent
                length={20}
                placeholder={t('general.modal.lbl.cellphone')}
                onChange={(value: string) => {
                  setToSave((prevState) => ({
                    ...prevState,
                    cellphone: value.trim(),
                  }));
                }}
                title={t('general.modal.lbl.cellphone')}
                regExp={onlyDigits}
                value={toSave.cellphone}
                horizontal={false}
                refElement={inputCellphoneRef}
                focusAction={() => {
                  inputEmailRef.current.focus();
                }}
              ></InputComponent>
            </div>
            <div className="col-item-modal email-item">
              <InputComponent
                placeholder={t('general.modal.lbl.email')}
                length={50}
                onChange={(value: string) => {
                  setToSave((prevState) => ({
                    ...prevState,
                    email: value.trim(),
                  }));
                }}
                title={t('general.modal.lbl.emailOptional')}
                value={toSave.email}
                horizontal={false}
                refElement={inputEmailRef}
                focusAction={() => {
                  if (type !== ROLE.CAPTURIST) {
                    inputTeamNameRef.current.focus();
                  } else {
                    inputFocusRef.current.focus();
                  }
                }}
              ></InputComponent>
            </div>
          </div>
          <div className="row-item-modal third-row">
            {type !== ROLE.CAPTURIST && (
              <div className="col-item-modal">
                <InputComponent
                  placeholder={t('general.modal.lbl.placeholder.team')}
                  length={50}
                  onChange={(value: string) => {
                    setToSave((prevState) => ({
                      ...prevState,
                      teamName: value,
                    }));
                  }}
                  title={t('general.modal.lbl.team')}
                  value={toSave.teamName}
                  horizontal={false}
                  refElement={inputTeamNameRef}
                  focusAction={() => {
                    inputFocusRef.current.focus();
                  }}
                ></InputComponent>
              </div>
            )}
            <div className="col-item-modal">
              <InputComponent
                placeholder={t('general.modal.lbl.placeholder.focus')}
                length={50}
                onChange={(value: string) => {
                  setToSave((prevState) => ({
                    ...prevState,
                    focus: value,
                  }));
                }}
                title={t('general.modal.lbl.focus')}
                value={toSave.focus}
                horizontal={false}
                refElement={inputFocusRef}
                focusAction={() => {
                  verifyCellphoneInUse();
                }}
              ></InputComponent>
            </div>
          </div>
          <div className="row-item-modal fourth-row">
            <div className="map-col">
              <h2>{t('settings.polling.modal.form.select.location')}</h2>
              <div id="map">
                <GoogleMapReact
                  yesIWantToUseGoogleMapApiInternals
                  shouldUnregisterMapOnUnmount={true}
                  style={{
                    width: '100%',
                    height: '100%',
                  }}
                  bootstrapURLKeys={{
                    key: GoogleAPIKEY,
                  }}
                  defaultZoom={citySelected?.defaultZoom}
                  center={
                    !toSave.geoLocation?.coordinates
                      ? {
                          lng: -117.1888535,
                          lat: 32.7157431,
                        }
                      : {
                          lng: parseFloat(toSave.geoLocation.coordinates[0]),
                          lat: parseFloat(toSave.geoLocation.coordinates[1]),
                        }
                  }
                  options={{ clickableIcons: false }}
                  onDragEnd={(map) => {
                    setToSave((prevState) => ({
                      ...prevState,
                      geoLocation: {
                        type: 'Point',
                        coordinates: [map.center.lng(), map.center.lat()],
                      },
                    }));
                  }}
                ></GoogleMapReact>
              </div>
              <div className="marker-layer">
                <VotersBoxMapIcon></VotersBoxMapIcon>
              </div>
            </div>
          </div>
        </Container>
      </>
    </StaticModal>
  );
};

export default ModalAddVolunteer;
