import {
  VOTERS_LOADING,
  VOTERS_SUCCESS,
  VOTERS_FAIL,
  VOTERS_SUCCESS_ONE,
  ConcentratedVoters,
  SearchVoters,
  VOTERS_COUNT_SUCCESS,
} from './VotersTypes';

import * as netWorking from '../netWorking';

export const getVoterById = (_id: string, successFun: any, errorFunc: any) => {
  return async (dispatch: any) => {
    dispatch({
      type: VOTERS_LOADING,
    });
    return await netWorking.get(
      netWorking.getVoterById + _id + '/1',
      (res: any) => {
        successFun();
        return dispatch({
          type: VOTERS_SUCCESS_ONE,
          payload: res.data.response,
        });
      },
      (error: any) => {
        errorFunc(error);
        return dispatch({
          type: VOTERS_FAIL,
        });
      },
    );
  };
};

export const postGetVotersTotal = (data: SearchVoters) => {
  return async (dispatch: any) => {
    return await netWorking.post(
      netWorking.postGetTotalVoters,
      data,
      (res: any) => {
        console.log(res);
        return dispatch({
          type: VOTERS_COUNT_SUCCESS,
          payload: res.data.response,
        });
      },
      (error: any) => {
        return dispatch({
          type: VOTERS_FAIL,
        });
      },
    );
  };
};

export const postVotersListByAddressElectionsSearch = (data: SearchVoters) => {
  return async (dispatch: any) => {
    dispatch({
      type: VOTERS_LOADING,
    });
    return await netWorking.post(
      netWorking.postVotersByAddressElectionsSearch,
      data,
      (res: any) => {
        // dispatch({
        //   type: VOTERS_COUNT_SUCCESS,
        //   payload: res.data.response,
        // });
        return dispatch({
          type: VOTERS_SUCCESS,
          payload: res.data.response,
        });
      },
      (error: any) => {
        return dispatch({
          type: VOTERS_FAIL,
        });
      },
    );
  };
};

export const getConcentratedVotersList = (
  data: ConcentratedVoters,
  successFun: any,
  errorFunc: any,
) => {
  return async (dispatch: any) => {
    dispatch({
      type: VOTERS_LOADING,
    });
    return await netWorking.post(
      netWorking.postConcentratedVoters,
      data,
      (res: any) => {
        successFun(res.data.response);
      },
      (error: any) => {
        errorFunc(error);
        return dispatch({
          type: VOTERS_FAIL,
        });
      },
    );
  };
};
