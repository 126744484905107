import React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
// redux stuff
import { connect } from "react-redux";
import { PRIVATE_DASHBOARD, PRIVATE_VOTERS } from "./paths";

interface MyRouteProps extends RouteProps {
  component: any;
  authenticated: boolean;
  rest?: any;
}

const GuestRoute: React.SFC<MyRouteProps> = ({
  component: Component,
  authenticated,
  ...rest
}) => {
  // console.log("rendered")
  return (
    <Route
      {...rest}
      render={(props) =>
        authenticated ? (
          <Redirect to={PRIVATE_VOTERS} />
        ) : (
          <Component {...props} history={props.history} />
        )
      }
    />
  );
};
const mapStateToProps = (state: any) => ({
  authenticated: state.user.authenticated,
});
export default connect(mapStateToProps)(GuestRoute);
