import {
  LAWYERS_LOADING,
  LAWYERS_FAIL,
  LAWYERS_SUCCESS,
  LAWYERS_COUNT_SUCCESS,
  LAWYERS_SEARCH_SUCCESS,
  LawyersDispatchTypes,
} from './lawyersTypes';

interface LawyersState {
  loading: boolean;
  page?: number;
  records?: number;
  sort?: any[];
  search: string;
  totalPage: number;
  totalLawyers: number;
  lawyers: any[];
}

const defaultState: LawyersState = {
  loading: false,
  page: 1,
  records: 10,
  sort: ['firstName', 'asc'],
  search: '',
  totalPage: 1,
  totalLawyers: 0,
  lawyers: [],
};

const lawyersReducer = (
  state: LawyersState = defaultState,
  action: LawyersDispatchTypes,
): LawyersState => {
  switch (action.type) {
    case LAWYERS_FAIL:
      return {
        ...state,
        loading: false,
      };
    case LAWYERS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case LAWYERS_SUCCESS:
      const list = [];
      const data: any = action.payload;
      list.push(...(data.lawyers || []));
      return {
        ...state,
        lawyers: list,
        page: data.page,
        records: data.records,
        sort: ['firstName', data.order],
        loading: false,
      };
    case LAWYERS_COUNT_SUCCESS:
      const countLawyersData: any = action.payload;
      return {
        ...state,
        totalPage: countLawyersData.pages,
        totalLawyers: countLawyersData.totalLawyers,
      };
    case LAWYERS_SEARCH_SUCCESS:
      return {
        ...state,
        search: action.searchValue,
      };
    default:
      return state;
  }
};

export default lawyersReducer;
