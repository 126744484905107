import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
//import { useHistory } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import StaticModal from '../../../../../dialogs/StaticModal';
import InputComponent from '../../../../../components/sub-component/input';
import { useDropzone } from 'react-dropzone';
import { BsUpload } from 'react-icons/bs';
import { EditIcon } from '../../../../../assets/svg';
import {
  createParties,
  updateParties,
  uploadParties,
} from '../../../../../redux/parties/partiesActions';

interface AddModalProps {
  modalShow: any;
  setModalShow: any;
  successFun: any;
  toEdit?: any;
  countrySelected: any;
}

let defaultFile: any;

export const AddPartyModalComponent: React.FunctionComponent<AddModalProps> = ({
  modalShow,
  setModalShow,
  successFun,
  toEdit,
  countrySelected,
}: AddModalProps) => {
  const defaultEdit = {
    _id: '',
    name: '',
    logoPath: '',
    abbreviation: '',
    countryName: countrySelected?.name || '',
    countryId: countrySelected?._id || '',
    iso3: '',
    deleted: false,
  };

  const dispatch = useDispatch();

  const [submitConfirmation, setSubmitConfirmation] = useState(false);
  const [submitMessage, setSubmitMessage] = useState(String);
  const [toSave, setToSave] = useState(defaultEdit);
  const [load, setLoad] = useState(false);
  const [, setToCompare] = useState(defaultEdit);
  const [image, setImage] = useState('');
  const [file, setFile] = useState(defaultFile);

  // eslint-disable-next-line
  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    // accept: '.jpg, .png, .jpeg, image/x-png, image/gif, image/jpeg',
    onDrop: (acceptedFile: any) => {
      if (acceptedFile.length > 0) {
        setImage(URL.createObjectURL(acceptedFile[0]));
        setFile(acceptedFile[0]);
      }
    },
  });

  useEffect(() => {
    if (!load && modalShow) {
      if (toEdit !== undefined) {
        setToSave({
          _id: toEdit._id,
          name: toEdit.name,
          abbreviation: toEdit.abbreviation,
          countryName: countrySelected?.name || '',
          countryId: countrySelected?._id || '',
          iso3: toEdit.iso3,
          logoPath: toEdit.logoPath,
          deleted: false,
        });
        setToCompare({
          _id: toEdit._id,
          name: toEdit.name,
          abbreviation: toEdit.abbreviation,
          countryName: countrySelected?.name || '',
          countryId: countrySelected?._id || '',
          iso3: toEdit.iso3,
          logoPath: toEdit.logoPath,
          deleted: false,
        });
      } else {
        setToSave(defaultEdit);
        setToCompare(defaultEdit);
      }
      setImage('');
      setFile(defaultFile);
      setSubmitConfirmation(false);
      setSubmitMessage('');
      setLoad(true);
    }
    if (!modalShow) {
      setLoad(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [load, modalShow]);

  const cancelFun = () => {
    setModalShow(false);
  };

  const submitFun = () => {
    if (validSubmit(1)) {
      setSubmitMessage(validSubmit(1));
      setSubmitConfirmation(true);
    } else {
      if (toSave._id === '') onSave();
      else onEdit();
    }
  };

  const validSubmit = (data: any) => {
    if (toSave.name === '') {
      return 'Name is empty';
    } else if (toSave.abbreviation === '') {
      return 'Abbreviation is empty';
    } else {
      return '';
    }
  };

  const onEdit = () => {
    if (image !== '') {
      const formData2 = new FormData();
      formData2.append('file', file);
      dispatch(
        uploadParties(formData2, (res: any) => {
          const save = toSave;
          save.logoPath = res.location;
          dispatch(
            updateParties(
              save,
              (res: any) => {
                // console.log(res.location);
                setModalShow(false);
                successFun();
              },
              (error: any) => {
                if (error.response.request.status === 400) {
                  if (error.response.data.errorMessage === 'Duplicated Data') {
                    setSubmitMessage('Party Already registered');
                    setSubmitConfirmation(true);
                  }
                }
              },
            ),
          );
        }),
      );
    } else {
      dispatch(
        updateParties(
          toSave,
          (res: any) => {
            // console.log(res.location);
            setModalShow(false);
            successFun();
          },
          (error: any) => {
            if (error.response.request.status === 400) {
              if (error.response.data.errorMessage === 'Duplicated Data') {
                setSubmitMessage('Party Already registered');
                setSubmitConfirmation(true);
              }
            }
          },
        ),
      );
    }
  };

  const onSave = () => {
    const formData2 = new FormData();
    formData2.append('file', file);
    dispatch(
      uploadParties(formData2, (res: any) => {
        const save = toSave;
        save.logoPath = res.location;
        dispatch(
          createParties(
            save,
            (res: any) => {
              // console.log(res.location);
              setModalShow(false);
              successFun();
            },
            (error: any) => {
              if (error.response.request.status === 400) {
                if (error.response.data.errorMessage === 'Duplicated Data') {
                  setSubmitMessage('Party Already registered');
                  setSubmitConfirmation(true);
                }
              }
            },
          ),
        );
      }),
    );
  };

  return (
    <StaticModal
      show={modalShow}
      title={toEdit ? 'Edit Admin' : 'Add Admin'}
      closeText={'Cancel'}
      confirmText={toEdit ? 'Edit' : 'Save'}
      onHide={cancelFun}
      submitFun={submitFun}
      validBtn={false}
      customTitle={
        <div className="header-modal-add-admin">
          <Modal.Title className="title-border-bottom">
            <strong>Add Party</strong>
          </Modal.Title>
        </div>
      }
      size="sm"
      sizeClass="add-admin-modal add-party-modal"
    >
      <Row
        noGutters
        className="addModal-content modal-text-row add-campaign-modal"
      >
        {submitConfirmation && (
          <Col xs={12} md={12} className="col-item-message">
            <p className="">{submitMessage}</p>
          </Col>
        )}{' '}
        <Col xs={4} md={4} className="col-item-modal">
          <div className="file-input-box">
            <h2>Imagen</h2>{' '}
            <div {...getRootProps({ className: 'dropzone-party' })}>
              {image === '' && toSave.logoPath === '' && (
                <div className="upload-box">
                  <input {...getInputProps()} />
                  <BsUpload></BsUpload> <p>Upload</p>
                </div>
              )}{' '}
              {((image !== '' && toSave.logoPath === '') ||
                (image !== '' && toSave.logoPath !== '')) && (
                <div className="upload-img-box">
                  <input {...getInputProps()} />
                  <Image src={image}></Image>
                  <div className="edit-box">
                    <EditIcon></EditIcon> <p>Edit</p>
                  </div>
                </div>
              )}
              {image === '' && toSave.logoPath !== '' && (
                <div className="upload-img-box">
                  <input {...getInputProps()} />
                  <Image src={toSave.logoPath}></Image>
                  <div className="edit-box">
                    <EditIcon></EditIcon> <p>Edit</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Col>
        <Col xs={12} md={12} className="col-item-modal">
          <InputComponent
            placeholder={'Partido Accion Nacional'}
            length={50}
            onChange={(value: string) => {
              let name = '';
              name = value;

              setToSave((prevState: any) => ({
                ...prevState,
                name,
              }));
            }}
            title={'Name of Party'}
            value={toSave.name}
            horizontal={false}
          ></InputComponent>
        </Col>
        <Col xs={12} md={12} className="col-item-modal">
          <InputComponent
            placeholder={'PAN'}
            length={50}
            onChange={(value: string) => {
              let abbreviation = '';
              abbreviation = value.toUpperCase();

              setToSave((prevState: any) => ({
                ...prevState,
                abbreviation,
              }));
            }}
            title={'Abbreviation'}
            value={toSave.abbreviation}
            horizontal={false}
          ></InputComponent>
        </Col>
        {/* <Col xs={12} md={12} className="col-item-modal">
          <div className={false ? 'selector-box-horizontal' : 'selector-box'}>
            <h2>Country</h2>
            <Form.Control
              size="sm"
              as="select"
              value={toSave.countryName}
              onChange={(data: any) => {
                const countryName = data.target.value;
                setToSave((prevState: any) => ({
                  ...prevState,
                  countryName,
                }));
              }}
            >
              {countries.map((e: any, index: any) => {
                return (
                  <option
                    // selected={e._id === defaultSelector}
                    key={e.name}
                    value={e.name}
                  >
                    {e.name}
                  </option>
                );
              })}
            </Form.Control>
          </div>
        </Col> */}
      </Row>
    </StaticModal>
  );
};

export default AddPartyModalComponent;
