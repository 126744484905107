import React, { Fragment, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CheckIcon } from '../../assets/svg';
import LayoutBodyComponent from '../../components/LayoutBody';
import TopSeparator from '../../components/TopSeparator';
import {
  PollingStation,
  SET_BINGO,
  UPDATE_BINGO,
  VoterBingo,
} from '../../redux/bingo/bingoTypes';
import { ContextWs, WS_STATES } from '../../redux/socket/wsContext';
import { RootStore } from '../../redux/Store';
import TopBarBingo from './components/topBarBingo';
import './style.scss';

const Bingo: React.FunctionComponent = () => {
  const { socket, wsState, rooms, join, disconnect, off } =
    useContext(ContextWs);
  const [bingoRoomHook, setBingoRoom] = React.useState<string>('');
  const { t } = useTranslation();

  const pollingStation: PollingStation | undefined = useSelector(
    (globalState: RootStore) => globalState.bingo.pollingStation,
  );

  const stats = useSelector(
    (globalState: RootStore) => globalState.bingo.stats,
  );

  const pollingStationSelected = useSelector(
    (globalState: RootStore) => globalState.bingo.pollingStationSelected,
  );

  React.useEffect(() => {
    if (wsState === WS_STATES.OPEN && socket && pollingStationSelected) {
      const bingoRoom = 'bingo' + pollingStationSelected._id;
      socket.once('RoomsJoined', (response: any) => {
        socket.emit('getBingoAll', { _id: pollingStationSelected._id });
      });

      if (off) {
        off(bingoRoomHook);
      }
      setBingoRoom(bingoRoom);
      if (join)
        join(bingoRoom, UPDATE_BINGO, '', '', SET_BINGO, (response: any) => {});
    }
  }, [pollingStationSelected]);

  const getPointComponent = (
    point: { _id: string; number: string; status: string; inDatabase: boolean },
    pollingId: string,
  ) => {
    if (point.status === 'MARKED' && point.inDatabase)
      return (
        <div
          className="point-bingo-item bg-green-point"
          key={point._id}
          onClick={() => {
            socket?.emit('updatePointBingo', {
              _id: pollingId,
              number: point.number,
              status: point.status === 'MARKED' ? 'NONE' : 'MARKED',
            });
          }}
        >
          {point.number}
        </div>
      );
    else if (point.status !== 'MARKED' && point.inDatabase)
      return (
        <div
          className="point-bingo-item "
          key={point._id}
          onClick={() => {
            socket?.emit('updatePointBingo', {
              _id: pollingId,
              number: point.number,
              status: point.status === 'MARKED' ? 'NONE' : 'MARKED',
            });
          }}
        >
          <div className="triangle"></div>
          {point.number}
        </div>
      );
    else if (point.status === 'MARKED' && !point.inDatabase)
      return (
        <div
          className="point-bingo-item bg-grey-point"
          key={point._id}
          onClick={() => {
            socket?.emit('updatePointBingo', {
              _id: pollingId,
              number: point.number,
              status: point.status === 'MARKED' ? 'NONE' : 'MARKED',
            });
          }}
        >
          {point.number}
        </div>
      );
    else
      return (
        <div
          className="point-bingo-item"
          key={point._id}
          onClick={() => {
            socket?.emit('updatePointBingo', {
              _id: pollingId,
              number: point.number,
              status: point.status === 'MARKED' ? 'NONE' : 'MARKED',
            });
          }}
        >
          {point.number}
        </div>
      );
  };

  return (
    <Fragment>
      <TopSeparator></TopSeparator>
      <div className="bingo-box">
        <TopBarBingo></TopBarBingo>
        <LayoutBodyComponent>
          <div className="grid-bingo">
            {pollingStation?.bingo?.voters.map((e) => {
              return getPointComponent(e, pollingStation._id);
            })}
          </div>
          <div className="info-box">
            <div className="title-box">
              <h2>{t('dday.bingo.lbl.symbology')}</h2>
            </div>
            <div className="symbols-box">
              <div className="divider-title">
                <p>Not Voted</p>
              </div>
              <div className="item">
                {getPointComponent(
                  { _id: '', number: '1', status: 'NONE', inDatabase: true },
                  '',
                )}
                <p>{t('dday.bingo.lbl.ours')}</p>
              </div>
              <div className="item">
                {getPointComponent(
                  { _id: '', number: '1', status: 'NONE', inDatabase: false },
                  '',
                )}
                <p>{t('dday.bingo.lbl.anonymous')}</p>
              </div>{' '}
              <div className="divider-title">
                <p>Voted</p>
              </div>
              <div className="item">
                {getPointComponent(
                  { _id: '', number: '1', status: 'MARKED', inDatabase: true },
                  '',
                )}
                <p>{t('dday.bingo.lbl.ours')}</p>
              </div>
              <div className="item">
                {getPointComponent(
                  { _id: '', number: '1', status: 'MARKED', inDatabase: false },
                  '',
                )}
                <p>{t('dday.bingo.lbl.anonymous')}</p>
              </div>
            </div>
            <div className="stats-box">
              {' '}
              <div className="item">
                <p>{t('dday.bingo.lbl.votesNeededWin')}:</p>
                <h2>{stats.toWin}</h2>
              </div>
              <div className="item">
                <p>{t('dday.bingo.lbl.votesToWin')}:</p>
                <h2 className={stats.forWin > 0 ? 'positive' : 'negative'}>
                  {stats.forWin < 0 ? Math.abs(stats.forWin) : stats.forWin}
                </h2>
              </div>
              <div className="item">
                <p>{t('dday.bingo.lbl.votesAvailable')}:</p>
                <h2>{stats.available}</h2>
              </div>
              <div className="item">
                <p>{t('dday.bingo.lbl.alreadyVoted')}:</p>
                <h2>{stats.ourVotes}</h2>
              </div>
              <div className="item">
                <p>{t('dday.bingo.lbl.totalVoted')}:</p>
                <h2>
                  {stats.totalMarked}/
                  {((stats.totalMarked * 100) / stats.total || 0).toFixed(0)}%
                </h2>
              </div>
              <div className="item-info">
                <p>
                  <span>*</span>
                  {t('dday.bingo.lbl.info')}
                </p>
                <h2></h2>
              </div>
            </div>
          </div>
        </LayoutBodyComponent>
      </div>
    </Fragment>
  );
};

export default Bingo;
