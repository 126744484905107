import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../redux/Store';
import { useTranslation } from 'react-i18next';

import {
  postGetLayersTotalByCity,
  postListLawyersByCity,
} from '../../../redux/lawyers/lawyersAction';
import { ROLE } from '../../../utils/utils';

import TableComponent, {
  HEADER,
  HEADER_TYPE,
  FIELD_TYPE,
} from '../../../components/Table';
import PaginationRecords from '../../../components/PaginationRecords';
import ModalDeleteLawyer from './deleteLawyer';
import ModalAddLawyer from './addLawyer';
import LoadingModal from '../../../dialogs/loadingModal';
import CodeGenerationDialog from './codeGenerationDialog';

const List: React.FunctionComponent = () => {
  const lawyers: any = useSelector(
    (globalState: RootStore) => globalState.lawyers.lawyers,
  );
  const page: any = useSelector(
    (globalState: RootStore) => globalState.lawyers.page,
  );
  const loading = useSelector(
    (globalState: RootStore) => globalState.lawyers.loading,
  );
  const totalLawyers: any = useSelector(
    (globalState: RootStore) => globalState.lawyers.totalLawyers,
  );
  const totalPage: any = useSelector(
    (globalState: RootStore) => globalState.lawyers.totalPage,
  );
  const records: any = useSelector(
    (globalState: RootStore) => globalState.lawyers.records,
  );
  const sort: any = useSelector(
    (globalState: RootStore) => globalState.lawyers.sort,
  );
  const search: any = useSelector(
    (globalState: RootStore) => globalState.lawyers.search,
  );

  let defaultLawyer: any;

  const [tableData, setTableData] = useState(Array);
  const [fieldOrder, setFieldOrder] = useState(sort[1] ? sort[1] : 'asc');
  // const [fieldName, setFieldName] = useState(sort[0] ? sort[0] : 'firstName');
  const [municipalityId, setMunicipalityId] = useState(String);
  const [modalShowAdd, setModalShowAdd] = useState(false);
  const [modalShowDelete, setModalShowDelete] = useState(false);
  const [edit, setEdit] = useState(Object);
  const [toDelete, setToDelete] = useState(Array);
  const [toResetPin, setToResetPin] = useState('');
  const [modalShowCodeGeneration, setModalShowCodeGeneration] = useState(false);

  const citySelected = useSelector((globalState: RootStore) => {
    return globalState.core.globalMunicipalitySelected;
  });

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const headerLawyers: any = {
    totalTeamData: totalLawyers || 0,
    labelPlural: t('electoral.lawyers.lbl.pluralLawyer'),

    secondButtonAppear: true,
    isSecondButtonDisabled: false,
    fun: () => {
      setEdit(defaultLawyer);
      setModalShowAdd(() => !modalShowAdd);
    },
  };

  const getMainTitle = () => {
    if (edit === undefined) {
      return t('electoral.lawyers.lbl.addLawyer');
    } else {
      return t('electoral.lawyers.lbl.editLawyer');
    }
  };

  const openEdit = (_id: any) => {
    const lawyer = lawyers?.find((value: any) => value._id === _id);
    if (lawyer) {
      setEdit(lawyer);
      setModalShowAdd(true);
    }
  };

  const openDelete = (deleteArray: any) => {
    setToDelete(deleteArray);
    setModalShowDelete(true);
  };

  const getPin = (_id: string) => {
    setToResetPin(_id);
    setModalShowCodeGeneration(true);
  };

  const transformData = () => {
    const newLawyers: any = [];
    const dataArray: any = lawyers;
    dataArray.forEach((lawyer: any) => {
      const newLawyer = {
        _id: lawyer._id,
        nameMap: {
          name2: `${lawyer.firstName} ${lawyer.lastName}`,
          isLeader: lawyer.role === ROLE.LAWYER ? false : true,
        },
        email: lawyer.email ? lawyer.email : '-',
        cellphone: lawyer.cellphone,
        activeProblems: 0,
        solvedProblems: 0,
        role: lawyer.role,
      };
      newLawyers.push(newLawyer);
    });

    setTableData(newLawyers);
  };

  const searchByValue = (reset = false) => {
    const params = buildParamSearchLawyersByCity(
      search,
      records ? records : 10,
      fieldOrder,
      !reset ? page : 1,
    );
    dispatch(postListLawyersByCity(params));
    dispatch(postGetLayersTotalByCity(params));
  };

  const buildParamSearchLawyersByCity = (
    search: string,
    recordsNum: number,
    order: string,
    pageNum: number,
  ) => {
    let requestObject;
    requestObject = {
      municipalityId,
      search: search.length >= 3 ? search : '',
      records: recordsNum,
      order,
      page: pageNum,
    };
    return requestObject;
  };

  useEffect(() => {
    if (lawyers) {
      transformData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lawyers]);

  useEffect(() => {
    if (citySelected) {
      setMunicipalityId(citySelected._id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [citySelected]);

  useEffect(() => {
    if (municipalityId) {
      searchByValue();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    if (municipalityId) {
      searchByValue();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldOrder]);

  useEffect(() => {
    if (municipalityId) {
      searchByValue();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [municipalityId]);

  return (
    <Fragment>
      <div className="lawyers-electoral-list">
        <div className="lawyers-electoral-container">
          <TableComponent
            showActions={true}
            selectedRows={toDelete}
            selectRows={setToDelete}
            selectMessage={true}
            header={headerLawyers}
            headerType={HEADER_TYPE.LAWYERS}
            headers={[
              {
                type: HEADER.FILTER,
                name: t('electoral.lawyers.lbl.name'),
                fun: () => {
                  const newOrder = fieldOrder === 'asc' ? 'desc' : 'asc';
                  setFieldOrder(newOrder);
                },
              },
              {
                type: HEADER.TEXT,
                name: t('electoral.lawyers.lbl.email'),
              },
              {
                type: HEADER.TEXT,
                name: t('electoral.lawyers.lbl.cellphone'),
              },
              {
                type: HEADER.TEXT,
                name: t('electoral.lawyers.lbl.activeProblems'),
              },
              {
                type: HEADER.TEXT,
                name: t('electoral.lawyers.lbl.solvedProblems'),
              },
              {
                type: HEADER.TEXT,
                name: t('territorial.volunteer.table.pin'),
              },
            ]}
            fieldTypes={[
              { type: FIELD_TYPE.IMAGE_TEXT_TWO_INITIALS_LAWYERS },
              { type: FIELD_TYPE.TEXT },
              { type: FIELD_TYPE.TEXT },
              { type: FIELD_TYPE.TEXT },
              { type: FIELD_TYPE.TEXT },
              {
                type: FIELD_TYPE.BUTTON_PIN,
                fun: (value: any) => {
                  getPin(value);
                },
              },
            ]}
            toEdit={(value: any) => {
              openEdit(value);
            }}
            toDelete={(value: any) => {
              openDelete(value);
            }}
            search={() => {}}
            regExp={undefined}
            data={tableData}
            nameOfData={[
              'nameMap',
              'email',
              'cellphone',
              'activeProblems',
              'solvedProblems',
              'role',
            ]}
            classBody={[
              'text-align-left selector-cell full-name-container',
              'text-align-left email-container',
              'text-align-left cellphone-container',
              'text-align-center active-problems-container',
              'text-align-center solver-problems-container',
              'text-align-center pin-container',
            ]}
          ></TableComponent>
          <PaginationRecords
            totalPages={totalPage}
            value={page}
            records={records}
            onChange={(pagina: string, record: string) => {
              const rec = record === '' ? '10' : record;
              let pag;
              if (records !== record) {
                pag = '1';
              } else {
                pag = pagina === '' || pagina === '0' ? '1' : pagina;
              }
              const params = buildParamSearchLawyersByCity(
                search,
                +rec,
                fieldOrder,
                +pag,
              );
              dispatch(postListLawyersByCity(params));
              dispatch(postGetLayersTotalByCity(params));
            }}
          ></PaginationRecords>
        </div>
      </div>
      {modalShowAdd && (
        <ModalAddLawyer
          mainTitle={getMainTitle()}
          modalShow={modalShowAdd}
          successFun={() => {
            searchByValue();
          }}
          setModalShow={setModalShowAdd}
          edit={edit}
          type={ROLE.LAWYER}
        ></ModalAddLawyer>
      )}
      {modalShowDelete && (
        <ModalDeleteLawyer
          modalShow={modalShowDelete}
          successFun={() => {
            searchByValue();
            setToDelete([]);
          }}
          toDelete={toDelete}
          lawyers={lawyers}
          setModalShow={setModalShowDelete}
        ></ModalDeleteLawyer>
      )}

      {loading && (
        <LoadingModal show={loading} onHide={() => {}}></LoadingModal>
      )}
      {modalShowCodeGeneration && (
        <CodeGenerationDialog
          setModalShow={setModalShowCodeGeneration}
          modalShow={modalShowCodeGeneration}
          userId={toResetPin}
        ></CodeGenerationDialog>
      )}
    </Fragment>
  );
};

export default List;
