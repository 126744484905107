import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Image from 'react-bootstrap/Image';
import GioobyLogo from '../../assets/logo/logo_giooby.png';
import './style.scss';
import Dropdown from 'react-bootstrap/Dropdown';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { SET_UNAUTHENTICATED } from '../../redux/User/userTypes';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import {
  SUPER_CAMPAIGNS,
  SUPER_SHARED,
  SUPER_SHARED_PARTIES,
  SUPER_USERS,
  SUPER_SHARED_COUNTRIES,
  SUPER_SHARED_USERS,
  SUPER_SHARED_GENERAL,
} from '../../utils/paths';
import { RootStore } from '../../redux/Store';

const MenuSComponent: React.FunctionComponent = () => {
  // tslint:disable-next-line: prefer-const
  let location = useLocation();

  const userData = useSelector((globalState: RootStore) => {
    return globalState.core.userData;
  });
  const dispatch = useDispatch();
  const redirect = useHistory();

  return (
    <div className="layout-menu-s">
      <div className="logo-s">
        <Image src={GioobyLogo} fluid />
      </div>
      <div className="menu-options-s">
        <div className="menu-list">
          <NavLink to={SUPER_CAMPAIGNS}>
            <div className="menu-item">
              <p>Campaigns</p>
            </div>
          </NavLink>
          <Dropdown
            as={ButtonGroup}
            alignRight
            className={
              location.pathname.includes(SUPER_SHARED)
                ? 'profile-dropdown active'
                : 'profile-dropdown'
            }
          >
            <Dropdown.Toggle id="dropdown-profile">
              <p>Shared</p>
            </Dropdown.Toggle>
            <Dropdown.Menu className="profile-dropdown-body">
              <Dropdown.Header
                className="dropdown-item-bold"
                onClick={() => {
                  redirect.push(SUPER_SHARED_PARTIES);
                }}
              >
                <p>Parties</p>
              </Dropdown.Header>
              <Dropdown.Header
                className="dropdown-item-bold"
                onClick={() => {
                  redirect.push(SUPER_SHARED_COUNTRIES);
                }}
              >
                <p>Countries</p>
              </Dropdown.Header>
              <Dropdown.Header
                className="dropdown-item-bold"
                onClick={() => {
                  redirect.push(SUPER_SHARED_GENERAL);
                }}
              >
                <p>General Settings</p>
              </Dropdown.Header>
            </Dropdown.Menu>
          </Dropdown>

          <NavLink to={SUPER_USERS}>
            <div className="menu-item">
              <p>Users</p>
            </div>
          </NavLink>
        </div>
        {/* <div className="add-item">
          <AddActionIcon></AddActionIcon>
        </div> */}
      </div>
      <div className="profile-s">
        <Dropdown as={ButtonGroup} alignRight className="profile-dropdown">
          <Dropdown.Toggle id="dropdown-profile">
            <div className="box-profile">
              <div className="user-box-profile">
                <div className="user-icon">
                  <div>
                    {(userData?.firstName?.slice(0, 1) || '') +
                      '' +
                      (userData?.lastName?.slice(0, 1) || '')}
                  </div>
                </div>
                <div className="profile-data">
                  <div className="profile-name">
                    {(userData?.firstName || '') +
                      '-' +
                      (userData?.lastName || '')}
                  </div>
                  <div className="profile-role">Super Admin</div>
                </div>
              </div>
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu className="profile-dropdown-body">
            {/* <Dropdown.Divider /> */}
            <Dropdown.Header
              className="dropdown-item-bold"
              onClick={() => {
                localStorage.removeItem('token');
                window.location.href = '/login';
                dispatch({
                  type: SET_UNAUTHENTICATED,
                });
              }}
            >
              Salir
            </Dropdown.Header>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};

export default MenuSComponent;
