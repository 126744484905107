import React, { useEffect, Fragment, FunctionComponent, useState } from 'react';
import Table from 'react-bootstrap/Table';
import './style.scss';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Col from 'react-bootstrap/Col';
import Figure from 'react-bootstrap/Figure';
import { BsSearch, BsThreeDots } from 'react-icons/bs';
import { BiReset } from 'react-icons/bi';
import Row from 'react-bootstrap/Row';
import Switch from 'react-switch';
import { GoCheck } from 'react-icons/go';
import moment from 'moment';
import {
  EditIcon,
  Trash,
  CorrectIcon,
  OrderIcon,
  FacebookIcon,
  InstagramIcon,
  TiktokIcon,
  WhatsappIcon,
  ActiveIcon,
  InactiveIcon,
  AddActionIcon,
  ResetPinIcon,
  PDFExportIcon,
} from '../assets/svg/index';
import { RiVipCrownFill } from 'react-icons/ri';
import { DownloadIcon } from '../assets/svg/index';
import { FaTwitter } from 'react-icons/fa';
import { DiOpera } from 'react-icons/di';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import Dropdown from 'react-bootstrap/Dropdown';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { Team, RCRG } from '../redux/RgRc/RgRcTypes';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'react-bootstrap';
import { ROLE, INCIDENCE_TYPE } from '../utils/utils';
import GlobalStatus from '../pages/votersTerritorial/components/globalStatus';
import PopupComponent from './sub-component/popupComponent';

export enum FIELD_TYPE {
  TEXT,
  TEXT_SYNC,
  INCIDENCE_STATUS,
  INCIDENCE_FINISH,
  TEXT_TEAMS,
  TEXT_DUPLICATES,
  TEXT_DUPLICATES_VOTERS,
  TEXT_ARRAY,
  IMAGE_TEXT,
  IMAGE_TEXT_TWO_INITIALS,
  IMAGE_TEXT_TWO_INITIALS_CAPTURISTS,
  IMAGE_TEXT_TWO_INITIALS_LAWYERS,
  IMAGE_FIELD,
  BUTTON_DOWNLOAD_PDF,
  BUTTON_ARRAY,
  BUTTON_ARRAY_VOTERS_BOX,
  BUTTON_TEXT,
  BUTTON_PIN,
  COUNTER_BOX,
  VOTER_BOX,
  PORCENTAJE,
  COUNT_LINK,
  OBJECT_ARRAY,
  SOCIAL_BAR,
  SOCIAL_NAME_ICON,
  WHATSAPP,
  VOTER_STATUS_TEXT,
  VOTER_INE_STATUS_TEXT,
  VOTER_VISITS_HISTORY,
  OBJECT_TEXT,
  TEXT_LINK,
  TEXT_RGRC,
  TEXT_LINK_DUPLICATES,
  TEXT_LINK_TEAMS,
  TEXTBOX_LINK,
  PERCENTAJE_PROGRESS,
  TEXT_BOLD,
  ROLE_ICON,
  USER_STATUS_INFORMATION,
  SWITCH,
}

export enum SOCIAL_NETWORK {
  UNDEFINED,
  FACEBOOK,
  INSTAGRAM,
  TIKTOK,
  TWITTER,
  ONLYFANS,
}
// header of all table
export enum HEADER_TYPE {
  NORMAL,
  CAMPAIGN_HEADER,
  TEAM,
  TERRITORIAL_TEAM,
  TERRITORIAL_TEAM_NEW,
  USERS_SETTINGS,
  HIDDEN,
  LAWYERS,
}
// header of each column table
export enum HEADER {
  TEXT,
  FILTER,
}

export interface HeaderNormal {
  title: string;
}

export interface HeaderTeamRg {
  _id: string;
  title: string;
  rg?: RCRG;
  funText: string;
  fun: any;
  team: Team;
  editFun: any;
  deleteFun: any;
}

export type header = HeaderNormal | HeaderTeamRg;

interface TableProps {
  header: header;
  headerType: HEADER_TYPE;
  headers: any;
  data: any;
  nameOfData: any;
  classBody: any;
  toEdit: any;
  toAssign?: any;
  toDelete: any;
  search: any;
  regExp?: any;
  fieldTypes: any;
  selectedRows?: any;
  selectRows?: any;
  showActions?: Boolean;
  showAssign?: Boolean;
  onTrAction?: boolean;
  onTrFun?: any;
  loading?: boolean;
  selectMessage?: boolean;
}

const TableComponent: FunctionComponent<TableProps> = ({
  loading = false,
  header,
  headerType,
  headers,
  data,
  nameOfData,
  classBody,
  toEdit,
  toAssign,
  toDelete,
  search,
  regExp,
  fieldTypes,
  selectedRows,
  selectRows,
  showActions,
  showAssign,
  onTrAction,
  onTrFun,
  selectMessage,
}: any) => {
  const { t } = useTranslation();
  //const [shiftOn, setShiftOn] = useState(false);

  const selectRow = (id: any) => {
    let listSelectedRows = [] as any;

    //if (shiftOn) {
    listSelectedRows = [...selectedRows];
    const index = listSelectedRows.indexOf(id);

    if (index !== -1) {
      listSelectedRows.splice(index, 1);
    } else {
      listSelectedRows.push(id);
    }
    /*} else {
      listSelectedRows.push(id);
    }*/
    Object.assign(selectedRows, listSelectedRows);
    selectRows(listSelectedRows);
  };

  useEffect(() => {}, []);

  /*useEffect(() => {
    document.addEventListener('keydown', (e) => {
      //e.preventDefault()
      if (e.key === 'Control') {
        setShiftOn(true);
      }
    });
  }, []);

  useEffect(() => {
    document.addEventListener('keyup', (e) => {
      //e.preventDefault()
      if (e.key === 'Control') {
        setShiftOn(false);
      }
    });
  }, []);*/

  const openDelete = (id?: any) => {
    if (id !== undefined) toDelete([id]);
    else {
      toDelete([...selectedRows]);
    }
  };

  const getHeader = () => {
    switch (headerType) {
      case HEADER_TYPE.NORMAL:
        return (
          <Container fluid className="header-table">
            <Row noGutters>
              <Col lg={4} md={5} xs={6}>
                <h2>{header.title}</h2>
              </Col>
              <Col lg={3} md={4} xs={5} className="search-action-box">
                {selectedRows.length > 0 ? (
                  <div className="delete-btn" onClick={() => openDelete()}>
                    <Trash></Trash>
                  </div>
                ) : (
                  <div className="delete-btn"></div>
                )}
              </Col>
            </Row>
          </Container>
        );
      case HEADER_TYPE.CAMPAIGN_HEADER:
        return (
          <Container fluid className="header-campaign-table">
            <div className="title">
              <h2>{header.title}</h2>
              <AddActionIcon onClick={header.fun}></AddActionIcon>
            </div>

            <div className="delete-action-box">
              {selectedRows.length > 0 ? (
                <>
                  <p>{selectedRows.length} Seleccionado</p>
                  <div className="delete-btn" onClick={() => openDelete()}>
                    <Trash></Trash>
                  </div>
                </>
              ) : (
                <div className="delete-btn"></div>
              )}
            </div>
          </Container>
        );
      case HEADER_TYPE.TEAM:
        return (
          <Container fluid className="header-table-team">
            <Row noGutters>
              <Col className="header-title-box">
                <div>
                  <h2>
                    RG:{' '}
                    {header.rg
                      ? ' ' + header.rg?.firstName + ' ' + header.rg?.lastName
                      : ''}
                  </h2>
                  {dropdown(header)}
                </div>
                {/* <div>
                  {header.rg
                    ? " " + header.rg?.firstName + " " + header.rg?.lastName
                    : ""}
                  {dropdown(header)}
                </div> */}
              </Col>
              <Col lg={6} md={7} xs={8} className="search-action-box">
                <Button variant="primary" onClick={header.fun}>
                  {header.funText}
                </Button>
                {selectedRows.length > 0 ? (
                  <div className="delete-btn" onClick={() => openDelete()}>
                    <Trash></Trash>
                  </div>
                ) : (
                  <div className="delete-btn"></div>
                )}
                <Form inline className="search-field">
                  <FormControl
                    type="text"
                    placeholder="Search"
                    className="mr-sm-2"
                    onChange={(data) => {
                      if (data.target.value === '') search(data.target.value);
                      if (
                        regExp === undefined ||
                        regExp.test(data.target.value.trim())
                      )
                        search(data.target.value);
                    }}
                  />
                  <div className="icon">
                    <BsSearch />
                  </div>
                </Form>
              </Col>
            </Row>
          </Container>
        );
      case HEADER_TYPE.LAWYERS:
        return (
          <Container fluid className="header-table-team">
            <Row noGutters>
              <div className="header-main">
                <div className="header-main-title">
                  <h1>
                    {header.labelPlural + ' [' + header.totalTeamData + ']'}
                  </h1>
                </div>
              </div>
              <div className="search-action-box">
                {selectedRows.length > 0 ? (
                  <div className="delete-btn" onClick={() => openDelete()}>
                    <Button
                      variant="outline-dark"
                      className="delete-item-territorial-team"
                    >
                      <Trash></Trash>
                    </Button>
                  </div>
                ) : (
                  <div className="delete-btn"></div>
                )}
                {header.secondButtonAppear && (
                  <Button
                    disabled={header.isSecondButtonDisabled}
                    variant="primary"
                    onClick={header.fun}
                    className="search-add-button"
                  >
                    {t('settings.trainings.form.add')}
                  </Button>
                )}
              </div>
            </Row>
          </Container>
        );
      case HEADER_TYPE.TERRITORIAL_TEAM_NEW:
        return (
          <Container fluid className="header-table-team">
            <Row noGutters>
              <div className="header-main">
                <div className="header-main-title">
                  <h1>
                    {header.labelPlural + ' [' + header.totalTeamData + ']'}
                  </h1>
                </div>
              </div>
              <div className="search-action-box">
                {selectedRows.length > 0 ? (
                  <div className="delete-btn" onClick={() => openDelete()}>
                    <Button
                      variant="outline-dark"
                      className="delete-item-territorial-team"
                    >
                      <Trash></Trash>
                    </Button>
                  </div>
                ) : (
                  <div className="delete-btn"></div>
                )}
                {header.downloadButtonAppear && (
                  <Row className="row-buttons">
                    <Button
                      className="template-button"
                      variant="outline-dark"
                      onClick={header.downloadFun}
                    >
                      <DownloadIcon></DownloadIcon>
                      {t('general.btn.template')}
                    </Button>
                  </Row>
                )}
                {header.tipButtonAppear && (
                  <Row className="row-buttons">
                    <Button
                      disabled={header.isTipButtonDisabled}
                      variant="outline-dark"
                      onClick={header.tipFun}
                      className="template-button"
                    >
                      {t('general.btn.tip')}
                    </Button>
                  </Row>
                )}
                {header.uploadButtonAppear && (
                  <Button
                    disabled={header.isUploadButtonDisabled}
                    variant="primary"
                    onClick={header.uploadFun}
                    className="upload-xls-button"
                  >
                    {t('general.btn.uploadXls')}
                  </Button>
                )}
                {header.firstButtonAppear && (
                  <Button
                    variant="primary"
                    disabled={header.isFirstButtonDisabled}
                    onClick={header.funAdmin}
                    className="search-transfer-button"
                  >
                    {header.funTextAdmin}
                  </Button>
                )}
                {header.secondButtonAppear && (
                  <Button
                    disabled={header.isSecondButtonDisabled}
                    variant="primary"
                    onClick={header.fun}
                    className="search-add-button"
                  >
                    {t('settings.trainings.form.add')}
                  </Button>
                )}
              </div>
            </Row>
          </Container>
        );
      case HEADER_TYPE.TERRITORIAL_TEAM:
        return (
          <Container fluid className="header-table-team">
            <Row noGutters>
              <div className="header-main">
                <div className="header-main-title">
                  <span>
                    <strong>
                      {t('territorial.volunteer.table.organizer')}:{' '}
                    </strong>
                  </span>
                  <span style={{ marginLeft: '0.5rem' }}>
                    {header.coordinatorName}
                  </span>
                  {header.totalTeamDataGlobal > 0 ? (
                    <div>{dropdown(header)}</div>
                  ) : (
                    <span className="">{t('select.lbl.none')}</span>
                  )}
                </div>
                <div className="header-main-subtitle">
                  <span>N° {t('territorial.volunteer.lbl.1')}:</span>{' '}
                  {header.totalTeamData}
                </div>
              </div>
              <Col lg={6} md={7} xs={8} className="search-action-box">
                <Button
                  variant="primary"
                  disabled={header.isFirstButtonDisabled}
                  onClick={header.funAdmin}
                >
                  {header.funTextAdmin}
                </Button>
                <Button
                  disabled={header.isSecondButtonDisabled}
                  variant="primary"
                  onClick={header.fun}
                >
                  {header.funText}
                </Button>
                {selectedRows.length > 0 ? (
                  <div className="delete-btn" onClick={() => openDelete()}>
                    <Button
                      variant="outline-dark"
                      className="delete-item-territorial-team"
                    >
                      <Trash></Trash>
                      <span>{t('settings.trainings.form.delete')}</span>
                    </Button>
                  </div>
                ) : (
                  <div className="delete-btn"></div>
                )}
                <Form inline className="search-field">
                  <FormControl
                    type="text"
                    placeholder={t('placeholder.search')}
                    className="mr-sm-2"
                    onChange={(data) => {
                      if (data.target.value === '') search(data.target.value);
                      if (
                        regExp === undefined ||
                        regExp.test(data.target.value.trim())
                      )
                        search(data.target.value);
                    }}
                  />
                  <div className="icon">
                    <BsSearch />
                  </div>
                </Form>
              </Col>
            </Row>
          </Container>
        );

      case HEADER_TYPE.USERS_SETTINGS:
        return (
          <Container fluid className="header-table-team">
            <Row noGutters>
              <div className="header-main">
                <div className="header-main-title">
                  <h1>
                    {`${header.totalTeamData} ${
                      header.totalTeamData === 1
                        ? header.labelSingular
                        : header.labelPlural
                    }`}
                  </h1>
                </div>
              </div>
              <div className="search-action-box">
                {selectedRows.length > 0 ? (
                  <div className="delete-btn" onClick={() => openDelete()}>
                    <Button
                      variant="outline-dark"
                      className="delete-item-territorial-team"
                    >
                      <Trash></Trash>
                    </Button>
                  </div>
                ) : (
                  <div className="delete-btn"></div>
                )}
                {header.firstButtonAppear && (
                  <Button
                    variant="primary"
                    disabled={header.isFirstButtonDisabled}
                    onClick={header.funAdmin}
                    className="search-transfer-button button-noMargin"
                  >
                    {header.funTextAdmin}
                  </Button>
                )}
                {header.secondButtonAppear && (
                  <Button
                    disabled={header.isSecondButtonDisabled}
                    variant="primary"
                    onClick={header.fun}
                    className="search-add-button"
                  >
                    {header.funText}
                  </Button>
                )}
              </div>
            </Row>
          </Container>
        );

      default:
        return null;
    }
  };

  const ListHeaders = (headers: any, classBody: any) => {
    // eslint-disable-next-line
    return headers.map((header: any, index: number) => {
      switch (header.type) {
        case HEADER.TEXT:
          return (
            <th className={classBody[index]} key={index}>
              {header.name}
            </th>
          );
        case HEADER.FILTER:
          return (
            <th className={classBody[index]} key={index}>
              {header.name}{' '}
              <OrderIcon
                className="filter-action-icon"
                onClick={() => {
                  header.fun();
                }}
              ></OrderIcon>
            </th>
          );
        default:
          break;
      }
    });
  };

  const ListBodyData = (
    bodyData: any,
    nameOfData: any,
    classBody: any,
    selectedRows: any,
    selectRow: any,
    toEdit: any,
    toAssign: any,
    openDelete: any,
    fieldTypes: any,
    showActions: boolean,
    showAssign: boolean,
    onTrAction: boolean,
    onTrFun: any,
  ) => {
    return bodyData.map((e: any) => {
      return (
        <tr
          key={e._id}
          className={selectedRows.indexOf(e._id) !== -1 ? 'selected' : ''}
          onClick={(ev) => {
            if (onTrAction) {
              onTrFun(e._id);
            }
          }}
        >
          {cellData(
            e,
            nameOfData,
            classBody,
            selectedRows,
            selectRow,
            fieldTypes,
            showActions,
            showAssign,
          )}
          {showActions === undefined ||
            (showActions && cellActions(e, classBody, toEdit, openDelete))}
          {showAssign === undefined ||
            (showAssign && cellAssign(e, classBody, toAssign))}
        </tr>
      );
    });
  };

  const cellData = (
    data: any,
    nameOfData: any,
    classBody: any,
    selectedRows: any,
    selectRow: any,
    fieldTypes: any,
    showActions: boolean,
    showAssign: boolean,
  ) => {
    return nameOfData.map((e: any, index: number) => {
      return (
        <th
          key={e}
          className={classBody[index]}
          onClick={() => {
            /*if (
              fieldTypes[index].type !== FIELD_TYPE.SWITCH &&
              fieldTypes[index].type !== FIELD_TYPE.BUTTON_TEXT &&
              fieldTypes[index].type !== FIELD_TYPE.BUTTON_ARRAY &&
              fieldTypes[index].type !== FIELD_TYPE.COUNT_LINK &&
              fieldTypes[index].type !== FIELD_TYPE.BUTTON_ARRAY_VOTERS_BOX &&
              fieldTypes[index].type !== FIELD_TYPE.USER_STATUS_INFORMATION &&
              (showActions === undefined || showActions) &&
              (showAssign === undefined || showAssign)
            )
              selectRow(data._id);*/
          }}
        >
          <div>
            {index === 0 && (
              <div
                className={
                  selectedRows.indexOf(data._id) !== -1
                    ? 'checkbox active'
                    : 'checkbox'
                }
                onClick={() => {
                  selectRow(data._id);
                }}
              >
                <GoCheck></GoCheck>
              </div>
            )}
            {
              <Field
                e={e}
                data={data}
                fieldType={fieldTypes[index]}
                actionFun={() => {}}
              ></Field>
            }
          </div>
        </th>
      );
    });
  };

  const cellActions = (
    data: any,
    classBody: any,
    toEdit: any,
    openDelete: any,
  ) => {
    return (
      <th className={'text-align-right action-buttons'}>
        <div>
          <EditIcon
            onClick={(event: any) => {
              event?.preventDefault();
              toEdit(data._id);
            }}
          ></EditIcon>
          <Trash
            onClick={(event: any) => {
              event?.preventDefault();
              openDelete(data._id);
            }}
          ></Trash>
        </div>
      </th>
    );
  };

  const cellAssign = (data: any, classBody: any, toAssign: any) => {
    return (
      <th className={'text-align-right action-buttons'}>
        <div>
          <Button
            variant="primary"
            onClick={(event: any) => {
              event?.preventDefault();
              toAssign(data._id);
            }}
          >
            {t('general.table.btn.assign')}
          </Button>
        </div>
      </th>
    );
  };

  const getRegisteredOverlay = () => {
    let content;

    content = (
      <Popover
        id={'exclamation-popover'}
        placement={'bottom'}
        className="pop-over-exclamation"
      >
        <Popover.Content bsPrefix="content">
          <Fragment>
            <span>{t('general.table.label.multipleSelect')}</span>
          </Fragment>
        </Popover.Content>
      </Popover>
    );

    return content;
  };

  return (
    <div className="table-box">
      {getHeader()}
      <div className="table-container">
        <Table responsive>
          <thead>
            {/*selectMessage && (
              <OverlayTrigger
                key={'exclamation-icon'}
                placement={'top-start'}
                trigger="click"
                overlay={getRegisteredOverlay()}
              >
                <div className="circle-exclamation">{'!'}</div>
              </OverlayTrigger>
            )*/}
            <tr>{ListHeaders(headers, classBody)}</tr>
          </thead>
          {loading ? (
            <div className="spinner">
              <span>
                <Spinner animation="grow" />
              </span>
            </div>
          ) : (
            <tbody>
              {ListBodyData(
                data,
                nameOfData,
                classBody,
                selectedRows,
                selectRow,
                toEdit,
                toAssign,
                openDelete,
                fieldTypes,
                showActions,
                showAssign,
                onTrAction,
                onTrFun,
              )}
            </tbody>
          )}
        </Table>
      </div>
    </div>
  );
};

interface FieldProps {
  e: any;
  data: any;
  fieldType: any;
  actionFun?: any;
}

const Field: FunctionComponent<FieldProps> = ({
  e,
  data,
  fieldType,
  actionFun,
}: any) => {
  const { t } = useTranslation();
  switch (fieldType.type) {
    case FIELD_TYPE.SWITCH:
      return (
        <Switch
          onChange={() => {
            fieldType.fun(data);
          }}
          checked={data[e] === false}
          height={20}
          width={40}
          onColor="#2fad74"
          // onHandleColor="#2693e6"
          handleDiameter={20}
          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
          activeBoxShadow="0px 0px 1px 5px rgba(0, 0, 0, 0.2)"
          className="react-switch"
          id="material-switch"
        ></Switch>
      );
    case FIELD_TYPE.OBJECT_TEXT:
      if (data[e] !== null)
        return (
          <div
            className="text-bold"
            onClick={() => {
              fieldType.fun(data);
            }}
          >
            {data[e].name}
          </div>
        );
      else
        return (
          <div>
            <Button
              variant="primary"
              onClick={() => {
                fieldType.fun(data);
              }}
            >
              {t('general.table.btn.assign')}
            </Button>
          </div>
        );
    case FIELD_TYPE.TEXT:
      return <div>{data[e]}</div>;
    case FIELD_TYPE.TEXT_SYNC:
      let now = moment(new Date());
      let end = moment(data[e]);
      let duration = moment.duration(now.diff(end));
      let days = duration.asDays();
      return (
        <>
          {data[e] === '' && <div>{t('select.lbl.none')}</div>}
          {data[e] && days > 4 && (
            <div className="sync-red">{moment(data[e]).format('D/MM/YY')}</div>
          )}
          {data[e] && days > 2 && days < 5 && (
            <div className="sync-orange">
              {moment(data[e]).format('D/MM/YY')}
            </div>
          )}
          {data[e] && days < 3 && (
            <div className="sync-green">
              {moment(data[e]).format('D/MM/YY')}
            </div>
          )}
        </>
      );
    case FIELD_TYPE.INCIDENCE_STATUS:
      return (
        <>
          {data[e] === INCIDENCE_TYPE.OPEN && (
            <div className="sync-red">
              <p>{data[e]}</p>
            </div>
          )}
          {data[e] === INCIDENCE_TYPE.ASSIGNED && (
            <div className="sync-orange">
              <p>{data[e]}</p>
            </div>
          )}
          {data[e] === INCIDENCE_TYPE.COMPLETED && (
            <div className="sync-green">
              <p>{data[e]}</p>
            </div>
          )}
        </>
      );
    case FIELD_TYPE.INCIDENCE_FINISH:
      if (data.status === INCIDENCE_TYPE.ASSIGNED) {
        return (
          <div>
            <Button
              variant="primary"
              onClick={() => {
                fieldType.fun(data._id);
              }}
            >
              {t('general.table.btn.finish')}
            </Button>
          </div>
        );
      } else {
        return (
          <div className="sync-green">
            <p>{data.status}</p>
          </div>
        );
      }
    case FIELD_TYPE.TEXT_TEAMS:
      let fillerProps = { backgroundColor: data.colorbase };
      return (
        <div className="teams-area-oval" style={fillerProps}>
          {data[e]}
        </div>
      );

    case FIELD_TYPE.TEXT_DUPLICATES:
      return (
        <div className="text-duplicates">
          {data.duplicates && data.duplicates.length >= 1 ? (
            <PopupComponent
              id={data._id}
              contentText={
                <div className="duplicates-popup-message">
                  <span>
                    {t('territorial.voters.popup.lbl.duplicates.1')}
                    <strong>
                      {' '}
                      {data.duplicates.length + 1}{' '}
                      {t('territorial.voters.popup.lbl.duplicates.2')}
                    </strong>
                  </span>
                  <span> {t('territorial.voters.popup.lbl.duplicates.3')}</span>
                </div>
              }
            >
              <div className="duplicates-circle">
                {data.duplicates.length + 1}
              </div>
            </PopupComponent>
          ) : (
            <div className="empty-duplicates"></div>
          )}
          {data[e]}
        </div>
      );
    case FIELD_TYPE.TEXT_DUPLICATES_VOTERS:
      return (
        <div className="text-duplicates">
          {data.duplicates && data.duplicates.length >= 1 ? (
            <PopupComponent
              id={data._id}
              contentText={
                <div className="duplicates-popup-message duplicates-voter-message">
                  <strong>
                    {data.duplicates.length + 1}{' '}
                    {t('territorial.voters.popup.lbl.duplicates.2')}
                  </strong>
                </div>
              }
            >
              <div className="duplicates-circle">
                {data.duplicates.length + 1}
              </div>
            </PopupComponent>
          ) : (
            <div className="empty-duplicates"></div>
          )}
          {data[e]}
        </div>
      );
    case FIELD_TYPE.TEXT_LINK:
      return (
        <Button
          variant="primary"
          className={
            data.permission
              ? 'box-section-sublevel box-section-none'
              : 'box-section-sublevel'
          }
          onClick={() => {
            fieldType.fun(data._id, data.level);
          }}
        >
          {data.tableView === 'problems'
            ? t('general.table.btn.seeDetail')
            : t('general.table.btn.see') + ' (' + data.members + ')'}
        </Button>
      );
    case FIELD_TYPE.TEXT_LINK_DUPLICATES:
      return (
        <div>
          <Button
            variant="primary"
            className="width-ranger"
            // onClick={actionFun[e]}
          >
            {t('territorial.auditory.btn.check')}
          </Button>
        </div>
      );
    case FIELD_TYPE.TEXT_LINK_TEAMS:
      return (
        <Button
          variant="primary"
          className="box-section"
          onClick={() => {
            //fieldType.actionFun(data[e])
          }}
        >
          {data[e]}
        </Button>
      );
    case FIELD_TYPE.TEXT_RGRC:
      return (
        <div className="rgrc-body">
          <div className="rgrc-container">
            <div className="rgrc-title">
              <p>
                <strong>{t('electoral.polling.table.rg')}:</strong>
              </p>
            </div>
            <p>
              {data[e].rg._id
                ? data[e].rg.firstName + ' ' + data[e].rg.lastName
                : '-'}
            </p>
          </div>
          <div className="rgrc-container">
            <div className="rgrc-title">
              <p>
                <strong>{t('electoral.polling.table.rc')}:</strong>
              </p>
            </div>
            <p>
              {data[e].rc._id
                ? data[e].rc.firstName + ' ' + data[e].rc.lastName
                : '-'}
            </p>
          </div>
        </div>
      );
    case FIELD_TYPE.TEXTBOX_LINK:
      return (
        <Button
          variant="primary"
          className="box-section"
          onClick={() => {
            //fieldType.actionFun(data[e])
          }}
        >
          {`${data[e]}`}
        </Button>
      );
    case FIELD_TYPE.TEXT_BOLD:
      const { text = '', houseDone = 0 } = data[e];
      return (
        <div>
          <strong>{text}</strong> <span>({houseDone} Casas)</span>
        </div>
      );
    case FIELD_TYPE.PERCENTAJE_PROGRESS:
      return (
        <div className="box-percentaje">
          <div className="box-percentaje-value">{data[e]}%</div>
          <ProgressBar
            className="box-progress"
            variant="success"
            now={data[e]}
          />
        </div>
      );
    case FIELD_TYPE.TEXT_ARRAY:
      return <div>{getArrayText(data[e])}</div>;
    case FIELD_TYPE.OBJECT_ARRAY:
      return <div>{getArrayObject(data[e], 'name')}</div>;
    case FIELD_TYPE.COUNT_LINK:
      return (
        <Button
          variant="primary"
          className="box-counter"
          onClick={() => fieldType.fun(data[e])}
          // onClick={actionFun[e]}
        >
          {data[e].members.length}
        </Button>
      );
    case FIELD_TYPE.IMAGE_TEXT:
      return (
        <div>
          <span className="initials">{getInitials(data[e])}</span>
          {data[e]}
        </div>
      );
    case FIELD_TYPE.IMAGE_TEXT_TWO_INITIALS:
      return (
        <div>
          <span className="initials">{getFirstTwoInitials(data[e])}</span>
          {data[e]}
        </div>
      );
    case FIELD_TYPE.IMAGE_TEXT_TWO_INITIALS_CAPTURISTS:
      const { name, isCoordinator } = data[e];
      return (
        <div>
          <span className={`initials ${isCoordinator && 'background-green'}`}>
            {getFirstTwoInitials(name)}
          </span>
          {name}
        </div>
      );
    case FIELD_TYPE.IMAGE_TEXT_TWO_INITIALS_LAWYERS:
      const { name2, isLeader } = data[e];
      return (
        <div>
          <span className={'initials'}>{getFirstTwoInitials(name2)}</span>
          {name2}
          {isLeader && (
            <RiVipCrownFill className="lawyer-crown"></RiVipCrownFill>
          )}
        </div>
      );
    case FIELD_TYPE.BUTTON_ARRAY_VOTERS_BOX:
      return (
        <>
          {data?.votersBoxes._id && (
            <div>
              {data._id === data.votersBoxes.substituteId
                ? data.votersBoxes.number + ' (S)'
                : data.votersBoxes.number}
            </div>
          )}
          {!data?.votersBoxes._id && (
            <div>{t('general.table.btn.noneAssign')}</div>
          )}
        </>
      );
    case FIELD_TYPE.IMAGE_FIELD:
      return (
        <Image
          className="photo-id-fluid"
          src={data[e]}
          fluid
          onClick={() => {}}
        />
      );
    case FIELD_TYPE.BUTTON_DOWNLOAD_PDF:
      return (
        <div
          onClick={() => {
            fieldType.fun(data._id);
          }}
        >
          <PDFExportIcon></PDFExportIcon>
        </div>
      );
    case FIELD_TYPE.BUTTON_ARRAY:
      if (data[e]) return <div>{data[e]}</div>;
      else
        return (
          <div>
            <Button
              variant="primary"
              onClick={() => {
                fieldType.fun(data._id);
              }}
            >
              {t('general.table.btn.assign')}
            </Button>
          </div>
        );
    case FIELD_TYPE.BUTTON_TEXT:
      return (
        <Button
          variant="primary"
          onClick={() => {
            fieldType.fun(data._id);
          }}
        >
          {t('territorial.goals.btn.assign')}
        </Button>
      );
    case FIELD_TYPE.BUTTON_PIN:
      return (
        <div
          onClick={() => {
            fieldType.fun(data._id);
          }}
        >
          <ResetPinIcon></ResetPinIcon>
        </div>
      );
    case FIELD_TYPE.COUNTER_BOX:
      return (
        <Button
          variant="primary"
          className={data[e].length > 0 ? 'box-counter' : ''}
        >
          {data[e].length}
        </Button>
      );
    case FIELD_TYPE.VOTER_BOX:
      return (
        <>
          {data[e]._id && <div>{data[e].name}</div>}
          {!data[e]._id && <div>{t('general.table.btn.noneAssign')}</div>}
        </>
      );
    case FIELD_TYPE.PORCENTAJE:
      return (
        <div>
          {data[e] === 100 && <CorrectIcon></CorrectIcon>}
          {data[e]}%
        </div>
      );
    case FIELD_TYPE.SOCIAL_BAR:
      return (
        <div>
          <ProgressBar>
            {data[e] >= 1 && <ProgressBar variant="success" now={25} key={1} />}
            {data[e] >= 2 && <ProgressBar variant="info" now={25} key={2} />}
            {data[e] >= 3 && <ProgressBar variant="warning" now={25} key={3} />}
            {data[e] >= 4 && <ProgressBar variant="danger" now={25} key={4} />}
          </ProgressBar>
        </div>
      );
    case FIELD_TYPE.SOCIAL_NAME_ICON:
      return getSocialNetwork(data[e]);
    case FIELD_TYPE.ROLE_ICON:
      return getRoleIcon(data[e]);
    case FIELD_TYPE.VOTER_STATUS_TEXT:
      return (
        <Fragment>
          <GlobalStatus voter={data[e]} t={t}></GlobalStatus>
        </Fragment>
      );
    case FIELD_TYPE.VOTER_INE_STATUS_TEXT:
      return getIneStatus(data[e], t);
    case FIELD_TYPE.VOTER_VISITS_HISTORY:
      return (
        <Fragment>
          {data.globalStatus.registered === 2 ||
          data.globalStatus.captured === 2 ||
          data.globalStatus.audited === 2 ? (
            <p className="timeline-problem">
              {' '}
              {t(data.ineStatus.auditedProblem)}{' '}
            </p>
          ) : (
            <p className="timeline-problem">
              {' '}
              {t('territorial.voters.lbl.withoutVisits')}{' '}
            </p>
            //<TimeLine months={3} breakPoints={data[e]}></TimeLine>
          )}
        </Fragment>
      );
    case FIELD_TYPE.WHATSAPP:
      return (
        <Fragment>
          <Figure
            className="social-network-container"
            onClick={() => {
              console.log('pending whatsapp functionality');
              console.log(data[e]);
            }}
          >
            <WhatsappIcon className="social-network-icon"></WhatsappIcon>
          </Figure>
        </Fragment>
      );
    case FIELD_TYPE.USER_STATUS_INFORMATION:
      return (
        <Fragment>
          {!data[e] ? (
            <ActiveIcon
              onClick={() => {
                fieldType.actionFun(data);
              }}
            ></ActiveIcon>
          ) : (
            <InactiveIcon
              onClick={() => {
                fieldType.actionFun(data);
              }}
            ></InactiveIcon>
          )}
        </Fragment>
      );
    default:
      return null;
  }
};

const getInitials = (value: string) => {
  let result = '';
  const array = value.split(' ');
  array.forEach((e) => {
    result = result + e.slice(0, 1);
  });
  return result;
};

const getFirstTwoInitials = (value: string) => {
  let result = '';
  const array = value.split(' ');
  array.forEach((e, idx) => {
    if (idx < 2) {
      result = result + e.slice(0, 1);
    }
  });
  return result.toUpperCase();
};

const getArrayObject = (value: any[], valueName: string) => {
  let result = '';

  value.forEach((e) => {
    if (result === '') {
      result = e[valueName];
    } else {
      result = result + ', ' + e[valueName];
    }
  });
  return result;
};

const getArrayText = (value: string[]) => {
  let result = '';

  value.forEach((e) => {
    if (result === '') {
      result = e;
    } else {
      result = result + ', ' + e;
    }
  });
  return result;
};

const dropdown = (header: HeaderTeamRg) => {
  return (
    <Dropdown
      as={ButtonGroup}
      id={`dropdown-button-drop-down`}
      className="dropdown-options"
      style={{ height: '100%' }}
    >
      <Dropdown.Toggle
        id="dropdown-custom-marker"
        style={{
          margin: 0,
          padding: 0,
        }}
      >
        <BsThreeDots />
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-body">
        <Dropdown.Item
          eventKey="1"
          onClick={() => {
            header.deleteFun(header.rg);
          }}
        >
          Eliminar
        </Dropdown.Item>
        <Dropdown.Item
          eventKey="1"
          onClick={() => {
            header.editFun(header.rg);
          }}
        >
          Editar
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

const getRoleIcon = (role: any): any => {
  let roleImg;
  switch (role) {
    case ROLE.CAPTURIST_BOSS:
      roleImg = (
        <span role="img" aria-label="capmob" aria-labelledby="jv">
          👨🏻
        </span>
      );
      break;
    default:
      return <div></div>;
  }
  return roleImg;
};

const getSocialNetwork = (network: number): any => {
  let networkName = '';
  let networkImg;
  switch (Number(network)) {
    case SOCIAL_NETWORK.FACEBOOK:
      networkName = 'Facebook';
      networkImg = (
        <FacebookIcon className="social-network-icon"></FacebookIcon>
      );
      break;
    case SOCIAL_NETWORK.INSTAGRAM:
      networkName = 'Instagram';
      networkImg = (
        <InstagramIcon className="social-network-icon"></InstagramIcon>
      );
      break;
    case SOCIAL_NETWORK.TIKTOK:
      networkName = 'TikTok';
      networkImg = <TiktokIcon className="social-network-icon"></TiktokIcon>;
      break;
    case SOCIAL_NETWORK.TWITTER:
      networkName = 'Twitter';
      networkImg = <FaTwitter className="social-network-icon" />;
      break;
    case SOCIAL_NETWORK.ONLYFANS:
      networkName = 'Only Fans';
      networkImg = <DiOpera className="social-network-icon" />;
      break;
    default:
      break;
  }

  return (
    <Fragment>
      <Figure className="social-network-container">{networkImg}</Figure>
      <span className="social-network-name"> {networkName} </span>
    </Fragment>
  );
};

const getIneStatus = (voter: any, t: any) => {
  let status = t('territorial.voters.lbl.missingData');
  let bg = '';
  if (voter.nextVotingElections === null) {
    status = t('territorial.voters.lbl.none');
    bg = 'expired';
  } else {
    let ineExpiration = +voter.ineExpirationYear;
    let electionsDate = +voter.nextVotingElections;

    if (ineExpiration >= electionsDate) {
      status = t('territorial.voters.lbl.valid');
      bg = 'valid';
    } else {
      status = t('territorial.voters.lbl.expired');
      bg = 'expired';
    }
  }

  return (
    <div className={`ine-status-container ${bg}`}>
      <p>{status}</p>
    </div>
  );
};

export default TableComponent;
