import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../style.scss";
import StaticModal from "../../../dialogs/StaticModal";

interface ModalOnEditProps {
  modalShow: any;
  customMsg: any;
  successFun: any;
  cancelFun: any;
  closeText: any;
  confirmText: any;
  t: any;
}

export const ModalOnEdit: React.SFC<ModalOnEditProps> = ({
  modalShow,
  customMsg,
  successFun,
  cancelFun,
  closeText,
  confirmText,
  t,
}: any) => {
  const submitFun = () => {
    successFun();
  };
  const onHide = () => {
    cancelFun();
  };
  return (
    <StaticModal
      show={modalShow}
      title={t("settings.trainings.modal.notification.title")}
      closeText={closeText}
      confirmText={confirmText}
      onHide={onHide}
      submitFun={submitFun}
      validBtn={false}
      size="sm"
      showConfirmBtn={true}
      showCancelBtn={true}
    >
      <Row noGutters className="modal-text-row">
        <Col xs={12} className="description-col">
          {customMsg}
        </Col>
      </Row>
    </StaticModal>
  );
};

export default ModalOnEdit;
