import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootStore } from '../../../redux/Store';
import '../style.scss';
import * as netWorking from '../../../redux/netWorking';
import { ROLE } from '../../../utils/utils';
import SelectorFormsComponent from '../../../components/sub-component/selectorForms';
import StaticModal from '../../../dialogs/StaticModal';
import Container from 'react-bootstrap/Container';

interface ModalAssignVoterBoxProps {
  modalShow: any;
  setModalShow: any;
  successFun: any;
  edit?: any;
  t: any;
}

export const ModalAssignVoterBoxes: React.FunctionComponent<ModalAssignVoterBoxProps> = ({
  modalShow,
  setModalShow,
  successFun,
  edit,
  t,
}: any) => {
  const defaultEdit = {
    _id: '',
    number: '',
    rgId: '',
    rcId: '',
    substituteId: '',
    address: {},
  };

  const arrayRG: any = [{ _id: '', name: t('settings.trainings.form.select') }];
  const arrayRC: any = [{ _id: '', name: t('settings.trainings.form.select') }];
  const arraySubstitute: any = [
    { _id: '', name: t('settings.trainings.form.select') },
  ];
  const [toSave, setToSave] = useState(defaultEdit);
  const [submitMessage, setSubmitMessage] = useState(String);
  const [submitConfirmation, setSubmitConfirmation] = useState(false);
  const [loadData, setLoadData] = useState(false);
  const [rgArray, setRgArray] = useState(arrayRG);
  const [rcArray, setRcArray] = useState(arrayRC);
  const [substituteArray, setSubstituteArray] = useState(arraySubstitute);
  const citySelected = useSelector((globalState: RootStore) => {
    return globalState.core.globalMunicipalitySelected;
  });
  const userData: any = useSelector((globalState: RootStore) => {
    return globalState.core.userData;
  });

  const role = localStorage.role;

  console.log('toSave', toSave);
  console.log('edit', edit);

  const getRgMembers = (id: any) => {
    netWorking.post(
      netWorking.postByCityRCsTeams,
      {
        municipalityId: id,
        isFirst: true,
      },
      (res: any) => {
        if (res.data.response.teams[0]) {
          const arrayData = res.data.response.teams;
          arrayData.unshift({
            _id: '',
            name: t('settings.trainings.form.select'),
          });
          if (toSave.rgId === undefined || toSave.rgId === '') {
            setToSave((prevState) => ({
              ...prevState,
              rgId: arrayData[0]._id,
            }));
            getRcMembers(arrayData[0]._id);
          } else {
            getRcMembers(toSave.rgId);
          }
          setRgArray(arrayData);
        } else {
          setRgArray(rgArray);
          setRcArray(arrayRC);
          setSubstituteArray(arraySubstitute);
          setToSave((prevState) => ({
            ...prevState,
            rgId: '',
            rcId: '',
            substituteId: '',
          }));
        }
        if (role === ROLE.RG) {
          setToSave((prevState) => ({
            ...prevState,
            rgId: userData._id,
          }));
          getRcMembers(userData._id);
        }
      },
      (error: any) => {
        setRgArray(rgArray);
        setRcArray(arrayRC);
        setSubstituteArray(arraySubstitute);
        setToSave((prevState) => ({
          ...prevState,
          rgId: '',
          rcId: '',
          substituteId: '',
        }));
        if (role === ROLE.RG) {
          getRcMembers(userData._id);
        }
      },
    );
  };

  const getRcMembers = (id: string) => {
    netWorking.post(
      netWorking.getTotalRCs,
      {
        RGId: id,
      },
      (res: any) => {
        if (res.data.response.teamData[0]) {
          const arrayData = res.data.response.teamData;
          arrayData.unshift({
            _id: '',
            name: t('settings.trainings.form.select'),
          });
          if (toSave.rcId === undefined || toSave.rcId === '') {
            setToSave((prevState) => ({
              ...prevState,
              rcId: arrayData[0]._id,
            }));
            getSubstitutesMembers(arrayData[0]._id, arrayData);
          } else {
            getSubstitutesMembers(toSave.rcId, arrayData);
          }
          setRcArray(arrayData);
        } else {
          setRcArray(arrayRC);
          setSubstituteArray(arraySubstitute);
          setToSave((prevState) => ({
            ...prevState,
            rcId: '',
            substituteId: '',
          }));
        }
      },
      (error: any) => {
        setRcArray(arrayRC);
        setSubstituteArray(arraySubstitute);
        setToSave((prevState) => ({
          ...prevState,
          rcId: '',
          substituteId: '',
        }));
      },
    );
  };

  const getSubstitutesMembers = (id: string, rcArray: any) => {
    const substitutes = rcArray.filter(
      (rc: any) => rc._id !== id && rc._id !== '',
    );
    if (substitutes) {
      const arrayData = substitutes;
      arrayData.unshift({ _id: '', name: t('settings.trainings.form.select') });
      setSubstituteArray(arrayData);
      if (toSave.substituteId === undefined || toSave.substituteId === '') {
        setToSave((prevState) => ({
          ...prevState,
          substituteId: arrayData[0]._id,
        }));
      }
    } else {
      setSubstituteArray(arraySubstitute);
      setToSave((prevState) => ({
        ...prevState,
        substituteId: '',
      }));
    }
  };

  const submitFun = (validSubmit: any) => {
    if (validSubmit) {
      setSubmitMessage(validSubmit);
      setSubmitConfirmation(true);
    } else {
      netWorking.post(
        netWorking.postAssignVoterBoxGeneral,
        {
          _id: toSave._id,
          rcId: toSave.rcId,
          rgId: toSave.rgId,
          substituteId: toSave.substituteId,
        },
        (res: any) => {
          successFun();
          setModalShow(false);
        },
        (err: any) => {},
      );
    }
  };

  const validSubmit = () => {
    const newStringValue = verifyFields();
    submitFun(newStringValue);
  };

  const verifyFields = () => {
    return '';
  };

  useEffect(() => {
    if (edit !== undefined) {
      setToSave((prevState) => ({
        ...prevState,
        ...edit,
      }));
    }
    setLoadData(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edit]);

  useEffect(() => {
    if (loadData) {
      getRgMembers(citySelected?._id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadData]);

  return (
    <StaticModal
      show={modalShow}
      title={t('electoral.polling.modal.title')}
      closeText={t('submit.cancel')}
      confirmText={t('general.modal.btn.confirm')}
      onHide={() => {
        setModalShow(false);
      }}
      submitFun={validSubmit}
      validBtn={false}
      size="sm"
      sizeClass="md modal-rc"
    >
      <>
        <Container className="dialog-assign-vb">
          {submitConfirmation && (
            <div className="col-item-message">
              <p>{submitMessage}</p>
            </div>
          )}
          {role !== ROLE.RG && (
            <div className="row-item-modal first-row">
              <div className="col-item-modal">
                <SelectorFormsComponent
                  key={1}
                  title={t('electoral.polling.modal.lbl.rg')}
                  defaultSelector={toSave.rgId}
                  options={rgArray?.filter((e: any) => {
                    return e.name;
                  })}
                  horizontal={false}
                  fun={(value: string) => {
                    getRcMembers(value);
                    setToSave((prevState) => ({
                      ...prevState,
                      rgId: value,
                    }));
                  }}
                ></SelectorFormsComponent>
              </div>
            </div>
          )}
          <div
            className={
              role !== ROLE.RG
                ? 'row-item-modal second-row'
                : 'row-item-modal first-row'
            }
          >
            <div className="col-item-modal">
              <SelectorFormsComponent
                key={2}
                title={t('electoral.polling.modal.lbl.rc')}
                defaultSelector={toSave.rcId}
                options={rcArray}
                horizontal={false}
                fun={(value: string) => {
                  getSubstitutesMembers(value, rcArray);
                  setToSave((prevState) => ({
                    ...prevState,
                    rcId: value,
                  }));
                  if (value === toSave.substituteId) {
                    setToSave((prevState) => ({
                      ...prevState,
                      substituteId: '',
                    }));
                  }
                }}
              ></SelectorFormsComponent>
            </div>
          </div>
          <div className="row-item-modal third-row">
            <div className="col-item-modal">
              <SelectorFormsComponent
                key={3}
                title={t('electoral.polling.modal.lbl.substitute')}
                defaultSelector={toSave.substituteId}
                options={substituteArray}
                horizontal={false}
                fun={(value: string) => {
                  setToSave((prevState) => ({
                    ...prevState,
                    substituteId: value,
                  }));
                }}
              ></SelectorFormsComponent>
            </div>
          </div>
        </Container>
      </>
    </StaticModal>
  );
};

export default ModalAssignVoterBoxes;
