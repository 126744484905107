import React from 'react';
import './style.scss';
import { RiCloseLine } from 'react-icons/ri';
import { PDFExportIcon, XLSExportIcon } from '../assets/svg';
import { Logs, LOGS_FILE_TYPE, LOGS_SUCCESS } from '../redux/logs/LogsTypes';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../redux/Store';
import moment from 'moment';
import { getLogsFile } from '../redux/logs/LogsActions';
import { useTranslation } from 'react-i18next';

interface TabsProps {
  openLogs: any;
  active: any;
}

const LogsListComponent: React.FunctionComponent<TabsProps> = ({
  openLogs,
  active,
}: any) => {
  const dispatch = useDispatch();
  const logs = useSelector((globalState: RootStore) => globalState.logs.logs);
  const module = useSelector(
    (globalState: RootStore) => globalState.logs.module,
  );
  const dataId = useSelector(
    (globalState: RootStore) => globalState.logs.dataId,
  );
  const { t } = useTranslation();

  const setOpen = () => {
    dispatch({ type: LOGS_SUCCESS, logs: [] });
    openLogs();
  };

  const getActionType = (type: any) => {
    switch (type) {
      case 'XLS_CREATE_VOTER':
        return t('logs.slider.lbl.register');
      case 'XLS_UPDATE_VOTER':
        return t('logs.slider.lbl.update');
      case 'CAPTURE_CREATE_VOTER':
        return t('logs.slider.lbl.capture');
      case 'CAPTURE_UPDATE_VOTER':
        return t('logs.slider.lbl.update');
      case 'AUDITED_CORRECT_VOTER':
        return t('logs.slider.lbl.confirm');
      case 'AUDITED_WRONG_VOTER':
        return t('logs.slider.lbl.error');
      default:
        break;
    }
  };

  return (
    <div className={!active ? 'logs-list' : 'logs-list is-open'}>
      <div className="export-icons">
        <PDFExportIcon
          onClick={() => {
            dispatch(
              getLogsFile(t('language'), LOGS_FILE_TYPE.PDF, dataId, module),
            );
          }}
        ></PDFExportIcon>
        <XLSExportIcon
          onClick={() => {
            dispatch(
              getLogsFile(t('language'), LOGS_FILE_TYPE.EXCEL, dataId, module),
            );
          }}
        ></XLSExportIcon>
      </div>
      <div className="close-button" onClick={setOpen}>
        <RiCloseLine />
      </div>
      <div className="body-logs">
        <div className="title">
          <h1>{t('logs.slider.table.lable.logs')}</h1>
        </div>
        <div className="list-nav">
          <div className="header">
            <div className="date">{t('logs.slider.table.lable.date')}</div>
            <div className="time">{t('logs.slider.table.lable.hour')}</div>
            <div className="type">{t('logs.slider.table.lable.type')}</div>
            <div className="description">
              {t('logs.slider.table.lable.description')}
            </div>
          </div>
          <div className="list-body">
            {logs.map((e: Logs) => (
              <div className="item" key={e._id}>
                <div className="date">
                  {moment(e.createdDate).format('D/MM/YY')}
                </div>
                <div className="time">
                  {moment(e.createdDate).format('h:mm a')}
                </div>
                <div className="type">{getActionType(e.action)}</div>
                <div className="description">{e.message}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default LogsListComponent;
