import React, { useState, useEffect, FunctionComponent } from 'react';
import '../style.scss';
import Button from 'react-bootstrap/Button';
import { Map, TileLayer } from 'react-leaflet';
import { LatLngExpression } from 'leaflet';
import L from 'leaflet';
import InputComponent from '../../../../../components/sub-component/input';
import {
  createMunicipality,
  createState,
} from '../../../../../redux/coreLicense/coreLicenseActions';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../../../redux/Store';
import SelectorFormsComponent from '../../../../../components/sub-component/selectorForms';
import { MODE_TYPE } from '../../../../../redux/coreLicense/coreLicenseTypes';

interface StateItemProps {
  feature: any;
  mode: string;
}
// tslint:disable-next-line: prefer-const
let emptyEdit: any;
const FeatureEditItem: FunctionComponent<StateItemProps> = ({
  feature,
  mode,
}: StateItemProps) => {
  const leafletMapRef = React.useRef<Map>(null);
  // const { t } = useTranslation();
  const dispatch = useDispatch();
  const defaultPosition = {
    lat: 32.7157431,
    lng: -117.1888535,
    zoom: 11,
  };
  const [bounds, setBounds] = useState(emptyEdit);
  const [toSave, setToSave] = useState({
    name: '',
    stateId: '',
  });

  const countrySelected = useSelector((globalState: RootStore) => {
    return globalState.campaignsManager.selectedCountry;
  });

  const states = useSelector((globalState: RootStore) => {
    return globalState.core.stateD;
  });

  const AddLayer = (polygon: any) => {
    const mapElement = leafletMapRef.current?.leafletElement;
    if (mapElement && polygon !== null) {
      var bounds = L.geoJSON(polygon, {
        onEachFeature: (feature, layer) => {
          layer.addTo(mapElement);
          layer.on('pm:edit', (e) => {
            if (e.target && e.target.pm) {
              //const lay = e.layer.toGeoJSON();
              // setPolygonData(lay);
            }
          });
          layer.on('pm:cut', function (e) {
            if (e.target && e.target.pm) {
              layer.off('pm:edit');
            }
          });
          layer.on('click', function (e) {
            // setPm(e.target.pm);
            e.target.pm.disable();
            // setEditMode(true);
          });
        },
      }).getBounds();
      setBounds(bounds);
      return bounds;
    } else return null;
  };

  useEffect(() => {
    if (leafletMapRef.current) {
      AddLayer(feature);
    }
  }, [feature]);

  useEffect(() => {
    if (states && states.length > 0) {
      setToSave((prevState: any) => ({
        ...prevState,
        stateId: states[0]._id,
      }));
    }
  }, [states]);

  useEffect(() => {
    if (leafletMapRef.current) {
      const mapElement = leafletMapRef.current.leafletElement;

      (mapElement as any).pm.setGlobalOptions({ pmIgnore: false });
      (mapElement as any).pm.setLang('es');

      (mapElement as any).pm.addControls({
        drawMarker: false,
        drawCircle: false,
        drawCircleMarker: false,
        drawPolyline: false,
        drawRectangle: false,
        drawPolygon: false,
        editMode: false,
        dragMode: false,
        cutPolygon: false,
        removalMode: false,
        zoomMode: false,
      });
      mapElement.off('pm:create');
      mapElement.on('pm:create', (e: any) => {
        if (e.target && e.target.pm) {
          // addNewLayer(e.layer.toGeoJSON());
        }
      });
      mapElement.off('pm:remove');
      mapElement.on('pm:remove', (e: any) => {
        // (mapElement as any).pm.addControls({
        //   drawPolygon: true,
        //   removalMode: false,
        // });
        // onPolygonRemoved();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leafletMapRef.current]);

  const save = () => {
    if (mode === MODE_TYPE.CITIES) {
      dispatch(
        createMunicipality(
          {
            name: toSave.name,
            stateId: toSave.stateId,
            geoCoordinates: {
              geometry: feature.geometry,
            },
          },
          feature._id,
        ),
      );
    }
    if (mode === MODE_TYPE.STATES) {
      dispatch(
        createState(
          {
            name: toSave.name,
            countryId: countrySelected.country._id,
            geoCoordinates: {
              geometry: feature.geometry,
            },
          },
          feature._id,
        ),
      );
    }
  };

  return (
    <div
      className={
        mode === MODE_TYPE.STATES ? 'feature-item ' : 'feature-item state-item'
      }
      key={feature._id}
    >
      <div className="title">
        <div className="box-inputs">
          <InputComponent
            placeholder={'California'}
            length={100}
            onChange={(value: string) => {
              let name = '';
              name = value;
              setToSave((prevState: any) => ({
                ...prevState,
                name,
              }));
            }}
            title={'Name'}
            value={toSave.name}
            horizontal={true}
          ></InputComponent>
          {mode === MODE_TYPE.CITIES && (
            <SelectorFormsComponent
              options={states}
              title={'State'}
              defaultSelector={toSave.stateId}
              horizontal={true}
              fun={(data: any) => {
                setToSave((prevState: any) => ({
                  ...prevState,
                  stateId: data,
                }));
              }}
            ></SelectorFormsComponent>
          )}
        </div>
        <div className="actions">
          <Button
            className="c-button"
            variant="outline-dark"
            onClick={() => {}}
          >
            Ignore
          </Button>
          <Button className="c-button" variant="primary" onClick={save}>
            Save
          </Button>
        </div>
      </div>
      <div className="invisible-wall"></div>
      <div className="map-box">
        <Map
          ref={leafletMapRef}
          center={
            [defaultPosition.lat, defaultPosition.lng] as LatLngExpression
          }
          zoom={defaultPosition.zoom}
          bounds={bounds}
          boundsOptions={{ padding: [0, 0] }}
          zoomControl={false}
          boxZoom={false}
          dragging={false}
          tap={false}
        >
          <TileLayer
            url="https://maps.googleapis.com/maps/vt?pb=!1m5!1m4!1i{z}!2i{x}!3i{y}!4i256!2m3!1e0!2sm!3i349018013!3m9!2sen-US!3sUS!5e18!12m1!1e47!12m3!1e37!2m1!1ssmartmaps!4e0"
            maxZoom={20}
            subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
            attribution={'Google Maps'}
          />
        </Map>
      </div>
    </div>
  );
};

export default FeatureEditItem;
