import {
  SET_USER,
  SET_AUTHENTICATED,
  SET_UNAUTHENTICATED,
  LOADING_USER,
} from '../types';
import { SET_SUPER_ADMIN, UserDispatchTypes } from './userTypes';

export interface USERState {
  authenticated: boolean;
  superAdmin: boolean;
  loading: boolean;
  user?: {};
  credentials?: {};
}

export const defaultState: USERState = {
  loading: false,
  superAdmin: false,
  authenticated: false,
};

export const userReducer = (
  state: USERState = defaultState,
  action: UserDispatchTypes,
): USERState => {
  switch (action.type) {
    case SET_AUTHENTICATED:
      return {
        ...state,
        authenticated: true,
        superAdmin: action.superAdmin,
      };
    case SET_UNAUTHENTICATED:
      return defaultState;
    case SET_USER:
      console.log(action.payload);
      return {
        ...state,
        authenticated: true,
        loading: false,
        user: action.payload,
      };
    case SET_SUPER_ADMIN:
      console.log(action.payload);
      return {
        ...state,
        superAdmin: action.payload,
      };
    case LOADING_USER:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};
