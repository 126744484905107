import React from 'react';
import { RootStore } from '../../../redux/Store';
import { useSelector } from 'react-redux';
import Container from 'react-bootstrap/Container';
import StaticModal from '../../../dialogs/StaticModal';
import Dropzone from './dropzoneXLS';
import * as netWorking from '../../../redux/netWorking';
import { ROLE, XLS_TYPE } from '../../../utils/utils';
import { BiChevronRight } from 'react-icons/bi';
import { TEAMS_LOADING, TEAMS_FAIL } from '../../../redux/teams/teamsTypes';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  CheckWrongStatusIcon,
  AuditoriaStatusIcon,
} from '../../../assets/svg/index';

interface ModalXLSProps {
  modalShow: any;
  setModalShow: any;
  type: any;
  teamId?: any;
  successFun?: any;
  cityLicenseId?: any;
  role?: any;
  states?: any;
  cities?: any;
  levelList?: any;
  showConfirm?: any;
  showModalFooterContinue?: any;
}

export const ModalAddXLS: React.FunctionComponent<ModalXLSProps> = ({
  modalShow,
  setModalShow,
  type,
  teamId = null,
  cityLicenseId = null,
  role = null,
  states = null,
  cities = null,
  successFun,
  levelList,
  showConfirm = true,
  showModalFooterContinue = false,
}: any) => {
  const citySelected: any = useSelector((globalState: RootStore) => {
    return globalState.core.globalMunicipalitySelected;
  });

  const navigationPath: any = useSelector(
    (globalState: RootStore) => globalState.teams.navigationPath,
  );
  const coordinator: any = useSelector(
    (globalState: RootStore) => globalState.teams.coordinator,
  );

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [levelArray, setLevelArray] = React.useState([]);
  const [status, setStatus] = React.useState('SUGGESTION');
  const [rows, setRows] = React.useState(0);
  const [showConfirmBtn, setShowConfirmBtn] = React.useState(showConfirm);
  const [showFooterContinue, setShowFooterContinue] = React.useState(
    showModalFooterContinue,
  );
  const [cancelButtonText, setCancelButtonText] = React.useState(String);
  const [routeFile, setRouteFile] = React.useState(null);

  React.useEffect(() => {
    let selectedRole =
      navigationPath.length < 1 ? localStorage?.role : coordinator.role;
    let selectedLevel =
      navigationPath.length < 1 ? localStorage?.level : coordinator.level;
    if (
      selectedRole !== ROLE.ADMIN_BOSS &&
      selectedRole !== ROLE.SUPER_ADMIN &&
      selectedLevel !== citySelected.capturistUserLevels.length
    )
      setStatus('UPLOAD');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigationPath, coordinator]);

  const startLoadingType = () => {
    if (
      type === XLS_TYPE.CAPTURIST_BOSS_XLS_TYPE ||
      type === XLS_TYPE.CAPTURIST_XLS_TYPE ||
      type === XLS_TYPE.CAPTURIST_LV1_XLS_TYPE
    ) {
      dispatch({ type: TEAMS_LOADING });
    }
  };

  const finishLoadingType = () => {
    if (
      type === XLS_TYPE.CAPTURIST_BOSS_XLS_TYPE ||
      type === XLS_TYPE.CAPTURIST_XLS_TYPE ||
      type === XLS_TYPE.CAPTURIST_LV1_XLS_TYPE
    ) {
      dispatch({ type: TEAMS_FAIL });
    }
  };

  const errorLoadingType = () => {
    if (
      type === XLS_TYPE.CAPTURIST_BOSS_XLS_TYPE ||
      type === XLS_TYPE.CAPTURIST_XLS_TYPE ||
      type === XLS_TYPE.CAPTURIST_LV1_XLS_TYPE
    ) {
      dispatch({ type: TEAMS_FAIL });
    }
  };

  const hideFun = () => {
    if (routeFile !== null) {
      netWorking.post(
        netWorking.ConfirmUpload,
        {
          type,
          route: routeFile,
          isValid: false,
          teamId,
          cityLicenseId,
          rol: role,
          levelList: levelList,
          states,
          cities,
        },
        (res: any) => {
          successFun(res);
        },
        () => {},
      );
    }
    setModalShow(false);
  };

  const submitFun = () => {
    if (routeFile !== null) {
      startLoadingType();
      netWorking.post(
        netWorking.ConfirmUpload,
        {
          type,
          route: routeFile,
          isValid: true,
          teamId,
          cityLicenseId,
          rol: role,
          levelList: levelList,
          states,
          cities,
        },
        (res: any) => {
          finishLoadingType();
          successFun();
          setShowConfirmBtn(false);
          setCancelButtonText(t('submit.close'));
          setRows(res.data.response.status);
          setStatus('DONE');
        },
        () => {
          errorLoadingType();
          setShowConfirmBtn(false);
          setCancelButtonText(t('submit.close'));
          setStatus('ERROR');
        },
      );
    }
  };

  const getAfterContinue = () => {
    setShowConfirmBtn(true);
    setShowFooterContinue(false);
    setStatus('UPLOAD');
  };

  React.useEffect(() => {
    if (citySelected.capturistUserLevels) {
      let array: any = [...citySelected.capturistUserLevels];
      setLevelArray(array);
    }
    if (type === XLS_TYPE.CAPTURIST_XLS_TYPE) {
      getAfterContinue();
    }
    setCancelButtonText(t('XLS.modal.button.cancel'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StaticModal
      show={modalShow}
      title={t('XLS.modal.lbl.uploadFile')}
      closeText={cancelButtonText}
      confirmText={t('XLS.modal.button.save')}
      onHide={hideFun}
      submitFun={submitFun}
      validBtn={routeFile === null}
      showConfirmBtn={showConfirmBtn}
      showModalFooterContinue={showFooterContinue}
      onContinue={getAfterContinue}
      size="xl"
      sizeClass="md modal-rc"
    >
      {status === 'SUGGESTION' && (
        <Container
          className={
            type === XLS_TYPE.CAPTURIST_BOSS_XLS_TYPE ||
            type === XLS_TYPE.CAPTURIST_LV1_XLS_TYPE ||
            type === XLS_TYPE.CAPTURIST_XLS_TYPE
              ? 'dialog-tip-teams'
              : 'dialog-tip-teams'
          }
        >
          <p className="sub-title">
            {t('territorial.volunteer.lbl.levelByRol')}
          </p>
          <div className="box-of-levels">
            {levelArray.map((e: any, index: any) => {
              let showLevel = false;
              let selectedRole =
                navigationPath.length < 1
                  ? localStorage?.role
                  : coordinator.role;
              let selectedLevel =
                navigationPath.length < 1
                  ? localStorage?.level
                  : coordinator.level;
              if (
                selectedRole === ROLE.SUPER_ADMIN ||
                selectedRole === ROLE.ADMIN_BOSS
              ) {
                showLevel = true;
              } else {
                if (index !== 0) {
                  showLevel = true;
                }
              }

              if (
                showLevel &&
                (selectedRole === ROLE.ADMIN_BOSS ||
                  selectedRole === ROLE.SUPER_ADMIN ||
                  selectedLevel === citySelected.capturistUserLevels.length)
              ) {
                return (
                  <div
                    className="tip-row-element"
                    style={{ paddingLeft: `${(index + 1) * 10}px` }}
                    key={index + '-tip'}
                  >
                    <p>{e['level'] + '° ' + e[t('language')]}</p>
                  </div>
                );
              } else {
                return <></>;
              }
            })}
          </div>
        </Container>
      )}
      {status === 'UPLOAD' && (
        <Dropzone
          type={type}
          levelList={levelList}
          setRouteFile={setRouteFile}
        ></Dropzone>
      )}
      {status === 'DONE' && (
        <Container className="dialog-result-teams">
          <AuditoriaStatusIcon></AuditoriaStatusIcon>
          <h2>{t('XLS.modal.lbl.congratulations')}</h2>
          <p>
            {t('XLS.modal.lbl.rowsAccepted', {
              counter: rows,
            })}
          </p>
        </Container>
      )}
      {status === 'ERROR' && (
        <Container className="dialog-result-teams">
          <CheckWrongStatusIcon></CheckWrongStatusIcon>
          <h2>{t('logs.slider.lbl.error')}</h2>
          <p>{t('XLS.modal.lbl.rowsDenied')}</p>
        </Container>
      )}
    </StaticModal>
  );
};

export default ModalAddXLS;
