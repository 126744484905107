import React, { useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../style.scss';
import StaticModal from '../../../dialogs/StaticModal';
import {
  putTraining,
  postTrainingUpdateFiles,
} from '../../../redux/trainings/TrainingsActions';
import { useDispatch } from 'react-redux';

interface ModalEditProps {
  modalShow: any;
  setModalShow: any;
  training: any;
  filesArray: any;
  setFilesArray: any;
  successFun: any;
  setIsPerformingRequest: any;
  t: any;
}

export const ModalEdit: React.SFC<ModalEditProps> = ({
  modalShow,
  setModalShow,
  training,
  filesArray,
  setFilesArray,
  successFun,
  setIsPerformingRequest,
  t,
}: any) => {
  const [formData, setFormData] = React.useState(Object);
  const dispatch = useDispatch();

  const checkFiles = (stepFiles: any) => {
    let newFormData = { ...formData };
    let imageFile;
    let stepFile;

    // if(stepFiles.length === (newFormData.stepData.length+1)){
    imageFile = stepFiles.filter((e: any) => {
      return e.fieldName === 'image';
    });

    stepFile = stepFiles.filter((e: any) => {
      return e.fieldName !== 'image';
    });

    if (imageFile.length > 0) {
      newFormData.uploadData.name = imageFile[0].location;
    }

    if (stepFile.length > 0) {
      stepFile.forEach((e: any) => {
        newFormData.stepData[e.fieldName].file.dataFull = e.location;
      });
    }

    putTraining(newFormData)
      .then(() => {
        successFun({ edited: true });
        setIsPerformingRequest(false);
      })
      .catch(() => {
        successFun({ edited: false });
        setIsPerformingRequest(false);
      });
  };

  const submitFun = () => {
    if (Object.keys(training).length > 0) {
      setIsPerformingRequest(true);
      setModalShow(false);
      dispatch(
        postTrainingUpdateFiles(
          filesArray,
          (res: any) => {
            checkFiles(res.files);
          },
          () => {},
        ),
      );
    }
  };

  useEffect(() => {
    setFormData(training);
  }, [training]);

  return (
    <StaticModal
      show={modalShow}
      title={t('settings.trainings.modal.edit.title')}
      closeText={t('settings.trainings.modal.button.cancel')}
      confirmText={t('settings.trainings.modal.button.yesEdit')}
      onHide={() => setModalShow(false)}
      submitFun={submitFun}
      validBtn={false}
      size="sm"
    >
      <Row noGutters className="modal-text-row">
        <Col xs={12} className="description-col">
          {t('settings.trainings.modal.edit.body')}
        </Col>
      </Row>
    </StaticModal>
  );
};

export default ModalEdit;
