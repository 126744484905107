import {
  INCIDENCE_LOADING,
  INCIDENCE_FAIL,
  INCIDENCE_ALL,
} from './incidenceTypes';
import * as netWorking from '../netWorking';

export const postListAllIncidence = (campaignId: any, successFun?: any) => {
  return async (dispatch: any) => {
    dispatch({
      type: INCIDENCE_LOADING,
    });
    return await netWorking.get(
      netWorking.postAllIncidences + campaignId,
      (res: any) => {
        if (successFun) {
          successFun(res.data.response);
        }
        return dispatch({
          type: INCIDENCE_ALL,
          payload: res.data.response,
        });
      },
      (error: any) => {
        return dispatch({
          type: INCIDENCE_FAIL,
        });
      },
    );
  };
};
