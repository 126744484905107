// In types.ts we will define constants for user authentication and UI loading purpose

// user reducer types
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED';
export const SET_UNAUTHENTICATED = 'SET_UNAUTHENTICATED';
export const SET_USER = 'SET_USER';
export const SET_SUPER_ADMIN = 'SET_SUPER_ADMIN';
export const LOADING_USER = 'LOADING_USER';

// UI reducer types
export const SET_ERRORS = 'SET_ERRORS';
export const LOADING_UI = 'LOADING_UI';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';

export type UserData = {};

export interface UserLoading {
  type: typeof LOADING_USER;
}

export interface SetUserAuthenticated {
  type: typeof SET_AUTHENTICATED;
  superAdmin: boolean;
}

export interface SetUserUnauthenticated {
  type: typeof SET_UNAUTHENTICATED;
}

export interface SetUser {
  type: typeof SET_USER;
  payload: UserData;
}

export interface SetUserSuperAdmin {
  type: typeof SET_SUPER_ADMIN;
  payload: boolean;
}

export interface SetErrors {
  type: typeof SET_ERRORS;
  payload: any;
}

export interface LoadingUi {
  type: typeof LOADING_UI;
}

export interface ClearErrors {
  type: typeof CLEAR_ERRORS;
}

export type UserDispatchTypes =
  | UserLoading
  | SetUserAuthenticated
  | SetUserUnauthenticated
  | SetUser
  | SetErrors
  | LoadingUi
  | ClearErrors
  | SetUserSuperAdmin;
