import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import StaticModal from '../../../../dialogs/StaticModal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { updateLicense } from '../../../../redux/campaigns/campaignsActions';
import {
  Campaign,
  City,
  SET_SELECTED_LICENSE_CITY,
} from '../../../../redux/campaigns/campaignsTypes';
import { SUPER_CAMPAIGNS } from '../../../../utils/paths';
import { useHistory } from 'react-router-dom';

interface AddModalProps {
  modalShow: any;
  setModalShow: any;
  licenseSelected?: Campaign;
  cityLicense?: City;
}

export const DeleteCityLicenseModalComponent: React.FunctionComponent<AddModalProps> = ({
  modalShow,
  setModalShow,
  licenseSelected,
  cityLicense,
}: AddModalProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const redirect = useHistory();
  const cancelFun = () => {
    setModalShow(false);
  };

  const submitFun = () => {
    onSave();
    setModalShow(false);
  };

  const onSave = () => {
    if (licenseSelected && cityLicense) {
      const license = licenseSelected;
      const city = cityLicense;
      const cities: any = [];
      license.cities.forEach((e: City) => {
        if (e._id !== city._id) {
          cities.push(e);
        }
      });
      license.cities = [...cities];
      dispatch(
        updateLicense(
          license,
          (data: any) => {
            redirect.push(SUPER_CAMPAIGNS);
            dispatch({
              type: SET_SELECTED_LICENSE_CITY,
              licenseSelected: undefined,
              cityLicense: undefined,
            });
          },
          (err: any) => {
            console.log(err);
          },
        ),
      );
    }
  };

  return (
    <StaticModal
      show={modalShow}
      title={'Delete City License'}
      closeText={'Cancel'}
      confirmText={'Delete'}
      onHide={cancelFun}
      submitFun={submitFun}
      validBtn={false}
      size="sm"
      sizeClass="delete-license-modal"
    >
      <Row noGutters className="modal-text-row">
        <Col xs={12} className="description-col">
          <div className="text-description">
            {t('admin.modal.lbl.deleteQuestionA')}{' '}
            <strong>{cityLicense?.name || ''}</strong>{' '}{t('admin.modal.lbl.deleteQuestionB')}
          </div>
        </Col>
      </Row>
    </StaticModal>
  );
};

export default DeleteCityLicenseModalComponent;
