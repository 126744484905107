import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootStore } from '../../../redux/Store';

import {
  getVotersBoxElectoralByCoordinates,
  getVotersBoxElectoralTotalByCoordinates,
  setSelectedValuesElectoralMap,
} from '../../../redux/votersBox/VotersBoxActions';
import { getMaxDistance } from '../../../utils/utils';

import { createClusterCustomIcon } from '../../../components/sub-component/markers';
import LayerControlComponent from '../../../components/sub-component/layerControl';
import VotersBoxMapIconGreen from '../../../assets/svg/green_place.svg';
import VotersBoxMapIcon from '../../../assets/svg/icon_casilla.svg';
import ModalAssignVoterBox from './assignVoterBox';
import Button from 'react-bootstrap/Button';
import ItemVoterBox from './itemVoterBox';
import TopBar from './TopBar';

import MarkerClusterGroup from 'react-leaflet-markercluster';
import { Map, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

const Maps: React.FunctionComponent = () => {
  const citySelected: any = useSelector((globalState: RootStore) => {
    return globalState.core.globalMunicipalitySelected;
  });

  const voterBoxsMap = useSelector(
    (globalState: RootStore) => globalState.votersBox.voterBoxsElectoralMap,
  );
  const totalVoterBoxsMap: number = useSelector(
    (globalState: RootStore) =>
      globalState.votersBox.totalVoterBoxsElectoralMap,
  );
  const search = useSelector(
    (globalState: RootStore) => globalState.votersBox.searchElectoralMap,
  );
  const selected: any = useSelector(
    (globalState: RootStore) =>
      globalState.votersBox.sectionSelectedElectoralMap,
  );

  let defaultCoordinate: any;

  const [radius, setRadius] = useState(144447.6442);
  const [coordinates, setCoordinates] = useState(defaultCoordinate);
  const [selectedItem, setSelectedItem] = useState('');
  const [modalAssignVB, setModalAssignVB] = useState(false);
  const [edit, setEdit] = useState(Object);

  const defaultPosition = {
    lat: 32.7157431,
    lng: -117.1888535,
    zoom: 11,
  };

  const [center, setCenter] = React.useState(defaultCoordinate);
  const [zoom, setZoom] = React.useState(defaultCoordinate);

  useEffect(() => {
    if (citySelected) {
      setCenter(
        citySelected?.defaultCenter !== undefined
          ? citySelected?.defaultCenter
          : [defaultPosition.lat, defaultPosition.lng],
      );
      setZoom(
        citySelected?.defaultZoom !== undefined
          ? citySelected?.defaultZoom
          : defaultPosition.zoom,
      );
      setCoordinates(
        citySelected?.defaultCenter !== undefined
          ? [citySelected?.defaultCenter.lng, citySelected?.defaultCenter.lat]
          : [defaultPosition.lng, defaultPosition.lat],
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [citySelected]);

  let markerRefs: any = [];

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const openEdit = (_id: any) => {
    const vb = voterBoxsMap?.find((value: any) => value._id === _id);
    if (vb) {
      setEdit(vb);
      setModalAssignVB(true);
    }
  };

  const setSelected = (data: any, value: string) => {
    dispatch(setSelectedValuesElectoralMap(data));
  };

  // const closePopusOnClick = (popup: any) => {
  //   popup.current.leafletElement.options.leaflet.map.closePopup();
  // };

  const getMarkerIcon = (voterboxId: any) => {
    if (voterboxId === selectedItem) {
      return L.icon({
        iconUrl: VotersBoxMapIconGreen,
        iconSize: [70, 70],
        iconAnchor: [20, 40],
        className: 'green-icon-map',
      });
    } else {
      return L.icon({
        iconUrl: VotersBoxMapIcon,
        iconSize: [40, 40],
        iconAnchor: [20, 40],
      });
    }
  };

  const getAddress = (address: any) => {
    let result = '';
    const newAddress = address;
    if (newAddress) {
      if (newAddress.street && newAddress.street !== '')
        result = newAddress.street;
      if (newAddress.exteriorNumber && newAddress.exteriorNumber !== '')
        result = result + `, ${newAddress.exteriorNumber}`;
      if (newAddress.interiorNumber && newAddress.interiorNumber !== '')
        result = result + `, ${newAddress.interiorNumber}`;
      if (newAddress.postalCode && newAddress.postalCode !== '')
        result = result + `, ${newAddress.postalCode}`;
      if (newAddress.suburb && newAddress.suburb !== '')
        result = result + `, ${newAddress.suburb}`;
      if (newAddress.section && newAddress.section !== '')
        result = result + `, ${newAddress.section}`;
      if (newAddress.district && newAddress.district !== '')
        result = result + `, ${newAddress.district}`;
      if (newAddress.municipality && newAddress.municipality !== '')
        result = result + `, ${newAddress.municipality}`;
    }
    return result;
  };

  const getRgName = (voterbox: any) => {
    if (voterbox?.rg.firstName && voterbox?.rg.lastName) {
      return (
        <div className="content-rg">
          <p className="rg-exists">
            <strong>
              {t('electoral.polling.slider.lbl.assignedTo')}
              {': '}
            </strong>
          </p>
          <p>
            {t('electoral.polling.table.rg')}
            {': '}
            {voterbox?.rg.firstName + ' ' + voterbox?.rg.lastName}
          </p>
          {voterbox?.rc.firstName && voterbox?.rc.lastName && (
            <p>
              {t('electoral.polling.table.rc')}
              {': '}
              {voterbox?.rc.firstName + ' ' + voterbox?.rc.lastName}
            </p>
          )}
          {voterbox?.substitute.firstName && voterbox?.substitute.lastName && (
            <p>
              {t('electoral.polling.table.rcSubstitute')}
              {': '}
              {voterbox?.substitute.firstName +
                ' ' +
                voterbox?.substitute.lastName}
            </p>
          )}
        </div>
      );
    } else {
      return (
        <div className="content-rg">
          <strong>{t('general.table.btn.noneAssign')} </strong>
          <Button
            variant="primary"
            onClick={() => {
              openEdit(voterbox._id);
            }}
          >
            {t('general.table.btn.assign')}
          </Button>
        </div>
      );
    }
  };

  const searchByValue = () => {
    const params = buildParamSearchVoterBoxDataMap(search);
    dispatch(getVotersBoxElectoralByCoordinates(params));
    dispatch(getVotersBoxElectoralTotalByCoordinates(params));
  };

  const buildParamSearchVoterBoxDataMap = (search: string) => {
    let requestObject;
    requestObject = {
      cityLicenseId: citySelected?._id,
      municipalityId: citySelected?.municipalityId,
      districtId: selected.district,
      sectionId: selected.section,
      search: search.length >= 3 ? search : '',
      longitude: coordinates[0],
      latitude: coordinates[1],
      radius: radius,
    };
    return requestObject;
  };

  const getBounds = (selectedId: any) => {
    const marketSelected = voterBoxsMap.find(
      (value: any) => value._id === selectedId,
    );
    if (marketSelected) {
      setCenter([
        marketSelected.address.geoLocation.coordinates[1],
        marketSelected.address.geoLocation.coordinates[0],
      ]);
    }
  };

  useEffect(() => {
    if (radius && coordinates) {
      searchByValue();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  useEffect(() => {
    if (selected && radius && coordinates) {
      searchByValue();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [radius]);

  useEffect(() => {
    if (selected && radius && coordinates) {
      searchByValue();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coordinates]);

  useEffect(() => {
    if (
      selected &&
      radius &&
      coordinates &&
      (search.length >= 3 || search.length <= 0)
    ) {
      searchByValue();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    if (selectedItem) {
      getBounds(selectedItem);
      markerRefs[selectedItem].leafletElement.openPopup();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem]);

  return (
    <div className="box-electoral-main">
      <div className="box-electoral-slider">
        <div className="slider-title">
          <h1>
            {t('electoral.rcrg.table.rgs.polling') +
              ' [' +
              totalVoterBoxsMap +
              ']'}
          </h1>
        </div>
        {voterBoxsMap?.map((e: any, i: number) => {
          return (
            <ItemVoterBox
              key={i}
              voterBox={e}
              index={i}
              selectedItem={selectedItem}
              setSelectedItem={setSelectedItem}
              t={t}
            ></ItemVoterBox>
          );
        })}
      </div>
      <div className="box-electoral-list box-map">
        <TopBar
          setSelected={setSelected}
          selected={selected}
          showName={false}
          showFilter={false}
          showDownload={false}
          showUpload={false}
          selectsToShow={3}
          total={totalVoterBoxsMap}
        ></TopBar>
        <div className="map-box-box">
          <Map
            closePopupOnClick={false}
            center={center}
            zoom={zoom}
            ondragend={(map: any) => {
              setCoordinates([
                map.target.getCenter().lng,
                map.target.getCenter().lat,
              ]);
              let zoom = map.target.getZoom();
              setRadius(getMaxDistance(zoom));
            }}
            onzoomend={(map: any) => {
              setCoordinates([
                map.target.getCenter().lng,
                map.target.getCenter().lat,
              ]);
              let zoom = map.target.getZoom();
              setRadius(getMaxDistance(zoom));
            }}
          >
            <LayerControlComponent></LayerControlComponent>
            <MarkerClusterGroup
              showCoverageOnHover={false}
              spiderfyDistanceMultiplier={2}
              iconCreateFunction={createClusterCustomIcon}
            >
              {voterBoxsMap.map((voterBoxes: any) => {
                const popup = React.createRef<Popup>();

                return (
                  <Marker
                    key={voterBoxes._id}
                    position={[
                      voterBoxes.address?.geoLocation.coordinates[1],
                      voterBoxes.address?.geoLocation.coordinates[0],
                    ]}
                    icon={getMarkerIcon(voterBoxes._id)}
                    ref={(ref) => (markerRefs[voterBoxes._id] = ref)}
                    onclick={() => {
                      setSelectedItem(
                        voterBoxes._id === selectedItem ? '' : voterBoxes._id,
                      );
                    }}
                  >
                    <Popup ref={popup}>
                      <div>
                        <div className="marker-actions">
                          <h2>
                            {t('electoral.rcrg.modal.lbl.polling')}{' '}
                            {voterBoxes.number}
                          </h2>
                        </div>
                        <p>{getAddress(voterBoxes.address)}</p>
                        {getRgName(voterBoxes)}
                      </div>
                    </Popup>
                  </Marker>
                );
              })}
            </MarkerClusterGroup>
          </Map>
        </div>
      </div>
      {modalAssignVB && (
        <ModalAssignVoterBox
          modalShow={modalAssignVB}
          setModalShow={setModalAssignVB}
          edit={edit}
          successFun={(res: any) => {
            searchByValue();
          }}
          t={t}
        ></ModalAssignVoterBox>
      )}
    </div>
  );
};

export default Maps;
