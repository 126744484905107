import React, { FunctionComponent, useEffect } from 'react';
import * as netWorking from '../../redux/netWorking';
import { useDispatch, useSelector } from 'react-redux';
import './style.scss';
import { useTranslation } from 'react-i18next';
import TopBarSelectors from '../../components/TopBarSelectors';
import CardDb from './components/card-db';
import ModalAddFile from './components/modalAddFile';
import { getUpData } from '../../redux/updata/UpDataActions';
import { RootStore } from '../../redux/Store';
import { UpData } from '../../redux/updata/UpDataTypes';
import ModalApproveFile from './components/ModalApproveFile';
import ListTemporalVoters from './components/listTemporalVoters';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import { DownloadIcon } from '../../assets/svg/index';
import { capitalizeWords, format, XLS_TYPE } from '../../utils/utils';
import moment from 'moment';
import LoadingModal from '../../dialogs/loadingModal';
import ModalDuplicateAlert from './components/alertDuplicationModal';
import ModalDuplicateList from './components/duplicateListModal';
import { BackIcon } from '../../assets/svg';
import ModalDeleteItem from './components/modalDeleteItem';
import TabComponent from '../../components/Tabs';
import { SELECT_DATA_SUCCESS } from '../../redux/updata/UpDataTypes';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import TopSeparator from '../../components/TopSeparator';

// tslint:disable-next-line: prefer-const
let defaultUpData: UpData;
// tslint:disable-next-line: prefer-const
let defaultArray: [] = [];
const VotersListSettings: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const upDataList = useSelector(
    (globalState: RootStore) => globalState.upData.upDataList,
  );
  const citySelected = useSelector((globalState: RootStore) => {
    return globalState.core.globalMunicipalitySelected;
  });
  const userData = useSelector((globalState: RootStore) => {
    return globalState.core.userData;
  });

  const [modalShowXls, setModalShowXls] = React.useState(false);
  const [modalShowApprove, setModalShowApprove] = React.useState(false);
  const [modalShowLoading, setModalShowLoading] = React.useState(false);
  const [modalDeleteUpData, setModalDeleteUpData] = React.useState(false);
  const [modalAlertDuplicate, setModalAlertDuplicate] = React.useState(false);
  const [modalDuplicateList, setModalDuplicateList] = React.useState(false);
  const [selected, setSelected] = React.useState(defaultUpData);
  const [selectedToDelete, setSelectedToDelete] = React.useState(defaultUpData);
  const [rejectedList, setRejectedList] = React.useState(defaultArray);
  const [loadData, setLoadData] = React.useState(false);
  const [, setFinalizeAvailable] = React.useState(false);
  const [type, setTypes] = React.useState<number[]>([0, 1, 2, 3]);

  // const filters = {
  //   type: [],
  //   search: '',
  // };

  // const [tabs, setTabs] = React.useState({
  //   first: true,
  //   second: false,
  //   third: false,
  // });

  useEffect(() => {
    if (!loadData) {
      dispatch(getUpData(citySelected?._id || ''));
      setLoadData(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadData]);

  useEffect(() => {
    if (selected) {
      dispatch({ type: SELECT_DATA_SUCCESS, selectData: true });
    } else {
      dispatch({ type: SELECT_DATA_SUCCESS, selectData: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const openDelete = (upDataId: string) => {
    const selectedUpData = upDataList?.filter((e: any) => {
      return e._id === upDataId;
    });
    if (selectedUpData && selectedUpData?.length > 0) {
      setSelectedToDelete(selectedUpData[0]);
      setModalDeleteUpData(true);
    }
  };

  const finalizeUpData = (upDataId: string) => {
    setModalShowLoading(true);
    netWorking.post(
      netWorking.ApproveUpData,
      {
        upDataId,
        reviewedId: userData?._id,
        reviewedByName:
          (userData?.firstName || '') + ' ' + (userData?.lastName || ''),
      },
      (resp: any) => {
        if (resp.data.response.rejectedVoter.length === 0) {
          setModalShowLoading(false);
          setSelected(defaultUpData);
          dispatch(getUpData(citySelected?._id || ''));
        } else {
          setModalShowLoading(false);
          setRejectedList(resp.data.response.rejectedVoter);
          setModalDuplicateList(true);
        }
      },
      (err: any) => {},
    );
  };

  const customTitle = (selected: UpData) => {
    return (
      <div className="custom-title-xls-db">
        <div
          className="circle-hamburger"
          onClick={() => {
            setSelected(defaultUpData);
            dispatch(getUpData(citySelected?._id || ''));
          }}
        >
          <BackIcon></BackIcon>
        </div>
        <p>
          <strong>{selected.name}</strong>
          {' - '}
          {' ' + t('settings.votersList.item.lbl.uploaded.by')}{' '}
          <strong>{selected.byName}</strong>
          {' ' + t('settings.votersList.item.lbl.uploaded.on')}:
          <strong>
            {' '}
            {capitalizeWords(
              moment(selected.createdDate)
                .locale(t('language').toString())
                .format(format),
            )}
          </strong>
        </p>
      </div>
    );
  };

  return (
    <div className="db-settings-box-body">
      {selected ? (
        <>
          <TopBarSelectors
            title={''}
            setSelected={setSelected}
            getData={(id: string) => {}}
            buttonFun={() => {
              finalizeUpData(selected._id);
            }}
            textButton={t('settings.votersList.btn.finish')}
            showBtn={true}
            showSelects={false}
            selectsToShow={0}
            customTitle={customTitle(selected)}
            showBack={true}
            setshowBack={() => {
              setSelected(defaultUpData);
              dispatch(getUpData(citySelected?._id || ''));
            }}
          ></TopBarSelectors>
          <ListTemporalVoters
            setFinalizeAvailable={setFinalizeAvailable}
            upData={selected}
          ></ListTemporalVoters>{' '}
        </>
      ) : (
        <>
          <TopSeparator></TopSeparator>
          {/* <TabComponent
            classAdditional={''}
            options={[
              'settings.votersList.tab.lbl.inReview',
              'settings.votersList.tab.lbl.uploaded',
              'settings.votersList.tab.lbl.approved',
            ]}
            amounts={[
              upDataList &&
                upDataList.filter((e) => {
                  return (
                    e.status === 1 &&
                    (filters.type === -1 || filters.type === 1) &&
                    (filters.search === '' || e.name.includes(filters.search))
                  );
                }).length,
              upDataList &&
                upDataList.filter((e) => {
                  return (
                    (e.status === 0 || e.status === 3) &&
                    (filters.type === -1 || filters.type === 0) &&
                    (filters.search === '' || e.name.includes(filters.search))
                  );
                }).length,
              upDataList &&
                upDataList.filter((e) => {
                  return (
                    e.status === 2 &&
                    (filters.type === -1 || filters.type === 2) &&
                    (filters.search === '' || e.name.includes(filters.search))
                  );
                }).length,
            ]}
            children={[ */}
          <div className="db-settings-box">
            <Container fluid className="header-table-team header-box-body">
              <Row noGutters>
                <div className="header-main">
                  <div className="header-main-title">
                    <h1>
                      {t('settings.votersList.lbl.files') +
                        ' [' +
                        (type.length > 0
                          ? upDataList.filter((e: any) => {
                              return type.includes(e.status);
                            }).length
                          : upDataList.length) +
                        ']'}
                    </h1>
                  </div>
                </div>
                <div className="xls-status">
                  <ButtonGroup
                    aria-label="xls-status-to-label"
                    className="xls-status-group"
                  >
                    <Button
                      variant="primary"
                      className={
                        type.indexOf(0) !== -1 && type.indexOf(1) !== -1
                          ? 'active'
                          : ''
                      }
                      onClick={() => {
                        setTypes((prev: number[]) => {
                          const index = type.indexOf(1);
                          const index2 = type.indexOf(0);
                          const array = [...prev];
                          if (index !== -1) {
                            array.splice(index, 1);
                          }
                          if (index2 !== -1) {
                            array.splice(index2, 1);
                          } else {
                            array.push(0);
                            array.push(1);
                          }
                          return array;
                        });
                      }}
                    >
                      {t('settings.votersList.tab.lbl.uploaded')}
                    </Button>
                    <Button
                      variant="primary"
                      className={type.indexOf(2) !== -1 ? 'active' : ''}
                      onClick={() => {
                        setTypes((prev: number[]) => {
                          const index = type.indexOf(2);
                          const array = [...prev];
                          if (index !== -1) {
                            array.splice(index, 1);
                          } else {
                            array.push(2);
                          }
                          return array;
                        });
                      }}
                    >
                      {t('settings.votersList.tab.lbl.inReview')}
                    </Button>

                    <Button
                      variant="primary"
                      className={type.indexOf(3) !== -1 ? 'active' : ''}
                      onClick={() => {
                        setTypes((prev: number[]) => {
                          const index = type.indexOf(3);
                          const array = [...prev];
                          if (index !== -1) {
                            array.splice(index, 1);
                          } else {
                            array.push(3);
                          }
                          return array;
                        });
                      }}
                    >
                      {t('settings.votersList.tab.lbl.approved')}
                    </Button>
                  </ButtonGroup>
                </div>
                <div className="search-action-box">
                  {' '}
                  <Row className="row-buttons">
                    <Button
                      className="template-button"
                      variant="outline-dark"
                      onClick={() => {
                        netWorking.downloadXls(
                          XLS_TYPE.VOTERS_TYPE,
                          citySelected?.name || '',
                        );
                      }}
                    >
                      <DownloadIcon></DownloadIcon>
                      {t('general.btn.template')}
                    </Button>
                  </Row>
                  <Button
                    disabled={false}
                    variant="primary"
                    onClick={() => {
                      setModalShowXls(true);
                    }}
                    className="search-add-button"
                  >
                    {t('settings.votersList.modal.lbl.uploadFile')}
                  </Button>
                </div>
              </Row>
            </Container>
            <div className="list-xls-uploads">
              {upDataList &&
                upDataList
                  .filter((e) => {
                    return type.length === 0 || type.indexOf(e.status) !== -1;
                  })
                  .sort((v1: any, v2: any) => v1.status - v2.status)
                  .map((e: UpData, i: number) => {
                    return (
                      <CardDb
                        key={e._id}
                        upData={e}
                        index={i}
                        setSelected={(value: UpData) => setSelected(value)}
                        openDelete={openDelete}
                        setModalShowApprove={setModalShowApprove}
                        setModalDeleteUpData={setModalDeleteUpData}
                      ></CardDb>
                    );
                  })}
            </div>
          </div>
        </>
      )}
      {modalShowXls && (
        <ModalAddFile
          modalShow={modalShowXls}
          setModalShow={setModalShowXls}
          successFun={() => {
            dispatch(getUpData(citySelected?._id || ''));
          }}
          setRouteFile={() => {}}
        ></ModalAddFile>
      )}
      {modalDeleteUpData && (
        <ModalDeleteItem
          modalShow={modalDeleteUpData}
          setModalShow={setModalDeleteUpData}
          toDelete={selectedToDelete}
          successFun={() => {
            dispatch(getUpData(citySelected?._id || ''));
          }}
        ></ModalDeleteItem>
      )}
      {modalShowApprove && (
        <ModalApproveFile
          modalShow={modalShowApprove}
          setModalShow={setModalShowApprove}
          successFun={() => {
            dispatch(getUpData(citySelected?._id || ''));
          }}
        ></ModalApproveFile>
      )}
      {modalShowLoading && (
        <LoadingModal
          show={modalShowLoading}
          onHide={setModalShowLoading}
        ></LoadingModal>
      )}
      {modalAlertDuplicate && (
        <ModalDuplicateAlert
          modalShow={modalAlertDuplicate}
          replacedFun={() => {
            setSelected(defaultUpData);
            dispatch(getUpData(citySelected?._id || ''));
          }}
          reviewFun={() => {
            setModalDuplicateList(true);
          }}
          upDataId={selected?._id}
          listRejected={rejectedList}
          setModalShow={setModalAlertDuplicate}
        ></ModalDuplicateAlert>
      )}
      {modalDuplicateList && (
        <ModalDuplicateList
          modalShow={modalDuplicateList}
          replacedFun={() => {
            setSelected(defaultUpData);
            dispatch(getUpData(citySelected?._id || ''));
          }}
          reviewFun={() => {}}
          setRejectedList={setRejectedList}
          upDataId={selected?._id}
          listRejected={rejectedList}
          setModalShow={setModalDuplicateList}
        ></ModalDuplicateList>
      )}
    </div>
  );
};

export default VotersListSettings;

interface CustomToggleProps {
  children?: any;
  eventKey?: any;
  amount?: any;
  setEvent?: any;
}

// const CustomToggle: FunctionComponent<CustomToggleProps> = ({
//   children,
//   eventKey,
//   amount,
//   setEvent,
// }: any) => {
//   const decoratedOnClick = () => {
//     setEvent(eventKey);
//   };

//   return (
//     <div onClick={decoratedOnClick} className="divider">
//       <h2>
//         {children}
//         <span>{amount}</span>
//       </h2>
//     </div>
//   );
// };
