import {
  AUDITORY_LOADING,
  AUDITORY_FAIL,
  AUDITORY_SUCCESS,
  AUDITORY_COUNT_SUCCESS,
  AUDITORY_SUCCESS_ONE,
  SearchVotersAuditory,
  DenyUnauditedVoter,
  ConfirmUnauditedVoter,
} from './AuditoryTypes';
import * as netWorking from '../netWorking';

export const postGetUnauditedVotersTotal = (data: SearchVotersAuditory) => {
  return async (dispatch: any) => {
    return await netWorking.post(
      netWorking.postGetUnauditedVotersTotal,
      data,
      (res: any) => {
        return dispatch({
          type: AUDITORY_COUNT_SUCCESS,
          payload: res.data.response,
        });
      },
      (error: any) => {
        return dispatch({
          type: AUDITORY_FAIL,
        });
      },
    );
  };
};

export const postVotersListByAddressElectionsUnauditedSearch = (
  data: SearchVotersAuditory,
) => {
  return async (dispatch: any) => {
    dispatch({
      type: AUDITORY_LOADING,
    });
    return await netWorking.post(
      netWorking.postVotersByAddressElectionsUnauditedSearch,
      data,
      (res: any) => {
        // dispatch({
        //   type: AUDITORY_COUNT_SUCCESS,
        //   payload: res.data.response,
        // });
        return dispatch({
          type: AUDITORY_SUCCESS,
          payload: res.data.response,
        });
      },
      (error: any) => {
        return dispatch({
          type: AUDITORY_FAIL,
        });
      },
    );
  };
};

export const getVoterAuditionById = (
  _id: string,
  successFun: any,
  errorFunc: any,
) => {
  return async (dispatch: any) => {
    dispatch({
      type: AUDITORY_LOADING,
    });
    return await netWorking.get(
      netWorking.getVoterDuplicatesById + _id,
      (res: any) => {
        console.log(res);
        successFun();
        return dispatch({
          type: AUDITORY_SUCCESS_ONE,
          payload: res.data.response,
        });
      },
      (error: any) => {
        errorFunc(error);
        return dispatch({
          type: AUDITORY_FAIL,
        });
      },
    );
  };
};

export const postDenyVoterById = (
  data: DenyUnauditedVoter,
  successFun: any,
) => {
  return async (dispatch: any) => {
    /*dispatch({
      type: AUDITORY_LOADING,
    });*/
    return await netWorking.post(
      netWorking.postDenyVoterById,
      data,
      (res: any) => {
        console.log(res);
        successFun();
        /*return dispatch({
          type: AUDITORY_LOADING,
        });*/
      },
      (error: any) => {
        return dispatch({
          type: AUDITORY_FAIL,
        });
      },
    );
  };
};

export const postConfirmVoterById = (
  data: ConfirmUnauditedVoter,
  successFun: any,
) => {
  return async (dispatch: any) => {
    dispatch({
      type: AUDITORY_LOADING,
    });
    return await netWorking.post(
      netWorking.postConfirmVoterById,
      data,
      (res: any) => {
        console.log(res);
        successFun();
      },
      (error: any) => {
        return dispatch({
          type: AUDITORY_FAIL,
        });
      },
    );
  };
};
