import React, { Fragment } from 'react';
import './style.scss';
import List from './components/List';
import TabComponent from '../../components/Tabs';
import CoverageMap from './components/CoverageMap';
import { useDispatch } from 'react-redux';
import { TAB_CHANGE } from '../../redux/teams/teamsTypes';

const TeamTerritorial: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  return (
    <Fragment>
      <TabComponent
        classAdditional={''}
        fun={(k: any) => {
          dispatch({
            type: TAB_CHANGE,
            tabChange: k,
          });
        }}
        options={['territorial.teams.tab.1', 'territorial.teams.tab.2']}
        children={[<List></List>, <CoverageMap></CoverageMap>]}
      ></TabComponent>
    </Fragment>
  );
};

export default TeamTerritorial;
