export const VOTERSBOX_LOADING = 'VOTERSBOX_LOADING';
export const VOTERSBOX_FAIL = 'VOTERSBOX_FAIL';
export const VOTERSBOX_SUCCESS = 'VOTERSBOX_SUCCESS';
export const VOTERSBOX_COUNT_SUCCESS = 'VOTERSBOX_COUNT_SUCCESS';
export const VOTERSBOX_SEARCH_SUCCESS = 'VOTERSBOX_SEARCH_SUCCESS';
export const VOTERSBOX_SELECT_SUCCESS = 'VOTERSBOX_SELECT_SUCCESS';
//================================================================
export const VOTERSBOX_MAP_LOADING = 'VOTERSBOX_MAP_LOADING';
export const VOTERSBOX_MAP_FAIL = 'VOTERSBOX_MAP_FAIL';
export const VOTERSBOX_MAP_SUCCESS = 'VOTERSBOX_MAP_SUCCESS';
export const VOTERSBOX_MAP_COUNT_SUCCESS = 'VOTERSBOX_MAP_COUNT_SUCCESS';
export const VOTERSBOX_MAP_SEARCH_SUCCESS = 'VOTERSBOX_MAP_SEARCH_SUCCESS';
//================================================================
export const VOTERSBOX_ELECTORAL_LOADING = 'VOTERSBOX_ELECTORAL_LOADING';
export const VOTERSBOX_ELECTORAL_FAIL = 'VOTERSBOX_ELECTORAL_FAIL';
export const VOTERSBOX_ELECTORAL_SUCCESS = 'VOTERSBOX_ELECTORAL_SUCCESS';
export const VOTERSBOX_ELECTORAL_COUNT_SUCCESS =
  'VOTERSBOX_ELECTORAL_COUNT_SUCCESS';
export const VOTERSBOX_ELECTORAL_SEARCH_SUCCESS =
  'VOTERSBOX_ELECTORAL_SEARCH_SUCCESS';
export const VOTERSBOX_ELECTORAL_SELECT_SUCCESS =
  'VOTERSBOX_ELECTORAL_SELECT_SUCCESS';
//================================================================
export const VOTERSBOX_ELECTORAL_MAP_LOADING =
  'VOTERSBOX_ELECTORAL_MAP_LOADING';
export const VOTERSBOX_ELECTORAL_MAP_FAIL = 'VOTERSBOX_ELECTORAL_MAP_FAIL';
export const VOTERSBOX_ELECTORAL_MAP_SUCCESS =
  'VOTERSBOX_ELECTORAL_MAP_SUCCESS';
export const VOTERSBOX_ELECTORAL_MAP_COUNT_SUCCESS =
  'VOTERSBOX_ELECTORAL_MAP_COUNT_SUCCESS';
export const VOTERSBOX_ELECTORAL_MAP_SELECT_SUCCESS =
  'VOTERSBOX_ELECTORAL_MAP_SELECT_SUCCESS';
export const VOTERSBOX_ELECTORAL_MAP_SEARCH_SUCCESS =
  'VOTERSBOX_ELECTORAL_MAP_SEARCH_SUCCESS';
//================================================================
export const TAB_CHANGE = 'TAB_CHANGE';
export const TAB_ELECTORAL_CHANGE = 'TAB_ELECTORAL_CHANGE';
//================================================================
export const VOTERSBOX_DDAY_LOADING = 'VOTERSBOX_DDAY_LOADING';
export const VOTERSBOX_DDAY_FAIL = 'VOTERSBOX_DDAY_FAIL';
export const VOTERSBOX_DDAY_ALL = 'VOTERSBOX_DDAY_ALL';
export const VOTERSBOX_DDAY_CREATE = 'VOTERSBOX_DDAY_CREATE';
export const VOTERSBOX_DDAY_UPDATE = 'VOTERSBOX_DDAY_UPDATE';
export const VOTERSBOX_DDAY_SEARCH_SUCCESS = 'VOTERSBOX_DDAY_SEARCH_SUCCESS';

export enum ADDRESS_TYPE {
  VOTERS_BOX,
  VOTERS_HOUSE,
  RC_RG_HOUSE,
}

export type VotersBox = {
  _id: string;
  addressId: string;
  bingoId: string;
  number: string;
  status: string;
  winningNumber: number;
};

export type VotersBoxElectoral = {
  voterBox: VotersBox;
  rg: any;
  rc: any;
  rc_substitute: any;
};

export type Address = {
  _id: string;
  street: string;
  addressType: number;
  interiorNumber: string;
  exteriorNumber: string;
  postalCode: string;
  suburb: string;
  geoLocation: any;
  sectionId: string;
  districtId: string;
  municipalityId: string;
};

export interface SearchVoterBoxData {
  cityLicenseId: string;
  municipalityId: string;
  districtId: string;
  sectionId: string;
  search: string;
  records: number;
  order: string;
  filters?: object;
  page: number;
}

export interface SearchNumberInUse {
  number: number;
  section: string;
  municipalityId: string;
}

export interface SearchVoterBoxMapData {
  cityLicenseId: string;
  municipalityId: string;
  districtId: string;
  sectionId: string;
  search: string;
  longitude: number;
  latitude: number;
  radius: number;
}

//================================================================
export interface VoterBoxLoading {
  type: typeof VOTERSBOX_LOADING;
}

export interface VoterBoxFail {
  type: typeof VOTERSBOX_FAIL;
}

export interface VoterBoxSuccess {
  type: typeof VOTERSBOX_SUCCESS;
  payload: object;
}

export interface VoterBoxCountSuccess {
  type: typeof VOTERSBOX_COUNT_SUCCESS;
  payload: object;
}

export interface VoterBoxSearchSuccess {
  type: typeof VOTERSBOX_SEARCH_SUCCESS;
  searchValue: string;
}

export interface VoterBoxSelectSuccess {
  type: typeof VOTERSBOX_SELECT_SUCCESS;
  selected: any;
}
//================================================================
export interface VoterBoxMapLoading {
  type: typeof VOTERSBOX_MAP_LOADING;
}

export interface VoterBoxMapFail {
  type: typeof VOTERSBOX_MAP_FAIL;
}

export interface VoterBoxMapSuccess {
  type: typeof VOTERSBOX_MAP_SUCCESS;
  payload: object;
}

export interface VoterBoxMapCountSuccess {
  type: typeof VOTERSBOX_MAP_COUNT_SUCCESS;
  payload: object;
}

export interface VoterBoxSearchMapSuccess {
  type: typeof VOTERSBOX_MAP_SEARCH_SUCCESS;
  searchValue: string;
}
//================================================================
export interface VoterBoxElectoralLoading {
  type: typeof VOTERSBOX_ELECTORAL_LOADING;
}

export interface VoterBoxElectoralFail {
  type: typeof VOTERSBOX_ELECTORAL_FAIL;
}

export interface VoterBoxElectoralSuccess {
  type: typeof VOTERSBOX_ELECTORAL_SUCCESS;
  payload: object;
}

export interface VoterBoxElectoralCountSuccess {
  type: typeof VOTERSBOX_ELECTORAL_COUNT_SUCCESS;
  payload: object;
}

export interface VoterBoxElectoralSearchSuccess {
  type: typeof VOTERSBOX_ELECTORAL_SEARCH_SUCCESS;
  searchValue: string;
}

export interface VoterBoxElectoralSelectSuccess {
  type: typeof VOTERSBOX_ELECTORAL_SELECT_SUCCESS;
  selected: any;
}
//================================================================
export interface VoterBoxElectoralMapLoading {
  type: typeof VOTERSBOX_ELECTORAL_MAP_LOADING;
}

export interface VoterBoxElectoralMapFail {
  type: typeof VOTERSBOX_ELECTORAL_MAP_FAIL;
}

export interface VoterBoxElectoralMapSuccess {
  type: typeof VOTERSBOX_ELECTORAL_MAP_SUCCESS;
  payload: object;
}

export interface VoterBoxElectoralMapCountSuccess {
  type: typeof VOTERSBOX_ELECTORAL_MAP_COUNT_SUCCESS;
  payload: object;
}

export interface VoterBoxElectoralMapSelectSuccess {
  type: typeof VOTERSBOX_ELECTORAL_MAP_SELECT_SUCCESS;
  selected: any;
}

export interface VoterBoxElectoralSearchMapSuccess {
  type: typeof VOTERSBOX_ELECTORAL_MAP_SEARCH_SUCCESS;
  searchValue: string;
}
//================================================================
export interface TabChange {
  type: typeof TAB_CHANGE;
  tabChange: any;
}

export interface TabElectoralChange {
  type: typeof TAB_ELECTORAL_CHANGE;
  tabChange: any;
}
//================================================================
export interface VoterBoxDdayLoading {
  type: typeof VOTERSBOX_LOADING;
}

export interface VoterBoxDdayFail {
  type: typeof VOTERSBOX_FAIL;
}

export interface VoterBoxDdaydAllSuccess {
  type: typeof VOTERSBOX_DDAY_ALL;
  data: any;
}

export interface VoterBoxDdayCreateSuccess {
  type: typeof VOTERSBOX_DDAY_CREATE;
  data: any;
}

export interface VoterBoxDdayUpdateSuccess {
  type: typeof VOTERSBOX_DDAY_UPDATE;
  data: any;
}

export interface VoterBoxDdaySearchSuccess {
  type: typeof VOTERSBOX_DDAY_SEARCH_SUCCESS;
  searchValue: string;
}

export type VotersBoxDispatchTypes =
  | VoterBoxLoading
  | VoterBoxFail
  | VoterBoxSuccess
  | VoterBoxCountSuccess
  | VoterBoxSearchSuccess
  | VoterBoxSelectSuccess
  //================================================================
  | VoterBoxMapLoading
  | VoterBoxMapFail
  | VoterBoxMapSuccess
  | VoterBoxMapCountSuccess
  | VoterBoxSearchMapSuccess
  //================================================================
  | VoterBoxElectoralLoading
  | VoterBoxElectoralFail
  | VoterBoxElectoralSuccess
  | VoterBoxElectoralCountSuccess
  | VoterBoxElectoralSearchSuccess
  | VoterBoxElectoralSelectSuccess
  //================================================================
  | VoterBoxElectoralMapLoading
  | VoterBoxElectoralMapFail
  | VoterBoxElectoralMapSuccess
  | VoterBoxElectoralMapCountSuccess
  | VoterBoxElectoralMapSelectSuccess
  | VoterBoxElectoralSearchMapSuccess
  //================================================================
  | TabChange
  | TabElectoralChange
  //================================================================
  | VoterBoxDdayLoading
  | VoterBoxDdayFail
  | VoterBoxDdaydAllSuccess
  | VoterBoxDdayCreateSuccess
  | VoterBoxDdayUpdateSuccess
  | VoterBoxDdaySearchSuccess;
