import React, { Fragment } from 'react';

const List: React.FunctionComponent = () => {
  return (
    <div className="voterbox-dday-container">
      <div className="img-voterbox"></div>
    </div>
  );
};

export default List;
