import React, { FunctionComponent, useState } from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import './style.scss';
import { useTranslation } from 'react-i18next';
import { LogsIcon } from '../assets/svg';
import i18next from 'i18next';

interface TabsProps {
  options: any;
  children: any;
  amounts?: any;
  module?: any;
  qty?: any;
  searchTrFun?: any;
  searchFun?: any;
  classAdditional?: string;
  fun?: any;
}

const TabComponent: FunctionComponent<TabsProps> = ({
  options,
  children,
  amounts,
  module,
  qty,
  searchTrFun = false,
  searchFun,
  classAdditional = '',
  fun,
}: any) => {
  const [key, setKey] = useState(options[0]);

  React.useEffect(() => {
    if (fun) {
      fun(key);
    }
  }, [key]);

  const { t } = useTranslation();
  return (
    <div className="tabs-box">
      {qty && (
        <div
          className={
            i18next.language === 'es'
              ? 'duplicates-circle-nav'
              : 'duplicates-circle-nav-en'
          }
        >
          {qty + 1}
        </div>
      )}
      <Tabs
        className={'tabs-container ' + classAdditional}
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k: any) => {
          setKey(k);
        }}
      >
        {children.map((e: any, index: number) => {
          window.dispatchEvent(new Event('resize'));
          return (
            <Tab
              className="tab-content-container"
              key={index}
              eventKey={options[index]}
              title={
                t(options[index]) +
                ' ' +
                (amounts ? '(' + amounts[index] + ')' : '')
              }
            >
              {e}
            </Tab>
          );
        })}
      </Tabs>
      {module && (
        <div
          className="history-box-trigger"
          onClick={() => {
            module();
          }}
        >
          <LogsIcon></LogsIcon>
        </div>
      )}
    </div>
  );
};

export default TabComponent;
