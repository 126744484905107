import {
  TRACKING_FAIL,
  TRACKING_SUCCESS,
  SearchCapturistTeamsByCity,
  TRACKING_DATA_FAIL,
  TRACKING_DATA_SUCCESS,
  TRACKING_TEAM_SUCCESS,
  TRACKING_COORDINATOR_CHANGE,
} from './trackingTypes';
import * as netWorking from '../netWorking';

export const invertCoordinates = (coordinates: any) => {
  const array1 = [];
  for (const value of coordinates) {
    const array2 = [];
    for (const value2 of value) {
      array2.push([value2[1], value2[0]]);
    }
    array1.push(array2);
  }
  return array1;
};

export function getCapturistTeamsData(users: string[]) {
  return async (dispatch: any) => {
    return await netWorking.post(
      netWorking.getCapturistTeamsData,
      {users},
      (res: any) => {
      return dispatch({
        type: TRACKING_TEAM_SUCCESS,
        payload: res.data.response,
      });
      },
      (error: any) => {},
    );
  };
}

export const postListCapturistTeamsByCity = (
  data: SearchCapturistTeamsByCity,
  successFun?: any,
) => {
  return async (dispatch: any) => {
    return await netWorking.post(
      netWorking.postByCityCapturistTeams,
      data,
      (res: any) => {
        if (successFun) {
          successFun();
        }
        return dispatch({
          type: TRACKING_DATA_SUCCESS,
          payload: res.data.response,
        });
      },
      (error: any) => {
        return dispatch({
          type: TRACKING_DATA_FAIL,
        });
      },
    );
  };
};

export const changeSelectedCoordinator = (coordinator: any, successFun?: any) => {
  return async (dispatch: any) => {
  dispatch({
      type: TRACKING_COORDINATOR_CHANGE,
      payload: coordinator,
  });
  if(successFun){
      successFun()
  }
  };
};

export const postGetTrackerByUserIdAndDate = (data: any) => {
  return async (dispatch: any) => {
    netWorking.post(
      netWorking.getTrackerByUserIdAndDate,
      data,
      (res: any) => {
        dispatch({
          type: TRACKING_SUCCESS,
          payload: res.data.response.tracker,
          payload2: res.data.response.result,
        });
      },
      (error: any) => {
        dispatch({
          type: TRACKING_FAIL,
        });
      },
    );
  };
};
