//import axios from 'axios';
import { Dispatch } from 'react';
import * as NetWorking from '../netWorking';
import { CoreLicenseDispatchTypes, IGNORE_FEATURE } from './coreLicenseTypes';

export const createCountry = (data: any, fun?: any, err?: any) => async (
  dispatch: Dispatch<CoreLicenseDispatchTypes>,
) => {
  NetWorking.post(
    NetWorking.createCountry,
    data,
    (res: any) => {
      fun();
    },
    (error: any) => {
      err(error);
      //   dispatch({
      //     type: SET_LOADING,
      //     value: false,
      //   });
    },
  );
};

export const createState = (data: any, _id: string, fun?: any) => async (
  dispatch: Dispatch<CoreLicenseDispatchTypes>,
) => {
  NetWorking.post(
    NetWorking.createState,
    data,
    (res: any) => {
      dispatch({
        type: IGNORE_FEATURE,
        featureId: _id,
      });
    },
    (error: any) => {
      //   dispatch({
      //     type: SET_LOADING,
      //     value: false,
      //   });
    },
  );
};

export const createMunicipality = (data: any, _id: string, fun?: any) => async (
  dispatch: Dispatch<CoreLicenseDispatchTypes>,
) => {
  NetWorking.post(
    NetWorking.createMunicipality,
    data,
    (res: any) => {
      dispatch({
        type: IGNORE_FEATURE,
        featureId: _id,
      });
    },
    (error: any) => {
      //   dispatch({
      //     type: SET_LOADING,
      //     value: false,
      //   });
    },
  );
};

export const deleteState = (_id: string, fun?: any) => async (
  dispatch: Dispatch<CoreLicenseDispatchTypes>,
) => {
  NetWorking.deleteRequest(
    NetWorking.deleteState + _id,
    (res: any) => {
      fun();
    },
    (error: any) => {
      fun();
      //   dispatch({
      //     type: SET_LOADING,
      //     value: false,
      //   });
    },
  );
};

export const deleteMunicipality = (_id: string, fun?: any) => async (
  dispatch: Dispatch<CoreLicenseDispatchTypes>,
) => {
  NetWorking.deleteRequest(
    NetWorking.deleteMunicipality + _id,
    (res: any) => {
      fun();
    },
    (error: any) => {
      fun();
      //   dispatch({
      //     type: SET_LOADING,
      //     value: false,
      //   });
    },
  );
};
