import React, { Fragment } from 'react';
import './style.scss';

import { useDispatch } from 'react-redux';
import { TAB_CHANGE } from '../../redux/votersBox/VotersBoxTypes';

import TabComponent from '../../components/Tabs';
import List from './components/List';
import Map from './components/Map';

const VotersBoxSettings: React.FunctionComponent = () => {
  const dispatch = useDispatch();

  return (
    <Fragment>
      <TabComponent
        classAdditional={''}
        fun={(k: any) => {
          dispatch({
            type: TAB_CHANGE,
            tabChange: k,
          });
        }}
        options={['settings.polling.tab.1', 'settings.polling.tab.2']}
        children={
          [<List></List>, <Map></Map>]}
      ></TabComponent>
    </Fragment>
  );
};

export default VotersBoxSettings;
