export const CAPTURISTS_LOADING = 'CAPTURISTS_LOADING';
export const CAPTURISTS_FAIL = 'CAPTURISTS_FAIL';
export const CAPTURISTS_SUCCESS = 'CAPTURISTS_SUCCESS';
export const CAPTURISTS_TEAM_SUCCESS = 'CAPTURISTS_TEAM_SUCCESS';
export const CAPTURISTS_COORDINATOR_CHANGE = 'CAPTURISTS_COORDINATOR_CHANGE';
export const CAPTURISTS_CAPTURIST_CHANGE = 'CAPTURISTS_CAPTURIST_CHANGE';

export type SearchCapturistTeamsByCity = {
    municipalityId: string;
    isFirst: boolean;
    teamType: number;
    projectFull: boolean;
};

export interface CapturistsLoading {
    type: typeof CAPTURISTS_LOADING;
}
export interface CapturistsFail {
    type: typeof CAPTURISTS_FAIL;
}
export interface CapturistsSuccess {
    type: typeof CAPTURISTS_SUCCESS;
    payload: object;
}
export interface CapturistsTeamSuccess {
  type: typeof CAPTURISTS_TEAM_SUCCESS;
  payload: object;
}
export interface CapturistsCoordinatorChange {
    type: typeof CAPTURISTS_COORDINATOR_CHANGE;
    payload: any;
}
export interface CapturistsCapturistChange {
    type: typeof CAPTURISTS_CAPTURIST_CHANGE;
    payload: any;
}

export type CapturistsDispatchTypes =
  | CapturistsLoading
  | CapturistsFail
  | CapturistsSuccess
  | CapturistsTeamSuccess
  | CapturistsCoordinatorChange
  | CapturistsCapturistChange;
