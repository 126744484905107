import {
  CoreDispatchTypes,
  STATE_LOADING,
  MUNICIPALITY_LOADING,
  DISTRICT_LOADING,
  SECTION_LOADING,
  STATE_FAIL,
  STATE_SUCCESS,
  MUNICIPALITY_FAIL,
  MUNICIPALITY_SUCCESS,
  DISTRICT_FAIL,
  DISTRICT_SUCCESS,
  SECTION_FAIL,
  SECTION_SUCCESS,
  State,
  Municipality,
  District,
  Section,
  SET_GLOBAL,
  statesLogin,
  citiesLogin,
  SET_GLOBAL_SELECTED,
  DecodedToken,
} from './CoreTypes';

interface DefaultStateI {
  stateLoading: boolean;
  municipalityLoading: boolean;
  districtLoading: boolean;
  sectionLoading: boolean;
  stateD?: State[];
  municipality?: Municipality[];
  district?: District[];
  section?: Section[];
  globalStateSelected?: statesLogin;
  globalMunicipalitySelected?: citiesLogin;
  globalStates?: statesLogin[];
  globalMunicipalities?: citiesLogin[];
  globalGeoCity?: any;
  userData?: DecodedToken;
}

const defaultState: DefaultStateI = {
  stateLoading: false,
  municipalityLoading: false,
  districtLoading: false,
  sectionLoading: false,
  stateD: [],
  municipality: [],
  district: [],
  section: [],
};

const coreReducer = (
  state: DefaultStateI = defaultState,
  action: CoreDispatchTypes,
): DefaultStateI => {
  switch (action.type) {
    case STATE_FAIL:
      return {
        ...state,
        stateLoading: true,
      };
    case STATE_LOADING:
      return {
        ...state,
        stateLoading: true,
      };
    case STATE_SUCCESS:
      return {
        ...state,
        stateLoading: false,
        stateD: [...action.payload],
      };
    case MUNICIPALITY_FAIL:
      return {
        ...state,
        municipalityLoading: true,
      };
    case MUNICIPALITY_LOADING:
      return {
        ...state,
        municipalityLoading: true,
      };
    case MUNICIPALITY_SUCCESS:
      Object.assign((state.municipality||[]), action.payload);
      return {
        ...state,
        municipalityLoading: false,
        municipality: action.payload,
      };
    case DISTRICT_FAIL:
      return {
        ...state,
        districtLoading: true,
      };
    case DISTRICT_LOADING:
      return {
        ...state,
        districtLoading: true,
      };
    case DISTRICT_SUCCESS:
      Object.assign((state.district||[]), action.payload);
      return {
        ...state,
        districtLoading: false,
        district: action.payload,
      };
    case SECTION_FAIL:
      return {
        ...state,
        sectionLoading: true,
      };
    case SECTION_LOADING:
      return {
        ...state,
        sectionLoading: true,
      };
    case SECTION_SUCCESS:
      Object.assign((state.section||[]), action.payload);
      return {
        ...state,
        sectionLoading: false,
        section: action.payload,
      };
    case SET_GLOBAL:
      return {
        ...state,
        globalStates: action.state,
        globalMunicipalities: action.municipality,
        userData: action.userData,
      };
    case SET_GLOBAL_SELECTED:
      return {
        ...state,
        globalStateSelected: action.state,
        globalMunicipalitySelected: action.municipality,
      };
    default:
      return state;
  }
};

export default coreReducer;

export const getStateId = () => {
  //todo: meanwhile
  return process.env.REACT_APP_STATE_ID || '5f57bc8235f97009e8018f23';
};
