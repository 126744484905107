import React, { useEffect } from 'react';
import { RouteProps } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import './style.scss';
import { useTranslation } from 'react-i18next';
interface SelectProps extends RouteProps {
  options: any;
  title: string;
  defaultSelector: string;
  horizontal: boolean;
  fun: any;
  funOnInit?: any;
  noOptionTitle?: string;
  additionalClass?: string;
}

const SelectorComponent: React.FunctionComponent<SelectProps> = ({
  title,
  defaultSelector = '-1',
  options,
  horizontal,
  fun,
  funOnInit = () => {},
  noOptionTitle,
  additionalClass = '',
}: any) => {
  useEffect(() => {
    funOnInit(defaultSelector);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { t } = useTranslation();
  return (
    <div
      className={
        horizontal
          ? 'selector-box-horizontal ' + additionalClass
          : 'selector-box ' + additionalClass
      }
    >
      <h2>{title}</h2>
      <Form.Control
        size="sm"
        as="select"
        defaultValue={defaultSelector}
        onChange={(data: any) => {
          fun(data.target.value);
        }}
      >
        {(noOptionTitle != null || options.length === 0) && (
          <option key={'0x0'} value={'-1'}>
            {noOptionTitle || t('select.lbl.none')}
          </option>
        )}
        {options.length >= 0 &&
          options.map((e: any, index: any) => {
            return (
              <option
                key={e._id}
                value={e._id}
                // selected={index === 0 ? true : false}
              >
                {(e.name)?e.name:(e.value)?e.value:e.firstName + ' ' + e.lastName}
              </option>
            );
          })}
      </Form.Control>
    </div>
  );
};

export default SelectorComponent;
