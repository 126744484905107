import React, { useEffect, FunctionComponent } from 'react';
import * as netWorking from '../../../redux/netWorking';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootStore } from '../../../redux/Store';

import { District, Section } from '../../../redux/core/CoreTypes';

import SelectorComponent from '../../../components/sub-component/selector';
import SelectorStatusComponent from './selectorStatus';
import Button from 'react-bootstrap/Button';
import { DownloadIcon } from '../../../assets/svg/index';

interface TopXlsProps {
  showName?: boolean;
  showSelects?: boolean;
  setSelected: any;
  selected: any;
  selectsToShow: number;
  showFilter?: boolean;
  filter?: any;
  setFilter?: any;
  showDownload?: boolean;
  downloadFun?: any;
  showUpload?: boolean;
  uploadFun?: any;
  total?: any;
}

const TopXlsBar: FunctionComponent<TopXlsProps> = ({
  showName = true,
  showSelects = true,
  setSelected,
  selected,
  selectsToShow,
  showFilter = true,
  filter,
  setFilter,
  showDownload = true,
  downloadFun,
  showUpload = true,
  uploadFun,
  total,
}: any) => {
  const { t } = useTranslation();

  const citySelected: any = useSelector((globalState: RootStore) => {
    return globalState.core.globalMunicipalitySelected;
  });

  const arrayDistrict: District[] = [];
  const arraySection: Section[] = [];

  const [loadData, setLoadData] = React.useState(false);
  const [district, setDistrict] = React.useState(arrayDistrict);
  const [section, setSection] = React.useState(arraySection);

  const getDist = (id: string) => {
    if (selectsToShow > 1) {
      netWorking.get(
        netWorking.getDistrict + id,
        (res: any) => {
          if (res.data.response.length > 0) {
            setDistrict(res.data.response);
            getSect(res.data.response[0]._id);
          } else {
            setDistrict(arrayDistrict);
            setSection(arraySection);
            getVoBo('', '');
          }
        },
        (error: any) => {
          setDistrict(arrayDistrict);
          setSection(arraySection);
          getVoBo('', '');
        },
      );
    }
  };
  const getSect = (id: string) => {
    if (selectsToShow > 2) {
      netWorking.get(
        netWorking.getSection + id,
        (res: any) => {
          if (res.data.response.length > 0) {
            setSection(res.data.response);
            getVoBo(res.data.response[0]._id, id);
          } else {
            setSection(arraySection);
            getVoBo('', '');
          }
        },
        (error: any) => {
          setSection(arraySection);
          getVoBo('', '');
        },
      );
    }
  };
  const getVoBo = (section: string, district: string) => {
    setSelected({
      section: section,
      district: district,
      municipality: citySelected?._id,
    });
  };

  useEffect(() => {
    if (!loadData && showSelects) {
      getDist(citySelected?._id || '');
      setLoadData(true);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadData]);

  useEffect(() => {
    if (showSelects) {
      getDist(citySelected?._id || '');
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [citySelected]);

  return (
    <div className="polling-electoral-top-bar">
      <div className="row-title">
        {showName && (
          <h1 className="count">
            {t('electoral.rcrg.table.rgs.polling') + ' [' + total + ']'}
          </h1>
        )}
      </div>
      <div className="row-selectors">
        <div className="row-actions">
          <div className="row-buttons">
            {showDownload && (
              <Button
                className="template-button"
                variant="outline-dark"
                onClick={downloadFun}
              >
                <DownloadIcon></DownloadIcon>
                {t('general.btn.template')}
              </Button>
            )}
          </div>
          {showUpload && (
            <Button
              variant="primary"
              onClick={uploadFun}
              className="upload-xls-button"
            >
              {t('general.btn.uploadXls')}
            </Button>
          )}
        </div>
        {showFilter && (
          <div className="no-assign-status">
            <SelectorStatusComponent
              title={t('electoral.polling.header.lbl.notAssign')}
              horizontal={true}
              filters={filter}
              fun={(newfilters: any) => {
                setFilter(newfilters);
              }}
              t={t}
            ></SelectorStatusComponent>
          </div>
        )}
        {selectsToShow > 0 && (
          <div className="col-selector col-padding">
            <SelectorComponent
              title={t('general.form.district')}
              defaultSelector={''}
              options={district}
              horizontal={true}
              fun={(value: string) => {
                getSect(value);
              }}
            ></SelectorComponent>
          </div>
        )}
        {selectsToShow > 1 && (
          <div className="col-selector col-padding">
            <SelectorComponent
              title={t('general.form.section')}
              defaultSelector={''}
              options={section}
              horizontal={true}
              fun={(value: string) => {
                getVoBo(value, selected.district);
              }}
            ></SelectorComponent>
          </div>
        )}
      </div>
    </div>
  );
};

export default TopXlsBar;
