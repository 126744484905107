import {
  AREAS_LOADING,
  AREAS_FAIL,
  AREAS_SUCCESS,
  AREAS_TEAM_SUCCESS,
  AREAS_COORDINATOR_CHANGE,
  AREAS_CAPTURIST_CHANGE,
  AreasDispatchTypes,
  AREAS_HEAT_MAP,
} from './areasTypes';
import { Team } from '../RgRc/RgRcTypes';
import { ROLE } from '../../utils/utils';

interface Coordinator {
  _id: string;
  name: string;
  members: Array<string>;
}

interface Capturist {
  _id: string;
  name: string;
}

interface AreasState {
  loading: boolean;
  teams: Array<Team>;
  teamData: Array<any>;
  selectedCoordinator: Coordinator;
  selectedCapturist?: Capturist;
  selectedTeam: Team | null | undefined;
  heatMap: any[];
}

const defaultState: AreasState = {
  loading: false,
  teams: [],
  teamData: [],
  selectedCoordinator: {
    _id: '',
    name: '',
    members: [],
  },
  selectedCapturist: {
    _id: '',
    name: '',
  },
  selectedTeam: null,
  heatMap: [],
};

const areasReducer = (
  state: AreasState = defaultState,
  action: AreasDispatchTypes,
): AreasState => {
  switch (action.type) {
    case AREAS_FAIL:
      return {
        ...state,
        loading: false,
      };
    case AREAS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case AREAS_SUCCESS:
      let coordinatorRole = state.selectedCoordinator._id;

      const dataTeamsSuccess: any = action.payload;
      const actualCoordinator = dataTeamsSuccess.teams.find(
        (team: any) => team.bossId === coordinatorRole,
      );

      const teams = [];
      teams.push(...(dataTeamsSuccess.teams || []));
      let coordinator =
        teams.length > 0
          ? !actualCoordinator && dataTeamsSuccess.isFirst
            ? {
                _id: teams[0].bossId,
                name: teams[0].name,
                members: teams[0].members,
              }
            : {
                _id: actualCoordinator.bossId,
                name: actualCoordinator.name,
                members: actualCoordinator.members,
              }
          : {
              _id: '',
              name: '',
              members: [],
            };
      const selectedTeam = teams.find(
        (team: Team) => team.bossId === coordinator._id,
      );
      return {
        ...state,
        teams,
        selectedCoordinator: {
          _id: coordinator._id,
          name: coordinator.name,
          members: coordinator.members,
        },
        selectedTeam,
        loading: false,
      };
    case AREAS_TEAM_SUCCESS:
      const dataTeamsTotalSuccess: any = action.payload;
      let selectedCap: any;
      if (
        localStorage.role === ROLE.CAPTURIST_BOSS &&
        dataTeamsTotalSuccess.length > 0
      ) {
        selectedCap = {
          _id: dataTeamsTotalSuccess[0]._id,
          name: dataTeamsTotalSuccess[0].firstName,
        };
      }
      return {
        ...state,
        teamData: dataTeamsTotalSuccess,
        selectedCapturist: selectedCap,
      };
    case AREAS_COORDINATOR_CHANGE:
      const selectedCoordinator = { ...action.payload };
      const selectedTeam2 = state.teams.find(
        (team) => team.bossId === selectedCoordinator._id,
      );

      return {
        ...state,
        selectedCoordinator,
        selectedTeam: selectedTeam2,
        loading: false,
      };
    case AREAS_CAPTURIST_CHANGE:
      const selectedCapturist = { ...action.payload };
      return {
        ...state,
        selectedCapturist,
        loading: false,
      };
    case AREAS_HEAT_MAP:
      return {
        ...state,
        heatMap: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};

export default areasReducer;
