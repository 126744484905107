import React from 'react';
import { RouteProps } from 'react-router-dom';
import './style.scss';

interface ItemProps extends RouteProps {
  children: any;
  className?: string;
}

const ItemListFilterComponent: React.FunctionComponent<ItemProps> = ({
  children,
  className = '',
}: any) => <div className={className + ' list-item-filter'}>{children}</div>;

export default ItemListFilterComponent;
