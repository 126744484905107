import React, { useState, useEffect, FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import '../style.scss';
import L, { LatLngExpression } from 'leaflet';
import { Map } from 'react-leaflet';
import LayerControlComponent from '../../../../../components/sub-component/layerControl';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../../../redux/Store';
import { RiArrowLeftLine } from 'react-icons/ri';
import { useHistory } from 'react-router-dom';
import {
  SUPER_SHARED_COUNTRIES,
  SUPER_SHARED_COUNTRIES_CREATION_SELECTED,
} from '../../../../../utils/paths';
import LayoutBodyComponent from '../../../../../components/LayoutBody';
import Button from 'react-bootstrap/Button';
import {
  MODE_TYPE,
  SET_STATES_DATA_TO_CREATE,
} from '../../../../../redux/coreLicense/coreLicenseTypes';

// tslint:disable-next-line: prefer-const
let emptyEdit: any;
const emptyArray: string[] = [];
const color = `rgba(0,135,239,0.2)`;
const colorBase = `rgba(0,135,239,1)`;
const colorUnSelected = `rgba(177, 174, 183,0.5)`;
const colorBaseUnSelected = `rgba(35, 44, 62, .6)`;

const SelectionMap: FunctionComponent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const redirect = useHistory();

  const [featureArray, setFeaturesArray] = useState(emptyArray);
  const [bounds, setBounds] = useState(emptyEdit);
  const leafletMapRef = React.useRef<Map>(null);
  const defaultPosition = {
    lat: 32.7157431,
    lng: -117.1888535,
    zoom: 11,
  };

  const statesCollections = useSelector((globalState: RootStore) => {
    return globalState.coreLicense.statesCollections;
  });

  const citiesCollections = useSelector((globalState: RootStore) => {
    return globalState.coreLicense.citiesCollections;
  });

  const countrySelected = useSelector((globalState: RootStore) => {
    return globalState.campaignsManager.selectedCountry;
  });

  const MODE = useSelector((globalState: RootStore) => {
    return globalState.coreLicense.mode;
  });

  const featuresGl = useSelector((globalState: RootStore) => {
    return globalState.coreLicense.features;
  });

  useEffect(() => {
    if (citiesCollections !== undefined) {
      AddLayer(citiesCollections);
    }
    if (statesCollections !== undefined) {
      AddLayer(statesCollections);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [citiesCollections, statesCollections]);

  const AddLayer = (polygon: any) => {
    const mapElement = leafletMapRef.current?.leafletElement;
    if (mapElement && polygon !== null) {
      let bounds = L.geoJSON(polygon, {
        style: {
          weight: 2,
          opacity: 1,
          color: colorBaseUnSelected,
          dashArray: '',
          fillOpacity: 1,
          fillColor: colorUnSelected,
        },
        onEachFeature: (feature, layer) => {
          layer.addTo(mapElement);
          layer.on('pm:edit', (e) => {
            if (e.target && e.target.pm) {
              //const lay = e.layer.toGeoJSON();
              // setPolygonData(lay);
            }
          });
          layer.on('pm:cut', function (e) {
            if (e.target && e.target.pm) {
              layer.off('pm:edit');
            }
          });
          layer.on('click', function (e) {
            // setPm(e.target.pm);
            setBounds(undefined);
            const fetArray = featureArray;
            const index = fetArray.indexOf(e.target.feature._id);
            if (index !== -1) {
              fetArray.splice(index, 1);
              e.target.setStyle({
                weight: 2,
                opacity: 1,
                color: colorBaseUnSelected,
                dashArray: '',
                fillOpacity: 1,
                fillColor: colorUnSelected,
              });
            } else {
              fetArray.push(e.target.feature._id);
              e.target.setStyle({
                weight: 2,
                opacity: 1,
                color: colorBase,
                dashArray: '',
                fillOpacity: 1,
                fillColor: color,
              });
            }
            setFeaturesArray(fetArray);
            if (MODE === MODE_TYPE.STATES) {
              const features = statesCollections?.features.filter(
                (e) => fetArray.indexOf(e._id) !== -1,
              );
              console.log(featureArray);
              console.log(features);
              dispatch({
                type: SET_STATES_DATA_TO_CREATE,
                features,
              });
            }
            if (MODE === MODE_TYPE.CITIES) {
              const features = citiesCollections?.features.filter(
                (e) => fetArray.indexOf(e._id) !== -1,
              );
              console.log(featureArray);
              console.log(features);
              dispatch({
                type: SET_STATES_DATA_TO_CREATE,
                features,
              });
            }
            e.target.pm.disable();
            // setEditMode(true);
          });
        },
      }).getBounds();
      setBounds(bounds);
      return bounds;
    } else return null;
  };

  const sendToNextStep = () => {
    redirect.push(SUPER_SHARED_COUNTRIES_CREATION_SELECTED);
  };

  const getCount = () => {
    return featuresGl?.length || 0;
  };

  useEffect(() => {
    if (leafletMapRef.current) {
      const mapElement = leafletMapRef.current.leafletElement;

      (mapElement as any).pm.setGlobalOptions({ pmIgnore: false });
      (mapElement as any).pm.setLang('es');

      (mapElement as any).pm.addControls({
        drawMarker: false,
        drawCircle: false,
        drawCircleMarker: false,
        drawPolyline: false,
        drawRectangle: false,
        drawPolygon: false,
        editMode: false,
        dragMode: false,
        cutPolygon: false,
        removalMode: false,
        zoomMode: false,
      });
      mapElement.off('pm:create');
      mapElement.on('pm:create', (e: any) => {
        if (e.target && e.target.pm) {
          // addNewLayer(e.layer.toGeoJSON());
        }
      });
      mapElement.off('pm:remove');
      mapElement.on('pm:remove', (e: any) => {
        // (mapElement as any).pm.addControls({
        //   drawPolygon: true,
        //   removalMode: false,
        // });
        // onPolygonRemoved();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leafletMapRef.current]);

  return (
    <>
      <LayoutBodyComponent>
        <div className="body-country license-view selection-view">
          <div className="top-divider">
            <div className="column-divider">
              <div className="city-divider">
                <h3>{countrySelected?.country.name + ' >'}</h3>
              </div>
              <div className="license-divider">
                <RiArrowLeftLine
                  onClick={() => {
                    // dispatch({
                    //   type: SET_SELECTED_LICENSE_CITY,
                    //   licenseSelected: undefined,
                    //   cityLicense: undefined,
                    // });
                    redirect.push(SUPER_SHARED_COUNTRIES);
                  }}
                ></RiArrowLeftLine>
                <div className="name-and-party">
                  <h1>
                    {MODE === MODE_TYPE.CITIES ? 'New Cities' : 'New States'}
                  </h1>
                </div>
              </div>
            </div>
            <div className="box-to-next-step">
              <h3>{getCount()}{' '}{t('admin.modal.lbl.selected')}</h3>
              <Button
                className="c-button"
                variant="primary"
                onClick={sendToNextStep}
              >
                {t('admin.modal.lbl.nextStep')}
              </Button>
            </div>
          </div>
          <div className="body-map-selection">
            <div className="map-box">
              <Map
                ref={leafletMapRef}
                center={
                  [defaultPosition.lat, defaultPosition.lng] as LatLngExpression
                }
                zoom={defaultPosition.zoom}
                bounds={bounds}
                boundsOptions={{ padding: [0, 0] }}
                zoomControl={false}
              >
                <LayerControlComponent
                  checked={'Street'}
                ></LayerControlComponent>
              </Map>
            </div>
          </div>
        </div>
      </LayoutBodyComponent>
    </>
  );
};

export default SelectionMap;
