import React, { FunctionComponent, useEffect } from 'react';
import { RouteProps } from 'react-router-dom';
import { Map } from 'react-leaflet';
import { useTranslation } from 'react-i18next';
import LayerControlComponent from '../../../components/sub-component/layerControl';
import ListFilterCustomComponent from '../../../components/ListFilterCustom';
import LayoutBodyComponent from '../../../components/LayoutBody';
import { ROLE } from '../../../utils/utils';
import { useSelector } from 'react-redux';
import { RootStore } from '../../../redux/Store';
import L from 'leaflet';
import AreaProgressBoxComponent from './areaProgressBox';

interface ItemProps extends RouteProps {
  transformData: any;
  fetchTrackers: any;
  date: any;
  generalAreas: any;
  generalAreasData: any;
}

const StatisticsTabComponent: FunctionComponent<ItemProps> = ({
  transformData,
  fetchTrackers,
  date,
  generalAreas,
  generalAreasData,
}: ItemProps) => {
  const { t } = useTranslation();
  const leafletMapRef = React.useRef<Map>(null);
  const role = localStorage.role;
  const citySelected = useSelector((globalState: RootStore) => {
    return globalState.core.globalMunicipalitySelected;
  });
  const defaultPosition = {
    lat: 32.7157431,
    lng: -117.1888535,
    zoom: 11,
  };
  const position: [number, number] =
    citySelected?.defaultCenter !== undefined
      ? citySelected?.defaultCenter
      : [defaultPosition.lat, defaultPosition.lng];

  const [center, setCenter] = React.useState(position);
  const [zoom, setZoom] = React.useState(
    citySelected?.defaultZoom !== undefined
      ? citySelected?.defaultZoom
      : defaultPosition.zoom,
  );

  useEffect(() => {
    if (citySelected) {
      setCenter(
        citySelected?.defaultCenter !== undefined
          ? citySelected?.defaultCenter
          : [defaultPosition.lat, defaultPosition.lng],
      );
      setZoom(
        citySelected?.defaultZoom !== undefined
          ? citySelected?.defaultZoom
          : defaultPosition.zoom,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [citySelected]);

  const getTotalCaptures = () => {
    let total = 0;
    generalAreas.forEach((e: any) => {
      if (e.dataOfArea) {
        total = total + e.dataOfArea[0].toAudit;
      }
    });
    return total;
  };

  const getReviewCountByArea = (areaId: string) => {
    let count = 0;
    if (generalAreasData.inReview) {
      for (const value of generalAreasData.inReview) {
        if (value._id.areaId === areaId) {
          count = count + value.count;
        }
      }
    }
    return count;
  };

  const getAuditedFailCountByArea = (areaId: string) => {
    let count = 0;
    if (generalAreasData.auditedFail) {
      for (const value of generalAreasData.auditedFail) {
        if (value._id.areaId === areaId) {
          count = count + value.count;
        }
      }
    }
    return count;
  };

  const getWithProblemCountByArea = (areaId: string) => {
    let count = 0;
    if (generalAreasData.withProblem) {
      for (const value of generalAreasData.withProblem) {
        if (value._id.areaId === areaId) {
          count = count + value.count;
        }
      }
    }
    return count;
  };

  useEffect(() => {
    const mapElement = leafletMapRef.current?.leafletElement;
    if (mapElement) {
      mapElement.eachLayer((layer: any) => {
        if (layer._url === undefined && layer._container === undefined)
          layer.remove();
      });
      generalAreas.forEach((e: any, index: number) => {
        L.geoJSON(
          {
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              coordinates: e.area.coordinates,
            },
          } as any,
          {
            style: {
              weight: 2,
              opacity: 1,
              color: e.color,
              dashArray: '',
              fillOpacity: 1,
              fillColor: e.color,
            },
            onEachFeature: (feature, layer) => {
              layer
                .addTo(mapElement)
                .bindTooltip(String(index + 1), {
                  permanent: true,
                  direction: 'auto',
                  className: 'my-labels',
                })
                .openTooltip();
              layer.addTo(mapElement);
            },
          },
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generalAreas]);

  return (
    <LayoutBodyComponent>
      <ListFilterCustomComponent
        direction={'left'}
        className={'tracker-filter-list'}
        size={'lg-filter'}
        onHide={() => {
          setTimeout(function () {
            window.dispatchEvent(new Event('resize'));
          }, 301);
        }}
      >
        <>
          {role === ROLE.CAPTURIST_BOSS && (
            /*<ItemSelectorComponent
              text={t('territorial.tracker.lbl.volunteers')}
              defaultSelector={capturistId}
              data={transformData(teamData)}
              setSelected={(value: string) => {
                setCapturistId(value);
                fetchTrackers(value, date);
                // console.log(value);
              }}
            ></ItemSelectorComponent>*/
            <></>
          )}
          <div className="statistics-tab-box">
            <div className="quantity-box">
              <div className="data-box">
                <strong>{t('territorial.tracker.lbl.captures')}</strong>
                <p>
                  {getTotalCaptures() || 0}{' '}
                  {t('territorial.tracker.lbl.persons')}
                </p>
              </div>
              {/* <div className="data-box">
                <strong>{t('territorial.tracker.lbl.kmTraveled')}</strong>
                <p>
                  {stepsToKm(
                    trackings !== undefined && trackings.length > 0
                      ? trackings[0].steps
                      : 0,
                  )}{' '}
                  {' ' + t('territorial.tracker.lbl.km')}
                </p>
              </div> */}
            </div>
            {generalAreas?.map((e: any, i: number) => {
              return (
                <AreaProgressBoxComponent
                  key={i}
                  area={e}
                  index={i}
                  getAuditedFailCountByArea={getAuditedFailCountByArea}
                  getWithProblemCountByArea={getWithProblemCountByArea}
                  getReviewCountByArea={getReviewCountByArea}
                ></AreaProgressBoxComponent>
              );
            })}
            {/* <div className="bar-graph-box">
              <div className="data-box">
                <strong>Lugar dentro del Equipo</strong>
                <p>
                  Lugar{' '}
                  <strong>
                    {statistics.find(
                      (e: any) => e._id === capturistId,
                    )?.position || '0°'}
                  </strong>{' '}
                  de{' '}
                  {statistics[statistics.length - 1]?.position ||
                    '0°'}
                </p>
                <p>{getTotalCount()} personas Capturadas hasta hoy</p>
              </div>
              <MyResponsiveBar data={statistics}></MyResponsiveBar>
            </div> */}
          </div>
        </>
      </ListFilterCustomComponent>
      <div className="map-box-box">
        <Map
          center={center}
          zoomControl={false}
          zoom={zoom}
          ref={leafletMapRef}
        >
          <LayerControlComponent></LayerControlComponent>
        </Map>
      </div>
    </LayoutBodyComponent>
  );
};

export default StatisticsTabComponent;
