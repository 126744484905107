import React, { Fragment } from 'react';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import {
  FemaleIcon,
  MaleIcon,
  FacebookIcon,
  InstagramIcon,
  TiktokIcon,
} from '../../../assets/svg';
import { AiFillTwitterCircle } from 'react-icons/ai';
import { DiOpera } from 'react-icons/di';

import '../voterStyle.scss';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';
import { SEX } from '../../../redux/RgRc/RgRcTypes';
import { IoIosTransgender } from 'react-icons/io';
import { formatPhoneNumber } from '../../../utils/utils';
import photoIdPlaceHolderFront from '../../../assets/img/photo-id-placeholder-front.png';
import photoIdPlaceHolderFrontNone from '../../../assets/img/photo-id-placeholder-front-none.png';

export enum SOCIAL_NETWORK {
  UNDEFINED,
  FACEBOOK,
  INSTAGRAM,
  TIKTOK,
  TWITTER,
  ONLYFANS,
}

interface duplicateCardProps {
  voterData: any;
  number: number;
  t: any;
}

const DuplicateCardComponent: React.FunctionComponent<duplicateCardProps> = ({
  voterData,
  number,
  t,
}: any) => {
  const { i18n } = useTranslation();

  const nextVotingElections = 2021;

  const getAddress = (address: any) => {
    let result = '';
    const newAddress = address;
    if (newAddress) {
      if (newAddress.street && newAddress.street !== '')
        result = newAddress.street;
      if (newAddress.interiorNumber && newAddress.interiorNumber !== '')
        result = result + `, i ${newAddress.interiorNumber}`;
      if (newAddress.exteriorNumber && newAddress.exteriorNumber !== '')
        result = result + `, ext ${newAddress.exteriorNumber}`;
      if (newAddress.suburb && newAddress.suburb !== '')
        result = result + `, ${newAddress.suburb}`;
      if (newAddress.postalCode && newAddress.postalCode !== '')
        result = result + `, ${newAddress.postalCode}`;
      if (newAddress.municipality) {
        result = result + `, ${newAddress.municipality.name}`;
      }
      // if (newAddress.district) {
      //   result = result + `, ${newAddress.district.name}`;
      // }
      // if (newAddress.section) {
      //   result = result + `, ${newAddress.section.name}`;
      // }
    }
    return result;
  };
  const getSexWithIcon = (network: number): any => {
    let sexImg;
    let sexName;
    switch (Number(network)) {
      case SEX.MAN:
        sexName = <span> {t('territorial.voters.lbl.sexMale')} </span>;
        sexImg = <MaleIcon className="icon"></MaleIcon>;
        break;
      case SEX.WOMAN:
        sexName = <span> {t('territorial.voters.lbl.sexFemale')} </span>;
        sexImg = <FemaleIcon className="icon"></FemaleIcon>;
        break;
      case SEX.APACHE_HELICOPTER:
        sexName = <span> {t('territorial.voters.lbl.sexTrans')}</span>;
        sexImg = <IoIosTransgender className="icon"></IoIosTransgender>;
        break;
      default:
        break;
    }

    return (
      <Fragment>
        {sexName}
        {sexImg}
      </Fragment>
    );
  };
  const getInitials = (value: string) => {
    let result = '*';
    if (value) {
      const array = value.split(' ');
      result = '';
      array.forEach((e) => {
        result = result + e.slice(0, 1);
      });
    }
    return result;
  };
  const getSocialNetworkIcon = (network: number): any => {
    let networkImg;
    switch (Number(network)) {
      case SOCIAL_NETWORK.FACEBOOK:
        networkImg = <FacebookIcon className="icon-social"></FacebookIcon>;
        break;
      case SOCIAL_NETWORK.INSTAGRAM:
        networkImg = <InstagramIcon className="icon-social"></InstagramIcon>;
        break;
      case SOCIAL_NETWORK.TIKTOK:
        networkImg = <TiktokIcon className="icon-social"></TiktokIcon>;
        break;
      case SOCIAL_NETWORK.TWITTER:
        networkImg = <AiFillTwitterCircle className="icon-social" />;
        break;
      case SOCIAL_NETWORK.ONLYFANS:
        networkImg = <DiOpera className="icon-social" />;
        break;
      default:
        break;
    }

    return networkImg;
  };
  const getIneStatus = (voter: any) => {
    let status = t('territorial.voters.lbl.missingData');
    let bg = '';
    let newDob = moment(voter.ineExpirationDate).format('YYYY');
    let ineExpiration = +newDob;
    let electionsDate = +nextVotingElections;

    if (ineExpiration >= electionsDate) {
      status = t('territorial.voters.lbl.valid');
      bg = 'valid';
    } else {
      status = t('territorial.voters.lbl.expired');
      bg = 'expired';
    }

    return (
      <div className={`ine-status-duplicate ${bg}`}>
        <p>{status}</p>
      </div>
    );
  };
  const getCaptureDate = (create: any) => {
    let lang = i18n.language;
    const newDob = moment(create).locale(lang).format('DD MMM YYYY, h:mma.');
    return newDob;
  };

  const getVoting2 = (voter: any) => {
    let result = '';
    if (voter) {
      if (voter.districtVote) {
        result = result + `${voter.districtVote}`;
      }
      if (voter.sectionVote) {
        if (result === '') {
          result = result + `${voter.sectionVote}`;
        } else result = result + `, ${voter.sectionVote}`;
      }
    }
    return result;
  };
  return (
    <div className="duplicate-card">
      <div className="header-duplicate">
        <div className="circle-number">{number}</div>
        <h2 className="name-duplicate">
          {voterData.firstName?.toUpperCase()}{' '}
          {voterData.lastName?.toUpperCase()}
        </h2>
      </div>
      <div className="body-duplicate">
        <Row>
          <Col sm={4} md={4} xl={2} lg={2} className="duplicate-data-box">
            <h3 className="title"> {t('territorial.voters.lbl.name')} </h3>
            <div className="content">{voterData.firstName}</div>
          </Col>
          <Col sm={4} md={4} xl={2} lg={2} className="duplicate-data-box">
            <h3 className="title">{t('territorial.voters.lbl.lastname')}</h3>
            <div className="content">{voterData.lastName}</div>
          </Col>
          <Col sm={4} md={4} xl={2} lg={2} className="duplicate-data-box">
            <h3 className="title">
              {t('territorial.voters.lbl.addressOfHome')}
            </h3>
            <div className="content">{getAddress(voterData.homeAddress)}</div>
          </Col>
          <Col sm={4} md={4} xl={2} lg={2} className="duplicate-data-box">
            <h3 className="title">
              {t('territorial.voters.lbl.addressOfVoting')}
            </h3>
            <div className="content">{getVoting2(voterData)}.</div>
          </Col>
          <Col sm={4} md={4} xl={2} lg={2} className="duplicate-data-box">
            <h3 className="title">{t('territorial.voters.lbl.phone')}</h3>
            <div className="content">
              {formatPhoneNumber(voterData.cellphone)}
            </div>
          </Col>
          <Col sm={4} md={4} xl={2} lg={2} className="duplicate-data-box">
            <h3 className="title"> {t('territorial.voters.lbl.sex')} </h3>
            <div className="content">{getSexWithIcon(voterData.sex)}</div>
          </Col>

          <Col sm={4} md={4} xl={2} lg={2} className="duplicate-data-box">
            <h3 className="title">{t('territorial.voters.lbl.capturedBy')}</h3>
            <div className="content">{voterData.capturer || '-'}</div>
          </Col>
          <Col sm={4} md={4} xl={2} lg={2} className="duplicate-data-box">
            <h3 className="title">{t('territorial.voters.lbl.captured')}</h3>
            <div className="content">{getCaptureDate(voterData.created)}</div>
          </Col>
          <Col sm={4} md={4} xl={2} lg={2} className="duplicate-data-box">
            <h3 className="title">{t('territorial.voters.lbl.photosId')}</h3>
            <div className="content">
              <Col xl={12} lg={12} md={12} sm={12} className="item with-ine">
                <Image
                  className="photo-id"
                  src={
                    !voterData.ineFrontPath
                      ? photoIdPlaceHolderFrontNone
                      : photoIdPlaceHolderFront
                  }
                  fluid
                  onClick={() => {}}
                />
                <div className="content">{getIneStatus(voterData)}</div>
              </Col>
            </div>
          </Col>

          {/* <Col sm={4} md={4} xl={4} lg={4} className="duplicate-data-box">
              <h3 className="title">{t("territorial.voters.lbl.questions")}</h3>
              <div className="content">
                <Col
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  className="item with-vote"
                >
                  <p className="title">
                    {t('territorial.voters.lbl.forWho')}
                  </p>
                  <p className="content">
                    <span className="initials">
                      {getInitials(voterData.forWho)}
                    </span>
                  </p>
                </Col>
                <Col
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  className="item with-vote"
                >
                  <p className="title">
                    {t('territorial.voters.lbl.willYouVote')}
                  </p>
                  <p className="content">
                    <span className="initials">
                      {getInitials(voterData.forWho)}
                    </span>
                  </p>
                </Col>
              </div>
          </Col> */}
          <Col sm={4} md={2} xl={2} lg={2} className="duplicate-data-box">
            <h3 className="title">
              {t('territorial.voters.lbl.socialNetworks')}
            </h3>
            <div className="content">
              {voterData.socialNetworks && voterData.socialNetworks.length && (
                <Fragment>
                  {voterData.socialNetworks?.map((social: any, index: any) => {
                    return (
                      <Col
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        className="item with-social"
                        key={index}
                      >
                        {getSocialNetworkIcon(social.network)}
                        <p className="content"> {social.username} </p>
                      </Col>
                    );
                  })}
                </Fragment>
              )}
              {!voterData.socialNetworks && (
                <Fragment>
                  <Col
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    className="item with-social"
                  >
                    <FacebookIcon className="icon-social"></FacebookIcon>
                    <p className="content"> {'@'} </p>
                  </Col>
                  <Col
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    className="item with-social"
                  >
                    <InstagramIcon className="icon-social"></InstagramIcon>
                    <p className="content"> {'@'} </p>
                  </Col>
                  <Col
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    className="item with-social"
                  >
                    <AiFillTwitterCircle className="icon-social" />
                    <p className="content"> {'@'} </p>
                  </Col>
                </Fragment>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default DuplicateCardComponent;
