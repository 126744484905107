import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import StaticModal from '../../../dialogs/StaticModal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import * as netWorking from '../../../redux/netWorking';
import Spinner from 'react-bootstrap/Spinner';

interface ModalDeleteRcRgProps {
  modalShow: any;
  setModalShow: any;
  userId: any;
}

export const CodeGenerationDialog: React.FunctionComponent<ModalDeleteRcRgProps> =
  ({ modalShow, setModalShow, userId }: any) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [code, setCode] = React.useState(
      t('general.table.generate.code.no.code'),
    );
    //   const submitFun = () => {
    //     // setModalShow(false);
    //     netWorking.post(
    //       netWorking.generateCode,
    //       { _id: '' },
    //       (res: any) => {
    //         // successFun();
    //       },
    //       (error: any) => {},
    //     );
    //   };
    const [counter, setCounter] = React.useState(5);
    const [toggle, setToggle] = React.useState(false);

    React.useEffect(() => {
      if (counter > 0) setTimeout(() => setCounter(counter - 1), 1000);
      if (counter <= 0) {
        if (toggle) {
          setToggle(false);
          setModalShow(false);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [counter, toggle]);

    useEffect(() => {
      if (modalShow)
        netWorking.post(
          netWorking.generateCode,
          { _id: userId },
          (res: any) => {
            // successFun();
            setToggle(true);
            setCounter(5);
            setCode(res.data.response.code);
          },
          (error: any) => {},
        );
    }, [modalShow]);

    const getPorcentaje = (counter: any) => {
      const progress = ((5 - counter) / 5) * 100;
      return Math.round(progress);
    };

    return (
      <StaticModal
        show={modalShow}
        title={t('general.table.generate.code')}
        closeText={t('submit.close') + ' (' + counter + ')'}
        confirmText={''}
        showConfirmBtn={false}
        onHide={() => {
          setToggle(false);
          setModalShow(false);
        }}
        submitFun={() => {}}
        validBtn={false}
        size="md"
        sizeClass="md modal-rc"
      >
        <>
          <Container className="body-modal code-generation-body">
            <Row noGutters className="modal-text-row">
              {/* <div className="text-box">
                <p>
                  {t('general.dialog.generate.code.message', { counter: 5 })}
                </p>
              </div> */}
              <Col xs={12} className="code-col">
                <h2 className="text-description">{code}</h2>
                {/* <div className="text-description">{getNames()}</div> */}
              </Col>{' '}
              {/* <div className="text2-box">
                <p>
                  {t('general.dialog.generate.code.remaining', {
                    counter: counter,
                  })}
                </p>
              </div> */}
              <div className="loading-bar">
                <div
                  className="bar"
                  style={{ width: `${getPorcentaje(counter)}%` }}
                ></div>
              </div>
            </Row>
          </Container>
        </>
      </StaticModal>
    );
  };

export default CodeGenerationDialog;
