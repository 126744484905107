import { Dispatch } from 'redux';
import {
  CoreDispatchTypes,
  STATE_LOADING,
  MUNICIPALITY_LOADING,
  DISTRICT_LOADING,
  SECTION_LOADING,
  STATE_FAIL,
  STATE_SUCCESS,
  MUNICIPALITY_FAIL,
  MUNICIPALITY_SUCCESS,
  DISTRICT_FAIL,
  DISTRICT_SUCCESS,
  SECTION_FAIL,
  SECTION_SUCCESS,
} from './CoreTypes';
import * as netWorking from '../netWorking';

export const getStates = (country: string, fun?: any) => async (
  dispatch: Dispatch<CoreDispatchTypes>,
) => {
  dispatch({
    type: STATE_LOADING,
  });
  if (country !== undefined)
    netWorking.get(
      netWorking.getState + country,
      (res: any) => {
        dispatch({
          type: STATE_SUCCESS,
          payload: res.data.response,
        });
        if (fun) {
          fun(res.data.response[0]?._id);
        }
      },
      (error: any) => {
        dispatch({
          type: STATE_FAIL,
        });
      },
    );
  else {
    dispatch({
      type: STATE_SUCCESS,
      payload: [],
    });
    if (fun) {
      fun(undefined);
    }
  }
};

export const getMunicipality = (state: string, fun?: any) => async (
  dispatch: Dispatch<CoreDispatchTypes>,
) => {
  dispatch({
    type: MUNICIPALITY_LOADING,
  });
  if (state !== undefined)
    netWorking.get(
      netWorking.getMunicipality + state,
      (res: any) => {
        dispatch({
          type: MUNICIPALITY_SUCCESS,
          payload: res.data.response,
        });
        if (fun) {
          fun(res.data.response[0]?._id);
        }
      },
      (error: any) => {
        dispatch({
          type: MUNICIPALITY_FAIL,
        });
      },
    );
  else {
    dispatch({
      type: MUNICIPALITY_SUCCESS,
      payload: [],
    });
    if (fun) {
      fun(undefined);
    }
  }
};

export const getDistrict = (municipality: string, fun?: any) => async (
  dispatch: Dispatch<CoreDispatchTypes>,
) => {
  dispatch({
    type: DISTRICT_LOADING,
  });
  if (municipality !== undefined)
    netWorking.get(
      netWorking.getDistrict + municipality,
      (res: any) => {
        dispatch({
          type: DISTRICT_SUCCESS,
          payload: res.data.response,
        });
        if (fun) {
          fun(res.data.response[0]?._id);
        }
      },
      (error: any) => {
        dispatch({
          type: DISTRICT_FAIL,
        });
      },
    );
  else {
    dispatch({
      type: DISTRICT_SUCCESS,
      payload: [],
    });
    if (fun) {
      fun(undefined);
    }
  }
};

export const getSection = (district: string, fun?: any) => async (
  dispatch: Dispatch<CoreDispatchTypes>,
) => {
  dispatch({
    type: SECTION_LOADING,
  });
  if (district !== undefined)
    netWorking.get(
      netWorking.getSection + district,
      (res: any) => {
        dispatch({
          type: SECTION_SUCCESS,
          payload: res.data.response,
        });
        if (fun) {
          fun(res.data.response[0]?._id);
        }
      },
      (error: any) => {
        dispatch({
          type: SECTION_FAIL,
        });
      },
    );
  else {
    dispatch({
      type: SECTION_SUCCESS,
      payload: [],
    });
    if (fun) {
      fun(undefined);
    }
  }
};
