import React, { FunctionComponent } from 'react';
import {
  VotersBoxMapIcon,
  VotersBoxMapIconGreen,
} from '../../../assets/svg/index';

interface TopXlsProps {
  voterBox: any;
  index: any;
  selectedItem: any;
  setSelectedItem: any;
  t: any;
}

const TopXlsBar: FunctionComponent<TopXlsProps> = ({
  voterBox,
  index,
  selectedItem,
  setSelectedItem,
  t,
}: any) => {
  const getAddress = (address: any) => {
    let result = '';
    const newAddress = address;
    if (newAddress) {
      if (newAddress.street && newAddress.street !== '')
        result = newAddress.street;
      if (newAddress.exteriorNumber && newAddress.exteriorNumber !== '')
        result = result + `, ${newAddress.exteriorNumber}`;
      if (newAddress.interiorNumber && newAddress.interiorNumber !== '')
        result = result + `, ${newAddress.interiorNumber}`;
      if (newAddress.postalCode && newAddress.postalCode !== '')
        result = result + `, ${newAddress.postalCode}`;
      if (newAddress.suburb && newAddress.suburb !== '')
        result = result + `, ${newAddress.suburb}`;
    }
    return result;
  };

  const getRgName = (vb: any) => {
    if (vb?.status === 'OPEN') {
      if (vb?.incidence.length > 0) {
        return (
          <div className="content-rg rg-exists">
            {t('territorial.voters.lbl.problems')}
          </div>
        );
      } else {
        return (
          <div className="content-rg rg-exists">
            {t('dday.pollingStation.lbl.openPool')}
            {': '}
            <strong>{'9:12am'}</strong>
          </div>
        );
      }
    } else if (vb?.status === 'NOT_OPEN') {
      return (
        <div className="content-rg rg-exists">
          {t('dday.pollingStation.lbl.closedPool')}
        </div>
      );
    }
  };

  const getIconVB = () => {
    if (selectedItem === voterBox._id) {
      return (
        <>
          <VotersBoxMapIconGreen className="green-icon"></VotersBoxMapIconGreen>
        </>
      );
    } else {
      return (
        <>
          <VotersBoxMapIcon></VotersBoxMapIcon>
        </>
      );
    }
  };

  return (
    <div
      className={
        selectedItem === voterBox._id ? 'voterbox-item active' : 'voterbox-item'
      }
      onClick={() => {
        setSelectedItem(voterBox._id);
      }}
    >
      <div className="voterbox-item-icon">{getIconVB()}</div>
      <div className="voterbox-item-content">
        <div className="content-polling">
          <strong>
            {t('electoral.rcrg.modal.lbl.polling')}
            {': '}
          </strong>
          {voterBox.number}
        </div>
        <div className="content-address">{getAddress(voterBox.address)}</div>
        {getRgName(voterBox.rg)}
      </div>
    </div>
  );
};

export default TopXlsBar;
