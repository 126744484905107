import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../redux/Store';
import { useTranslation } from 'react-i18next';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Checkbox from 'react-bootstrap/FormCheck';
import Group from 'react-bootstrap/FormGroup';
import Input from 'react-bootstrap/FormControl';

import {
  postDenyVoterById,
} from '../../../redux/auditory/AuditoryActions';
import StaticModal from '../../../dialogs/StaticModal';

interface ModalDenyVoterProps {
  modalShow: any;
  setModalShow: any;
  setOpen: any;
}

export enum SEX {
  MAN,
  WOMAN,
  APACHE_HELICOPTER,
}

export const ModalDenyVoter: React.FunctionComponent<ModalDenyVoterProps> = ({
  modalShow,
  setModalShow,
  setOpen,
}: any) => {
  const citySelected = useSelector((globalState: RootStore) => {
    return globalState.core.globalMunicipalitySelected;
  });

  const voter: any = useSelector(
    (globalState: RootStore) => globalState.auditory.voterAudition,
  );
  
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [voterData, setVoterData] = useState(Object);
  const [reasonInput, setReasonInput] = useState('');
  const [checkOption1, setCheckStatusOption1] = useState(true);
  const [checkOption2, setCheckStatusOption2] = useState(false);
  const [checkOption3, setCheckStatusOption3] = useState(false);
  const [checkOption4, setCheckStatusOption4] = useState(false);

  const setReasonState = (state: number) => {
    for (let index = 1; index < 5; index++) {
      if (state === index) {
        if (state === 1) setCheckStatusOption1(true);
        if (state === 2) setCheckStatusOption2(true);
        if (state === 3) setCheckStatusOption3(true);
        if (state === 4) setCheckStatusOption4(true);
      } else {
        if (index === 1) setCheckStatusOption1(false);
        if (index === 2) setCheckStatusOption2(false);
        if (index === 3) setCheckStatusOption3(false);
        if (index === 4) setCheckStatusOption4(false);
      }
    }
  };

  const getAllData = () => {
    setModalShow(false);
    setOpen();
  };

  const submitFun = () => {
    const params_confirm = buildParamConfirmVoter(voterData);
    dispatch(postDenyVoterById(params_confirm, getAllData));
  };

  const buildParamConfirmVoter = (voter: any) => {
    let auditedProblem = checkOption1
      ? 'territorial.auditory.modal.label.doesntMatch'
      : checkOption2
      ? 'territorial.auditory.modal.label.incompleteInformation'
      : checkOption3
      ? 'territorial.auditory.modal.label.doesntExist'
      : reasonInput;

    let votersArray = [] as any;

    let originalRegistered = voter.registered === 1 ? 2 : voter.registered;
    let originalCaptured = voter.captured === 1 ? 2 : voter.captured;
    let originalVoter = {
      id: voter._id,
      registered: originalRegistered,
      captured: originalCaptured,
      deleted: true,
      audited: 2,
      auditedProblem: auditedProblem,
    };
    votersArray.push(originalVoter);

    voter?.duplicates.map((e: any) => {
      let duplicateRegistered = e.registered === 1 ? 2 : e.registered;
      let duplicateCaptured = e.captured === 1 ? 2 : e.captured;
      let duplicateVoter = {
        id: e._id,
        registered: duplicateRegistered,
        captured: duplicateCaptured,
        deleted: true,
        audited: 2,
        auditedProblem: auditedProblem,
      };
      votersArray.push(duplicateVoter);
    });

    let newVoter = { ...voterData };
    newVoter.registered = originalRegistered;
    newVoter.captured = originalCaptured;
    newVoter.audited = 2;
    newVoter.auditedProblem = auditedProblem;
    newVoter.deleted = false;
    newVoter.cityLicenseId = citySelected?._id;

    newVoter.mostUsedNetworks = {};
    newVoter.isNewVoteAddress = true;
    newVoter.isNewAddress = true;

    delete newVoter['_id'];
    delete newVoter['homeAddress'];
    delete newVoter['votingAddress'];
    delete newVoter['municipality'];

    let confirmData = [] as any;
    confirmData.push(votersArray);
    confirmData.push(newVoter);

    let requestObject;
    requestObject = {
      voterArray: confirmData,
    };

    return requestObject;
  };

  useEffect(() => {
    let newVoterData = { ...voter };
    setVoterData(newVoterData);
  }, [voter]);

  return (
    <StaticModal
      show={modalShow}
      title={t('territorial.auditory.modal.denyVoter')}
      closeText={t('territorial.auditory.btn.cancel')}
      confirmText={t('territorial.auditory.btn.confirm')}
      onHide={() => setModalShow(false)}
      submitFun={submitFun}
      validBtn={false}
      size="lg"
      sizeClass="md modal-rc"
    >
      <>
        <Container className="body-modal">
          <Row className="information-content">
            <Col xl={12} lg={12} md={12} sm={12} className="mt-2">
              <p className="content-voter-confirm">
                {t('territorial.auditory.modal.label.title')}
                <strong>
                  {voterData.firstName + ' ' + voterData.lastName}
                </strong>
              </p>
            </Col>
            <Col xl={12} lg={12} md={12} sm={12} className="">
              <Group className="mt-4">
                <Checkbox
                  custom
                  type="radio"
                  label={t('territorial.auditory.modal.label.doesntMatch')}
                  className="mr-3 deny-voter-radio-button"
                  name="deny-voter-doesntMatch"
                  id="deny-voter-doesntMatch"
                  checked={checkOption1}
                  onChange={() => {
                    setReasonState(1);
                  }}
                />
                <Checkbox
                  custom
                  type="radio"
                  label={t(
                    'territorial.auditory.modal.label.incompleteInformation',
                  )}
                  className="mr-3 deny-voter-radio-button"
                  name="deny-voter-incompleteInformation"
                  id="deny-voter-incompleteInformation"
                  checked={checkOption2}
                  onChange={() => {
                    setReasonState(2);
                  }}
                />
                <Checkbox
                  custom
                  type="radio"
                  label={t('territorial.auditory.modal.label.doesntExist')}
                  className="mr-3 deny-voter-radio-button"
                  name="deny-voter-doesntExist"
                  id="deny-voter-doesntExist"
                  checked={checkOption3}
                  onChange={() => {
                    setReasonState(3);
                  }}
                />
                <Checkbox
                  custom
                  type="radio"
                  label={t('territorial.auditory.modal.label.other')}
                  className="mr-3 deny-voter-radio-button"
                  name="deny-voter-other"
                  id="deny-voter-other"
                  checked={checkOption4}
                  onChange={() => {
                    setReasonState(4);
                  }}
                />
              </Group>
            </Col>
            <Col
              xl={12}
              lg={12}
              md={12}
              sm={12}
              className={checkOption4 ? 'mt-2' : 'mt-2 d-none'}
            >
              <Input
                as="textarea"
                rows={3}
                placeholder={t('territorial.auditory.modal.input.placeholder')}
                onChange={(event) => setReasonInput(event.target.value)}
                onKeyPress={(e: any) => {
                  if (e.key === 'Enter') {
                    e.preventDefault()
                    submitFun()
                  }
                }}
              />
            </Col>
          </Row>
        </Container>
      </>
    </StaticModal>
  );
};

export default ModalDenyVoter;
