import { string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteBlackIcon, EditIcon } from '../../../../../assets/svg';
import LayoutBodyComponent from '../../../../../components/LayoutBody';
import InputComponent from '../../../../../components/sub-component/input';
import {
  createProblems,
  getProblems,
  updateProblems,
} from '../../../../../redux/campaigns/campaignsActions';
import { RootStore } from '../../../../../redux/Store';
import DeleteModalComponent from './deleteDialog';
import Top from './top';

// tslint:disable-next-line: prefer-const
let emptyEdit: Problem;
export interface Problem {
  es: string;
  en: string;
  pt: string;
  _id: string;
}
const ProblemsView: React.FunctionComponent = () => {
  const [editMode, setEditMode] = useState<Problem>(emptyEdit);
  const [deleteItem, setDeleteItem] = useState<{
    _id: string;
    name: string;
  } | null>(null);
  const [showDelete, setShowDelete] = useState<boolean>(false);

  const dispatch = useDispatch();
  const problemList: Problem[] = useSelector((globalState: RootStore) => {
    return globalState.campaignsManager.problemList;
  });

  const save = () => {
    if (editMode) {
      if (editMode?._id === 'TEMP_ID') {
        dispatch(
          createProblems({ ...editMode, _id: undefined }, () => {
            dispatch(getProblems());
            setEditMode(emptyEdit);
          }),
        );
      } else {
        dispatch(
          updateProblems({ ...editMode }, () => {
            dispatch(getProblems());
            setEditMode(emptyEdit);
          }),
        );
      }
    }
  };

  const validSubmit = () => {
    if (
      editMode &&
      editMode.en &&
      editMode.en !== '' &&
      editMode.es &&
      editMode.es !== '' &&
      editMode.pt &&
      editMode.pt !== ''
    ) {
      return false;
    } else return true;
  };

  return (
    <>
      <LayoutBodyComponent>
        <div className="body-problems-list">
          <Top
            title={'General Settings'}
            btnText={'Add Problems'}
            submit={() => {
              setEditMode({
                _id: 'TEMP_ID',
                es: '',
                en: '',
                pt: '',
              });
            }}
          ></Top>
          <div className="list">
            <div className="header">
              <div className="header-title">
                <h2>Spanish</h2>
              </div>
              <div className="header-title">
                <h2>English</h2>
              </div>
              <div className="header-title">
                <h2>Portuguese</h2>
              </div>
            </div>
            <div className="body-list">
              {editMode && (
                <div className="item-edit">
                  <div className="inputs">
                    {' '}
                    <InputComponent
                      placeholder={'Robo, Agua...'}
                      length={250}
                      onChange={(value: string) => {
                        setEditMode((prev) => {
                          return { ...prev, es: value };
                        });
                      }}
                      title={''}
                      value={editMode.es}
                      horizontal={false}
                    ></InputComponent>{' '}
                    <InputComponent
                      placeholder={'Theft, Water...'}
                      length={250}
                      onChange={(value: string) => {
                        setEditMode((prev) => {
                          return { ...prev, en: value };
                        });
                      }}
                      title={''}
                      value={editMode.en}
                      horizontal={false}
                    ></InputComponent>{' '}
                    <InputComponent
                      placeholder={'Roubou, Água...'}
                      length={250}
                      onChange={(value: string) => {
                        setEditMode((prev) => {
                          return { ...prev, pt: value };
                        });
                      }}
                      title={''}
                      value={editMode.pt}
                      horizontal={false}
                    ></InputComponent>
                  </div>
                  <div className="buttons">
                    <div className="buttons-row">
                      <Button
                        variant="outline-dark"
                        onClick={() => {
                          // setEditModules(false);
                          setEditMode(emptyEdit);
                        }}
                      >
                        Cancel
                      </Button>

                      <Button
                        disabled={validSubmit()}
                        variant="primary"
                        onClick={() => {
                          // if (false) {
                          // setEditModules(false);
                          // } else null;
                          if (!validSubmit()) {
                            save();
                          }
                        }}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </div>
              )}
              {problemList.map((e, i) => {
                if (!editMode || editMode._id !== e._id)
                  return (
                    <div className="item" key={e._id}>
                      <div className="inputs">
                        {' '}
                        <p>{e.es}</p>
                        <p>{e.en}</p>
                        <p>{e.pt}</p>{' '}
                        <div className="actions">
                          <DeleteBlackIcon
                            onClick={() => {
                              // onDelete(data);
                              setShowDelete(true);
                              setDeleteItem({
                                _id: e._id,
                                name: e.en,
                              });
                            }}
                          ></DeleteBlackIcon>
                          <EditIcon
                            onClick={() => {
                              // onEdit(data);
                              setEditMode(e);
                            }}
                          ></EditIcon>
                          <div className="empty-box"></div>
                        </div>
                      </div>
                    </div>
                  );
              })}
            </div>
          </div>
        </div>
      </LayoutBodyComponent>{' '}
      <DeleteModalComponent
        setModalShow={setShowDelete}
        modalShow={showDelete}
        successFun={() => {
          dispatch(getProblems());
          setDeleteItem(null);
        }}
        toDelete={deleteItem}
        type={'PROBLEM'}
      ></DeleteModalComponent>
    </>
  );
};

export default ProblemsView;
