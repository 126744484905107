import axios from 'axios';

export const setSession = (authResult: any, history: any) => {
  // Set the time that the access token will expire at
  let expiresAt = JSON.stringify(authResult.expiresIn + new Date().getTime());
  // If there is a value on the `scope` param from the authResult,
  // use it to set scopes in the session for the user. Otherwise
  // use the scopes as requested. If no scopes were requested,
  // set it to nothing
  const token = `Bearer ${authResult.accessToken}`;
  localStorage.setItem('token', `Bearer ${authResult.accessToken}`); // setting token to local storage
  axios.defaults.headers.common['Authorization'] = token; // setting authorize token to header in axios
  localStorage.setItem('access_token', authResult.accessToken);
  localStorage.setItem('refresh_token', authResult.refreshToken);
  localStorage.setItem('expires_at', expiresAt);
  // localStorage.setItem('email', authResult.email);
  // localStorage.setItem('firstName', authResult.firstName);
  // localStorage.setItem('lastName', authResult.lastName);
  // localStorage.setItem('id', authResult.id);
  // localStorage.setItem('role', authResult.role);
  localStorage.setItem('team', JSON.stringify(authResult.team));
  // localStorage.setItem('states', JSON.stringify(authResult.states));
  // localStorage.setItem('cities', JSON.stringify(authResult.cities));
  // if (authResult.geoCities)
  //   localStorage.setItem('geoCities', JSON.stringify(authResult.geoCities));
  // localStorage.setItem('stateSelected', JSON.stringify(authResult.states[0]));
  // var citySelected = authResult.cities.find(
  //   (e: any) => e.stateId === authResult.states[0]._id,
  // );
  // localStorage.setItem('citySelected', JSON.stringify(citySelected));
  // if (authResult.geoCities)
  //   localStorage.setItem(
  //     'geoSelected',
  //     JSON.stringify(
  //       authResult.geoCities.find(
  //         (e: any) => e._id === citySelected.geoCoordenatesId,
  //       ),
  //     ),
  //   );
  // navigate to the home route
  history();
};
