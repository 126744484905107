import React, { useState, useEffect, FunctionComponent } from 'react';
//import { useTranslation } from 'react-i18next';
import '../style.scss';
import { DeleteBlackIcon } from '../../../../../assets/svg';
import { Map, TileLayer } from 'react-leaflet';
import { LatLngExpression } from 'leaflet';
import L from 'leaflet';
import { Municipality } from '../../../../../redux/core/CoreTypes';
//import { useDispatch } from 'react-redux';
//import { deleteState } from '../../../../../redux/coreLicense/coreLicenseActions';
import DeleteStateModalComponent from './deleteStateDialog';

interface StateItemProps {
  city: Municipality;
  getState: any;
}

const emptyEdit: any = L.latLngBounds([]);
const CityItem: FunctionComponent<StateItemProps> = ({
  city,
  getState,
}: StateItemProps) => {
  const leafletMapRef = React.useRef<Map>(null);
  // const { t } = useTranslation();
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const defaultPosition = {
    lat: 32.7157431,
    lng: -117.1888535,
    zoom: 11,
  };

  const [bounds, setBounds] = useState(emptyEdit);
  //const dispatch = useDispatch();
  const AddLayer = (polygon: any) => {
    const mapElement = leafletMapRef.current?.leafletElement;
    if (mapElement && polygon !== null) {
      var bounds = L.geoJSON(polygon, {
        onEachFeature: (feature, layer) => {
          layer.addTo(mapElement);
          layer.on('pm:edit', (e) => {
            if (e.target && e.target.pm) {
              //const lay = e.layer.toGeoJSON();
              // setPolygonData(lay);
            }
          });
          layer.on('pm:cut', function (e) {
            if (e.target && e.target.pm) {
              layer.off('pm:edit');
            }
          });
          layer.on('click', function (e) {
            // setPm(e.target.pm);
            e.target.pm.disable();
            // setEditMode(true);
          });
        },
      }).getBounds();
      setBounds(bounds);
      return bounds;
    } else return null;
  };

  useEffect(() => {
    if (leafletMapRef.current) {
      const mapElement = leafletMapRef.current.leafletElement;

      (mapElement as any).pm.setGlobalOptions({ pmIgnore: false });
      (mapElement as any).pm.setLang('es');

      (mapElement as any).pm.addControls({
        drawMarker: false,
        drawCircle: false,
        drawCircleMarker: false,
        drawPolyline: false,
        drawRectangle: false,
        drawPolygon: false,
        editMode: false,
        dragMode: false,
        cutPolygon: false,
        removalMode: false,
        zoomMode: false,
      });
      mapElement.off('pm:create');
      mapElement.on('pm:create', (e: any) => {
        if (e.target && e.target.pm) {
          // addNewLayer(e.layer.toGeoJSON());
        }
      });
      mapElement.off('pm:remove');
      mapElement.on('pm:remove', (e: any) => {
        // (mapElement as any).pm.addControls({
        //   drawPolygon: true,
        //   removalMode: false,
        // });
        // onPolygonRemoved();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leafletMapRef.current]);

  useEffect(() => {
    if (leafletMapRef.current && city) {
      console.log(city);
      AddLayer({ ...city.geoCoordinates, type: 'Feature' });
    }
  }, [city]);

  return (
    <>
      <div className="state-item" key={city._id}>
        <div className="title">
          <h2>{city.name}</h2>
          <DeleteBlackIcon
            onClick={() => {
              // onDelete(data);
              setShowDeleteModal(true);
            }}
          ></DeleteBlackIcon>
        </div>
        <div className="invisible-wall"></div>
        <div className="map-box">
          <Map
            ref={leafletMapRef}
            center={
              [defaultPosition.lat, defaultPosition.lng] as LatLngExpression
            }
            zoom={defaultPosition.zoom}
            bounds={bounds.isValid() ? bounds : undefined}
            boundsOptions={{ padding: [0, 0] }}
            zoomControl={false}
            boxZoom={false}
            dragging={false}
            tap={false}
          >
            <TileLayer
              url="https://maps.googleapis.com/maps/vt?pb=!1m5!1m4!1i{z}!2i{x}!3i{y}!4i256!2m3!1e0!2sm!3i349018013!3m9!2sen-US!3sUS!5e18!12m1!1e47!12m3!1e37!2m1!1ssmartmaps!4e0"
              maxZoom={20}
              subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
              attribution={'Google Maps'}
            />
          </Map>
        </div>
      </div>
      <DeleteStateModalComponent
        setModalShow={setShowDeleteModal}
        modalShow={showDeleteModal}
        successFun={() => {
          getState();
        }}
        toDelete={city}
        type={'CITY'}
      ></DeleteStateModalComponent>
    </>
  );
};

export default CityItem;
